// import 'bootstrap/dist/css/bootstrap.min.css';
import "./css/style.css";

import { createTheme, ThemeProvider } from "@mui/material/styles";
// import { Lines } from 'react-preloaders';
import { LicenseInfo } from "@mui/x-license-pro";
import { initializeApp } from "firebase/app";
import {
  connectAuthEmulator,
  getAuth,
  onAuthStateChanged,
  signOut,
} from "firebase/auth";
import {
  connectFirestoreEmulator,
  doc,
  getDoc,
  getDocFromCache,
  getFirestore,
  onSnapshot,
  writeBatch,
} from "firebase/firestore";
import { enableIndexedDbPersistence } from "firebase/firestore";
import { getPerformance } from "firebase/performance";
import { post } from "jsx/api";
import { UserContext } from "jsx/contexts/user-context";
import React, { useEffect, useState } from "react";

import firebaseConfig from "./configs/firebase-config.json";
import Index from "./jsx";

const isLocal =
  window.location.hostname === "localhost" ||
  window.location.hostname === "127.0.0.1";

const app = initializeApp(firebaseConfig);
getPerformance(app);

const auth = getAuth();
const db = getFirestore();

let userSub;

const theme = createTheme({
  palette: {
    primary: {
      main: "#645CFF",
    },
  },

  components: {
    MuiOutlinedInput: {
      styleOverrides: {
        notchedOutline: {
          borderColor: "var(--border-color)",
        },
      },
    },
    MuiInputLabel: {
      styleOverrides: {
        root: {
          fontFamily: "var(--primary-font)",
        },
      },
    },

    MuiTableContainer: {
      styleOverrides: {
        root: {
          marginBottom: 16,
        },
      },
    },

    MuiInputBase: {
      styleOverrides: {
        root: {
          fontFamily: "var(--primary-font)",
        },
      },
    },

    MuiToggleButtonGroup: {
      styleOverrides: {
        MuiButtonBase: {
          root: {
            fontFamily: "var(--primary-font)",
          },
        },
      },
    },

    MuiTableCell: {
      styleOverrides: {
        root: {
          fontFamily: "var(--primary-font)",
          borderBottom: "1px solid var(--purple-light)",
        },
        head: {
          borderBottom: "1px solid var(--purple-light)",
          fontSize: "13px",
          color: "#6c757d",
        },
      },
    },

    MuiButton: {
      styleOverrides: {
        sizeLarge: {
          padding: "12px 30px",
          fontSize: "16px",
          fontWeight: "500",
        },

        sizeMedium: {
          padding: "10px 20px",
          fontSize: "14px",
        },

        sizeSmall: {
          padding: "8px 20px",
          fontSize: "13px",
        },
      },
    },
  },
});

// setPersistence(auth, browserLocalPersistence)
//   .then(() => { })
//   .catch((error) => {
//     // Handle Errors here.
//     const errorCode = error.code;
//     const errorMessage = error.message;
//   });
if (isLocal) {
  connectFirestoreEmulator(db, "localhost", 8080);
  connectAuthEmulator(auth, "http://localhost:9099");
}

enableIndexedDbPersistence(db)
  .then(() => {})
  .catch((err) => {
    if (err.code === "failed-precondition") {
      // Multiple tabs open, persistence can only be enabled
      // in one tab at a a time.
      // ...
    } else if (err.code === "unimplemented") {
      // The current browser does not support all of the
      // features required to enable persistence
      // ...
    } else {
      console.log("failed");
    }
  });

function App() {
  const defaultCtx = {
    user: {
      displayName: "",
      photoURL: "",
      uid: "",
      email: "",
      organizations: [],
    },
    selectedOrganization: "",
    organizations: {},
  };
  const [ctx, setCtx] = useState(defaultCtx);
  const [hasProcessedAuth, setHasProcessedAuth] = useState(false);

  useEffect(() => {
    if (localStorage.getItem("ctx")) {
      // check if session is expired
      try {
        const cachedContext = JSON.parse(localStorage.getItem("ctx"));
        if (
          cachedContext.user.stsTokenManager &&
          new Date().getTime() >
            cachedContext.user.stsTokenManager.expirationTime
        ) {
          logout();
        } else {
          setCtx(processCtx(cachedContext));
        }
      } catch (e) {
        clearLocalStorage();
      }
    }

    onAuthStateChanged(auth, async (googleUser) => {
      const db = getFirestore();
      const batch = writeBatch(db);
      setHasProcessedAuth(true);

      if (googleUser) {
        let savedUser = (
          await getDoc(
            doc(db, "users", googleUser.uid, "users_private", "data")
          )
        ).data();

        // if user doesn't exist, create something default
        if (!savedUser) {
          savedUser = {};
        }

        // merge in google data
        savedUser.selectedOrganization = ctx.selectedOrganization;

        const privateData = JSON.parse(JSON.stringify(googleUser));
        savedUser = Object.assign({}, savedUser, privateData);

        const adminData =
          (
            await getDoc(
              doc(db, "users", googleUser.uid, "users_admin", "data")
            )
          )?.data() ?? {};

        const { role } = adminData;

        setCtx((prev) => {
          return {
            ...processCtx(prev),
            user: savedUser,
            isSiteAdmin: role === "admin",
            adminData,
          };
        });

        const publicData = {
          displayName: googleUser.displayName,
          photoURL: googleUser.photoURL,
        };

        try {
          const publicDoc = doc(db, "users", googleUser.uid);
          const privateDoc = doc(
            db,
            "users",
            googleUser.uid,
            "users_private",
            "data"
          );

          batch.set(publicDoc, publicData, { merge: true });
          batch.set(privateDoc, privateData, { merge: true });
          await batch.commit();

          if (process.env.NODE_ENV === "development") {
            await post("users/setAdmin", {});
          }
        } catch (e) {
          console.error("Error adding document: ", e);
        }
      } else {
        // User is signed out
        logout();
        // TODO: REDIRECT USER HERE
      }
    });
  }, []);

  useEffect(() => {
    const user = ctx.user;
    if (!user.uid) {
      return;
    }
    if (userSub) {
      return;
    }

    // setup listener
    userSub = onSnapshot(
      doc(db, "users", user.uid, "users_private", "data"),
      (doc) => {
        const source = doc.metadata.hasPendingWrites ? "Local" : "Server";
        if (source === "Server") {
          setCtx((prev) => {
            return { ...processCtx(prev), user: doc.data() };
          });
        }
      }
    );
  }, [ctx.user]);

  useEffect(() => {
    fetchCompanies();
  }, [
    ctx.user.organizations
      ? ctx.user.organizations.length
      : ctx.user.organizations,
  ]);

  useEffect(() => {
    saveCtx();
  }, [ctx]);

  async function fetchCompanies() {
    if (!ctx.user.organizations || ctx.user.organizations.length === 0) {
      return;
    }

    const fetchedCompanies = {};

    for (const e of ctx.user.organizations) {
      let company;
      if (!localStorage.getItem("shouldInvalidateCache")) {
        //get from cache
        try {
          company = (await getDocFromCache(doc(db, "organizations", e))).data();
        } catch (error) {
          company = (await getDoc(doc(db, "organizations", e))).data();
        }
      } else {
        localStorage.setItem("shouldInvalidateCache", false);
        company = (await getDoc(doc(db, "organizations", e))).data();
      }

      fetchedCompanies[e] = company;
    }

    setCtx((prev) => {
      return { ...processCtx(prev), organizations: fetchedCompanies };
    });
  }

  function clearLocalStorage() {
    localStorage.clear();
  }

  function processCtx(ctx) {
    if (!ctx) {
      return;
    }
    const user = ctx.user;

    if (!ctx.selectedOrganization && user.organizations) {
      if (user.organizations.length) {
        ctx.selectedOrganization = user.organizations[0];
      }
    }

    return ctx;
  }

  function updateCtx(ctx) {
    setCtx((prev) => {
      return {
        ...processCtx(prev),
        ...ctx,
      };
    });
  }

  function saveCtx() {
    localStorage.setItem("ctx", JSON.stringify(ctx));
  }

  // Logout updates the user data to default
  async function logout() {
    const auth = getAuth();
    try {
      await signOut(auth);

      clearLocalStorage();
      setCtx({
        user: {
          displayName: "",
          photoURL: "",
          uid: "",
          email: "",
          userLoggedOut: true,
        },
      });
    } catch (e) {
      console.log("error", e);
    }
  }

  function selectOrganization(organizationId) {
    setCtx((prev) => {
      return { ...processCtx(prev), selectedOrganization: organizationId };
    });
  }

  let organizationName = "";
  if (
    ctx.selectedOrganization &&
    ctx.organizations &&
    ctx.organizations[ctx.selectedOrganization]
  ) {
    organizationName = ctx.organizations[ctx.selectedOrganization].companyName;
  }

  useEffect(
    () =>
      LicenseInfo.setLicenseKey(
        "1bbce218646beb48320245711c03b56bT1JERVI6NDIyMjUsRVhQSVJZPTE2ODIxMTAwNDEwMDAsS0VZVkVSU0lPTj0x"
      ),
    []
  );
  return (
    <ThemeProvider theme={theme}>
      <UserContext.Provider
        value={{
          user: ctx.user,
          selectedOrganization: ctx.selectedOrganization,
          organizations: ctx.organizations,
          organizationName: organizationName,
          isSiteAdmin: ctx.isSiteAdmin,
          admin: ctx.adminData,
          hasProcessedAuth,
          updateCtx,
          selectOrganization,
          logout,
        }}
      >
        <div className="App">
          <Index />
          {/* <Lines /> */}
        </div>
      </UserContext.Provider>
    </ThemeProvider>
  );
}

export default App;
