import {
  collection,
  doc,
  getDoc,
  getDocs,
  getFirestore,
  query,
  where,
} from "@firebase/firestore";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import Skeleton from "@mui/material/Skeleton";
import { UserContext } from "jsx/contexts/user-context";
import SecondaryDocumentRow from "jsx/element/secondary-document-row";
import Header4 from "jsx/layout/header4";
import SigningStatusSection from "jsx/pages/company/secondaries/docs/secondaries-signing-status";
import React, { useContext, useEffect, useState } from "react";

function CompanySecondariesTransaction({ match }) {
  const { user, selectedOrganization } = useContext(UserContext);
  const [transaction, setTransaction] = useState();
  const [secondary, setSecondary] = useState();
  const [stakeholder, setStakeholder] = useState();

  const { secondaryId, transactionId } = match.params;

  const db = getFirestore();

  async function fetchTransaction(organizationId, secondaryId, transactionId) {
    const transactionSnapshot = await getDoc(
      doc(
        db,
        "organizations",
        organizationId,
        "organizations_admin",
        "data",
        "secondaries",
        secondaryId,
        "secondary_transactions",
        transactionId
      )
    );
    const transactionData = transactionSnapshot.data();
    setTransaction(transactionData);
    return transactionData;
  }

  async function fetchStakeholder(organizationId, stakeholderEmail) {
    const stakeholderSnapshot = await getDocs(
      query(
        collection(
          db,
          "organizations",
          organizationId,
          "organizations_private",
          "data",
          "stakeholders"
        ),
        where("emails", "array-contains", stakeholderEmail)
      )
    );
    const stakeholderData = stakeholderSnapshot.docs[0]?.data();
    setStakeholder(stakeholderData);
  }

  async function fetchSecondary(organizationId, secondaryId) {
    const secondarySnapshot = await getDoc(
      doc(
        db,
        "organizations",
        organizationId,
        "organizations_private",
        "data",
        "secondaries",
        secondaryId
      )
    );
    const secondaryData = secondarySnapshot.data();
    setSecondary(secondaryData);
  }

  async function fetchData() {
    const transactionData = await fetchTransaction(
      selectedOrganization,
      secondaryId,
      transactionId
    );
    const sellerEmail = transactionData.sellerEmail;
    await fetchStakeholder(selectedOrganization, sellerEmail);
    await fetchSecondary(selectedOrganization, secondaryId);
  }

  useEffect(() => {
    fetchData();
  }, [selectedOrganization, secondaryId, transactionId]);

  const pricePerShare =
    transaction?.pricePerShareInBaseUnits / transaction?.pricePerSharePrecision;

  const pricePerShareFormat = {
    style: "currency",
    currency: "USD",
    minimumFractionDigits: Math.max(
      2,
      Math.log10(transaction?.pricePerSharePrecision ?? 2)
    ),
  };

  const totalProceeds = pricePerShare * transaction?.quantity;
  const totalPriceFormat = {
    style: "currency",
    currency: "USD",
  };

  const [originalPriceInBaseUnits, originalPricePrecision] =
    transaction?.securityDetails.securityCategory === "optionGrant"
      ? [
          transaction?.securityDetails.strikePriceInBaseUnits,
          transaction?.securityDetails.strikePricePrecision,
        ]
      : [
          transaction?.securityDetails.purchasePriceInBaseUnits,
          transaction?.securityDetails.purchasePricePrecision,
        ];
  const originalPrice = originalPriceInBaseUnits / originalPricePrecision;
  const originalPriceFormat = {
    style: "currency",
    currency: "USD",
    minimumFractionDigits: Math.max(2, Math.log10(originalPricePrecision ?? 2)),
  };

  const fmv = secondary?.fmvInBaseUnits / secondary?.fmvPrecision;
  const fmvPriceFormat = {
    style: "currency",
    currency: "USD",
    minimumFractionDigits: Math.max(
      2,
      Math.log10(secondary?.fmvPrecision ?? 2)
    ),
  };

  const exerciseCosts =
    transaction?.aggregateExercisePriceInBaseUnits /
    transaction?.aggregateExercisePricePrecision;
  const withholdingAmount =
    transaction?.withholdingAmountInBaseUnits /
    transaction?.withholdingAmountPrecision;
  const sellerProceeds =
    transaction?.sellerProceedsInBaseUnits /
    transaction?.sellerProceedsPrecision;

  const transferDocId = transaction?.transferDocumentId;

  const loading = (
    <>
      <Skeleton height={80} />
      <Skeleton height={80} />
      <Skeleton height={80} />
      <Skeleton height={80} />
      <Skeleton height={80} />
      <Skeleton height={80} />
    </>
  );

  return (
    <>
      <Header4 hideAllMenuItems />

      <div className="content-body section-padding">
        <div className="container">
          <div className="row justify-content-center align-items-center">
            <div className="col-10">
              <div className="card outlined">
                <div className="card-header">
                  <h4 className="card-title">Transaction</h4>
                </div>
                {!transaction && loading}
                {transaction && stakeholder && (
                  <div className="card-body">
                    <div className="mt-3 mb-3">
                      <h4>Seller</h4>
                    </div>
                    <div className="card outlined-small-thin">
                      <div className="row">
                        <Detail title="Name">{transaction.sellerName}</Detail>
                        <Detail title="Email">{transaction.sellerEmail}</Detail>
                        {stakeholder.tenureInMonths && (
                          <Detail title="Months employed">
                            {Math.floor(stakeholder.tenureInMonths)}
                          </Detail>
                        )}
                      </div>
                    </div>
                    <div className="mt-3 mb-3">
                      <h4>Buyer</h4>
                    </div>
                    <div className="card outlined-small-thin">
                      <div className="row">
                        <Detail title="Name">{transaction.buyerName}</Detail>
                        <Detail title="Email">{transaction.buyerEmail}</Detail>
                      </div>
                    </div>
                    <div className="mt-3 mb-3">
                      <h4>Security</h4>
                    </div>
                    <div className="card outlined-small-thin">
                      <div className="row">
                        <Detail title="Certificate number">
                          {transaction.securityDetails.certificateNo}
                        </Detail>
                        <Detail title="Class">Common stock</Detail>
                        <Detail title="Security type">
                          {transaction.securityDetails.securityCategory ===
                          "optionGrant"
                            ? "Options"
                            : "Shares"}
                        </Detail>
                        <Detail
                          title={
                            transaction.securityDetails.securityCategory ===
                            "optionGrant"
                              ? "Strike price"
                              : "Purchase price"
                          }
                        >
                          {originalPrice.toLocaleString(
                            undefined,
                            originalPriceFormat
                          )}
                        </Detail>
                        <Detail title="FMV">
                          {fmv.toLocaleString(undefined, fmvPriceFormat)}
                        </Detail>
                      </div>
                    </div>
                    <div className="mt-3 mb-3">
                      <h4>Proceeds</h4>
                    </div>
                    <div className="card outlined-small-thin">
                      <div className="row">
                        <Detail title="Quantity sold">
                          {transaction.quantity.toLocaleString()} shares
                        </Detail>
                        <Detail title="Price per share">
                          {pricePerShare.toLocaleString(
                            undefined,
                            pricePerShareFormat
                          )}
                        </Detail>
                        <Detail title="Sale total">
                          {totalProceeds.toLocaleString(
                            undefined,
                            totalPriceFormat
                          )}
                        </Detail>
                        <Detail title="Exercise costs">
                          {exerciseCosts
                            ? exerciseCosts.toLocaleString(
                                undefined,
                                totalPriceFormat
                              )
                            : "-"}
                        </Detail>
                        <Detail title="Tax withholding">
                          {withholdingAmount
                            ? withholdingAmount.toLocaleString(
                                undefined,
                                totalPriceFormat
                              )
                            : "-"}
                        </Detail>
                        <Detail title="Seller proceeds">
                          {sellerProceeds
                            ? sellerProceeds.toLocaleString(
                                undefined,
                                totalPriceFormat
                              )
                            : "-"}
                        </Detail>
                      </div>
                    </div>
                    {transaction && (
                      <>
                        <div className="mt-3 mb-3">
                          <h4>Signing Status</h4>
                        </div>
                        <SigningStatusSection transaction={transaction} />
                      </>
                    )}
                    {transferDocId && (
                      <>
                        <div className="mt-4 mb-3">
                          <h4>Documents</h4>
                        </div>
                        <SecondaryDocumentRow
                          organizationId={selectedOrganization}
                          secondaryId={secondaryId}
                          transactionId={transactionId}
                          documentId={transferDocId}
                          onDocumentVoid={fetchData}
                          showVoid
                          showInvites
                        />
                      </>
                    )}
                  </div>
                )}
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
}

// helper component for formatting details/info
function Detail({ title, icon, children }) {
  return (
    <div className="p-2">
      <div className="d-flex">
        {icon && (
          <FontAwesomeIcon
            icon={icon}
            size="sm"
            style={{ marginRight: 12, marginTop: 2, color: "var(--gray-500)" }}
          />
        )}

        <h6 className="col-5" style={{ color: "var(--gray-500)" }}>
          {title}
        </h6>
        <h6>{children}</h6>
      </div>
    </div>
  );
}

export default CompanySecondariesTransaction;
