import { regular } from "@fortawesome/fontawesome-svg-core/import.macro";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import MSButton from "jsx/element/button";
import SettingsNav from "jsx/element/secondaries-nav";
import SecondaryModelingTable from "jsx/element/secondary-modeling-table";
import Header4 from "jsx/layout/header4";
import React, { useState } from "react";

function CompanySecondariesModeling() {
  const [isFullscreen, setFullscreen] = useState(false);

  return (
    <>
      <Header4 />

      {isFullscreen && (
        <MSButton
          variant="icon"
          onClick={() => {
            setFullscreen(!isFullscreen);
          }}
          style={{
            right: "32px",
            top: "24px",
            minWidth: 36,
            minHeight: 36,
            padding: "0px",
            zIndex: 999,
            position: "fixed",
          }}
        >
          <FontAwesomeIcon icon={regular("arrows-minimize")} size="xs" />
        </MSButton>
      )}

      <div
        className="content-body section-padding"
        style={{ marginTop: `${isFullscreen ? "-60px" : "0"}` }}
      >
        <div className={isFullscreen ? "" : "container"}>
          <div className="d-flex">
            {!isFullscreen && (
              <div className="me-4" style={{ minWidth: "220px" }}>
                <SettingsNav />
              </div>
            )}
            <div className={isFullscreen ? "col-xl-12" : "col-xl-11"}>
              <div className="flex-column">
                {/* Debug Remove later */}
                <div className="card outlined">
                  <div className="card-header">
                    <h4 className="card-title">Secondary modeling</h4>
                    <div>
                      <MSButton
                        variant="icon"
                        onClick={() => {
                          setFullscreen(!isFullscreen);
                        }}
                      >
                        <FontAwesomeIcon
                          icon={regular("arrows-maximize")}
                          size="xs"
                        />
                      </MSButton>
                    </div>
                  </div>

                  <div className="card-body">
                    <SecondaryModelingTable isFullscreen={isFullscreen} />
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
}

export default CompanySecondariesModeling;
