import Footer1 from "jsx/layout/footer1";
import Header3 from "jsx/layout/header3";
import React from "react";
import { Link } from "react-router-dom";

function Demo() {
  return (
    <>
      <Header3 />

      <div className="intro section-padding position-relative" id="intro">
        <div className="container">
          <div className="row align-items-center justify-content-between">
            <div className="col-xl-6 col-md-6">
              <div className="intro-content mb-5">
                <h1>
                  Landing Page with <br /> Dashboard Template
                </h1>
                <p>
                  Modern Share is the complete UI of Front end and Backend. Sign
                  in, Signup, Phone and ID card verification, One time password
                  verify and add bank, debit card settings and profile etc pages
                  included.{" "}
                </p>
                <a
                  href="#demo"
                  className="btn btn-primary me-3"
                  data-scroll-nav="1"
                >
                  View Demo
                </a>
                <a href="#" className="btn btn-outline-primary me-2">
                  Buy
                </a>
              </div>
            </div>
            <div className="col-xl-6 col-md-6 py-md-5">
              <div className="intro-demo_img">
                <img
                  src={require("./../../images/portfolio.jpg")}
                  alt=""
                  className="img-fluid"
                />
              </div>
            </div>
          </div>
        </div>
      </div>

      <div className="demo section-padding page-section" id="demo">
        <div className="container">
          <div className="row py-lg-5 justify-content-center">
            <div className="col-xl-7">
              <div className="section-heading text-center">
                <span>Explore</span>
                <h2>Landing Page</h2>
              </div>
            </div>
          </div>
          <div className="row">
            <div className="col-xl-4 col-md-4 col-sm-6">
              <div className="demo_img">
                <Link to={"./"} target="_blank">
                  <div className="img-wrap">
                    <img
                      src={require("./../../images/demo/landing/home1.jpg")}
                      alt=""
                      className="img-fluid"
                    />
                  </div>
                </Link>
                <h4>Homepage</h4>
              </div>
            </div>
            <div className="col-xl-4 col-md-4 col-sm-6">
              <div className="demo_img">
                <Link to={"./companies"} target="_blank">
                  <div className="img-wrap">
                    <img
                      src={require("./../../images/demo/landing/home2.jpg")}
                      alt=""
                      className="img-fluid"
                    />
                  </div>
                </Link>
                <h4>Homepage</h4>
              </div>
            </div>
            <div className="col-xl-4 col-md-4 col-sm-6">
              <div className="demo_img">
                <Link to={"./price"} target="_blank">
                  <div className="img-wrap">
                    <img
                      src={require("./../../images/demo/landing/price.jpg")}
                      alt=""
                      className="img-fluid"
                    />
                  </div>
                </Link>
                <h4>Price</h4>
              </div>
            </div>
            <div className="col-xl-4 col-md-4 col-sm-6">
              <div className="demo_img">
                <Link to={"./wallet"} target="_blank">
                  <div className="img-wrap">
                    <img
                      src={require("./../../images/demo/landing/wallet.jpg")}
                      alt=""
                      className="img-fluid"
                    />
                  </div>
                </Link>
                <h4>Wallet</h4>
              </div>
            </div>
            <div className="col-xl-4 col-md-4 col-sm-6">
              <div className="demo_img">
                <Link to={"./about"} target="_blank">
                  <div className="img-wrap">
                    <img
                      src={require("./../../images/demo/landing/about.jpg")}
                      alt=""
                      className="img-fluid"
                    />
                  </div>
                </Link>
                <h4>About</h4>
              </div>
            </div>
            <div className="col-xl-4 col-md-4 col-sm-6">
              <div className="demo_img">
                <Link to={"./team"} target="_blank">
                  <div className="img-wrap">
                    <img
                      src={require("./../../images/demo/landing/team.jpg")}
                      alt=""
                      className="img-fluid"
                    />
                  </div>
                </Link>
                <h4>Team</h4>
              </div>
            </div>
            <div className="col-xl-4 col-md-4 col-sm-6">
              <div className="demo_img">
                <Link to={"./blog"} target="_blank">
                  <div className="img-wrap">
                    <img
                      src={require("./../../images/demo/landing/blog.jpg")}
                      alt=""
                      className="img-fluid"
                    />
                  </div>
                </Link>
                <h4>Blog</h4>
              </div>
            </div>
            <div className="col-xl-4 col-md-4 col-sm-6">
              <div className="demo_img">
                <Link to={"./blog-single"} target="_blank">
                  <div className="img-wrap">
                    <img
                      src={require("./../../images/demo/landing/blog-single.jpg")}
                      alt=""
                      className="img-fluid"
                    />
                  </div>
                </Link>
                <h4>Blog Single</h4>
              </div>
            </div>
            <div className="col-xl-4 col-md-4 col-sm-6">
              <div className="demo_img">
                <Link to={"./career"} target="_blank">
                  <div className="img-wrap">
                    <img
                      src={require("./../../images/demo/landing/career.jpg")}
                      alt=""
                      className="img-fluid"
                    />
                  </div>
                </Link>
                <h4>Career</h4>
              </div>
            </div>
            <div className="col-xl-4 col-md-4 col-sm-6">
              <div className="demo_img">
                <Link to={"./contact"} target="_blank">
                  <div className="img-wrap">
                    <img
                      src={require("./../../images/demo/landing/contact.jpg")}
                      alt=""
                      className="img-fluid"
                    />
                  </div>
                </Link>
                <h4>Contact</h4>
              </div>
            </div>
            <div className="col-xl-4 col-md-4 col-sm-6">
              <div className="demo_img">
                <Link to={"./helpdesk"} target="_blank">
                  <div className="img-wrap">
                    <img
                      src={require("./../../images/demo/landing/helpdesk.jpg")}
                      alt=""
                      className="img-fluid"
                    />
                  </div>
                </Link>
                <h4>Help Desk</h4>
              </div>
            </div>
            <div className="col-xl-4 col-md-4 col-sm-6">
              <div className="demo_img">
                <Link to={"./faq"} target="_blank">
                  <div className="img-wrap">
                    <img
                      src={require("./../../images/demo/landing/faq.jpg")}
                      alt=""
                      className="img-fluid"
                    />
                  </div>
                </Link>
                <h4>FAQ</h4>
              </div>
            </div>
            <div className="col-xl-4 col-md-4 col-sm-6">
              <div className="demo_img">
                <Link to={"./privacy-policy"} target="_blank">
                  <div className="img-wrap">
                    <img
                      src={require("./../../images/demo/landing/privacy.jpg")}
                      alt=""
                      className="img-fluid"
                    />
                  </div>
                </Link>
                <h4>Privacy Policy</h4>
              </div>
            </div>
            <div className="col-xl-4 col-md-4 col-sm-6">
              <div className="demo_img">
                <Link to={"./terms-condition"} target="_blank">
                  <div className="img-wrap">
                    <img
                      src={require("./../../images/demo/landing/terms.jpg")}
                      alt=""
                      className="img-fluid"
                    />
                  </div>
                </Link>
                <h4>Terms Condition</h4>
              </div>
            </div>
          </div>
        </div>
        <div className="container">
          <div className="row py-lg-5 justify-content-center">
            <div className="col-xl-7">
              <div className="section-heading text-center">
                <span>Explore</span>
                <h2>Dashboard</h2>
              </div>
            </div>
          </div>
          <div className="row">
            <div className="col-xl-4 col-md-4 col-sm-6">
              <div className="demo_img">
                <Link to={"./dashboard"} target="_blank">
                  <div className="img-wrap">
                    <img
                      src={require("./../../images/demo/dashboard/dashboard.jpg")}
                      alt=""
                      className="img-fluid"
                    />
                  </div>
                </Link>
                <h4>Dashboard</h4>
              </div>
            </div>
            <div className="col-xl-4 col-md-4 col-sm-6">
              <div className="demo_img">
                <Link to={"./browse"} target="_blank">
                  <div className="img-wrap">
                    <img
                      src={require("./../../images/demo/dashboard/buy-sell.jpg")}
                      alt=""
                      className="img-fluid"
                    />
                  </div>
                </Link>
                <h4>Buy and Sell</h4>
              </div>
            </div>
            <div className="col-xl-4 col-md-4 col-sm-6">
              <div className="demo_img">
                <Link to={"./accounts"} target="_blank">
                  <div className="img-wrap">
                    <img
                      src={require("./../../images/demo/dashboard/accounts.jpg")}
                      alt=""
                      className="img-fluid"
                    />
                  </div>
                </Link>
                <h4>Accounts</h4>
              </div>
            </div>
            <div className="col-xl-4 col-md-4 col-sm-6">
              <div className="demo_img">
                <Link to={"./settings"} target="_blank">
                  <div className="img-wrap">
                    <img
                      src={require("./../../images/demo/dashboard/settings.jpg")}
                      alt=""
                      className="img-fluid"
                    />
                  </div>
                </Link>
                <h4>Edit Profile</h4>
              </div>
            </div>
            <div className="col-xl-4 col-md-4 col-sm-6">
              <div className="demo_img">
                <Link to={"./settings-preferences"} target="_blank">
                  <div className="img-wrap">
                    <img
                      src={require("./../../images/demo/dashboard/preferences.jpg")}
                      alt=""
                      className="img-fluid"
                    />
                  </div>
                </Link>
                <h4>Preferences</h4>
              </div>
            </div>
            <div className="col-xl-4 col-md-4 col-sm-6">
              <div className="demo_img">
                <Link to={"./settings-security"} target="_blank">
                  <div className="img-wrap">
                    <img
                      src={require("./../../images/demo/dashboard/security.jpg")}
                      alt=""
                      className="img-fluid"
                    />
                  </div>
                </Link>
                <h4>Security</h4>
              </div>
            </div>
            <div className="col-xl-4 col-md-4 col-sm-6">
              <div className="demo_img">
                <Link to={"./settings-account"} target="_blank">
                  <div className="img-wrap">
                    <img
                      src={require("./../../images/demo/dashboard/linked-account.jpg")}
                      alt=""
                      className="img-fluid"
                    />
                  </div>
                </Link>
                <h4>Linked Account</h4>
              </div>
            </div>
            <div className="col-xl-4 col-md-4 col-sm-6">
              <div className="demo_img">
                <Link to={"./history"} target="_blank">
                  <div className="img-wrap">
                    <img
                      src={require("./../../images/demo/dashboard/history.jpg")}
                      alt=""
                      className="img-fluid"
                    />
                  </div>
                </Link>
                <h4>History</h4>
              </div>
            </div>
            <div className="col-xl-4 col-md-4 col-sm-6">
              <div className="demo_img">
                <Link to={"./signin"} target="_blank">
                  <div className="img-wrap">
                    <img
                      src={require("./../../images/demo/dashboard/signin.jpg")}
                      alt=""
                      className="img-fluid"
                    />
                  </div>
                </Link>
                <h4>Signin</h4>
              </div>
            </div>
            <div className="col-xl-4 col-md-4 col-sm-6">
              <div className="demo_img">
                <Link to={"./signup"} target="_blank">
                  <div className="img-wrap">
                    <img
                      src={require("./../../images/demo/dashboard/signup.jpg")}
                      alt=""
                      className="img-fluid"
                    />
                  </div>
                </Link>
                <h4>Signup</h4>
              </div>
            </div>
            <div className="col-xl-4 col-md-4 col-sm-6">
              <div className="demo_img">
                <Link to={"./reset"} target="_blank">
                  <div className="img-wrap">
                    <img
                      src={require("./../../images/demo/dashboard/reset.jpg")}
                      alt=""
                      className="img-fluid"
                    />
                  </div>
                </Link>
                <h4>Reset</h4>
              </div>
            </div>
            <div className="col-xl-4 col-md-4 col-sm-6">
              <div className="demo_img">
                <Link to={"./lock"} target="_blank">
                  <div className="img-wrap">
                    <img
                      src={require("./../../images/demo/dashboard/lock.jpg")}
                      alt=""
                      className="img-fluid"
                    />
                  </div>
                </Link>
                <h4>Locked</h4>
              </div>
            </div>
            <div className="col-xl-4 col-md-4 col-sm-6">
              <div className="demo_img">
                <Link to={"./otp-1"} target="_blank">
                  <div className="img-wrap">
                    <img
                      src={require("./../../images/demo/dashboard/otp-phone.jpg")}
                      alt=""
                      className="img-fluid"
                    />
                  </div>
                </Link>
                <h4>OTP Number</h4>
              </div>
            </div>
            <div className="col-xl-4 col-md-4 col-sm-6">
              <div className="demo_img">
                <Link to={"./otp-2"} target="_blank">
                  <div className="img-wrap">
                    <img
                      src={require("./../../images/demo/dashboard/otp-code.jpg")}
                      alt=""
                      className="img-fluid"
                    />
                  </div>
                </Link>
                <h4>OTP Code</h4>
              </div>
            </div>
            <div className="col-xl-4 col-md-4 col-sm-6">
              <div className="demo_img">
                <Link to={"./verify-step-1"} target="_blank">
                  <div className="img-wrap">
                    <img
                      src={require("./../../images/demo/dashboard/verify-id.jpg")}
                      alt=""
                      className="img-fluid"
                    />
                  </div>
                </Link>
                <h4>Verify ID</h4>
              </div>
            </div>
            <div className="col-xl-4 col-md-4 col-sm-6">
              <div className="demo_img">
                <Link to={"./verify-step-2"} target="_blank">
                  <div className="img-wrap">
                    <img
                      src={require("./../../images/demo/dashboard/upload-id.jpg")}
                      alt=""
                      className="img-fluid"
                    />
                  </div>
                </Link>
                <h4>Upload ID</h4>
              </div>
            </div>
            <div className="col-xl-4 col-md-4 col-sm-6">
              <div className="demo_img">
                <Link to={"./verify-step-3"} target="_blank">
                  <div className="img-wrap">
                    <img
                      src={require("./../../images/demo/dashboard/id-verifing.jpg")}
                      alt=""
                      className="img-fluid"
                    />
                  </div>
                </Link>
                <h4>ID Verifying</h4>
              </div>
            </div>
            <div className="col-xl-4 col-md-4 col-sm-6">
              <div className="demo_img">
                <Link to={"./verify-step-4"} target="_blank">
                  <div className="img-wrap">
                    <img
                      src={require("./../../images/demo/dashboard/id-verified.jpg")}
                      alt=""
                      className="img-fluid"
                    />
                  </div>
                </Link>
                <h4>ID Verified</h4>
              </div>
            </div>
            <div className="col-xl-4 col-md-4 col-sm-6">
              <div className="demo_img">
                <Link to={"./add-debit-card"} target="_blank">
                  <div className="img-wrap">
                    <img
                      src={require("./../../images/demo/dashboard/add-debit-card.jpg")}
                      alt=""
                      className="img-fluid"
                    />
                  </div>
                </Link>
                <h4>Add Debit Card</h4>
              </div>
            </div>
            <div className="col-xl-4 col-md-4 col-sm-6">
              <div className="demo_img">
                <Link to={"./verify-step-6"} target="_blank">
                  <div className="img-wrap">
                    <img
                      src={require("./../../images/demo/dashboard/success.jpg")}
                      alt=""
                      className="img-fluid"
                    />
                  </div>
                </Link>
                <h4>Success</h4>
              </div>
            </div>
            <div className="col-xl-4 col-md-4 col-sm-6">
              <div className="demo_img">
                <Link to={"./verify-step-5"} target="_blank">
                  <div className="img-wrap">
                    <img
                      src={require("./../../images/demo/dashboard/choose-account.jpg")}
                      alt=""
                      className="img-fluid"
                    />
                  </div>
                </Link>
                <h4>Recommendation</h4>
              </div>
            </div>
            <div className="col-xl-4 col-md-4 col-sm-6">
              <div className="demo_img">
                <Link to={"./add-bank-acc"} target="_blank">
                  <div className="img-wrap">
                    <img
                      src={require("./../../images/demo/dashboard/add-bank.jpg")}
                      alt=""
                      className="img-fluid"
                    />
                  </div>
                </Link>
                <h4>Add Bank Account</h4>
              </div>
            </div>
          </div>
        </div>
      </div>

      <Footer1 />
    </>
  );
}

export default Demo;
