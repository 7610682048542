import { confirmPasswordReset, verifyPasswordResetCode } from "firebase/auth";
import { getAuth } from "firebase/auth";
import { Field, Form, Formik } from "formik";
import MSButton from "jsx/element/button";
import MSField from "jsx/element/ms-field";
import React, { useEffect, useState } from "react";
import { Link } from "react-router-dom";
import * as Yup from "yup";

const ResetSchema = Yup.object().shape({
  password: Yup.string().required("Required"),
});

function Reset({ oobCode }) {
  const [hasSubmitted, setHasSubmitted] = useState(false);
  const auth = getAuth();
  const [email, setEmail] = useState();
  const [isError, setError] = useState(false);

  useEffect(() => {
    const actionCode = oobCode;

    // Verify the password reset code is valid.
    verifyPasswordResetCode(auth, actionCode)
      .then((email) => {
        const accountEmail = email;
        setEmail(accountEmail);
      })
      .catch((error) => {
        console.log("Some error", error);
        setError(true);
      });
  }, []);

  let content;

  if (!isError) {
    content = (
      <>
        <Formik
          initialValues={{
            email: "",
            password: "",
          }}
          validationSchema={ResetSchema}
          onSubmit={async (values) => {
            const actionCode = oobCode;
            const newPassword = values.password;

            // Save the new password.
            confirmPasswordReset(auth, actionCode, newPassword)
              .then((resp) => {
                // Password reset has been confirmed and new password updated.

                // TODO: Display a link back to the app, or sign-in the user directly
                // if the page belongs to the same domain as the app:
                auth
                  .signInWithEmailAndPassword(email, newPassword)
                  .then((userCredential) => {
                    // Signed in
                    // const user = userCredential.user;
                  })
                  .catch((error) => {
                    /* const errorCode = error.code; */
                    /* const errorMessage = error.message; */
                    /* if (errorCode === "auth/wrong-password") { */
                    /* } */
                  });
                // TODO: If a continue URL is available, display a button which on
                // click redirects the user back to the app via continueUrl with
                // additional state determined from that URL's parameters.
              })
              .catch((error) => {
                // Error occurred during confirmation. The code might have expired or the
                // password is too weak.
              });
            setHasSubmitted(true);
          }}
        >
          {(formik) => {
            const { touched, errors, isSubmitting } = formik;

            return (
              <Form>
                <div className="authincation section-padding">
                  <div className="container h-100">
                    <div className="row justify-content-center h-100 align-items-center">
                      <div className="col-xl-5 col-md-6">
                        <div className="card outlined-small">
                          {!hasSubmitted && (
                            <>
                              <div className="card-header justify-content-center">
                                <h3>Change password</h3>
                              </div>
                              <div className="card-body py-4">
                                <form action="">
                                  <div className="mb-0">
                                    <MSField
                                      type="email"
                                      name="email"
                                      value={email}
                                      disabled
                                      formik={formik}
                                    />
                                  </div>

                                  <div className="mb-3">
                                    <MSField
                                      autoFocus
                                      type="password"
                                      name="password"
                                      formik={formik}
                                      placeholder="New password"
                                    />
                                  </div>
                                  <MSButton
                                    variant={"primary"}
                                    size={"large"}
                                    type="submit"
                                    title="Reset password"
                                    className="w-100"
                                    loading={isSubmitting}
                                  ></MSButton>
                                </form>
                              </div>
                            </>
                          )}

                          {hasSubmitted && (
                            <>
                              <div className="card-header justify-content-center">
                                <h3>Password Reset</h3>
                              </div>
                              <div className="card-body py-4">
                                <p className="text-center">
                                  Your password has been reset. You can now
                                  follow the previous investment invite link in
                                  your email to sign in.
                                </p>
                              </div>
                            </>
                          )}
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </Form>
            );
          }}
        </Formik>
      </>
    );
  } else {
    content = (
      <>
        <div className="section-padding">
          <div className="container h-100">
            <div className="row justify-content-center h-100 align-items-center">
              <div className="col-xl-5 col-md-6">
                <div className="card outlined-small">
                  <div className="card-header">
                    <h3>Change password</h3>
                  </div>
                  <div className="card-body py-4">
                    <p>
                      The password reset link you clicked on is either expired
                      or invalid.
                    </p>
                    <br></br>
                    <p>
                      Please go try resetting your password again by clicking{" "}
                      <Link
                        to={"/reset"}
                        style={{
                          color: "var(--primary)",
                          textDecoration: "underline",
                        }}
                      >
                        here
                      </Link>
                      .
                    </p>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </>
    );
  }

  return content;
}

export default Reset;
