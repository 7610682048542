import "react-dates/initialize";
import "react-dates/lib/css/_datepicker.css";
import "react-datepicker/dist/react-datepicker.css";

import { doc, getDoc, getFirestore } from "firebase/firestore";
import { UserContext } from "jsx/contexts/user-context";
import Header4 from "jsx/layout/header4";
import React, { useEffect, useState } from "react";
import { useParams } from "react-router-dom";

import SecondaryForm from "./secondary-form";

function CompanySecondariesManageEdit() {
  const { selectedOrganization } = React.useContext(UserContext);
  const [secondary, setSecondary] = useState();
  const { secondaryId } = useParams();

  useEffect(() => {
    fetchSecondary();
  }, [selectedOrganization]);

  const db = getFirestore();

  async function fetchSecondary() {
    if (!selectedOrganization) {
      return;
    }

    const res = await getDoc(
      doc(
        db,
        "organizations",
        selectedOrganization,
        "organizations_private",
        "data",
        "secondaries",
        secondaryId
      )
    );

    if (res) {
      const secondaryData = res.data();
      setSecondary(secondaryData);
    }
  }

  return (
    <>
      <Header4 hideAllMenuItems title="Create secondary round" />
      <div className="content-body section-padding">
        <div className="container mb-5">
          <div className="row justify-content-center align-items-center">
            <div className="col-12">
              <div className="row">
                <div className="card outlined">
                  <div className="card-header" style={{ display: "block" }}>
                    <h2>Update secondary round</h2>
                    <p>
                      Setup a new secondary round to generate documents and
                      invite investors
                    </p>
                  </div>
                  <div className="card-body">
                    <SecondaryForm
                      secondaryId={secondaryId}
                      secondary={secondary}
                      isUpdate={true}
                    />
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
}

export default CompanySecondariesManageEdit;
