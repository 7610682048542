import React from "react";
import { Link } from "react-router-dom";

function Footer2() {
  return (
    <>
      <div className="footer dashboard">
        <div className="container-fluid">
          <div className="row">
            <div className="col-xl-6">
              <div className="copyright">
                <p>
                  © Copyright {new Date().getFullYear()}{" "}
                  <Link to={"#"}>Modern Share</Link> I All Rights Reserved
                </p>
              </div>
            </div>
            <div className="col-xl-6">
              <div className="footer-social">
                <ul></ul>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
}

export default Footer2;
