import { duotone } from "@fortawesome/fontawesome-svg-core/import.macro";
import { get } from "jsx/api";
import { UserContext } from "jsx/contexts/user-context";
import EmptyState from "jsx/element/empty-state";
import SellerHeader from "jsx/layout/seller-header";
import React, { useEffect, useState } from "react";

import FilesViewer from "../../element/files-viewer";
import SellerTendersNav from "./seller-tenders-nav";

export default function SellerDataroom({ match }) {
  const { organizationId } = match.params;
  const { user } = React.useContext(UserContext);
  const [securities, setSecurities] = useState();
  const [activeSecondary, setActiveSecondary] = useState();
  const [files, setFiles] = useState();

  useEffect(() => {
    // fetchOrganization();
    fetchSecurities();
    fetchActiveSecondaries();
  }, [user.uid]);

  async function fetchSecurities() {
    if (!user.uid) {
      return;
    }
    const result = await get("users/listSecurities");
    if (result.data && result.data.length > 0) {
      setSecurities(
        result.data.find((sec) => sec.organizationId === organizationId)
      );
    }
  }

  async function fetchActiveSecondaries() {
    const result = await get("users/getActiveSecondaries");
    if (result.data && result.data.length > 0) {
      const secondary = result.data.find(
        (sec) => sec.organizationId === organizationId
      );
      setActiveSecondary(secondary);
      if ((secondary?.sellerDocuments ?? []).length > 0) {
        setFiles(secondary.sellerDocuments);
      } else setFiles();
    }
  }

  return (
    <>
      <SellerHeader />

      <div className="content-body section-padding">
        <div className="container">
          <div className="d-flex">
            <div className="me-4" style={{ minWidth: "220px" }}>
              <SellerTendersNav
                organizationId={organizationId}
                secondaryId={activeSecondary?.id}
              />
            </div>
            <div className="col-xl-10">
              <div className="card outlined">
                <div className="card-header">
                  <h4 className="card-title">
                    {securities?.organizationName} Dataroom
                  </h4>
                </div>
                <div className="card-body">
                  {files && <FilesViewer files={files} user={user} />}
                  {!files && (
                    <EmptyState
                      icon={duotone("rectangle-history-circle-plus")}
                      title={"No documents to display"}
                      subtitle={
                        "Documents will appear here when uploaded by the company."
                      }
                    />
                  )}
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
}
