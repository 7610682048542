import React from "react";
import { Link, useHistory } from "react-router-dom";

import routes from "./routes.json";
function CommonSharesWelcomeCard({ show }) {
  const history = useHistory();
  if (!show) {
    history.push(routes.view);
  }
  return (
    <>
      {show && (
        <div className="row card-body">
          <div className="col d-flex flex-column justify-content-center">
            <h5 className="mb-2 mt-3">Add a common share class</h5>
            <p>
              This should reflect how your common share class exists today.
              Modern Share will add the share class to your cap table and issue
              certificates to your shareholders in electronic form.
            </p>
          </div>
          <div className="col d-flex flex-row justify-content-end align-items-center">
            <Link to={routes.addClass}>
              <div className="btn">Add share class</div>
            </Link>
          </div>
        </div>
      )}
    </>
  );
}
export default CommonSharesWelcomeCard;
