import Header4 from "jsx/layout/header4";
import React, { useEffect } from "react";

function NonDisclosure() {
  useEffect(() => {
    window.scrollTo({
      top: 0,
      left: 0,
      behavior: "instant",
    });
  }, []);

  return (
    <>
      <Header4 hideAllMenuItems />

      <div className="terms_condition mt-4">
        <div className="container">
          <div className="row justify-content-center">
            <div className="col-lg-9">
              <div className="terms_condition-content">
                <h2>NON-DISCLOSURE AGREEMENT</h2>
                <p>
                  <strong>
                    PLEASE CAREFULLY READ THE FOLLOWING TERMS OF THIS
                    NON-DISCLOSURE AGREEMENT. BY INDICATING BELOW THAT YOU AGREE
                    TO BE BOUND BY THE TERMS OF THIS AGREEMENT, YOU HAVE ENTERED
                    INTO THIS LEGALLY BINDING AGREEMENT.
                  </strong>
                </p>

                <p>
                  This Non-Disclosure Agreement (this “
                  <strong>Agreement</strong>”) is entered into by and between M
                  Share Capital LLC, a Delaware limited liability company
                  (collectively with its affiliates, “
                  <strong>Modern Share</strong>”), and you (the “
                  <strong>Recipient</strong>”), effective as of the date that
                  you electronically sign this Agreement.
                </p>

                <h3>THE PARTIES AGREE AS FOLLOWS:</h3>
                <ol>
                  <li>
                    <p>
                      <strong>Purpose.</strong> Modern Share is providing the
                      Recipient with access to its platform, available on
                      Apple’s App Store, Google Play Apps and{" "}
                      <a href="http://www.modernshare.com">
                        www.modernshare.com
                      </a>{" "}
                      (the “<strong>Platform</strong>”), for the purpose of
                      evaluating one or more potential transactions that may be
                      presented to the Recipient on the Platform (the “
                      <strong>Purpose</strong>
                      ”). This Agreement is intended to protect against the
                      unauthorized use or disclosure of any Confidential
                      Information that is made available to the Recipient on the
                      Platform.
                    </p>
                  </li>
                  <li>
                    <p>
                      <strong>Confidential Information.</strong> “Confidential
                      Information” means any and all information, data,
                      documents, agreements, files and other materials, whether
                      in oral, written, graphic or other form, that is made
                      available on the Platform, regardless of whether or not
                      any such information, data, document, agreement, file or
                      other material bears a “confidential,” “proprietary” or
                      similar legend. Without limiting the foregoing,
                      “Confidential Information” includes any and all
                      information, data, documents, agreements, files and other
                      materials provided in connection with the Purpose, such as
                      (without limitation) names, prices, addresses, terms,
                      share quantities, term sheets, financial reports,
                      financial data, employee data, customer lists, forecasts,
                      strategies, products, reports, business plans or
                      processes, financing documents, transactional offers,
                      proposals, documents or agreements or other business
                      information, documents or materials. For the avoidance of
                      doubt, all information made available to the Recipient via
                      the Platform is considered Confidential Information.
                    </p>
                  </li>
                  <li>
                    <p>
                      <strong>Obligations of the Recipient.</strong> The
                      Recipient agrees not to use any Confidential Information
                      for any purpose other than the Purpose. The Recipient
                      agrees to maintain the confidentiality of the Confidential
                      Information with at least the same degree of care that it
                      uses to protect its own confidential and proprietary
                      information, and with no less than a reasonable degree of
                      care. The Recipient agrees not to disclose or permit
                      disclosure of any Confidential Information to any third
                      party for any purpose whatsoever, unless such disclosure
                      is (a) expressly consented to in writing by Modern Share
                      or (b) made to a Representative (as defined below) of the
                      Recipient, provided, in the case of either “(a)” or “(b),”
                      such third party receiving such disclosure is subject to
                      nondisclosure obligations at least as restrictive as those
                      set forth in this Agreement. The Recipient further agrees
                      to notify Modern Share in writing of any actual or
                      suspected unauthorized disclosure of Confidential
                      Information.
                    </p>
                  </li>
                  <li>
                    <p>
                      <strong>Obligations Regarding Representatives.</strong> If
                      the Recipient is an entity, such as (without limitation) a
                      partnership, limited liability company, corporation,
                      association or trust, then the Recipient agrees that each
                      of its obligations under this Agreement shall apply,
                      mutatis mutandis, to its directors, officers, employees,
                      members, partners, agents, consultants and affiliates
                      (collectively, “<strong>Representatives</strong>”), and
                      that the Recipient shall be responsible for any breach of
                      any such obligation by any of its Representatives.
                    </p>
                  </li>
                  <li>
                    <p>
                      <strong>No Duplication.</strong> The Recipient agrees not
                      to make any copies or duplicates of any Confidential
                      Information.
                    </p>
                  </li>
                  <li>
                    <p>
                      <strong>
                        Exceptions to the Obligation of Confidentiality.
                      </strong>{" "}
                      Notwithstanding anything to the contrary contained in this
                      Agreement, Confidential Information shall not include any
                      information that: (a) was in the public domain at the time
                      it was disclosed by Modern Share or has entered the public
                      domain through no wrongful act, fault or omission of the
                      Recipient; (b) was rightfully known to the Recipient,
                      without restriction, at the time of disclosure, as
                      evidenced by the Recipient’s contemporaneous written
                      records; (c) becomes known to the Recipient, without
                      restriction, from a source other than Modern Share without
                      breach of this Agreement by the Recipient and otherwise
                      not in violation of Modern Share’s rights; or (d) is
                      disclosed pursuant to the order or requirement of a court,
                      administrative agency or other governmental body;
                      provided, however, that prior to any such compelled
                      disclosure, the Recipient shall provide reasonable advance
                      notice of such order or requirement to Modern Share to
                      enable Modern Share, at Modern Share’s own expense, to
                      seek a protective order or otherwise prevent or restrict
                      such disclosure.
                    </p>
                  </li>
                  <li>
                    <p>
                      <strong>Disclaimer.</strong> The Recipient agrees that
                      neither Modern Share nor any of its Representatives: (a)
                      have made or make any representation or warranty,
                      expressed or implied, as to the accuracy or completeness
                      of any Confidential Information; or (b) shall have any
                      liability to the Recipient or any of its Representatives
                      relating to or resulting from the use or disclosure of any
                      Confidential Information or any errors therein or
                      omissions therefrom.
                    </p>
                  </li>
                  <li>
                    <p>
                      <strong>No Rights Granted.</strong> All Confidential
                      Information (including, without limitation, all copies,
                      extracts and portions thereof) is and shall remain the
                      sole property of Modern Share. Nothing in this Agreement
                      shall (a) be construed as granting any rights under any
                      patent, copyright or other intellectual property right of
                      Modern Share or (b) grant the Recipient any rights in or
                      to Modern Share’s Confidential Information other than the
                      limited right to review such Confidential Information.{" "}
                    </p>
                  </li>
                  <li>
                    <p>
                      <strong>Remedies.</strong> Modern Share and the Recipient
                      each agree that the obligations of the Recipient set forth
                      in this Agreement are necessary and reasonable in order to
                      protect Modern Share and its business. Modern Share and
                      the Recipient each expressly agree that due to the unique
                      nature of the Confidential Information, monetary damages
                      would be inadequate to compensate Modern Share for any
                      breach by the Recipient of its covenants and agreements
                      set forth in this Agreement. Accordingly, Modern Share and
                      the Recipient each agree and acknowledge that any such
                      violation or threatened violation shall cause irreparable
                      harm to Modern Share and that, in addition to any other
                      remedies that may be available in law, in equity or
                      otherwise, Modern Share shall be entitled to obtain
                      injunctive relief against the threatened breach of this
                      Agreement or the continuation of any such breach by the
                      Recipient, without the necessity of proving actual
                      damages.
                    </p>
                  </li>
                  <li>
                    <p>
                      <strong>General.</strong>
                      <ol style={{ marginTop: 5 }} type="a">
                        <li>
                          <p>
                            If one or more provisions of this Agreement are held
                            to be unenforceable under applicable law, the
                            parties agree to renegotiate such provision in good
                            faith. In the event that the parties cannot reach a
                            mutually agreeable and enforceable replacement for
                            such provision, then (i) such provision shall be
                            excluded from this Agreement, (ii) the balance of
                            this Agreement shall be interpreted as if such
                            provision were so excluded and (iii) the balance of
                            this Agreement shall be enforceable in accordance
                            with its terms.
                          </p>
                        </li>
                        <li>
                          <p>
                            The Recipient understands and acknowledges that no
                            license under any patents, copyrights, trademarks,
                            or other technologies is granted to or conferred
                            upon Recipient in this Agreement or by the
                            disclosure of any Confidential Information by Modern
                            Share as contemplated hereunder, either expressly,
                            by implication, inducement, estoppel or otherwise,
                            and that any license under such intellectual
                            property rights must be express and in writing.
                          </p>
                        </li>
                        <li>
                          <p>
                            The failure of either party to enforce any right
                            resulting from any breach of any provision of this
                            Agreement by the other party is not a waiver of any
                            right relating to a subsequent breach of such
                            provision or of any other right under this
                            Agreement.
                          </p>
                        </li>
                        <li>
                          <p>
                            This Agreement will be governed by the laws of the
                            State of Delaware without reference to conflict of
                            laws principles, if any.
                          </p>
                        </li>
                        <li>
                          <p>
                            This Agreement constitutes the sole and entire
                            agreement between the parties with respect to the
                            Confidential Information and all restrictions upon
                            it; it supersedes any and all prior or
                            contemporaneous electronic, oral or written
                            agreements, negotiations, communications,
                            understandings and terms, whether express or implied
                            regarding the Confidential Information, and may not
                            be amended except in a writing signed by an
                            authorized representative of each respective party.
                            Any other agreements between the parties, including
                            nondisclosure agreements, will not be affected by
                            this Agreement.
                          </p>
                        </li>
                      </ol>
                    </p>
                  </li>
                </ol>

                <p>
                  <strong>
                    THE RECIPIENT WILL CAREFULLY READ, UNDERSTAND AND ACCEPT THE
                    TERMS AND CONDITIONS OF THIS AGREEMENT BEFORE CLICKING “I
                    AGREE” OR OTHER SIMILARLY WORDED BUTTON. IF RECIPIENT HAS
                    ANY QUESTIONS ABOUT ANY OF THE PROVISIONS IN THIS AGREEMENT,
                    S/HE WILL CONTACT MODERN SHARE AT{" "}
                    <a href="mailto:support@modernshare.com">
                      SUPPORT@MODERNSHARE.COM
                    </a>
                    . THE RECIPIENT UNDERSTANDS THAT CLICKING “I AGREE” IS THE
                    LEGAL EQUIVALENT OF MANUALLY SIGNING THIS AGREEMENT, AND THE
                    RECIPIENT WILL BE LEGALLY BOUND BY ITS TERMS.
                  </strong>
                </p>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
}

export default NonDisclosure;
