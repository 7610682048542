import {
  collection,
  doc,
  getDoc,
  getDocs,
  getFirestore,
  onSnapshot,
  setDoc,
} from "@firebase/firestore";
import { regular } from "@fortawesome/fontawesome-svg-core/import.macro";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import Dialog from "@mui/material/Dialog";
import DialogActions from "@mui/material/DialogActions";
import Table from "@mui/material/Table";
import TableBody from "@mui/material/TableBody";
import TableCell from "@mui/material/TableCell";
import TableContainer from "@mui/material/TableContainer";
import TableHead from "@mui/material/TableHead";
import TableRow from "@mui/material/TableRow";
import {
  differenceInDays,
  differenceInHours,
  differenceInMinutes,
  differenceInSeconds,
  formatDuration,
} from "date-fns";
import { get } from "jsx/api";
import { post } from "jsx/api";
import { getBaseUrl } from "jsx/api";
import { UserContext } from "jsx/contexts/user-context";
import MSButton from "jsx/element/button";
import Detail from "jsx/element/detail";
import Divider from "jsx/element/divider";
import FilesViewer from "jsx/element/files-viewer";
import ManageTendersNav from "jsx/element/manage-tenders-nav";
import { formatPrice } from "jsx/helpers";
import Header6 from "jsx/layout/header6";
import moment from "moment";
import React, { useContext, useEffect, useRef, useState } from "react";
import { Link, useHistory } from "react-router-dom";

export default function CompanyTenderManage({ match }) {
  const history = useHistory();
  const { user, selectedOrganization, selectOrganization } =
    React.useContext(UserContext);
  const [secondary, setSecondary] = useState({});
  const [stakeholders, setStakeholders] = useState([]);
  const [secondaryEmployees, setSecondaryEmployees] = useState([]);
  const [secondaryOrders, setSecondaryOrders] = useState([]);
  const [transactions, setTransactions] = useState([]);
  const [decks, setDecks] = useState([]);
  const [sellerDocuments, setSellerDocuments] = useState([]);
  const [deckConfirmId, setDeckConfirmId] = useState();
  const [secondaryId, setSecondaryId] = useState();
  const [checkedSecondaries, setCheckedSecondaries] = useState(false);

  const [loading, setIsLoading] = useState(false);

  //Tooltip
  const [isTooltipOpen, setIsTooltipOpen] = useState(false);
  const copyTextInput = useRef(null);
  const copyTooltipTarget = useRef(null);

  useEffect(() => {
    // Check if user is part of any secondaries. If so, redirect to seller dashboard

    if (!checkedSecondaries) {
      redirectToSeller();
    }

    if (!selectedOrganization) {
      if (user.organizations && user.organizations.length > 0) {
        selectOrganization(user.organizations[0]);
      }
    }
    async function fetchSecondaryId() {
      if (!selectedOrganization) {
        return;
      }
      const querySnapshot = await getDocs(
        collection(
          db,
          `organizations/${selectedOrganization}/organizations_private/data/secondaries`
        )
      );
      const secondaryId = querySnapshot.docs[0]?.id;
      setSecondaryId(secondaryId);
    }

    if (!secondaryId) {
      fetchSecondaryId();
    }

    if (
      selectedOrganization &&
      secondaryId &&
      Object.entries(secondary).length === 0
    ) {
      fetchSecondary(selectedOrganization, secondaryId);
    }
  }, [selectedOrganization, secondaryId, checkedSecondaries]);

  const db = getFirestore();

  async function redirectToSeller() {
    const result = await get("users/getSecondaries");
    if (result.data && result.data.length > 0) {
      history.replace({ pathname: `/seller/dashboard` });
    }
    setCheckedSecondaries(true);
  }

  function copyLink() {
    setIsTooltipOpen(true);
    setTimeout(() => {
      setIsTooltipOpen(false);
    }, 3000);

    /* Copy the text inside the text field */
    navigator.clipboard.writeText(
      `${getBaseUrl()}/org/${selectedOrganization}/invite/secondary/${secondaryId}`
    );
  }

  // Get secondary information along with stakeholder, secondary seller, secondary order
  // data, secondary transactions
  async function fetchSecondary(organizationId, secondaryId) {
    setIsLoading(true);

    const fetchSecondary = async () => {
      const secondarySnap = await getDoc(
        doc(
          db,
          `organizations/${organizationId}/organizations_private/data/secondaries/${secondaryId}`
        )
      );
      const secondaryData = secondarySnap.data();
      setSecondary(secondaryData);
      setDecks(secondaryData.decks ?? []);
      setSellerDocuments(secondaryData.sellerDocuments ?? []);
      return secondaryData;
    };

    const fetchEmployees = async () => {
      const employeesSnapshot = await getDocs(
        collection(
          db,
          `organizations/${organizationId}/organizations_private/data/stakeholders`
        )
      );
      const stakeholders = employeesSnapshot.docs.map((doc) => ({
        id: doc.id,
        ...doc.data(),
      }));
      setStakeholders(stakeholders);
      return stakeholders;
    };

    const fetchSellers = async () => {
      const sellersSnapshot = await getDocs(
        collection(
          db,
          `organizations/${organizationId}/organizations_admin/data/secondaries/${secondaryId}/secondary_sellers`
        )
      );

      const secondarySellers = sellersSnapshot.docs.map((seller) => ({
        id: seller.id,
        ...seller.data(),
      }));
      return secondarySellers;
    };

    const fetchOrders = async () => {
      const secondaryOrdersSnapshot = await getDocs(
        collection(
          db,
          `organizations/${organizationId}/organizations_admin/data/secondaries/${secondaryId}/secondary_orders`
        )
      );
      const secondaryOrders = secondaryOrdersSnapshot.docs.map((doc) => ({
        id: doc.id,
        ...doc.data(),
      }));
      setSecondaryOrders(secondaryOrders);
      return secondaryOrders;
    };

    const fetchTransactions = async () => {
      const transactionsSnapshot = await getDocs(
        collection(
          db,
          "organizations",
          organizationId,
          "organizations_admin",
          "data",
          "secondaries",
          secondaryId,
          "secondary_transactions"
        )
      );
      const transactions = transactionsSnapshot.docs.map((doc) => ({
        id: doc.id,
        ...doc.data(),
      }));
      setTransactions(transactions);
      return transactions;
    };

    const [
      secondaryData, // eslint-disable-line no-unused-vars
      stakeholders,
      secondarySellers,
      secondaryOrders,
      transactions, // eslint-disable-line no-unused-vars
    ] = await Promise.all([
      fetchSecondary(),
      fetchEmployees(),
      fetchSellers(),
      fetchOrders(),
      fetchTransactions(),
    ]);

    const secondaryEmployees = secondarySellers.flatMap((secondarySeller) => {
      const stakeholder = stakeholders.find(
        (stakeholder) =>
          secondarySeller.email === stakeholder.workEmail ||
          secondarySeller.email === stakeholder.homeEmail
      );

      if (secondarySeller) {
        const securitiesSelling = secondaryOrders
          .filter(
            (order) =>
              order.userId === secondarySeller.userId &&
              order.status === "open" &&
              order.orderType === "sell"
          )
          .reduce((acc, { quantity }) => acc + quantity, 0);
        return [{ ...stakeholder, ...secondarySeller, securitiesSelling }];
      } else {
        return [];
      }
    });

    setSecondaryEmployees(secondaryEmployees);
    setIsLoading(false);
  }

  async function uploadSecondaryDeck(e) {
    var formData = new FormData();
    var file = e.target.files[0];
    formData.append("file", file);
    formData.append("organizationId", selectedOrganization);
    formData.append("secondaryId", secondaryId);
    await post("secondaries/uploadDeck", formData, {
      headers: {
        "Content-Type": "multipart/form-data",
      },
    });
    await fetchSecondary(selectedOrganization, secondaryId);
  }

  async function deleteDeck(deckId) {
    const newDecks = secondary.decks.filter((item) => {
      return item.deckId !== deckId;
    });

    await setDoc(
      doc(
        db,
        "organizations",
        selectedOrganization,
        "organizations_private",
        "data",
        "secondaries",
        secondaryId
      ),
      { decks: newDecks },
      { merge: true }
    );
    await fetchSecondary(selectedOrganization, secondaryId);
  }

  async function uploadSellerDocument(e) {
    var formData = new FormData();
    var file = e.target.files[0];
    formData.append("file", file);
    formData.append("organizationId", selectedOrganization);
    formData.append("secondaryId", secondaryId);
    await post("secondaries/uploadSellerDocument", formData, {
      headers: {
        "Content-Type": "multipart/form-data",
      },
    });
    await fetchSecondary(selectedOrganization, secondaryId);
  }

  async function deleteSellerDocument(sellerDocumentId) {
    const newSellerDocuments = secondary.sellerDocuments.filter((item) => {
      return item.documentId !== sellerDocumentId;
    });

    await setDoc(
      doc(
        db,
        "organizations",
        selectedOrganization,
        "organizations_private",
        "data",
        "secondaries",
        secondaryId
      ),
      { sellerDocuments: newSellerDocuments },
      { merge: true }
    );
    await fetchSecondary(selectedOrganization, secondaryId);
  }

  let statusText;
  let badgeColor;
  if (
    [
      "active.setup",
      "active.automated_matching",
      "active.ordering.buyer",
    ].includes(secondary.status)
  ) {
    statusText = "Set-Up Phase";
    badgeColor = "gray-300";
  } else if (secondary.status === "active.ordering.seller") {
    statusText = "Offer Period Open";
    badgeColor = "green";
  } else {
    statusText = "Offer Period Ended";
    badgeColor = "gray-300";
  }

  return (
    <>
      <Header6 />
      <div className="content-body section-padding">
        <div className="container">
          <div className="d-flex">
            <div className="me-4" style={{ minWidth: "220px" }}>
              <ManageTendersNav secondaryId={secondaryId} />
            </div>
            <div className="col-xl-10">
              <div className="flex-column">
                <div className="card outlined">
                  <div className="card-header">
                    <h4 className="card-title">{secondary.name}</h4>
                    <div
                      className={"pill p-2 text-center"}
                      style={{
                        fontSize: 13,
                        background: `var(--${badgeColor})`,
                      }}
                    >
                      {statusText}
                    </div>
                  </div>
                  <TenderDetails
                    secondary={secondary}
                    secondaryId={secondaryId}
                  />
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
}

function TenderDetails(props) {
  const { secondary, secondaryId } = props;
  const [remaining, setRemaining] = useState();

  useEffect(() => {
    const endDate = secondary?.endDate?.seconds
      ? new Date(secondary?.endDate?.seconds * 1000)
      : null;
    if (!endDate) return;
    const calculateRemaining = () => {
      const now = new Date();

      // Don't show time remaining if start date is in the future
      if (
        secondary?.startDate?.seconds &&
        new Date(secondary.startDate.seconds * 1000) > now
      ) {
        return;
      }

      const days = differenceInDays(endDate, now);
      const hours = differenceInHours(endDate, now);
      const minutes = differenceInMinutes(endDate, now);
      const seconds = differenceInSeconds(endDate, now);
      const duration = formatDuration(
        {
          days,
          hours: hours - 24 * days,
          minutes: minutes - 60 * hours,
          seconds: seconds - 60 * minutes,
        },
        { delimiter: "," }
      )
        .split(",")
        .slice(0, 2)
        .join(", ");
      setRemaining(seconds > 0 ? duration : "0 seconds");
    };
    calculateRemaining();
    const timer = setInterval(() => {
      calculateRemaining();
    }, 1000);
    return () => clearInterval(timer);
  }, [secondary.endDate]);

  if (!secondary.startDate) {
    return <div></div>;
  }

  const sellableLimit = secondary.sellableRatioLimit.toLocaleString(undefined, {
    style: "percent",
  });

  return (
    <div>
      <div className="my-4"></div>
      <div style={{ paddingLeft: 8, marginBottom: 8 }}>
        <div className="row">
          {secondary.startDate && (
            <Detail
              title="Tender commencement"
              icon={regular("calendar-range")}
            >
              {new Date(secondary.startDate.seconds * 1000).toLocaleString(
                "en-US",
                { timeZone: "America/Los_Angeles", timeZoneName: "short" }
              )}
            </Detail>
          )}

          {secondary.endDate && (
            <Detail title="Tender expiration" icon={regular("calendar-check")}>
              {new Date(secondary.endDate.seconds * 1000).toLocaleString(
                "en-US",
                { timeZone: "America/Los_Angeles", timeZoneName: "short" }
              )}
            </Detail>
          )}
          {remaining && (
            <Detail title="Time left" icon={regular("stopwatch")}>
              {remaining} remaining
            </Detail>
          )}
          <Detail title="Total offer amount" icon={regular("sack-dollar")}>
            $50,000,000
          </Detail>
          <Detail title="Price per unit" icon={regular("money-bill")}>
            {secondary.pricePerShareInBaseUnits
              ? formatPrice(
                  secondary.pricePerShareInBaseUnits,
                  secondary.pricePerSharePrecision
                )
              : "-"}
          </Detail>
          <Detail title="Eligible holders" icon={regular("user-group")}>
            10
          </Detail>
          <Detail title="Sellable limit" icon={regular("percent")}>
            {sellableLimit} of vested units
          </Detail>
        </div>
      </div>
    </div>
  );
}

function TrackingSection({ organizationId, secondaryId, secondaryOrders }) {
  let roundVisitsSub;
  const db = getFirestore();
  const [visits, setVisits] = useState([]);
  const [groupedVisits, setGroupedVisits] = useState([]);

  useEffect(() => {
    fetchVisits();
  }, [secondaryId, organizationId]);

  async function fetchVisits() {
    if (!secondaryId || !organizationId) {
      return;
    }
    if (!roundVisitsSub) {
      roundVisitsSub = onSnapshot(
        doc(
          db,
          "organizations",
          organizationId,
          "organizations_private",
          "data",
          "secondaries",
          secondaryId,
          "visits",
          "summary"
        ),
        (doc) => {
          if (doc.exists && doc.data() && "visits" in doc.data()) {
            setVisits(doc.data().visits.reverse());
          }
        }
      );
    }
  }

  useEffect(() => {
    if (visits.length === 0) {
      return;
    }
    let grouped = Object.entries(
      visits.reduce(function (r, a) {
        r[a.e] = r[a.e] || [];
        r[a.e].push(a);
        return r;
      }, Object.create(null))
    );

    grouped = grouped.filter((e) => e[0] !== "");
    setGroupedVisits(grouped);
  }, [visits]);

  return (
    <>
      <Divider style={{ left: 48, right: 48 }} />
      <div className="mt-4">
        <div className="d-flex">
          <div className={"mb-2 mt-3"} style={{ flexGrow: 1 }}>
            <h4>Sellers</h4>
          </div>

          <Link to={`/org/secondaries/manage/${secondaryId}/visits`}>
            <div style={{ flexDirection: "row", display: "flex" }}>
              <div className={`forward-button d-flex`}>
                <div style={{ marginTop: 16 }}>
                  <span style={{ marginRight: 6 }}>Detailed analytics</span>
                  <i className="la la-angle-right" style={{ fontSize: 14 }}></i>
                </div>
              </div>
            </div>
          </Link>
        </div>

        {groupedVisits.length === 0 && (
          <div className="py-4">No sellers have viewed documents yet.</div>
        )}

        {groupedVisits.length > 0 && (
          <>
            <TableContainer
              style={{
                border: "1px solid var(--purple-light  )",
                borderRadius: 8,
              }}
            >
              <Table aria-label="simple table">
                <TableHead>
                  <TableRow>
                    <TableCell>Email</TableCell>
                    <TableCell align="right">Updated</TableCell>
                    <TableCell align="right">Views</TableCell>
                    <TableCell align="right">Status</TableCell>
                  </TableRow>
                </TableHead>
                <TableBody>
                  {groupedVisits.map((e) => {
                    const existingOrder = secondaryOrders.find((order) => {
                      return order.email === e[0] && order.status === "open";
                    });
                    let link;
                    if (existingOrder) {
                      link = `/org/secondaries/manage/${secondaryId}/orders/${existingOrder.id}`;
                    }

                    return (
                      <TableRow key={e[0]}>
                        <TableCell>
                          <h6>{e[0]}</h6>
                        </TableCell>

                        <TableCell align="right" style={{ padding: "24px" }}>
                          {moment(new Date(e[1][0].d)).format("M/D, h:mm:ss a")}
                        </TableCell>
                        <TableCell align="right">
                          <span>{e[1].length}</span>
                        </TableCell>
                        <TableCell align="right">
                          <span>
                            {existingOrder ? (
                              <MSButton
                                component={Link}
                                to={link}
                                size="small"
                                startIcon={
                                  <FontAwesomeIcon icon={regular("eye")} />
                                }
                              >
                                Submitted
                              </MSButton>
                            ) : (
                              "Viewed"
                            )}
                          </span>
                        </TableCell>
                      </TableRow>
                    );
                  })}
                </TableBody>
              </Table>
            </TableContainer>
          </>
        )}
      </div>
    </>
  );
}

function SellerDocuments({
  deleteSellerDocument,
  sellerDocuments,
  uploadSellerDocument,
}) {
  const { user } = useContext(UserContext);
  const [documentDeleteId, setDocumentDeleteId] = useState();
  return (
    <>
      <div className="py-4 mb-2">
        <div className="d-flex">
          <div className="flex-grow-1">
            <span style={{ fontWeight: 600 }}>
              Offer Documents and Disclosures
            </span>
          </div>
          <div className="d-flex justify-content-center align-items-center">
            <MSButton
              onClick={() => {
                document.getElementById("seller-document-upload").click();
              }}
              size="small"
              title="Upload document"
              startIcon={<FontAwesomeIcon icon={regular("upload")} />}
            />
            <input
              id="seller-document-upload"
              type="file"
              style={{ display: "none" }}
              onChange={(e) => {
                uploadSellerDocument(e);
              }}
            />
          </div>
        </div>

        {sellerDocuments.length > 0 && (
          <FilesViewer
            files={sellerDocuments}
            user={user}
            editMode
            onDelete={setDocumentDeleteId}
          />
        )}
      </div>

      <Dialog
        open={!!documentDeleteId}
        onClose={() => {
          setDocumentDeleteId();
        }}
        aria-labelledby="alert-dialog-title"
        aria-describedby="alert-dialog-description"
      >
        <div className="p-4">
          <h3>Delete this deck?</h3>
          <p className="mb-0">
            Deleting this deck will also delete the analytics with it.
          </p>
        </div>

        <DialogActions className="me-3 mb-3">
          <MSButton
            title="Cancel"
            onClick={() => {
              setDocumentDeleteId();
            }}
            size="small"
          >
            Cancel
          </MSButton>
          <MSButton
            title="Delete"
            variant="danger"
            onClick={() => {
              setDocumentDeleteId();
              deleteSellerDocument(documentDeleteId);
            }}
            size="small"
            autoFocus
          >
            Delete
          </MSButton>
        </DialogActions>
      </Dialog>
    </>
  );
}
