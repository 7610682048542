import { getAuth, sendEmailVerification } from "firebase/auth";
import { post } from "jsx/api";
import { UserContext } from "jsx/contexts/user-context";
import MSButton from "jsx/element/button";
import React, { useState } from "react";
import { Link } from "react-router-dom";

function VerifyEmail({ location, type, isSignin }) {
  const { user } = React.useContext(UserContext);

  const [isVerificationSent, setVerificationSent] = useState(false);

  function sendVerificationEmail() {
    const auth = getAuth();
    sendEmailVerification(auth.currentUser).then(() => {
      setVerificationSent(true);
    });
  }

  return (
    <>
      <div className="auth-form card">
        <div className="card-header">
          <h3>Verify your email</h3>
          <MSButton
            type="outlined light small"
            title="Debug skip"
            hidden={!window.location.href.includes("localhost")}
            onClick={async () => {
              await post("users/debug", { action: "verifyEmail" });
            }}
          />
        </div>
        <div
          className="card-body"
          style={{ paddingTop: 32, paddingBottom: 16 }}
        >
          <p>
            Please check <span style={{ fontWeight: "500" }}>{user.email}</span>{" "}
            for a link to verify your email address.
          </p>
          <p>After you have verified your email address, refresh the page.</p>
        </div>

        <MSButton
          size="large"
          onClick={sendVerificationEmail}
          title={isVerificationSent ? "Sent" : "Re-send verification email"}
          disabled={isVerificationSent}
          variant="secondary"
        />
        <Link
          style={{ margin: "0 auto", fontSize: 16, marginTop: 16 }}
          to={"/logout"}
        >
          Logout
        </Link>
      </div>
    </>
  );
}

export default VerifyEmail;
