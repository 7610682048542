import Lottie from "lottie-react";
import React from "react";

import bulb from "../element/bulb.json";

export default function Loader() {
  return (
    <div
      style={{
        display: "flex",
        alignItems: "center",
        justifyContent: "center",
        height: "100vh",
      }}
    >
      <Lottie
        animationData={bulb}
        loop={true}
        style={{ width: "200px", height: "200px" }}
      />
    </div>
  );
}
