import SettingsNav from "jsx/element/settings-nav";
import Header6 from "jsx/layout/header6";
import CompanyInfoForm from "jsx/pages/company/company-info-form";
import React from "react";

function CompanyEditInfo(props) {
  return (
    <>
      <Header6 />
      <div className="content-body section-padding">
        <div className="container">
          <div className="d-flex">
            <div className="me-4" style={{ "min-width": "220px" }}>
              <SettingsNav />
            </div>
            <div className="col-xl-10">
              <CompanyInfoForm />
            </div>
          </div>
        </div>
      </div>
    </>
  );
}

export default CompanyEditInfo;
