import { getFirestore } from "@firebase/firestore";
import { Skeleton } from "@mui/material";
import { Field, Form, Formik } from "formik";
import { post } from "jsx/api";
import states from "jsx/data/states.json";
import SecondaryDocumentRow from "jsx/element/secondary-document-row";
import Header4 from "jsx/layout/header4";
import React, { useEffect, useState } from "react";
import * as Yup from "yup";

import SigningSection from "./secondaries-signing-section";
import WaitingForSignatures from "./secondaries-waiting-for-secondaries";

function CompanySecondariesSignBuyer({ match, location }) {
  const [document, setDocument] = useState();
  const [transaction, setTransaction] = useState();

  const queryParams = new URLSearchParams(location.search.replace("?", ""));
  const token = queryParams.get("t");

  const { organizationId, secondaryId, transactionId, documentId } =
    match.params;

  const db = getFirestore();

  async function fetchSigningPacket(
    organizationId,
    secondaryId,
    transactionId
  ) {
    const params = {
      organizationId: organizationId,
      secondaryId: secondaryId,
      transactionId: transactionId,
      documentId: documentId,
      token: token,
    };

    const result = await post(
      "secondaries/getSignaturePacketWithToken",
      params
    );

    setDocument(result.data.document);
    setTransaction(result.data.transaction);
  }

  async function handleSubmit(signatureImage, values) {
    const {
      signerName,
      signerTitle,
      foreignSigner,
      signerStreetAddress,
      signerCity,
      signerState,
      signerZipcode,
      signerAddressLine1,
      signerAddressLine2,
      isPreview,
    } = values;

    const params = {
      signerParty: "buyer",
      organizationId: organizationId,
      secondaryId: secondaryId,
      transactionId: transactionId,
      documentId: documentId,
      token: token,
      signature: signatureImage,
      signerName: signerName,
      signerTitle: signerTitle,
      foreignSigner: foreignSigner,
      signerAddressLine1: signerAddressLine1,
      signerAddressLine2: signerAddressLine2,
      signerStreetAddress: signerStreetAddress,
      signerCity: signerCity,
      signerState: signerState,
      signerZipcode: signerZipcode,
      isPreview: isPreview,
    };

    const result = await post(`signTransferDocWithToken`, params);

    if (isPreview) {
      const uri = "data:pdf;base64," + result.data;

      var downloadLink = window.document.createElement("a");
      downloadLink.href = uri;
      downloadLink.download = "DRAFT.docx";

      window.document.body.appendChild(downloadLink);
      downloadLink.click();
      window.document.body.removeChild(downloadLink);
      return;
    }

    fetchSigningPacket(organizationId, secondaryId, transactionId);
    if (token) {
      await new Promise((resolve) => setTimeout(resolve, 15000));
      fetchSigningPacket(organizationId, secondaryId, transactionId);
      await new Promise((resolve) => setTimeout(resolve, 30000));
      fetchSigningPacket(organizationId, secondaryId, transactionId);
      await new Promise((resolve) => setTimeout(resolve, 60000));
      fetchSigningPacket(organizationId, secondaryId, transactionId);
      await new Promise((resolve) => setTimeout(resolve, 120000));
      fetchSigningPacket(organizationId, secondaryId, transactionId);
    }
  }

  useEffect(() => {
    fetchSigningPacket(organizationId, secondaryId, transactionId);
  }, [organizationId, secondaryId, transactionId]);

  const isLoaded = transaction && document;

  const documentPreview = (
    <SecondaryDocumentRow
      prefetchedDocument={document}
      organizationId={organizationId}
      secondaryId={secondaryId}
      transactionId={transactionId}
      documentId={documentId}
      showSigning={false}
      token={token}
    />
  );

  return (
    <>
      <Header4 hideAllMenuItems />

      <div className="content-body section-padding">
        <div className="container">
          <div className="row justify-content-center align-items-center">
            <div className="col-9">
              <div className="card outlined">
                {!isLoaded && (
                  <>
                    <Skeleton height={80} />
                    <Skeleton height={240} />
                    <Skeleton height={80} />
                    <Skeleton height={80} />
                    <Skeleton height={240} />
                    <Skeleton height={80} />
                    <Skeleton height={80} />
                  </>
                )}

                {isLoaded &&
                  (document.voided ? (
                    <Voided documentPreviewSection={documentPreview} />
                  ) : document.isCompleted ? (
                    <Completed documentPreviewSection={documentPreview} />
                  ) : document.isSignedByBuyer ? (
                    <WaitingForSignatures
                      isWaiting={document.isSignedByBuyer}
                      transaction={transaction}
                      documentPreviewSection={documentPreview}
                    />
                  ) : (
                    <SigningForm
                      transaction={transaction}
                      initialValues={
                        // TODO: Pre-fill values with stored data
                        {
                          signerName: "",
                          foreignSigner: false,
                          signerStreetAddress: "",
                          signerCity: "",
                          signerState: "",
                          signerZipcode: "",
                          signerTitle: "",
                          signerAddressLine1: "",
                          signerAddressLine2: "",
                        }
                      }
                      documentPreviewSection={documentPreview}
                      onSubmit={handleSubmit}
                    />
                  ))}
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
}

function Completed({ documentPreviewSection }) {
  return (
    <>
      <div className="card-header">
        <h4 className="card-title mb-3">Completed</h4>
      </div>
      <div className="card-body">
        {/* TODO: Add copy */}
        {documentPreviewSection}
      </div>
    </>
  );
}

function Voided({ documentPreviewSection }) {
  return (
    <>
      <div className="card-header">
        <h4 className="card-title">This document has been voided</h4>
      </div>
      <div className="card-body">
        <p className="mb-2">
          This document has been voided and is no longer available for signing
        </p>
        {documentPreviewSection}
      </div>
    </>
  );
}

const SignSchema = Yup.object().shape({
  signerName: Yup.string().required("Enter your name"),
  signerTitle: Yup.string().required("Required"),

  signerStreetAddress: Yup.string().when("foreignSigner", {
    is: false,
    then: Yup.string().required("Required"),
  }),

  signerCity: Yup.string().when("foreignSigner", {
    is: false,
    then: Yup.string().required("Required"),
  }),
  signerState: Yup.string().when("foreignSigner", {
    is: false,
    then: Yup.string().required("Required"),
  }),
  signerZipcode: Yup.string().when("foreignSigner", {
    is: false,
    then: Yup.string().required("Required"),
  }),

  signerAddressLine1: Yup.string().when("foreignSigner", {
    is: true,
    then: Yup.string().required("Required"),
  }),
  signerAddressLine2: Yup.string().when("foreignSigner", {
    is: true,
    then: Yup.string().required("Required"),
  }),
});

function SigningForm({
  transaction,
  initialValues,
  documentPreviewSection,
  onSubmit,
}) {
  const [signatureImage, setSignatureImage] = useState();
  const [isError, setError] = useState(false);
  const [isPreviewing, setIsPreviewing] = useState(false);
  const [isSigning, setIsSigning] = useState(false);

  const pricePerShare =
    transaction.pricePerShareInBaseUnits / transaction.pricePerSharePrecision;
  const pricePerShareFormat = {
    style: "currency",
    currency: "USD",
    minimumFractionDigits: Math.max(
      2,
      Math.log10(transaction.pricePerSharePrecision)
    ),
  };

  const totalAmount = pricePerShare * transaction.quantity;

  return (
    <Formik
      enableReinitialize
      initialValues={initialValues}
      validationSchema={SignSchema}
      onSubmit={async (values) => {
        if (!signatureImage) {
          setError(true);
          return;
        }

        if (values.isPreview) {
          setIsPreviewing(true);
        } else {
          setIsSigning(true);
        }

        await onSubmit(signatureImage, values);
        setIsPreviewing(false);
        setIsSigning(false);
      }}
    >
      {({
        values,
        setFieldValue,
        setFieldTouched,
        submitForm,
        errors,
        touched,
      }) => (
        <Form>
          <div className="card-header" style={{ display: "block" }}>
            <h2>Sign Documents</h2>
            <p>Sign documents to formally accept this proposed transfer.</p>
          </div>
          <div className="card-body">
            <div className="row">
              {/* Review section */}
              <h4 className="form-section-header mt-0">Review terms</h4>
              <h6 className="form-label">
                I acknowledge this stock transfer at the terms below:
              </h6>
              <ul className="investment-overview ms-4 mt-1">
                {transaction && (
                  <>
                    <li>
                      <strong>Certificate Number </strong>
                      {transaction.securityDetails.certificateNo}
                    </li>
                    <li>
                      <strong>Company </strong>
                      {transaction.companyName}
                    </li>
                    <li>
                      <strong>Seller </strong>
                      {transaction.sellerName}
                    </li>
                    <li>
                      <strong>Buyer </strong>
                      {transaction.buyerName}
                    </li>
                    <li>
                      <strong>Share class </strong>
                      {
                        // TODO: grab this from the transaction once it gets added
                        "Common Stock"
                      }
                    </li>
                    <li>
                      <strong>Number of shares </strong>
                      {transaction.quantity.toLocaleString()}
                    </li>
                    <li>
                      <strong>Price per share </strong>
                      {pricePerShare.toLocaleString(
                        undefined,
                        pricePerShareFormat
                      )}
                    </li>

                    <li>
                      <strong>Total amount </strong>
                      {totalAmount.toLocaleString(undefined, {
                        style: "currency",
                        currency: "USD",
                      })}
                    </li>
                  </>
                )}
              </ul>

              {/* Signature block section */}
              <h4 className="form-section-header mt-4">Signature Block</h4>
              <div className="mb-0 col-xl-12">
                <h6 className="form-label">What is your full name?</h6>
                <Field
                  type="text"
                  name="signerName"
                  className={
                    "form-control " +
                    (touched.signerName && errors.signerName && "is-invalid")
                  }
                  placeholder="Firstname Lastname"
                  autoComplete="off"
                  autoCorrect="off"
                  autoCapitalize="off"
                  spellCheck="false"
                />
              </div>
              <div className="mb-0 col-xl-12">
                <h6 className="form-label">What is your title?</h6>
                <Field
                  type="text"
                  name="signerTitle"
                  className={
                    "form-control " +
                    (touched.signerTitle && errors.signerTitle && "is-invalid")
                  }
                  placeholder="General Partner"
                  autoComplete="off"
                  autoCorrect="off"
                  autoCapitalize="off"
                  spellCheck="false"
                />
              </div>

              <h4 className="form-section-header mt-4">Address block</h4>

              <div className="mb-0 col-xl-12">
                <div className="form-check mt-4 mb-0">
                  <label>
                    <Field
                      className="form-check-input"
                      type="checkbox"
                      name="foreignSigner"
                    />
                    I am a non-US based investor.
                  </label>
                </div>
              </div>

              {!values.foreignSigner && (
                <>
                  <div className="mb-0 col-xl-12">
                    <h6 className="form-label">Address</h6>
                    <Field
                      type="text"
                      name="signerStreetAddress"
                      className={
                        "form-control " +
                        (touched.signerStreetAddress &&
                          errors.signerStreetAddress &&
                          "is-invalid")
                      }
                      placeholder="123 Main Street"
                      autoComplete="off"
                      autoCorrect="off"
                      autoCapitalize="off"
                      spellCheck="false"
                    />
                  </div>

                  <div className="mb-0 col-xs-2 col-sm-5">
                    <h6 className="form-label">City</h6>
                    <Field
                      type="text"
                      name="signerCity"
                      className={
                        "form-control " +
                        (touched.signerCity &&
                          errors.signerCity &&
                          "is-invalid")
                      }
                      placeholder="City"
                      autoCorrect="off"
                      autoComplete="off"
                      spellCheck="false"
                    />
                  </div>
                  <div className="mb-0 col-xs-2 col-sm-3">
                    <h6 className="form-label">State</h6>
                    <Field
                      type="text"
                      name="signerState"
                      className={
                        "form-select " +
                        (touched.signerState &&
                          errors.signerState &&
                          "is-invalid")
                      }
                      component="select"
                      placeholder="State"
                      defaultValue=""
                    >
                      <option value="" disabled hidden>
                        State
                      </option>
                      {states.map((item) => {
                        return (
                          <option
                            key={item.abbreviation}
                            value={item.abbreviation}
                          >
                            {item.abbreviation}
                          </option>
                        );
                      })}
                    </Field>
                  </div>
                  <div className="mb-0 col-xs-12 col-sm-4">
                    <h6 className="form-label">Zipcode</h6>
                    <Field
                      type="text"
                      name="signerZipcode"
                      className={
                        "form-control " +
                        (touched.signerZipcode &&
                          errors.signerZipcode &&
                          "is-invalid")
                      }
                      placeholder="Zipcode"
                    />
                  </div>
                </>
              )}

              {values.foreignSigner && (
                <>
                  <div className="mb-0 col-xl-12">
                    <h6 className="form-label">Address Line 1</h6>
                    <Field
                      type="text"
                      name="signerAddressLine1"
                      className={
                        "form-control " +
                        (touched.signerAddressLine1 &&
                          errors.signerAddressLine1 &&
                          "is-invalid")
                      }
                      placeholder="Street Address"
                      autoComplete="off"
                      autoCorrect="off"
                      autoCapitalize="off"
                      spellCheck="false"
                    />
                  </div>
                  <div className="mb-0 col-xl-12">
                    <h6 className="form-label">Address Line 2</h6>
                    <Field
                      type="text"
                      name="signerAddressLine2"
                      className={
                        "form-control " +
                        (touched.signerAddressLine2 &&
                          errors.signerAddressLine2 &&
                          "is-invalid")
                      }
                      placeholder="City, Postal, Country"
                      autoComplete="off"
                      autoCorrect="off"
                      autoCapitalize="off"
                      spellCheck="false"
                    />
                  </div>
                </>
              )}

              <SigningSection
                documentPreviewSection={documentPreviewSection}
                isError={isError}
                signatureImage={signatureImage}
                setSignatureImage={setSignatureImage}
                isSigning={isSigning}
                isPreviewing={isPreviewing}
                submitForm={() => {
                  setFieldValue("isPreview", false);
                  submitForm();
                }}
                downloadPreview={() => {
                  setFieldValue("isPreview", true);
                  submitForm();
                }}
              />
            </div>
          </div>
        </Form>
      )}
    </Formik>
  );
}

export default CompanySecondariesSignBuyer;
