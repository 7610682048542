import ClassicEditor from "@ckeditor/ckeditor5-build-classic";
import { CKEditor } from "@ckeditor/ckeditor5-react";
import { doc, getDoc, getFirestore, setDoc } from "@firebase/firestore";
import { regular } from "@fortawesome/fontawesome-svg-core/import.macro";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { Box, Tab, Tabs, Typography } from "@mui/material";
import { get } from "jsx/api";
import { UserContext } from "jsx/contexts/user-context";
import MSButton from "jsx/element/button";
import ManageTendersNav from "jsx/element/manage-tenders-nav";
import Header6 from "jsx/layout/header6";
import React, { useContext, useState } from "react";
import { useEffect } from "react";
import { Link } from "react-router-dom";

export default function CompanyTenderManageCommunications({ match }) {
  const { secondaryId } = match.params;
  const [editMode, setEditMode] = useState(false);
  const [commsData, setCommsData] = useState([]);
  const { selectedOrganization } = useContext(UserContext);
  const [editingDataBuffer, setEditingDataBuffer] = useState();

  const [value, setValue] = React.useState(0);
  // const [value, setValue] = React.useState(commsData[0].title);
  const db = getFirestore();

  const handleChange = (event, newValue) => {
    setValue(newValue);
  };

  useEffect(() => {
    if (!commsData || !commsData.length) {
      return;
    }

    setEditingDataBuffer(commsData[value].content.replaceAll("\n", "<br />"));
  }, [commsData, value]);

  async function save() {
    // update data
    const newData = commsData;
    newData[value].content = editingDataBuffer;

    setCommsData(newData);
    setEditMode(false);

    await setDoc(
      doc(
        db,
        `organizations/${selectedOrganization}/organizations_private/data/secondaries/${secondaryId}/comms/data`
      ),
      { commsData },
      { merge: true }
    );
  }

  useEffect(() => {
    fetchComms();
  }, []);

  async function fetchComms() {
    try {
      const req = await get("secondaries/getFAQs", {
        secondaryId,
        organizationId: selectedOrganization,
      });

      if (req.data && req.data.comms) {
        setCommsData(req.data.comms);
      }
    } catch (e) {}
  }

  return (
    <>
      <Header6 />

      <div className="content-body section-padding">
        <div className="container">
          <div className="d-flex">
            <div className="me-4" style={{ minWidth: "220px" }}>
              <ManageTendersNav secondaryId={secondaryId} />
            </div>
            <div className="col-xl-10">
              <div className="flex-column">
                <div className="card outlined">
                  <div className="card-header">
                    <h4 className="card-title">Communications</h4>
                    <div style={{ flexGrow: 1 }} />
                    {!editMode && (
                      <>
                        <MSButton
                          component={Link}
                          variant="secondary"
                          to={`/org/tenders/manage/${secondaryId}/communications`}
                          title="Edit"
                          size="small"
                          startIcon={<FontAwesomeIcon icon={regular("edit")} />}
                          onClick={() => {
                            setEditMode(true);
                          }}
                        />
                      </>
                    )}
                    {editMode && (
                      <>
                        <MSButton
                          component={Link}
                          to={`/org/tenders/manage/${secondaryId}/communications`}
                          title="Cancel"
                          size="small"
                          onClick={() => {
                            setEditMode(false);
                          }}
                          style={{ marginRight: 16 }}
                        />
                        <MSButton
                          component={Link}
                          variant="secondary"
                          to={`/org/tenders/manage/${secondaryId}/communications`}
                          title="Save"
                          size="small"
                          startIcon={<FontAwesomeIcon icon={regular("save")} />}
                          onClick={() => {
                            save();
                          }}
                        />
                      </>
                    )}
                  </div>

                  <div className="card-body">
                    <Box
                      sx={{
                        flexGrow: 1,
                        bgcolor: "background.paper",
                        display: "flex",
                      }}
                    >
                      <Tabs
                        orientation="vertical"
                        centered={false}
                        variant="standard"
                        value={value}
                        onChange={handleChange}
                        sx={{
                          borderRight: 1,
                          borderColor: "divider",
                          flexBasis: "30%",
                          flexShrink: 0,
                        }}
                      >
                        <h5 className="mt-2">General</h5>
                        {commsData.slice(0, 1).map((comm, i) => (
                          <Tab
                            key={comm.title}
                            label={comm.title}
                            value={i}
                            sx={{
                              alignSelf: "stretch",
                              textAlign: "left",
                              alignItems: "start",
                              textTransform: "none",
                            }}
                          />
                        ))}
                        <h5 className="mt-2">Emails</h5>
                        {commsData.slice(1).map((comm, i) => (
                          <Tab
                            key={comm.title}
                            label={comm.title}
                            value={i + 1}
                            sx={{
                              alignSelf: "stretch",
                              textAlign: "left",
                              alignItems: "start",
                              textTransform: "none",
                            }}
                          />
                        ))}
                      </Tabs>

                      {commsData.map((comm, i) => (
                        <TabPanel
                          key={comm.title}
                          value={value}
                          k={i}
                          index={i}
                        >
                          {editMode && (
                            <div style={{ width: "110%" }}>
                              <CKEditor
                                editor={ClassicEditor}
                                config={{
                                  toolbar: [
                                    "heading",
                                    "|",
                                    "bold",
                                    "italic",
                                    "link",
                                    "bulletedList",
                                    "numberedList",
                                    "blockQuote",
                                    "|",
                                    "undo",
                                    "redo",
                                  ],
                                }}
                                data={comm.content.replaceAll("\n", "<br />")}
                                onReady={(editor) => {}}
                                onChange={(event, editor) => {
                                  const data = editor.getData();
                                  setEditingDataBuffer(data);
                                }}
                              />
                            </div>
                          )}
                          {!editMode && (
                            <div
                              dangerouslySetInnerHTML={{
                                __html: comm.content.replaceAll("\n", "<br />"),
                              }}
                            />
                          )}
                        </TabPanel>
                      ))}
                    </Box>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
}

function TabPanel(props) {
  const { children, value, index, k, ...other } = props;

  return (
    <div
      role="tabpanel"
      hidden={value !== k}
      id={`vertical-tabpanel-${index}`}
      aria-labelledby={`vertical-tab-${index}`}
    >
      {value === k && <Box sx={{ p: 3, pt: 1 }}>{children}</Box>}
    </div>
  );
}
