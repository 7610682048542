import LoadingButton from "@mui/lab/LoadingButton";
import CircularProgress from "@mui/material/CircularProgress";
import IconButton from "@mui/material/IconButton";
import { styled } from "@mui/material/styles";
import * as React from "react";

const PrimaryButton = styled(LoadingButton)({
  borderRadius: "4px",
  fontWeight: "500",
  backgroundColor: "var(--primary)",
  color: "var(--white)",
  transition: "background 0.2s",
  textTransform: "none",
  boxShadow: "0px 20px 25px rgba(22, 28, 45, 0.05)",

  borderColor: "#0063cc",
  fontFamily: [
    "Montserrat",
    "-apple-system",
    "BlinkMacSystemFont",
    '"Segoe UI"',
    "Roboto",
    '"Helvetica Neue"',
    "Arial",
    "sans-serif",
    '"Apple Color Emoji"',
    '"Segoe UI Emoji"',
    '"Segoe UI Symbol"',
  ].join(","),

  "&:hover": {
    boxShadow: "0px 0px",
  },
  "&:active": {
    boxShadow: "0px 0px",
  },
  "&:focus": {
    boxShadow: "0px 0px",
  },
});

const SecondaryButton = styled(LoadingButton)({
  textTransform: "none",

  fontFamily: [
    "Montserrat",
    "-apple-system",
    "BlinkMacSystemFont",
    '"Segoe UI"',
    "Roboto",
    '"Helvetica Neue"',
    "Arial",
    "sans-serif",
    '"Apple Color Emoji"',
    '"Segoe UI Emoji"',
    '"Segoe UI Symbol"',
  ].join(","),
});

const RegularButton = styled(LoadingButton)({
  textTransform: "none",
  borderColor: "var(--border-color)",
  color: "#525b66",

  "&:hover": {
    borderColor: "#CCC",
  },

  fontFamily: [
    "Montserrat",
    "-apple-system",
    "BlinkMacSystemFont",
    '"Segoe UI"',
    "Roboto",
    '"Helvetica Neue"',
    "Arial",
    "sans-serif",
    '"Apple Color Emoji"',
    '"Segoe UI Emoji"',
    '"Segoe UI Symbol"',
  ].join(","),
});

const MSIconButton = styled(IconButton)({
  border: "1px solid var(--border-color) !important",
  borderRadius: "4px !important",
  color: "#666 !important",
});

export default function MSButton({
  children,
  title,
  variant,
  loadingLabel,
  endIcon,
  ...props
}) {
  let buttonLabel;

  if (loadingLabel && props.loading) {
    buttonLabel = loadingLabel;
  } else {
    buttonLabel = children ?? title;
  }

  if (variant === "primary") {
    return (
      <PrimaryButton
        disableRipple
        loadingPosition="end"
        variant="contained"
        endIcon={endIcon ?? <></>}
        loadingIndicator={<CircularProgress color="inherit" size="1rem" />}
        {...props}
      >
        <div className={props.loading ? "me-2" : ""}>{buttonLabel}</div>
      </PrimaryButton>
    );
  } else if (variant === "secondary") {
    return (
      <SecondaryButton
        disableRipple
        variant="outlined"
        loadingPosition="end"
        endIcon={endIcon ?? <></>}
        loadingIndicator={<CircularProgress color="inherit" size="1rem" />}
        {...props}
      >
        <div className={props.loading ? "me-2" : ""}>{buttonLabel}</div>
      </SecondaryButton>
    );
  } else if (variant === "danger") {
    return (
      <SecondaryButton
        disableRipple
        variant="outlined"
        loadingPosition="end"
        endIcon={endIcon ?? <></>}
        loadingIndicator={<CircularProgress color="inherit" size="1rem" />}
        style={{
          borderColor: "var(--danger)",
          color: "var(--danger)",
        }}
        {...props}
      >
        <div className={props.loading ? "me-2" : ""}>{buttonLabel}</div>
      </SecondaryButton>
    );
  } else if (variant === "icon") {
    return (
      <MSIconButton disableRipple {...props}>
        {children}
      </MSIconButton>
    );
  } else {
    return (
      <RegularButton
        disableRipple
        variant="outlined"
        loadingPosition="end"
        endIcon={endIcon ?? <></>}
        loadingIndicator={<CircularProgress color="inherit" size="1rem" />}
        {...props}
      >
        <div className={props.loading ? "me-2" : ""}>{buttonLabel}</div>
      </RegularButton>
    );
  }
}
