import { collection, getDocs, getFirestore } from "firebase/firestore";
import { UserContext } from "jsx/contexts/user-context";
import PageTitle from "jsx/element/page-title";
import Sidebar from "jsx/layout/company-sidebar";
import Footer2 from "jsx/layout/footer2";
import Header2 from "jsx/layout/header2";
import React, { useEffect, useState } from "react";
import { Link, useHistory } from "react-router-dom";

function Contracts() {
  const [contracts, setContracts] = useState([]);
  const history = useHistory();

  const { selectedOrganization } = React.useContext(UserContext);

  useEffect(() => {
    const ret = [];
    const db = getFirestore();

    async function fetchData() {
      const querySnapshot = await getDocs(
        collection(db, "companies", selectedOrganization, "contracts")
      );
      querySnapshot.forEach((doc) => {
        ret.push(
          <tr
            style={{ cursor: "pointer" }}
            key={doc.id}
            onClick={(event) => {
              if (event.metaKey || event.ctrlKey) {
                const win = window.open("contracts/" + doc.id, "_blank");
                win.focus();
              } else {
                history.push("contracts/" + doc.id);
              }
            }}
          >
            <td className="coin_icon">
              <span>{doc.data().name}</span>
            </td>

            <td>$19.30</td>
            <td>
              <span className="text-success">+1.13%</span>
            </td>
            <td>
              {" "}
              <span className="sparkline8"></span>
            </td>
          </tr>
        );

        setContracts(ret);
      });
    }

    fetchData();
  });

  return (
    <>
      <Header2 />
      <Sidebar />
      <PageTitle />

      <div className="content-body">
        <div className="container-fluid">
          <div className="row">
            <div className="col-xl-12">
              <div className="card">
                <div className="card-header border-0">
                  <h4 className="card-title">Your Contracts</h4>
                  <Link to={"./contracts/create/rules"}>
                    <button className="btn btn-success waves-effect">
                      Create new offering
                    </button>
                  </Link>
                </div>
                <div className="card-body pt-2">
                  <div className="market-table">
                    <div className="table-responsive">
                      <table className="table mb-0 table-responsive-sm">
                        <thead>
                          <tr>
                            <th>Name</th>
                            <th>Price</th>
                            <th>Change</th>
                            <th>Chart</th>
                          </tr>
                        </thead>
                        <tbody>{contracts}</tbody>
                      </table>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>

      <Footer2 />
    </>
  );
}

export default Contracts;
