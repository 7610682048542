import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";

function EmptyState({ style, title, subtitle, icon }) {
  return (
    <>
      <div
        style={{
          textAlign: "center",
          alignItems: "center",
          flexDirection: "column",
          display: "flex",
        }}
        className="py-4"
      >
        <div
          className="my-5"
          style={{
            width: 150,
            height: 150,
            background: "#F8F8FF",
            borderRadius: 9999,
            position: "relative",
          }}
        >
          <div style={{ position: "absolute", left: 30, top: 28 }}>
            <FontAwesomeIcon
              icon={icon}
              size="5x"
              style={{
                "--fa-primary-color": "var(--primary)",
                "--fa-secondary-color": "#FFF",
                "--fa-secondary-opacity": 1,
                filter: "drop-shadow(1px 1px 2px rgba(0, 0, 0, 0.12))",
              }}
            />
          </div>
        </div>
        <h5>{title}</h5>
        <p className="small">{subtitle}</p>
      </div>
    </>
  );
}

export default EmptyState;
