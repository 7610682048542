import { Field, Form, Formik } from "formik";
import states from "jsx/data/states.json";
import DatePicker from "jsx/element/date-picker";
import React from "react";
import * as Yup from "yup";

import currencies from "./currencies.json";
import industries from "./industry.json";

function CompanyDetails({ onReturn }) {
  const CompanyDetailsSchema = Yup.object().shape({
    companyName: Yup.string()
      .min(2, "Too Short!")
      .max(50, "Too Long!")
      .required("Required"),
    countryOfIncorporation: Yup.string()
      .min(2, "Too Short!")
      .max(50, "Too Long!")
      .required("Required"),
    stateOfIncorporation: Yup.string().required("Required"),
    dateOfIncorporation: Yup.string().required("Required"),
    taxID: Yup.string().required("Required"),
    currency: Yup.string().required("Required"),
    industry: Yup.string().required("Required"),
  });
  return (
    <Formik
      initialValues={{
        companyName: "",
        countryOfIncorporation: "",
        stateOfIncorporation: "WA",
        dateOfIncorporation: "",
        taxID: "11-4142524",
        currency: "USD",
        industry: "Accounting",
      }}
      validationSchema={CompanyDetailsSchema}
      onSubmit={async (values) => {
        onReturn(1, values);
      }}
    >
      {({ values, touched, errors }) => (
        <Form>
          <div className="card-header">
            <h4 className="card-title">Company Details</h4>
          </div>
          <div className="card-body">
            <div className="row">
              <div className="mb-3 col-xl-12">
                <h5 className="mb-2 mt-3">Company Name</h5>
                <Field
                  type="text"
                  name="companyName"
                  className={
                    "form-control " +
                    (touched.companyName && errors.companyName && "is-invalid")
                  }
                  placeholder="Flow Dynamics, LLC"
                />
                {touched.companyName && (
                  <p className="text-danger">{errors.companyName}</p>
                )}
              </div>
              <div className="mb-3 col-xl-12">
                <h5 className="mb-2 mt-3">Country of Incorporation</h5>
                <Field
                  type="text"
                  name="countryOfIncorporation"
                  className={
                    "form-control " +
                    (touched.countryOfIncorporation &&
                      errors.countryOfIncorporation &&
                      "is-invalid")
                  }
                  placeholder="United States"
                />
                {touched.countryOfIncorporation && (
                  <p className="text-danger">{errors.countryOfIncorporation}</p>
                )}
              </div>
              <div className="mb-3 col-xl-12">
                <h5 className="mb-2 mt-3">State of Incorporation</h5>
                <Field
                  name="stateOfIncorporation"
                  component="select"
                  className={
                    "form-select " +
                    (touched.stateOfIncorporation &&
                      errors.stateOfIncorporation &&
                      "is-invalid")
                  }
                >
                  {states.map((item) => {
                    return (
                      <option key={item.abbreviation} value={item.abbreviation}>
                        {item.name}
                      </option>
                    );
                  })}
                </Field>
                {touched.stateOfIncorporation && (
                  <p className="text-danger">{errors.stateOfIncorporation}</p>
                )}
              </div>
              <div className="mb-3 col-xl-12">
                <h5 className="mb-2 mt-3">Date of Incorporation</h5>
                <DatePicker
                  name="dateOfIncorporation"
                  className={
                    "form-control mb-3 " +
                    (touched.dateOfIncorporation &&
                      errors.dateOfIncorporation &&
                      "is-invalid")
                  }
                />
                {touched.dateOfIncorporation && (
                  <p className="text-danger">{errors.dateOfIncorporation}</p>
                )}
              </div>
              <div className="mb-3 col-xl-12">
                <h5 className="mb-2 mt-3">Tax ID</h5>
                <Field
                  type="text"
                  name="taxID"
                  className={
                    "form-control mb-3 " +
                    (touched.taxID && errors.taxID && "is-invalid")
                  }
                  placeholder=""
                />
                {touched.taxID && <p className="text-danger">{errors.taxID}</p>}
              </div>
              <div className="mb-3 col-xl-12">
                <h5 className="mb-2 mt-3">Currency</h5>
                <Field
                  name="currency"
                  component="select"
                  className={
                    "form-select " +
                    (touched.currency && errors.currency && "is-invalid")
                  }
                >
                  {currencies.map((item) => {
                    return (
                      <option key={item.cc} value={item.cc}>
                        {item.name}
                      </option>
                    );
                  })}
                </Field>
                {touched.currency && (
                  <p className="text-danger">{errors.currency}</p>
                )}
              </div>
              <div className="mb-3 col-xl-12">
                <h5 className="mb-2 mt-3">Company Industry</h5>
                <Field
                  name="industry"
                  component="select"
                  className={
                    "form-select " +
                    (touched.industry && errors.industry && "is-invalid")
                  }
                >
                  {industries.map((item) => {
                    return (
                      <option key={item} value={item}>
                        {item}
                      </option>
                    );
                  })}
                </Field>
                {touched.industry && (
                  <p className="text-danger">{errors.industry}</p>
                )}
              </div>
              <div className="mb-3 col-xl-12">
                <button
                  style={{ width: 100 }}
                  className="btn btn-success waves-effect"
                  type="submit"
                >
                  Submit
                </button>
              </div>
            </div>
          </div>
        </Form>
      )}
    </Formik>
  );
}

export default CompanyDetails;
