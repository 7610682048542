import { MenuItem } from "@mui/material";
import {
  addDoc,
  collection,
  doc,
  getDoc,
  getFirestore,
  setDoc,
} from "firebase/firestore";
import { Form, Formik } from "formik";
import { UserContext } from "jsx/contexts/user-context";
import states from "jsx/data/states.json";
import MSButton from "jsx/element/button";
import MSField from "jsx/element/ms-field";
import React, { useContext, useEffect, useRef, useState } from "react";
import { useHistory } from "react-router-dom";
import * as Yup from "yup";

const SetupSchema = Yup.object().shape({
  companyName: Yup.string().required("Enter your company name"),
  legalEntity: Yup.string().required("Required"),
  stateOfIncorporation: Yup.string().required("Required"),
  address: Yup.string().required("Required"),
  city: Yup.string().required("Required"),
  state: Yup.string().required("Required"),
  zipcode: Yup.string().required("Required"),
});

export default function CompanyInfoForm(props) {
  const { user, selectedOrganization, selectOrganization } =
    useContext(UserContext);
  const [organization, setOrganization] = useState(false);

  const db = getFirestore();
  const buttonIcon = useRef();

  const history = useHistory();

  let header = (
    <>
      <h4 className="card-title">Edit company info</h4>
    </>
  );

  if (!selectedOrganization || props.createNewOrganization) {
    header = (
      <>
        <h4 className="card-title">Setup a new company</h4>
        <p>
          Setup a new company to manage your fundraising and document signing
        </p>
      </>
    );
  }

  async function fetchOrganization() {
    if (!selectedOrganization || props.createNewOrganization) {
      return;
    }
    const docRef = doc(
      db,
      "organizations",
      selectedOrganization,
      "organizations_private",
      "data"
    );
    const docSnap = await getDoc(docRef);
    if (docSnap.exists()) {
      setOrganization(docSnap.data());
    } else {
    }
  }

  async function debugCreate() {
    const values = {
      companyName: "Test Company",
      legalEntity: "Test Company, Inc.",
      stateOfIncorporation: "WA",
      address: "123 Main Street",
      city: "Bellevue",
      state: "WA",
      zipcode: "98005",
      creator: user.uid,
    };

    const company = {
      companyName: values.companyName,
      legalEntity: values.legalEntity,
    };

    try {
      const docRef = await addDoc(collection(db, "organizations"), company);
      await setDoc(
        doc(db, "organizations", docRef.id, "organizations_private", "data"),
        values,
        { merge: true }
      );
      selectOrganization(docRef.id);

      history.push("/org/dashboard");
    } catch (e) {
      console.log("error", e);
    }
  }

  useEffect(() => {
    fetchOrganization();
  }, [selectedOrganization]);

  let initialValues = {
    companyName: "",
    legalEntity: "",
    stateOfIncorporation: "",
    address: "",
    city: "",
    state: "",
    zipcode: "",
  };
  if (organization) {
    initialValues = organization;
  }

  return (
    <Formik
      enableReinitialize
      initialValues={initialValues}
      validationSchema={SetupSchema}
      onSubmit={async (values, errors) => {
        values.countryOfIncorporation = "USA";
        try {
          const company = {
            companyName: values.companyName,
            legalEntity: values.legalEntity,
          };

          values.creator = user.uid;
          // save
          if (organization) {
            await setDoc(
              doc(
                db,
                "organizations",
                selectedOrganization,
                "organizations_private",
                "data"
              ),
              values,
              { merge: true }
            );
            buttonIcon.current.className = "la la-check";
            localStorage.setItem("shouldInvalidateCache", false);
          } else {
            const docRef = await addDoc(
              collection(db, "organizations"),
              company
            );
            await setDoc(
              doc(
                db,
                "organizations",
                docRef.id,
                "organizations_private",
                "data"
              ),
              values,
              { merge: true }
            );
            selectOrganization(docRef.id);
            history.push("/org/dashboard");
          }
        } catch (e) {
          console.log("error", e);
        }
      }}
    >
      {(formik) => {
        const { isSubmitting, values } = formik;

        return (
          <Form>
            <div className="row">
              <div className="card outlined">
                <div className="card-header" style={{ display: "block" }}>
                  {header}
                </div>
                <div className="card-body">
                  <div className="row">
                    <h4 className="mt-4 mb-0">Details</h4>
                    <div className="mb-0 col-xl-12">
                      <MSField
                        name="companyName"
                        placeholder="Acme"
                        autoComplete="off"
                        autoCorrect="off"
                        autoCapitalize="off"
                        spellCheck="false"
                        autoFocus
                        formik={formik}
                        defaultValue={values.companyName}
                      />
                    </div>

                    <div className="mb-0 col-xl-12">
                      <MSField
                        name="legalEntity"
                        placeholder="Acme, Inc."
                        formik={formik}
                        description="The legal name as it appears in your incorporation
                        documents."
                        defaultValue={values.legalEntity}
                      />
                    </div>

                    <div className="mb-0 col-xl-12">
                      <MSField
                        name="stateOfIncorporation"
                        select
                        placeholder="State"
                        defaultValue={values.stateOfIncorporation}
                        formik={formik}
                        description="The state in which your company is legally incorporated."
                      >
                        {states.map((item) => {
                          return (
                            <MenuItem
                              key={item.abbreviation}
                              value={item.abbreviation}
                            >
                              {item.name}
                            </MenuItem>
                          );
                        })}
                      </MSField>
                    </div>

                    <h4 className="mt-4">Location</h4>

                    <div className="mb-0 col-xl-12">
                      <MSField
                        name="countryOfIncorporation"
                        select
                        defaultValue="US"
                        disabled
                        formik={formik}
                        description="Modern Share is currently only available for US based
                        companies."
                      >
                        <option value="US">United States</option>
                      </MSField>
                    </div>

                    <div className="mb-0 col-xl-12">
                      <MSField
                        type="text"
                        name="address"
                        placeholder="123 Main Street"
                        autoComplete="off"
                        autoCorrect="off"
                        autoCapitalize="off"
                        spellCheck="false"
                        defaultValue={values.address}
                        formik={formik}
                      />
                    </div>

                    <div className="mb-0 col-5">
                      <MSField
                        type="text"
                        name="city"
                        placeholder="City"
                        autoComplete="off"
                        autoCorrect="off"
                        spellCheck="false"
                        defaultValue={values.city}
                        formik={formik}
                      />
                    </div>
                    <div className="mb-0 col-3">
                      <MSField
                        type="text"
                        name="state"
                        select
                        placeholder="State"
                        defaultValue={values.state}
                        formik={formik}
                      >
                        {states.map((item) => {
                          return (
                            <MenuItem
                              key={item.abbreviation}
                              value={item.abbreviation}
                            >
                              {item.abbreviation}
                            </MenuItem>
                          );
                        })}
                      </MSField>
                    </div>
                    <div className="mb-0 col-4">
                      <MSField
                        type="text"
                        name="zipcode"
                        placeholder="Zipcode"
                        defaultValue={values.zipcode}
                        formik={formik}
                      />
                    </div>

                    <div className="col-12 mt-5">
                      <MSButton
                        size="large"
                        variant="primary"
                        className="w-100"
                        disabled={isSubmitting}
                        type="submit"
                      >
                        {organization ? "Save Company" : "Setup new company"}
                        <i ref={buttonIcon} className="la la-arrow-right"></i>
                      </MSButton>

                      <MSButton
                        size="large"
                        className="mt-2 w-100"
                        hidden={!window.location.href.includes("localhost")}
                        onClick={debugCreate}
                      >
                        Debug
                      </MSButton>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </Form>
        );
      }}
    </Formik>
  );
}
