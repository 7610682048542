import { UserContext } from "jsx/contexts/user-context";
import React from "react";
import { propTypes } from "react-bootstrap/esm/Image";

function PageTitle(props) {
  const { user } = React.useContext(UserContext);

  var message = (
    <div className="page-title-content">
      <p>
        Welcome Back,
        <span> {user.displayName}</span>
      </p>
    </div>
  );

  message = props.showWelcome ? message : "";

  return (
    <>
      <div className="page-title dashboard">
        <div className="container">
          <div className="row">
            <div className="col-xl-12">{message}</div>
          </div>
        </div>
      </div>
    </>
  );
}

export default PageTitle;
