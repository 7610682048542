import { TextField } from "@mui/material";
import FormError from "jsx/element/form-error";
import React from "react";
import NumberFormat from "react-number-format";

export default function MSField({
  name = "",
  formik = {},
  type = "text",
  description,
  label,
  children,
  ...props
}) {
  const { touched = {}, errors = {}, initialValues = {} } = formik;

  const titleName = name
    .replace(/([A-Z])/g, (match) => ` ${match}`)
    .replace(/^./, (match) => match.toUpperCase())
    .trim();

  const onChange =
    type === "number"
      ? () => {}
      : (e) => {
          formik?.setFieldValue(name, e.target.value);
        };

  const Component = type === "number" ? NumberFormat : TextField;
  const numberProps =
    type === "number"
      ? {
          customInput: TextField,
          format: null,
          type: "text",
          isNumericString: true,
          onValueChange: (val) => {
            formik?.setFieldValue(name, val.floatValue);
          },
        }
      : {};

  return (
    <div style={{ display: "flex", flexDirection: "column" }}>
      <Component
        key={initialValues[name]}
        label={label ?? titleName}
        type={type}
        onChange={onChange}
        className={"w-100 mt-4 mb-0"}
        error={type === "number" ? errors[name] : !!errors[name]}
        {...numberProps}
        {...props}
      >
        {children}
      </Component>
      {description && (
        <p className="small form-description mb-2 mt-2">{description}</p>
      )}
      <FormError show={touched[name] && errors[name]} message={errors[name]} />
    </div>
  );
}
