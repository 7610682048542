import {
  collection,
  doc,
  getDoc,
  getDocs,
  getFirestore,
} from "@firebase/firestore";
import { duotone } from "@fortawesome/fontawesome-svg-core/import.macro";
import { Skeleton } from "@mui/material";
import { UserContext } from "jsx/contexts/user-context";
import EmptyState from "jsx/element/empty-state";
import ManageSecondariesNav from "jsx/element/manage-secondaries-nav";
import SecondaryOrderTable from "jsx/element/secondary-order-table";
import SecondaryTransactionTable from "jsx/element/secondary-transaction-table";
import Header4 from "jsx/layout/header4";
import React, { useContext, useEffect, useState } from "react";
function CompanySecondaryManageOrders({ match }) {
  const { selectedOrganization } = useContext(UserContext);
  const [secondary, setSecondary] = useState({});
  const [secondaryOrders, setSecondaryOrders] = useState();
  const [transactions, setTransactions] = useState();

  const [loading, setIsLoading] = useState(false);

  const { secondaryId, orderId } = match.params;

  const db = getFirestore();

  // Get secondary information along with stakeholder, secondary seller, secondary order
  // data, secondary transactions
  async function fetchSecondary(organizationId, secondaryId) {
    setIsLoading(true);

    const fetchSecondary = async () => {
      const secondarySnap = await getDoc(
        doc(
          db,
          `organizations/${organizationId}/organizations_private/data/secondaries/${secondaryId}`
        )
      );
      const secondaryData = secondarySnap.data();
      setSecondary(secondaryData);
      return secondaryData;
    };

    const fetchOrders = async () => {
      const secondaryOrdersSnapshot = await getDocs(
        collection(
          db,
          `organizations/${organizationId}/organizations_admin/data/secondaries/${secondaryId}/secondary_orders`
        )
      );
      const secondaryOrders = secondaryOrdersSnapshot.docs.map((doc) => ({
        id: doc.id,
        ...doc.data(),
      }));
      setSecondaryOrders(secondaryOrders);
      return secondaryOrders;
    };

    const fetchTransactions = async () => {
      const transactionsSnapshot = await getDocs(
        collection(
          db,
          "organizations",
          organizationId,
          "organizations_admin",
          "data",
          "secondaries",
          secondaryId,
          "secondary_transactions"
        )
      );
      const transactions = transactionsSnapshot.docs.map((doc) => ({
        id: doc.id,
        ...doc.data(),
      }));
      setTransactions(transactions);
      return transactions;
    };

    const [
      secondaryData, // eslint-disable-line no-unused-vars
      secondaryOrders,
      transactions, // eslint-disable-line no-unused-vars
    ] = await Promise.all([
      fetchSecondary(),
      fetchOrders(),
      fetchTransactions(),
    ]);

    setIsLoading(false);
  }

  useEffect(() => {
    if (
      selectedOrganization &&
      secondaryId &&
      Object.entries(secondary).length === 0
    ) {
      fetchSecondary(selectedOrganization, secondaryId);
    } else {
      setSecondaryOrders([]);
      setTransactions([]);
    }
  }, [selectedOrganization, secondaryId]);

  let secondaryOrdersSection;
  let secondaryTransactionsSection;

  if (!secondaryOrders) {
    secondaryOrdersSection = <Skeleton variant="rectangular" height={500} />;
  } else if (secondaryOrders.length) {
    secondaryOrdersSection = (
      <SecondaryOrderTable
        highlightedOrderId={orderId}
        secondaryOrders={secondaryOrders}
      />
    );
  } else {
    secondaryOrdersSection = (
      <EmptyState
        icon={duotone("rectangle-history-circle-user")}
        title={"No orders to display"}
        subtitle={"When buyers and sellers put in their orders, it will appear"}
      />
    );
  }

  if (!transactions) {
    secondaryTransactionsSection = (
      <Skeleton variant="rectangular" height={500} />
    );
  } else if (transactions.length) {
    secondaryTransactionsSection = (
      <SecondaryTransactionTable
        transactions={transactions}
        secondaryId={secondaryId}
      />
    );
  } else {
    secondaryTransactionsSection = (
      <EmptyState
        icon={duotone("money-check-dollar-pen")}
        title={"No transactions to display"}
        subtitle={
          "When orders are paired, transactions and generated documents will appear here"
        }
      />
    );
  }

  return (
    <>
      <Header4
        hideAllMenuItems
        backButtonLink={"/org/secondaries/manage"}
        pageTitle="Manage Secondary"
      />

      <div className="content-body section-padding">
        <div className="container">
          <div className="d-flex">
            <div className="me-4" style={{ minWidth: "220px" }}>
              <ManageSecondariesNav secondaryId={secondaryId} />
            </div>
            <div className="col-xl-10">
              <div className="flex-column">
                <div className="card outlined">
                  <div className="card-header">
                    <h4 className="card-title">Orders</h4>
                  </div>

                  <div className="card-body">
                    {secondaryOrdersSection}
                    <div className="d-flex justify-content-between mt-5">
                      <h4>Transactions</h4>
                    </div>
                    {secondaryTransactionsSection}
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
}

export default CompanySecondaryManageOrders;
