export function usd(valueInBaseUnits: number, precision: number) {
  const value: number = valueInBaseUnits / precision;
  return value;
}

export function numberWithCommas(x) {
  return x.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",");
}

export function clamp(num, min, max) {
  return Math.min(Math.max(num, min), max);
}

export function titleCase(text) {
  if (!text) return text;
  return text.replace(
    /\w\S*/g,
    (txt) => txt.charAt(0).toUpperCase() + txt.substr(1).toLowerCase()
  );
}

export function isProduction() {
  return window.location.href.includes("modernshare.com");
}

export function formatPrice(number, precision) {
  if (
    number === null ||
    number === undefined ||
    precision === null ||
    precision === undefined
  ) {
    return NaN;
  }
  return (number / precision).toLocaleString(undefined, {
    style: "currency",
    currency: "USD",
    minimumFractionDigits: Math.max(2, Math.log10(precision)),
  });
}

export function toCapcase(str) {
  if (!str) return str;
  return str.charAt(0).toUpperCase() + str.slice(1);
}

export function monthDiff(d1, d2) {
  var months;
  months = (d2.getFullYear() - d1.getFullYear()) * 12;
  months -= d1.getMonth();
  months += d2.getMonth();
  return months <= 0 ? 0 : months;
}

export function detailedStatus(transaction) {
  if (transaction.status === "approved") {
    if (!transaction.isSignedByBuyer) {
      return "Ready to sign";
    } else if (!transaction.isSignedBySeller) {
      return "Waiting seller";
    } else if (!transaction.isSignedByCompany) {
      return "Waiting company";
    } else if (transaction.isSigningCompleted) {
      return "Completed";
    }
  } else {
    return "Pending";
  }
}
