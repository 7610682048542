import AreaChart from "jsx/charts/area";
import OrderBook from "jsx/element/order-book";
import Orders from "jsx/element/orders";
import PageTitle from "jsx/element/page-title";
import Footer2 from "jsx/layout/footer2";
import Header2 from "jsx/layout/header2";
import Sidebar from "jsx/layout/sidebar";
import React from "react";

function Test() {
  // const [user1Orders, setUser1Orders] = useState([]);
  // const [user2Orders, setUser2Orders] = useState([]);
  // const [user3Orders, setUser3Orders] = useState([]);
  // const [user4Orders, setUser4Orders] = useState([]);

  // const [orderBook, setOrderBook] = useState([]);
  // const [transactions, setTransactions] = useState([]);

  return (
    <>
      <Header2 />
      <Sidebar />
      <PageTitle />

      <div className="content-body">
        <div className="container-fluid">
          <div className="row">
            <div className="col-3">
              <div className="card">
                <div className="card-header">
                  <h4 className="card-title">User 1</h4>
                </div>
                <div className="card-body">
                  <div className="d-flex">
                    <Orders userId="user1" />
                  </div>
                </div>
              </div>
              <div className="card">
                <div className="card-header">
                  <h4 className="card-title">User 2</h4>
                </div>
                <div className="card-body">
                  <div className="d-flex">
                    <Orders userId="user2" />
                  </div>
                </div>
              </div>
              <div className="card">
                <div className="card-header">
                  <h4 className="card-title">User 3</h4>
                </div>
                <div className="card-body">
                  <div className="d-flex">
                    <Orders userId="user3" />
                  </div>
                </div>
              </div>
              <div className="card">
                <div className="card-header">
                  <h4 className="card-title">User 4</h4>
                </div>
                <div className="card-body">
                  <div className="d-flex">
                    <Orders userId="user4" />
                  </div>
                </div>
              </div>
              {/* <div className="card">
                                <div className="card-header">
                                    <h4 className="card-title">User 5</h4>
                                </div>
                                <div className="card-body">
                                    <div className="d-flex">
                                        <Orders userId="user5" />
                                    </div>
                                </div>
                            </div>
                            <div className="card">
                                <div className="card-header">
                                    <h4 className="card-title">User 6</h4>
                                </div>
                                <div className="card-body">
                                    <div className="d-flex">
                                        <Orders userId="user6" />
                                    </div>
                                </div>
                            </div>
                            <div className="card">
                                <div className="card-header">
                                    <h4 className="card-title">User 7</h4>
                                </div>
                                <div className="card-body">
                                    <div className="d-flex">
                                        <Orders userId="user7" />
                                    </div>
                                </div>
                            </div>
                            <div className="card">
                                <div className="card-header">
                                    <h4 className="card-title">User 8</h4>
                                </div>
                                <div className="card-body">
                                    <div className="d-flex">
                                        <Orders userId="user8" />
                                    </div>
                                </div>
                            </div> */}
            </div>
            <div className="col-6">
              <div className="card">
                <div className="card-header">
                  <h4 className="card-title">Live Data</h4>
                </div>
                <div className="card-body">
                  <AreaChart />
                </div>
              </div>
            </div>

            <div className="col-3">
              <div className="card">
                <div className="card-header">
                  <h4 className="card-title">Order Book</h4>
                </div>
                <div className="card-body">
                  <div className="d-flex">
                    <OrderBook />
                  </div>
                </div>
              </div>
              <div className="card">
                <div className="card-header">
                  <h4 className="card-title">Transactions</h4>
                </div>
                <div className="card-body">
                  <div className="d-flex">WIP</div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>

      <Footer2 />
    </>
  );
}

export default Test;
