import { UserContext } from "jsx/contexts/user-context";
import MSDropdown from "jsx/element/dropdown";
import React from "react";
import { Navbar, NavDropdown } from "react-bootstrap";
import { Link } from "react-router-dom";

function Header1() {
  const { user } = React.useContext(UserContext);

  return (
    <>
      <div className="header">
        <div className="container">
          <div className="row">
            <div className="col-xl-12">
              <div className="navigation">
                <Navbar bg="light" expand="lg">
                  <Link className="navbar-brand py-4" to={"/"}>
                    <img src={require("./../../images/logo.png")} alt="" />
                  </Link>
                  <Navbar.Collapse></Navbar.Collapse>
                  {user.email && (
                    <MSDropdown
                      anchorOrigin={{
                        vertical: "bottom",
                        horizontal: "right",
                      }}
                      transformOrigin={{
                        vertical: "top",
                        horizontal: "right",
                      }}
                      title={user.displayName}
                      buttonProps={{
                        className: "profile-log d-none d-sm-block",
                      }}
                    >
                      <Link to={"/org/dashboard"} className="dropdown-item">
                        <i className="la la-user"></i> Dashboard
                      </Link>
                      <NavDropdown.Divider />

                      <Link to={"/logout"} className="dropdown-item logout">
                        <i className="la la-sign-out"></i> Logout
                      </Link>
                    </MSDropdown>
                  )}
                  {!user.email && (
                    <Link to={"/org/signin"}>
                      <button className="btn">Sign in</button>
                    </Link>
                  )}
                </Navbar>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
}

export default Header1;
