import { UserContext } from "jsx/contexts/user-context";
import React from "react";
import { useHistory } from "react-router-dom";

function Logout(props) {
  const { logout } = React.useContext(UserContext);
  logout();

  const history = useHistory();
  history.push("/");

  return <></>;
}

export default Logout;
