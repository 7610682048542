import { useField } from "formik";
import React from "react";
import Form from "react-bootstrap/Form";

function DatePicker({ ...props }) {
  const [field, meta] = useField(props);
  return (
    <Form.Control
      type="date"
      {...props}
      value={field.value}
      onChange={field.onChange}
      onBlur={field.onBlur}
    />
  );
}

export default DatePicker;
