import React, { useEffect, useRef } from "react";

function SignatureField(props) {
  const canvas = useRef();
  let ctx = null;
  const signatureImage = useRef();

  const { setFieldValue } = props;

  useEffect(() => {
    // dynamically assign the width and height to canvas
    const canvasEle = canvas.current;
    canvasEle.width = 900;
    canvasEle.height = 200;

    setTimeout(() => {
      window.scrollTo({
        top: 0,
        left: 0,
        behavior: "instant",
      });
    }, 100);
  }, []);

  const generateSignature = (e, setFieldValue) => {
    const text = e.target.value;
    const fontSize = 68,
      fontFamily = "Dancing Script",
      color = "black",
      textAlign = "#383838",
      textBaseline = "top";

    ctx = canvas.current.getContext("2d");
    ctx.clearRect(0, 0, 10000, 10000);
    ctx.beginPath();
    ctx.font = fontSize + "px " + fontFamily;
    ctx.textAlign = textAlign;
    ctx.textBaseline = textBaseline;
    ctx.fillStyle = color;
    ctx.fillText(text, 16, 0);
    ctx.stroke();

    signatureImage.current.src = canvas.current.toDataURL();

    if (props.onChange) {
      // send back undefined if no text
      props.onChange(text ? signatureImage.current.src : undefined);
    }
  };

  return (
    <>
      <input
        style={{ fontFamily: "Dancing Script", fontSize: 34, textIndent: 16 }}
        type="text"
        autoComplete="off"
        autoCorrect="off"
        autoCapitalize="off"
        spellCheck="false"
        name="signatureName"
        className={props.className}
        placeholder="First Last"
        onChange={(e) => {
          generateSignature(e, setFieldValue);
        }}
      />
      <canvas
        style={{ visibility: "hidden", position: "absolute" }}
        ref={canvas}
      ></canvas>
      <img
        alt=""
        style={{ visibility: "hidden", position: "absolute" }}
        ref={signatureImage}
      />
    </>
  );
}

export default SignatureField;
