import BankingIcon from "images/banking.png";
import ChartIcon from "images/chart.png";
import DocumentIcon from "images/documents.png";
import EducationIcon from "images/education.png";
import Globe from "images/globe.svg";
import logo from "images/logo.png";
import PeopleIcon from "images/people.png";
import WalletIcon from "images/wallet.png";
import Animation from "jsx/animation.json";
import MSButton from "jsx/element/button";
import Lottie from "lottie-react";
import React from "react";

const globeTitle = {
  fontSize: 32,
  fontWeight: 600,
  color: "black",
  marginBottom: 12,
};

const h2 = {
  fontSize: 48,
  fontWeight: 700,
  color: "black",
  lineHeight: 1.3,
};

const serviceIcon = {
  width: 138,
  height: 138,
  marginBottom: 24,
  marginLeft: "auto",
  marginRight: "auto",
  display: "block",
};

const serviceTitle = {
  fontSize: 24,
  fontWeight: 600,
  width: 300,
  textAlign: "center",
  color: "black",
  lineHeight: 1.3,
};

const serviceSubtitle = {
  textAlign: "center",
  fontSize: 18,
  lineHeight: 1.7,
};

function Homepage() {
  return (
    <div>
      {/* Navbar */}
      <div style={{ width: "100%", position: "absolute" }}>
        <div
          style={{
            width: 1200,
            textAlign: "center",
            alignItems: "center",
            justifyContent: "center",
            marginLeft: "auto",
            marginRight: "auto",
          }}
        >
          <nav className="nav-main navbar justify-content-between">
            <img
              alt="Modern Share logo"
              src={logo}
              style={{ height: "28px" }}
            />
          </nav>
        </div>
      </div>

      {/* Background */}

      <div
        style={{
          minHeight: "100vh",
          textAlign: "center",
        }}
      >
        {/* Container */}

        <div>
          {/* Hero BG */}
          <div
            style={{
              height: "100vh",
              background:
                "linear-gradient(84.23deg, #EEE2F4 9.28%, #F9F2F9 21.38%, #FFFFFF 50.8%, #F4E9F5 79.22%, #DFD5F3 91.05%)",
            }}
          >
            {/* Hero */}
            <div
              style={{
                width: 1200,
                marginLeft: "auto",
                marginRight: "auto",
              }}
            >
              <p
                style={{
                  fontSize: 64,
                  paddingTop: "30%",
                  letterSpacing: 1,
                  color: "black",
                  lineHeight: 1.3,
                }}
              >
                <b>Attract top talent</b> with an employee{" "}
                <b>liquidity program</b>
              </p>
              <p
                style={{
                  fontSize: 24,
                  paddingTop: 16,
                  paddingBottom: 16,
                  lineHeight: 1.7,
                }}
              >
                Modern Share provides the new standard for employee liquidity.
                Trusted by the best startups, we make the process frictionless
                and free.
              </p>

              <MSButton
                size="large"
                style={{ paddingLeft: 60, paddingRight: 60, fontSize: 18 }}
                title="Get Started"
              />
            </div>
          </div>

          {/* <div style={{ height: "100vh" }}>
            <p
              style={{
                fontSize: 18,
                letterSpacing: 2,
                color: "black",
              }}
            >
              POWERING THE NEXT GENERATION OF COMPANIES
            </p>
          </div> */}

          <div style={{ background: "black", height: 400 }}></div>

          {/* Block 1 */}
          <div
            style={{
              width: 1200,
              marginLeft: "auto",
              marginRight: "auto",
              padding: "220px 0px 80px 0px",
              flexDirection: "row",
              display: "flex",
            }}
          >
            <div style={{ textAlign: "left", width: 600 }}>
              <p style={h2}>The power of an employee liquidity program</p>
              <p
                style={{
                  fontSize: 18,
                  lineHeight: 1.7,
                }}
              >
                To be the best company, you need the best employees. Liquidity
                makes your current employees happy, and enables you to attract
                your top candidates.
              </p>
            </div>
            <div style={{ width: 540, marginTop: -120, marginLeft: 80 }}>
              <Lottie animationData={Animation} />
            </div>
          </div>

          {/* Block 2 */}
          <div
            style={{
              width: 1200,
              marginLeft: "auto",
              marginRight: "auto",
              padding: "140px 0px 140px 0px",
              flexDirection: "row",
              display: "flex",
            }}
          >
            <div style={{ width: 540, marginTop: -120, marginRight: 80 }}>
              <Lottie animationData={Animation} />
            </div>
            <div style={{ textAlign: "left", width: 600 }}>
              <p style={h2}>Employees value liquidity, proven by the numbers</p>
              <p
                style={{
                  fontSize: 18,
                  lineHeight: 1.7,
                }}
              >
                Compared to FAANG companies, startups have to give their
                employees up to 5x more stock. This is because employees value
                liquidity. Get on an even playing field and win your top
                candidates.
              </p>
            </div>
          </div>

          {/* World block */}
          <div
            style={{
              width: 1200,
              marginLeft: "auto",
              marginRight: "auto",
              padding: "140px 0px 140px 0px",
            }}
          >
            <div style={{ textAlign: "left", width: 900 }}>
              <p style={h2}>Modern Share sets the standard</p>
              <p
                style={{
                  fontSize: 18,
                  width: 770,
                  lineHeight: 1.7,
                  marginBottom: 48,
                }}
              >
                Developed with input form the top founders, CEOs, and lawyers in
                silicon valley, Modern Share is an end-to-end system for you to
                use best practices for your liquidity program. We provide legal
                documents, educate your employees, match shares with new or
                existing investors, and process transactions.
              </p>
            </div>
            <div
              style={{
                display: "flex",
                width: "100%",
                textAlign: "left",
                marginBottom: 48,
              }}
            >
              <div style={{ marginRight: "64px" }}>
                <div style={{ display: "flex" }}>
                  <img
                    alt=""
                    src={WalletIcon}
                    style={{
                      width: "40px",
                      height: "40px",
                      marginTop: 4,
                      marginRight: 12,
                    }}
                  />
                  <p style={globeTitle}>$250M+</p>
                </div>
                <p>Transactions on our platform every month.</p>
              </div>
              <div style={{ marginRight: "64px" }}>
                <div style={{ display: "flex" }}>
                  <img
                    alt=""
                    src={ChartIcon}
                    style={{
                      width: "40px",
                      height: "40px",
                      marginTop: 4,
                      marginRight: 12,
                    }}
                  />
                  <p style={globeTitle}>99.99%</p>
                </div>
                <p>Transactions on our platform every month.</p>
              </div>
              <div style={{ marginRight: "64px" }}>
                <div style={{ display: "flex" }}>
                  <img
                    alt=""
                    src={PeopleIcon}
                    style={{
                      width: "40px",
                      height: "40px",
                      marginTop: 4,
                      marginRight: 12,
                    }}
                  />
                  <p style={globeTitle}>100+</p>
                </div>
                <p>Transactions on our platform every month.</p>
              </div>
            </div>
            <img
              alt=""
              src={Globe}
              style={{ width: "100%", marginBottom: "-16px" }}
            />
          </div>

          {/* Take care of everything block */}
          <div
            style={{
              width: 1200,
              marginLeft: "auto",
              marginRight: "auto",
              padding: "140px 0px 140px 0px",
            }}
          >
            <div style={{ textAlign: "center" }}>
              <p style={h2}>We take care of everything</p>
              <p
                style={{
                  fontSize: 18,
                  lineHeight: 1.7,
                  marginBottom: 32,
                  textAlign: "center",
                }}
              >
                We do the work. You get the best practices. And it’s free.
              </p>
            </div>
            <div
              style={{
                display: "flex",
                width: "100%",
                textAlign: "left",
                marginBottom: 32,
              }}
            >
              <div
                style={{
                  display: "flex",
                  width: "100%",
                  justifyContent: "space-between",
                  marginLeft: 32,
                  marginRight: 32,
                }}
              >
                <div style={{ padding: 32 }}>
                  <img alt="" src={DocumentIcon} style={serviceIcon} />
                  <p style={serviceTitle}>Standard Legal Agreements</p>
                  <p style={serviceSubtitle}>
                    We provide standardized legal documents pre-approved by top
                    legal law firms to save you time and money.
                  </p>
                </div>
                <div style={{ padding: 32 }}>
                  <img alt="" src={EducationIcon} style={serviceIcon} />
                  <p style={serviceTitle}>Employee Onboarding and Education</p>
                  <p style={serviceSubtitle}>
                    We provide a dedicated CPA to your employees and help
                    organize town halls to answer all their questions.
                  </p>
                </div>
                <div style={{ padding: 32 }}>
                  <img alt="" src={BankingIcon} style={serviceIcon} />
                  <p style={serviceTitle}>Execution</p>
                  <p style={serviceSubtitle}>
                    We handle KYC / AML for all new and existing investors. We
                    will handle all complex money movements.
                  </p>
                </div>
              </div>
            </div>
          </div>

          {/* Footer */}
          <div
            style={{
              width: "100%",
              background: "#0C0C0C",
            }}
          >
            <div
              style={{
                textAlign: "center",
                width: 1150,
                marginLeft: "auto",
                marginRight: "auto",
                padding: "140px 0px 32px 0px",
              }}
            >
              <p style={{ ...h2, color: "white", fontWeight: 600 }}>
                Join the best companies that are committed to doing right by
                their employees
              </p>

              <MSButton
                type="light"
                style={{
                  paddingLeft: 70,
                  paddingRight: 70,
                  fontSize: 18,
                  paddingTop: 18,
                  paddingBottom: 18,
                  fontWeight: 600,
                  marginTop: 40,
                  marginBottom: 140,
                }}
                title="Get Started"
              />

              <p style={{ textAlign: "center", color: "white" }}>
                © Modern Share. All rights reserved.
              </p>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}

export default Homepage;
