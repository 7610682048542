import { Form } from "formik";
import CurrencySelect from "jsx/element/currency-select/currency-select";
import DatePicker from "jsx/element/date-picker";
import FileInput from "jsx/element/file-input";
import NumberFormatted from "jsx/element/number-formatted";
import React from "react";

import SecurityHolderType from "../captable-security-holder-type.json";
import PaymentForShares from "./payment-for-shares-element";
function CommonSharesFormContext({ config, children }) {
  const values = config.values;
  const handleChange = config.handleChange;
  const handleBlur = config.handleBlur;
  const errors = config.errors;
  const touched = config.touched;
  return (
    <Form className="d-flex flex-column card-body">
      <h3>Shareholder Information</h3>
      <div className="mb-3 col-xl-12">
        <h5 className="mb-2 mt-3">Email</h5>
        <input
          value={values.email}
          name="email"
          className={
            "form-control mb-3 " +
            (touched.email && errors.email && "is-invalid")
          }
          onChange={handleChange}
          onBlur={handleBlur}
        />
        {touched.email && <p className="text-danger">{errors.email}</p>}
      </div>
      <div className="mb-3 col-xl-12">
        <h5 className="mb-2 mt-3">Stakeholder type</h5>
        <div
          role="group"
          className="d-flex flex-column"
          aria-labelledby="my-radio-group"
        >
          <label>
            <input
              type="radio"
              className="form-check-input"
              name="stackHolderType"
              defaultChecked={true}
              value="individual"
              onChange={handleChange}
              onBlur={handleBlur}
            />
            Individual
          </label>
          <label>
            <input
              type="radio"
              className="form-check-input"
              name="stackHolderType"
              value="organization"
              onChange={handleChange}
              onBlur={handleBlur}
            />
            Organization
          </label>
        </div>
        {touched.stackHolderType && (
          <p className="text-danger">{errors.stackHolderType}</p>
        )}
        {values.stackHolderType === "individual" ? (
          <>
            <div className="mb-3 col-xl-12">
              <h5 className="mb-2 mt-3">First Name</h5>
              <input
                value={values.firstName}
                name="firstName"
                className={
                  "form-control mb-3 " +
                  (touched.firstName && errors.firstName && "is-invalid")
                }
                onChange={handleChange}
                onBlur={handleBlur}
              />
              {touched.firstName && (
                <p className="text-danger">{errors.firstName}</p>
              )}
            </div>
            <div className="mb-3 col-xl-12">
              <h5 className="mb-2 mt-3">Last Name</h5>
              <input
                value={values.lastName}
                name="lastName"
                className={
                  "form-control mb-3 " +
                  (touched.lastName && errors.lastName && "is-invalid")
                }
                onChange={handleChange}
                onBlur={handleBlur}
              />
              {touched.lastName && (
                <p className="text-danger">{errors.lastName}</p>
              )}
            </div>
          </>
        ) : (
          <>
            <div className="mb-3 col-xl-12">
              <h5 className="mb-2 mt-3">Organization Name</h5>
              <input
                value={values.organizationName}
                name="organizationName"
                className={
                  "form-control mb-3 " +
                  (touched.organizationName &&
                    errors.organizationName &&
                    "is-invalid")
                }
                onChange={handleChange}
                onBlur={handleBlur}
              />
              {touched.organizationName && (
                <p className="text-danger">{errors.organizationName}</p>
              )}
            </div>
          </>
        )}
        <small>
          Non-individual investors are entities like investment firms, funds,
          trusts, etc.
        </small>
        <div className="mb-3 col-xl-12">
          <h5 className="mb-2 mt-3">Security holder relationship</h5>
          <select
            name="securityHolderRelationship"
            value={values.securityHolderRelationship}
            onChange={handleChange}
            onBlur={handleBlur}
            className={
              "form-select mb-3 " +
              (touched.securityHolderRelationship &&
                errors.securityHolderRelationship &&
                "is-invalid")
            }
          >
            <option value=""></option>
            {SecurityHolderType.map((item) => {
              return <option key={item.value}>{item.name}</option>;
            })}
          </select>
          {touched.securityHolderRelationship && (
            <p className="text-danger">{errors.securityHolderRelationship}</p>
          )}
        </div>
        <div className="mb-3 col-xl-12">
          <h5 className="mb-2 mt-3">Relationship start date</h5>
          <DatePicker
            name="relationShipStartDate"
            className={
              "form-control mb-3 " +
              (touched.relationShipStartDate &&
                errors.relationShipStartDate &&
                "is-invalid")
            }
          />
          {touched.relationShipStartDate && (
            <p className="text-danger">{errors.relationShipStartDate}</p>
          )}
          <small>
            MM/DD/YYYY when the shareholder’s relationship began with the
            company.
          </small>
        </div>
      </div>
      <h3>Certificate Details</h3>
      <div className="mb-3 col-xl-12">
        <h5 className="mb-2 mt-3">Board approval date</h5>
        <DatePicker
          name="boardApprovalDate"
          className={
            "form-control mb-3 " +
            (touched.boardApprovalDate &&
              errors.boardApprovalDate &&
              "is-invalid")
          }
        />
        {touched.boardApprovalDate && (
          <p className="text-danger">{errors.boardApprovalDate}</p>
        )}
        <small>
          The last date a director signed a Board Consent approving the issuance
          of the shares to this holder. This date must be on or before the issue
          date of the shares.
        </small>
      </div>
      <div className="mb-3 col-xl-12">
        <h5 className="mb-2 mt-3">Issue date</h5>
        <DatePicker
          name="issueDate"
          className={
            "form-control mb-3 " +
            (touched.issueDate && errors.issueDate && "is-invalid")
          }
        />
        {touched.issueDate && <p className="text-danger">{errors.issueDate}</p>}
        <small>
          The date which the shares were issued. The issue date can be found in
          the Stock Purchase agreement. This date will also be used for
          transactions related to Rule 144.
        </small>
      </div>
      <h3>Transaction Details</h3>
      <div className="mb-3 col-xl-12">
        <h5 className="mb-2 mt-3">Number of shares</h5>
        <NumberFormatted
          value={values.numberOfShares}
          name="numberOfShares"
          className={
            touched.numberOfShares && errors.numberOfShares && "is-invalid"
          }
        />
        {touched.numberOfShares && (
          <p className="text-danger">{errors.numberOfShares}</p>
        )}
      </div>
      <div className="mb-3 col-xl-12">
        <h5 className="mb-2 mt-3">Currency</h5>
        <CurrencySelect
          name="currency"
          className={touched.currency && errors.currency && "is-invalid"}
        />
        {touched.currency && <p className="text-danger">{errors.currency}</p>}
      </div>
      <div className="mb-3 col-xl-12">
        <h5 className="mb-2 mt-3">Payment for the shares</h5>
        <PaymentForShares
          name="paymentForShares"
          className={
            touched.paymentForShares && errors.paymentForShares && "is-invalid"
          }
        />
        {touched.paymentForShares && (
          <p className="text-danger">{errors.paymentForShares}</p>
        )}
      </div>
      <h3>Vesting</h3>
      <div className="mb-3 col-xl-12">
        <h5 className="mb-2 mt-3">Vesting schedule</h5>
        <select
          onBlur={handleBlur}
          onChange={handleChange}
          value={values.vestingSchedule}
          name="vestingSchedule"
          className={
            "form-select mb-3 " +
            (touched.vestingSchedule && errors.vestingSchedule && "is-invalid")
          }
        >
          <option value=""></option>
          <option value="no-vesting-schedule">No vesting schedule</option>
          <option value="1/24-monthly-no-cliff">1/24 monthly, no cliff</option>
          <option value="1/24-monthly-no-cliff">1/48 monthly, no cliff</option>
          <option value="1/48-monthly-cliff">1/48 monthly, 1 year cliff</option>
        </select>
        {touched.vestingSchedule && (
          <p className="text-danger">{errors.vestingSchedule}</p>
        )}
        <small>
          A schedule that vests the shares overtime pursuant to certain
          milestones
        </small>
      </div>
      <div className="mb-3 col-xl-12">
        <h5 className="mb-2 mt-3">Vesting start date</h5>
        <DatePicker
          name="vestingStartDate"
          className={
            "form-control mb-3 " +
            (touched.vestingStartDate &&
              errors.vestingStartDate &&
              "is-invalid")
          }
        />
        {touched.vestingStartDate && (
          <p className="text-danger">{errors.vestingStartDate}</p>
        )}
        <small>
          MM/DD/YYYY which the shares will start vesting. This can be any date
          you decide and does not have to be tied to the grant date.
        </small>
      </div>
      <div className="mb-3 col-xl-12 d-flex flex-column">
        <h5 className="mb-2 mt-3">Acceleration terms</h5>
        <textarea
          name="accelerationTerms"
          className={
            "form-control mb-3 " +
            (touched.accelerationTerms &&
              errors.accelerationTerms &&
              "is-invalid")
          }
          value={values.accelerationTerms}
          onChange={handleChange}
          onBlur={handleBlur}
          rows="4"
          cols="50"
        ></textarea>
        {touched.accelerationTerms && (
          <p className="text-danger">{errors.accelerationTerms}</p>
        )}
        <small>
          Accelerated vesting allows a shareholder to quicken the schedule by
          which he or she gain access to an incentive.
        </small>
      </div>
      <h3>Legal Details</h3>
      <div className="mb-3 col-xl-12">
        <h5 className="mb-2 mt-3">Legend</h5>
        <select
          onBlur={handleBlur}
          onChange={handleChange}
          name="legend"
          className={
            "form-select mb-3 " +
            (touched.legend && errors.legend && "is-invalid")
          }
        >
          <option value="default">Default</option>
        </select>
        {touched.legend && <p className="text-danger">{errors.legend}</p>}
        <small>
          A statement on the certificate noting the restrictions on the transfer
          of a stock. This is the standard legal information that is applied to
          the certificate and can either be found on the issuing agreement or in
          the company’s bylaws.
        </small>
      </div>
      {values.legend === "default" && (
        <div className="mb-3 col-xl-12">
          <h5 className="mb-2 mt-3">Default</h5>
          <p>
            THE SECURITIES REPRESENTED HEREBY HAVE NOT BEEN REGISTERED UNDER THE
            SECURITIES ACT OF 1933, AND HAVE BEEN ACQUIRED FOR INVESTMENT AND
            NOT WITH A VIEW TO, OR IN CONNECTION WITH, THE SALE OR DISTRIBUTION
            THEREOF. NO SUCH SALE OR DISTRIBUTION MAY BE EFFECTED WITHOUT AN
            EFFECTIVE REGISTRATION STATEMENT RELATED THERETO OR AN OPINION OF
            COUNSEL IN A FORM SATISFACTORY TO THE COMPANY THAT SUCH REGISTRATION
            IS NOT REQUIRED UNDER THE SECURITIES ACT OF 1933. THE SHARES
            REPRESENTED BY THIS CERTIFICATE MAY BE TRANSFERRED ONLY IN
            ACCORDANCE WITH THE TERMS OF AN AGREEMENT BETWEEN THE COMPANY AND
            THE STOCKHOLDER, A COPY OF WHICH IS ON FILE WITH AND MAY BE OBTAINED
            FROM THE SECRETARY OF THE COMPANY AT NO CHARGE.
          </p>
        </div>
      )}
      <h3>Additional Documents</h3>
      <div className="mb-3 col-xl-12">
        <h5 className="mb-2 mt-3">Documents</h5>
        <FileInput
          name="documents"
          className={touched.documents && errors.documents && "is-invalid"}
        />
        {touched.documents && <p className="text-danger">{errors.documents}</p>}
        <small>
          Add any other document or amendment related to this security.
        </small>
      </div>
      <div>{children}</div>
    </Form>
  );
}

export default CommonSharesFormContext;
