import "react-dates/initialize";
import "react-dates/lib/css/_datepicker.css";

import { MenuItem } from "@mui/material";
import { ChatWidget } from "@papercups-io/chat-widget";
import { doc, getFirestore, setDoc } from "firebase/firestore";
import { Field, Form, Formik } from "formik";
import { post } from "jsx/api";
import { UserContext } from "jsx/contexts/user-context";
import MSButton from "jsx/element/button";
import MSField from "jsx/element/ms-field";
import Header4 from "jsx/layout/header4";
import React, { useState } from "react";
import { Link, useHistory } from "react-router-dom";
import * as Yup from "yup";

import countries from "./countries.json";

function InvestorSetupInfo({ location, onboardingFlow, success }) {
  const { user } = React.useContext(UserContext);
  const history = useHistory();
  const [loading, setIsLoading] = useState(false);
  let redirectUrl = location?.search.includes("?redirectUrl=")
    ? document.location.href.split("?redirectUrl=")[1]
    : null;

  const InvestorSetupSchema = Yup.object().shape({
    fullName: Yup.string().required("Enter your name"),
    email: Yup.string().required("Enter your email"),
    investorType: Yup.string().required("Required"),
    trustName: Yup.string().when("investorType", {
      is: "trust",
      then: Yup.string().required("Enter your trust name"),
    }),
    trustLocation: Yup.string().when("investorType", {
      is: "trust",
      then: Yup.string().required("Enter the location of your trust"),
    }),
    revocable: Yup.string().when("investorType", {
      is: "trust",
      then: Yup.string().required("Required"),
    }),
    trustAccreditation: Yup.string().when("investorType", {
      is: "trust",
      then: Yup.string().required("Required"),
    }),
    individualLocation: Yup.string().when("investorType", {
      is: "individual",
      then: Yup.string().required(
        "Enter the location of your legal place of residence"
      ),
    }),
    individualAccreditation: Yup.string().when("investorType", {
      is: "individual",
      then: Yup.string().required("Required"),
    }),
    firmFundName: Yup.string().when("investorType", {
      is: "firmfund",
      then: Yup.string().required("Required"),
    }),
    agree: Yup.boolean()
      .required("You must accept the rules before you submit")
      .oneOf([true], "The terms and conditions must be accepted."),
  });

  const db = getFirestore();

  function Countries() {
    return countries.map((country) => {
      return (
        <MenuItem value={country.code} key={country.code}>
          {country.name}
        </MenuItem>
      );
    });
  }

  return (
    <>
      <Header4 hideAllMenuItems title="Setup account" />

      <Formik
        enableReinitialize
        initialValues={{
          fullName: user.displayName,
          email: user.email,
          investorType: "individual",
          trustName: "",
          trustLocation: "USA",
          revocable: "",
          trustAccreditation: "",
          foreignInvestor: false,
          individualLocation: "USA",
          individualAccreditation: "",
          firmFundName: "",
          firmLocation: "USA",
          firmAccreditation: "",
          individualCountry: "",
          firmFundCountry: "",
          trustCountry: "",
          agree: false,
        }}
        validationSchema={InvestorSetupSchema}
        onSubmit={async (values, errors) => {
          /**Now we have to process the data */
          /**Of course, this thing would be greater to be executed on the back-end */
          const investorTypeCondition = values.investorType;
          const processedData = {
            fullName: values.fullName,
            email: values.email,
            investorType: values.investorType,
            //conditional
            location:
              investorTypeCondition === "individual"
                ? values.individualLocation
                : investorTypeCondition === "trust"
                ? values.trustLocation
                : investorTypeCondition === "firmfund"
                ? values.firmLocation
                : "MD", // for individual, trust, firm
            //individual
            ...(investorTypeCondition === "individual" && {
              accreditation: values.individualAccreditation,
            }),
            //trust
            ...(investorTypeCondition === "trust" && {
              accreditation: values.trustAccreditation,
            }),
            ...(investorTypeCondition === "trust" && {
              revocable: values.revocable,
            }),
            ...(investorTypeCondition === "trust" && {
              trustName: values.trustName,
            }),
            //firmfund
            ...(investorTypeCondition === "firmfund" && {
              firmFundName: values.firmFundName,
            }),
            ...(investorTypeCondition === "firmfund" && {
              accreditation: values.firmAccreditation,
            }),
            agree: values.agree,
          };

          try {
            setIsLoading(true);

            await setDoc(
              doc(db, "users", user.uid, "users_private", "data"),
              {
                displayName: values.fullName,
              },
              { merge: true }
            );

            user.displayName = values.fullName;

            await post("users/setBuyerDetails", {
              accreditation: processedData,
              accreditationStatus: "approved",
            });
            if (success) {
              success();
            }
            await new Promise((r) => setTimeout(r, 500));

            // Number of companies
            if (location.state && location.state.redirectUrl) {
              redirectUrl = location.state.redirectUrl;
            }
            if (redirectUrl) {
              history.replace(redirectUrl);
              setIsLoading(false);
            }
          } catch (e) {
            console.log("error", e);
          }
        }}
      >
        {(formik) => {
          const { values, errors, touched } = formik;

          return (
            <Form>
              <div className="content-body">
                <div className="container mb-5">
                  <div className="row justify-content-center align-items-center">
                    {/* <div className="col-md-2">
                            <SettingsNav />
                        </div> */}
                    <div
                      className={
                        onboardingFlow ? "col-12" : "col-xl-8 col-md-8"
                      }
                    >
                      <MSButton
                        type="outlined light"
                        title="Debug skip small"
                        hidden={!window.location.href.includes("localhost")}
                        onClick={async () => {
                          await post("users/debug", { action: "accredit" });
                        }}
                      />
                      <div className="row">
                        <div className="card outlined">
                          <div
                            className="card-header"
                            style={{ display: "block" }}
                          >
                            <h2>Setup investor account</h2>
                            <p>
                              Setup your account details to see deals and to
                              start investing
                            </p>
                          </div>
                          <div className="card-body">
                            <div className="row">
                              <h4 className="form-section-header mt-2">
                                Personal details
                              </h4>
                              <div className="col-xl-12">
                                <MSField
                                  type="text"
                                  name="fullName"
                                  placeholder="Firstname Lastname"
                                  autoComplete="off"
                                  autoCorrect="off"
                                  autoCapitalize="off"
                                  spellCheck="false"
                                  autoFocus
                                  defaultValue={values.fullName}
                                  formik={formik}
                                />
                              </div>

                              <div className="col-xl-12">
                                <MSField
                                  type="text"
                                  name="email"
                                  defaultValue={values.email}
                                  placeholder="email@domain.com"
                                  disabled
                                  formik={formik}
                                  description="This email is linked to the one you used to
                                  log in."
                                />
                              </div>

                              <h4 className="form-section-header">
                                Accreditation
                              </h4>

                              <div className="mb-0 col-xl-12">
                                <h6 className="form-label">
                                  Will you be investing money as an Individual,
                                  a Trust, or a Firm or Fund?
                                </h6>
                                <div
                                  role="group"
                                  className="d-flex flex-column"
                                >
                                  <label className="m-2">
                                    <Field
                                      className="form-check-input me-2"
                                      type="radio"
                                      name="investorType"
                                      value="individual"
                                    />
                                    Individual
                                  </label>
                                  <label className="m-2">
                                    <Field
                                      className="form-check-input me-2"
                                      type="radio"
                                      name="investorType"
                                      value="trust"
                                    />
                                    Trust
                                  </label>
                                  <label className="m-2">
                                    <Field
                                      className="form-check-input me-2"
                                      type="radio"
                                      name="investorType"
                                      value="firmfund"
                                    />
                                    Firm or Fund
                                  </label>
                                </div>
                                <div className="form-check">
                                  <label>
                                    <Field
                                      className="form-check-input"
                                      type="checkbox"
                                      name="foreignInvestor"
                                    />
                                    I am a non-US based investor.
                                  </label>
                                </div>
                              </div>
                              {values.investorType === "trust" && (
                                <>
                                  <h4 className="form-section-header">Trust</h4>
                                  <div className="mb-0 col-xl-12">
                                    <h6 className="form-label">
                                      What is your trust’s legal name?
                                    </h6>
                                    <MSField
                                      type="text"
                                      name="trustName"
                                      placeholder="Jane Doe Living Trust"
                                      autoComplete="off"
                                      autoCorrect="off"
                                      autoCapitalize="off"
                                      spellCheck="false"
                                      formik={formik}
                                    />
                                  </div>

                                  <div className="mb-0 col-xl-12">
                                    <>
                                      <h6 className="form-label">
                                        Where is your trust located?
                                      </h6>
                                      <MSField
                                        name="trustLocation"
                                        select
                                        disabled={!values.foreignInvestor}
                                        formik={formik}
                                      >
                                        {Countries()}
                                      </MSField>
                                    </>
                                  </div>

                                  <div className="mb-0 col-xl-12">
                                    <h6
                                      className={
                                        "form-label " +
                                        (touched.revocable &&
                                          errors.revocable &&
                                          "is-invalid")
                                      }
                                    >
                                      Is your trust revocable?
                                    </h6>
                                    <div
                                      role="group"
                                      className="d-flex flex-column"
                                    >
                                      <label className="m-2">
                                        <Field
                                          className="form-check-input me-2"
                                          type="radio"
                                          name="revocable"
                                          value="yes"
                                        />
                                        Yes, revocable
                                      </label>
                                      <label className="m-2">
                                        <Field
                                          className="form-check-input me-2"
                                          type="radio"
                                          name="revocable"
                                          value="no"
                                        />
                                        No, irrevocable
                                      </label>
                                    </div>
                                  </div>

                                  <div className="mb-0 col-xl-12">
                                    <h6
                                      className={
                                        "form-label " +
                                        (touched.trustAccreditation &&
                                          errors.trustAccreditation &&
                                          "is-invalid")
                                      }
                                    >
                                      How is your trust accredited?
                                    </h6>
                                    <div
                                      role="group"
                                      className="d-flex flex-column"
                                    >
                                      <label className="m-2">
                                        <Field
                                          className="form-check-input me-2"
                                          type="radio"
                                          name="trustAccreditation"
                                          value="25m"
                                        />
                                        The trust has over $25M in investments
                                      </label>
                                      <label className="m-2">
                                        <Field
                                          className="form-check-input me-2"
                                          type="radio"
                                          name="trustAccreditation"
                                          value="5m"
                                        />
                                        The trust has over $5M in net assets
                                      </label>
                                      {values.revocable === "yes" && (
                                        <label className="m-2">
                                          <Field
                                            className="form-check-input me-2"
                                            type="radio"
                                            name="trustAccreditation"
                                            value="allGrantorsAccredited"
                                          />
                                          All grantors of the trust are
                                          themselves accredited
                                        </label>
                                      )}
                                      <label className="m-2">
                                        <Field
                                          className="form-check-input me-2"
                                          type="radio"
                                          name="trustAccreditation"
                                          value="none"
                                        />
                                        None of the above
                                      </label>
                                    </div>
                                  </div>
                                </>
                              )}

                              {values.investorType === "individual" && (
                                <>
                                  <h4 className="form-section-header">
                                    Individual
                                  </h4>
                                  <div className="col-xl-12">
                                    <h6
                                      className={
                                        "form-label " +
                                        (touched.individualLocation &&
                                          errors.individualLocation &&
                                          "is-invalid")
                                      }
                                    >
                                      Where is your legal place of residence?
                                    </h6>
                                    <>
                                      <MSField
                                        type="text"
                                        name="individualLocation"
                                        disabled={!values.foreignInvestor}
                                        defaultValue={values.individualLocation}
                                        formik={formik}
                                      >
                                        {Countries()}
                                      </MSField>
                                    </>
                                  </div>

                                  <div className="mb-0 col-xl-12">
                                    <h6
                                      className={
                                        "form-label " +
                                        (touched.individualAccreditation &&
                                          errors.individualAccreditation &&
                                          "is-invalid")
                                      }
                                    >
                                      How are you accredited?
                                    </h6>
                                    <div
                                      role="group"
                                      className="individualAccreditation d-flex flex-column"
                                    >
                                      <label className="m-2">
                                        <Field
                                          className="form-check-input me-2"
                                          type="radio"
                                          name="individualAccreditation"
                                          value="5m"
                                        />
                                        I have at least $5M in investments
                                      </label>
                                      <label className="m-2">
                                        <Field
                                          className="form-check-input me-2"
                                          type="radio"
                                          name="individualAccreditation"
                                          value="2.2mto5m"
                                        />
                                        I have between $2.2M and $5M in net
                                        assets
                                      </label>
                                      <label className="m-2">
                                        <Field
                                          className="form-check-input me-2"
                                          type="radio"
                                          name="individualAccreditation"
                                          value="1mto2.2m"
                                        />
                                        I have between $1M and $2.2M in net
                                        assets
                                      </label>
                                      <label className="m-2">
                                        <Field
                                          className="form-check-input me-2"
                                          type="radio"
                                          name="individualAccreditation"
                                          value="200k"
                                        />
                                        I have income of $200k (or $300k jointly
                                        with spouse) for the past 2 years and
                                        expect the same this year
                                      </label>
                                      <label className="m-2">
                                        <Field
                                          className="form-check-input me-2"
                                          type="radio"
                                          name="individualAccreditation"
                                          value="series7"
                                        />
                                        I am a Series 7, Series 65, or Series 82
                                        holder and my license is active and in
                                        good standing
                                      </label>
                                      <label className="m-2">
                                        <Field
                                          className="form-check-input me-2"
                                          type="radio"
                                          name="individualAccreditation"
                                          value="none"
                                        />
                                        I'm not accredited yet
                                      </label>
                                    </div>
                                  </div>
                                </>
                              )}

                              {values.investorType === "firmfund" && (
                                <>
                                  <h4 className="form-section-header">
                                    Firm or Fund
                                  </h4>
                                  <div className="mb-0 col-xl-12">
                                    <h6 className="form-label">
                                      What is your firm or fund's legal name?
                                    </h6>
                                    <MSField
                                      type="text"
                                      name="firmFundName"
                                      placeholder="Investment Ventures, LP"
                                      autoComplete="off"
                                      autoCorrect="off"
                                      autoCapitalize="off"
                                      spellCheck="false"
                                      formik={formik}
                                    />
                                  </div>

                                  <div className="mb-0 col-xl-12">
                                    <>
                                      <h6 className="form-label">
                                        Where is firm or fund located?
                                      </h6>
                                      <MSField
                                        name="firmLocation"
                                        select
                                        disabled={!values.foreignInvestor}
                                        formik={formik}
                                      >
                                        {Countries()}
                                      </MSField>
                                    </>
                                  </div>

                                  <div className="mb-0 col-xl-12">
                                    <h6
                                      className={
                                        "form-label " +
                                        (touched.firmAccreditation &&
                                          errors.firmAccreditation &&
                                          "is-invalid")
                                      }
                                    >
                                      How is your investing entity accredited?
                                    </h6>
                                    <div
                                      role="group"
                                      className="d-flex flex-column"
                                    >
                                      <label className="m-2">
                                        <Field
                                          className="form-check-input me-2"
                                          type="radio"
                                          name="firmAccreditation"
                                          value="25m"
                                        />
                                        The investing entity has over $25M in
                                        investments
                                      </label>
                                      <label className="m-2">
                                        <Field
                                          className="form-check-input me-2"
                                          type="radio"
                                          name="firmAccreditation"
                                          value="5mto25m"
                                        />
                                        The investing entity has between $5M and
                                        $25M in net assets
                                      </label>
                                      <label className="m-2">
                                        <Field
                                          className="form-check-input me-2"
                                          type="radio"
                                          name="firmAccreditation"
                                          value="qualifiedPurchasers"
                                        />
                                        All owners of the investing entity are
                                        qualified purchasers
                                      </label>
                                      <label className="m-2">
                                        <Field
                                          className="form-check-input me-2"
                                          type="radio"
                                          name="firmAccreditation"
                                          value="individuallyAccredited"
                                        />
                                        All owners of the investing entity are
                                        individually accredited
                                      </label>
                                      <label className="m-2">
                                        <Field
                                          className="form-check-input me-2"
                                          type="radio"
                                          name="firmAccreditation"
                                          value="registeredAdvisor"
                                        />
                                        The investing entity is a state or SEC
                                        registered investment adviser or any
                                        exempt reporting adviser
                                      </label>
                                      <label className="m-2">
                                        <Field
                                          className="form-check-input me-2"
                                          type="radio"
                                          name="firmAccreditation"
                                          value="none"
                                        />
                                        None of the above
                                      </label>
                                    </div>
                                  </div>
                                </>
                              )}
                              <h4 className="form-section-header">Agreement</h4>
                              <div className="mb-0 col-xl-12">
                                <h6 className="form-label mt-3 mb-3">
                                  Please read and agree to the following below.
                                </h6>
                                <ol className="rules ms-4">
                                  <li>
                                    I understand that most investments in
                                    startups result in a complete loss.
                                  </li>
                                  <li>
                                    I understand that Modern Share does not
                                    guarantee the accuracy of information on the
                                    site, and I am responsible for my own
                                    diligence.
                                  </li>
                                  <li>
                                    I promise to hold Modern Share harmless
                                    against any damage that may happen to myself
                                    as a result of my use of Modern Share.
                                  </li>
                                  <li>
                                    If I invest, I will comply with securities
                                    laws and consult my own attorney and
                                    professional advisors where I need advice.
                                  </li>
                                  <li>
                                    I understand that I will be permanently
                                    banned from Modern Share and that I may face
                                    legal consequences if I falsely represent my
                                    accreditation status.
                                  </li>
                                  <li>
                                    I understand that this site contains
                                    confidential information and I am entering
                                    into to a{" "}
                                    <Link
                                      style={{
                                        textDecoration: "underline",
                                      }}
                                      to="/non-disclosure"
                                    >
                                      Non-disclosure Agreement
                                    </Link>
                                    .
                                  </li>
                                  <li>
                                    I acknowledge and agree that, following my
                                    completion of a transaction on the Modern
                                    Share platform (if any), I will pay Modern
                                    Share a transaction fee of up to five
                                    percent (5%) of the gross value of each such
                                    completed transaction.
                                  </li>
                                  <li>
                                    I have read Modern Share's{" "}
                                    <Link
                                      style={{ textDecoration: "underline" }}
                                      to="/disclaimers-disclosures"
                                    >
                                      disclosures and disclaimers
                                    </Link>
                                    .
                                  </li>
                                </ol>
                                <div className="form-check">
                                  <label
                                    className={
                                      "agree-text " +
                                      (touched.agree &&
                                        errors.agree &&
                                        "is-invalid")
                                    }
                                  >
                                    <Field
                                      className="form-check-input"
                                      type="checkbox"
                                      name="agree"
                                    />
                                    I agree to the terms above and to{" "}
                                    <Link
                                      style={{ textDecoration: "underline" }}
                                      to="/terms-condition"
                                    >
                                      Modern Share's terms and conditions
                                    </Link>
                                    .
                                  </label>
                                </div>
                              </div>

                              <div className="col-12 mt-5">
                                <MSButton
                                  size="large"
                                  title="Setup account"
                                  loading={loading}
                                  className="w-100"
                                  variant="primary"
                                  type="submit"
                                />
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </Form>
          );
        }}
      </Formik>

      <ChatWidget
        // `accountId` is used instead of `token` in older versions
        // of the @papercups-io/chat-widget package (before v1.2.x).
        // You can delete this line if you are on the latest version.
        // accountId="a7012e56-3103-4b84-a651-c6f3e379085c"
        token="a7012e56-3103-4b84-a651-c6f3e379085c"
        inbox="5e418ce4-0b89-429b-ad0e-803a77ad2dfb"
        title="Welcome to Modern Share"
        subtitle="Having any trouble? Write us a message below."
        primaryColor="#1890ff"
        greeting="This is Vu from Modern Share. Let me know if you need any help or if you encounter any issues using the software."
        newMessagePlaceholder="Start typing..."
        showAgentAvailability={false}
        agentAvailableText="We're online right now!"
        agentUnavailableText="We're away at the moment."
        requireEmailUpfront={false}
        iconVariant="outlined"
        baseUrl="https://app.papercups.io"
        // Optionally include data about your customer here to identify them
        // customer={{
        //   name: __CUSTOMER__.name,
        //   email: __CUSTOMER__.email,
        //   external_id: __CUSTOMER__.id,
        //   metadata: {
        //     plan: "premium"
        //   }
        // }}
      />
    </>
  );
}

export default InvestorSetupInfo;
