import { regular } from "@fortawesome/fontawesome-svg-core/import.macro";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
  Box,
  InputAdornment,
  Switch,
  Tab,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableRow,
  Tabs,
  Tooltip,
} from "@mui/material";
import { Form, Formik } from "formik";
import { post } from "jsx/api";
import MSButton from "jsx/element/button";
import Divider from "jsx/element/divider";
import MSDropdown, { MSDropdownItem } from "jsx/element/dropdown";
import MSField from "jsx/element/ms-field";
import { camelToTitle } from "jsx/utils";
import React, { useState } from "react";
import { Modal } from "react-bootstrap";
import NumberFormat from "react-number-format";

import SecurityModal from "./seller-security-modal";

export default function OrderForms({
  orgName,
  pricePerShare,
  sellableQuantity,
  sellableDollars,
  amountAvailableDollars,
  secondaryId,
  organizationId,
  onOrderSubmitted,
  securities,
  onOrderPreview,
}) {
  const [sellMode, setSellMode] = React.useState("securities");

  return (
    <>
      <div className="card-header" style={{ display: "block" }}>
        <div className="d-flex flex-row">
          <div className="flex-grow-1">
            <h4 className="card-title">Sell {orgName}</h4>
          </div>
          {/* <MSDropdown
            size="small"
            title={
              sellMode === "dollar" ? "By dollar amount" : "Select securities"
            }
            anchorOrigin={{
              vertical: "bottom",
              horizontal: "right",
            }}
            transformOrigin={{
              vertical: "top",
              horizontal: "right",
            }}
          >
            <MSDropdownItem
              onClick={() => {
                setSellMode("dollar");
              }}
            >
              By dollar amount
            </MSDropdownItem>
            <MSDropdownItem
              onClick={() => {
                setSellMode("securities");
              }}
            >
              Select securities
            </MSDropdownItem>
          </MSDropdown> */}
        </div>
      </div>
      <div className="card-body pt-5">
        {sellMode === "dollar" && (
          <OrderFormDollars
            orgName={orgName}
            pricePerShare={pricePerShare}
            sellableDollars={sellableDollars}
            organizationId={organizationId}
            secondaryId={secondaryId}
            onOrderSubmitted={onOrderSubmitted}
          />
        )}

        {sellMode === "securities" && (
          <OrderFormSecurities
            orgName={orgName}
            pricePerShare={pricePerShare}
            sellableDollars={sellableDollars}
            sellableQuantity={sellableQuantity}
            organizationId={organizationId}
            secondaryId={secondaryId}
            securities={securities}
            onOrderSubmitted={onOrderSubmitted}
            onOrderPreview={onOrderPreview}
          />
        )}
      </div>
    </>
  );
}

export function OrderFormDollars({
  pricePerShare,
  sellableDollars,
  amountAvailableDollars,
  secondaryId,
  organizationId,
  onOrderSubmitted,
}) {
  // Select dollar amount to sell
  const [isReviewing, setIsReviewing] = useState(false);
  const [orderPreview, setOrderPreview] = useState();
  const [submitting, setSubmitting] = useState(false);
  const [agreed, setAgreed] = useState();

  async function confirmOrder() {
    setSubmitting(true);
    try {
      await post("secondaries/createSellOrder", {
        secondaryId: secondaryId,
        organizationId: organizationId,
        sellSecuritiesOrder: orderPreview.securities,
      });
    } catch (e) {
      console.error("Create sell order error", e);
    }
    setSubmitting(false);
    setIsReviewing(false);
    await onOrderSubmitted();
  }

  return (
    <>
      <Modal
        show={isReviewing}
        contentClassName="larger-modal"
        dialogClassName="larger-modal"
      >
        <Modal.Header>
          <div>
            <h4>Step 2 of 5</h4>
            <h3>Review order</h3>
          </div>

          <MSButton
            variant="icon"
            style={{ width: 40, height: 40, marginTop: -18 }}
            onClick={() => {
              setIsReviewing(false);
            }}
          >
            <FontAwesomeIcon icon={regular("close")} size="xs" />
          </MSButton>
        </Modal.Header>
        <div className="p-4">
          <>
            <div>
              <OrderPreview
                pricePerShare={pricePerShare}
                previewDetails={orderPreview}
              />
            </div>
            <h5>Agreement</h5>
            <Agreement />
            <div className="d-flex justify-content-between align-items-center mb-2">
              <div>
                <p
                  className="mb-2"
                  style={{ color: "var(--primary)", fontWeight: 500 }}
                >
                  By confirming my order, I acknowledge that I understand and
                  accept this information.
                </p>
              </div>
              <div>
                <Switch onChange={(event) => setAgreed(event.target.checked)} />
              </div>
            </div>
            <div className="d-flex justify-content-end">
              <MSButton
                size="small"
                className="me-2"
                onClick={() => {
                  setIsReviewing(false);
                }}
              >
                Cancel
              </MSButton>
              <MSButton
                size="small"
                variant="primary"
                loading={submitting}
                onClick={confirmOrder}
                disabled={!agreed}
              >
                Next
              </MSButton>
            </div>
          </>
        </div>
      </Modal>

      <Formik
        enableReinitialize={true}
        initialValues={{ sellAmount: 0 }}
        onSubmit={async (values, { resetForm }) => {
          const { sellAmount } = values;
          const result = await post("secondaries/createSellOrderRequest", {
            organizationId,
            secondaryId,
            totalInCents: Number(sellAmount * 100),
          });
          setOrderPreview(result.data);
          setIsReviewing(true);
          resetForm();
        }}
      >
        {(formik) => {
          const { values, isSubmitting, errors, setFieldValue } = formik;

          if (errors && Object.keys(errors).length > 0) {
            console.error("Sell order request form errors", errors);
          }
          return (
            <Form>
              <div className="d-flex justify-content-between">
                <p>Price per unit</p>
                <p>
                  {pricePerShare?.toLocaleString("en-US", {
                    minimumFractionDigits: 2,
                    maximumFractionDigits: 2,
                    currency: "USD",
                    style: "currency",
                  })}
                </p>
              </div>
              <div className="d-flex justify-content-between mt-3">
                <p>Max sellable</p>
                <p>
                  {sellableDollars?.toLocaleString("en-US", {
                    maximumFractionDigits: 0,
                    currency: "USD",
                    style: "currency",
                  })}
                </p>
              </div>
              <div className="d-flex justify-content-between align-items-center">
                <p>Amount to sell</p>
                <div>
                  <NumberFormat
                    name="sellAmount"
                    customInput={MSField}
                    size="small"
                    placeholder="0"
                    isNumericString={true}
                    thousandSeparator={true}
                    allowNegative={false}
                    onValueChange={(val) =>
                      setFieldValue("sellAmount", val.floatValue)
                    }
                    formik={formik}
                    inputProps={{
                      style: { textAlign: "right" },
                      "data-lpignore": "true",
                    }}
                    InputProps={{
                      startAdornment: (
                        <InputAdornment
                          position="start"
                          style={{ outline: "none" }}
                        >
                          $
                        </InputAdornment>
                      ),
                      sx: {
                        "&::after": {
                          borderColor: "black",
                        },
                      },
                    }}
                  />
                </div>
              </div>
              <div className="d-flex justify-content-end mt-2">
                <MSButton
                  size="small"
                  variant="primary"
                  type="submit"
                  loading={isSubmitting}
                  disabled={!values.sellAmount}
                >
                  Preview
                </MSButton>
              </div>
            </Form>
          );
        }}
      </Formik>
    </>
  );
}

export function Agreement() {
  return (
    <Box sx={{ color: "var(--gray-600)" }}>
      <p className="mb-2">Please be aware:</p>
      <p className="mb-2">
        1. Tax laws are complex. You are urged to consult with your personal tax
        advisor to understand the effect of your proposed sale of units.
      </p>
      <p className="mb-2">
        2. Your sale of units will be a taxable transaction.
      </p>
      <p className="mb-2">
        3. If you are exercising options in connection with your sale,
        additional considerations apply. The proceeds payable to you will be net
        of applicable exercise prices and, under certain facts and
        circumstances, may be subject to withholding by your employer. In
        addition, if your options are intended to qualify as ISOs, your sale may
        constitute a disqualifying disposition resulting in treatment of your
        options similar to the treatment applicable to an NSO.
      </p>
    </Box>
  );
}

export function OrderFormSecurities({
  pricePerShare,
  sellableDollars,
  sellableQuantity,
  amountAvailableDollars,
  secondaryId,
  organizationId,
  securities,
  onOrderSubmitted,
  onOrderPreview,
}) {
  // Select dollar amount to sell
  const [isReviewing, setIsReviewing] = useState(false);
  const [orderPreview, setOrderPreview] = useState();
  const [submitting, setSubmitting] = useState(false);
  const [agreed, setAgreed] = useState();
  const [showCert, setShowCert] = useState();
  const [selectedSecurity, setSelectedSecurity] = useState();

  async function confirmOrder() {
    // setSubmitting(true);
    // try {
    //   await post("secondaries/createSellOrder", {
    //     secondaryId: secondaryId,
    //     organizationId: organizationId,
    //     sellSecuritiesOrder: orderPreview.securities,
    //   });
    // } catch (e) {
    //   console.error("Create sell order error", e);
    // }
    // setSubmitting(false);
    // setIsReviewing(false);
    // await onOrderSubmitted();
  }

  function showSecurityModal(security) {
    setSelectedSecurity(security);
    setShowCert(true);
  }

  return (
    <>
      <Modal
        show={isReviewing}
        contentClassName="larger-modal"
        dialogClassName="larger-modal"
      >
        <Modal.Header>
          <h3>Review order</h3>
          <MSButton
            variant="icon"
            style={{ width: 40, height: 40, marginTop: -18 }}
            onClick={() => {
              setIsReviewing(false);
            }}
          >
            <FontAwesomeIcon icon={regular("close")} size="xs" />
          </MSButton>
        </Modal.Header>
        <div className="p-4">
          <>
            <div>
              <OrderPreview
                pricePerShare={pricePerShare}
                previewDetails={orderPreview}
              />
            </div>
            <h5>Agreement</h5>
            <Agreement />
            <div className="d-flex justify-content-between align-items-center mb-2">
              <div>
                <p
                  className="mb-2"
                  style={{ color: "var(--primary)", fontWeight: 500 }}
                >
                  By confirming my order, I acknowledge that I understand and
                  accept this information.
                </p>
              </div>
              <div>
                <Switch onChange={(event) => setAgreed(event.target.checked)} />
              </div>
            </div>
            <div className="d-flex justify-content-end">
              <MSButton
                size="small"
                className="me-2"
                onClick={() => {
                  setIsReviewing(false);
                }}
              >
                Cancel
              </MSButton>
              <MSButton
                size="small"
                variant="primary"
                loading={submitting}
                onClick={confirmOrder}
                disabled={!agreed}
              >
                Next
              </MSButton>
            </div>
          </>
        </div>
      </Modal>

      <Formik
        enableReinitialize={true}
        initialValues={Object.fromEntries(
          securities?.map((security) => [
            security.id,
            { quantity: 0, priority: "" },
          ])
        )}
        onSubmit={async (values, { resetForm }) => {
          const securitiesBody = Object.entries(values).flatMap(
            ([securityId, { quantity, priority }]) =>
              quantity > 0 ? [{ securityId, quantity, priority }] : []
          );
          if (securitiesBody.length < 1) {
            return;
          }
          const result = await post("secondaries/createSellOrderRequest", {
            organizationId,
            secondaryId,
            securities: securitiesBody,
          });
          const mergedPriorities = (result?.data?.securities ?? []).flatMap(
            (security) => {
              const submittedSec = securitiesBody.find(
                (sec) => sec.securityId === security.securityId
              );
              if (submittedSec) {
                return [{ ...security, priority: submittedSec.priority }];
              }
              return [];
            }
          );
          const orderPreview = { ...result.data, securities: mergedPriorities };

          setOrderPreview(orderPreview);
          onOrderPreview(orderPreview);
          setIsReviewing(true);
          resetForm();
        }}
      >
        {(formik) => {
          const { values, isSubmitting, errors, setFieldValue, touched } =
            formik;

          if (errors && Object.keys(errors).length > 0) {
            console.error("Sell order request form errors", errors);
          }
          return (
            <Form>
              <SecurityModal
                open={showCert}
                onClose={() => setShowCert(false)}
                security={selectedSecurity}
              />

              <div className="mb-2">
                <Detail title="Price per unit" icon={regular("money-bill")}>
                  {pricePerShare?.toLocaleString("en-US", {
                    minimumFractionDigits: 2,
                    maximumFractionDigits: 2,
                    currency: "USD",
                    style: "currency",
                  })}
                </Detail>

                <Detail
                  title="Max sellable units"
                  icon={regular("file-contract")}
                >
                  {sellableQuantity?.toLocaleString("en-US", {
                    maximumFractionDigits: 0,
                  })}{" "}
                  units
                </Detail>
              </div>

              <div
                className="d-flex"
                style={{
                  fontSize: "13px",
                  color: "#6c757d",
                  fontWeight: "500",
                  paddingTop: 20,
                  paddingBottom: 20,
                }}
              >
                <div className="col-6">Security</div>
                <Tooltip
                  placement="top"
                  title="This allows you to set the priority of the securities you want
                  to sell in the event of a cutback. Securities with lower
                  priority will be cutback before securities with higher
                  priority. The highest priority is 1.

                  If no priority is assigned and there is a cutback, the cutback
                  will be applied to all of your securities on a pro rata basis.
                  To learn more about how cutbacks work, please review the Offer
                  to Purchase or visit our help page."
                >
                  <div className="d-flex col-3">
                    <div>Priority</div>
                    <FontAwesomeIcon
                      icon={regular("circle-info")}
                      style={{ marginLeft: 2, marginTop: 4 }}
                    />
                  </div>
                </Tooltip>
                <div className="col-3">Quantity</div>
              </div>

              {securities?.map((security, i) => {
                return (
                  <>
                    <Divider />

                    <div className="d-flex" key={i}>
                      <div
                        className="col-6"
                        style={{ paddingTop: 12, paddingBottom: 12 }}
                      >
                        <p>
                          {security.certificateNo}{" "}
                          <FontAwesomeIcon
                            onClick={() => showSecurityModal(security)}
                            cursor="pointer"
                            color="var(--gray-500)"
                            icon={regular("circle-info")}
                          />{" "}
                        </p>
                        <p style={{ color: "#888", fontSize: 12 }}>
                          {security.meta.sellable.toLocaleString("en-US")}{" "}
                          available
                        </p>
                        <p style={{ color: "#888", fontSize: 12 }}>
                          {/* {camelToTitle(security.category)} */}
                          {"Unit Grant"}
                        </p>
                      </div>
                      <div className="col-3" style={{ paddingRight: 16 }}>
                        {" "}
                        <NumberFormat
                          name="Priority"
                          customInput={MSField}
                          decimalScale={0}
                          size="small"
                          placeholder=""
                          isNumericString={true}
                          thousandSeparator={true}
                          allowNegative={false}
                          onValueChange={(val) =>
                            setFieldValue(security.id, {
                              ...values[security.id],
                              priority: val.floatValue
                                ? Number(val.floatValue)
                                : val.floatValue,
                            })
                          }
                          formik={formik}
                          inputProps={{
                            style: { textAlign: "right" },
                            "data-lpignore": "true",
                          }}
                          InputProps={{
                            sx: {
                              "&::after": {
                                borderColor: "black",
                              },
                            },
                          }}
                        />
                      </div>
                      <div className="col-3">
                        <NumberFormat
                          name="Units"
                          customInput={MSField}
                          size="small"
                          placeholder="0"
                          isNumericString={true}
                          thousandSeparator={true}
                          allowNegative={false}
                          onValueChange={(val) => {
                            setFieldValue(security.id, {
                              ...values[security.id],
                              quantity: val.floatValue,
                            });
                          }}
                          isAllowed={(vals) => {
                            const currentSum = Object.entries(values)
                              .flatMap(([securityId, secVals]) =>
                                securityId !== security.id
                                  ? [secVals?.quantity ?? 0]
                                  : []
                              )
                              .reduce((acc, sum) => acc + sum, 0);
                            const { floatValue } = vals;
                            if (floatValue && Number(floatValue)) {
                              return (
                                floatValue + currentSum <= sellableQuantity
                              );
                            }
                            return true;
                          }}
                          formik={formik}
                          inputProps={{
                            style: { textAlign: "right" },
                            "data-lpignore": "true",
                          }}
                          InputProps={{
                            sx: {
                              "&::after": {
                                borderColor: "black",
                              },
                            },
                          }}
                        />
                      </div>
                    </div>
                  </>
                );
              })}
              <div className="d-flex justify-content-end mt-2">
                <MSButton
                  size="small"
                  variant="primary"
                  type="submit"
                  loading={isSubmitting}
                  disabled={
                    !Object.values(values).some(({ quantity }) => quantity > 0)
                  }
                >
                  Preview
                </MSButton>
              </div>
            </Form>
          );
        }}
      </Formik>
    </>
  );
}

function OrderPreview({
  pricePerShare,
  previewDetails: {
    grossProceedsInCents,
    exerciseCostInCents,
    netProceedsInCents,
    securities,
  },
}) {
  const [showSecurity, setShowSecurity] = useState({});
  return (
    <>
      <h5>Sell confirmation</h5>
      <TableContainer>
        <Table>
          <TableBody>
            {securities.map((security, i) => {
              return (
                <TableRow key={i}>
                  <TableCell>
                    {security.certificateNo} @{" "}
                    {pricePerShare?.toLocaleString("en-US", {
                      minimumFractionDigits: 2,
                      maximumFractionDigits: 2,
                      currency: "USD",
                      style: "currency",
                    })}
                    <br />× {security.quantity}
                    {showSecurity[security.certificateNo] && (
                      <div style={{ color: "var(--gray-600)" }}>
                        <div>Proceeds</div>
                      </div>
                    )}
                  </TableCell>
                  <TableCell align="right">
                    {(grossProceedsInCents / 100).toLocaleString("en-US", {
                      minimumFractionDigits: 2,
                      maximumFractionDigits: 2,
                      currency: "USD",
                      style: "currency",
                    })}
                    <p
                      className="mb-0"
                      style={{
                        cursor: "pointer",
                        color: "var(--gray-600)",
                        fontSize: 14,
                      }}
                      onClick={() => {
                        setShowSecurity({
                          ...showSecurity,
                          [security.certificateNo]:
                            !showSecurity[security.certificateNo],
                        });
                      }}
                    >
                      {showSecurity[security.certificateNo] ? "Hide" : "View"}{" "}
                      details
                    </p>
                    {showSecurity[security.certificateNo] && (
                      <div style={{ color: "var(--gray-600)" }}>
                        <div>
                          {(
                            security.estimatedProceeds.grossProceedsInCents /
                            100
                          ).toLocaleString("en-US", {
                            minimumFractionDigits: 2,
                            maximumFractionDigits: 2,
                            currency: "USD",
                            style: "currency",
                          })}
                        </div>
                      </div>
                    )}
                  </TableCell>
                </TableRow>
              );
            })}
          </TableBody>
        </Table>
      </TableContainer>
      <h5>Order summary</h5>
      <TableContainer>
        <Table>
          <TableBody>
            <TableRow>
              <TableCell>Gross proceeds</TableCell>
              <TableCell align="right">
                {(grossProceedsInCents / 100).toLocaleString("en-US", {
                  minimumFractionDigits: 2,
                  maximumFractionDigits: 2,
                  currency: "USD",
                  style: "currency",
                })}
              </TableCell>
            </TableRow>
            <TableRow>
              <TableCell>Exercise costs</TableCell>
              <TableCell align="right">
                {exerciseCostInCents ? "-" : ""}
                {(exerciseCostInCents / 100).toLocaleString("en-US", {
                  minimumFractionDigits: 2,
                  maximumFractionDigits: 2,
                  currency: "USD",
                  style: "currency",
                })}
              </TableCell>
            </TableRow>
            <TableRow>
              <TableCell>Estimated proceeds</TableCell>
              <TableCell align="right">
                {(netProceedsInCents / 100).toLocaleString("en-US", {
                  minimumFractionDigits: 2,
                  maximumFractionDigits: 2,
                  currency: "USD",
                  style: "currency",
                })}
              </TableCell>
            </TableRow>
          </TableBody>
        </Table>
      </TableContainer>
    </>
  );
}

// helper component for formatting details/info
function Detail({ title, icon, children }) {
  return (
    <div className="p-2">
      <div className="d-flex">
        <FontAwesomeIcon
          icon={icon}
          size="sm"
          style={{ marginRight: 12, marginTop: 2, color: "var(--gray-500)" }}
        />

        <h5 style={{ color: "var(--gray-500)" }}>{title}</h5>
        <div style={{ flexGrow: 1 }} />
        <h5>{children}</h5>
      </div>
    </div>
  );
}
