import React from "react";
import NumberFormat from "react-number-format";
function NumberFormatted({ onReturn, ...props }) {
  const handleReturn = (values) => {
    onReturn(props.id, values.floatValue !== undefined ? values.floatValue : 0);
  };
  return (
    <NumberFormat
      {...props}
      onValueChange={handleReturn}
      className={"form-control " + props.className}
      thousandSeparator={true}
      prefix={""}
    />
  );
}
export default NumberFormatted;
