import { doc, getFirestore, updateDoc } from "firebase/firestore";
import { Field, Form, Formik } from "formik";
import { useState } from "react";

export default function UpdateSecondary({
  organizationId,
  secondary,
  handleUpdate,
}) {
  const db = getFirestore();
  const [isUpdatingSecondary, setIsUpdatingSecondary] = useState(false);

  const secondaryId = secondary.id;

  const statuses = [
    "active.setup",
    "active.automated_matching",
    "active.ordering.buyer",
    "active.ordering.seller",
    "active.matching",
    "active.closing",
    "complete",
    "canceled",
  ];

  const updateSecondaryStatus = async (status) => {
    const secondaryRef = doc(
      db,
      "organizations",
      organizationId,
      "organizations_private",
      "data",
      "secondaries",
      secondaryId
    );
    await updateDoc(secondaryRef, { status });
    handleUpdate();
  };

  return (
    <Formik
      initialValues={{ status: "" }}
      onSubmit={async (values, { resetForm }) => {
        setIsUpdatingSecondary(true);
        const { status } = values;
        if (status) await updateSecondaryStatus(status);
        resetForm({ status: "" });
        setIsUpdatingSecondary(false);
      }}
    >
      {(props) => (
        <Form className="d-flex">
          <Field as="select" name="status" className="me-2 form-control">
            <option defaultValue value="">
              Select a status
            </option>
            {statuses.map((status) => (
              <option value={status} key={status}>
                {status}
              </option>
            ))}
          </Field>
          <button
            type="submit"
            className="btn btn-secondary form-control"
            disabled={!props.dirty || isUpdatingSecondary}
          >
            Update status
          </button>
        </Form>
      )}
    </Formik>
  );
}
