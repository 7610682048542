import {
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
} from "@mui/material";
import { toCapcase } from "jsx/helpers";

const orderStatusMap = {
  draft: "pending",
  open: "submitted",
  closed: "completed",
  canceled: "canceled",
};

export default function OrdersTable({ orders }) {
  return (
    <>
      <TableContainer>
        <Table>
          <TableHead>
            <TableRow>
              <TableCell>Certificate</TableCell>
              <TableCell align="right">Status</TableCell>
              <TableCell align="right">Quantity</TableCell>
              <TableCell align="right">Price per unit</TableCell>
              <TableCell align="right">Total</TableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            {orders.map((order, i) => {
              const pricePerShare =
                order.pricePerShareInBaseUnits / order.pricePerSharePrecision;
              const total = order.quantity * pricePerShare;
              return (
                <TableRow key={i}>
                  <TableCell>{order.securityDetails.certificateNo}</TableCell>
                  <TableCell align="right">
                    {toCapcase(orderStatusMap[order.status] ?? order.status)}
                  </TableCell>
                  <TableCell align="right">
                    {order.quantity}{" "}
                    {order.securityDetails.securityCategory === "optionGrant"
                      ? "units"
                      : "units"}
                  </TableCell>
                  <TableCell align="right">
                    {pricePerShare.toLocaleString("en-US", {
                      style: "currency",
                      currency: "USD",
                    })}
                  </TableCell>
                  <TableCell align="right">
                    {total.toLocaleString("en-US", {
                      style: "currency",
                      currency: "USD",
                      maximumFractionDigits: 2,
                    })}
                  </TableCell>
                </TableRow>
              );
            })}
          </TableBody>
        </Table>
      </TableContainer>
    </>
  );
}
