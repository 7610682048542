import { regular } from "@fortawesome/fontawesome-svg-core/import.macro";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import logo from "images/logo-400.png";
import { UserContext } from "jsx/contexts/user-context";
import MSDropdown from "jsx/element/dropdown";
import React, { useEffect, useState } from "react";
import { NavDropdown } from "react-bootstrap";
import { useMediaQuery } from "react-responsive";
import { Link } from "react-router-dom";

function Header5(props) {
  const [offset, setOffset] = useState(0);
  const { user, selectedOrganization } = React.useContext(UserContext);

  const isMobile = useMediaQuery({ minWidth: 0, maxWidth: 500 });

  useEffect(() => {
    window.onscroll = () => {
      setOffset(window.pageYOffset);
    };
  }, []);

  return (
    <>
      <div className={"header " + (offset > 60 ? "fixed" : "")}>
        <div className="container-fluid" style={{ padding: isMobile ? 8 : 0 }}>
          <div className="row">
            <div className="col-xl-12 navigation">
              <div className="navbar d-flex">
                <div style={{ height: "30px" }}>
                  <img alt="" src={logo} style={{ height: "100%" }} />
                </div>

                {user.email && (
                  <MSDropdown title="Account">
                    <Link
                      to={
                        selectedOrganization === "personal"
                          ? "/investor/dashboard"
                          : "/org/dashboard"
                      }
                      className="dropdown-item"
                    >
                      <i className="la la-user"></i> Dashboard
                    </Link>
                    <NavDropdown.Divider />
                    <Link to={"/logout"} className="dropdown-item logout">
                      <i className="la la-sign-out"></i> Logout
                    </Link>
                  </MSDropdown>
                )}
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
}

export default Header5;
