import { Formik } from "formik";
import CurrencyInputV2 from "jsx/element/currency-input-v2";
import CurrencySelect from "jsx/element/currency-select/currency-select";
import DatePicker from "jsx/element/date-picker";
import FileInput from "jsx/element/file-input";
import PercentInput from "jsx/element/percent-input";
import React from "react";
import { useHistory } from "react-router-dom";
import * as Yup from "yup";

import SecurityHolderType from "../captable-security-holder-type.json";
import InterestAccrualPeriod from "./interest-accrual-period.json";
import InterestCompound from "./interest-compound.json";
function ConvertibleAddNote({ onAdd }) {
  const history = useHistory();
  const ConvertibleNotesSchema = Yup.object().shape({
    email: Yup.string().email("Invalid email").required("Required"),
    // stackHolderType: Yup.string().required('Required'),
    firstName: Yup.string().when("stackHolderType", {
      is: "individual",
      then: Yup.string()
        .min(2, "Too Short!")
        .max(50, "Too Long!")
        .required("Required"),
    }),
    lastName: Yup.string().when("stackHolderType", {
      is: "individual",
      then: Yup.string()
        .min(2, "Too Short!")
        .max(50, "Too Long!")
        .required("Required"),
    }),
    organizationName: Yup.string().when("stackHolderType", {
      is: "organization",
      then: Yup.string().required("Required"),
    }),
    securityHolderRelationship: Yup.string().required("Required"),
    relationShipStartDate: Yup.string().required("Required"),
    maturityDate: Yup.string().required("Required"),
    principal: Yup.string().required("Required"),
    currency: Yup.string().required("Required"),
    issueDate: Yup.string().required("Required"),
    interestAccrualPeriod: Yup.string().required("Required"),
    interestRateAnnual: Yup.string().required("Required"),
    dayCountBasis: Yup.string().required("Required"),
    formOfConvertibleNote: Yup.string().required("Required"),
  });
  return (
    <Formik
      initialValues={{
        email: "",
        stackHolderType: "individual",
        firstName: "",
        lastName: "",
        organizationName: "",
        securityHolderRelationship: "",
        relationShipStartDate: "",
        maturityDate: "",
        principal: "",
        otherConsideration: "",
        currency: "",
        boardApprovalDate: "",
        issueDate: "",
        valuationCap: "",
        valuationCapType: "",
        conversionDiscount: "",
        interestAccrualPeriod: "",
        interestRateAnnual: "",
        interestPayout: "",
        interestCompound: "",
        dayCountBasis: "",
        formOfConvertibleNote: "",
        notePurchaseAgreement: "",
        additionalDocuments: "",
      }}
      validationSchema={ConvertibleNotesSchema}
      onSubmit={(data) => {
        history.push(`/captable/convertible-notes/`);
        console.log(data);
        onAdd(data);
      }}
    >
      {({
        touched,
        errors,
        values,
        handleSubmit,
        handleChange,
        handleBlur,
      }) => {
        return (
          <form onSubmit={handleSubmit}>
            <div className="card-header">
              <h4 className="card-title">Add convertible note</h4>
            </div>
            <div className="mb-3 col-xl-12">
              <h5 className="mb-2 mt-3">Email</h5>
              <input
                value={values.email}
                name="email"
                className={
                  "form-control mb-3 " +
                  (touched.email && errors.email && "is-invalid")
                }
                onChange={handleChange}
                onBlur={handleBlur}
              />
              {touched.email && <p className="text-danger">{errors.email}</p>}
            </div>
            <div className="mb-3 col-xl-12">
              <h5 className="mb-2 mt-3">Stakeholder type</h5>
              <div
                role="group"
                className="d-flex flex-column"
                aria-labelledby="my-radio-group"
              >
                <label>
                  <input
                    type="radio"
                    className="form-check-input"
                    name="stackHolderType"
                    defaultChecked={true}
                    value="individual"
                    onChange={handleChange}
                    onBlur={handleBlur}
                  />
                  Individual
                </label>
                <label>
                  <input
                    type="radio"
                    className="form-check-input"
                    name="stackHolderType"
                    value="organization"
                    onChange={handleChange}
                    onBlur={handleBlur}
                  />
                  Organization
                </label>
              </div>
              {touched.stackHolderType && (
                <p className="text-danger">{errors.stackHolderType}</p>
              )}
              {values.stackHolderType === "individual" ? (
                <>
                  <div className="mb-3 col-xl-12">
                    <h5 className="mb-2 mt-3">First Name</h5>
                    <input
                      value={values.firstName}
                      name="firstName"
                      className={
                        "form-control mb-3 " +
                        (touched.firstName && errors.firstName && "is-invalid")
                      }
                      onChange={handleChange}
                      onBlur={handleBlur}
                    />
                    {touched.firstName && (
                      <p className="text-danger">{errors.firstName}</p>
                    )}
                  </div>
                  <div className="mb-3 col-xl-12">
                    <h5 className="mb-2 mt-3">Last Name</h5>
                    <input
                      value={values.lastName}
                      name="lastName"
                      className={
                        "form-control mb-3 " +
                        (touched.lastName && errors.lastName && "is-invalid")
                      }
                      onChange={handleChange}
                      onBlur={handleBlur}
                    />
                    {touched.lastName && (
                      <p className="text-danger">{errors.lastName}</p>
                    )}
                  </div>
                </>
              ) : (
                <>
                  <div className="mb-3 col-xl-12">
                    <h5 className="mb-2 mt-3">Organization Name</h5>
                    <input
                      value={values.organizationName}
                      name="organizationName"
                      className={
                        "form-control mb-3 " +
                        (touched.organizationName &&
                          errors.organizationName &&
                          "is-invalid")
                      }
                      onChange={handleChange}
                      onBlur={handleBlur}
                    />
                    {touched.organizationName && (
                      <p className="text-danger">{errors.organizationName}</p>
                    )}
                  </div>
                </>
              )}
              <small>
                Non-individual investors are entities like investment firms,
                funds, trusts, etc.
              </small>
              <div className="mb-3 col-xl-12">
                <h5 className="mb-2 mt-3">Security holder relationship</h5>
                <select
                  name="securityHolderRelationship"
                  value={values.securityHolderRelationship}
                  onChange={handleChange}
                  onBlur={handleBlur}
                  className={
                    "form-select mb-3 " +
                    (touched.securityHolderRelationship &&
                      errors.securityHolderRelationship &&
                      "is-invalid")
                  }
                >
                  <option value=""></option>
                  {SecurityHolderType.map((item) => {
                    return <option key={item.value}>{item.name}</option>;
                  })}
                </select>
                {touched.securityHolderRelationship && (
                  <p className="text-danger">
                    {errors.securityHolderRelationship}
                  </p>
                )}
              </div>
              <div className="mb-3 col-xl-12">
                <h5 className="mb-2 mt-3">Relationship start date</h5>
                <DatePicker
                  name="relationShipStartDate"
                  className={
                    "form-control mb-3 " +
                    (touched.relationShipStartDate &&
                      errors.relationShipStartDate &&
                      "is-invalid")
                  }
                />
                {touched.relationShipStartDate && (
                  <p className="text-danger">{errors.relationShipStartDate}</p>
                )}
              </div>
              <h3 className="mt-5">Note Details</h3>
              <div className="mb-3 col-xl-12">
                <h5 className="mb-2 mt-3">Maturity date</h5>
                <DatePicker
                  name="maturityDate"
                  className={
                    "form-control mb-3 " +
                    (touched.maturityDate &&
                      errors.maturityDate &&
                      "is-invalid")
                  }
                />
                {touched.maturityDate && (
                  <p className="text-danger">{errors.maturityDate}</p>
                )}
                <small>
                  The date the note is due to be repaid to the investor along
                  with any accrued interest, if the note has not yet converted
                  to equity.
                </small>
              </div>
              <div className="mb-3 col-xl-12">
                <h5 className="mb-2 mt-3">Principal</h5>
                <CurrencyInputV2
                  name="principal"
                  className={
                    touched.principal && errors.principal && "is-invalid"
                  }
                />
                {touched.principal && (
                  <p className="text-danger">{errors.principal}</p>
                )}
                <small>
                  The date the note is due to be repaid to the investor along
                  with any accrued interest, if the note has not yet converted
                  to equity.
                </small>
              </div>
              <div className="mb-3 col-xl-12">
                <h5 className="mb-2 mt-3">Other consideration</h5>
                <CurrencyInputV2
                  name="otherConsideration"
                  className={
                    touched.otherConsideration &&
                    errors.otherConsideration &&
                    "is-invalid"
                  }
                />
                {touched.otherConsideration && (
                  <p className="text-danger">{errors.otherConsideration}</p>
                )}
                <small>
                  Other forms of payment not part of the principal amount
                </small>
              </div>
              <div className="mb-3 col-xl-12">
                <h5 className="mb-2 mt-3">Currency</h5>
                <CurrencySelect
                  name="currency"
                  className={
                    touched.currency && errors.currency && "is-invalid"
                  }
                />
                {touched.currency && (
                  <p className="text-danger">{errors.currency}</p>
                )}
              </div>
              <div className="mb-3 col-xl-12">
                <h5 className="mb-2 mt-3">Board approval date</h5>
                <DatePicker
                  name="boardApprovalDate"
                  className={
                    "form-control mb-3 " +
                    (touched.boardApprovalDate &&
                      errors.boardApprovalDate &&
                      "is-invalid")
                  }
                />
                {touched.boardApprovalDate && (
                  <p className="text-danger">{errors.boardApprovalDate}</p>
                )}
                <small>
                  The last date a director signed a Board Consent approving the
                  issuance of the note. This must be before the issue date of
                  the note.
                </small>
              </div>
              <div className="mb-3 col-xl-12">
                <h5 className="mb-2 mt-3">Issue date</h5>
                <DatePicker
                  name="issueDate"
                  className={
                    "form-control mb-3 " +
                    (touched.issueDate && errors.issueDate && "is-invalid")
                  }
                />
                {touched.issueDate && (
                  <p className="text-danger">{errors.issueDate}</p>
                )}
                <small>The date which the note was issued</small>
              </div>
            </div>
            <h3 className="mt-5">Conversion Terms</h3>
            <div className="mb-3 col-xl-12">
              <h5 className="mb-2 mt-3">Valuation cap</h5>
              <CurrencyInputV2
                name="valuationCap"
                className={
                  touched.valuationCap && errors.valuationCap && "is-invalid"
                }
              />
              {touched.valuationCap && (
                <p className="text-danger">{errors.valuationCap}</p>
              )}
              <small>
                Maximum valuation at which a convertible security will convert
                into equity in the financing round. Leave this empty if the
                valuation is uncapped.
              </small>
            </div>
            <div className="mb-3 col-xl-12">
              <h5 className="mb-2 mt-3">Valuation cap type</h5>
              <select
                name="valuationCapType"
                value={values.valuationCapType}
                className={
                  "form-select " +
                  (touched.valuationCapType &&
                    errors.valuationCapType &&
                    "is-invalid")
                }
                onChange={handleChange}
                onBlur={handleBlur}
              >
                <option value=""></option>
                <option value="pre-money">Pre money</option>
                <option value="post-money">Post money</option>
                <option value="uncapped">Uncapped</option>
              </select>
              {touched.valuationCapType && (
                <p className="text-danger">{errors.valuationCapType}</p>
              )}
              <small>
                Indicates if the maximum valuation is before or after accounting
                for new capital from a financing round. Select “uncapped” if
                there is no valuation cap.
              </small>
            </div>
            <div className="mb-3 col-xl-12">
              <h5 className="mb-2 mt-3">Conversion discount</h5>
              <PercentInput
                className={
                  "form-select " +
                  (touched.conversionDiscount &&
                    errors.conversionDiscount &&
                    "is-invalid")
                }
                name="conversionDiscount"
              />
              {touched.conversionDiscount && (
                <p className="text-danger">{errors.conversionDiscount}</p>
              )}
              <small>
                The discount is subtracted from the price per share when
                converted. For example, 20% discount calculates to $2.00 * (1 -
                0.20) = $1.60.
              </small>
            </div>
            <h3 className="mt-5">Interest terms</h3>
            <div className="mb-3 col-xl-12">
              <h5 className="mb-2 mt-3">Interest accrual period</h5>
              <select
                value={values.interestAccrualPeriod}
                onChange={handleChange}
                onBlur={handleBlur}
                name="interestAccrualPeriod"
                className={
                  "form-select " +
                  (touched.interestAccrualPeriod &&
                    errors.interestAccrualPeriod &&
                    "is-invalid")
                }
              >
                <option value=""></option>
                {InterestAccrualPeriod.map((item) => {
                  return (
                    <option key={item.value} value={item.value}>
                      {item.name}
                    </option>
                  );
                })}
              </select>
              {touched.interestAccrualPeriod && (
                <p className="text-danger">{errors.interestAccrualPeriod}</p>
              )}
            </div>
            <div className="mb-3 col-xl-12">
              <h5 className="mb-2 mt-3">Interest rate (annual)</h5>
              <PercentInput
                className={
                  touched.interestRateAnnual &&
                  errors.interestRateAnnual &&
                  "is-invalid"
                }
                name="interestRateAnnual"
              />
              {touched.interestRateAnnual && (
                <p className="text-danger">{errors.interestRateAnnual}</p>
              )}
            </div>
            <div className="mb-3 col-xl-12">
              <h5 className="mb-2 mt-3">Interest payout</h5>
              <select
                value={values.interestPayout}
                onChange={handleChange}
                onBlur={handleBlur}
                name="interestPayout"
                className={
                  "form-select " +
                  (touched.interestPayout &&
                    errors.interestPayout &&
                    "is-invalid")
                }
              >
                <option value=""></option>
                <option value="deferred">Deferred</option>
                <option value="cash">Cash</option>
              </select>
              {touched.interestPayout && (
                <p className="text-danger">{errors.interestPayout}</p>
              )}
              <small>
                Deferred indicates that interest will convert in the form of
                additional shares instead of cash.
              </small>
            </div>
            <div className="mb-3 col-xl-12">
              <h5 className="mb-2 mt-3">Interest compound</h5>
              <select
                value={values.interestCompound}
                onChange={handleChange}
                onBlur={handleBlur}
                name="interestCompound"
                className={
                  "form-select " +
                  (touched.interestCompound &&
                    errors.interestCompound &&
                    "is-invalid")
                }
              >
                <option value=""></option>
                {InterestCompound.map((item) => {
                  return <option value={item.value}>{item.name}</option>;
                })}
              </select>
              {touched.interestCompound && (
                <p className="text-danger">{errors.interestCompound}</p>
              )}
            </div>
            <div className="mb-3 col-xl-12">
              <h5 className="mb-2 mt-3">Day count basis</h5>
              <select
                value={values.dayCountBasis}
                onChange={handleChange}
                onBlur={handleBlur}
                name="dayCountBasis"
                className={
                  "form-select " +
                  (touched.dayCountBasis &&
                    errors.dayCountBasis &&
                    "is-invalid")
                }
              >
                <option value=""></option>
                <option value="30/365">30/365</option>
                <option value="actual/365">Actual/365</option>
                <option value="actual/360">Actual/360</option>
              </select>
              {touched.dayCountBasis && (
                <p className="text-danger">{errors.dayCountBasis}</p>
              )}
              <small>
                The day count basis for a convertible note is a standardized way
                of counting the number of days between two dates.
              </small>
            </div>
            <h3 className="mt-5">Documents</h3>
            <div className="mb-3 col-xl-12">
              <h5 className="mb-2 mt-3">Form of convertible note</h5>
              <FileInput
                name="formOfConvertibleNote"
                className={
                  touched.formOfConvertibleNote &&
                  errors.formOfConvertibleNote &&
                  "is-invalid"
                }
              />
              {touched.formOfConvertibleNote && (
                <p className="text-danger">{errors.formOfConvertibleNote}</p>
              )}
            </div>
            <div className="mb-3 col-xl-12">
              <h5 className="mb-2 mt-3">Note purchase agreement</h5>
              <FileInput
                name="notePurchaseAgreement"
                className={
                  touched.notePurchaseAgreement &&
                  errors.notePurchaseAgreement &&
                  "is-invalid"
                }
              />
              {touched.notePurchaseAgreement && (
                <p className="text-danger">{errors.notePurchaseAgreement}</p>
              )}
            </div>
            <div className="mb-3 col-xl-12">
              <h5 className="mb-2 mt-3">Additional documents</h5>
              <FileInput
                name="additionalDocuments"
                className={
                  touched.additionalDocuments &&
                  errors.additionalDocuments &&
                  "is-invalid"
                }
              />
              {touched.additionalDocuments && (
                <p className="text-danger">{errors.additionalDocuments}</p>
              )}
            </div>
            <button className="btn" type="submit">
              Submit
            </button>
          </form>
        );
      }}
    </Formik>
  );
}

export default ConvertibleAddNote;
