import { regular } from "@fortawesome/fontawesome-svg-core/import.macro";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { MenuItem, Skeleton } from "@mui/material";
import Table from "@mui/material/Table";
import TableBody from "@mui/material/TableBody";
import TableCell from "@mui/material/TableCell";
import TableContainer from "@mui/material/TableContainer";
import TableHead from "@mui/material/TableHead";
import TableRow from "@mui/material/TableRow";
import {
  collection,
  deleteDoc,
  doc,
  getDoc,
  getDocs,
  getFirestore,
  orderBy,
  query,
  setDoc,
} from "firebase/firestore";
import { Form, Formik } from "formik";
import { post } from "jsx/api";
import { UserContext } from "jsx/contexts/user-context";
import MSButton from "jsx/element/button";
import MSDropdown, { MSDropdownItem } from "jsx/element/dropdown";
import MSField from "jsx/element/ms-field";
import SettingsNav from "jsx/element/settings-nav";
import { titleCase } from "jsx/helpers";
import Header6 from "jsx/layout/header6";
import React, { useEffect, useState } from "react";
import * as Yup from "yup";

function RoleSelector(props) {
  const { selectedOrganization } = React.useContext(UserContext);
  const [teamMember, setTeamMember] = useState(props.teamMember);

  const checkmark = <i className="mx-2 la la-check" />;
  const db = getFirestore();

  async function selectRole(role) {
    setTeamMember((prev) => {
      return { ...prev, role: role };
    });

    await setDoc(
      doc(
        db,
        "organizations",
        selectedOrganization,
        "organizations_private",
        "data",
        "team",
        teamMember.email
      ),
      {
        role: role,
      },
      { merge: true }
    );
  }

  return (
    <MSDropdown
      id="dropdown-basic-button"
      buttonProps={{ className: "m-0", size: "small" }}
      title={titleCase(teamMember.role)}
    >
      <MSDropdownItem
        onClick={() => {
          selectRole("reader");
        }}
        name="reader"
      >
        Reader {teamMember.role === "reader" && checkmark}
      </MSDropdownItem>
      <MSDropdownItem
        onClick={() => {
          selectRole("signer");
        }}
        name="signer"
      >
        Signer {teamMember.role === "signer" && checkmark}
      </MSDropdownItem>
      <MSDropdownItem
        onClick={() => {
          selectRole("admin");
        }}
        name="admin"
      >
        Admin {teamMember.role === "admin" && checkmark}
      </MSDropdownItem>
    </MSDropdown>
  );
}

function SettingsSelector(props) {
  const { teamMember } = props;
  const { user, selectedOrganization } = React.useContext(UserContext);

  const db = getFirestore();

  async function removeMember(e) {
    if (teamMember.userId === user.uid) {
      alert("Cannot remove yourself");
      return;
    }

    await deleteDoc(
      doc(
        db,
        "organizations",
        selectedOrganization,
        "organizations_private",
        "data",
        "team",
        teamMember.email
      )
    );

    props.fetchTeamMembers();
  }

  return (
    <MSDropdown
      buttonProps={{
        className: "m-0 px-3",
        size: "small",
        variant: "icon",
        children: <FontAwesomeIcon icon={regular("ellipsis-vertical")} />,
      }}
    >
      <MSDropdownItem onClick={removeMember} name="admin">
        <FontAwesomeIcon
          icon={regular("trash")}
          className="py-2 pe-2"
          color="var(--danger)"
        />
        Remove team member
      </MSDropdownItem>
    </MSDropdown>
  );
}

const InvestorSchema = Yup.object().shape({
  email: Yup.string().email("Invalid email").required("Required"),
  role: Yup.string().required("Required"),
});

function SettingsManageTeam() {
  const { user, selectedOrganization } = React.useContext(UserContext);
  const [teamMembers, setTeamMembers] = useState();
  const [isAddingTeamMember, setIsAddingTeamMember] = useState(false);

  const db = getFirestore();

  useEffect(() => {
    fetchTeamMembers();
  }, [selectedOrganization]);

  async function fetchTeamMembers() {
    if (!user.uid) {
      return;
    }

    try {
      const q = query(
        collection(
          db,
          `organizations/${selectedOrganization}/organizations_private/data/team`
        ),
        orderBy("createdDate", "desc")
      );
      const querySnapshot = await getDocs(q);
      const res = [];

      for (const e of querySnapshot.docs) {
        const teamMember = e.data();
        let nameField = teamMember.email;

        if (teamMember.userId) {
          const hydratedUser = (
            await getDoc(doc(db, "users", teamMember.userId))
          ).data();
          if (hydratedUser && hydratedUser.displayName) {
            nameField = hydratedUser.displayName;
          }
        }

        res.push(
          <TableRow key={teamMember.email}>
            <TableCell>
              <div className="company-icon"></div>
              <span>{nameField}</span>
            </TableCell>

            <TableCell style={{ padding: "24px" }}>
              {new Date(teamMember.createdDate.seconds * 1000).toLocaleString()}
            </TableCell>
            <TableCell>
              <RoleSelector teamMember={teamMember} />
            </TableCell>
            <TableCell>
              <div className="pill py-2 text-center px-2">
                {titleCase(teamMember.status)}
              </div>
            </TableCell>
            <TableCell align="right">
              <span>
                <SettingsSelector
                  teamMember={teamMember}
                  fetchTeamMembers={fetchTeamMembers}
                />{" "}
              </span>
            </TableCell>
          </TableRow>
        );
      }
      setTeamMembers(res);
    } catch (e) {
      console.log("error", e);
    }
  }

  return (
    <>
      <Header6 />
      <Formik
        initialValues={{ email: "", role: "" }}
        validationSchema={InvestorSchema}
        onSubmit={async (values) => {
          try {
            await post("addTeamMember", {
              organizationId: selectedOrganization,
              email: values.email,
              role: values.role,
            });

            setIsAddingTeamMember(false);
            fetchTeamMembers();
          } catch (error) {
            console.log("error", error);
          }
        }}
      >
        {(formik) => {
          const { isSubmitting } = formik;

          return (
            <Form>
              <div className="content-body section-padding">
                <div className="container">
                  <div className="d-flex">
                    <div className="me-4" style={{ minWidth: "200px" }}>
                      <SettingsNav />
                    </div>
                    <div className="col-10">
                      <div className="card outlined">
                        <div className="card-header border-0 position-relative">
                          <h4 className="card-title">Team Members</h4>
                          <div
                            style={{
                              right: "16x",
                              bottom: "32px",
                            }}
                          >
                            {isAddingTeamMember && (
                              <div
                                className="back-button"
                                onClick={() => {
                                  setIsAddingTeamMember(false);
                                }}
                              >
                                <i className="la la-angle-left"></i>
                                <span>Cancel</span>
                              </div>
                            )}
                            {!isAddingTeamMember && (
                              <div
                                className="forward-button"
                                onClick={() => {
                                  setIsAddingTeamMember(true);
                                }}
                              >
                                <span>Add a team member</span>
                                <i className="la la-angle-right"></i>
                              </div>
                            )}
                          </div>
                        </div>

                        {isAddingTeamMember && (
                          <div className="row">
                            <div className="mb-4 col-8">
                              <MSField
                                name="email"
                                autoFocus
                                placeholder="email@domain.com"
                                formik={formik}
                              />
                            </div>
                            <div className="mb-0 col-4">
                              <div className="input-with-icon">
                                <MSField
                                  select
                                  name="role"
                                  placeholder="Role"
                                  formik={formik}
                                >
                                  <MenuItem value="reader">Reader</MenuItem>
                                  <MenuItem value="signer">Signer</MenuItem>
                                  <MenuItem value="admin">Admin</MenuItem>
                                </MSField>
                              </div>
                            </div>
                            <div className="mb-4">
                              <MSButton
                                className="float-end"
                                title="Add"
                                loadingLabel="Processing"
                                loading={isSubmitting}
                                type="submit"
                              />
                            </div>
                          </div>
                        )}

                        {!teamMembers && (
                          <div className="py-4">
                            <Skeleton height={50} />
                            <Skeleton height={50} />
                            <Skeleton height={50} />
                          </div>
                        )}
                        {!isAddingTeamMember && teamMembers && (
                          <div className="card-body pt-2">
                            <TableContainer>
                              <Table aria-label="simple table">
                                <TableHead>
                                  <TableRow>
                                    <TableCell>Name</TableCell>
                                    <TableCell>Date added</TableCell>
                                    <TableCell>Role</TableCell>
                                    <TableCell>Status</TableCell>
                                    <TableCell align="right">
                                      Settings
                                    </TableCell>
                                  </TableRow>
                                </TableHead>
                                <TableBody
                                  sx={{
                                    "& .MuiTableRow-root": {
                                      transition: "background 0.5",
                                      color: "red",
                                      bottomBorder: "none",
                                    },
                                  }}
                                >
                                  {/* <tr key={teamMember.userId}>
            <td>
              <div className="item">
                <div className="company-icon"></div>
                <span>{nameField}</span>
              </div>
            </td>

            <td>
              <div className="item">
                <div>
                  {new Date(
                    teamMember.createdDate.seconds * 1000
                  ).toLocaleString()}
                </div>
              </div>
            </td>
            <td>
              <div className="item">
                <RoleSelector teamMember={teamMember} />
              </div>
            </td>
            <td>
              <div className="item">
                <span>{teamMember.status}</span>
              </div>
            </td>
            <td>
              <div className="item">
                <SettingsSelector
                  teamMember={teamMember}
                  fetchTeamMembers={fetchTeamMembers}
                />
              </div>
            </td> */}

                                  {teamMembers}
                                </TableBody>
                              </Table>
                            </TableContainer>
                          </div>
                        )}
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </Form>
          );
        }}
      </Formik>
    </>
  );
}

export default SettingsManageTeam;
