import { regular } from "@fortawesome/fontawesome-svg-core/import.macro";
import Tooltip from "@mui/material/Tooltip";
import MSButton from "jsx/element/button";
import Divider from "jsx/element/divider";
import moment from "moment";
import React, { useEffect, useState } from "react";
import { CountUp } from "use-count-up";

import Detail from "./detail";
import VisitAnalytics from "./visit-analytics";

function timeDateFromMilliseconds(seconds) {
  if (Number.isNaN(seconds)) {
    return 0;
  }

  var date = new Date(0);
  date.setSeconds(seconds / 1000); // specify value for SECONDS here
  return date.toISOString().substr(14, 5);
}

export default function TenderVisitAnalytics({
  roundVisits,
  selectedOrganization,
  secondaryId,
}) {
  const [isExpanded, setIsExpanded] = useState(false);

  const visit = roundVisits[0];

  let totalTime = 0;

  // Javascript reducers are confusion
  if (roundVisits.length > 0) {
    for (const visit of roundVisits) {
      totalTime += visit.t;
    }
  }

  return (
    <>
      <div className="col-12">
        {isExpanded && (
          <>
            <MSButton
              onClick={() => {
                setIsExpanded(false);
              }}
              style={{ marginBottom: 16 }}
            >
              Back
            </MSButton>
          </>
        )}
        {isExpanded && (
          <div className="row justify-content-center align-items-center">
            <div className="col-6 col-xs-12">
              <div className="card outlined py-4">
                <div className="d-flex">
                  <div className="big-icon">
                    <i className="la la-chart-bar"></i>
                  </div>
                  <div>
                    <h2>
                      <CountUp
                        isCounting
                        end={roundVisits.length}
                        duration={0.5}
                        decimalPlaces={0}
                      />
                    </h2>
                    <h6>Total Views</h6>
                  </div>
                </div>
              </div>
            </div>

            <div className="col-6 col-xs-12">
              <div className="card outlined py-4">
                <div className="d-flex">
                  <div className="big-icon">
                    <i className="la la-stopwatch"></i>
                  </div>
                  <div>
                    <h2>{timeDateFromMilliseconds(totalTime)}</h2>
                    <h6>Total Time Spent</h6>
                  </div>
                </div>
              </div>
            </div>
          </div>
        )}
        {!isExpanded && (
          <div>
            <div className="col-12 d-flex py-3 ">
              <div className="col-2 align-self-center" style={{ fontSize: 14 }}>
                {moment(new Date(visit.d)).fromNow()}
              </div>
              {/* <Link to={`${visit.e}`} className='col-7 align-self-center text-link'>
                          {visit.e}
                      </Link> */}
              <div className="col-4 align-self-center" style={{ fontSize: 14 }}>
                {visit.e}
              </div>
              <div className="col-5 align-self-center" style={{ fontSize: 13 }}>
                {visit.fn}
              </div>

              <MSButton
                title="Details"
                onClick={() => {
                  setIsExpanded(!isExpanded);
                }}
                size="small"
              >
                View
              </MSButton>
            </div>
          </div>
        )}

        {isExpanded && (
          <>
            <Detail title="Email" icon={regular("envelope")}>
              {visit.e}
            </Detail>

            <Detail title="Progress" icon={regular("list-check")}>
              Signed up
            </Detail>

            <h4 style={{ marginTop: 32 }}>User Timeline</h4>
            <Divider />
            {roundVisits.length === 0 && (
              <div className="py-4">
                You have not received any analytics data yet.
              </div>
            )}
            {roundVisits.map((e) => {
              return (
                <div key={e.i}>
                  <VisitAnalytics
                    selectedOrganization={selectedOrganization}
                    secondaryId={secondaryId}
                    visit={e}
                  />
                  <Divider style={{ marginTop: "0px" }} />
                </div>
              );
            })}
          </>
        )}
      </div>
    </>
  );
}
