import { Divider } from "@mui/material";

export default function D({ style }) {
  return (
    <Divider
      style={{
        color: "var(--purple-light)",
        opacity: 0.5,
        ...style,
      }}
    />
  );
}
