import { Skeleton, Tooltip } from "@mui/material";
import { doc, getFirestore, onSnapshot } from "firebase/firestore";
import { getBaseUrl } from "jsx/api";
import { post } from "jsx/api";
import { UserContext } from "jsx/contexts/user-context";
import MSButton from "jsx/element/button";
import React, { useEffect, useState } from "react";
import { Link } from "react-router-dom";

import Divider from "./divider";
import VoidButton from "./secondary-transfer-void-button";

function SecondaryDocumentRow({
  organizationId,
  secondaryId,
  transactionId,
  documentId,
  onDocumentVoid,
  showVoid = false,
  showInvites = false,
  showSigning = true,
  prefetchedDocument,
  token,
}) {
  const [document, setDocument] = useState(prefetchedDocument);
  const [isDownloading, setIsDownloading] = useState(false);
  const { isSiteAdmin } = React.useContext(UserContext);

  useEffect(() => {
    setDocument(prefetchedDocument);
  }, [prefetchedDocument]);

  const docParams = {
    organizationId,
    secondaryId,
    transactionId,
    documentId,
  };
  const db = getFirestore();

  useEffect(() => {
    if (!document) {
      subscribeToTransferDoc(
        organizationId,
        secondaryId,
        transactionId,
        documentId
      );
    }
  }, [organizationId, secondaryId, transactionId, documentId]);

  async function subscribeToTransferDoc(
    organizationId,
    secondaryId,
    transactionId,
    documentId
  ) {
    onSnapshot(
      doc(
        db,
        "organizations",
        organizationId,
        "organizations_admin",
        "data",
        "secondaries",
        secondaryId,
        "secondary_transactions",
        transactionId,
        "documents",
        documentId
      ),
      (doc) => {
        const docMetadata = doc.data();
        setDocument(docMetadata);
      }
    );
  }

  async function downloadDocument(fileFormat) {
    const params = {
      organizationId: organizationId,
      secondaryId: secondaryId,
      transactionId: transactionId,
      documentId: documentId,
      fileFormat: fileFormat,
      token: token,
    };

    setIsDownloading(true);

    const result = await post("getTransferDocFile", params);

    const uri = "data:pdf;base64," + result.data;

    var downloadLink = window.document.createElement("a");
    downloadLink.href = uri;
    downloadLink.download =
      fileFormat === "pdf" ? document.latestPdfName : document.latestDocxName;

    window.document.body.appendChild(downloadLink);
    downloadLink.click();
    window.document.body.removeChild(downloadLink);
    setIsDownloading(false);
  }

  async function voidDocument() {
    await post("voidTransferDoc", {
      organizationId: organizationId,
      secondaryId: secondaryId,
      transactionId: transactionId,
      documentId: documentId,
    });
    await onDocumentVoid?.();
  }

  const latestSigningRound = document?.latestSigningRound;
  const isUnsigned = !latestSigningRound;
  const latestPdf = document?.[`signedPdfName${latestSigningRound}`];
  const latestDocx = document?.[`signedDocxName${latestSigningRound}`];
  const shouldShowProcessing = !isUnsigned && latestDocx && !latestPdf;

  const signingPageBasePath = `/org/${organizationId}/secondaries/${secondaryId}/transactions/${transactionId}/documents/${documentId}`;
  const companySignPath = `${signingPageBasePath}/company_sign`;
  const buyerSignPath = `${signingPageBasePath}/buyer_sign`;
  const viewPagePath = `${signingPageBasePath}/view`;

  const signButton = (
    <Link to={companySignPath}>
      <MSButton title="Sign" size="small" variant="primary" />
    </Link>
  );

  const title = (
    <p className="small mb-0" style={{ maxWidth: "300px" }}>
      {shouldShowProcessing
        ? "Finalizing signed documents. We will email you the final document."
        : document?.latestPdfName ?? document?.latestDocxName}
    </p>
  );
  const downloadButtons = shouldShowProcessing ? (
    <MSButton
      className="me-2"
      title="View"
      loadingLabel="Processing"
      loading={true}
      size="small"
      disabled
    />
  ) : (
    <>
      <MSButton
        className="me-2"
        title="View"
        loadingLabel="Processing"
        loading={isDownloading}
        size="small"
        onClick={() => downloadDocument(isUnsigned ? "docx" : "pdf")}
      />
      {showSigning && signButton}
    </>
  );

  const loading = (
    <>
      <Skeleton height={50} />
      <Skeleton height={50} />
    </>
  );

  const content = (
    <>
      <div className="d-flex align-items-center py-2 mb-2">
        <div className="flex-grow-1">
          <span style={{ fontWeight: 400 }}>{document?.displayName}</span>
          <div className="mt-1">{title}</div>
        </div>
        <div>{downloadButtons}</div>
      </div>
      {!shouldShowProcessing && (
        <>
          {showInvites && (
            <>
              <Divider style={{ left: 48, right: 48 }} />
              <div className="py-4">
                <div className="d-flex align-items-center">
                  <div className="flex-grow-1">
                    <span style={{ fontWeight: 400 }}>Signing invites</span>
                    <p className="small mt-1 mb-0">
                      Copy and share invitation links to the signing page
                    </p>
                  </div>
                  <div>
                    <TokenLinkButton
                      title={"Copy buyer signing link"}
                      tooltip={"Buyer link created and saved to clipboard"}
                      path={buyerSignPath}
                      params={docParams}
                    />
                  </div>
                </div>
              </div>
            </>
          )}
          {showVoid && (
            <>
              <Divider style={{ left: 48, right: 48 }} />
              <div className="py-4">
                <div className="d-flex align-items-center">
                  <div className="flex-grow-1">
                    <span style={{ fontWeight: 400 }}>Void document</span>
                    <p className="small mt-1 mb-0">
                      Voids the existing transfer document and generates a new
                      one
                    </p>
                  </div>
                  <div>
                    <VoidButton onVoid={voidDocument} />
                  </div>
                </div>
              </div>
            </>
          )}
          {isSiteAdmin && (
            <>
              <Divider style={{ left: 48, right: 48 }} />
              <div className="pt-3 mb-2">
                <div className="d-flex align-items-center">
                  <div className="flex-grow-1">
                    <span style={{ fontWeight: 400 }}>
                      [Admin debug] View documents
                    </span>
                    <p className="small mt-1">
                      Create a view-only link to signing docs
                    </p>
                  </div>
                  <div>
                    <TokenLinkButton
                      title={"Copy document link"}
                      tooltip={"Viewer link created and copied to clipboard"}
                      path={viewPagePath}
                      params={docParams}
                    />
                  </div>
                </div>
              </div>
            </>
          )}
        </>
      )}
    </>
  );

  return (
    <div className="outlined-small-thin">{!document ? loading : content}</div>
  );
}

function TokenLinkButton({ title, tooltip, path, params }) {
  const [loading, setIsLoading] = useState(false);
  const [error, setError] = useState(false);
  const [isTooltipOpen, setIsTooltipOpen] = useState(false);

  async function generateToken(params) {
    const response = await post("secondaries/createDocViewToken", params);
    const { token } = response.data;
    return token;
  }

  async function handleClick() {
    setError(false);
    setIsLoading(true);
    const token = await generateToken(params);
    setIsLoading(false);
    if (token) {
      const link = `${getBaseUrl()}${path}?t=${token}`;
      navigator.clipboard.writeText(link);
    } else {
      setError(true);
    }
    setIsTooltipOpen(true);
    setTimeout(() => {
      setIsTooltipOpen(false);
    }, 2000);
  }
  return (
    <Tooltip
      placement="top"
      title={error ? "Something went wrong" : tooltip}
      open={isTooltipOpen}
      disableFocusListener
      disableHoverListener
      disableTouchListener
    >
      <div>
        <MSButton
          onClick={handleClick}
          size="small"
          title={title}
          loading={loading}
        ></MSButton>
      </div>
    </Tooltip>
  );
}

export default SecondaryDocumentRow;
