import { Form, Formik } from "formik";
import DatePicker from "jsx/element/date-picker";
import NumberFormatted from "jsx/element/number-formatted";
import React from "react";
import { useHistory } from "react-router-dom";
import * as Yup from "yup";

import routes from "./routes.json";
function CommonSharesAdd({ onAdd }) {
  const history = useHistory();
  const CommonSharesSchema = Yup.object().shape({
    shareClassName: Yup.string().required("Required"),
    shareClassPrefix: Yup.string().required("Required"),
    authorizedShares: Yup.string().required("Required"),
    authorizedDate: Yup.string().required("Required"),
  });
  return (
    <Formik
      initialValues={{
        shareClassName: "Common",
        shareClassPrefix: "CS",
        authorizedShares: "",
        authorizedDate: "",
        parValue: "",
      }}
      validationSchema={CommonSharesSchema}
      onSubmit={(values) => {
        history.push(routes.root);
        onAdd(values);
      }}
    >
      {({
        values,
        handleChange,
        handleBlur,
        handleSubmit,
        errors,
        touched,
      }) => (
        <>
          <div className="card-header mb-2">
            <h4 className="card-title">Add a share class</h4>
          </div>
          <small>
            The information you provide below should reflect how your share
            class exists today. Once you sign and activate your account, Carta
            will add the share class to your cap table and issue certificates to
            your shareholders in electronic form.
          </small>
          <Form className="d-flex flex-column card-body">
            <div className="mb-3 col-xl-12">
              <h5 className="mb-2 mt-3">Share class name</h5>
              <input
                value={values.shareClassName}
                name="shareClassName"
                className={
                  "form-control mb-3 " +
                  (touched.shareClassName &&
                    errors.shareClassName &&
                    "is-invalid")
                }
                onChange={handleChange}
                onBlur={handleBlur}
              />
              {errors.shareClassName && touched.shareClassName && (
                <p className="text-danger">{errors.shareClassName}</p>
              )}
              <small>
                Used to identify different share classes in Modern Share. Each
                share class name must be unique.
              </small>
            </div>
            <div className="mb-3 col-xl-12">
              <h5 className="mb-2 mt-3">Share class prefix</h5>
              <input
                value={values.shareClassPrefix}
                name="shareClassPrefix"
                className={
                  "form-control mb-3 " +
                  (touched.shareClassPrefix &&
                    errors.shareClassPrefix &&
                    "is-invalid")
                }
                onChange={handleChange}
                onBlur={handleBlur}
              />
              {errors.shareClassPrefix && touched.shareClassPrefix && (
                <p className="text-danger">{errors.shareClassPrefix}</p>
              )}
              <small>
                Used to uniquely identify each certificate (e.g. CS-1)
              </small>
            </div>
            <div className="mb-3 col-xl-12">
              <h5 className="mb-2 mt-3">Authorized shares</h5>
              <NumberFormatted
                className={
                  touched.authorizedShares &&
                  errors.authorizedShares &&
                  "is-invalid"
                }
                name="authorizedShares"
                placeholder="e.g. 10,000,000"
              />
              {errors.authorizedShares && touched.authorizedShares && (
                <p className="text-danger">{errors.authorizedShares}</p>
              )}
              <small>
                The number of shares in this class authorized by the Certificate
                of Incorporation
              </small>
            </div>
            <div className="mb-3 col-xl-12">
              <h5 className="mb-2 mt-3">Authorized date</h5>
              <DatePicker
                name="authorizedDate"
                className={
                  "form-control mb-3 " +
                  (touched.authorizedDate &&
                    errors.authorizedDate &&
                    "is-invalid")
                }
              />
              {errors.authorizedDate && touched.authorizedDate && (
                <p className="text-danger">{errors.authorizedDate}</p>
              )}
              <small>MM/DD/YYYY when the authorized shares were approved</small>
            </div>
            <div className="mb-3 col-xl-12">
              <h5 className="mb-2 mt-3">Par value</h5>
              <NumberFormatted
                className={touched.parValue && errors.parValue && "is-invalid"}
                name="parValue"
                placeholder="e.g. 0.00001"
              />
              {errors.parValue && touched.parValue && (
                <p className="text-danger">{errors.parValue}</p>
              )}
              <small>
                The minimum price at which a corporation can issue shares. This
                price is important because it can impact taxes and fees paid by
                the corporation, particularly if incorporated in Delaware. If
                your jurisdiction does not have par value, leave this blank.
              </small>
            </div>
            <div>
              <button className="btn" type="submit">
                Add a share class
              </button>
            </div>
          </Form>
        </>
      )}
    </Formik>
  );
}
export default CommonSharesAdd;
