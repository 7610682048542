import {
  collection,
  doc,
  getDoc,
  getDocs,
  getFirestore,
} from "@firebase/firestore";
import {
  duotone,
  regular,
} from "@fortawesome/fontawesome-svg-core/import.macro";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { Skeleton } from "@mui/material";
import { UserContext } from "jsx/contexts/user-context";
import EmptyState from "jsx/element/empty-state";
import ManageTendersNav from "jsx/element/manage-tenders-nav";
import SecondaryTransactionTable from "jsx/element/secondary-transaction-table";
import TenderOrderTable from "jsx/element/tender-order-table";
import Header6 from "jsx/layout/header6";
import React, { useContext, useEffect, useState } from "react";
import { Link } from "react-router-dom";
import { CountUp } from "use-count-up";

function CompanyTenderManageOrders({ match }) {
  const { selectedOrganization } = useContext(UserContext);
  const [secondary, setSecondary] = useState({});
  const [secondaryOrders, setSecondaryOrders] = useState();
  const [transactions, setTransactions] = useState();
  const [secondaryEmployees, setSecondaryEmployees] = useState([]);
  const [cards, setCards] = useState([]);

  const [loading, setIsLoading] = useState(false);

  const { secondaryId, orderId } = match.params;

  const db = getFirestore();

  // Get secondary information along with stakeholder, secondary seller, secondary order
  // data, secondary transactions
  async function fetchSecondary(organizationId, secondaryId) {
    setIsLoading(true);

    const fetchSecondary = async () => {
      const secondarySnap = await getDoc(
        doc(
          db,
          `organizations/${organizationId}/organizations_private/data/secondaries/${secondaryId}`
        )
      );
      const secondaryData = secondarySnap.data();
      setSecondary(secondaryData);
      return secondaryData;
    };

    const fetchOrders = async () => {
      const secondaryOrdersSnapshot = await getDocs(
        collection(
          db,
          `organizations/${organizationId}/organizations_admin/data/secondaries/${secondaryId}/secondary_orders`
        )
      );
      const secondaryOrders = secondaryOrdersSnapshot.docs.map((doc) => ({
        id: doc.id,
        ...doc.data(),
      }));
      setSecondaryOrders(secondaryOrders);
      return secondaryOrders;
    };

    const fetchSellers = async () => {
      const sellersSnapshot = await getDocs(
        collection(
          db,
          `organizations/${organizationId}/organizations_admin/data/secondaries/${secondaryId}/secondary_sellers`
        )
      );

      const secondarySellers = sellersSnapshot.docs.map((seller) => ({
        id: seller.id,
        ...seller.data(),
      }));
      return secondarySellers;
    };

    const fetchTransactions = async () => {
      const transactionsSnapshot = await getDocs(
        collection(
          db,
          "organizations",
          organizationId,
          "organizations_admin",
          "data",
          "secondaries",
          secondaryId,
          "secondary_transactions"
        )
      );
      const transactions = transactionsSnapshot.docs.map((doc) => ({
        id: doc.id,
        ...doc.data(),
      }));
      setTransactions(transactions);
      return transactions;
    };

    const [
      secondaryData, // eslint-disable-line no-unused-vars
      secondaryOrders,
      secondarySellers,
      transactions, // eslint-disable-line no-unused-vars
    ] = await Promise.all([
      fetchSecondary(),
      fetchOrders(),
      fetchSellers(),

      fetchTransactions(),
    ]);

    setIsLoading(false);

    const securitiesSellingTotal = secondaryOrders.reduce(
      (acc, { quantity, status, orderType }) =>
        acc +
        parseInt(
          status !== "canceled" && orderType === "sell" ? quantity ?? 0 : 0
        ),
      0
    );

    let pricePerShare = 0;

    if (secondaryData && secondaryData.pricePerShareInBaseUnits) {
      pricePerShare =
        secondaryData.pricePerShareInBaseUnits /
        secondaryData.pricePerSharePrecision;
    }

    const c = [
      {
        title: "Total participants submitted orders",
        value:
          secondarySellers.filter((e) => e.status === "submitted").length ?? 0,
        icon: "la-id-badge",
        link: `/org/secondaries/manage/${secondaryId}/stakeholders`,
      },
      {
        title: "Total units tendered",
        value: securitiesSellingTotal.toFixed(0),
        icon: "la-file-invoice-dollar",
        link: `/org/secondaries/manage/${secondaryId}/orders`,
      },
      {
        title: "Total value tendered",
        value: pricePerShare * securitiesSellingTotal,
        icon: "la-money",
        display: displayRounded,
        count: true,
        link: `/org/secondaries/manage/${secondaryId}/orders`,
      },
    ];

    setCards(c);
  }

  useEffect(() => {
    if (
      selectedOrganization &&
      secondaryId &&
      Object.entries(secondary).length === 0
    ) {
      fetchSecondary(selectedOrganization, secondaryId);
    } else {
      setSecondaryOrders([]);
      setTransactions([]);
    }
  }, [selectedOrganization, secondaryId]);

  let secondaryOrdersSection;
  let secondaryTransactionsSection;

  if (!secondaryOrders) {
    secondaryOrdersSection = <Skeleton variant="rectangular" height={500} />;
  } else if (secondaryOrders.length) {
    secondaryOrdersSection = (
      <TenderOrderTable
        highlightedOrderId={orderId}
        secondaryOrders={secondaryOrders}
      />
    );
  } else {
    secondaryOrdersSection = (
      <EmptyState
        icon={duotone("rectangle-history-circle-user")}
        title={"No orders to display"}
        subtitle={"When sellers put in their orders, they will appear here"}
      />
    );
  }

  if (!transactions) {
    secondaryTransactionsSection = (
      <Skeleton variant="rectangular" height={500} />
    );
  } else if (transactions.length) {
    secondaryTransactionsSection = (
      <SecondaryTransactionTable
        transactions={transactions}
        secondaryId={secondaryId}
      />
    );
  } else {
    secondaryTransactionsSection = (
      <EmptyState
        icon={duotone("money-check-dollar-pen")}
        title={"No transactions to display"}
        subtitle={
          "When orders are paired, transactions and generated documents will appear here"
        }
      />
    );
  }

  const displayRounded = (value) =>
    value.toLocaleString("en-US", {
      maximumFractionDigits: 0,
      style: "currency",
      currency: "USD",
    });

  return (
    <>
      <Header6 />

      <div className="content-body section-padding">
        <div className="container">
          <div className="d-flex">
            <div className="me-4" style={{ minWidth: "220px" }}>
              <ManageTendersNav secondaryId={secondaryId} />
            </div>
            <div className="col-xl-10">
              <div className="flex-column">
                <div
                  className="d-flex flex-wrap"
                  style={{ marginRight: "-24px" }}
                >
                  {cards.map(
                    (
                      { title, value, display, icon, count, decimals, link },
                      i
                    ) => (
                      <div key={i} className="col-12 col-sm-4 d-flex">
                        <div
                          to={link}
                          className="card outlined py-4 me-4 rounded w-100 ps-4 pe-2"
                        >
                          <div className="d-flex">
                            <div className="big-icon">
                              <i className={`la ${icon}`}></i>
                            </div>
                            <div>
                              <h2>
                                {count ? (
                                  <CountUp
                                    isCounting
                                    end={value}
                                    duration={0.5}
                                    decimalPlaces={decimals ?? 0}
                                    formatter={display ?? ((val) => val)}
                                  />
                                ) : (
                                  value
                                )}
                              </h2>
                              <h6>{title}</h6>
                            </div>
                            {/* <div className="d-flex align-items-center ms-auto me-2">
                              <FontAwesomeIcon
                                className="card-icon"
                                icon={regular("chevron-right")}
                                size="lg"
                                style={{ color: "var(--gray-200)" }}
                              />
                            </div> */}
                          </div>
                        </div>
                      </div>
                    )
                  )}
                </div>
                <div className="card outlined">
                  <div className="card-header">
                    <div>
                      <h4 className="card-title">Orders</h4>
                      <p className="small mt-3">
                        View the status of elections in the tender offer
                      </p>
                    </div>
                  </div>
                  <div className="card-body">
                    <div className="mt-4">{secondaryOrdersSection}</div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
}

export default CompanyTenderManageOrders;
