import "react-datepicker/dist/react-datepicker.css";

import { regular } from "@fortawesome/fontawesome-svg-core/import.macro";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { InputAdornment, Skeleton } from "@mui/material";
import Checkbox from "@mui/material/Checkbox";
import Dialog from "@mui/material/Dialog";
import DialogActions from "@mui/material/DialogActions";
import Table from "@mui/material/Table";
import TableBody from "@mui/material/TableBody";
import TableCell from "@mui/material/TableCell";
import TableContainer from "@mui/material/TableContainer";
import TableHead from "@mui/material/TableHead";
import TableRow from "@mui/material/TableRow";
import Tooltip from "@mui/material/Tooltip";
import {
  collection,
  doc,
  getFirestore,
  onSnapshot,
  orderBy,
  query,
  setDoc,
  where,
} from "firebase/firestore";
import { getDownloadURL, getStorage, ref } from "firebase/storage";
import { Field, Form, Formik } from "formik";
import { getBaseUrl, post } from "jsx/api";
import { UserContext } from "jsx/contexts/user-context";
import MSButton from "jsx/element/button";
import Divider from "jsx/element/divider";
import MSField from "jsx/element/ms-field";
import Header4 from "jsx/layout/header4";
import moment from "moment";
import React, { useEffect, useRef, useState } from "react";
import DatePicker from "react-datepicker";
import NumberFormat from "react-number-format";
import { Link } from "react-router-dom";
import { useParams } from "react-router-dom";
import { CountUp } from "use-count-up";
import * as Yup from "yup";

const InvestorSchema = Yup.object().shape({
  amount: Yup.number(),
  email: Yup.string().email("Invalid email").required("Required"),
});

function Dashboard() {
  const { user, selectedOrganization } = React.useContext(UserContext);
  const [investments, setInvestments] = useState([]);
  const [round, setRound] = useState();

  const [isAddingInvestor, setIsAddingInvestor] = useState(false);
  const [isTooltipOpen, setIsTooltipOpen] = useState(false);
  const { roundId } = useParams();

  const db = getFirestore();
  const copyTextInput = useRef(null);
  const copyTooltipTarget = useRef(null);

  const [initiated, setInitiated] = useState(0);
  const [signed, setSigned] = useState(0);
  const [inTheBank, setInTheBank] = useState(0);

  const [templates, setTemplates] = useState([]);
  const [decks, setDecks] = useState([]);

  const [template, setTemplate] = useState();
  const [hideVoidedInvestments, setHideVoidedInvestments] = useState(true);
  const [deckConfirmId, setDeckConfirmId] = useState();
  const [datePickerOpen, setDatePickerOpen] = useState();
  const [endDate, setEndDate] = useState(new Date());

  let roundSub;
  let investmentsSub;

  useEffect(() => {
    fetchInvestments();
  }, [selectedOrganization]);

  useEffect(() => {
    fetchRound();
  }, [roundId, selectedOrganization]);

  const parsedInvestments = investments.filter((e) => {
    if (hideVoidedInvestments && e.data().status === "voided") {
      return false;
    }
    return e;
  });

  function copyLink() {
    setIsTooltipOpen(true);
    setTimeout(() => {
      setIsTooltipOpen(false);
    }, 3000);

    /* Copy the text inside the text field */
    navigator.clipboard.writeText(
      `${getBaseUrl()}/org/${selectedOrganization}/fundraise/round/${roundId}`
    );
  }

  function uploadDeck(e) {
    var formData = new FormData();
    var file = e.target.files[0];
    formData.append("file", file);
    formData.append("organizationId", selectedOrganization);
    formData.append("roundId", roundId);
    post("uploadDeck", formData, {
      headers: {
        "Content-Type": "multipart/form-data",
      },
    });
  }

  function confirmEndDate() {
    setDoc(
      doc(
        db,
        "organizations",
        selectedOrganization,
        "organizations_private",
        "data",
        "rounds",
        roundId
      ),
      { endDate },
      { merge: true }
    );
    setDatePickerOpen(false);
  }

  function deleteEndDate() {
    setDoc(
      doc(
        db,
        "organizations",
        selectedOrganization,
        "organizations_private",
        "data",
        "rounds",
        roundId
      ),
      { endDate: null },
      { merge: true }
    );
    setDatePickerOpen(false);
  }

  function uploadTemplate(e) {
    var formData = new FormData();
    var file = e.target.files[0];
    formData.append("file", file);
    formData.append("organizationId", selectedOrganization);
    formData.append("roundId", roundId);

    post("uploadTemplate", formData, {
      headers: {
        "Content-Type": "multipart/form-data",
      },
    });
  }

  async function fetchRound() {
    if (!selectedOrganization || !roundId) {
      return;
    }

    if (!roundSub) {
      roundSub = onSnapshot(
        doc(
          db,
          "organizations",
          selectedOrganization,
          "organizations_private",
          "data",
          "rounds",
          roundId
        ),
        (doc) => {
          const source = doc.metadata.hasPendingWrites ? "Local" : "Server";
          setRound(doc.data());
        }
      );
    }
  }

  async function downloadTemplate(url) {
    const storage = getStorage();

    getDownloadURL(ref(storage, url))
      .then((url) => {
        window.location = url;
      })
      .catch((error) => {});
  }

  async function deleteTemplate(templateId) {
    const newTemplates = round.templates.filter((item) => {
      return item.templateId !== templateId;
    });

    setDoc(
      doc(
        db,
        "organizations",
        selectedOrganization,
        "organizations_private",
        "data",
        "rounds",
        roundId
      ),
      { templates: newTemplates },
      { merge: true }
    );
  }

  async function deleteDeck(deckId) {
    const newDecks = round.decks.filter((item) => {
      return item.deckId !== deckId;
    });

    setDoc(
      doc(
        db,
        "organizations",
        selectedOrganization,
        "organizations_private",
        "data",
        "rounds",
        roundId
      ),
      { decks: newDecks },
      { merge: true }
    );
  }

  useEffect(() => {
    if (!round) {
      return;
    }
    if (round.templates) {
      const tmp = [];
      for (const t of round.templates) {
        if (!t.name) {
          continue;
        }
        tmp.push(
          <div className="outlined mb-2">
            <div className="d-flex py-2">
              <div className="flex-grow-1">
                <p className="small mt-1 mb-0">{t.name}</p>
              </div>
              <MSButton
                className="me-2"
                title="View"
                type="light small"
                onClick={(e) => {
                  downloadTemplate(t.fileUrl);
                }}
              />
              <MSButton
                title="Delete"
                type="light small"
                onClick={(e) => {
                  deleteTemplate(t.templateId);
                }}
              />
            </div>
          </div>
        );
      }

      if (round.templates.length > 0) {
        setTemplate(JSON.stringify(round.templates[0]));
      }
      setTemplates(tmp);
    }

    if (round.decks) {
      const tmp = [];
      for (const t of round.decks) {
        if (!t.name) {
          continue;
        }
        tmp.push(
          <div className="d-flex py-2">
            <div className="flex-grow-1">
              <p className="mt-1 mb-0">{t.name}</p>
              <p className="small mb-0">
                {"Uploaded " +
                  moment(new Date(t.createdDate.milliseconds)).fromNow()}
              </p>
            </div>
            <div>
              {/* <MSButton className="me-2" title="View" type="light small" onClick={(e) => { }} /> */}
              <MSButton
                className="me-2"
                title="Delete"
                type="light small"
                onClick={(e) => {
                  setDeckConfirmId(t.deckId);
                }}
              />
              <Link to={`/org/fundraise/manage/${roundId}/visits/${t.deckId}`}>
                <MSButton title="Analytics" type="light small" />
              </Link>
            </div>
          </div>
        );
      }
      setDecks(tmp);
    }
  }, [round]);

  async function setRoundPublic(pub) {
    await setDoc(
      doc(
        db,
        "organizations",
        selectedOrganization,
        "organizations_private",
        "data",
        "rounds",
        roundId
      ),
      {
        public: pub,
      },
      { merge: true }
    );
  }

  async function fetchInvestments() {
    if (!user.uid || !roundId) {
      return;
    }
    const q = query(
      collection(
        db,
        `organizations/${selectedOrganization}/organizations_admin/data/investments`
      ),
      where("roundId", "==", roundId),
      orderBy("createdDate", "desc")
    );

    if (!investmentsSub) {
      investmentsSub = onSnapshot(q, (snapshot) => {
        const ret = [];
        let init = 0;
        let sign = 0;
        let inBank = 0;

        snapshot.forEach((doc) => {
          const source = doc.metadata.hasPendingWrites ? "Local" : "Server";

          if (doc.data().createdDate) {
            ret.push(doc);
          }

          if (doc.data().status === "completed") {
            init += doc.data().investmentAmount;
            sign += doc.data().investmentAmount;
            inBank += doc.data().investmentAmount;
          } else if (doc.data().status === "investor_signed") {
            init += doc.data().investmentAmount;
            sign += doc.data().investmentAmount;
          } else if (
            doc.data().status === "initiated" ||
            doc.data().status === "invited"
          ) {
            init += doc.data().investmentAmount;
          }
        });

        setInvestments(ret);
        setInitiated(init);
        setSigned(sign);
        setInTheBank(inBank);
      });
    }
  }

  function investmentContentForInvestment(doc, i) {
    const investment = doc.data();
    const investmentLink = `/org/fundraise/manage/${roundId}/investment/${doc.id}`;

    return (
      <TableRow key={doc.id} component={Link} to={investmentLink}>
        <TableCell>
          <div className="company-icon"></div>
          <span>
            {investment.investor.displayName
              ? investment.investor.displayName
              : investment.investorEmail}
          </span>
        </TableCell>

        <TableCell>
          <div>
            {new Date(investment.createdDate.seconds * 1000).toLocaleString()}
          </div>
        </TableCell>
        <TableCell>
          <span className="text-success">
            {investment.investmentAmount
              ? "$" + investment.investmentAmount.toLocaleString()
              : ""}
          </span>
        </TableCell>
        <TableCell>
          <span>{investment.status}</span>
        </TableCell>
      </TableRow>
    );
  }

  async function createInvestment(investment) {
    const params = {
      investorEmail: investment.email,
      organizationId: selectedOrganization,
      roundId: roundId,
    };
    if (investment.template) {
      params.template = JSON.parse(investment.template);
    }

    if (investment.amount !== undefined && investment.amount !== null) {
      params.investmentAmount = investment.amount;
    }

    await post(`inviteInvestor`, params);
  }

  let content;

  if (round) {
    content = (
      <div className="content-body section-padding">
        <div className="container">
          <div className="row justify-content-center align-items-center">
            <div className="col-4 col-xs-12">
              <div className="card outlined py-4">
                <div className="d-flex">
                  <div className="big-icon">
                    <i className="la la-handshake"></i>
                  </div>
                  <div>
                    <h2>
                      <CountUp
                        isCounting
                        end={initiated}
                        duration={0.5}
                        decimalPlaces={0}
                        formatter={(value) =>
                          "$" + value.toLocaleString().split(".")[0]
                        }
                      />
                    </h2>
                    <h6>Initiated</h6>
                  </div>
                </div>
              </div>
            </div>
            <div className="col-4 col-xs-12">
              <div className="card outlined py-4">
                <div className="d-flex">
                  <div className="big-icon">
                    <i className="la la-money-bill"></i>
                  </div>
                  <div>
                    <h2>
                      <CountUp
                        isCounting
                        end={signed}
                        duration={0.5}
                        decimalPlaces={0}
                        formatter={(value) =>
                          "$" + value.toLocaleString().split(".")[0]
                        }
                      />
                    </h2>
                    <h6>Signed</h6>
                  </div>
                </div>
              </div>
            </div>
            <div className="col-4 col-xs-12">
              <div className="card outlined py-4">
                <div className="d-flex">
                  <div className="big-icon">
                    <i className="la la-bank"></i>
                  </div>
                  <div>
                    <h2>
                      <CountUp
                        isCounting
                        end={inTheBank}
                        duration={0.5}
                        decimalPlaces={0}
                        formatter={(value) =>
                          "$" + value.toLocaleString().split(".")[0]
                        }
                      />
                    </h2>
                    <h6>In the Bank</h6>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div className="row justify-content-center align-items-center">
            <div className="col-xl-12">
              <div className="card outlined">
                <div className="card-header border-0">
                  <div>
                    <h4 className="card-title mb-3">Manage round</h4>
                  </div>
                </div>

                <Formik
                  enableReinitialize
                  initialValues={{
                    template: template,
                  }}
                  validationSchema={InvestorSchema}
                  onSubmit={async (values) => {
                    try {
                      await createInvestment(values);
                      setIsAddingInvestor(false);
                    } catch (error) {
                      console.log("error", error);
                    }
                  }}
                >
                  {({ errors, setFieldValue, isSubmitting }) => (
                    <Form>
                      <div className="card-body pt-2">
                        <div className="col-12 mt-3">
                          {round && (
                            <>
                              <div className="mb-3">
                                <h4>Details</h4>
                              </div>
                              <div className="outlined-small pt-4">
                                <div className="col-12 d-flex">
                                  <div className="flex-grow-1">
                                    <label>
                                      <span style={{ fontWeight: 600 }}>
                                        {round.public &&
                                          "Public fundraising round"}
                                        {!round.public &&
                                          "Private fundraising round"}
                                      </span>
                                    </label>
                                    <p
                                      className={
                                        "small mt-1 " +
                                        (round.public ? "mb-0" : "mb-0")
                                      }
                                    >
                                      {!round.public &&
                                        "Only investors that you add can invest in your fundraising round."}
                                      {round.public &&
                                        "Any accredited investor can view and invest in your round when they use the generated link."}
                                    </p>
                                  </div>

                                  <label className="mt-2">
                                    <input
                                      className="toggle-checkbox"
                                      type="checkbox"
                                      onChange={(e) => {
                                        setRoundPublic(e.target.checked);
                                      }}
                                      checked={round.public}
                                    />
                                    <span className="toggle-switch ms-2"></span>
                                  </label>
                                </div>

                                <div className="col-12 mb-4">
                                  {round.public && (
                                    <div className="input-with-icon right">
                                      <MSField
                                        name="buyerLink"
                                        value={`${getBaseUrl()}/org/${selectedOrganization}/fundraise/round/${roundId}`}
                                        InputProps={{
                                          endAdornment: (
                                            <Tooltip
                                              title="Saved link to clipboard"
                                              open={isTooltipOpen}
                                              disableFocusListener
                                              disableHoverListener
                                              disableTouchListener
                                            >
                                              <InputAdornment position="end">
                                                <MSButton
                                                  style={{ margin: 0 }}
                                                  onClick={copyLink}
                                                  edge="end"
                                                  variant="icon"
                                                >
                                                  <FontAwesomeIcon
                                                    icon={regular("copy")}
                                                    size="xs"
                                                  />
                                                </MSButton>
                                              </InputAdornment>
                                            </Tooltip>
                                          ),
                                        }}
                                      />
                                    </div>
                                  )}
                                </div>
                                {round.public && (
                                  <>
                                    <Divider style={{ left: 48, right: 48 }} />
                                    <div className="mt-5">
                                      <div className="d-flex">
                                        {round.endDate && (
                                          <>
                                            <div className="flex-grow-1">
                                              <span style={{ fontWeight: 600 }}>
                                                End date
                                              </span>
                                              <p className="small mt-1 mb-0">{`Investors will be able to invest until ${moment(
                                                new Date(
                                                  round.endDate.seconds * 1000
                                                )
                                              ).calendar()}.`}</p>
                                            </div>
                                            <div>
                                              <MSButton
                                                onClick={() => {
                                                  setDatePickerOpen(true);
                                                }}
                                                type="light small"
                                                title="Change"
                                              />
                                              <input
                                                id="deck-upload"
                                                type="file"
                                                style={{ display: "none" }}
                                                onChange={(e) => {
                                                  uploadDeck(e);
                                                }}
                                              />
                                            </div>
                                          </>
                                        )}
                                        {!round.endDate && (
                                          <>
                                            <div className="flex-grow-1">
                                              <span style={{ fontWeight: 600 }}>
                                                No end date
                                              </span>
                                              <p className="small mt-1 mb-4">
                                                Investors will be able to invest
                                                indefinitely.
                                              </p>
                                            </div>
                                            <div>
                                              <MSButton
                                                onClick={() => {
                                                  setDatePickerOpen(true);
                                                }}
                                                type="light small"
                                                title="Set end date"
                                              />
                                              <input
                                                id="deck-upload"
                                                type="file"
                                                style={{ display: "none" }}
                                                onChange={(e) => {
                                                  uploadDeck(e);
                                                }}
                                              />
                                            </div>
                                          </>
                                        )}
                                      </div>
                                    </div>
                                  </>
                                )}
                                {round.public && (
                                  <>
                                    <Divider style={{ left: 48, right: 48 }} />
                                    <div className="pt-4">
                                      {decks.length === 0 && (
                                        <div className="d-flex">
                                          <div className="flex-grow-1 mb-2">
                                            <span style={{ fontWeight: 600 }}>
                                              Decks
                                            </span>
                                            <p className="small mt-1">
                                              No decks have been uploaded.
                                            </p>
                                          </div>
                                          <div>
                                            <MSButton
                                              onClick={() => {
                                                document
                                                  .getElementById("deck-upload")
                                                  .click();
                                              }}
                                              size="small"
                                              title="Upload deck"
                                              startIcon={
                                                <FontAwesomeIcon
                                                  icon={regular("upload")}
                                                />
                                              }
                                            />
                                            <input
                                              id="deck-upload"
                                              type="file"
                                              style={{ display: "none" }}
                                              onChange={(e) => {
                                                uploadDeck(e);
                                              }}
                                            />
                                          </div>
                                        </div>
                                      )}
                                      {decks.length > 0 && (
                                        <>
                                          <div
                                            className={"mb-0"}
                                            style={{ fontWeight: 600 }}
                                          >
                                            Decks
                                          </div>
                                          {decks}
                                        </>
                                      )}
                                    </div>
                                  </>
                                )}
                              </div>

                              <div className="position-relative">
                                <div className="mb-3 mt-5">
                                  <h4>Custom Fundraising Templates</h4>
                                </div>
                                <div
                                  style={{
                                    position: "absolute",
                                    right: "0px",
                                    top: "0px",
                                  }}
                                >
                                  <div
                                    className="forward-button"
                                    onClick={() => {}}
                                  >
                                    <label htmlFor="template-upload">
                                      <span>Upload new custom template</span>
                                    </label>
                                    <input
                                      id="template-upload"
                                      type="file"
                                      style={{ display: "none" }}
                                      onChange={(e) => {
                                        uploadTemplate(e);
                                      }}
                                    />
                                    <i className="la la-angle-right"></i>
                                  </div>
                                </div>

                                {templates.length === 0 && (
                                  <div className="outlined-small">
                                    <div className="d-flex py-2">
                                      <div className="flex-grow-1">
                                        <span
                                          className="mb-2"
                                          style={{ fontWeight: 600 }}
                                        >
                                          No templates
                                        </span>
                                        <p className="small mt-1 mb-0">
                                          No custom templates have been uploaded
                                          yet. Upload a template and it will
                                          appear here.
                                        </p>
                                      </div>
                                    </div>
                                  </div>
                                )}
                                {templates.length > 0 && templates}
                              </div>
                            </>
                          )}
                        </div>

                        <div className="col-12 mt-5">
                          <div className="position-relative">
                            <h4>Investors</h4>
                            <div className="d-flex">
                              <div className="flex-grow-1">
                                <label>
                                  <Checkbox
                                    className="mt-0"
                                    onChange={(e) => {
                                      setHideVoidedInvestments(
                                        e.target.checked
                                      );
                                    }}
                                    checked={hideVoidedInvestments}
                                    sx={{
                                      color: "#111",
                                      "&.Mui-checked": {
                                        color: "var(--primary)",
                                      },
                                    }}
                                  />
                                </label>
                                <span>Hide voided investments</span>
                              </div>
                              <div>
                                <div>
                                  {isAddingInvestor && (
                                    <div
                                      className="back-button"
                                      onClick={() => {
                                        setIsAddingInvestor(false);
                                      }}
                                    >
                                      <i className="la la-angle-left"></i>
                                      <span>Cancel</span>
                                    </div>
                                  )}
                                  {!isAddingInvestor && (
                                    <div
                                      className="forward-button"
                                      onClick={() => {
                                        setIsAddingInvestor(true);
                                      }}
                                    >
                                      <span>Add an investor</span>
                                      <i className="la la-angle-right"></i>
                                    </div>
                                  )}
                                </div>
                              </div>
                            </div>
                          </div>
                          {isAddingInvestor && (
                            <div className="outlined card">
                              <div className="row">
                                <div className="mb-0 col-5">
                                  <h6 className="form-label">Email</h6>
                                  <Field
                                    type="text"
                                    name="email"
                                    autoFocus
                                    className={
                                      "form-control " +
                                      (errors.email && "is-invalid")
                                    }
                                    placeholder="email@domain.com"
                                  />
                                  {errors.email && (
                                    <p className="small is-invalid">
                                      {errors.email}
                                    </p>
                                  )}
                                </div>
                                <div className="mb-0 col-3">
                                  <div className="d-flex">
                                    <h6 className="form-label">Amount</h6>
                                    <Tooltip
                                      title="Specifying an amount is optional. If empty, the investor is free to invest as much as they want."
                                      placement="top-start"
                                    >
                                      <i className="ms-1 form-label las la-question-circle"></i>
                                    </Tooltip>
                                  </div>

                                  <div className="input-with-icon">
                                    <i className="fa fa-usd icon"></i>
                                    <NumberFormat
                                      name="amount"
                                      className={
                                        "form-control mb-3 text-end " +
                                        (errors.amount && "is-invalid")
                                      }
                                      onValueChange={(val) =>
                                        setFieldValue("amount", val.floatValue)
                                      }
                                      allowNegative={false}
                                      isNumericString={true}
                                      thousandSeparator={true}
                                    />
                                  </div>
                                </div>
                                <div className="mb-0 col-4">
                                  <h6 className="form-label">Template</h6>
                                  <Field
                                    type="text"
                                    name="template"
                                    component="select"
                                    className={
                                      "form-control " +
                                      (errors.template && "is-invalid")
                                    }
                                  >
                                    {round.templates &&
                                      round.templates.map((template) => {
                                        if (!template.name) {
                                          return null;
                                        }
                                        return (
                                          <option
                                            value={JSON.stringify(template)}
                                          >
                                            {template.name}
                                          </option>
                                        );
                                      })}
                                  </Field>
                                  {errors.email && (
                                    <p className="small is-invalid">
                                      {errors.template}
                                    </p>
                                  )}
                                </div>
                                <div>
                                  <MSButton
                                    className="float-end mb-0 mt-2"
                                    size="small"
                                    title="Add"
                                    icon="la la-plus"
                                    loading={isSubmitting}
                                  />
                                </div>
                              </div>
                            </div>
                          )}

                          {!isAddingInvestor && (
                            <TableContainer
                              style={{
                                border: "1px solid var(--purple-light  )",
                                borderRadius: 8,
                              }}
                            >
                              <Table aria-label="simple table">
                                <TableHead>
                                  <TableRow>
                                    <TableCell>Investor</TableCell>
                                    <TableCell>Investment Date</TableCell>
                                    <TableCell>Amount</TableCell>
                                    <TableCell>Status</TableCell>
                                  </TableRow>
                                </TableHead>
                                <TableBody>
                                  {parsedInvestments.map((e, i) =>
                                    investmentContentForInvestment(e, i)
                                  )}
                                </TableBody>
                              </Table>
                            </TableContainer>
                          )}
                        </div>
                      </div>
                    </Form>
                  )}
                </Formik>
              </div>
            </div>
          </div>
        </div>
        <Dialog
          open={deckConfirmId ?? false}
          onClose={() => {
            setDeckConfirmId();
          }}
        >
          <div className="p-4">
            <h3>Delete this deck?</h3>
            <p className="mb-0">
              Deleting this deck will also delete the analytics with it.
            </p>
          </div>

          <DialogActions className="me-3 mb-3">
            <MSButton
              title="Cancel"
              type="light small"
              onClick={() => {
                setDeckConfirmId();
              }}
            >
              Disagree
            </MSButton>
            <MSButton
              title="Delete"
              type="light small danger"
              onClick={() => {
                setDeckConfirmId();
                deleteDeck(deckConfirmId);
              }}
              autoFocus
            >
              Agree
            </MSButton>
          </DialogActions>
        </Dialog>

        <Dialog
          open={datePickerOpen ?? false}
          onClose={() => {}}
          aria-labelledby="alert-dialog-title"
          aria-describedby="alert-dialog-description"
        >
          <div className="p-4">
            <h3>Select end date</h3>
            <p className="mb-0">
              <DatePicker
                selected={endDate}
                onChange={(date) => setEndDate(date)}
                showTimeSelect
                inline
              />
            </p>
          </div>

          <DialogActions className="me-3 mb-3">
            {round.endDate && (
              <MSButton
                title="Delete"
                type="light small danger"
                onClick={() => {
                  deleteEndDate();
                }}
              >
                Delete
              </MSButton>
            )}
            <MSButton
              title="Cancel"
              type="light small"
              onClick={() => {
                setDatePickerOpen(false);
              }}
            >
              Cancel
            </MSButton>
            <MSButton
              title="Confirm"
              type="light small"
              onClick={() => {
                confirmEndDate();
              }}
              autoFocus
            >
              Confirm
            </MSButton>
          </DialogActions>
        </Dialog>
      </div>
    );
  } else {
    content = (
      <div className="content-body section-padding">
        <div className="container">
          <div className="row justify-content-center align-items-center">
            <div className="col-12">
              <div className="card outlined py-4">
                <Skeleton height={50} />
                <Skeleton height={50} />
                <Skeleton height={50} />
                <Skeleton height={200} />
                <Skeleton height={200} />
              </div>
            </div>
          </div>
        </div>
      </div>
    );
  }

  return (
    <>
      <Header4 hideAllMenuItems />
      {content}
    </>
  );
}

export default Dashboard;
