import Header4 from "jsx/layout/header4";
import React, { useEffect } from "react";
import { Link } from "react-router-dom";

function PrivacyPolicy() {
  useEffect(() => {
    window.scrollTo({
      top: 0,
      left: 0,
      behavior: "instant",
    });
  }, []);

  return (
    <>
      <Header4 hideAllMenuItems />

      <div className="terms_condition mt-4">
        <div className="container">
          <div className="row justify-content-center">
            <div className="col-lg-9">
              <div className="terms_condition-content">
                <h2>MODERN SHARE PRIVACY POLICY</h2>
                <h5 className="mb-3">Effective May 10, 2022</h5>
                <p>
                  At M Share Capital LLC (the “Company”), your privacy is very
                  important. We value transparency and want to be clear about
                  how we collect, use and share information about you.
                </p>

                <p>This policy is here to help you understand:</p>

                <ul>
                  <li>The categories of information we collect</li>
                  <li>How we use information we collect</li>
                  <li>How we share information we collect</li>
                  <li>How we store and secure information we collect</li>
                  <li>Other important privacy information</li>
                </ul>

                <p>
                  This Privacy Policy applies to you when you access or use any
                  domains or subdomains of Modern Share’s website at{" "}
                  <Link to="/">https://modernshare.com</Link> (the “Site”),
                  download and/or use Modern Share’s application (the
                  “Application”), or when you access, use, or download any other
                  products, services, features, content, widgets, materials or
                  other tools offered by the Company or its subsidiaries and
                  affiliates, including Modern Share, Inc. (collectively the
                  “Services”). “Modern Share”, “we”, “us”, and “our” refer to
                  the Company together with any of our subsidiaries and
                  corporate affiliates. For more information about our Terms of
                  Use, please click <Link to="/terms-condition">here</Link>.
                </p>

                <h3>WHAT INFORMATION WE COLLECT ABOUT YOU</h3>

                <p>
                  We collect the categories of personal information listed
                  below:
                </p>

                <ul>
                  <li>
                    Directly from you when you provide the information to us,
                  </li>
                  <li>
                    Indirectly from you when you use or access our Services, the
                    Site, or the Application, and
                  </li>
                  <li>
                    From other sources like third-party service providers and
                    partners.
                  </li>
                </ul>

                <h4 className="mt-4">Information you provide to us</h4>
                <p>
                  We collect information from you when you input it or otherwise
                  provide it directly to us.
                </p>
                <p>
                  <em>
                    <strong>Account Information:</strong>
                  </em>{" "}
                  We collect information about you when you create an account or
                  sign up for Services. For example, you provide your name,
                  email address, password, and phone number when you create an
                  account. Depending on the Services you select, you may provide
                  different information, such as your address, date of birth,
                  taxpayer ID and/or other information. We collect this
                  information to verify your identity and to help the government
                  fight the funding of terrorism and money-laundering
                  activities, as required by U.S. law. If you register or use
                  the Services through third-party sites or applications, such
                  as Google, we may gather information from them.
                </p>
                <p>
                  <em>
                    <strong>
                      Bank, Brokerage and Other Payment Information:
                    </strong>
                  </em>{" "}
                  You may provide your banking information, such as bank account
                  and routing numbers, to facilitate the sending or receiving of
                  funds in connection with transactions effected through the
                  Site or Application.
                </p>
                <p>
                  <em>
                    <strong>Buyer Information:</strong>
                  </em>{" "}
                  If you are a potential investor, when you create an account or
                  sign up for Services, you may provide us information
                  concerning whether you are investing on your own behalf or
                  that of an institution, your status as an accredited investor,
                  your investment objectives, risk tolerance, investment
                  time-horizon, investment experience, and/or information
                  concerning your income and/or net worth.{" "}
                </p>
                <p>
                  For investments related to some issuers, you may also provide
                  us employment information or representations that you are not
                  affiliated with, or employed by, a competitor of that issuer.
                  If you express interest in investing in a particular
                  investment opportunity, you provide us information about your
                  interest and preferences which may include the investment
                  amount and other information regarding your interest in making
                  an investment.
                </p>
                <p>
                  <em>
                    <strong>Seller Information:</strong>
                  </em>{" "}
                  To sell private company equity with us, you may provide us
                  information and documents concerning your ownership of shares
                  or options. For example, you may provide information about the
                  company in which you own the shares, ownership type, and the
                  number of shares you want to sell. If you are a seller, you
                  also provide us information and documents reflecting your
                  vesting and other attributes applicable to your shares or
                  options. You may also be asked to provide your spouse’s name
                  and email address if you are married. You also provide
                  information about your preferences, such as, for example, the
                  price at which you desire to sell your shares.
                </p>
                <p>
                  <em>
                    <strong>Issuer Information:</strong>
                  </em>{" "}
                  To engage in a transaction, whether primary or secondary
                  (including tender offers), on the Site or Application, you may
                  provide us information and documents concerning your
                  capitalization table, employee data and other documents and
                  information about your business. For example, you may provide
                  you’re an employee census or capitalization table containing
                  ownership and vesting information, your Certificate of
                  Information and/or shareholder agreements.
                </p>
                <p>
                  <em>
                    <strong>
                      Support Information and Other Communications:
                    </strong>
                  </em>{" "}
                  The Services include customer support, where you may choose to
                  submit information or questions concerning a problem you are
                  experiencing with a Service or questions you have about a
                  Service. You may also provide, or be asked to provide,
                  screenshots, documentation or information to help us address
                  your problem or respond to your question.
                </p>
                <p>
                  You may also provide us with feedback and survey responses
                  when using the Service, as well as other information you
                  voluntarily provide to us when communicating with us, such as
                  via email, any chat bots available on the Site and/or the
                  Application, or via other actions you perform when using the
                  Services. You may also provide us with your marketing and
                  email preferences in your account settings.
                </p>
                <p>
                  <em>
                    <strong>Trust Information:</strong>
                  </em>{" "}
                  If you purchase or sell securities held in the name of the
                  trust, you provide us with additional information and
                  documents concerning the identity and contact information of
                  individuals related to the trust.
                </p>
                <p>
                  <em>
                    <strong>Trusted Contact Person:</strong>
                  </em>{" "}
                  You are permitted, but not required, to provide the name and
                  contact information of a Trusted Contact Person who can be
                  contacted about your account. For more information about
                  FINRA’s Trusted Contact Person program, please click{" "}
                  <Link to="/trusted-contact">here</Link>.
                </p>
                <p>
                  <em>
                    <strong>Verification Information and Documents:</strong>
                  </em>{" "}
                  If required to help us verify your identity, you may provide
                  us a copy of your driver’s license, passport or other
                  government-issued ID. If required to help us verify your
                  residence, you may provide us with a utility bill, lease or
                  other proof of residence. You may also provide us other
                  verification documents, such as bank statements reflecting the
                  source of funds, to meet U.S. Bank Secrecy Act or anti-money
                  laundering obligations, U.S. Treasury department rules or
                  regulations or other legal requirements. By providing the
                  verification information and documents mentioned above, you
                  also provide us information within those records, which may
                  include characteristics of protected classifications like your
                  sex, age, national origin, and citizenship status.
                </p>
                <p>
                  <em>
                    <strong>Voluntary Information:</strong>
                  </em>{" "}
                  If you authorize your accountant, financial advisor, lawyer or
                  relative to receive information about our Services on your
                  behalf, or to take actions on the Site on your behalf, you
                  provide us the name and contact information for the person you
                  authorize.{" "}
                </p>
                <p>
                  <em>
                    <strong>Transaction Information:</strong>
                  </em>{" "}
                  We collect information in connection with the transactions you
                  become a party to through our Services as either an issuer,
                  buyer or seller (“Transaction Data”). Transaction Data
                  includes, without limitation, the terms on which you purchase
                  or sell securities and information relating to price, size,
                  volume, class of securities, bids, offers, transfer
                  restrictions, and any derivative data related thereto.
                </p>

                <h4 className="mt-4">
                  Information we collect when you use the Services
                </h4>
                <p>
                  We collect the following categories of information about you
                  when you use our services, including when visiting the Site or
                  the Application, and taking certain actions within the
                  Services.
                </p>
                <p>
                  <em>
                    <strong>Information About Your Use of the Services:</strong>
                  </em>{" "}
                  We collect certain information about you when you visit the
                  Site and interact with any of our Services. We collect
                  information about when you use Services, the pages within the
                  Site and/or the Application you visit, the links you click on,
                  the type, size and names of files you upload or download, and
                  the search terms you use. If you invite others to use the Site
                  or Application, we retain information about your referral.
                </p>
                <p>
                  <em>
                    <strong>Device and Connection Information:</strong>
                  </em>{" "}
                  We collect information about your computer, phone, tablet or
                  other device you use to access the Services. This device
                  information may include your operating system, browser type,
                  IP address, URLs of referring pages, App name and version, and
                  device identifiers.
                </p>
                <p>
                  <em>
                    <strong>
                      Cookies, Web Beacons, Pixel Tags and Other Similar
                      Technologies:
                    </strong>
                  </em>{" "}
                  Modern Share and its third-party service providers, such as
                  our analytics and advertising partners, collect certain
                  information using cookies, web beacons, pixel tags and other
                  similar technologies to provide functionality, enable and
                  support security features, recognize you across different
                  devices, improve our offerings, understand how you use and
                  interact with our Services, improve performance of Services,
                  and otherwise manage and enhance Services. “Cookies” are
                  simple text files stored on your device for use by a browser.
                  “Web Beacons” or “pixel tags” are small images, often
                  transparent, on a website or email that are used to observe if
                  you have accessed content. Using cookies, web beacons or pixel
                  tags, or other tracking technologies are standard practices on
                  internet web sites.
                </p>
                <p>
                  Some web browsers transmit “do-not-track” signals to websites.
                  At this time, our Services are not designed to respond to
                  these signals or similar mechanisms from browsers.
                </p>
                <p>
                  We use session cookies to make it easier for you to use our
                  Services and to identify you while you are on the Site or
                  Application. We also use persistent cookies to make it easier
                  for you to navigate the Site and track whether you are logged
                  into the Site. A persistent cookie remains on your device’s
                  hard drive for an extended time. You can remove persistent
                  cookies by following the directions provided in your internet
                  browser’s “help” file. If you reject persistent cookies, you
                  will still be able to view the Site, but your ability to use
                  some areas of the Site, such as automatically-generated
                  recommendations, will be limited.
                </p>

                <h4 className="mt-4">
                  Information we receive from other sources
                </h4>
                <p>
                  We receive information about you from third-party services and
                  business partners.
                </p>
                <p>
                  <em>
                    <strong>
                      Third-Party Identity Verification Service Provider:
                    </strong>
                  </em>{" "}
                  We receive information about you from a third-party identity
                  verification service provider to help us confirm your
                  identity, prevent fraud, money-laundering and other illegal
                  activity, and to satisfy legal requirements relating to the
                  Services.
                </p>
                <p>
                  <em>
                    <strong>
                      Marketing and Advertising Services and Partners:
                    </strong>
                  </em>{" "}
                  We receive information about you and your activities on and
                  off the Services from third-party service providers, such as
                  Google, Mixpanel, HotJar, LinkedIn and other advertising,
                  social media and market research partners who provide us with
                  information about you, and/or your engagement with and
                  interest in our Services.
                </p>

                <h3>HOW WE USE INFORMATION WE COLLECT</h3>

                <p>
                  We use the information we collect based on the Services you
                  choose to receive. The specific purposes are described below.
                </p>

                <p>
                  <em>
                    <strong>
                      To Provide the Services and Personalize Your Experience:
                    </strong>
                  </em>{" "}
                  We use information about you to provide the Services to you,
                  including to process transactions for you, authenticate you
                  when you log in, verify your identity, and, if you are seeking
                  to invest, determine your eligibility for investments. We also
                  use information you provide to validate that information,
                  comply with legal requirements, provide customer support and
                  communicate with you, and operate and maintain the Services.{" "}
                </p>
                <p>
                  We also use information about you to personalize and improve
                  your experience while using the Services and to determine
                  effectiveness of the Services. We also use information about
                  you and your activities to provide an integrated experience,
                  such as allowing you to access the Services from multiple
                  devices.
                </p>
                <p>
                  <em>
                    <strong>To Communicate With You About the Services:</strong>
                  </em>{" "}
                  We use your contact information to send communications to you
                  (typically via email) about your transactions, including
                  confirming the terms on which you purchase or sell securities,
                  updating you on the status of transactions and related
                  deadlines, responding to your questions or comments, and
                  providing information received from the issuer or, when
                  applicable, other parties to your transactions. If you are
                  eligible to invest through the Site, we send you notifications
                  about potential investment opportunities available through the
                  Site. We also provide tailored communications to you based on
                  actions you take on the Site. For example, if you start the
                  investment process on the Site, we will use your contact
                  information to remind you to come back and complete the
                  investment process. We also send you communications about the
                  Services as part of onboarding to help you understand and use
                  the Services.
                </p>
                <p>
                  If you download and/or use the Application, and if you are a
                  registered user of the Application, we may send push
                  notifications to your device as a way to deliver information,
                  offers, alerts, updates, reminders, news, and other relevant
                  notifications pertaining to Services, including messages,
                  sounds, and hyperlinks to pages within the Application, or
                  hyperlinks to pages on the Site. Push notifications may be
                  controlled from within the Application’s settings and at the
                  device’s operating system “OS” level at any time. For
                  additional information about push notifications, please see
                  our Terms of Use.
                </p>
                <p>
                  <em>
                    <strong>To Operate the Services:</strong>
                  </em>{" "}
                  We share information about you as needed with our own service
                  providers, including financial institutions, fund
                  administrators, accountants, auditors, lawyers, payment
                  providers, information technology staff, advisors and our
                  affiliates. We only share information to the extent it is
                  required to fulfill our obligations to you and to regulators,
                  and to operate the Site and the Services.
                </p>
                <p>
                  <em>
                    <strong>For Research and Development:</strong>
                  </em>{" "}
                  We strive to make our Services better, smarter, faster, more
                  secure and easier to use. We collect information about how
                  people use the Services and feedback provided directly to us
                  to identify trends and problems, usage and activity patterns,
                  and areas for integrating and improving our Services. We may
                  also test and analyze certain new features with some users
                  before rolling out the features to all users.
                </p>
                <p>
                  <em>
                    <strong>
                      To Market, Promote and Drive Engagement With the Services:
                    </strong>
                  </em>{" "}
                  We use your contact information and information about how you
                  use the Services to send promotional communications that may
                  be of interest to you, including by email and by displaying
                  our content on other websites and applications, such as on
                  platforms including Google or Facebook. These communications
                  are intended to increase engagement and what you get out of
                  the Services, including information about new features, new
                  content, and events we think may be of interest to you. We
                  also communicate with you about new products and services and
                  promotions.
                </p>
                <p>
                  We do not – and do not plan to – rent or sell personally
                  identifiable information about you to other people or to
                  non-affiliated companies.
                </p>
                <p>
                  <em>
                    <strong>For Safety and Security:</strong>
                  </em>{" "}
                  We use information about you and your Service use to verify
                  your identity, accounts and activity, to monitor suspicious or
                  fraudulent activity, and to identify violations of Service
                  policies. We also use your information to debug, identify and
                  fix errors that impair how our Services function.
                </p>
                <p>
                  <em>
                    <strong>As Required by Law or Regulations:</strong>
                  </em>{" "}
                  We retain information about you that is required by U.S. law
                  or regulation, including those applicable to the sale of
                  securities or operating an investment advisor or broker-dealer
                  (if, when and to the extent applicable). In general, we are
                  required to retain these records for at least three years, and
                  up to six years (and in some circumstances, longer). We may
                  keep some information longer to meet our needs and those of
                  our customers, even after you close or deactivate your
                  account, including for backup, archives, contract enforcement,
                  dispute resolution. We may access and disclose information
                  about you with U.S., state or international regulators where
                  we believe doing so is required or appropriate to comply with
                  laws, regulations or other legal processes or law enforcement
                  requests, such as court orders or subpoenas.
                </p>
                <p>
                  <em>
                    <strong>For a Merger, Sale or Other Asset Transfer:</strong>
                  </em>{" "}
                  If we are involved in a merger, acquisition, financing due
                  diligence, reorganization, bankruptcy, creation of a trust or
                  other affiliated entity, receivership or sale of Modern
                  Share’s assets, or other corporate transaction affecting
                  Modern Share, your information may be shared, transferred or
                  sold under a duty of confidentiality as part of a transaction
                  as permitted by law or by contract.
                </p>
                <p>
                  <em>
                    <strong>Legal Bases for Processing (for EEA users):</strong>
                  </em>{" "}
                  If you are an individual residing in the European Economic
                  Area (“EEA”), we collect and process information about you
                  only where we have legal bases for doing so under applicable
                  EU laws, including the General Data Protection Regulation
                  (“GDPR”). The legal bases depend on the Services you use and
                  how you use them. This means we collect and use your
                  information only where:
                </p>

                <ul>
                  <li>
                    We need it to provide you the Services, including to operate
                    the Services, perform a contract with you, take necessary
                    steps prior to performing a contract or at your request,
                    provide customer support, and to protect the safety and
                    security of the Services;
                  </li>
                  <li>
                    It satisfies a legitimate interest (which is not overridden
                    by your data protection interests), such as for research and
                    development, to market and promote the Services, to
                    personalize services you receive, and to protect our legal
                    rights and interests;
                  </li>
                  <li>
                    You give us consent to do so for a specific purpose; or
                  </li>
                  <li>
                    We need to process your data to comply with a legal
                    requirement.
                  </li>
                </ul>

                <p>
                  If you have consented to our use of information about you for
                  a specific purpose, you may change your mind at any time, but
                  this will not affect any processing that has already taken
                  place. Where we are using your information because we or a
                  third party (e.g. your employer) have a legitimate interest to
                  do so, you may object to that use though, in some cases, this
                  may mean no longer using the Services.
                </p>

                <p>
                  To do so, please email us at{" "}
                  <a href="mailto:support@modernshare.com">
                    support@modernshare.com
                  </a>
                  .
                </p>

                <h3>HOW WE SHARE INFORMATION WE COLLECT</h3>

                <p>
                  We manage a private company equity marketplace, and we want it
                  to work well for you. This means sharing information through
                  the Services and with certain third parties. We share
                  information we collect about you with third parties in the
                  ways discussed below, including in connection with possible
                  business transfers.
                </p>

                <p>
                  <em>
                    <strong>With Affiliates of Modern Share:</strong>
                  </em>{" "}
                  If you are a party to a prospective or actual transaction, we
                  share information about you with our affiliates, including our
                  broker-dealer, M Share Capital LLC, to provide you Services
                  relating to that transaction.
                </p>
                <p>
                  <em>
                    <strong>
                      With Parties to a Transaction, Companies and Service
                      Providers:
                    </strong>
                  </em>{" "}
                  If you are a party to a prospective or actual transaction, we
                  will give the other party (or other parties) to the
                  transaction the information they need to evaluate and enter
                  into that transaction. If you enter into a transaction with a
                  third-party company, such as by becoming a shareholder, that
                  company will receive information about you subject to its own
                  privacy policy, and may be required to disclose that
                  information to its own board members, officers, investors or
                  shareholders.
                </p>
                <p>
                  We also provide your information where necessary for a
                  transaction or to comply with legal requirements. For example,
                  we may provide certain aspects of your information to
                  financial institutions, payment processors, fund
                  administrators, accountants, auditors, lawyers, payment
                  providers, third-party hosting service, email and technology
                  service providers, information technology staff, advisors,
                  affiliates, marketing and analytics partners, or third-party
                  verification services.
                </p>
                <p>
                  As part of providing their services to us, our marketing and
                  advertising service providers mentioned above may use cookies
                  and other technologies to collect data on our users’ behavior
                  and their devices. This may include, for example, a device’s
                  IP address, device type (unique device identifiers), browser
                  information, and geographic location (region and/or country
                  only).
                </p>
                <p>
                  We do not and will not sell your personally identifiable
                  information. We also do not offer financial incentives,
                  preferential service agreements, or any other differences in
                  our prices or services in exchange for your data.
                </p>
                <p>
                  We may share aggregated, deidentified and/or anonymized
                  information that does not specifically identify you or any
                  individual user of our Services for any purpose with any third
                  parties, including but not limited with advertisers, national
                  industry organizations, and prospective affiliates, or as
                  permitted or required by law, or to protect our rights.
                </p>
                <p>
                  <em>
                    <strong>With Regulators:</strong>
                  </em>{" "}
                  We may share information about you with U.S., state or
                  international regulators where we believe doing so is required
                  or appropriate to comply with any laws, regulations or other
                  legal processes or law enforcement requests, such as court
                  orders or subpoenas.
                </p>
                <p>
                  We may also share information about you to protect the
                  security and integrity of our Services, to protect Modern
                  Share’s interests, or those of other persons or the public.
                </p>
                <p>
                  <em>
                    <strong>With Third-Party Sites:</strong>
                  </em>{" "}
                  The Site may contain links to third party web sites and may
                  redirect you to third party web sites (“Third Party Sites”).
                  These sites include, among others, service providers who have
                  a relationship with Modern Share. Third Party Sites are not
                  under our control, and we are not responsible for any Third
                  Party Sites, or the accuracy, sufficiency, correctness,
                  reliability, veracity, completeness, or timeliness of their
                  information, links, changes or updates. The inclusion or
                  access to Third Party Sites does not imply an endorsement
                  thereof by Modern Share, or of the provider of such content or
                  services, or of any third-party web site. Modern Share
                  reserves the right to terminate any link or linking program at
                  any time. Please be aware that when you enter a third-party
                  web site, any information you provide, including financial
                  information, is subject to the terms of use and privacy policy
                  of that site.
                </p>
                <p>
                  <em>
                    <strong>With Your Consent:</strong>
                  </em>{" "}
                  We share information about you where you have given us consent
                  to do so for a specific purpose. For example, we may disclose
                  information to your financial advisor, accountant attorney or
                  spouse, or other individual or company you authorize to
                  receive information about you.
                </p>

                <h3>HOW WE STORE AND SECURE INFORMATION WE COLLECT</h3>

                <p>
                  We take steps to ensure that your information is treated
                  securely and in accordance with this policy. While we use
                  industry-accepted security principles, practices, and tools,
                  the internet is not 100% secure and we cannot ensure or
                  guarantee the security of data, during transmission through
                  the Internet or while stored on our systems or otherwise in
                  our care. We do not accept liability for unintentional
                  disclosure.
                </p>
                <p>
                  By using the Service or providing personal data to us, you
                  agree that we may communicate with you electronically
                  regarding security, privacy, and administrative issues
                  relating to your use of the Service. If we learn of a security
                  breach, we may attempt to notify you electronically by posting
                  a notice on the Service or sending an email to you. You may
                  have a legal right to receive this notice in writing.
                </p>
                <p>
                  We use a data hosting service provider in the United States to
                  host the information we collect, and we use technical measures
                  to secure your data. We use industry-accepted practices,
                  including 256-bit encryption, to protect customer-related
                  information and also use field-level encryption on highly
                  sensitive data. Information is encrypted at rest and in
                  transit from client to web-to-web server, including proxies,
                  and to the database.
                </p>
                <p>
                  We request third party service providers to agree to keep your
                  information confidential by entering into agreements that
                  limit their use of the information they receive about you for
                  purposes of performing the services for which they are being
                  retained.
                </p>
                <p>
                  If you are outside the United States, by using the Services or
                  visiting the Site, you will transfer data to the United States
                  and are permitting your information to be used in accordance
                  with this Privacy Policy.
                </p>

                <h3>OTHER IMPORTANT PRIVACY INFORMATION</h3>

                <p>
                  You have choices and rights available to you when it comes to
                  your information. They are summarized below.
                </p>

                <p>
                  <em>
                    <strong>Your Rights as a California Resident:</strong>
                  </em>{" "}
                  Residents of California have the right to request from us, one
                  time per year, certain information with respect to the types
                  of personal information we share with third parties for those
                  third parties’ direct marketing purposes and the identities of
                  the third parties with whom the business has shared such
                  information during the immediately preceding calendar year.
                </p>
                <p>
                  To exercise your rights, please use one of the submission
                  methods mentioned below under “Submitting Requests Pertaining
                  to Your Information”.
                </p>
                <p>
                  <em>
                    <strong>Your Rights as a Vermont Resident:</strong>
                  </em>{" "}
                  We do not share nonpublic personal financial information we
                  collect about Vermont residents to non-affiliated third
                  parties except for our everyday business purposes or as
                  otherwise permitted by law. If you wish to revoke any
                  authorization to share your nonpublic personal financial
                  information, please contact us for assistance at
                  <a href="mailto:support@modernshare.com">
                    support@modernshare.com
                  </a>
                  , though we will no longer be able to provide you Services or
                  access to the Site.
                </p>
                <p>
                  <em>
                    <strong>
                      If You are a European Economic Area Resident:
                    </strong>
                  </em>{" "}
                  If you are an EEA data subject, you may review, correct or
                  update the personal data you provide at any time.
                </p>
                <p>
                  If you would like to exercise your GDPR rights, please use one
                  of the submission methods mentioned below under “Submitting
                  Requests Pertaining to Your Information”.
                </p>
                <p>
                  In the event of a data breach, we will endeavor to notify you
                  within 72 hours of detection of a breach that we conclude
                  poses significant risks to your rights and freedoms under the
                  General Data Protection Regulation. You may also lodge a
                  complaint with a supervisor authority in the EEA member state
                  in which you reside.
                </p>
                <p>
                  <em>
                    <strong>Access to Your Data and Documents:</strong>
                  </em>{" "}
                  You may review, correct or update the personal data you
                  provide at any time. You may access your documents relating to
                  your transactions at any time by logging into your account and
                  navigating to the “Dashboard” section of the Site or
                  Application. If you need assistance, please contact us at{" "}
                  <a href="mailto:support@modernshare.com">
                    support@modernshare.com
                  </a>
                  .
                </p>
                <p>
                  <em>
                    <strong>Unsubscribe Options:</strong>
                  </em>{" "}
                  If you chose to use the Services, you will receive periodic
                  emails or other electronic communications from us which, based
                  on the information you provide, may be of interest. If you no
                  longer wish to receive our communications, you may unsubscribe
                  by following the instructions included in each communication.
                </p>
                <p>
                  <em>
                    <strong>Children’s Privacy:</strong>
                  </em>{" "}
                  The Site and our Services are not directed to any person under
                  the age of 18. If you become aware that your child has
                  provided us with personal data without your consent, please
                  contact us at{" "}
                  <a href="mailto:support@modernshare.com">
                    support@modernshare.com
                  </a>
                  . We do not knowingly collect or solicit information from,
                  market to, or accept services from children.
                </p>
                <p>
                  <em>
                    <strong>Lost or Stolen Information:</strong>
                  </em>{" "}
                  You must promptly notify us if you become aware that any
                  information provided by or submitted to the Site is lost,
                  stolen or used without permission.
                </p>
                <p>
                  <em>
                    <strong>Changes to this Policy:</strong>
                  </em>{" "}
                  Each time you visit the Site, Application or use the Services,
                  the current version of this Privacy Policy will apply. When
                  you use the Site, you should check the date of this Privacy
                  Policy (which appears at the top of the Privacy Policy) and
                  review any changes since the last version. Our business
                  changes frequently and this Privacy Policy is subject to
                  change from time to time. Unless stated otherwise, our current
                  Privacy Policy applies to all information that we have about
                  you. We may require you to affirmatively assent to a revised
                  version of this Privacy Policy as a condition of continuing to
                  use the Site or our Services.
                </p>
                <p>
                  <em>
                    <strong>
                      Submitting Requests Pertaining to Your Information:
                    </strong>
                  </em>{" "}
                  If you would like to make a request regarding your
                  information, you may do so, free of charge, by emailing us at{" "}
                  <a href="mailto:support@modernshare.com">
                    support@modernshare.com
                  </a>
                  . Please note that for all requests, we will need to verify
                  your identity to confirm that you are the individual about
                  whom we have collected personal information.
                </p>
                <p>
                  <em>
                    <strong>Contact Us:</strong>
                  </em>{" "}
                  Your information is controlled by Modern Share. If you have
                  any questions or concerns about how your information is
                  handled, you may contact us at{" "}
                  <a href="mailto:support@modernshare.com">
                    support@modernshare.com
                  </a>
                  .
                </p>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
}

export default PrivacyPolicy;
