import Header4 from "jsx/layout/header4";
import React, { useEffect } from "react";
import { Link } from "react-router-dom";

function DisclaimersDisclosures() {
  useEffect(() => {
    window.scrollTo({
      top: 0,
      left: 0,
      behavior: "instant",
    });
  }, []);

  return (
    <>
      <Header4 hideAllMenuItems />

      <div className="terms_condition mt-4">
        <div className="container">
          <div className="row justify-content-center">
            <div className="col-lg-9">
              <div className="terms_condition-content">
                <h1>DISCLAIMERS & DISCLOSURES</h1>

                <h2>SECURITIES DISCLAIMER</h2>
                <p>
                  The following disclaimers and disclosures are made by Modern
                  Share, Inc. and its subsidiaries and affiliates (collectively,
                  “Modern Share”) concerning the information displayed on or
                  made available via Modern Share’s platform, whether through
                  its website or application (the “Platform”). Use of the
                  Platform and these disclaimers and disclosures are subject to
                  Modern Share’s <Link to="/terms-condition">Terms of Use</Link>
                  ; any capitalized term used but not defined in these
                  disclaimers and disclosures will have the meaning provided in
                  the Terms of Use.
                </p>
                <p>
                  Modern Share does not (i) advise parties on the merits of a
                  particular transaction, (ii) assist in negotiation,
                  transaction, or financial dealings between users or with the
                  issuer of company shares, (iii) determine the fair market
                  value of any security or investment, or (iv) provide legal,
                  tax or advisory services to its users. An offer or
                  solicitation can be made only through the delivery of the
                  final offering and purchase document(s) and will be subject to
                  the terms and conditions and risks delivered in such
                  documents. Investors and shareholders should conduct their own
                  research and analysis on companies of interest and should not
                  rely on Modern Share. All information about companies of
                  interest available on the Platform, including (without
                  limitation) their capitalization table data, has been provided
                  by such companies, and none of such information has been
                  independently verified by Modern Share.{" "}
                </p>
                <p>
                  All investment opportunities are based on non-binding
                  indications of interest from buyers and sellers and are
                  subject to such parties entering into definitive agreements
                  regarding such transactions. Opportunity size and
                  price-per-share figures do not include transaction fees or
                  fees charged by Modern Share.
                </p>
                <p>
                  Investing in private company securities is not suitable for
                  all investors. An investment in private company securities is
                  highly speculative and involves a high degree of risk and
                  should only be considered a long-term investment. You must be
                  prepared to withstand a total loss of your investment. Private
                  company securities are also highly illiquid and may be subject
                  to transfer or other restrictions, and there is no assurance
                  that your investment objectives will be attained or guarantee
                  that a market will develop for such securities. Each
                  investment also carries its own specific risks and you should
                  complete your own independent due diligence regarding the
                  investment, including obtaining additional information about
                  the company, opinions, financial projections and legal or
                  other investment advice. Accordingly, investing in private
                  company securities is appropriate only for those investors who
                  can tolerate a high degree of risk and do not require a liquid
                  investment.{" "}
                </p>
                <p>
                  Under no circumstances should any person make trading
                  decisions based solely on the information provided by Modern
                  Share and/or on the Platform. We are not a qualified financial
                  advisor, and you should not construe any information discussed
                  herein or available on the Platform to constitute investment
                  advice. It is strictly informational in nature. You are solely
                  responsible for making your own investment decisions and any
                  consequences relating to such decisions.
                </p>
                <p>
                  Modern Share makes no representation or warranty or guarantee
                  (i) as to the completeness, accuracy, timeliness or
                  suitability of any information made available on the Platform
                  or contained within any communication from Modern Share or
                  (ii) that such information is free from error. Past
                  performance is not indicative of future results. Modern Share
                  does not accept any liability (whether in contract, tort or
                  otherwise whatsoever and whether or not Modern Share has been
                  negligent) for any loss or damage (including, without
                  limitation, loss of profit), which may arise directly or
                  indirectly from the use of or reliance on such information.
                  While the information provided has been obtained from sources
                  believed to be reliable, Modern Share does not attest to its
                  accuracy or completeness. Modern Share reserves the right to
                  change any source without restriction or notice.
                </p>
                <p>
                  Modern Share is not responsible for any errors, omissions or
                  representations on any of its website or on any links to other
                  web pages contained on such pages. The site contains material
                  submitted by third parties. These third parties are solely
                  responsible for ensuring that the materials submitted comply
                  with all legal requirements. Modern Share makes no warranty
                  that the contents of the site are free from infection by
                  viruses or any other contaminating or destructive properties
                  and shall have no liability in respect thereof.
                </p>
                <p>
                  Modern Share offers standard forms of agreement that may be
                  digitally signed by the buyer, seller and/or issuer as part of
                  the transaction process. Forms of agreement are made available
                  on an ‘as-is’ basis. Modern Share is not acting as legal
                  counsel to any party and use of any form of agreement, whether
                  made available on the Platform or otherwise, does not
                  constitute the provision of legal advice by Modern Share to
                  any person. Users are solely responsible for their use of
                  Modern Share forms of agreement and should read these
                  important disclaimers before initiating the transaction
                  process. Modern Share strongly recommends users consult their
                  legal, tax and/or financial advisors prior to entering into
                  any agreement.
                </p>
                <p>
                  Transactions initiated on Modern Share may require the buyer,
                  seller and/or issuer to enter into additional agreements,
                  including (if and as applicable and without limitation)
                  commission agreements with Modern Share or its affiliates, an
                  escrow agreement with a third-party provider for escrow of the
                  buyer’s funds and evidence of the seller’s ownership of
                  securities and/or a payment processing agreement with a
                  third-party provider of payment processing services. Most
                  issuers of securities require the buyer and seller to enter
                  into a stock transfer agreement with it before the issuer
                  agrees to process a change in ownership of its securities on
                  its books and records.
                </p>
                <p>
                  Each buyer, seller and issuer in a Modern Share facilitated
                  transaction is solely responsible for making his, her or its
                  own legal determination about the availability of an exemption
                  from applicable securities laws. Only accredited investors may
                  purchase securities on Modern Share.
                </p>
                <p>
                  Modern Share is not registered as an investment adviser with
                  the U.S. Securities and Exchange Commission (the “SEC”), any
                  state regulator or any other regulatory body. Nothing
                  contained on the Platform may be construed as investment
                  advice and use of the Platform constitutes explicit agreement
                  that any use of the Platform is qualified by your
                  understanding and acceptance of the foregoing disclaimer.
                  Information about companies presented on Modern Share is
                  provided by third-party sources, including user-submitted
                  comments and documents. Modern Share makes no effort to verify
                  the accuracy of any information and does not warrant the
                  truthfulness or completeness of any company information
                  viewable on the site. Modern Share expresses no opinion as to
                  the suitability of any transaction for any person
                  contemplating a transaction facilitated by Modern Share. Any
                  person contemplating such a transaction should make his, her
                  or its own independent investigation of the suitability of any
                  proposed transaction based on the facts and circumstances of
                  such person’s financial situation, and Modern Share strongly
                  recommends consultation with legal or financial advisors prior
                  to initiating a transaction on Modern Share. Modern Share
                  neither holds nor gives any opinion about the value of any
                  company or that company’s securities.
                </p>

                <h3>USA PATRIOT Act</h3>
                <p>
                  In order to help the U.S. government fight the funding of
                  terrorism and money laundering activities, U.S. federal law
                  requires all financial institutions to obtain, verify, and
                  record information that identifies each person or entity with
                  whom Modern Share conducts securities transactions. In
                  addition to asking you for your name and address and other
                  identifying information, we may also request other identifying
                  information and documentation.
                </p>

                <h3>General Disclaimer/Disclosures</h3>
                <p>
                  Use of the Platform, whether on Modern Share’s website or
                  through its application, is governed by the Modern Share Terms
                  of Use and Privacy Policy, as well as various notices, rules,
                  policies, and procedures that may be published on Modern
                  Share’s website from time to time. Your use of the Platform
                  serves as your acknowledgement and acceptance of these terms,
                  conditions, and disclaimers.
                </p>
                <p>
                  No contract can be entered into on this site and no
                  transaction is processed or cleared by Modern Share, Inc. Any
                  securities transactions, if and when approved, will be handled
                  through a licensed broker-dealer affiliated with M Share
                  Capital LLC.
                </p>
                <p>
                  Content on the Platform is provided for informational purposes
                  only, and no guarantee is made as to the completeness,
                  reliability or accuracy of the information. The Platform is
                  open only to qualified, approved participants, and is intended
                  for sophisticated private equity shareholders, for owners of
                  other private equity securities and interests, and for persons
                  who are “accredited investors,” all of whom must pass Modern
                  Share’s vetting process and meet any other qualifications
                  required by U.S. securities laws and regulations, and any
                  other applicable laws and regulations.
                </p>
                <p>
                  Pricing data on this site does not necessarily reflect actual
                  current market prices or the value you would receive upon sale
                  of such assets. The information displayed does not represent a
                  commitment by Modern Share to transact at those prices, or at
                  any price, in the future. Your assets, when sold, may be worth
                  more or less than the original cost to you. Certain assets may
                  be illiquid and unavailable for sale at any price.
                </p>
                <p>
                  {/* TODO: Review that this text is still correct before launch (as soon as FINRA approves B/D license) */}
                  Securities are offered by M Share Capital LLC, an SEC
                  registered broker-dealer and member FINRA/SIPC. Modern Share
                  Global Advisors LLC, an investment adviser registered with the
                  SEC, serves as the investment adviser to the Funds. Custodial
                  services are offered through Modern Share Trust Co., a South
                  Dakota chartered trust company. Modern Share Securities LLC,
                  Modern Share Global Advisors LLC, and Modern Share Trust Co.
                  are wholly owned subsidiaries of Modern Share Global, Inc.
                  Modern Share Global, Inc. and Modern Share Trust Co. are not
                  registered with the SEC, FINRA, or any state securities
                  authority and do not engage in the offer, sale or trading of
                  alternative investments or securities. EQUIAM LLC is an
                  investment management firm affiliated with Modern Share
                  Global, Inc.
                </p>

                <h3>Public Disclosure Program</h3>
                <p>
                  M Share Capital LLC is required to provide to you information
                  regarding the Public Disclosure Program for investors.
                </p>
                <p>
                  Created by FINRA in 1988, the Public Disclosure Program allows
                  you, the investor, to learn about the professional background,
                  business practices, and conduct of FINRA member firms and
                  their brokers. To request disclosable information under this
                  program, visit the FINRA Regulation Web site at www.finra.org
                  or call (800) 289-9999, a toll-free hotline operated by FINRA.
                </p>
                <p>
                  In addition, please note that there is a Public Disclosure
                  Program Brochure available to you as well. This brochure helps
                  you answer questions pertaining to the Public Disclosure
                  Program, and is available on FINRA’s Web site.
                </p>

                <h3>Securities Investor Protection Corporation (SIPC)</h3>

                <p>
                  Modern Share Securities LLC is required to provide to you
                  information regarding the Securities Investor Protection
                  Corporation (SIPC).
                </p>
                <p>
                  Created by Congress in 1970, the SIPC is an important part of
                  the overall system of investor protection in the United
                  States. The SIPC’s focus is very narrow: restoring funds to
                  investors with assets in the hands of bankrupt and otherwise
                  financially sound troubled brokerage firms.
                </p>
                <p>
                  You can obtain information about SIPC, including obtaining the
                  SIPC brochure, by contacting SIPC by phone, email or regular
                  mail as follows:
                </p>

                <address>
                  <strong>Securities Investor Protection Corporation</strong>{" "}
                  <br />
                  805 15th Street, N.W. Suite 800 Washington, D.C. 20005-2215{" "}
                  <br />
                  Telephone: (202) 371-8300 <br />
                  Fax: (202) 371-6728 <br />
                  Email: <a href="mailto:asksipc@sipc.org">asksipc@sipc.org</a>
                </address>

                <p>
                  In addition, you may also visit the SIPC Web site at{" "}
                  <a href="http://www.sipc.org">www.sipc.org</a> to obtain
                  information.
                </p>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
}

export default DisclaimersDisclosures;
