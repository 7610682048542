import SigningStatusSection from "./secondaries-signing-status";

export default function WaitingForSignatures({
  documentPreviewSection,
  transaction,
  isWaiting,
}) {
  let title;

  if (isWaiting) {
    title = (
      <>
        <h4 className="card-title mt-3">Waiting for signatures</h4>
        <p className="mt-1 small">
          Waiting for other parties to finish signing
        </p>
      </>
    );
  } else {
    title = (
      <>
        <h4 className="card-title">Requires your signature</h4>
        <p className="mt-1 small">
          Waiting for your signature to finish the transaction
        </p>
      </>
    );
  }

  return (
    <>
      <div>{title}</div>
      <div className="card-body">
        <SigningStatusSection transaction={transaction} />
        <h4 className="mt-5">Document</h4>
        {documentPreviewSection}
      </div>
    </>
  );
}
