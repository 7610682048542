import Header4 from "jsx/layout/header4";
import React from "react";

function StepNumber({ number }) {
  return (
    <div
      style={{
        left: -5,
        top: -5,
        position: "absolute",
        color: "var(--secondary)",
        border: "2px solid #eae9f9",
        background: "var(--purple-light)",
        width: 25,
        height: 25,
        borderRadius: 3,
        textAlign: "center",
      }}
    >
      {number}
    </div>
  );
}

export default function SecondaryExplainerSeller() {
  return (
    <>
      <Header4 />
      <div className="content-body section-padding">
        <div className="container">
          <div className="row justify-content-center align-items-center">
            <div className="col-10">
              <div className="card outlined">
                <div className="card-header">
                  <h2 className="card-title">The secondary process</h2>
                </div>

                <div className="card-body">
                  <div className="mt-4 mb-4">
                    <h4 className="mt-4">Overview</h4>
                    <p className="mb-3">
                      Rescale has partnered with Modern Share to give employees
                      the opportunity for liquidity on their shares through a
                      secondary round.
                    </p>
                    <p className="mb-3">
                      Existing investors (buyers) will be invited to bid on a
                      pool of Rescale shares. A{" "}
                      <a
                        style={{ textDecoration: "underline" }}
                        href="https://www.investopedia.com/terms/d/dutchauction.asp"
                      >
                        Dutch auction
                      </a>{" "}
                      will be used to determine the base price which will be
                      communicated to the employees (sellers) participating in
                      the round. You will have 7 days to decide whether to
                      participate in the round and how many shares to sell.
                    </p>
                    <p>
                      If you do decide to sell any of your shares, the
                      transaction will then be pending acceptance by the buyer,
                      and transaction documents will be provided to be signed.
                      After signing and the transfer of the shares is completed,
                      you will receive funds via wire transfer.
                    </p>
                  </div>
                  <h4 className="mb-4 mt-5">Timeline</h4>
                  <div style={{ position: "relative" }}>
                    <div
                      style={{
                        width: 2,
                        position: "absolute",
                        background: "var(--purple-light)",
                        marginLeft: 48,
                        bottom: 0,
                        top: 0,
                      }}
                    ></div>
                    <div className="card outlined-small py-4">
                      <StepNumber number={1} />
                      <div style={{ flexDirection: "row", display: "flex" }}>
                        <div>
                          <h5 className="mb-1">
                            Seller invitation and election
                          </h5>
                        </div>
                        <div style={{ flexGrow: 1 }}>
                          <p className="mb-0 small ms-2">10 business days</p>
                        </div>
                      </div>
                      <p className="mt-2">
                        Sellers elect to sell a portion of their vested holdings
                      </p>
                    </div>
                    <div className="card outlined-small py-4">
                      <StepNumber number={2} />
                      <div style={{ flexDirection: "row", display: "flex" }}>
                        <div>
                          <h5 className="mb-1">
                            Order matching and document drafting
                          </h5>
                        </div>
                        <div style={{ flexGrow: 1 }}>
                          <p className="mb-0 small ms-2">5 business days</p>
                        </div>
                      </div>
                      <p className="mt-2">
                        Orders between buyers and sellers are matched and
                        transfer agreements are drafted
                      </p>
                    </div>
                    <div className="card outlined-small py-4">
                      <StepNumber number={3} />
                      <div style={{ flexDirection: "row", display: "flex" }}>
                        <div>
                          <h5 className="mb-1">Document signing</h5>
                        </div>
                        <div style={{ flexGrow: 1 }}>
                          <p className="mb-0 small ms-2">5 business days</p>
                        </div>
                      </div>
                      <p className="mt-2">
                        Transfer documents are sent to all relevant parties for
                        signing
                      </p>
                    </div>
                    <div className="card outlined-small py-4">
                      <StepNumber number={4} />
                      <div style={{ flexDirection: "row", display: "flex" }}>
                        <div>
                          <h5 className="mb-1">Wiring and closing</h5>
                        </div>
                        <div style={{ flexGrow: 1 }}>
                          <p className="mb-0 small ms-2">2 business days</p>
                        </div>
                      </div>
                      <p className="mt-2">
                        Wires transfers are processed, signatures are released
                        and final cap tables updates are made
                      </p>
                    </div>
                  </div>

                  <h4 className="mt-5">FAQ</h4>
                  <div className="mb-4">
                    <b>What do I have to decide?</b>
                    <p>
                      You will decide whether to sell any of your shares.
                      Participation in the secondary round is completely
                      optional but do note that if you decline, there is no
                      guarantee you will receive the opportunity to sell your
                      shares in the future.
                    </p>
                  </div>
                  <div className="mb-4">
                    <b>How much can I sell?</b>
                    <p>
                      You will be allowed to sell up to 10% of your vested
                      holdings, which includes shares held and unexercised
                      vested options. Selling unexercised options will
                      immediately exercise the options. The exercise costs are
                      deducted from the transaction sale amount and sent to the
                      company by the buyer, which is often called
                      “sell-to-cover”.
                    </p>
                  </div>
                  <div className="mb-4">
                    <b>How can I see how many shares I currently have?</b>
                    <p>
                      We’ll be onboarding sellers to Modern Share’s mobile app
                      where you can see your total shares outstanding and the
                      amount that is sellable.
                    </p>
                  </div>
                  <div className="mb-4">
                    <b>How long do I have to decide?</b>
                    <p>
                      You’ll have 10 business days after the price is determined
                      to decide whether to participate and how many shares to
                      sell.
                    </p>
                  </div>
                  <div className="mb-4">
                    <b>How is the price determined?</b>
                    <p>
                      The price is determined with an auction system on the part
                      of the buyers. You will be presented with a price and you
                      can choose whether to accept, decline, or negotiate it.
                    </p>
                  </div>
                  <div>
                    <b>What about taxes?</b>
                    <p>
                      In some cases, the company may withhold a portion of the
                      sale for tax purposes. Please note that additional taxes
                      may still apply, so it is highly recommended sellers
                      consult with tax, legal, and financial planning
                      professionals. Some transactions may be considered regular
                      income for tax purposes, and others as capital gains.{" "}
                      <a
                        style={{ textDecoration: "underline" }}
                        href="https://carta.com/blog/equity-101-exercising-and-taxes/"
                      >
                        This article by Carta
                      </a>{" "}
                      provides an overview of tax considerations.
                    </p>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
}
