import { Form, Formik } from "formik";
import SettingsNav from "jsx/element/fundraise-create-nav";
import PageTitle from "jsx/element/page-title";
import Header4 from "jsx/layout/header4";
import React, { useState } from "react";
import { Button, Modal } from "react-bootstrap";

function TeamMembers() {
  const [list, addToList] = React.useState([]);
  const [isDialogVisible, showDialog] = React.useState(false);

  const onAdd = ({ data }) => {
    const processedData = {
      id: list.length + 1,
      ...data,
    };
    addToList((prev) => [...prev, processedData]);
  };

  const onEdit = (id, { name, email, option1, option2, option3, option4 }) => {
    console.log(id, name, email, option1, option2, option3, option4);
    addToList((prev) =>
      prev.map((item) => {
        if (item.id === id) {
          item.name = name;
          item.email = email;
          item.option1 = option1;
          item.option2 = option2;
          item.option3 = option3;
          item.option4 = option4;
        }
        return item;
      })
    );
  };

  const onRemove = (id) => {
    addToList((prev) => prev.filter((item) => item.id !== id));
  };

  return (
    <>
      <Header4 />
      <PageTitle />
      <Formik
        initialValues={{}}
        onSubmit={async (values) => {
          const processedData = {
            ...values,
            list,
          };
          await new Promise((resolve) => setTimeout(resolve, 500));
          alert(JSON.stringify(processedData, null, 2));
        }}
      >
        <Form>
          <div className="content-body">
            <div className="container-fluid">
              <div className="row">
                <div className="col-md-2">
                  <SettingsNav />
                </div>
                <div className="col-xl-9 col-md-8">
                  <div className="row">
                    <div className="col-xl-6">
                      <div className="card">
                        <div className="card-header">
                          <h4 className="card-title">Team Members</h4>
                        </div>
                        <div className="card-body">
                          <div className="row">
                            <div className="mb-4 col-xl-12">
                              <h5 className="mb-2 mt-3">
                                Invite co-founders to your RUV dashboard
                              </h5>
                              {list.map((item) => {
                                return (
                                  <>
                                    <Item
                                      key={item.id}
                                      id={item.id}
                                      name={item.name}
                                      email={item.email}
                                      option1={item.option1}
                                      option2={item.option2}
                                      option3={item.option3}
                                      option4={item.option4}
                                      onRemove={onRemove}
                                      returnFunction={onEdit}
                                    />
                                  </>
                                );
                              })}
                              <Dialog
                                mode="add"
                                state={isDialogVisible}
                                setState={showDialog}
                                onChange={onAdd}
                              />
                              <div
                                onClick={() => showDialog(!isDialogVisible)}
                                className="btn mt-3"
                              >
                                Add more
                              </div>
                            </div>
                          </div>
                        </div>
                        <div className="row">
                          <div className="col"></div>
                          <div className="col d-flex flex-row justify-content-end">
                            <button type="submit" className="btn submit-button">
                              Save
                            </button>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </Form>
      </Formik>
    </>
  );
}

function Item({
  id,
  name,
  email,
  option1,
  option2,
  option3,
  option4,
  onRemove,
  returnFunction,
}) {
  const [isDialogVisible, showDialog] = React.useState(false);
  const handleEdit = () => {
    showDialog(true);
  };
  const handleRemove = () => {
    onRemove(id);
  };
  return (
    <div className="row">
      <div className="col d-flex flex-row align-items-center m-3">
        <EditDialog
          id={id}
          state={isDialogVisible}
          setState={showDialog}
          defName={name}
          defEmail={email}
          defOption1={option1}
          defOption2={option2}
          defOption3={option3}
          defOption4={option4}
          returnFunction={returnFunction}
        />
        <div>
          <div className="rounded-image-avatar d-flex flex-row justify-content-center align-items-center">
            {name[0]}
          </div>
        </div>
        <div className="d-flex flex-column">
          <div>
            <p className="mb-0 user-card-title">{name}</p>
            <span className="badge custom-badge-pending">Pending</span>
          </div>
          <small>Will have permission to:</small>
          <ul>
            {option1 && <li>view all areas of your lead account</li>}
            {option2 && <li>add team members and assign roles</li>}
            {option3 && <li>submit and run deals, message LPs</li>}
            {option4 && (
              <li>receive emails regarding your syndicate and deals</li>
            )}
          </ul>
        </div>
      </div>
      <div className="col-3 d-flex flex-row justify-content-end align-items-center">
        <div onClick={handleEdit} className="transparent-button m-1">
          Edit
        </div>
        <div onClick={handleRemove} className="transparent-button m-1">
          Remove
        </div>
      </div>
    </div>
  );
}

function Dialog({ state, setState, onChange }) {
  const handleClose = () => setState(false);
  const resetValues = () => {
    setName("");
    setEmail("");
    setOption1(true);
    setOption2(false);
    setOption3(false);
    setOption4(false);
  };
  const handleFinish = () => {
    const data = {
      name: name,
      email: email,
      option1: option1,
      option2: option2,
      option3: option3,
      option4: option4,
    };
    onChange({ data });
    resetValues();
    setState(false);
  };

  const [name, setName] = useState("");
  const [email, setEmail] = useState("");
  const [option1, setOption1] = useState(true);
  const [option2, setOption2] = useState(false);
  const [option3, setOption3] = useState(false);
  const [option4, setOption4] = useState(false);

  return (
    <Modal show={state} onHide={handleClose} animation={true}>
      <Modal.Header>
        <Modal.Title>Add Team Member</Modal.Title>
      </Modal.Header>
      <Modal.Body>
        <div className="mb-4 col-xl-12">
          <p>Full Name</p>
          <input
            id="name"
            onChange={(e) => setName(e.target.value)}
            className="form-control"
            type="text"
          />
        </div>
        <div className="mb-4 col-xl-12">
          <p>Email address</p>
          <input
            id="email"
            onChange={(e) => setEmail(e.target.value)}
            className="form-control"
            type="email"
          />
        </div>
        <div className="mb-4 col-xl-12">
          <p className="mb-0">Access permissions</p>
          <small>The user can:</small>

          <div className="form-check">
            <input
              className="form-check-input"
              type="checkbox"
              value=""
              id="option1"
              defaultChecked={option1}
              disabled
            />
            <label className="form-check-label" htmlFor="option1">
              view all areas of your lead account
            </label>
          </div>
          <div className="form-check">
            <input
              className="form-check-input"
              type="checkbox"
              value=""
              id="option2"
              defaultChecked={option2}
              onChange={() => setOption2(!option2)}
            />
            <label className="form-check-label" htmlFor="option2">
              add team members and assign roles
            </label>
          </div>
          <div className="form-check">
            <input
              className="form-check-input"
              type="checkbox"
              value=""
              id="option3"
              defaultChecked={option3}
              onChange={() => setOption3(!option3)}
            />
            <label className="form-check-label" htmlFor="option3">
              submit and run deals, message LPs
            </label>
          </div>
          <div className="form-check">
            <input
              className="form-check-input"
              type="checkbox"
              value=""
              id="option4"
              defaultChecked={option4}
              onChange={() => setOption4(!option4)}
            />
            <label className="form-check-label" htmlFor="option4">
              receive emails regarding your syndicate and deals
            </label>
          </div>
        </div>
      </Modal.Body>
      <Modal.Footer>
        <Button
          variant="primary"
          className="transparent-button"
          onClick={handleClose}
        >
          Cancel
        </Button>
        <Button
          variant="secondary"
          className="custom-button"
          onClick={handleFinish}
        >
          Add team member
        </Button>
      </Modal.Footer>
    </Modal>
  );
}

const EditDialog = ({
  id,
  state,
  setState,
  defName,
  defEmail,
  defOption1,
  defOption2,
  defOption3,
  defOption4,
  returnFunction,
}) => {
  const handleClose = () => {
    setState(false);
  };
  const handleFinish = () => {
    returnFunction(id, { name, email, option1, option2, option3, option4 });
    setState(false);
  };
  const [name, setName] = useState(defName);
  const [email, setEmail] = useState(defEmail);
  const option1 = defOption1;
  // const [option1, setOption1] = useState(defOption1);
  const [option2, setOption2] = useState(defOption2);
  const [option3, setOption3] = useState(defOption3);
  const [option4, setOption4] = useState(defOption4);

  return (
    <Modal show={state} animation={true}>
      <Modal.Header>
        <Modal.Title>Edit member</Modal.Title>
      </Modal.Header>
      <Modal.Body>
        <div className="mb-4 col-xl-12">
          <p>Full Name</p>
          <input
            defaultValue={name}
            onChange={(e) => setName(e.target.value)}
            className="form-control"
            type="text"
          />
        </div>
        <div className="mb-4 col-xl-12">
          <p>Email address</p>
          <input
            defaultValue={email}
            onChange={(e) => setEmail(e.target.value)}
            className="form-control"
            type="email"
          />
        </div>
        <div className="mb-4 col-xl-12">
          <p className="mb-0">Access permissions</p>
          <small>The user can:</small>

          <div className="form-check">
            <input
              className="form-check-input"
              type="checkbox"
              value=""
              id="option1"
              defaultChecked={option1}
              disabled
            />
            <label className="form-check-label" htmlFor="option1">
              view all areas of your lead account
            </label>
          </div>
          <div className="form-check">
            <input
              className="form-check-input"
              type="checkbox"
              value=""
              id="option2"
              defaultChecked={option2}
              onChange={() => setOption2(!option2)}
            />
            <label className="form-check-label" htmlFor="option2">
              add team members and assign roles
            </label>
          </div>
          <div className="form-check">
            <input
              className="form-check-input"
              type="checkbox"
              value=""
              id="option3"
              defaultChecked={option3}
              onChange={() => setOption3(!option3)}
            />
            <label className="form-check-label" htmlFor="option3">
              submit and run deals, message LPs
            </label>
          </div>
          <div className="form-check">
            <input
              className="form-check-input"
              type="checkbox"
              value=""
              id="option4"
              defaultChecked={option4}
              onChange={() => setOption4(!option4)}
            />
            <label className="form-check-label" htmlFor="option4">
              receive emails regarding your syndicate and deals
            </label>
          </div>
        </div>
      </Modal.Body>
      <Modal.Footer>
        <Button
          variant="primary"
          className="transparent-button"
          onClick={handleClose}
        >
          Cancel
        </Button>
        <Button
          variant="secondary"
          className="custom-button"
          onClick={handleFinish}
        >
          Modify member
        </Button>
      </Modal.Footer>
    </Modal>
  );
};

export default TeamMembers;
