import { Formik } from "formik";
import React from "react";
import { useHistory, useParams } from "react-router-dom";
import * as Yup from "yup";

import CommonSharesFormContext from "./common-shares-form-context";
import routes from "./routes.json";
function EditShares({ data, onEdit }) {
  const history = useHistory();
  const { rootId, id } = useParams();
  const defRoot = data.filter((rootElement) => rootElement.id === rootId)[0];
  const defValues = defRoot.shares.filter((sharesId) => sharesId.id === id)[0];
  const AddSharesSchema = Yup.object().shape({
    email: Yup.string().email("Invalid email").required("Required"),
    // stackHolderType: Yup.string().required('Required'),
    firstName: Yup.string().when("stackHolderType", {
      is: "individual",
      then: Yup.string()
        .min(2, "Too Short!")
        .max(50, "Too Long!")
        .required("Required"),
    }),
    lastName: Yup.string().when("stackHolderType", {
      is: "individual",
      then: Yup.string()
        .min(2, "Too Short!")
        .max(50, "Too Long!")
        .required("Required"),
    }),
    organizationName: Yup.string().when("stackHolderType", {
      is: "organization",
      then: Yup.string().required("Required"),
    }),
    securityHolderRelationship: Yup.string().required("Required"),
    relationShipStartDate: Yup.string().required("Required"),
    boardApprovalDate: Yup.string().required("Required"),
    issueDate: Yup.string().required("Required"),
    numberOfShares: Yup.string().required("Required"),
    currency: Yup.string().required("Required"),
    legend: Yup.string().required("Required"),
  });
  return (
    <Formik
      initialValues={{
        email: defValues.email,
        stackHolderType: defValues.stackHolderType,
        firstName: defValues.firstName,
        lastName: defValues.lastName,
        organizationName: defValues.organizationName,
        securityHolderRelationship: defValues.securityHolderRelationship,
        relationShipStartDate: defValues.relationShipStartDate,
        boardApprovalDate: defValues.boardApprovalDate,
        issueDate: defValues.issueDate,
        numberOfShares: defValues.numberOfShares,
        currency: defValues.currency,
        paymentForShares: defValues.paymentForShares,
        vestingSchedule: defValues.vestingSchedule,
        vestingStartDate: defValues.vestingStartDate,
        accelerationTerms: defValues.accelerationTerms,
        legend: defValues.legend,
        documents: {},
      }}
      validationSchema={AddSharesSchema}
      onSubmit={(values) => {
        onEdit(id, rootId, values);
        history.push(routes.view);
      }}
    >
      {({
        values,
        handleChange,
        handleBlur,
        handleSubmit,
        errors,
        touched,
      }) => (
        <>
          <div className="card-header d-flex flex-column align-items-start mb-2">
            <h4 className="card-title">Add Shares</h4>
            <code>{id}</code>
          </div>
          <small>
            The information you provide below should reflect how shares exist
            today. Once you sign and activate your account, Carta will add the
            share class to your cap table and issue certificates to your
            shareholders in electronic form.
          </small>
          <CommonSharesFormContext
            config={{
              values,
              handleChange,
              handleBlur,
              handleSubmit,
              errors,
              touched,
            }}
          >
            <button type="submit">Save changes</button>
          </CommonSharesFormContext>
        </>
      )}
    </Formik>
  );
}
export default EditShares;
