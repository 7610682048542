import Sidebar from "jsx/layout/captable-sidebar";
import Footer1 from "jsx/layout/footer1";
import Header2 from "jsx/layout/header2";
import React from "react";
import { Route, Switch, useRouteMatch } from "react-router-dom";

//Views
import AccountAdministrators from "./account-administrators/captable-account-administrators";
import CommonShares from "./common-shares/common-shares-main";
import CompanyDetails from "./company-details/captable-company-details";
import ConvertibleNotes from "./convertible-notes/convertible-notes-main";
import IncorporationDocuments from "./incorporation-documents/captable-incorporation-documents";
import ShareholderExperience from "./shareholder-experience/captable-shareholder-experience";
import Signatories from "./signatories/captable-signatories";

function OnboardCompanyDetails() {
  const { path } = useRouteMatch();
  const handleReturn = (id, data) => {
    console.log("called", data);
  };
  return (
    <>
      <Header2 />
      <Sidebar />
      <div className="section-padding">
        <div className="container">
          <div className="card">
            <div className="card-body">
              Do work here
              <br />
              <br />
              "At vero eos et accusamus et iusto odio dignissimos ducimus qui
              blanditiis praesentium voluptatum deleniti atque corrupti quos
              dolores et quas molestias excepturi sint occaecati cupiditate non
              provident, similique sunt in culpa qui officia deserunt mollitia
              animi, id est laborum et dolorum fuga. Et harum quidem rerum
              facilis est et expedita distinctio. Nam libero tempore, cum soluta
              nobis est eligendi optio cumque nihil impedit quo minus id quod
              maxime placeat facere possimus, omnis voluptas assumenda est,
              omnis dolor repellendus. Temporibus autem quibusdam et aut
              officiis debitis aut rerum necessitatibus saepe eveniet ut et
              voluptates repudiandae sint et molestiae non recusandae. Itaque
              earum rerum hic tenetur a sapiente delectus, ut aut reiciendis
              voluptatibus maiores alias consequatur aut perferendis doloribus
              asperiores repellat."
              <Switch>
                <Route exact path={`${path}/company-details`}>
                  <CompanyDetails onReturn={handleReturn} />
                </Route>
                <Route exact path={`${path}/incorporation-documents`}>
                  <IncorporationDocuments onReturn={handleReturn} />
                </Route>
                <Route exact path={`${path}/account-administrators`}>
                  <AccountAdministrators onReturn={handleReturn} />
                </Route>
                <Route exact path={`${path}/signatories`}>
                  <Signatories onReturn={handleReturn} />
                </Route>
                <Route exact path={`${path}/shareholder-experience`}>
                  <ShareholderExperience onReturn={handleReturn} />
                </Route>
                <Route path={`${path}/common-shares`}>
                  <CommonShares onReturn={handleReturn} />
                </Route>
                <Route path={`${path}/convertible-notes`}>
                  <ConvertibleNotes onReturn={handleReturn} />
                </Route>
              </Switch>
            </div>
          </div>
        </div>
      </div>
      <Footer1 />
    </>
  );
}

export default OnboardCompanyDetails;
