import { solid } from "@fortawesome/fontawesome-svg-core/import.macro";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { doc, getFirestore, onSnapshot } from "firebase/firestore";
import { UserContext } from "jsx/contexts/user-context";
import Loader from "jsx/element/loader";
import Header4 from "jsx/layout/header4";
import React, { useContext, useEffect, useState } from "react";
import { useHistory } from "react-router-dom";

import VerifyIdentity from "../kyc";
import VerifyEmail from "../verify-email";
import InvestorSetupInfo from "./investor-setup-info";

const circleTextStyle = {
  fontSize: 14,
  top: 5,
  width: 33,
  height: 30,
  position: "absolute",
  textAlign: "center",
};

const progressTextStyle = {
  fontSize: 14,
  textAlign: "center",
  marginTop: 0,
  color: "#666",
};

const notInProgressTextStyle = {
  ...progressTextStyle,
  color: "#999",
};

const circleStyleBase = {
  width: 36,
  height: 36,
  borderRadius: 999,
  position: "relative",
  flexShrink: 0,
};

const circleStyleInProgress = {
  ...circleStyleBase,
  border: "2px solid var(--primary)",
  color: "var(--primary)",
};
const circleStyleCompleted = {
  ...circleStyleBase,
  background: "var(--primary)",
  color: "white",
};
const circleStyleNotStarted = {
  ...circleStyleBase,
  border: "2px solid #CCC",
  color: "#CCC",
};

export default function Onboarding({
  showBackButton,
  hideProgressBar,
  shouldShowKyc,
}) {
  const { user, updateCtx } = useContext(UserContext);
  const [admin, setAdmin] = useState();
  const history = useHistory();

  const ctx = useContext(UserContext);

  const db = getFirestore();

  useEffect(() => {
    if (!user.uid) {
      return;
    }

    onSnapshot(doc(db, "users", user.uid, "users_admin", "data"), (doc) => {
      const source = doc.metadata.hasPendingWrites ? "Local" : "Server";
      if (source === "Server") {
        const adminData = doc.data();
        const newCtx = ctx;
        newCtx.adminData = adminData;
        updateCtx(newCtx);
        setAdmin(adminData ?? {});
      }
    });
  }, [user.uid]);

  let content;
  let state;

  if (!admin) {
    return <Loader />;
  }

  if (!user.emailVerified && !user.debugEmailVerified) {
    state = 0;
  } else if (admin.kycStatus !== "success" && shouldShowKyc) {
    state = 1;
  } else if (!admin.accreditationStatus) {
    state = 2;
  } else {
    state = 3;
    history.replace("/org/dashboard");
    // history.replace("/investor/dashboard");
  }

  if (state === 0) {
    content = <VerifyEmail />;
  } else if (state === 1) {
    content = <VerifyIdentity admin={admin} />;
  } else if (state === 2) {
    content = (
      <InvestorSetupInfo
        onboardingFlow={true}
        success={() => {
          history.go(0);
        }}
      />
    );
  }

  let emailCircleStyle;

  if (state === 0) {
    emailCircleStyle = circleStyleInProgress;
  } else {
    emailCircleStyle = circleStyleCompleted;
  }

  let identityCircleStyle;

  if (state === 0) {
    identityCircleStyle = circleStyleNotStarted;
  } else if (state === 1) {
    identityCircleStyle = circleStyleInProgress;
  } else {
    identityCircleStyle = circleStyleCompleted;
  }

  let accCircleStyle;

  if (state < 2) {
    accCircleStyle = circleStyleNotStarted;
  } else if (state === 2) {
    accCircleStyle = circleStyleInProgress;
  } else {
    accCircleStyle = circleStyleCompleted;
  }

  let emailCircleText;

  if (state === 0) {
    emailCircleText = "1";
  } else {
    emailCircleText = (
      <FontAwesomeIcon
        icon={solid("check")}
        style={{ marginTop: 6, marginLeft: 3 }}
      />
    );
  }

  let identityCircleText;

  if (state > 1) {
    identityCircleText = (
      <FontAwesomeIcon
        icon={solid("check")}
        style={{ marginTop: 6, marginLeft: 3 }}
      />
    );
  } else {
    identityCircleText = "2";
  }

  return (
    <>
      <Header4
        hideAllMenuItems
        hideBackButton={!showBackButton}
        title="Setup company"
      />

      <div className="authentication">
        <div className={"container " + (state === 2 ? "" : "section-padding")}>
          <div className="row justify-content-center align-items-center">
            {!hideProgressBar && (
              <div
                className="d-flex align-items-center"
                style={{
                  gap: 12,
                  position: "fixed",
                  top: 0,
                  zIndex: 99999,
                  marginTop: 16,
                  width: 640,
                }}
              >
                <div style={emailCircleStyle}>
                  <p style={circleTextStyle}>{emailCircleText}</p>
                </div>
                <div className="d-none d-sm-block">
                  <p style={progressTextStyle}>Verify email</p>
                </div>
                {shouldShowKyc && (
                  <>
                    <div
                      style={{ height: 1, flexGrow: 1, background: "#e6e6e6" }}
                    ></div>{" "}
                    <div style={identityCircleStyle}>
                      <p style={circleTextStyle}>{identityCircleText}</p>
                    </div>
                    <div className="d-none d-sm-block">
                      <p style={progressTextStyle}>Verify identity</p>
                    </div>
                  </>
                )}
                <div
                  style={{ height: 1, flexGrow: 1, background: "#e6e6e6" }}
                ></div>
                <div style={accCircleStyle}>
                  <p style={circleTextStyle}>{shouldShowKyc ? 3 : 2}</p>
                </div>
                <div className="d-none d-sm-block">
                  <p style={notInProgressTextStyle}>Accreditation</p>
                </div>
              </div>
            )}

            <div
              className={
                state === 2 ? "col-lg-10  col-xs-12" : "col-lg-6 col-xs-12"
              }
            >
              {content}
            </div>
          </div>
        </div>
      </div>
    </>
  );
}
