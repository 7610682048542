import React from "react";
import { Link, useHistory, useRouteMatch } from "react-router-dom";
function WelcomeCard({ show }) {
  const { path } = useRouteMatch();
  const history = useHistory();
  if (!show) {
    history.push(`${path}/view`);
  }
  return (
    <>
      <div className="card-header">
        <h4 className="card-title">Add a convertible note</h4>
      </div>
      {show && (
        <div className="row card-body">
          <div className="col d-flex flex-column justify-content-center">
            <h5 className="mb-2 mt-3">Add an existing convertible note</h5>
            <p>
              This should reflect how your convertible note exists today. Carta
              will add the convertible note to your cap table and issue a note
              document in electronic form.
            </p>
          </div>
          <div className="col d-flex flex-row justify-content-end align-items-center">
            <Link to={`${path}/add`} className="btn">
              Add share class
            </Link>
          </div>
        </div>
      )}
    </>
  );
}
export default WelcomeCard;
