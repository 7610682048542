import Skeleton from "@mui/material/Skeleton";
import { doc, getFirestore, onSnapshot } from "firebase/firestore";
import { useFormikContext } from "formik";
import { post } from "jsx/api";
import MSButton from "jsx/element/button";
import React, { useEffect, useState } from "react";
import { useMediaQuery } from "react-responsive";

const loading = (
  <>
    <Skeleton height={50} />
    <Skeleton height={50} />
  </>
);

function DocumentRow(props) {
  const [isDownloading, setIsDownloading] = useState(false);
  const db = getFirestore();
  const [document, setDocument] = useState({});

  const isMobile = useMediaQuery({ minWidth: 0, maxWidth: 800 });
  const isDesktop = useMediaQuery({ query: "(min-width: 800px)" });

  // Pretty hacky, but if values exists, it means it's embedded inside a formik field
  const { values } = useFormikContext() ?? {};

  useEffect(() => {
    if (
      !props.documentId ||
      !props.documentId ||
      !props.organizationId ||
      !props.investmentId
    ) {
      return;
    }

    fetchDocument();
  }, [props.documentId, props.documentId, props.organizationId]);

  useEffect(() => {
    // Stupid, but long poll
    if (shouldShowProcessing()) {
      setTimeout(() => {
        fetchDocument();
      }, 6000);
    }
  }, [document]);

  async function fetchDocument() {
    if (props.accessToken) {
      const investmentToSign = await post("getDocument", {
        accessToken: props.accessToken,
        organizationId: props.organizationId,
        investmentId: props.investmentId,
      });
      setDocument(investmentToSign.data.document);
    } else {
      onSnapshot(
        doc(
          db,
          "organizations",
          props.organizationId,
          "organizations_admin",
          "data",
          "investments",
          props.investmentId,
          "documents",
          props.documentId
        ),
        (doc) => {
          const source = doc.metadata.hasPendingWrites ? "Local" : "Server";
          if (source === "Server") {
            setDocument(doc.data());
          }
        }
      );
    }
  }

  async function downloadDocument() {
    if (props.isSignaturePreview) {
      previewDocument();
      return;
    }

    // TODO: Intentionally did not use user agent here bc I don't know the behavior on android either
    if (isMobile) {
      viewDocument();
      return;
    }

    const params = {
      documentId: props.documentId,
      investmentId: props.investmentId,
      organizationId: props.organizationId,
    };

    setIsDownloading(true);

    const result = await post("getDocumentFile", params);

    const uri = "data:pdf;base64," + result.data;

    var downloadLink = window.document.createElement("a");
    downloadLink.href = uri;
    downloadLink.download = document.latest;

    window.document.body.appendChild(downloadLink);
    downloadLink.click();
    window.document.body.removeChild(downloadLink);
    setIsDownloading(false);
  }

  async function previewDocument() {
    const params = {
      documentId: props.documentId,
      investmentId: props.investmentId,
      organizationId: props.organizationId,
      ...values,
    };

    setIsDownloading(true);

    const result = await post("previewDocument", params);

    const uri = "data:pdf;base64," + result.data;

    const downloadLink = window.document.createElement("a");
    const fileName = `${document.name}`;

    downloadLink.href = uri;
    downloadLink.download = fileName;
    downloadLink.click();

    setIsDownloading(false);
  }

  async function viewDocument() {
    const params = {
      documentId: props.documentId,
      investmentId: props.investmentId,
      organizationId: props.organizationId,
    };

    setIsDownloading(true);

    const result = await post("getDocumentFile", params);

    try {
      const pdfWindow = window.open("");
      pdfWindow.document.write(
        "<iframe width='100%' height='100%' src='data:application/pdf;base64, " +
          encodeURI(result.data) +
          "'></iframe>"
      );
    } catch {}

    setIsDownloading(false);
  }

  function shouldShowProcessing() {
    if (
      document.status === "investor_signed" &&
      (!document.latest || !document.latest.includes(".pdf"))
    ) {
      return true;
    } else if (
      document.status === "completed" &&
      (!document.latest || !document.latest.includes("complete.pdf"))
    ) {
      return true;
    }

    return false;
  }

  let title = (
    <p className="small mb-0" style={{ maxWidth: "300px" }}>
      {document.latest ?? document.signedName ?? document.name}
    </p>
  );
  let downloadButtons = (
    <>
      <MSButton
        size="small"
        title="View"
        loadingLabel="Processing"
        loading={isDownloading}
        onClick={downloadDocument}
        className="me-2"
      />
      {props.rightButton}
    </>
  );

  // If it requires a PDF, and none has been generated, don't show a title
  if (shouldShowProcessing()) {
    title = (
      <p className="small mb-0" style={{ maxWidth: "300px" }}>
        Finalizing signed documents. We will email you the final document.
      </p>
    );
    downloadButtons = (
      <>
        <MSButton
          className="me-2"
          title="View"
          loadingLabel="Processing"
          loading={true}
          type="light small"
          disabled
        />
        {props.rightButton}
      </>
    );
  }

  // mobile
  let content = (
    <div className="d-flex-column py-2">
      <div className="flex-grow-">
        <h5 className="mb-1">SAFE</h5>
        {title}
      </div>
      <div className="flex-grow-1 mt-3">{downloadButtons}</div>
    </div>
  );

  if (isDesktop) {
    content = (
      <div className="d-flex py-2">
        <div className="flex-grow-1">
          <h5 className="mb-1">SAFE</h5>
          {title}
        </div>
        <div className="edit-option">{downloadButtons}</div>
      </div>
    );
  }

  return (
    <div className="outlined-small">
      {!document.name && loading}
      {document.name && content}
    </div>
  );
}

export default DocumentRow;
