import Tooltip from "@mui/material/Tooltip";
import { doc, getDoc, getFirestore } from "firebase/firestore";
import MSButton from "jsx/element/button";
import moment from "moment";
import React, { useEffect, useState } from "react";
import ReactApexChart from "react-apexcharts";

function timeDateFromMilliseconds(seconds) {
  if (Number.isNaN(seconds)) {
    return 0;
  }

  var date = new Date(0);
  date.setSeconds(seconds / 1000); // specify value for SECONDS here
  return date.toISOString().substr(14, 5);
}

export default function VisitAnalytics(props) {
  const db = getFirestore();

  const { visit, selectedOrganization, roundId, secondaryId } = props;
  const [isExpanded, setIsExpanded] = useState(false);
  const [fullVisit, setFullVisit] = useState();

  let barContainerStyle;

  if (isExpanded) {
    barContainerStyle = {
      height: "350px",
      transition: "height 100ms ease-out",
    };
  } else {
    barContainerStyle = {
      height: "0px",
      transition: "height 100ms ease-out",
    };
  }

  const radial = {
    series: [(visit.cp / visit.tp) * 100],
    options: {
      chart: {
        type: "radialBar",
      },
      plotOptions: {
        radialBar: {
          hollow: {
            size: "5%",
          },
          dataLabels: {
            value: {
              show: false,
            },
          },
        },
      },
      labels: [""],
    },
  };

  useEffect(() => {
    if (isExpanded && !fullVisit) {
      fetchFullVisit();
    }
  }, [isExpanded]);

  async function fetchFullVisit() {
    if (roundId) {
      const result = await getDoc(
        doc(
          db,
          "organizations",
          selectedOrganization,
          "organizations_private",
          "data",
          "rounds",
          roundId,
          "visits",
          visit.i
        )
      );
      setFullVisit(result.data());
    } else if (secondaryId) {
      const result = await getDoc(
        doc(
          db,
          "organizations",
          selectedOrganization,
          "organizations_private",
          "data",
          "secondaries",
          secondaryId,
          "visits",
          visit.i
        )
      );
      setFullVisit(result.data());
    }
  }

  let bar;
  if (fullVisit) {
    bar = {
      series: [
        {
          name: "Time spent",
          data: Object.values(fullVisit.timePerPage).map((e) => e / 1000),
        },
      ],
      options: {
        chart: {
          type: "bar",
          width: "100%",
          height: 250,
          toolbar: {
            show: true,
            tools: {
              download: false,
            },
          },
        },
        plotOptions: {
          bar: {
            horizontal: false,
            columnWidth: "55%",
            endingShape: "rounded",
          },
        },
        dataLabels: {
          enabled: false,
        },
        stroke: {
          show: true,
          width: 2,
          colors: ["transparent"],
        },
        xaxis: {
          categories: Object.keys(fullVisit.timePerPage),
        },
        yaxis: {
          title: {
            text: "",
          },
        },
        fill: {
          opacity: 1,
        },
        tooltip: {
          y: {
            formatter: function (val) {
              return `${val} seconds`;
            },
          },
          x: {
            formatter: function (val) {
              return `Page ${val} / ${fullVisit.totalPages}`;
            },
          },
        },
      },
    };
  }

  return (
    <div>
      <div className="col-12 d-flex py-3 ">
        <div className="col-2 align-self-center" style={{ fontSize: 14 }}>
          {moment(new Date(visit.d)).fromNow()}
        </div>
        {/* <Link to={`${visit.e}`} className='col-7 align-self-center text-link'>
                    {visit.e}
                </Link> */}
        <div className="col-4 align-self-center" style={{ fontSize: 14 }}>
          {visit.fn}
        </div>
        <div className="col-3 align-self-center" style={{ fontSize: 14 }}>
          {visit.e}
        </div>
        <div
          className="col-1 align-self-center"
          style={{ textAlign: "right", fontSize: 14 }}
        >
          <Tooltip
            arrow
            title={"Duration: " + (visit.t / 1000).toFixed(2) + " seconds"}
            placement="top"
          >
            <span>{timeDateFromMilliseconds(visit.t)}</span>
          </Tooltip>
        </div>

        <div className="col-1">
          <ReactApexChart
            style={{ marginTop: "-8px", marginBottom: "-8px" }}
            options={radial.options}
            series={radial.series}
            type="radialBar"
            height={82}
          />

          <Tooltip
            arrow
            title={`Viewed: ${visit.cp} out of ${visit.tp} pages`}
            placement="top"
          >
            <div
              style={{
                zIndex: 99999,
                position: "absolute",
                left: 12,
                top: 0,
                width: 58,
                height: 30,
              }}
            ></div>
          </Tooltip>
        </div>
        <MSButton
          title="Details"
          onClick={() => {
            setIsExpanded(!isExpanded);
          }}
          size="small"
        >
          Details
        </MSButton>

        {/* <div className='col-1' onClick={() => { setIsExpanded(!isExpanded) }}>
                    Button
                </div> */}
      </div>
      <div className={"col-12"} style={barContainerStyle}>
        {isExpanded && fullVisit && (
          <ReactApexChart
            options={bar.options}
            series={bar.series}
            type="bar"
            height={350}
          />
        )}
      </div>
    </div>
  );
}
