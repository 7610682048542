import SettingsNav from "jsx/element/secondaries-nav";
import SecondaryModelingTable from "jsx/element/secondary-modeling-table";
import Header4 from "jsx/layout/header4";
import React, { useEffect, useState } from "react";

import ImportButton from "../imports/button";

function CompanySecondariesStakeholdersManagelderTable() {
  const [refresh, setRefresh] = useState(0);

  useEffect(() => {
    if (!refresh) {
      return;
    }
    setRefresh(false);
  }, [refresh]);

  function onFinish() {
    setRefresh(refresh + 1);
  }

  return (
    <>
      <Header4 />

      <div className="content-body section-padding">
        <div className="container">
          <div className="d-flex">
            <div className="me-4" style={{ minWidth: "220px" }}>
              <SettingsNav />
            </div>
            <div className="col-xl-10">
              <div className="flex-column">
                <div className="card outlined">
                  <div className="card-header">
                    <h4 className="card-title">Stakeholders</h4>
                    <ImportButton onFinish={onFinish} />
                  </div>
                  <div className="card-body">
                    <SecondaryModelingTable
                      pageSize={50}
                      showModeling={false}
                      disableSelectionOnClick={true}
                      disableColumnMenu
                      checkboxSelection={true}
                      refresh={refresh}
                      hiddenFields={[
                        "unvested",
                        "exercisable",
                        "owned",
                        "sellable",
                        "issued",
                        "lastSold",
                        "calcSellable",
                        "proceeds",
                      ]}
                    />
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
}

export default CompanySecondariesStakeholdersManagelderTable;
