import {
  collection,
  getDocs,
  getFirestore,
  onSnapshot,
  query,
  where,
} from "firebase/firestore";
import { enableIndexedDbPersistence } from "firebase/firestore";
import { UserContext } from "jsx/contexts/user-context";
import BuySellElement from "jsx/element/buy-sell-element";
import CompanyDetails from "jsx/element/company-details";
import PageTitle from "jsx/element/page-title";
import Footer2 from "jsx/layout/footer2";
import Header2 from "jsx/layout/header2";
import Sidebar from "jsx/layout/sidebar";
import React, { useEffect, useState } from "react";
import { useParams } from "react-router-dom";

function Company() {
  const [company, setCompany] = useState({});

  const [orders, setOrders] = useState([]);

  const { user } = React.useContext(UserContext);

  const { companyKey, contractId } = useParams();
  const db = getFirestore();
  enableIndexedDbPersistence(db);

  async function fetchOrganizationDetails() {
    if (!companyKey) {
      return;
    }

    const q = query(
      collection(db, "companies"),
      where("key", "==", companyKey)
    );
    const querySnapshot = await getDocs(q);
    if (!querySnapshot.empty) {
      const doc = querySnapshot.docs[0];
      const company = doc.data();
      company.id = doc.id;
      setCompany(company);
      fetchUserOrders();
      listenForChanges();
    }
  }

  async function listenForChanges() {
    onSnapshot(
      collection(
        db,
        "users",
        user.uid,
        "users_private",
        "data",
        "users_orders"
      ),
      (querySnapshot) => {
        processOrders(querySnapshot);
      }
    );
  }

  function processOrders(querySnapshot) {
    var ret = [];
    querySnapshot.forEach((doc) => {
      const data = doc.data();
      ret.push(
        <tr key={doc.id}>
          <td>
            <span
              className={
                "badge badge-" + (data.buySell === "buy" ? "success" : "danger")
              }
            >
              {data.buySell}
            </span>
          </td>

          <td
            className={
              "text-" + (data.buySell === "buy" ? "success" : "danger")
            }
          >
            {data.numberOfShares} shares
          </td>
          <td>${data.price}</td>
          <td>{data.status}</td>
          <td>Cancel</td>
        </tr>
      );
    });

    setOrders(ret);
  }

  async function fetchUserOrders() {
    if (!company.id) {
      return;
    }

    const q = collection(
      db,
      "users",
      user.uid,
      "users_private",
      "data",
      "users_orders"
    );
    const querySnapshot = await getDocs(q);
    processOrders(querySnapshot);
  }

  useEffect(() => {
    fetchOrganizationDetails();
  }, []);

  return (
    <>
      <Header2 />
      <Sidebar />
      <PageTitle />

      <div className="content-body">
        <div className="container">
          <div className="row">
            <CompanyDetails company={company} orders={orders} />
            <div className="col-xl-4 col-lg-12 col-xxl-12">
              <div className="card position-fixed">
                <div className="card-body ">
                  <BuySellElement
                    marketPrice={12.41}
                    contractId={contractId}
                    companyId={company.id}
                  />
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>

      <Footer2 />
    </>
  );
}

export default Company;
