import { Dialog, DialogActions } from "@mui/material";
import { useState } from "react";

import MSButton from "./button";

export default function VoidButton({ onVoid }) {
  const [voidDialogOpen, setVoidDialogOpen] = useState(false);
  const [isVoidingDocument, setIsVoidingDocument] = useState(false);

  async function voidDocument() {
    setIsVoidingDocument(true);
    console.log("voiding");
    await onVoid();
    setIsVoidingDocument(false);
  }

  return (
    <>
      <MSButton
        title={"Void"}
        size="small"
        variant="danger"
        onClick={() => {
          setVoidDialogOpen(true);
        }}
        loading={isVoidingDocument}
      />
      <Dialog
        open={voidDialogOpen}
        onClose={() => {
          setVoidDialogOpen(false);
        }}
        aria-labelledby="alert-dialog-title"
        aria-describedby="alert-dialog-description"
      >
        <div className="p-4">
          <h3>Void this document?</h3>
          <p className="mb-0">
            Voiding the document deletes it from this transaction. It is
            irreversible. A new unsigned document will be generated in its
            place.
          </p>
        </div>

        <DialogActions className="me-3 mb-3">
          <MSButton
            title="Cancel"
            size="small"
            onClick={() => {
              setVoidDialogOpen(false);
            }}
          >
            Disagree
          </MSButton>
          <MSButton
            title="Void"
            size="small"
            onClick={() => {
              setVoidDialogOpen(false);
              voidDocument();
            }}
            autoFocus
          >
            Agree
          </MSButton>
        </DialogActions>
      </Dialog>
    </>
  );
}
