import "react-dates/initialize";
import "react-dates/lib/css/_datepicker.css";

import Skeleton from "@mui/material/Skeleton";
import {
  collection,
  doc,
  getDoc,
  getDocs,
  getFirestore,
} from "firebase/firestore";
import { Field, Form, Formik } from "formik";
import { post } from "jsx/api";
import { UserContext } from "jsx/contexts/user-context";
import states from "jsx/data/states.json";
import MSButton from "jsx/element/button";
import DocumentRow from "jsx/element/document-row";
import PageTitle from "jsx/element/page-title";
import Header4 from "jsx/layout/header4";
import React, { useEffect, useRef, useState } from "react";
import { useParams } from "react-router-dom";
import * as Yup from "yup";
const queryString = require("query-string");

const InvestorSignSchema = Yup.object().shape({
  investorName: Yup.string().required("Enter your name"),
  investorType: Yup.string().required("Required"),
  signatureName: Yup.string().required("Required"),

  investorStreetAddress: Yup.string().when("foreignInvestor", {
    is: false,
    then: Yup.string().required("Required"),
  }),

  investorCity: Yup.string().when("foreignInvestor", {
    is: false,
    then: Yup.string().required("Required"),
  }),
  investorState: Yup.string().when("foreignInvestor", {
    is: false,
    then: Yup.string().required("Required"),
  }),
  investorZipcode: Yup.string().when("foreignInvestor", {
    is: false,
    then: Yup.string().required("Required"),
  }),
  investorEntity: Yup.string().when("investorType", {
    is: "entity",
    then: Yup.string().required("Required"),
  }),

  investorTitle: Yup.string().when("investorType", {
    is: "entity",
    then: Yup.string().required("Required"),
  }),

  investorAddressLine1: Yup.string().when("foreignInvestor", {
    is: true,
    then: Yup.string().required("Required"),
  }),
  investorAddressLine2: Yup.string().when("foreignInvestor", {
    is: true,
    then: Yup.string().required("Required"),
  }),
});

const loading = (
  <>
    <Skeleton height={80} />
    <Skeleton height={50} />
    <Skeleton height={50} />
    <Skeleton height={130} />
    <Skeleton height={50} />
    <Skeleton height={50} />
    <Skeleton height={130} />
    <Skeleton height={50} />
    <Skeleton height={50} />
    <Skeleton height={80} />
  </>
);

function CompanyFundraiseSign(props) {
  const { investmentId, organizationId } = useParams();
  const { user, selectedOrganization } = React.useContext(UserContext);
  const canvas = useRef();
  const signatureImage = useRef();
  let ctx = null;

  const db = getFirestore();

  const [isError, setError] = useState(false);
  const [document, setDocument] = useState({});
  const [round, setRound] = useState({});

  const [documentId, setDocumentId] = useState();
  const [accreditation, setAccreditation] = useState({});

  const [investment, setInvestment] = useState({});

  const queryParams = queryString.parse(props.location.search);
  const accessToken = queryParams.accessToken;
  const [isUnauthorized, setIsUnauthorized] = useState(false);

  async function loadDocument() {
    try {
      if (accessToken) {
        try {
          const investmentToSign = await post("getInvestmentPacket", {
            accessToken: accessToken,
            organizationId,
            investmentId,
          });
          console.log("investmentToSign", investmentToSign.data);
          setInvestment(investmentToSign.data.investment);
          setDocument(investmentToSign.data.document);
          setDocumentId(investmentToSign.data.documentId);
        } catch (e) {
          setIsUnauthorized(true);
        }
      } else {
        try {
          const investmentRef = await getDoc(
            doc(
              db,
              `organizations/${organizationId}/organizations_admin/data/investments`,
              investmentId
            )
          );
          setInvestment(investmentRef.data());
          getDocToSign();
        } catch (e) {
          setIsUnauthorized(true);
        }
      }
    } catch (e) {
      console.log("error", e);
    }
  }

  async function getDocToSign() {
    console.log("selectedOrganization", organizationId);
    console.log("investmentId", investmentId);
    const querySnapshot = await getDocs(
      collection(
        db,
        `organizations/${organizationId}/organizations_admin/data/investments/${investmentId}/documents`
      )
    );
    const documentRef = querySnapshot.docs[0];
    const documentData = documentRef.data();

    console.log("documentData", documentData);
    setDocument(documentData);
    setDocumentId(documentRef.id);
  }

  // async function checkAccrediation() {
  //   if (!user.uid) {
  //     return;
  //   }
  //   if (queryParams.countersign) {
  //     loadDocument();
  //     return;
  //   }

  //   const admin = (
  //     await getDoc(doc(db, "users", user.uid, "users_admin", "data"))
  //   ).data();
  //   if (admin) {
  //     setAccreditation(admin.accreditation);
  //     if (admin.accreditationStatus) {
  //       loadDocument();
  //     }
  //   } else {
  //     history.push({
  //       pathname: `/investor/org/new`,
  //       search: `?redirectUrl=${location.pathname}` + location.search,
  //     });
  //   }
  // }

  useEffect(() => {
    // Only need to check accrediation for the investor
    loadDocument();
  }, [user.uid]);

  useEffect(() => {
    // dynamically assign the width and height to canvas
    const canvasEle = canvas.current;
    canvasEle.width = 900;
    canvasEle.height = 200;

    setTimeout(() => {
      window.scrollTo({
        top: 0,
        left: 0,
        behavior: "instant",
      });
    }, 100);
  }, []);

  const writeText = (e, setFieldValue) => {
    const text = e.target.value;
    const fontSize = 68,
      fontFamily = "Ephesis",
      color = "black",
      textAlign = "#383838",
      textBaseline = "top";

    ctx = canvas.current.getContext("2d");
    ctx.clearRect(0, 0, 10000, 10000);
    ctx.beginPath();
    ctx.font = fontSize + "px " + fontFamily;
    ctx.textAlign = textAlign;
    ctx.textBaseline = textBaseline;
    ctx.fillStyle = color;
    ctx.fillText(text, 0, 0);
    ctx.stroke();

    signatureImage.current.src = canvas.current.toDataURL();
    setFieldValue("signatureName", text);
  };

  const currentUserIsAFounder =
    selectedOrganization === investment.organizationId;
  var content;

  if (investment) {
    content = (
      <InvestorSignForm
        documentId={documentId}
        investmentId={investmentId}
        document={document}
        investment={investment}
        isError={isError}
        canvas={canvas}
        round={round}
        signatureImage={signatureImage}
        writeText={writeText}
        accessToken={accessToken}
        initialValues={{
          investorEmail: user.email,
          investorName: user.displayName,
          investorType:
            accreditation.investorType === "individual"
              ? "individual"
              : "entity",
          foreignInvestor: false,
          investorStreetAddress: "",
          investorCity: "",
          investorState: "",
          investorZipcode: "",
          investorEntity: "",
          investorTitle: "",
          investorAddressLine1: "",
          investorAddressLine2: "",
          signatureName: "",
        }}
        handleChange={async (values) => {
          console.log("values", values);
        }}
        onSubmit={async (values) => {
          let params = {
            counterSign: false,
            documentId: documentId,
            organizationId: organizationId,
            organizationName: investment.organizationName,
            investorSignature: signatureImage.current.src,
            investmentId: investmentId,
          };

          params = Object.assign({}, params, values);

          try {
            const result = await post(`signDocument`, params);

            console.log("result", result);
          } catch (e) {}
          await loadDocument();
        }}
      />
    );
  }

  // If the document has been signed, none of the founders have signed it yet & the current user is a founder then countersign it
  if (document.status === "completed") {
    content = (
      <FundraiseCompletionStage
        documentId={documentId}
        investment={investment}
        investmentId={investmentId}
        header={"Congrats! The deal is done!"}
        subheader={
          currentUserIsAFounder
            ? "Your investor has been notified and sent wire instructions."
            : "Check your email for wire instructions."
        }
        document={document}
        documentURL={document.signedUrl}
        documentName={document.signedName}
        accessToken={accessToken}
      />
    );
  } else if (
    document.signedUser &&
    currentUserIsAFounder &&
    document.status === "investor_signed"
  ) {
    content = (
      <FounderCountersignForm
        documentId={documentId}
        investmentId={investmentId}
        document={document}
        investment={investment}
        isError={isError}
        canvas={canvas}
        round={round}
        signatureImage={signatureImage}
        writeText={writeText}
        accessToken={accessToken}
        initialValues={{
          investorEmail: user.email,
          investorName: user.displayName,
          investorType:
            accreditation.investorType === "individual"
              ? "individual"
              : "entity",
        }}
        handleChange={async (values) => {
          console.log("values", values);
        }}
        onSubmit={async (values) => {
          let error = false;

          // Check if the signature has been filled out
          if (
            !signatureImage.current.src ||
            !values.fullName ||
            !values.founderTitle
          ) {
            error = true;
          } else {
            error = false;
          }

          setError(error);

          if (error) {
            return;
          }

          const params = {
            counterSign: true,
            documentId: documentId,
            organizationId: organizationId,
            organizationName: investment.organizationName,
            founderName: values.fullName,
            founderTitle: values.founderTitle,
            founderEmail: user.email,
            founderSignature: signatureImage.current.src,
            investorEmail: investment.investorEmail,
            investmentId: investmentId,
          };

          try {
            const result = await post(`signDocument`, params);

            console.log("result", result);
          } catch (e) {}
          await loadDocument();
        }}
      />
    );
  } else if (document.signedUser && document.signedUser === user.uid) {
    content = (
      <FundraiseCompletionStage
        documentId={documentId}
        investmentId={investmentId}
        document={document}
        investment={investment}
        header={"You're done!"}
        subheader={`${investment.organizationName} still has to counter sign the documents. We'll notify you when the documents have been signed.`}
        documentURL={document.signedUrl}
        documentName={document.signedName}
        accessToken={accessToken}
      />
    );
  } else if (
    !document.status ||
    document.status === "invited" ||
    document.status === "initiated"
  ) {
    if (currentUserIsAFounder) {
      // means hasn't signed yet
      content = (
        <div className="content-body section-padding">
          <div className="container mb-5">
            <div className="row justify-content-center align-items-center">
              <div className="col-xl-8 col-md-8">
                <div className="row">
                  <div className="card outlined">
                    {!investment.status && loading}
                    {investment.status && (
                      <>
                        <div
                          className="card-header"
                          style={{ display: "block" }}
                        >
                          <h3>Waiting for investor signature</h3>
                        </div>
                        <div className="card-body">
                          <p>
                            {investment.investorEmail} still has to sign the
                            documents. We'll notify you when its your turn to
                            countersign.
                          </p>

                          <DocumentRow
                            documentId={documentId}
                            investmentId={investmentId}
                            organizationId={organizationId}
                            accessToken={accessToken}
                          />
                        </div>
                      </>
                    )}
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      );
    }
  }

  if (isUnauthorized) {
    content = (
      <div className="content-body section-padding">
        <div className="container mb-5">
          <div className="row justify-content-center align-items-center">
            <div className="col-xl-8 col-md-8">
              <div className="row">
                <div className="card outlined">
                  <>
                    <div className="card-header" style={{ display: "block" }}>
                      <h3>Unauthorized</h3>
                    </div>
                    <div className="card-body">
                      <p>
                        Your access link is invalid or you're not permissioned
                        to view the details of this transcation.
                      </p>
                    </div>
                  </>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    );
  }

  return (
    <>
      <canvas
        style={{ visibility: "hidden", position: "absolute" }}
        ref={canvas}
      ></canvas>
      <img
        alt=""
        style={{ visibility: "hidden", position: "absolute" }}
        ref={signatureImage}
      />
      <Header4 hideAllMenuItems title="Sign document" />
      <PageTitle />

      {content}
    </>
  );
}

/**
 *
 * Components
 *
 */

function InvestorSignForm(props) {
  return (
    <>
      <Formik
        enableReinitialize
        initialValues={props.initialValues}
        onSubmit={props.onSubmit}
        validationSchema={InvestorSignSchema}
      >
        {({
          values,
          isSubmitting,
          setFieldValue,
          setFieldTouched,
          errors,
          touched,
        }) => (
          <Form>
            <div className="content-body">
              <div className="container mb-5">
                <div className="row justify-content-center align-items-center">
                  <div className="col-xl-8 col-md-8">
                    <div className="row">
                      <div className="card outlined">
                        {!props.document.name && loading}
                        {props.document.name && (
                          <>
                            <div
                              className="card-header"
                              style={{ display: "block" }}
                            >
                              <h2>Sign Documents</h2>
                              {props.investment.status === "voided" && (
                                <p>
                                  This document has been voided and can no
                                  longer be signed.
                                </p>
                              )}
                              {props.investment.status !== "voided" && (
                                <p>
                                  Sign documents to complete your investment in{" "}
                                  {props.investment.organizationLegalEntity}.
                                </p>
                              )}
                            </div>
                            <div className="card-body">
                              <div className="row">
                                <h4 className="form-section-header mt-0">
                                  Review terms
                                </h4>
                                <h6 className="form-label">
                                  I hereby acknowledge my commitment to invest
                                  in {props.investment.organizationLegalEntity}{" "}
                                  at the terms below:
                                </h6>

                                <ul className="investment-overview ms-4 mt-1">
                                  {props.investment && (
                                    <>
                                      <li>
                                        <strong>Amount</strong> $
                                        {props.investment.investmentAmount
                                          ? props.investment.investmentAmount.toLocaleString()
                                          : ""}
                                      </li>
                                      <li>
                                        <strong>Valuation</strong> $
                                        {props.investment.valuation
                                          ? props.investment.valuation.toLocaleString()
                                          : ""}
                                      </li>
                                      <li>
                                        <strong>Discount</strong>{" "}
                                        {props.investment.discount ?? 0}%
                                      </li>
                                      {props.round.mfn && (
                                        <li>
                                          <strong>Clauses</strong> Most Favored
                                          Nation
                                        </li>
                                      )}
                                    </>
                                  )}
                                </ul>

                                {props.investment.status !== "voided" && (
                                  <>
                                    <h4 className="form-section-header mt-4">
                                      Signature Block
                                    </h4>
                                    <div className="mb-0 col-xl-12">
                                      <h6 className="form-label">
                                        Will you be signing as an individual or
                                        on behalf of an entity?
                                      </h6>
                                      <div
                                        role="group"
                                        className="d-flex flex-column"
                                      >
                                        <label className="m-2">
                                          <Field
                                            className="form-check-input me-2"
                                            type="radio"
                                            name="investorType"
                                            value="individual"
                                          />
                                          Individual
                                        </label>
                                        <label className="m-2">
                                          <Field
                                            className="form-check-input me-2"
                                            type="radio"
                                            name="investorType"
                                            value="entity"
                                          />
                                          Entity
                                        </label>
                                      </div>
                                    </div>

                                    <div className="mb-0 col-xl-12">
                                      <h6 className="form-label">
                                        What is your first name? (The signer)
                                      </h6>
                                      <Field
                                        type="text"
                                        name="investorName"
                                        className={
                                          "form-control " +
                                          (touched.investorName &&
                                            errors.investorName &&
                                            "is-invalid")
                                        }
                                        placeholder="Firstname Lastname"
                                        autoComplete="off"
                                        autoCorrect="off"
                                        autoCapitalize="off"
                                        spellCheck="false"
                                      />
                                    </div>

                                    {values.investorType === "entity" && (
                                      <>
                                        <div className="mb-0 col-xl-12">
                                          <h6 className="form-label">
                                            What is the legal name of your
                                            entity?
                                          </h6>
                                          <Field
                                            type="text"
                                            name="investorEntity"
                                            className={
                                              "form-control " +
                                              (touched.investorEntity &&
                                                errors.investorEntity &&
                                                "is-invalid")
                                            }
                                            placeholder="Investment Ventures, LP"
                                            autoComplete="off"
                                            autoCorrect="off"
                                            autoCapitalize="off"
                                            spellCheck="false"
                                          />
                                        </div>

                                        <div className="mb-0 col-xl-12">
                                          <h6 className="form-label">
                                            What is your title?
                                          </h6>
                                          <Field
                                            type="text"
                                            name="investorTitle"
                                            className={
                                              "form-control " +
                                              (touched.investorTitle &&
                                                errors.investorTitle &&
                                                "is-invalid")
                                            }
                                            placeholder="General Partner"
                                            autoComplete="off"
                                            autoCorrect="off"
                                            autoCapitalize="off"
                                            spellCheck="false"
                                          />
                                        </div>
                                      </>
                                    )}

                                    <h4 className="form-section-header mt-4">
                                      Address block
                                    </h4>

                                    <div className="mb-0 col-xl-12">
                                      <div className="form-check mt-4 mb-0">
                                        <label>
                                          <Field
                                            className="form-check-input"
                                            type="checkbox"
                                            name="foreignInvestor"
                                          />
                                          I am a non-US based investor.
                                        </label>
                                      </div>
                                    </div>

                                    {!values.foreignInvestor && (
                                      <>
                                        <div className="mb-0 col-xl-12">
                                          <h6 className="form-label">
                                            Address
                                          </h6>
                                          <Field
                                            type="text"
                                            name="investorStreetAddress"
                                            className={
                                              "form-control " +
                                              (touched.investorStreetAddress &&
                                                errors.investorStreetAddress &&
                                                "is-invalid")
                                            }
                                            placeholder="123 Main Street"
                                            autoComplete="off"
                                            autoCorrect="off"
                                            autoCapitalize="off"
                                            spellCheck="false"
                                          />
                                        </div>

                                        <div className="mb-0 col-xs-2 col-sm-5">
                                          <h6 className="form-label">City</h6>
                                          <Field
                                            type="text"
                                            name="investorCity"
                                            className={
                                              "form-control " +
                                              (touched.investorCity &&
                                                errors.investorCity &&
                                                "is-invalid")
                                            }
                                            placeholder="City"
                                            autoCorrect="off"
                                            autoComplete="off"
                                            spellCheck="false"
                                          />
                                        </div>
                                        <div className="mb-0 col-xs-2 col-sm-3">
                                          <h6 className="form-label">State</h6>
                                          <Field
                                            type="text"
                                            name="investorState"
                                            className={
                                              "form-select " +
                                              (touched.investorState &&
                                                errors.investorState &&
                                                "is-invalid")
                                            }
                                            component="select"
                                            placeholder="State"
                                            defaultValue=""
                                          >
                                            <option value="" disabled hidden>
                                              State
                                            </option>
                                            {states.map((item) => {
                                              return (
                                                <option
                                                  key={item.abbreviation}
                                                  value={item.abbreviation}
                                                >
                                                  {item.abbreviation}
                                                </option>
                                              );
                                            })}
                                          </Field>
                                        </div>
                                        <div className="mb-0 col-xs-12 col-sm-4">
                                          <h6 className="form-label">
                                            Zipcode
                                          </h6>
                                          <Field
                                            type="text"
                                            name="investorZipcode"
                                            className={
                                              "form-control " +
                                              (touched.investorZipcode &&
                                                errors.investorZipcode &&
                                                "is-invalid")
                                            }
                                            placeholder="Zipcode"
                                          />
                                        </div>
                                      </>
                                    )}

                                    {values.foreignInvestor && (
                                      <>
                                        <div className="mb-0 col-xl-12">
                                          <h6 className="form-label">
                                            Address Line 1
                                          </h6>
                                          <Field
                                            type="text"
                                            name="investorAddressLine1"
                                            className={
                                              "form-control " +
                                              (touched.investorAddressLine1 &&
                                                errors.investorAddressLine1 &&
                                                "is-invalid")
                                            }
                                            placeholder="Street Address"
                                            autoComplete="off"
                                            autoCorrect="off"
                                            autoCapitalize="off"
                                            spellCheck="false"
                                          />
                                        </div>
                                        <div className="mb-0 col-xl-12">
                                          <h6 className="form-label">
                                            Address Line 2
                                          </h6>
                                          <Field
                                            type="text"
                                            name="investorAddressLine2"
                                            className={
                                              "form-control " +
                                              (touched.investorAddressLine2 &&
                                                errors.investorAddressLine2 &&
                                                "is-invalid")
                                            }
                                            placeholder="City, Postal, Country"
                                            autoComplete="off"
                                            autoCorrect="off"
                                            autoCapitalize="off"
                                            spellCheck="false"
                                          />
                                        </div>
                                      </>
                                    )}
                                    <div
                                      style={{
                                        height: 1,
                                        background: "#EEE",
                                        marginTop: "32px",
                                      }}
                                    />
                                    <h4 className="form-section-header mt-4">
                                      Preview documents
                                    </h4>
                                    <ul className="fundraising-rounds mt-4">
                                      {props.document && (
                                        <DocumentRow
                                          isSignaturePreview={true}
                                          documentId={props.documentId}
                                          investmentId={props.investmentId}
                                          organizationId={
                                            props.investment.organizationId
                                          }
                                          accessToken={props.accessToken}
                                        />
                                      )}
                                    </ul>
                                    <div
                                      style={{
                                        height: 1,
                                        background: "#EEE",
                                        marginTop: "32px",
                                      }}
                                    />
                                    <h4 className="form-section-header">
                                      Create your electronic signature
                                    </h4>

                                    <h6 className="form-label mb-4">
                                      Enter your full name to sign the document.
                                    </h6>

                                    <div className="mb-0 col-xs-12">
                                      <Field
                                        type="text"
                                        autoComplete="off"
                                        autoCorrect="off"
                                        autoCapitalize="off"
                                        spellCheck="false"
                                        name="signatureName"
                                        className={
                                          "signature-block w-100 form-control " +
                                          (touched.signatureName &&
                                            errors.signatureName &&
                                            "is-invalid")
                                        }
                                        placeholder="First Last"
                                        onChange={(e) => {
                                          props.writeText(e, setFieldValue);
                                        }}
                                      />
                                    </div>

                                    <div className="mb-0 col-xl-12">
                                      <p className="smaller mb-3 mt-4">
                                        By clicking the button below: I certify
                                        that I consent to conduct all affairs
                                        relating to the documents listed above
                                        electronically. I further certify that I
                                        have electronically accessed the
                                        documents listed above, and that I have
                                        read them. I adopt the above electronic
                                        signature as my signature, and hereby
                                        electronically sign the documents listed
                                        above. I acknowledge that I have
                                        accessed, have read and hereby agree to
                                        the Modern Share Terms of Service, and
                                        that I authorize the Modern Share
                                        services, in the manner designated
                                        therein, to process the documents and
                                        signatures provided herewith and to
                                        create, store, and communicate
                                        electronic records of the documents
                                        listed above.
                                      </p>

                                      <MSButton
                                        className="w-100"
                                        type="submit"
                                        loading={isSubmitting}
                                        title="Sign"
                                        loadingLabel="Signing"
                                        variant="primary"
                                      />
                                    </div>
                                  </>
                                )}
                              </div>
                            </div>
                          </>
                        )}
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </Form>
        )}
      </Formik>
    </>
  );
}

function FounderCountersignForm(props) {
  return (
    <>
      <Formik
        enableReinitialize
        initialValues={props.initialValues}
        onSubmit={props.onSubmit}
      >
        {({ values, isSubmitting, setFieldValue }) => (
          <Form>
            <div className="content-body">
              <div className="container mb-5">
                <div className="row justify-content-center align-items-center">
                  <div className="col-xl-8 col-md-8">
                    <div className="row">
                      <div className="card outlined">
                        {!props.document.name && loading}
                        {props.document.name && (
                          <>
                            <div
                              className="card-header"
                              style={{ display: "block" }}
                            >
                              <h2>Countersign Documents</h2>
                              {props.investment.status === "voided" && (
                                <p>
                                  This document has been voided and can no
                                  longer be signed.
                                </p>
                              )}
                              {props.investment.status !== "voided" && (
                                <p>
                                  Countersign documents to formally accept this
                                  proposed investment.
                                </p>
                              )}
                            </div>
                            <div className="card-body">
                              <div className="row">
                                <h4 className="form-section-header mt-0">
                                  Review terms
                                </h4>
                                <h6 className="form-label">
                                  I acknowledge this investment in{" "}
                                  {props.investment.organizationLegalEntity} at
                                  the terms below:
                                </h6>

                                <ul className="investment-overview ms-4 mt-1">
                                  {props.investment && (
                                    <>
                                      <li>
                                        <strong>Amount</strong> $
                                        {props.investment.investmentAmount
                                          ? props.investment.investmentAmount.toLocaleString()
                                          : ""}
                                      </li>
                                      <li>
                                        <strong>Valuation</strong> $
                                        {props.investment.valuation
                                          ? props.investment.valuation.toLocaleString()
                                          : ""}
                                      </li>
                                      <li>
                                        <strong>Discount</strong>{" "}
                                        {props.investment.discount ?? 0}%
                                      </li>
                                      {props.round.mfn && (
                                        <li>
                                          <strong>Clauses</strong> Most Favored
                                          Nation
                                        </li>
                                      )}
                                    </>
                                  )}
                                </ul>
                                <h4 className="form-section-header mt-4">
                                  Signature Block
                                </h4>

                                <div className="mb-0 col-xl-12">
                                  <h6 className="form-label">
                                    What is your full name?
                                  </h6>
                                  <Field
                                    type="text"
                                    name="fullName"
                                    className={
                                      "form-control " +
                                      (props.isError &&
                                        !values.fullName &&
                                        "is-invalid")
                                    }
                                    placeholder="Firstname Lastname"
                                    autoComplete="off"
                                    autoCorrect="off"
                                    autoCapitalize="off"
                                    spellCheck="false"
                                  />
                                </div>

                                <div className="mb-0 col-xl-12">
                                  <h6 className="form-label">
                                    What is your title?
                                  </h6>
                                  <Field
                                    type="text"
                                    name="founderTitle"
                                    className={
                                      "form-control " +
                                      (props.isError &&
                                        !values.founderTitle &&
                                        "is-invalid")
                                    }
                                    placeholder="CEO"
                                    autoComplete="off"
                                    autoCorrect="off"
                                    autoCapitalize="off"
                                    spellCheck="false"
                                  />
                                </div>
                                <div
                                  style={{
                                    height: 1,
                                    background: "#EEE",
                                    marginTop: "32px",
                                  }}
                                />
                                <h4 className="form-section-header mt-5">
                                  Preview documents
                                </h4>
                                <ul className="fundraising-rounds mt-4">
                                  {props.document && (
                                    <DocumentRow
                                      documentId={props.documentId}
                                      investmentId={props.investmentId}
                                      organizationId={
                                        props.investment.organizationId
                                      }
                                      accessToken={props.accessToken}
                                    />
                                  )}
                                </ul>
                                <div
                                  style={{
                                    height: 1,
                                    background: "#EEE",
                                    marginTop: "32px",
                                  }}
                                />
                                <h4 className="form-section-header">
                                  Create your electronic signature
                                </h4>

                                <h6 className="form-label mb-4">
                                  Enter your full name to sign the document.
                                </h6>

                                <div className="mb-0 col-xs-12">
                                  <Field
                                    type="text"
                                    autoComplete="off"
                                    autoCorrect="off"
                                    autoCapitalize="off"
                                    spellCheck="false"
                                    name="signatureName"
                                    className={
                                      "signature-block w-100 form-control " +
                                      (props.isError &&
                                        !values.signatureName &&
                                        "is-invalid")
                                    }
                                    placeholder="First Last"
                                    onChange={(e) => {
                                      props.writeText(e, setFieldValue);
                                    }}
                                  />
                                </div>

                                <div className="mb-0 col-xl-12">
                                  <p className="smaller mb-3 mt-4">
                                    By clicking the button below: I certify that
                                    I consent to conduct all affairs relating to
                                    the documents listed above electronically. I
                                    further certify that I have electronically
                                    accessed the documents listed above, and
                                    that I have read them. I adopt the above
                                    electronic signature as my signature, and
                                    hereby electronically sign the documents
                                    listed above. I acknowledge that I have
                                    accessed, have read and hereby agree to the
                                    Modern Share Terms of Service, and that I
                                    authorize the Modern Share services, in the
                                    manner designated therein, to process the
                                    documents and signatures provided herewith
                                    and to create, store, and communicate
                                    electronic records of the documents listed
                                    above.
                                  </p>

                                  <MSButton
                                    className="w-100"
                                    type="submit"
                                    loading={isSubmitting}
                                    title="Countersign"
                                    loadingLabel="Signing"
                                  />
                                </div>
                              </div>
                            </div>
                          </>
                        )}
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </Form>
        )}
      </Formik>
    </>
  );
}

function FundraiseCompletionStage(props) {
  return (
    <>
      <div className="content-body">
        <div className="container mb-5">
          <div className="row justify-content-center align-items-center">
            <div className="col-xl-8 col-md-8">
              <div className="row">
                <div className="card outlined-small">
                  <div className="card-header" style={{ display: "block" }}>
                    <h2>{props.header}</h2>
                    <p>{props.subheader}</p>
                  </div>
                  <div className="card-body">
                    <div className="row">
                      <h4 className="form-section-header mt-0">
                        Review documents
                      </h4>
                      <ul className="fundraising-rounds mt-4">
                        {props.document && props.investment && (
                          <DocumentRow
                            documentId={props.documentId}
                            investmentId={props.investmentId}
                            organizationId={props.investment.organizationId}
                            accessToken={props.accessToken}
                          />
                        )}
                      </ul>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
}

export default CompanyFundraiseSign;
