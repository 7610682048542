import { doc, getDoc, getFirestore } from "firebase/firestore";
import { Form, Formik } from "formik";
import { post } from "jsx/api";
import { UserContext } from "jsx/contexts/user-context";
import MSButton from "jsx/element/button";
import PDFViewer from "jsx/element/pdf-viewer";
import Header5 from "jsx/layout/header5";
import React, { useEffect, useState } from "react";
import NumberFormat from "react-number-format";
import { useHistory } from "react-router-dom";
import { useLocation, useParams } from "react-router-dom";

function FundraiseViewDeal() {
  const { user } = React.useContext(UserContext);
  const history = useHistory();
  const [round, setRound] = useState({});
  const [decks, setDecks] = useState([]);

  const { organizationId, roundId } = useParams();

  const [isSubmitting, setSubmitting] = useState(false);
  const [isError, setError] = useState(false);

  const location = useLocation();

  const db = getFirestore();

  const dealOverview = (
    <>
      <h6 className="mt-0 mb-1">Valuation</h6>
      <p className="small">${round.cap ? round.cap.toLocaleString() : ""}</p>

      <h6 className="mt-2 mb-1">Discount</h6>
      <p className="small">{round.discount}%</p>

      {/* <h6 className="mt-2 mb-1">Round size</h6>
      <p className="small">${round.size ? round.size.toLocaleString() : ""}</p> */}

      <h6 className="mt-2 mb-1">Investment type</h6>
      <p className="small">{round.instrument}</p>

      <Formik
        initialValues={{}}
        onSubmit={async (values) => {
          setSubmitting(true);
          let error = false;

          if (Object.keys(values).length !== 1) {
            error = true;
          } else {
            error = false;
          }

          setError(error);

          if (error) {
            setSubmitting(false);
            return;
          }

          const params = {
            investmentAmount: values.investmentAmount,
            investorId: user.uid,
            investorName: user.displayName,
            investorEmail: user.email,
            organizationId: organizationId,
            roundId: roundId,
          };

          const result = await post(`createInvestment`, params);

          history.push(
            `/org/${organizationId}/investment/${result.data.investmentId}/sign`
          );

          setSubmitting(false);
        }}
      >
        {({ values, setFieldValue }) => (
          <Form>
            <div className="input-with-icon">
              <i className="fa fa-usd icon"></i>
              <NumberFormat
                name="investmentAmount"
                className={
                  "form-control mb-3 text-end " +
                  (isError && !values.investmentAmount && "is-invalid")
                }
                placeholder="0"
                allowNegative={false}
                isNumericString={true}
                thousandSeparator={true}
                onValueChange={(val) =>
                  setFieldValue("investmentAmount", val.floatValue)
                }
              />
            </div>

            <MSButton
              loading={isSubmitting}
              title="Invest Now"
              size="large"
              className="w-100"
              type="submit"
            />
          </Form>
        )}
      </Formik>
    </>
  );

  async function fetchRound() {
    const res = await getDoc(
      doc(
        db,
        "organizations",
        organizationId,
        "organizations_private",
        "data",
        "rounds",
        roundId
      )
    );

    if (res) {
      const roundData = res.data();
      setRound(roundData);

      if (!roundData.templates) {
        return;
      }

      const tmp = [];

      for (const deck of roundData.templates) {
        const result = await post("getBucketDownloadPath", {
          filename: roundData.templates[0].fileUrl,
        });
        console.log("result", result);
        tmp.push(
          <div className="col-xl-8 col-md-8">
            <PDFViewer
              deckId={deck.deckId}
              file={result.data}
              email={user.email}
              displayName={user.displayName}
              userId={user.uid}
              organizationId={organizationId}
              roundId={roundId}
              showWatermark={true}
            />
          </div>
        );
      }

      setDecks(tmp);
    }
  }

  async function checkAccrediation() {
    if (!user.uid) {
      return;
    }
    const admin = (
      await getDoc(doc(db, "users", user.uid, "users_admin", "data"))
    ).data();
    if (!admin || !admin.accreditationStatus) {
      history.push({
        pathname: `/onboarding`,
        search: `?redirectUrl=${location.pathname}`,
      });
    } else {
      fetchRound();
    }
  }

  useEffect(() => {
    checkAccrediation();
  }, [user.uid]);

  return (
    <>
      <Header5 />
      <div
        className="content-body"
        style={{
          paddingTop: round.endDate ? "80px" : "120px",
          paddingBottom: "60px",
          minHeight: "100vh",
        }}
      >
        {round.endDate && (
          <div style={{ zIndex: 99 }}>
            <div className="container">
              <div style={{ paddingTop: "12px" }} className="text-center">
                {/* {`Investment Deadline – Nov 17th, 12:00PM PST`} */}
                {`Investment Deadline – ${new Date(
                  round.endDate.seconds * 1000
                ).toLocaleDateString("en-US", {
                  month: "short",
                  day: "numeric",
                  year: "numeric",
                  hour: "2-digit",
                  minute: "2-digit",
                  timeZoneName: "short",
                })}`}
              </div>
            </div>
          </div>
        )}
        <div className="container">
          <div className="row">
            {/* <div
              className="col-xl-8 col-md-6 top-content"
              style={{ paddingTop: round.endDate ? "100px" : "120px" }}
            >
              <div className="outlined card">
                <div className="card-header">
                  <h6>
                    ABOUT {(organization.companyName ?? "").toUpperCase()}
                  </h6>
                </div>
                <div className="card-body">
                  <div></div>
                </div>
              </div>
            </div> */}

            {decks}

            {/* <div className="col-xl-8 col-md-8">
                            <div className="outlined card" >
                                <div className="card-header">
                                    <h6>INVESTMENT MATERIALS</h6>
                                </div>
                                <div className="card-body">
                                    <ul className="fundraising-rounds">
                                        <li>
                                            <div className="d-flex">
                                                <div className="flex-grow-1">
                                                    <h5 className="mt-0 mb-1">Website</h5>
                                                </div>
                                                <div className="edit-option">
                                                    <Link to={'#'}>
                                                        <button className="btn btn-small btn-outline  waves-effect">
                                                            View
                                                        </button>
                                                    </Link>

                                                </div>
                                            </div>
                                        </li>

                                    </ul>
                                </div>
                            </div>
                        </div> */}

            <div className={"col-12 d-sm-none"}>
              <div className="card">
                <div className="outlined py-4">{dealOverview}</div>
              </div>
            </div>

            <div className={"col-4"}>
              <div
                className="card position-fixed d-none d-sm-block"
                style={{ top: round.endDate ? "180px" : "120px" }}
              >
                <div className="outlined fixed-size py-4">{dealOverview}</div>
              </div>
            </div>

            {/*  + (offset > 60 ? "position-fixed" : "position-absolute") } */}

            {/* <div className="col-xl-4 col-lg-12 col-xxl-12">
                            <div className="card position-fixed d-none d-sm-block" style={{ "top": "116px" }}>
                                <div className="card outlined fixed-size">
                                    <div className="py-4">
                                        {dealOverview}
                                    </div>
                                </div>
                            </div>

                        </div> */}
          </div>
        </div>
      </div>
      {/* 
            <Bottom />

            <Footer1 /> */}
    </>
  );
}

export default FundraiseViewDeal;
