import { useField } from "formik";
import React from "react";

function PercentInput({ ...props }) {
  const [field, meta] = useField(props);
  return (
    <div
      className={
        "form-control d-flex flex-row align-items-center " + props.className
      }
    >
      <input
        {...props}
        type="number"
        value={field.value}
        onChange={field.onChange}
        onBlur={field.onBlur}
        className="transparent-input"
        placeholder={props.placeholder ? props.placeholder : "%"}
      />
    </div>
  );
}

export default PercentInput;
