import { Field, Form, Formik } from "formik";
import React from "react";

import ShareholderAccessLevel from "./shareholder-access-level.json";
function ShareholderExperience({ onReturn }) {
  const handleSkip = () => {};
  return (
    <Formik
      initialValues={{
        founders: "full-details",
        investors: "advanced",
        employees: "employee",
        otherStakeholders: "basic",
      }}
      onSubmit={async (values) => {
        onReturn(5, values);
      }}
    >
      {({ values }) => (
        <Form>
          <div className="card-header">
            <h4 className="card-title">Select Shareholder Information</h4>
          </div>
          <div className="card-body">
            <div className="mb-3 col-xl-12">
              <p>
                Choose the default access levels for each stakeholder group.
              </p>
              <div className="mb-3 col-xl-12">
                <h5 className="mb-2 mt-3">Founders</h5>
                <Selector name="founders" />
              </div>
            </div>
            <div className="mb-3 col-xl-12">
              <div className="mb-3 col-xl-12">
                <h5 className="mb-2 mt-3">Investors</h5>
                <Selector name="investors" />
              </div>
            </div>
            <div className="mb-3 col-xl-12">
              <div className="mb-3 col-xl-12">
                <h5 className="mb-2 mt-3">Stakeholders</h5>
                <Selector name="stakeholders" />
              </div>
            </div>
            <div className="mb-3 col-xl-12">
              <div className="mb-3 col-xl-12">
                <h5 className="mb-2 mt-3">Other stakeholders</h5>
                <Selector name="otherStakeholders" />
              </div>
            </div>
          </div>
          <button className="btn m-2" onClick={handleSkip}>
            Skip for now
          </button>
          <button className="btn m-2" type="submit">
            Submit
          </button>
        </Form>
      )}
    </Formik>
  );
}

function Selector({ name }) {
  return (
    <Field component="select" name={name} className="form-select">
      <option value={null}></option>
      {ShareholderAccessLevel.map((item) => {
        return (
          <option key={item.value} value={item.value}>
            {item.name}
          </option>
        );
      })}
    </Field>
  );
}

export default ShareholderExperience;
