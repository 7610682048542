import Skeleton from "@mui/material/Skeleton";
import { doc, getDoc, getFirestore } from "firebase/firestore";
import { post } from "jsx/api";
import { UserContext } from "jsx/contexts/user-context";
import MSButton from "jsx/element/button";
import SignInForm from "jsx/element/signin-form";
import Header4 from "jsx/layout/header4";
import React, { useEffect, useState } from "react";
import { useHistory } from "react-router-dom";
import { useParams } from "react-router-dom";

function TeamInvite(props) {
  const [organization, setOrganization] = useState({});
  const { organizationId, inviteCode } = useParams();
  const [loading, setIsLoading] = useState(false);

  const { user } = React.useContext(UserContext);

  const history = useHistory();

  let acceptSigninButton;

  const db = getFirestore();

  async function fetchOrganization() {
    const docRef = doc(db, "organizations", organizationId);
    const docSnap = await getDoc(docRef);
    if (docSnap.exists()) {
      setOrganization(docSnap.data());
    } else {
    }
  }

  async function acceptInvite() {
    const params = {
      organizationId: organizationId,
      inviteCode: inviteCode,
    };

    setIsLoading(true);
    try {
      post(`acceptTeamInvite`, params);
      history.push("/org/dashboard");
    } catch (error) {
      alert("Not the correct email.");
    }
    setIsLoading(false);
  }

  useEffect(() => {
    //
  }, [inviteCode]);

  useEffect(() => {
    fetchOrganization();
  }, []);

  useEffect(() => {
    // We don't redirect the user until we get the user context
    // if(isWaitingForSignin && user.email) {
    //     history.push(signURL ? signURL : roundURL);
    // }
  }, [user.email]);

  const skeleton = (
    <div style={{ lineHeight: "30px" }}>
      <Skeleton height={80} />
      <Skeleton height={30} />
      <Skeleton height={30} />
      <Skeleton height={60} />
    </div>
  );

  if (user && user.email) {
    acceptSigninButton = (
      <div>
        <p>Click below to accept the invite.</p>
        <MSButton
          title="Join"
          loading={loading}
          icon={<i className="la la-arrow-right"></i>}
          onClick={acceptInvite}
          size="large"
          className="w-100"
        />
      </div>
    );
  } else {
    acceptSigninButton = (
      <div>
        <SignInForm isInvite={true} type={"company"} />
      </div>
    );
  }

  return (
    <>
      <Header4 hideAllMenuItems />

      <div className="authincation section-padding">
        <div className="container h-75">
          <div className="row justify-content-center h-75 align-items-center">
            <div className="col-xl-6 col-md-6">
              <div className="auth-form card text-center outlined">
                {!organization.companyName && skeleton}
                {organization.companyName && (
                  <>
                    <p>
                      You've been invited to join{" "}
                      <strong>{organization.companyName}</strong>. Sign in to
                      accept the invite.
                    </p>
                    <div className="card-body">
                      <div className="text-center">{acceptSigninButton}</div>
                    </div>
                  </>
                )}
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
}

export default TeamInvite;
