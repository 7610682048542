import {
  addDoc,
  collection,
  getDocs,
  getFirestore,
  serverTimestamp,
} from "@firebase/firestore";
import {
  duotone,
  regular,
} from "@fortawesome/fontawesome-svg-core/import.macro";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { Skeleton } from "@mui/material";
import Table from "@mui/material/Table";
import TableBody from "@mui/material/TableBody";
import TableCell from "@mui/material/TableCell";
import TableContainer from "@mui/material/TableContainer";
import TableHead from "@mui/material/TableHead";
import TableRow from "@mui/material/TableRow";
import { UserContext } from "jsx/contexts/user-context";
import MSButton from "jsx/element/button";
import EmptyState from "jsx/element/empty-state";
import SettingsNav from "jsx/element/secondaries-nav";
import { formatPrice } from "jsx/helpers";
import Header4 from "jsx/layout/header4";
import moment from "moment";
import React, { useEffect, useState } from "react";
import { Link, useHistory } from "react-router-dom";

function CompanySecondariesManageList() {
  const { selectedOrganization } = React.useContext(UserContext);
  const [secondaries, setSecondaries] = useState();
  const history = useHistory();

  const db = getFirestore();

  useEffect(() => {
    fetchSecondaries();
  }, [selectedOrganization]);

  async function debugCreate() {
    const values = {
      name: "Secondary",
      startDate: new Date(),
      buyerEndDate: new Date(new Date().getTime() + 14 * 86400000), // 2 weeks from now
      sellerStartDate: new Date(new Date().getTime() + 14 * 86400000), // same as buyer end
      endDate: new Date(new Date().getTime() + 28 * 86400000), // 2 weeks after buyer end/seller start
      sellerCountLimit: 12,
      sellableRatioLimit: 0.2,
      roundType: "nonTender",
      // pricePerShareInBaseUnits: Math.round(11111100 / 123144),
      // pricePerSharePrecision: 100,
      fmvInBaseUnits: 412,
      fmvPrecision: 100,
      status: "active.setup",
      createdDate: serverTimestamp(),
      updatedDate: serverTimestamp(),
      fullyDilutedSharesCount: 1e7,
      minPriceInBaseUnits: 1200,
      maxPriceInBaseUnits: 3400,
      minPricePrecision: 100,
      maxPricePrecision: 100,
      signingStartDate: new Date(new Date().getTime() + 42 * 86400000), // 2 weeks after endDate
      signingEndDate: new Date(new Date().getTime() + 49 * 86400000), // 1 week after signingStartDate
      closingDate: new Date(new Date().getTime() + 50 * 86400000), // 1 day after signingEndDate
      orderBookVisibility: "public",
    };

    try {
      await addDoc(
        collection(
          db,
          "organizations",
          selectedOrganization,
          "organizations_private",
          "data",
          "secondaries"
        ),
        values
      );
      await fetchSecondaries();
    } catch (e) {
      console.log("error", e);
    }
  }

  async function fetchSecondaries() {
    if (!selectedOrganization) {
      return;
    }
    const querySnapshot = await getDocs(
      collection(
        db,
        `organizations/${selectedOrganization}/organizations_private/data/secondaries`
      )
    );
    setSecondaries(querySnapshot.docs);
  }

  const emptyState = (
    <EmptyState
      icon={duotone("comments-dollar")}
      style={{ marginTop: 24 }}
      title={"No secondary rounds have been created yet"}
      subtitle={"Secondary rounds will appear here"}
    />
  );

  let secondariesContent;

  if (!secondaries) {
    secondariesContent = (
      <>
        <Skeleton height={50} />
        <Skeleton height={50} />
        <Skeleton height={50} />
        <Skeleton height={50} />
      </>
    );
  } else if (secondaries.length > 0) {
    secondariesContent = (
      <TableContainer>
        <Table aria-label="simple table">
          <TableHead>
            <TableRow>
              <TableCell>Name</TableCell>
              <TableCell align="right">Start Date</TableCell>
              <TableCell align="right">End Date</TableCell>
              <TableCell align="right">Stakeholders</TableCell>
              <TableCell align="right">Price Per Share</TableCell>
            </TableRow>
          </TableHead>
          <TableBody
            sx={{
              "& .MuiTableRow-root": {
                transition: "background 0.5",
              },
              "& .MuiTableRow-root:hover": {
                background: "var(--background)",
              },
            }}
          >
            {secondaries.map((e, i) => {
              const secondary = e.data();
              const secondaryLink = `/org/secondaries/manage/${e.id}/overview`;

              return (
                <TableRow
                  key={i}
                  onClick={() => history.push(secondaryLink)}
                  sx={{ cursor: "pointer" }}
                >
                  <TableCell>
                    <h6>{secondary.name}</h6>
                  </TableCell>

                  <TableCell align="right" style={{ padding: "24px" }}>
                    {moment(secondary.startDate.toDate()).format("M/D/YYYY")}
                  </TableCell>
                  <TableCell align="right">
                    {moment(secondary.endDate.toDate()).format("M/D/YYYY")}
                  </TableCell>
                  <TableCell align="right">
                    {secondary.sellerCount ?? 0}
                  </TableCell>
                  <TableCell align="right">
                    {secondary.pricePerShareInBaseUnits &&
                      formatPrice(
                        secondary.pricePerShareInBaseUnits,
                        secondary.pricePerSharePrecision
                      )}
                    {!secondary.pricePerShareInBaseUnits &&
                      secondary.minPriceInBaseUnits &&
                      secondary.minPriceInBaseUnits &&
                      `${formatPrice(
                        secondary.minPriceInBaseUnits,
                        secondary.minPricePrecision
                      )} - ${formatPrice(
                        secondary.maxPriceInBaseUnits,
                        secondary.maxPricePrecision
                      )}`}
                  </TableCell>
                </TableRow>
              );
            })}
          </TableBody>
        </Table>
      </TableContainer>
    );
  } else {
    secondariesContent = emptyState;
  }

  return (
    <>
      <Header4 />

      <div className="content-body section-padding">
        <div className="container">
          <div className="d-flex">
            <div className="me-4" style={{ minWidth: "220px" }}>
              <SettingsNav />
            </div>
            <div className="col-xl-10">
              <div className="flex-column">
                <div className="card outlined">
                  <div className="card-header">
                    <h4 className="card-title">Secondary rounds</h4>
                    <div>
                      <MSButton
                        hidden={!window.location.href.includes("localhost")}
                        onClick={debugCreate}
                        size="small"
                      >
                        Debug
                      </MSButton>
                      <Link to="/org/secondaries/manage/create">
                        <MSButton
                          size="small"
                          variant="secondary"
                          startIcon={
                            <FontAwesomeIcon icon={regular("plus")} size="xs" />
                          }
                        >
                          Create Secondary Round
                        </MSButton>
                      </Link>
                    </div>
                  </div>

                  <div className="card-body">{secondariesContent}</div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
}

export default CompanySecondariesManageList;
