import React from "react";
import { Link } from "react-router-dom";

import Divider from "./divider";

function SecondariesNav() {
  const activeSetting =
    document.location.href.split("/")[
      document.location.href.split("/").length - 1
    ];

  return (
    <>
      <div className="settings_menu">
        <h4 className="my-3">Secondaries</h4>
        <Divider
          style={{
            position: "relative",
            left: 0,
            marginBottom: 16,
            height: 2,
            marginRight: 4,
          }}
        />

        <ul>
          <div className="sidemenu">
            <li className="nav-item">
              <Link
                to={"/org/secondaries/manage"}
                className={
                  "nav-link " + (activeSetting === "manage" ? "active" : "")
                }
              >
                <i className="la la-usd"></i>
                <span>Manage Secondaries</span>
              </Link>
            </li>
            <li className="nav-item">
              <Link
                to={"/org/secondaries/stakeholders"}
                className={
                  "nav-link " +
                  (activeSetting === "stakeholders" ? "active" : "")
                }
              >
                <i className="la la-user-circle"></i>
                <span>Manage Stakeholders</span>
              </Link>
            </li>
            <li className="nav-item">
              <Link
                to={"/org/secondaries/modeling"}
                className={
                  "nav-link " + (activeSetting === "modeling" ? "active" : "")
                }
              >
                <i className="la la-chart-pie"></i>
                <span>Modeling</span>
              </Link>
            </li>
          </div>
        </ul>
      </div>
    </>
  );
}

export default SecondariesNav;
