import { Field, Form, Formik } from "formik";
import { postNoAuth } from "jsx/api";
import MSButton from "jsx/element/button";
import React from "react";
import * as Yup from "yup";

const ResetSchema = Yup.object().shape({
  email: Yup.string().email("Invalid email").required("Required"),
});
function Reset() {
  const [hasSubmitted, setHasSubmitted] = React.useState(false);
  return (
    <>
      <Formik
        initialValues={{
          email: "",
        }}
        validationSchema={ResetSchema}
        onSubmit={async (values) => {
          await postNoAuth(
            "sendPasswordReset",
            {
              email: values.email,
            },
            false
          );

          setHasSubmitted(true);
        }}
      >
        {({ touched, errors, isSubmitting }) => (
          <Form>
            <div className="authincation section-padding">
              <div className="container h-100">
                <div className="row justify-content-center h-100 align-items-center">
                  <div className="col-xl-5 col-md-6">
                    <div className="auth-form card">
                      {!hasSubmitted && (
                        <>
                          <div className="card-header justify-content-center">
                            <h4 className="card-title">Reset password</h4>
                          </div>
                          <div className="card-body">
                            <form action="">
                              <div className="mb-3">
                                <label>Email</label>
                                <Field
                                  autoFocus
                                  type="email"
                                  name="email"
                                  className={
                                    "form-control " +
                                    (touched.email &&
                                      errors.email &&
                                      "is-invalid")
                                  }
                                  placeholder="hello@example.com"
                                />
                              </div>
                              <MSButton
                                variant={"primary"}
                                size={"large"}
                                type="submit"
                                title="Reset password"
                                className="w-100"
                                loading={isSubmitting}
                              ></MSButton>
                            </form>
                          </div>
                        </>
                      )}

                      {hasSubmitted && (
                        <>
                          <div className="card-header justify-content-center">
                            <h4 className="card-title">Password Reset Sent</h4>
                          </div>
                          <div className="card-body">
                            <p>
                              Your password reset request has been received.{" "}
                              <br /> <br /> If there was an account associated
                              with the email provided, we will send an password
                              reset link to the provided email.
                            </p>
                          </div>
                        </>
                      )}
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </Form>
        )}
      </Formik>
    </>
  );
}

export default Reset;
