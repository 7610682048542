import axios from "axios";
import { getAuth, getIdToken } from "firebase/auth";

export function getBaseApiUrl() {
  if (
    window.location.hostname === "localhost" ||
    window.location.hostname === "127.0.0.1"
  ) {
    return "http://localhost:5001/modernsharecomstaging/us-central1";
  } else if (window.location.hostname === "modernsharecomstaging.web.app") {
    return "https://us-central1-modernsharecomstaging.cloudfunctions.net";
  } else {
    return "https://us-central1-modernsharecom.cloudfunctions.net";
  }
}

export function getBaseUrl() {
  if (
    window.location.hostname === "localhost" ||
    window.location.hostname === "127.0.0.1"
  ) {
    return "http://localhost:3000";
  } else if (window.location.hostname === "modernsharecomstaging.web.app") {
    return "https://modernsharecomstaging.web.app";
  } else {
    return "https://dashboard.modernshare.com";
  }
}

export async function postBeacon(path, params) {
  navigator.sendBeacon(`${getBaseApiUrl()}/v1/${path}`, params);
}

export async function post(path, params, options = {}) {
  const auth = getAuth();
  const authedUser = auth.currentUser;
  if (!authedUser && !Object.keys(params).includes("token")) {
    throw new Error("Missing API auth: no user or token");
  }

  let config = { headers: options };

  if (authedUser) {
    const accessToken = await getIdToken(authedUser);

    config = {
      ...config,
      headers: { Authorization: `Bearer ${accessToken}`, ...config.headers },
      withCredentials: true,
    };
  }

  try {
    const result = await axios.post(
      `${getBaseApiUrl()}/v1/${path}`,
      params,
      config
    );

    return result;
  } catch (e) {
    throw e;
  }
}

export async function postNoAuth(path, params) {
  try {
    const result = await axios.post(`${getBaseApiUrl()}/v1/${path}`, params);

    return result;
  } catch (e) {
    throw e;
  }
}

export async function get(path, params, options = {}) {
  const auth = getAuth();
  const authedUser = auth.currentUser;
  if (!authedUser) {
    return;
  }

  const accessToken = await getIdToken(authedUser);
  const config = {
    headers: { Authorization: `Bearer ${accessToken}`, ...options },
    withCredentials: true,
    params,
  };

  try {
    const result = await axios.get(`${getBaseApiUrl()}/v1/${path}`, config);

    return result;
  } catch (e) {
    throw e;
  }
}
