import { Skeleton } from "@mui/material";
import { Field, Form, Formik } from "formik";
import { post } from "jsx/api";
import SecondaryDocumentRow from "jsx/element/secondary-document-row";
import Header4 from "jsx/layout/header4";
import React, { useEffect, useState } from "react";
import * as Yup from "yup";

import SigningSection from "./secondaries-signing-section";
import WaitingForSignatures from "./secondaries-waiting-for-secondaries";

function CompanySecondariesSignSpouse({ match, location }) {
  const [document, setDocument] = useState();
  const [transaction, setTransaction] = useState();

  const queryParams = new URLSearchParams(location.search.replace("?", ""));
  const token = queryParams.get("t");

  const { organizationId, secondaryId, transactionId, documentId } =
    match.params;

  async function fetchSigningPacket(
    organizationId,
    secondaryId,
    transactionId
  ) {
    const params = {
      organizationId: organizationId,
      secondaryId: secondaryId,
      transactionId: transactionId,
      documentId: documentId,
      token: token,
    };

    const result = await post(
      "secondaries/getSignaturePacketWithToken",
      params
    );

    setTransaction(result.data.transaction);
    setDocument(result.data.document);
  }

  async function handleSubmit(signatureImage, values) {
    const { signerName } = values;

    const params = {
      signerParty: "seller_spouse",
      organizationId: organizationId,
      secondaryId: secondaryId,
      transactionId: transactionId,
      signature: signatureImage,
      signerName: signerName,
      isPreview: values.isPreview,
      documentId,
      token,
    };

    const result = await post(`signTransferDoc`, params);

    if (values.isPreview) {
      const uri = "data:pdf;base64," + result.data;

      var downloadLink = window.document.createElement("a");
      downloadLink.href = uri;
      downloadLink.download = "DRAFT.docx";

      window.document.body.appendChild(downloadLink);
      downloadLink.click();
      window.document.body.removeChild(downloadLink);
      return;
    }

    fetchSigningPacket(organizationId, secondaryId, transactionId);
  }

  useEffect(() => {
    fetchSigningPacket(organizationId, secondaryId, transactionId);
  }, [organizationId, secondaryId, transactionId, documentId]);

  const isLoaded = transaction && document;

  const documentPreview = (
    <SecondaryDocumentRow
      prefetchedDocument={document}
      organizationId={organizationId}
      secondaryId={secondaryId}
      transactionId={transactionId}
      documentId={documentId}
      token={token}
      showSigning={false}
    />
  );

  return (
    <>
      <Header4 hideAllMenuItems />

      <div className="content-body section-padding">
        <div className="container">
          <div className="row justify-content-center align-items-center">
            <div className="col-9">
              <div className="card outlined">
                {!isLoaded && (
                  <>
                    <Skeleton height={80} />
                    <Skeleton height={240} />
                    <Skeleton height={80} />
                    <Skeleton height={80} />
                    <Skeleton height={240} />
                    <Skeleton height={80} />
                    <Skeleton height={80} />
                  </>
                )}
                {isLoaded &&
                  (document.voided ? (
                    <Voided documentPreviewSection={documentPreview} />
                  ) : document.isCompleted ? (
                    <Completed documentPreviewSection={documentPreview} />
                  ) : document.isSignedBySellerSpouse ? (
                    <WaitingForSignatures
                      isWaiting={document.isSignedBySellerSpouse}
                      transaction={transaction}
                      documentPreviewSection={documentPreview}
                    />
                  ) : (
                    <SigningForm
                      transaction={transaction}
                      initialValues={
                        // TODO: Pre-fill values with stored data
                        {
                          signerName: "",
                        }
                      }
                      documentPreviewSection={documentPreview}
                      onSubmit={handleSubmit}
                    />
                  ))}
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
}

function Completed({ documentPreviewSection }) {
  return (
    <>
      <div className="card-header">
        <h4 className="card-title mb-3">Completed</h4>
      </div>
      <div className="card-body">
        {/* TODO: Add copy */}
        {documentPreviewSection}
      </div>
    </>
  );
}

function Voided({ documentPreviewSection }) {
  return (
    <>
      <div className="card-header">
        <h4 className="card-title">This document has been voided</h4>
      </div>
      <div className="card-body">
        <p className="mb-2">
          This document has been voided and is no longer available for signing
        </p>
        {documentPreviewSection}
      </div>
    </>
  );
}

const SignSchema = Yup.object().shape({
  signerName: Yup.string().required("Enter your name"),
});

function SigningForm({
  transaction,
  initialValues,
  documentPreviewSection,
  onSubmit,
}) {
  const [signatureImage, setSignatureImage] = useState();
  const [isError, setError] = useState(false);
  const [isPreviewing, setIsPreviewing] = useState(false);
  const [isSigning, setIsSigning] = useState(false);

  const pricePerShare =
    transaction.pricePerShareInBaseUnits / transaction.pricePerSharePrecision;
  const pricePerShareFormat = {
    style: "currency",
    currency: "USD",
    minimumFractionDigits: Math.max(
      2,
      Math.log10(transaction.pricePerSharePrecision)
    ),
  };

  const totalAmount = pricePerShare * transaction.quantity;

  return (
    <Formik
      enableReinitialize
      initialValues={initialValues}
      validationSchema={SignSchema}
      onSubmit={async (values) => {
        if (!signatureImage) {
          setError(true);
          return;
        }

        if (values.isPreview) {
          setIsPreviewing(true);
        } else {
          setIsSigning(true);
        }

        await onSubmit(signatureImage, values);
        setIsPreviewing(false);
        setIsSigning(false);
      }}
    >
      {({
        values,
        isSubmitting,
        setFieldValue,
        setFieldTouched,
        submitForm,
        errors,
        touched,
      }) => (
        <Form>
          <div className="card-header" style={{ display: "block" }}>
            <h2>Consent of Spouse</h2>
            <p>Sign documents to formally accept this proposed transfer.</p>
          </div>
          <div className="card-body">
            <div className="row">
              {/* Review section */}
              <h4 className="form-section-header mt-0">Review terms</h4>
              <h6 className="form-label">
                I acknowledge this stock transfer at the terms below:
              </h6>
              <ul className="investment-overview ms-4 mt-1">
                {transaction && (
                  <>
                    <li>
                      <strong>Spouse </strong>
                      {transaction.sellerName}
                    </li>
                    <li>
                      <strong>Company </strong>
                      {transaction.companyName}
                    </li>
                    <li>
                      <strong>Number of shares </strong>
                      {transaction.quantity.toLocaleString()}
                    </li>
                    <li>
                      <strong>Price per share </strong>
                      {pricePerShare.toLocaleString(
                        undefined,
                        pricePerShareFormat
                      )}
                    </li>

                    <li>
                      <strong>Total amount </strong>
                      {totalAmount.toLocaleString(undefined, {
                        style: "currency",
                        currency: "USD",
                      })}
                    </li>
                  </>
                )}
              </ul>

              {/* Signature block section */}
              <h4 className="form-section-header mt-4">Signature Block</h4>
              <div className="mb-0 col-xl-12">
                <h6 className="form-label">What is your full name?</h6>
                <Field
                  type="text"
                  name="signerName"
                  className={
                    "form-control " +
                    (touched.signerName && errors.signerName && "is-invalid")
                  }
                  placeholder="Firstname Lastname"
                  autoComplete="off"
                  autoCorrect="off"
                  autoCapitalize="off"
                  spellCheck="false"
                />
              </div>

              <SigningSection
                documentPreviewSection={documentPreviewSection}
                isError={isError}
                signatureImage={signatureImage}
                setSignatureImage={setSignatureImage}
                isSigning={isSigning}
                isPreviewing={isPreviewing}
                submitForm={() => {
                  setFieldValue("isPreview", false);
                  submitForm();
                }}
                downloadPreview={() => {
                  setFieldValue("isPreview", true);
                  submitForm();
                }}
              />
            </div>
          </div>
        </Form>
      )}
    </Formik>
  );
}

export default CompanySecondariesSignSpouse;
