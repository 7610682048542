import SettingsNav from "jsx/element/contract-settings-nav";
import PageTitle from "jsx/element/page-title";
import Sidebar from "jsx/layout/company-sidebar";
import Footer2 from "jsx/layout/footer2";
import Header2 from "jsx/layout/header2";
import React, { useState } from "react";

function Settings() {
  const [numToMint, setNumToMint] = useState(0);
  const [numToList, setNumToList] = useState(0);

  function onMintChange(e) {
    const re = /^[0-9\b]+$/;

    // if value is not blank, then test the regex

    if (e.target.value === "" || re.test(e.target.value)) {
      setNumToMint(e.target.value);
    }
  }

  function onListChange(e) {
    const re = /^[0-9\b]+$/;

    // if value is not blank, then test the regex

    if (e.target.value === "" || re.test(e.target.value)) {
      setNumToList(e.target.value);
    }
  }

  const mint = () => {
    alert("mint");
  };

  const list = () => {};
  return (
    <>
      <Header2 />
      <Sidebar />
      <PageTitle />

      <div className="content-body">
        <div className="container-fluid">
          <div className="row">
            <div className="col-xl-3 col-md-4">
              <SettingsNav />
            </div>
            <div className="col-xl-9 col-md-8">
              <div className="row">
                <div className="col-xl-6">
                  <div className="card">
                    <div className="card-header">
                      <h4 className="card-title">Mint</h4>
                    </div>
                    <div className="card-body">
                      <div className="row">
                        <div className="mb-3 col-xl-12">
                          <div className="d-flex justify-content-between mt-3 mb-2">
                            <h5 className="mb-0 mt-1">Amount to mint</h5>
                            <p className="mb-0">10000 Mintable</p>
                          </div>
                          <input
                            type="number"
                            className="form-control"
                            placeholder="0"
                            onChange={onMintChange}
                            value={numToMint ? numToMint : ""}
                          />
                        </div>

                        <div className="col-12">
                          <button
                            className="btn btn-success waves-effect"
                            onClick={mint}
                          >
                            Mint
                          </button>
                        </div>
                        <br />
                        <br />
                        <br />

                        <div className="mb-3 col-xl-12">
                          <div className="d-flex justify-content-between mt-3 mb-2">
                            <h5 className="mb-0 mt-1">Amount to list</h5>
                            <p className="mb-0">0 Listable</p>
                          </div>
                          <input
                            type="number"
                            className="form-control"
                            placeholder="0"
                            onChange={onListChange}
                            value={numToList ? numToList : ""}
                          />
                        </div>

                        <div className="col-12">
                          <button
                            className="btn btn-success waves-effect"
                            onClick={list}
                          >
                            List
                          </button>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
                <div className="col-xl-4 col-lg-12 col-xxl-12">
                  <div className="card position-fixed" style={{ width: 300 }}>
                    <div className="card-header">
                      <h4 className="card-title">Overview</h4>
                    </div>
                    <div className="card-body">
                      <div className="row">
                        <div className="d-flex justify-content-between mt-3 mb-2">
                          <h5 className="mb-0 mt-1">Minted</h5>
                          <p className="mb-0">0 / 10000</p>
                        </div>
                        <div className="d-flex justify-content-between mt-3 mb-2">
                          <h5 className="mb-0 mt-1">Listed</h5>
                          <p className="mb-0">0 / 0</p>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>

      <Footer2 />
    </>
  );
}

export default Settings;
