import { Skeleton, Tooltip } from "@mui/material";
import { InputAdornment } from "@mui/material";
import { Box } from "@mui/system";
import {
  DataGridPro,
  GridLinkOperator,
  GridOverlay,
} from "@mui/x-data-grid-pro";
import { post } from "jsx/api";
import { monthDiff, titleCase, toCapcase } from "jsx/helpers";
import EmployeeActionButton from "jsx/pages/company/stakeholder-action-button";
import { useMemo, useState } from "react";
import { Link } from "react-router-dom";

import MSButton from "./button";
import MSField from "./ms-field";

export default function SecondaryStakeholderTable({
  secondaryEmployees,
  stakeholders,
  onAddEmployees,
  loading,
  organizationId,
  secondary,
  secondaryId,
  onSecondaryEmployeesUpdate,
}) {
  const [searchString, setSearchString] = useState("");
  const [selectionModel, setSelectionModel] = useState([]);
  const [isSelectingEmployees, setIsSelectingEmployees] = useState(false);

  const columns = useMemo(() => {
    if (isSelectingEmployees) {
      return [
        { field: "name", headerName: "Name", width: 180 },
        { field: "email", headerName: "Email", width: 160 },

        {
          field: "securitiesTotal",
          headerName: "Securities",
          type: "number",
          width: 140,
        },
        {
          field: "role",
          headerName: "Role",
          width: 120,
        },
        {
          field: "tenureInMonths",
          headerName: "Tenure",
          type: "number",
          width: 140,
          renderCell: (params) => <span>{Math.floor(params.value)} mo</span>,
        },
      ];
    } else {
      return [
        { field: "name", headerName: "Name", width: 180 },
        { field: "email", headerName: "Email", width: 140 },
        {
          field: "status",
          headerName: "Status",
          width: 90,
          renderCell: (params) => {
            const employee = secondaryEmployees.find(
              (empl) => empl.primaryEmail === params.row.id
            );

            if (!employee) {
              return;
            }

            const { status, invitedDate } = employee;

            const date =
              invitedDate instanceof Date ? invitedDate : invitedDate?.toDate();
            return (
              <div
                className="d-flex justify-content-between align-items-end"
                style={{ lineHeight: "normal" }}
              >
                {params.value}
                {status === "invited" && date && (
                  <span style={{ color: "#888", fontSize: 12, marginLeft: 4 }}>
                    on{" "}
                    {date.toLocaleString("en-us", {
                      month: "numeric",
                      day: "numeric",
                    })}
                  </span>
                )}
              </div>
            );
          },
        },
        {
          field: "securitiesTotal",
          headerName: "Securities",
          type: "number",
          width: 140,
        },
        {
          field: "securitiesSellable",
          headerName: "Sellable",
          type: "number",
          width: 140,
        },
        {
          field: "securitiesSelling",
          headerName: "Elected to sell",
          type: "number",
          width: 130,
        },
        // {
        //   field: "actions",
        //   headerName: " ",
        //   sortable: false,
        //   width: 80,
        //   disableClickEventBubbling: true,
        //   renderCell: (params) => {
        //     const stakeholder = secondaryEmployees.find(
        //       (empl) => empl.primaryEmail === params.row.id
        //     );
        //     return (
        //       <ActionButton
        //         stakeholder={stakeholder}
        //         organizationId={organizationId}
        //         secondaryId={secondaryId}
        //         onUpdate={onSecondaryEmployeesUpdate}
        //       />
        //     );
        //   },
        // },
      ];
    }
  }, [
    isSelectingEmployees,
    onSecondaryEmployeesUpdate,
    organizationId,
    secondaryEmployees,
    secondaryId,
  ]);

  const secondaryEmployeeRows = useMemo(() => {
    return secondaryEmployees.map((stakeholder, i) => {
      return {
        id: stakeholder.primaryEmail,
        name: stakeholder.displayName,
        email: stakeholder.primaryEmail,
        status: titleCase(stakeholder.status),
        securitiesTotal: stakeholder.securitiesSummary
          ?.map((sec) => sec.quantityOutstanding ?? 0)
          .reduce((acc, num) => (acc += num), 0),
        securitiesSellable: stakeholder.securitiesSummary
          ?.map((sec) => sec.sellable ?? 0)
          .reduce((acc, num) => (acc += num), 0),
        securitiesSelling: stakeholder.securitiesSelling ?? "-",
      };
    });
  }, [secondaryEmployees]);

  const employeeRows = useMemo(() => {
    const secondaryEmployeeIds = secondaryEmployeeRows.map(({ id }) => id);
    return stakeholders.flatMap((stakeholder, i) => {
      const email = stakeholder.primaryEmail;
      if (secondaryEmployeeIds.includes(email)) return [];
      let tenureInMonths;

      if (stakeholder.tenureInMonths) {
        tenureInMonths = stakeholder.tenureInMonths;
      } else if (stakeholder.earliestVestingStartDate) {
        tenureInMonths = stakeholder.tenureInMonths = monthDiff(
          new Date(stakeholder.earliestVestingStartDate.seconds * 1000),
          new Date()
        );
      } else {
        tenureInMonths = stakeholder.tenureInMonths = "";
      }

      return [
        {
          id: email,
          name: stakeholder.displayName,
          email: email,
          tenureInMonths,
          role: toCapcase(stakeholder.role?.replace("_", "-")),
          securitiesTotal:
            stakeholder.securitiesSummary
              ?.map((sec) => sec.quantityOutstanding ?? 0)
              .reduce((acc, num) => (acc += num), 0) || 0,
        },
      ];
    });
  }, [stakeholders, secondaryEmployeeRows]);

  function searchOnChange(e) {
    setSearchString(e.target.value);
  }

  async function handleAddEmployees() {
    const selectedEmployees = selectionModel.flatMap((id) => {
      const stakeholder = stakeholders.find((empl) => empl.primaryEmail === id);
      const email = stakeholder.primaryEmail;

      if (email) {
        return [
          {
            email: email,
            firstName: stakeholder.firstName,
            lastName: stakeholder.lastName,
          },
        ];
      } else {
        return [];
      }
    });
    setSelectionModel([]);
    await onAddEmployees(selectedEmployees);
    setIsSelectingEmployees(false);
    setSearchString("");
  }

  const gridStyle = {
    width: "100%",
    "& .MuiDataGrid-cell": {
      fontSize: "13px",
    },
  };

  let gridSection;

  gridSection = (
    <DataGridPro
      rows={isSelectingEmployees ? employeeRows : secondaryEmployeeRows}
      columns={columns}
      pageSize={100}
      disableColumnMenu
      checkboxSelection={true}
      loading={loading}
      key={loading}
      sx={{
        borderColor: "var(--purple-light)",

        "& .MuiDataGrid-cell": {
          borderBottomColor: "var(--purple-light)",
          fontFamily: "var(--primary-font)",
          fontSize: 14,
          fontWeight: 400,
        },
        "& .MuiDataGrid-columnHeaderTitle": {
          fontSize: 13,
          fontFamily: "var(--primary-font)",
          color: "#6c757d",
        },
        "& .MuiDataGrid-columnHeaders": {
          borderBottomColor: "var(--purple-light)",
        },
        "& .MuiDataGrid-row:hover": {
          background: "#FFF",
        },
        "& .MuiDataGrid-cell:focus": {
          outline: "none",
        },
        "& .MuiDataGrid-columnHeader:focus": {
          outline: "none",
        },
        "& .MuiDataGrid-columnHeader:focus-within": {
          outline: "none",
        },
        "& .MuiDataGrid-cell:focus-within": {
          outline: "none",
        },
      }}
      components={{
        NoRowsOverlay: NoRowsFound({
          linkToEmployees: stakeholders.length === 0,
          setIsSelectingEmployees,
        }),
      }}
      onSelectionModelChange={(newSelectionModel) => {
        setSelectionModel(newSelectionModel);
      }}
      selectionModel={selectionModel}
      filterModel={{
        items: [
          {
            id: 1,
            columnField: "name",
            operatorValue: "contains",
            value: `${searchString}`,
          },
        ],
        linkOperator: GridLinkOperator.Or,
      }}
    />
  );

  async function removeSellers() {
    const sellers = await post("secondaries/removeSellers", {
      organizationId,
      secondaryId,
      sellerIds: selectionModel,
    });
    onSecondaryEmployeesUpdate(
      secondaryEmployees.filter(
        (employee) =>
          !selectionModel.some((email) => employee.emails.includes(email))
      )
    );
  }

  return (
    <>
      <div>
        <div className="d-flex mb-3 justify-content-between">
          <div className="d-flex">
            <EmployeeActionButton
              showRemoveEmployees={!isSelectingEmployees}
              disabled={selectionModel.length === 0}
              handleRemoveEmployees={removeSellers}
            />
            <MSField
              name="Search stakeholders"
              className="ms-2"
              size="small"
              style={{ minWidth: 300 }}
              placeholder="First or last name"
              onChange={searchOnChange}
              InputProps={{
                startAdornment: (
                  <InputAdornment position="start" style={{ outline: "none" }}>
                    <i
                      className="fa fa-search"
                      style={{
                        fontSize: 18,
                      }}
                    ></i>
                  </InputAdornment>
                ),
              }}
            />
          </div>
          <div className="d-flex align-items-center">
            {isSelectingEmployees && (
              <div
                className="back-button me-3"
                onClick={() => {
                  setSelectionModel([]);
                  setIsSelectingEmployees(false);
                }}
              >
                <i className="la la-angle-left"></i>
                <span>Cancel</span>
              </div>
            )}
            {!isSelectingEmployees && stakeholders.length > 0 && (
              <div
                className={`forward-button me-3 ${
                  secondary?.sellerCountLimit &&
                  secondaryEmployees.length >= secondary.sellerCountLimit
                    ? "disabled"
                    : ""
                }`}
                onClick={() => {
                  if (
                    secondary?.sellerCountLimit &&
                    secondaryEmployees.length >= secondary.sellerCountLimit
                  ) {
                    return;
                  }
                  setIsSelectingEmployees(true);
                }}
              >
                <span>Add participants</span>
                <i className="la la-angle-right"></i>
              </div>
            )}
            {selectionModel &&
              selectionModel.length > 0 &&
              isSelectingEmployees && (
                <MSButton
                  title="Add participants"
                  onClick={async () => await handleAddEmployees()}
                  variant="primary"
                />
              )}
          </div>
        </div>

        <div style={{ height: 600, width: "100%", marginBottom: 32 }}>
          <Box
            sx={{
              ...gridStyle,
              height: 620,
            }}
          >
            {gridSection}
          </Box>
        </div>
      </div>
    </>
  );
}

function NoRowsFound({ linkToEmployees, setIsSelectingEmployees }) {
  // Return a function because NoRowsOverlay is expecting a function/component
  return () => (
    <GridOverlay style={{ zIndex: 9, position: "relative" }}>
      {linkToEmployees && (
        <div className="row">
          <div className="col-12 text-center flex-shrink-1">
            <div className="col-8 mx-auto">
              You don’t have any stakeholders added to your directory. Add them
              first to your directory and they will appear here.
            </div>
            <div className="m-4">
              <Link
                to={"/org/secondaries/stakeholders"}
                className="btn btn-primary"
              >
                Upload stakeholders
              </Link>
            </div>
          </div>
        </div>
      )}

      {!linkToEmployees && (
        <div className="row mx-auto justify-content-center">
          <div className="col-12 flex-shrink-1 text-nowrap text-center mb-4">
            No stakeholders selected
          </div>
          <div className="col-12 text-center">
            <button
              onClick={() => setIsSelectingEmployees(true)}
              className="btn btn-primary text-nowrap"
            >
              Select stakeholders
            </button>
          </div>
        </div>
      )}
    </GridOverlay>
  );
}

function ActionButton({ stakeholder, organizationId, secondaryId, onUpdate }) {
  const [isSubmitting, setIsSubmitting] = useState(false);
  const [isSent, setIsSent] = useState(false);
  // For now just a resend email button if the stakeholder status
  // is 'invited'. Can turn this into a DropdownButton if needed
  const sendEmailInvitation = async (event) => {
    setIsSubmitting(true);
    await post("secondaries/remindSeller", {
      secondaryId,
      organizationId,
      sellerEmail: stakeholder.email,
    });
    setIsSubmitting(false);
    setIsSent(true);
    onUpdate({ ...stakeholder, invitedDate: new Date() });
    setTimeout(() => setIsSent(false), 2000);
  };

  const { status } = stakeholder;

  if (status !== "invited") return <div />;
  return (
    <div className="d-flex justify-content-end w-100">
      {!isSent && (
        <Tooltip title={`Send email reminder to ${stakeholder.email}`}>
          <div>
            <div
              className="border border-2 p-1"
              style={{ lineHeight: "normal" }}
            >
              {!isSubmitting && (
                <i
                  className="la la-fw la-lg la-envelope"
                  style={{ cursor: "pointer" }}
                  onClick={sendEmailInvitation}
                />
              )}
              {isSubmitting && (
                <i className="la la-fw la-lg la-spin la-circle-notch" />
              )}
            </div>
          </div>
        </Tooltip>
      )}
      {isSent && (
        <div>
          <div className="border border-2 p-1" style={{ lineHeight: "normal" }}>
            <Tooltip title="Email reminder sent" open={true}>
              <i className="la la-fw la-lg la-check" />
            </Tooltip>
          </div>
        </div>
      )}
    </div>
  );
}
