import { regular } from "@fortawesome/fontawesome-svg-core/import.macro";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import CloseIcon from "@mui/icons-material/Close";
import {
  Dialog,
  IconButton,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Toolbar,
} from "@mui/material";
import { Box } from "@mui/system";
import { post } from "jsx/api";
import PDFViewer from "jsx/element/pdf-viewer";
import { useState } from "react";

import MSButton from "./button";

export default function FilesViewer({
  files,
  user,
  editMode = false,
  onDelete,
}) {
  const [openFile, setOpenFile] = useState();

  const handleClose = () => {
    setOpenFile();
  };

  const handleDelete = (documentId) => {
    onDelete(documentId);
  };

  const handleView = async (file) => {
    const result = await post("getBucketDownloadPath", {
      filename: file.fileUrl,
    });
    setOpenFile({ ...file, fileData: result.data });
  };

  return (
    <>
      {editMode ? (
        <div className="mt-2">
          {files.map((file, i) => (
            <Box
              className="d-flex pt-2 pb-1 ps-4 pe-2"
              key={i}
              sx={{
                cursor: "pointer",
                borderRadius: 1,
                "&:hover": {
                  background: "var(--background)",
                },
              }}
            >
              <div className="flex-grow-1" onClick={() => handleView(file)}>
                <p className="mt-1 mb-0">{file.name}</p>
                <p className="small mb-0">
                  {"Uploaded " +
                    new Date(file.createdDate.milliseconds).toLocaleString(
                      "en-us",
                      {
                        month: "short",
                        day: "numeric",
                      }
                    )}
                </p>
              </div>
              <div>
                <MSButton
                  className="me-2"
                  title="Delete"
                  size="small"
                  startIcon={<FontAwesomeIcon icon={regular("trash")} />}
                  onClick={(e) => {
                    handleDelete(file.documentId);
                  }}
                />
              </div>
            </Box>
          ))}
        </div>
      ) : (
        <TableContainer>
          <Table aria-label="simple table">
            <TableHead>
              <TableRow>
                <TableCell>Document Name</TableCell>
              </TableRow>
            </TableHead>
            <TableBody
              sx={{
                "& .MuiTableRow-root": {
                  transition: "background 0.5",
                  color: "red",
                  bottomBorder: "none",
                },
                "& .MuiTableRow-root:hover": {
                  background: "var(--background)",
                },
              }}
            >
              {files.map((file) => {
                return (
                  <TableRow
                    key={file.documentId}
                    hover={true}
                    onClick={() => handleView(file)}
                    sx={{ cursor: "pointer" }}
                  >
                    <TableCell>{file.name}</TableCell>
                  </TableRow>
                );
              })}
            </TableBody>
          </Table>
        </TableContainer>
      )}
      <Dialog
        fullWidth={true}
        maxWidth="xl"
        open={!!openFile}
        onClose={handleClose}
      >
        <Toolbar sx={{ mt: 2 }}>
          <Box sx={{ ml: 2, flex: 1 }}>
            <h3>{openFile?.name}</h3>
          </Box>
          <IconButton
            edge="start"
            color="inherit"
            onClick={handleClose}
            aria-label="close"
          >
            <CloseIcon />
          </IconButton>
        </Toolbar>
        <div className="p-4 pt-2">
          <PDFViewer
            file={openFile?.fileData}
            showWatermark={true}
            email={user.email}
            displayName={user.displayName}
            userId={user.uid}
            hideFullScreenButton
          />
        </div>
      </Dialog>
    </>
  );
}
