import { regular } from "@fortawesome/fontawesome-svg-core/import.macro";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { InputAdornment } from "@mui/material";
import { doc, getFirestore, updateDoc } from "firebase/firestore";
import { Form, Formik } from "formik";
import MSButton from "jsx/element/button";
import MSField from "jsx/element/ms-field";
import { formatPrice } from "jsx/helpers";
import { useState } from "react";
import NumberFormat from "react-number-format";

function calcPrecision(price) {
  return 10 ** (price.toString().split(".")?.[1]?.length || 0);
}

function calcPrice(price) {
  if (!price) return [null, 0];
  const precision = calcPrecision(price);
  return [Math.round(price * precision), precision];
}

export default function UpdateSecondaryPrice({
  organizationId,
  secondary,
  handleUpdate,
}) {
  const db = getFirestore();
  const [isUpdatingSecondary, setIsUpdatingSecondary] = useState(false);
  const [isEditing, setIsEditing] = useState(false);

  const secondaryId = secondary?.id;

  const updateSecondary = async (
    pricePerShareInBaseUnits,
    pricePerSharePrecision
  ) => {
    const secondaryRef = doc(
      db,
      "organizations",
      organizationId,
      "organizations_private",
      "data",
      "secondaries",
      secondaryId
    );
    await updateDoc(secondaryRef, {
      pricePerShareInBaseUnits,
      pricePerSharePrecision,
    });
    handleUpdate();
  };

  return (
    <>
      {!isEditing && secondary.pricePerShareInBaseUnits && (
        <div className="d-flex align-items-center">
          <h4 className="mb-0 me-2">
            {formatPrice(
              secondary.pricePerShareInBaseUnits,
              secondary.pricePerSharePrecision
            )}
          </h4>
          <MSButton onClick={() => setIsEditing(true)} variant="icon">
            <FontAwesomeIcon icon={regular("edit")} size="xs" />
          </MSButton>
        </div>
      )}
      {(!secondary.pricePerShareInBaseUnits || isEditing) && (
        <Formik
          initialValues={{
            pricePerShare: secondary?.pricePerShareInBaseUnits
              ? secondary.pricePerShareInBaseUnits /
                secondary.pricePerSharePrecision
              : "",
          }}
          onSubmit={async (values) => {
            const [pricePerShareInBaseUnits, pricePerSharePrecision] =
              calcPrice(values.pricePerShare);
            setIsUpdatingSecondary(true);
            await updateSecondary(
              pricePerShareInBaseUnits,
              pricePerSharePrecision
            );
            setIsUpdatingSecondary(false);
            setIsEditing(false);
          }}
        >
          {(formik) => (
            <Form className="d-flex align-items-end justify-content-between">
              <NumberFormat
                name="pricePerShare"
                customInput={MSField}
                isNumericString={true}
                thousandSeparator={true}
                defaultValue={formik.values.pricePerShare}
                allowNegative={false}
                onValueChange={(val) =>
                  formik.setFieldValue("pricePerShare", val.floatValue)
                }
                formik={formik}
                inputProps={{
                  style: { textAlign: "right" },
                  "data-lpignore": "true",
                }}
                InputProps={{
                  startAdornment: (
                    <InputAdornment
                      position="start"
                      style={{ outline: "none" }}
                    >
                      $
                    </InputAdornment>
                  ),
                  sx: {
                    "&::after": {
                      borderColor: "black",
                    },
                  },
                }}
              />
              <MSButton
                type="submit"
                disabled={!formik.dirty || isUpdatingSecondary}
                style={{ marginLeft: "auto", marginRight: 20, maxWidth: 240 }}
              >
                Update price
              </MSButton>
              <MSButton onClick={() => setIsEditing(false)}>Cancel</MSButton>
            </Form>
          )}
        </Formik>
      )}
    </>
  );
}
