import {
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
} from "@mui/material";

export default function FundraisingTable({ fundraises }) {
  return (
    <>
      <div style={{ overflowX: "auto" }}>
        <TableContainer
          style={{
            border: "1px solid var(--purple-light)",
            borderRadius: 8,
          }}
        >
          <Table aria-label="simple table" style={{ whiteSpace: "nowrap" }}>
            <TableHead>
              <TableRow>
                <TableCell>Date</TableCell>
                <TableCell align="right">Round</TableCell>
                {/* <TableCell align="right">Amount Raised</TableCell> */}
                <TableCell align="right">Price Per Share</TableCell>
                <TableCell align="right">Post Money Valuation</TableCell>
              </TableRow>
            </TableHead>
            <TableBody>
              {fundraises.map((f, i) => {
                return (
                  <TableRow key={i}>
                    <TableCell>{f.funding_date}</TableCell>

                    <TableCell align="right">{f.share_type}</TableCell>
                    {/* <TableCell align="right"> */}
                    {/*   {f.humanized_money_raised_dollars} */}
                    {/* </TableCell> */}
                    <TableCell align="right">
                      {f.humanized_share_price_dollars}
                    </TableCell>
                    <TableCell align="right">
                      {f.humanized_implied_valuation_dollars}
                    </TableCell>
                  </TableRow>
                );
              })}
            </TableBody>
          </Table>
        </TableContainer>
        <p className="smaller px-3 my-3">
          * For SAFE financing rounds, the price is based on the pre-money
          valuation cap of the SAFE
        </p>
      </div>
    </>
  );
}
