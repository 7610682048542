import { Skeleton } from "@mui/material";
import { applyActionCode } from "firebase/auth";
import { getAuth } from "firebase/auth";
import React, { useEffect, useState } from "react";

function VerifyEmailConfirmation({ oobCode }) {
  const auth = getAuth();
  const [error, setError] = useState(false);
  const [loading, setLoading] = useState(true);

  const [success, setSuccess] = useState(false);

  useEffect(() => {
    const actionCode = oobCode;

    // Verify the password reset code is valid.
    applyActionCode(auth, actionCode)
      .then(async (resp) => {
        setSuccess(true);
        setError(false);
        setLoading(false);
        await getAuth().currentUser.reload();
      })
      .catch((error) => {
        if (error) {
          setError("Invalid link");
        }
        setLoading(false);
      });
  }, []);

  return (
    <>
      <div className="authincation section-padding">
        <div className="container h-100">
          <div className="row justify-content-center h-100 align-items-center">
            <div className="col-xl-5 col-md-6">
              <div className="card outlined-small">
                {loading && (
                  <div className="card-header justify-content-center">
                    <div className="card-body py-4">
                      <Skeleton height={50} />
                      <Skeleton height={50} />
                    </div>
                  </div>
                )}
                {error && !success && (
                  <>
                    <div className="card-header justify-content-center">
                      <h4 className="card-title">Invalid verification link</h4>
                    </div>
                    <div className="card-body py-4">
                      <p className="text-center">
                        The link you used is either expired or invalid. Please
                        send a new verification link.
                      </p>
                    </div>
                  </>
                )}
                {success && (
                  <>
                    <div className="card-header justify-content-center">
                      <h4 className="card-title">Email address confirmed</h4>
                    </div>
                    <div className="card-body py-4">
                      <p className="text-center">
                        Your email address has been confirmed.
                      </p>
                    </div>
                  </>
                )}
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
}

export default VerifyEmailConfirmation;
