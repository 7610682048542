import "react-dates/initialize";
import "react-dates/lib/css/_datepicker.css";

import Dialog from "@mui/material/Dialog";
import DialogActions from "@mui/material/DialogActions";
import Skeleton from "@mui/material/Skeleton";
import Tooltip from "@mui/material/Tooltip";
import {
  collection,
  doc,
  getDocs,
  getFirestore,
  onSnapshot,
} from "firebase/firestore";
import { getBaseUrl } from "jsx/api";
import { post } from "jsx/api";
import { UserContext } from "jsx/contexts/user-context";
import MSButton from "jsx/element/button";
import DocumentRow from "jsx/element/document-row";
import Header4 from "jsx/layout/header4";
import moment from "moment";
import React, { useEffect, useRef, useState } from "react";
import { useParams } from "react-router-dom";
import { Link } from "react-router-dom";

const loading = (
  <>
    <Skeleton height={80} />
    <Skeleton height={50} />
    <Skeleton height={50} />
    <Skeleton height={130} />
    <Skeleton height={50} />
    <Skeleton height={50} />
  </>
);

function CompanyFundraiseInvestment() {
  const { investmentId, roundId } = useParams();
  const { selectedOrganization } = React.useContext(UserContext);

  const db = getFirestore();

  const [document, setDocument] = useState({});
  const [documentId, setDocumentId] = useState();
  const [isTooltipOpen, setIsTooltipOpen] = useState(false);
  const [isResendingInvite, setIsResendingInvite] = useState(false);
  const [isVoidingInvestment, setIsVoidingInvestment] = useState(false);

  const [isInviteResent, setIsInviteResent] = useState(false);
  const [voidDialogOpen, setVoidDialogOpen] = React.useState(false);

  const [investment, setInvestment] = useState({});
  const copyTextInput = useRef(null);
  const copyTooltipTarget = useRef(null);

  async function loadDocument() {
    if (!selectedOrganization) {
      return;
    }
    try {
      onSnapshot(
        doc(
          db,
          `organizations/${selectedOrganization}/organizations_admin/data/investments`,
          investmentId
        ),
        (doc) => {
          const source = doc.metadata.hasPendingWrites ? "Local" : "Server";
          if (source === "Server") {
            setInvestment(doc.data());
            getDocToSign();
          }
        }
      );
    } catch (e) {
      console.log("error", e);
    }
  }

  function copyLink() {
    setIsTooltipOpen(true);
    setTimeout(() => {
      setIsTooltipOpen(false);
    }, 2000);
    /* Select the text field */
    copyTextInput.current.select();
    copyTextInput.current.setSelectionRange(0, 99999); /* For mobile devices */

    /* Copy the text inside the text field */
    navigator.clipboard.writeText(copyTextInput.current.value);
  }

  async function getDocToSign() {
    const querySnapshot = await getDocs(
      collection(
        db,
        `organizations/${selectedOrganization}/organizations_admin/data/investments/${investmentId}/documents`
      )
    );
    const documentRef = querySnapshot.docs[0];
    const documentData = documentRef.data();

    setDocument(documentData);
    setDocumentId(documentRef.id);
  }

  useEffect(() => {
    loadDocument();
  }, [selectedOrganization]);

  useEffect(() => {
    // dynamically assign the width and height to canvas
  }, []);

  async function voidInvestment() {
    setIsVoidingInvestment(true);
    try {
      await post("voidInvestment", {
        organizationId: selectedOrganization,
        investmentId: investmentId,
      });
      setIsVoidingInvestment(false);
    } catch (e) {
      setIsVoidingInvestment(false);
    }
  }

  async function resendInvite() {
    setIsResendingInvite(true);
    try {
      await post("resendInvite", {
        organizationId: selectedOrganization,
        investmentId: investmentId,
      });
      setIsResendingInvite(false);
      setIsInviteResent(true);
    } catch (e) {
      setIsResendingInvite(false);
    }
  }

  let investmentDateAgo;
  if (investment && investment.status === "invited") {
    investmentDateAgo =
      "Invite sent " +
      moment(new Date(investment.updatedDate.seconds * 1000)).fromNow();
  }

  return (
    <>
      <Header4 hideAllMenuItems title="Sign document" />
      <div className="content-body section-padding">
        <div className="container">
          <div className="row justify-content-center align-items-center">
            <div className="col-xl-10">
              <div className="card outlined">
                {!document.name && loading}
                {document.name && (
                  <>
                    <div className="card-header border-0">
                      <div>
                        <h4 className="card-title mb-3">Manage investment</h4>
                      </div>
                    </div>
                    <div className="card-body">
                      <div className="row">
                        <h4 className="form-section-header mt-0">Terms</h4>
                        <ul className="investment-overview ms-4 mt-4 mb-3">
                          {investment && (
                            <>
                              <li>
                                <strong>Amount</strong> $
                                {investment.investmentAmount
                                  ? investment.investmentAmount.toLocaleString()
                                  : ""}
                              </li>
                              <li>
                                <strong>Discount</strong>{" "}
                                {investment.discount ?? 0}%
                              </li>
                              <li>
                                <strong>Status</strong> {investment.status}
                              </li>

                              {/* <li><strong>Clauses</strong> Most Favored Nation</li> */}
                            </>
                          )}
                        </ul>
                        <h4 className="form-section-header mt-0">Investor</h4>
                        <ul className="fundraising-rounds mt-4">
                          <div className="outlined-small">
                            <div className="d-flex">
                              <div className="flex-grow-1">
                                {investment && investment.createdDate && (
                                  <>
                                    <h5 className="mt-0 mb-2">
                                      {investment.investorName ??
                                        investment.investorEmail}
                                    </h5>
                                    <p className="small">{investmentDateAgo}</p>
                                  </>
                                )}
                              </div>
                              <div className="edit-option">
                                <MSButton
                                  title="Resend"
                                  size="small"
                                  className="me-3"
                                  onClick={resendInvite}
                                  loading={isResendingInvite}
                                  isSuccess={isInviteResent}
                                >
                                  Resend
                                </MSButton>
                                <Tooltip
                                  title="Saved link to clipboard"
                                  open={isTooltipOpen}
                                  disableFocusListener
                                  disableHoverListener
                                  disableTouchListener
                                >
                                  <MSButton
                                    ref={copyTooltipTarget}
                                    onClick={copyLink}
                                    size="small"
                                  >
                                    Copy invite link
                                  </MSButton>
                                </Tooltip>
                                <input
                                  hidden
                                  ref={copyTextInput}
                                  className="form-control w-100"
                                  value={`${getBaseUrl()}/org/${selectedOrganization}/invite/${roundId}/${investmentId}?accessToken=${
                                    investment.accessToken
                                  }`}
                                />
                              </div>
                            </div>
                          </div>
                        </ul>
                        <h4 className="form-section-header mt-4">Documents</h4>
                        <ul className="fundraising-rounds mt-4">
                          {document && (
                            <DocumentRow
                              documentId={documentId}
                              document={document}
                              investmentId={investmentId}
                              organizationId={selectedOrganization}
                              rightButton={
                                <Link
                                  to={`/org/${selectedOrganization}/investment/${investmentId}/sign`}
                                >
                                  <MSButton
                                    size="small"
                                    disabled={
                                      investment.status !== "investor_signed"
                                        ? true
                                        : undefined
                                    }
                                  >
                                    Sign
                                  </MSButton>
                                </Link>
                              }
                            />
                          )}
                        </ul>

                        <h4 className="form-section-header mt-4">Admin</h4>
                        <ul className="fundraising-rounds mt-4">
                          <div className="outlined-small">
                            <div className="d-flex">
                              <div className="flex-grow-1">
                                <h5 className="mt-0 mb-2">Void investment</h5>
                                <p className="small">
                                  {investment.status === "voided"
                                    ? "The investment has been voided and can no longer be signed by either party."
                                    : "Voids the investment and prevents the investor from being able to sign. This can only be done if the investment isn't already countersigned."}
                                </p>
                              </div>
                              <div className="edit-option">
                                <MSButton
                                  title={
                                    investment.status === "voided"
                                      ? "Voided"
                                      : "Void"
                                  }
                                  size="small"
                                  onClick={() => {
                                    setVoidDialogOpen(true);
                                  }}
                                  disabled={
                                    investment.status === "voided"
                                      ? true
                                      : undefined
                                  }
                                  loading={isVoidingInvestment}
                                />
                                <Dialog
                                  open={voidDialogOpen}
                                  onClose={() => {
                                    setVoidDialogOpen(false);
                                  }}
                                  aria-labelledby="alert-dialog-title"
                                  aria-describedby="alert-dialog-description"
                                >
                                  <div className="p-4">
                                    <h3>Void this investment?</h3>
                                    <p className="mb-0">
                                      Voiding the investment prevents anyone
                                      from signing this investment in the
                                      future. It is irreversible.
                                    </p>
                                  </div>

                                  <DialogActions className="me-3 mb-3">
                                    <MSButton
                                      title="Cancel"
                                      type="light small"
                                      onClick={() => {
                                        setVoidDialogOpen(false);
                                      }}
                                    >
                                      Disagree
                                    </MSButton>
                                    <MSButton
                                      title="Void"
                                      type="light small danger"
                                      onClick={() => {
                                        setVoidDialogOpen(false);
                                        voidInvestment();
                                      }}
                                      autoFocus
                                    >
                                      Agree
                                    </MSButton>
                                  </DialogActions>
                                </Dialog>
                              </div>
                            </div>
                          </div>
                        </ul>
                      </div>
                    </div>
                  </>
                )}
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
}

export default CompanyFundraiseInvestment;
