import Header6 from "jsx/layout/header4";
import CompanyInfoForm from "jsx/pages/company/company-info-form";
import React from "react";

function CompanySetupInfo(props) {
  return (
    <>
      <Header6 hideAllMenuItems title="Setup company" />
      <div className="content-body section-padding">
        <div className="container mb-5">
          <div className="row justify-content-center align-items-center">
            <div className="col-xl-8 col-md-8">
              <CompanyInfoForm createNewOrganization={true} />
            </div>
          </div>
        </div>
      </div>
    </>
  );
}

export default CompanySetupInfo;
