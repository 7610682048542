import { regular } from "@fortawesome/fontawesome-svg-core/import.macro";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { Box } from "@mui/material";
import { DataGridPro } from "@mui/x-data-grid-pro";
import { detailedStatus, titleCase } from "jsx/helpers";
import React from "react";
import { useHistory } from "react-router-dom";

export default function InvestorSecondaryTransactionTable({
  transactions,
  secondaryId,
  organizationId,
}) {
  const history = useHistory();

  const columns = [
    {
      field: "seller",
      headerName: "Seller",
      flex: 140,
      renderCell: (cellValues) => {
        return (
          <div style={{ width: "100%", display: "flex" }}>
            <div style={{ flexShrink: 1 }}>{cellValues.formattedValue}</div>
            <div style={{ width: "30px" }}>
              <FontAwesomeIcon
                icon={regular("arrow-right")}
                style={{ marginLeft: 8, float: "right", marginTop: 3 }}
              />
            </div>
          </div>
        );
      },
    },
    {
      field: "buyer",
      headerName: "Buyer",
      flex: 140,
    },
    {
      field: "certificateNo",
      headerName: "Security",
      flex: 80,
      valueFormatter: ({ value }) => value ?? "-",
    },
    {
      field: "pricePerShare",
      headerName: "Price",
      flex: 80,
      type: "number",
    },
    {
      field: "quantity",
      headerName: "Quantity",
      type: "number",
      flex: 80,
      valueFormatter: ({ value }) => value && value.toLocaleString(),
    },
    {
      field: "total",
      headerName: "Total",
      type: "number",
      flex: 80,
    },
    {
      field: "status",
      headerName: "Status",
      flex: 120,
      valueFormatter: ({ value }) => value && titleCase(value),
      renderCell: (cellValues) => {
        return <div className="pill py-1">{cellValues.formattedValue}</div>;
      },
    },
  ];

  const transactionRows = transactions.map((transaction, i) => {
    return {
      id: transaction.id,
      buyer: transaction.buyerName,
      seller: transaction.sellerName,
      certificateNo: transaction.securityDetails.certificateNo,
      quantity: transaction.quantity,
      status: detailedStatus(transaction),
      total: (
        (transaction.pricePerShareInBaseUnits /
          transaction.pricePerSharePrecision) *
        transaction.quantity
      ).toLocaleString(undefined, {
        style: "currency",
        currency: "USD",
      }),
      pricePerShare: (
        transaction.pricePerShareInBaseUnits /
        transaction.pricePerSharePrecision
      ).toLocaleString(undefined, {
        style: "currency",
        currency: "USD",
      }),
    };
  });

  return (
    <div>
      <div style={{ width: "100%" }}>
        <DataGridPro
          rows={transactionRows}
          columns={columns}
          pageSize={100}
          disableColumnMenu
          hideFooter={true}
          autoHeight={true}
          onCellClick={(param) => {
            history.push(
              `/investor/secondaries/${organizationId}/${secondaryId}/transactions/${param.id}`
            );
          }}
          sx={{
            borderColor: "var(--purple-light)",

            "& .MuiDataGrid-cell": {
              borderBottomColor: "var(--purple-light)",
              fontFamily: "var(--primary-font)",
              fontSize: 14,
              fontWeight: 400,
            },
            "& .MuiDataGrid-columnHeaderTitle": {
              fontSize: 13,
              fontFamily: "var(--primary-font)",
              color: "#6c757d",
            },
            "& .MuiDataGrid-columnHeaders": {
              borderBottomColor: "var(--purple-light)",
            },
            "& .MuiDataGrid-row:hover": {
              // background: "#FFF",
              background: "var(--background)",
              cursor: "pointer",
            },
            "& .MuiDataGrid-columnSeparator": {
              visibility: "hidden",
            },
            "& .MuiDataGrid-cell:focus": {
              outline: "none",
            },
            "& .MuiDataGrid-columnHeader:focus": {
              outline: "none",
            },
            "& .MuiDataGrid-columnHeader:focus-within": {
              outline: "none",
            },
            "& .MuiDataGrid-cell:focus-within": {
              outline: "none",
            },
          }}
        />
      </div>
    </div>
  );
}
