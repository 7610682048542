import { Field, Form, Formik } from "formik";
import SettingsNav from "jsx/element/fundraise-create-nav";
import PageTitle from "jsx/element/page-title";
import Header4 from "jsx/layout/header4";
import React from "react";

function RisksAndDisclosures() {
  return (
    <>
      <Header4 />
      <PageTitle />
      <Formik
        initialValues={{ text: "", other: "" }}
        onSubmit={async (values) => {
          await new Promise((resolve) => setTimeout(resolve, 500));
          alert(JSON.stringify(values, null, 2));
        }}
      >
        <Form>
          <div className="content-body">
            <div className="container-fluid">
              <div className="row">
                <div className="col-md-2">
                  <SettingsNav />
                </div>
                <div className="col-xl-9 col-md-8">
                  <div className="row">
                    <div className="col-xl-6">
                      <div className="card">
                        <div className="card-header">
                          <h4 className="card-title">Risks & Disclosures</h4>
                        </div>
                        <div className="card-body">
                          <div className="row">
                            <div className="mb-4 col-xl-12">
                              <h5 className="mb-2 mt-3">Risks</h5>
                              <Field name="text">
                                {({ field, form, meta }) => {
                                  return (
                                    <textarea
                                      style={{ height: 240 }}
                                      className="form-control"
                                      {...field}
                                    />
                                  );
                                }}
                              </Field>
                            </div>
                            <div className="mb-4 col-xl-12">
                              <h5 className="mb-2 mt-3">Other Disclosures</h5>
                              <Field name="other">
                                {({ field, form, meta }) => {
                                  return (
                                    <textarea
                                      style={{ height: 240 }}
                                      className="form-control"
                                      {...field}
                                    />
                                  );
                                }}
                              </Field>
                            </div>
                          </div>
                        </div>
                        <button type="submit" className="btn">
                          Next
                        </button>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </Form>
      </Formik>
    </>
  );
}

export default RisksAndDisclosures;
