import React from "react";
function FormError({ show, message }) {
  return (
    <>
      {show && (
        <p style={{ marginBottom: -16 }} className="text-danger mt-2">
          {message}
        </p>
      )}
    </>
  );
}

export default FormError;
