import React, { useEffect } from "react";
import { Link, Route, useHistory, useRouteMatch } from "react-router-dom";
import { useLocation } from "react-router-dom";

import EditNote from "./convertible-edit-note";
function useQuery() {
  return new URLSearchParams(useLocation().search);
}
function ConvertibleUsers({ data, onEdit, onRemove, emptyListVal }) {
  const { path, url } = useRouteMatch();
  const history = useHistory();
  const query = useQuery();
  const isIdValid =
    data.filter((item) => item.id === parseInt(query.get("id")))[0] !==
    undefined;
  useEffect(() => {
    if (emptyListVal) {
      history.push(`/captable/convertible-notes/`);
    }
  }, []);
  const handleEdit = (data) => {
    onEdit(parseInt(query.get("id")), data);
    history.push(`/captable/convertible-notes/`);
  };
  const handleRemove = () => {
    onRemove(parseInt(query.get("id")));
    history.push(`/captable/convertible-notes/`);
  };
  return (
    <>
      <div className="mb-3 col-xl-12">
        <Route exact path={`${url}`}>
          <div className="card-header">
            <h4 className="card-title">View your convertible notes</h4>
            <Link
              to={`/captable/convertible-notes/add`}
              className="add-floating-button"
            >
              Add more
            </Link>
          </div>
          {data.map((item) => {
            return (
              <>
                <Item key={item.id} id={item.id} data={item} />
              </>
            );
          })}
        </Route>
        <Route exact path={`${path}/edit`}>
          <div className="card-header">
            <h4 className="card-title">Edit convertible note</h4>
            <Link
              to={`/captable/convertible-notes/add`}
              className="add-floating-button"
            >
              Add more
            </Link>
          </div>
          {isIdValid ? (
            <EditNote
              data={
                data.filter((item) => item.id === parseInt(query.get("id")))[0]
              }
              onEdit={handleEdit}
              onRemove={handleRemove}
            />
          ) : (
            "Not found :/"
          )}
        </Route>
      </div>
    </>
  );
}
function Item({ id, data }) {
  const { path } = useRouteMatch();
  return (
    <div className="mb-2 card-body">
      <p>
        Note holder name:{" "}
        {data.stackHolderType === "individual"
          ? `${data.firstName} ${data.lastName}`
          : data.organizationName}
      </p>
      <p>Principal: {data.principal.length > 0 ? data.principal : "-"}</p>
      <p>
        Valuation cap: {data.valuationCap.length > 0 ? data.valuationCap : "-"}
      </p>
      <p>
        Discount:{" "}
        {data.conversionDiscount > 0 ? data.conversionDiscount + "%" : "-"}
      </p>
      <p>Issue date: {data.issueDate.length > 0 ? data.issueDate : "-"}</p>
      <Link to={`${path}/edit?id=${id}`}>
        <small className="text-primary">Edit</small>
      </Link>
    </div>
  );
}
export default ConvertibleUsers;
