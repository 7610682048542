import { regular } from "@fortawesome/fontawesome-svg-core/import.macro";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import Menu from "@mui/material/Menu";
import MenuItem from "@mui/material/MenuItem";
import { alpha, styled } from "@mui/material/styles";
import * as React from "react";
import { Children, cloneElement, useState } from "react";

import MSButton from "./button";

const Dropdown = styled(({ buttonProps, ...props }) => (
  // disableScrollLock to keep scrol on the right side
  // when opening the modal
  <Menu disableScrollLock={true} elevation={0} {...props} />
))(({ theme }) => ({
  "& .MuiPaper-root": {
    borderRadius: 6,
    marginTop: theme.spacing(1),
    minWidth: 180,
    color:
      theme.palette.mode === "light"
        ? "rgb(55, 65, 81)"
        : theme.palette.grey[300],
    boxShadow:
      "rgb(255, 255, 255) 0px 0px 0px 0px, " +
      "rgba(0, 0, 0, 0.05) 0px 0px 0px 1px, " +
      "rgba(0, 0, 0, 0.1) 0px 10px 15px -3px, " +
      "rgba(0, 0, 0, 0.05) 0px 4px 6px -2px",
    "& .MuiMenu-list": {
      padding: "4px 0",
    },
    "& .MuiMenuItem-root": {
      "& .MuiSvgIcon-root": {
        fontSize: 18,
        color: theme.palette.text.secondary,
        marginRight: theme.spacing(1.5),
      },
      "&:active": {
        backgroundColor: alpha(
          theme.palette.primary.main,
          theme.palette.action.selectedOpacity
        ),
      },
    },
  },
}));

export const MSDropdownItem = styled(({ children, ...props }) => (
  <MenuItem
    disableRipple
    style={{
      padding: 8,
      margin: 8,
      marginBottom: 4,
      marginTop: 4,
      borderRadius: 4,
      fontSize: 14,
      fontFamily: "var(--primary-font)",
      paddingLeft: 16,
      paddingRight: 16,
      fontWeight: 500,
    }}
    {...props}
  >
    {children}
  </MenuItem>
))(({ theme }) => ({}));

export default function MSDropdown({
  children,
  title,
  icon,
  variant,
  size,
  disabled,
  startIcon,
  ...props
}) {
  const [anchorEl, setAnchorEl] = useState(null);
  const open = Boolean(anchorEl);
  const handleClick = (event) => {
    setAnchorEl(event?.currentTarget);
  };
  const handleClose = () => {
    setAnchorEl(null);
  };

  return (
    <>
      <MSButton
        endIcon={
          icon ?? <FontAwesomeIcon icon={regular("angle-down")} size="xs" />
        }
        title={title}
        onClick={handleClick}
        disabled={disabled}
        size={size}
        {...(props.buttonProps && props.buttonProps)}
      />
      <Dropdown
        open={open}
        anchorEl={anchorEl}
        onClose={handleClose}
        {...props}
      >
        {Children.map(children, (child) =>
          cloneElement(child, {
            onClick: (event) => {
              child.props.onClick && child.props.onClick(event);
              handleClose();
            },
          })
        )}
      </Dropdown>
    </>
  );
}
