import Skeleton from "@mui/material/Skeleton";
import { doc, getDoc, getFirestore } from "firebase/firestore";
import { UserContext } from "jsx/contexts/user-context";
import SignInForm from "jsx/element/signin-form";
import Header4 from "jsx/layout/header4";
import React, { useEffect, useState } from "react";
import { Link, useHistory } from "react-router-dom";
import { useParams } from "react-router-dom";
const queryString = require("query-string");

function SecondaryInvite(props) {
  const [organization, setOrganization] = useState({});
  const { organizationId, secondaryId } = useParams();

  const parsed = queryString.parse(props.location.search);

  const { user } = React.useContext(UserContext);

  const history = useHistory();

  const roundURL = `/org/${organizationId}/secondary/${secondaryId}`;

  let acceptSigninButton;

  const db = getFirestore();

  async function fetchOrganization() {
    const docRef = doc(db, "organizations", organizationId);
    const docSnap = await getDoc(docRef);
    if (docSnap.exists()) {
      setOrganization(docSnap.data());
    } else {
    }
  }

  useEffect(() => {
    fetchOrganization();
  }, []);

  useEffect(() => {
    // We don't redirect the user until we get the user context
    if (user.email) {
      history.push(roundURL);
    }
  }, [user.email]);

  const loading = (
    <div style={{ lineHeight: "30px" }}>
      <Skeleton height={80} />
      <Skeleton height={30} />
      <Skeleton height={30} />
      <Skeleton height={60} />
    </div>
  );

  if (user && user.email) {
    acceptSigninButton = (
      <div>
        <Link to={roundURL}>
          <button className="btn btn-big w-100">
            View Deal
            <i className="la la-arrow-right"></i>
          </button>
        </Link>
      </div>
    );
  } else {
    acceptSigninButton = (
      <div>
        <SignInForm
          isSignin={parsed.countersign}
          isInvite={true}
          type={"investor"}
        />
      </div>
    );
  }

  return (
    <>
      <Header4 hideBackButton hideAllMenuItems />

      <div className="authincation section-padding">
        <div className="container h-75">
          <div className="row justify-content-center h-75 align-items-center">
            <div className="col-xl-6 col-md-6">
              <div className="auth-form card text-center outlined">
                {!organization.companyName && loading}
                {organization.companyName && (
                  <>
                    <div className="card-body">
                      <div className="text-center">
                        <>
                          <p style={{ fontSize: 20, marginBottom: 38 }}>
                            You've been invited to invest in{" "}
                            <strong>{organization.companyName}</strong>'s
                            employee liquidity round.
                          </p>
                          <p>
                            Sign up with Google to see details of this round.
                          </p>
                        </>
                        {acceptSigninButton}
                      </div>
                    </div>
                  </>
                )}
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
}

export default SecondaryInvite;
