import React from "react";
import NumberFormat from "react-number-format";
function NumberFormatted(props) {
  return (
    <NumberFormat
      isNumericString={true}
      allowNegative={false}
      thousandSeparator={true}
      autoComplete="off"
      prefix={""}
      {...props}
    />
  );
}
export default NumberFormatted;
