import GoogleIcon from "@mui/icons-material/Google";
import {
  createUserWithEmailAndPassword,
  getAuth,
  GoogleAuthProvider,
  sendEmailVerification,
  signInWithEmailAndPassword,
  signInWithPopup,
} from "firebase/auth";
import { doc, getFirestore, setDoc } from "firebase/firestore";
import { Form, Formik } from "formik";
import MSButton from "jsx/element/button";
import React, { useState } from "react";
import { Link } from "react-router-dom";
import * as Yup from "yup";

import MSField from "./ms-field";

// Type defines what the user is trying to sign in as, "investor" or "company"
function SignInForm({ isSignin, redirectUrl, isInvite, type }) {
  const [errorMessage, setErrorMessage] = useState();
  const [signin, setIsSignIn] = useState(isSignin);

  const auth = getAuth();
  const provider = new GoogleAuthProvider();
  const [loading, setIsLoading] = useState(false);
  const [showSignUpWithEmail, setShowSignUpWithEmail] = useState(false);

  const SignUpSchema = Yup.object().shape({
    fullName: Yup.string().required("Required"),
    email: Yup.string().email("Invalid email").required("Required"),
    password: Yup.string().required("Required"),
  });

  const SignInSchema = Yup.object().shape({
    email: Yup.string().email("Invalid email").required("Required"),
    password: Yup.string().required("Required"),
  });

  const signInWithGoogle = (e) => {
    signInWithPopup(auth, provider)
      .then(async (result) => {})
      .catch((error) => {});
  };

  const db = getFirestore();

  function constructQueryParmas() {
    const searchParams = new URLSearchParams();
    if (redirectUrl) {
      searchParams.append("redirectUrl", redirectUrl);
    }

    if (type) {
      searchParams.append("type", type);
    }
    return searchParams.toString();
  }

  function signUpWithEmailSection(formik) {
    return (
      <>
        <div className="text-start">
          <Form>
            {!signin && <MSField type="text" name="fullName" formik={formik} />}
            <MSField
              type="text"
              name="email"
              placeholder="email@domain.com"
              formik={formik}
            />

            <MSField
              type="password"
              name="password"
              placeholder="Password"
              formik={formik}
            />
            <p className="small mt-2 mb-0">
              <i className="la la-lock me-1" />
              Your information is protected using TLS 1.3, X25519, and 128 bit
              encryption.
            </p>

            <MSButton
              className="me-2 w-100 mt-3 mb-0"
              size="large"
              title={signin ? "Log In With Email" : "Sign Up With Email"}
              loadingLabel="Processing"
              variant="secondary"
              loading={loading}
              type="submit"
            />

            {signin && (
              <p className="mb-0 mt-3 small text-center">
                Don't have an account?{" "}
                <Link
                  to={"#"}
                  onClick={() => {
                    setIsSignIn(false);
                  }}
                >
                  <span style={{ textDecoration: "underline" }}>Sign up</span>
                </Link>
              </p>
            )}

            {!signin && (
              <p className="mb-0 mt-3 small text-center">
                Already have an account?{" "}
                <Link
                  to={"#"}
                  onClick={() => {
                    setIsSignIn(true);
                  }}
                >
                  <span style={{ textDecoration: "underline" }}>Log in</span>
                </Link>
              </p>
            )}

            {errorMessage && (
              <p className="mb-0 mt-3 small text-center is-invalid">
                {errorMessage}
              </p>
            )}

            <Link to={"/reset?" + constructQueryParmas()}>
              <p
                className="mb-0 mt-3 small text-center"
                style={{ textDecoration: "underline" }}
              >
                Forgot your password?
              </p>
            </Link>
          </Form>
        </div>
      </>
    );
  }

  return (
    <div className="text-center">
      <MSButton
        onClick={signInWithGoogle}
        className="w-100 my-3"
        startIcon={<GoogleIcon />}
        size="large"
        variant="primary"
        color="primary"
      >
        {signin ? "Log in with Google" : "Sign up with Google"}
      </MSButton>
      <div className="d-flex" style={{ marginTop: 16 }}>
        <div
          style={{ height: 1, flexGrow: 1, background: "var(--border-color)" }}
        />
        <div
          style={{
            marginLeft: 16,
            marginRight: 16,
            marginTop: -10,
            color: "#ccc",
            fontSize: 14,
          }}
        >
          OR
        </div>
        <div
          style={{ height: 1, flexGrow: 1, background: "var(--border-color)" }}
        />
      </div>
      <Formik
        initialValues={{ email: "", password: "" }}
        validationSchema={signin ? SignInSchema : SignUpSchema}
        onSubmit={async (values) => {
          const auth = getAuth();
          const email = values.email;
          const password = values.password;
          const fullName = values.fullName;

          setIsLoading(true);
          setErrorMessage();

          if (signin) {
            signInWithEmailAndPassword(auth, email, password)
              .then((userCredential) => {
                // Signed in
                // const user = userCredential.user;
                // ...
              })
              .catch((error) => {
                const errorCode = error.code;
                /* const errorMessage = error.message; */

                if (errorCode === "auth/user-not-found") {
                  setErrorMessage("No user with that email.");
                } else if (errorCode === "auth/wrong-password") {
                  setErrorMessage("Wrong password");
                }

                setIsLoading(false);
              });

            return;
          }

          createUserWithEmailAndPassword(auth, email, password)
            .then(async (userCredential) => {
              // Signed in
              // const user = userCredential.user;
              await setDoc(
                doc(db, "users", auth.currentUser.uid, "users_private", "data"),
                { displayName: fullName },
                { merge: true }
              );
              await sendEmailVerification(auth.currentUser).then(() => {});
            })
            .catch((error) => {
              setIsLoading(false);
              const errorCode = error.code;
              /* const errorMessage = error.message; */
              if (error.code === "auth/email-already-in-use") {
                signInWithEmailAndPassword(auth, email, password)
                  .then((userCredential) => {
                    // Signed in
                    const user = userCredential.user;
                  })
                  .catch((error) => {
                    const errorCode = error.code;
                    /* const errorMessage = error.message; */

                    if (errorCode === "auth/wrong-password") {
                      setErrorMessage("Wrong password.");
                    }
                  });
              }
              if (errorCode === "auth/weak-password") {
                setErrorMessage("Password is too weak.");
              }
              console.log(error.code);
            });
        }}
      >
        {(formik) => (
          <>
            {!showSignUpWithEmail && isInvite && (
              <div
                className="mt-1 small text-center"
                style={{
                  cursor: "pointer",
                  textDecoration: "underline",
                  marginBottom: -16,
                }}
                onClick={() => {
                  setShowSignUpWithEmail(true);
                }}
              >
                {signin && "Or log in with email"}
                {!signin && "Or sign up with email"}
              </div>
            )}
            {(showSignUpWithEmail || !isInvite) &&
              signUpWithEmailSection(formik)}
          </>
        )}
      </Formik>
    </div>
  );
}

export default SignInForm;
