import { Form, Formik } from "formik";
import React, { useEffect, useState } from "react";
import Form2 from "react-bootstrap/Form";
function IncorporationDocuments({ onReturn }) {
  const [isError, setError] = useState(false);
  const [dataList, setList] = useState([
    { id: 1, file: null, date: new Date() },
  ]);
  // console.log(dataList)
  const addHandler = () => {
    const defaultObject = {
      id: dataList.length + 1,
      file: null,
      date: new Date(),
    };
    setList((prev) => [...prev, defaultObject]);
  };
  const onEdit = (id, { date, file }) => {
    setList((prev) =>
      prev.map((item) => {
        if (item.id === id) {
          item.file = file;
          item.date = date;
        }
        return item;
      })
    );
  };
  const onRemove = (id) => {
    id > 1 && setList((prev) => prev.filter((item) => item.id !== id));
  };
  const onSubmit = () => {
    dataList.forEach((item) => {
      if (
        item.file !== null &&
        item.file !== undefined &&
        item.date !== null &&
        item.date.length > 0
      ) {
        onReturn(2, dataList);
      } else {
        setError(true);
      }
    });
  };
  return (
    <Formik
      initialValues={{}}
      // onSubmit={async (values) => {
      //     await new Promise((resolve) => setTimeout(resolve, 500));
      // }}
    >
      <Form>
        <div className="card-header">
          <h4 className="card-title">Upload all incorporation documents</h4>
          <button onClick={addHandler} className="add-floating-button">
            Add more
          </button>
        </div>
        <div className="card-body">
          {dataList.map((item) => {
            return (
              <Item
                id={item.id}
                isError={isError}
                onRemove={onRemove}
                onReturn={onEdit}
                defFileName={
                  item.file !== null && item.file !== undefined
                    ? item.file.name
                    : ""
                }
                defDate={item.date}
              />
            );
          })}
        </div>
        <button onClick={onSubmit}>Submit</button>
      </Form>
    </Formik>
  );
}

function Item({ id, isError, onRemove, onReturn, defFileName, defDate }) {
  const [date, setDate] = useState("");
  const [file, setFile] = useState(null);
  useEffect(() => {
    /**Listen for changes */
    if (file !== null) {
      onReturn(id, { date: date, file: file });
    }
  }, [date, file]);

  return (
    <div className="row incorporation-box mt-0 mb-4">
      <div className="col">
        <small>File upload</small>
        <p className="mb-0">
          Drop or choose a{" "}
          <label className="file-picker-main" for={`upload-photo${id}`}>
            different PDF, JPEG, or PNG file
          </label>
        </p>
        <label>
          {defFileName.length === 0 ? "No file selected" : defFileName}
        </label>
        <div>
          {isError && defFileName.length === 0 && (
            <small className="text-danger">Required</small>
          )}
        </div>
        <input
          type="file"
          id={`upload-photo${id}`}
          className="custom-file-picker"
          accept="image/png, image/jpeg"
          onChange={(e) => setFile(e.target.files[0])}
        />
      </div>
      <div className="col-4">
        <small>Effective date</small>
        <Form2.Control
          type="date"
          name="date_of_birth"
          className={"form-control " + (isError && !date && "is-invalid")}
          value={date}
          defaultValue={defDate}
          onChange={(e) => setDate(e.target.value)}
        />
      </div>
      {id > 1 && <small onClick={() => onRemove(id)}>Remove</small>}
    </div>
  );
}

export default IncorporationDocuments;
