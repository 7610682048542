import { MenuItem } from "@mui/material";
import { doc, getDoc, getFirestore, updateDoc } from "firebase/firestore";
import { UserContext } from "jsx/contexts/user-context";
import states from "jsx/data/states.json";
import MSButton from "jsx/element/button";
import FormError from "jsx/element/form-error";
import MSField from "jsx/element/ms-field";
import React, { useEffect, useState } from "react";
import * as Yup from "yup";

const { Form, Field, Formik } = require("formik");

const LegalSchema = Yup.object().shape({
  name: Yup.string().required("Enter the name of the security holder"),

  streetAddress: Yup.string().when("foreign", {
    is: false,
    then: Yup.string().required("Required"),
  }),

  city: Yup.string().when("foreign", {
    is: false,
    then: Yup.string().required("Required"),
  }),
  state: Yup.string().when("foreign", {
    is: false,
    then: Yup.string().required("Required"),
  }),
  zipcode: Yup.string().when("foreign", {
    is: false,
    then: Yup.string().required("Required"),
  }),

  addressLine1: Yup.string().when("foreign", {
    is: true,
    then: Yup.string().required("Required"),
  }),
  addressLine2: Yup.string().when("foreign", {
    is: true,
    then: Yup.string().required("Required"),
  }),

  taxDocument: Yup.string().required("Required"),
  maritalStatus: Yup.string().required("Required"),

  spouseName: Yup.string().when("maritalStatus", {
    is: "Spouse",
    then: Yup.string().required("Required"),
  }),

  spouseEmail: Yup.string().when("maritalStatus", {
    is: "Spouse",
    then: Yup.string().required("Required"),
  }),
});

export function SellerLegalInformationForm({ onSubmit }) {
  const { user } = React.useContext(UserContext);
  const [personal, setPersonal] = useState({});
  const [address, setAddress] = useState({});
  const db = getFirestore();

  useEffect(() => {
    async function getUserData(userId) {
      const userSnap = await getDoc(
        doc(db, "users", user.uid, "users_private", "data")
      );
      const userData = userSnap.data();
      setPersonal(userData.personal ?? {});
      setAddress(userData.address ?? {});
    }
    if (user.uid) {
      getUserData(user.uid);
    }
  }, [user.uid]);

  async function updateUserData({ personal, address, name }) {
    await updateDoc(doc(db, "users", user.uid, "users_private", "data"), {
      displayName: name,
      personal,
      address,
    });
  }
  return (
    <Formik
      enableReinitialize
      initialValues={{
        foreign: false,
        name: user.displayName,
        streetAddress: address.address1,
        addressLine1: address.address1,
        addressLine2: address.address2,
        zipcode: address.zipcode,
        city: address.city,
        state: address.state,
        taxDocument: personal.taxDocument,
        maritalStatus: personal.maritalStatus,
        spouseName: personal.spouseName ?? "",
        spouseEmail: personal.spouseEmail ?? "",
      }}
      validationSchema={LegalSchema}
      onSubmit={async (values) => {
        await updateUserData({
          name: values.name,
          address: {
            address1: values.foreign
              ? values.addressLine1
              : values.streetAddress,
            ...(values.foreign && { address2: values.addressLine2 }),
            ...(!values.foreign && {
              city: values.city,
              state: values.state,
              zipcode: values.zipcode,
              country: "USA",
            }),
          },
          personal: {
            maritalStatus: values.maritalStatus ?? null,
            taxDocument: values.taxDocument,
            spouseName: values.spouseName ?? null,
            spouseEmail: values.spouseEmail ?? null,
          },
        });
        onSubmit();
        return;
      }}
    >
      {(formik) => {
        const { values, isSubmitting, errors, initialValues } = formik;

        return (
          <Form>
            <div className="card-body">
              <div className="row">
                {/* Review section */}

                <div className="mb-0 col-xl-12">
                  <h4 style={{ marginTop: 32, marginBottom: -8 }}>
                    Name of security holder
                  </h4>

                  <MSField
                    type="text"
                    name="name"
                    formik={formik}
                    placeholder="Firstname Lastname"
                    autoComplete="off"
                    autoCorrect="off"
                    autoCapitalize="off"
                    spellCheck="false"
                    defaultValue={initialValues.name ?? ""}
                  />
                </div>

                <h4 className="form-section-header mt-6">Address block</h4>

                <div className="mb-0 col-xl-12">
                  <div className="form-check mt-4 mb-0">
                    <label>
                      <Field
                        className="form-check-input"
                        type="checkbox"
                        name="foreign"
                      />
                      I am a non-US based.
                    </label>
                  </div>
                </div>

                {!values.foreign && (
                  <>
                    <div className="mb-0 col-xl-12">
                      <MSField
                        type="text"
                        name="streetAddress"
                        formik={formik}
                        placeholder="123 Main Street"
                        autoComplete="off"
                        autoCorrect="off"
                        autoCapitalize="off"
                        spellCheck="false"
                        defaultValue={initialValues.streetAddress ?? ""}
                      />
                    </div>

                    <div className="mb-0 col-xs-2 col-sm-5">
                      <MSField
                        type="text"
                        name="city"
                        formik={formik}
                        placeholder="City"
                        autoCorrect="off"
                        autoComplete="off"
                        spellCheck="false"
                        defaultValue={initialValues.city ?? ""}
                      />
                    </div>
                    <div className="mb-0 col-xs-2 col-sm-3">
                      <MSField
                        type="text"
                        name="state"
                        select
                        placeholder="State"
                        formik={formik}
                        defaultValue={initialValues.state ?? ""}
                      >
                        {states.map((item) => {
                          return (
                            <MenuItem
                              key={item.abbreviation ?? ""}
                              value={item.abbreviation ?? ""}
                            >
                              {item.abbreviation}
                            </MenuItem>
                          );
                        })}
                      </MSField>
                    </div>
                    <div className="mb-0 col-xs-12 col-sm-4">
                      <MSField
                        type="text"
                        name="zipcode"
                        formik={formik}
                        placeholder="Zipcode"
                        defaultValue={initialValues.zipcode ?? ""}
                      />
                    </div>
                  </>
                )}

                {values.foreign && (
                  <>
                    <div className="mb-0 col-xl-12">
                      <MSField
                        type="text"
                        name="addressLine1"
                        formik={formik}
                        placeholder="Street Address"
                        autoComplete="off"
                        autoCorrect="off"
                        autoCapitalize="off"
                        spellCheck="false"
                        defaultValue={initialValues.addressLine1 ?? ""}
                      />
                    </div>
                    <div className="mb-0 col-xl-12">
                      <MSField
                        type="text"
                        name="addressLine2"
                        formik={formik}
                        placeholder="City, Postal, Country"
                        autoComplete="off"
                        autoCorrect="off"
                        autoCapitalize="off"
                        spellCheck="false"
                        defaultValue={initialValues.addressLine2 ?? ""}
                      />
                    </div>
                  </>
                )}
              </div>

              <div style={{ display: "block", marginTop: 48 }}>
                <h4>Tax Document</h4>
              </div>
              <FormError
                show={errors.taxDocument}
                message={errors.taxDocument}
              />

              <h5 className="form-label">
                Which type of tax document will you need to file your taxes?
              </h5>
              <div role="group" className="d-flex flex-column">
                <label className="m-2">
                  <Field
                    className="form-check-input me-2"
                    type="radio"
                    name="taxDocument"
                    value="W-9"
                  />
                  W-9
                </label>
                <div
                  style={{
                    fontSize: 14,
                    color: "#999",
                    marginLeft: 32,
                    marginBottom: 16,
                    marginTop: -8,
                  }}
                >
                  For US Citizens or US Resident Aliens
                </div>
                <label className="m-2">
                  <Field
                    className="form-check-input me-2"
                    type="radio"
                    name="taxDocument"
                    value="W-8BEN"
                  />
                  W-8BEN
                </label>
                <div
                  style={{
                    fontSize: 14,
                    color: "#999",
                    marginLeft: 32,
                    marginBottom: 16,
                    marginTop: -8,
                  }}
                >
                  For Individuals Who Are Neither US Citizens Nor US Resident
                  Aliens
                </div>
                <label className="m-2">
                  <Field
                    className="form-check-input me-2"
                    type="radio"
                    name="taxDocument"
                    value="W-8BEN-E"
                  />
                  W-8BEN-E
                </label>
                <div
                  style={{
                    fontSize: 14,
                    color: "#999",
                    marginLeft: 32,
                    marginTop: -8,
                  }}
                >
                  For Non-US Entities
                </div>
              </div>
              <div className="row"></div>
              <div style={{ display: "block", marginTop: 48 }}>
                <h4>Spouse Information</h4>
              </div>
              <FormError
                show={errors.maritalStatus}
                message={errors.maritalStatus}
              />
              <h5 className="form-label">
                Your spouse may be requested to sign documents to complete your
                order.
              </h5>
              <div role="group" className="d-flex flex-column">
                <label className="m-2">
                  <Field
                    className="form-check-input me-2"
                    type="radio"
                    name="maritalStatus"
                    value="No spouse"
                  />
                  I do not have a spouse
                </label>
                <label className="m-2">
                  <Field
                    className="form-check-input me-2"
                    type="radio"
                    name="maritalStatus"
                    value="Spouse"
                  />
                  I have a spouse
                </label>
              </div>

              {values.maritalStatus === "Spouse" && (
                <>
                  <div className="mb-0 col-xl-12">
                    <MSField
                      type="text"
                      name="spouseName"
                      formik={formik}
                      autoComplete="off"
                      autoCorrect="off"
                      autoCapitalize="off"
                      spellCheck="false"
                      placeholder="First Last"
                      defaultValue={initialValues.spouseName ?? ""}
                    />
                  </div>

                  <div className="mb-0 col-xl-12">
                    <MSField
                      type="text"
                      name="spouseEmail"
                      formik={formik}
                      autoComplete="off"
                      autoCorrect="off"
                      autoCapitalize="off"
                      spellCheck="false"
                      placeholder="test@email.com"
                      defaultValue={initialValues.spouseEmail ?? ""}
                    />
                  </div>
                </>
              )}
            </div>
            <MSButton
              style={{ float: "right" }}
              type="submit"
              loading={isSubmitting}
              title={"Next"}
              variant="primary"
            />
          </Form>
        );
      }}
    </Formik>
  );
}
