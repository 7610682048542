import Table from "@mui/material/Table";
import TableBody from "@mui/material/TableBody";
import TableCell from "@mui/material/TableCell";
import TableContainer from "@mui/material/TableContainer";
import TableHead from "@mui/material/TableHead";
import TableRow from "@mui/material/TableRow";

const signedPill = (
  <>
    <span className="pill px-3 py-2" style={{ backgroundColor: "var(--info)" }}>
      Signed
    </span>
  </>
);

const waitingPill = (
  <>
    <span className="pill px-3 py-2">Waiting for signature</span>
  </>
);

export default function SigningStatusSection({ transaction }) {
  return (
    <>
      <TableContainer
        style={{
          border: "1px solid var(--purple-light)",
          borderRadius: 8,
        }}
      >
        <Table aria-label="simple table">
          <TableHead>
            <TableRow>
              <TableCell>Name</TableCell>
              <TableCell align="center">Signing party</TableCell>
              <TableCell align="center">Status</TableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            <TableRow>
              <TableCell>
                <h6>{transaction.buyerName}</h6>
              </TableCell>

              <TableCell
                align="center"
                style={{ paddingBottom: "20px", paddingTop: "20px" }}
              >
                Buyer
              </TableCell>
              <TableCell align="center">
                {transaction.isSignedByBuyer ? signedPill : waitingPill}
              </TableCell>
            </TableRow>

            <TableRow>
              <TableCell>
                <h6>{transaction.sellerName}</h6>
              </TableCell>

              <TableCell
                align="center"
                style={{ paddingBottom: "20px", paddingTop: "20px" }}
              >
                Seller
              </TableCell>

              <TableCell align="center">
                {transaction.isSignedBySeller ? signedPill : waitingPill}
              </TableCell>
            </TableRow>

            <TableRow>
              <TableCell>
                <h6>{transaction.companyName}</h6>
              </TableCell>

              <TableCell
                align="center"
                style={{ paddingBottom: "20px", paddingTop: "20px" }}
              >
                Company
              </TableCell>

              <TableCell align="center">
                {transaction.isSignedByCompany ? signedPill : waitingPill}
              </TableCell>
            </TableRow>
          </TableBody>
        </Table>
      </TableContainer>
    </>
  );
}
