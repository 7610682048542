import Box from "@mui/material/Box";
import Button from "@mui/material/Button";
import Step from "@mui/material/Step";
import StepLabel from "@mui/material/StepLabel";
import Stepper from "@mui/material/Stepper";
import Typography from "@mui/material/Typography";
import MSButton from "jsx/element/button";
import { SellerLegalInformationForm } from "jsx/pages/seller/seller-legal-information";
import * as React from "react";

import { OrderPreview } from "./order-preview";
import { SellerTaxBankInfo } from "./seller-tax-bank-info";
import SellerTenderSign from "./seller-tender-sign";

const steps = [
  "Select securities",
  "Review order",
  "Confirm information",
  "Tax & banking details",
  "Signing",
];

export default function SellerLoTStepper({
  organizationId,
  secondaryId,
  cancelOrder,
  orderPreview,
  pricePerShare,
}) {
  const [activeStep, setActiveStep] = React.useState(1);
  const [skipped, setSkipped] = React.useState(new Set());
  const [showBack, setShowBack] = React.useState(true);

  const isStepOptional = (step) => {
    return step === 999;
  };

  const isStepSkipped = (step) => {
    return skipped.has(step);
  };

  const handleNext = () => {
    let newSkipped = skipped;
    if (isStepSkipped(activeStep)) {
      newSkipped = new Set(newSkipped.values());
      newSkipped.delete(activeStep);
    }

    setActiveStep((prevActiveStep) => prevActiveStep + 1);
    setSkipped(newSkipped);
  };

  const handleBack = () => {
    if (activeStep === 1) {
      cancelOrder();
    } else {
      setActiveStep((prevActiveStep) => prevActiveStep - 1);
    }
  };

  const handleSkip = () => {
    if (!isStepOptional(activeStep)) {
      // You probably want to guard against something like this,
      // it should never occur unless someone's actively trying to break something.
      throw new Error("You can't skip a step that isn't optional.");
    }

    setActiveStep((prevActiveStep) => prevActiveStep + 1);
    setSkipped((prevSkipped) => {
      const newSkipped = new Set(prevSkipped.values());
      newSkipped.add(activeStep);
      return newSkipped;
    });
  };

  const handleReset = () => {
    setActiveStep(0);
  };

  function contentForActiveStep(activeStep) {
    const buttons = (
      <>
        <Box sx={{ display: "flex", flexDirection: "row", pt: 2 }}>
          <MSButton color="inherit" onClick={handleBack} sx={{ mr: 1 }}>
            Back
          </MSButton>
          <Box sx={{ flex: "1 1 auto" }} />
          {isStepOptional(activeStep) && (
            <Button color="inherit" onClick={handleSkip} sx={{ mr: 1 }}>
              Skip
            </Button>
          )}

          <MSButton onClick={handleNext} variant="primary">
            {activeStep === steps.length - 1 ? "Finish" : "Next"}
          </MSButton>
        </Box>
      </>
    );

    let content;

    if (activeStep === 1) {
      content = (
        <>
          <div>
            <div className="card-header" style={{ marginBottom: 24 }}>
              <h3>Review order</h3>
              <Typography>
                Step {activeStep + 1} of {steps.length}
              </Typography>
            </div>

            <div className="card-body">
              <OrderPreview
                pricePerShare={pricePerShare}
                previewDetails={orderPreview}
              />

              {buttons}
            </div>
          </div>
        </>
      );
    } else if (activeStep === 2) {
      content = (
        <>
          <div>
            <div className="card-header" style={{ marginBottom: 0 }}>
              <h3>Review order</h3>
              <Typography>
                Step {activeStep + 1} of {steps.length}
              </Typography>
            </div>

            <SellerLegalInformationForm
              onSubmit={() => {
                handleNext();
              }}
            />
            <>
              <Box sx={{ display: "flex", flexDirection: "row" }}>
                <MSButton color="inherit" onClick={handleBack} sx={{ mr: 1 }}>
                  Back
                </MSButton>
                <Box sx={{ flex: "1 1 auto" }} />
                {isStepOptional(activeStep) && (
                  <Button color="inherit" onClick={handleSkip} sx={{ mr: 1 }}>
                    Skip
                  </Button>
                )}
              </Box>
            </>
          </div>
        </>
      );
    } else if (activeStep === 3) {
      content = (
        <>
          <div>
            <div className="card-header" style={{ marginBottom: 0 }}>
              <h3>Tax & banking details</h3>
              <Typography>
                Step {activeStep + 1} of {steps.length}
              </Typography>
            </div>

            <SellerTaxBankInfo
              organizationId={organizationId}
              secondaryId={secondaryId}
              onSubmit={() => {
                handleNext();
              }}
            />
            <>
              <Box sx={{ display: "flex", flexDirection: "row" }}>
                <MSButton color="inherit" onClick={handleBack} sx={{ mr: 1 }}>
                  Back
                </MSButton>
                <Box sx={{ flex: "1 1 auto" }} />
                {isStepOptional(activeStep) && (
                  <Button color="inherit" onClick={handleSkip} sx={{ mr: 1 }}>
                    Skip
                  </Button>
                )}
              </Box>
            </>
          </div>
        </>
      );
    } else if (activeStep === 4) {
      content = (
        <>
          <div className="card-header" style={{ marginBottom: 0 }}>
            <h3>Signing</h3>
            <Typography>
              Step {activeStep + 1} of {steps.length}
            </Typography>
          </div>
          <SellerTenderSign
            organizationId={organizationId}
            secondaryId={secondaryId}
            orderPreview={orderPreview}
            onSubmit={() => {
              setShowBack(false);
            }}
          />

          <Box sx={{ display: "flex", flexDirection: "row" }}>
            {showBack && (
              <MSButton color="inherit" onClick={handleBack} sx={{ mr: 1 }}>
                Back
              </MSButton>
            )}
            <Box sx={{ flex: "1 1 auto" }} />
          </Box>
        </>
      );
    }

    return <>{content}</>;
  }

  return (
    <Box sx={{ width: "100%" }}>
      <Stepper activeStep={activeStep} style={{ marginBottom: 32 }}>
        {steps.map((label, index) => {
          const stepProps = {};
          const labelProps = {};
          if (isStepOptional(index)) {
            labelProps.optional = (
              <Typography variant="caption">Optional</Typography>
            );
          }
          if (isStepSkipped(index)) {
            stepProps.completed = false;
          }
          return (
            <Step key={label} {...stepProps}>
              <StepLabel {...labelProps}>{label}</StepLabel>
            </Step>
          );
        })}
      </Stepper>
      {activeStep === steps.length ? (
        <React.Fragment>
          <Typography sx={{ mt: 2, mb: 1 }}>
            All steps completed - you&apos;re finished
          </Typography>
          <Box sx={{ display: "flex", flexDirection: "row", pt: 2 }}>
            <Box sx={{ flex: "1 1 auto" }} />
            <Button onClick={handleReset}>Reset</Button>
          </Box>
        </React.Fragment>
      ) : (
        <React.Fragment>
          <div className="card outlined">
            {contentForActiveStep(activeStep)}
          </div>
        </React.Fragment>
      )}
    </Box>
  );
}
