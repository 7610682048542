import { Box, CircularProgress } from "@mui/material";
import { doc, getDoc, getFirestore, onSnapshot } from "firebase/firestore";
import { post } from "jsx/api";
import { UserContext } from "jsx/contexts/user-context";
import React, { useEffect, useState } from "react";

import { SellerBankingInformation } from "./seller-banking-information";

const taxFormTypeMap = {
  "W-9": "w9Oct2018",
  "W-8BEN": "w8benOct2021",
  "W-8BEN-E": "w8beneOct2021",
};

export function SellerTaxBankInfo({ onSubmit, organizationId, secondaryId }) {
  const { user } = React.useContext(UserContext);
  const userEmail = user.email;
  const userId = user.uid;
  const [taxDocument, setTaxDocument] = useState();
  const [selectedTaxFormType, setSelectedTaxFormType] = useState("");
  const [formUrl, setFormUrl] = useState();

  const db = getFirestore();

  const completedTaxFormType = taxDocument?.formType; // w9Oct2018, w8benOct2021, w8beneOct2021

  useEffect(() => {
    if (organizationId && secondaryId && userEmail) {
      subscribeToSellerTaxDocument(organizationId, secondaryId, userEmail);
    }
  }, [organizationId, secondaryId, userEmail]);

  useEffect(() => {
    async function getUserData() {
      const userSnap = await getDoc(
        doc(db, "users", user.uid, "users_private", "data")
      );
      const userData = userSnap.data();
      setSelectedTaxFormType(taxFormTypeMap[userData.personal.taxDocument]);
    }
    if (user.uid) {
      getUserData();
    }
  }, [user.uid]);

  useEffect(() => {
    if (selectedTaxFormType && organizationId && secondaryId && userId) {
      const referenceId = `${organizationId}:${secondaryId}:${userId}`;
      createFormSession(referenceId);
    }
  }, [selectedTaxFormType, organizationId, secondaryId, userId]);

  async function subscribeToSellerTaxDocument(
    organizationId,
    secondaryId,
    sellerEmail
  ) {
    onSnapshot(
      doc(
        db,
        "organizations",
        organizationId,
        "organizations_admin",
        "data",
        "secondaries",
        secondaryId,
        "secondary_sellers",
        sellerEmail
      ),
      (doc) => {
        const sellerData = doc.data();
        setTaxDocument(sellerData.taxDocument);
      }
    );
  }

  const createFormSession = async (referenceId) => {
    const result = await post("nextform/session", {
      formType: selectedTaxFormType,
      reference: referenceId,
      signerEmail: userEmail,
    });
    const { id: sessionId, url } = result.data;
    console.log(sessionId, url);
    setFormUrl(url);
    return url;
  };

  return selectedTaxFormType !== completedTaxFormType ? (
    <SellerTaxForm formUrl={formUrl} />
  ) : (
    <SellerBankingInformation
      organizationId={organizationId}
      secondaryId={secondaryId}
      onSubmit={onSubmit}
    />
  );
}

function SellerTaxForm({ formUrl }) {
  return formUrl ? (
    <Box
      sx={{
        my: 5,
        // https://www.benmarshall.me/responsive-iframes/
        overflow: "hidden",
        paddingTop: "82%",
        position: "relative",
        "& iframe": {
          border: 0,
          height: 1,
          left: 0,
          position: "absolute",
          top: 0,
          width: 1,
        },
      }}
    >
      <iframe title="Tax form" src={formUrl} />
    </Box>
  ) : (
    <Box sx={{ display: "flex", justifyContent: "center", my: 5 }}>
      <CircularProgress />
    </Box>
  );
}
