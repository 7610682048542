import { LocalizationProvider } from "@mui/lab";
import { AdapterDateFns } from "@mui/x-date-pickers/AdapterDateFns";
import { DatePicker } from "@mui/x-date-pickers/DatePicker";
import { startOfDay } from "date-fns";
import {
  collection,
  doc,
  getDoc,
  getDocs,
  getFirestore,
  query,
} from "firebase/firestore";
import { Field, Form, Formik } from "formik";
import { get, post } from "jsx/api";
import { UserContext } from "jsx/contexts/user-context";
import MSButton from "jsx/element/button";
import MSField from "jsx/element/ms-field";
import SecondaryOrderTable from "jsx/element/secondary-order-table";
import SecondaryTransactionTable from "jsx/element/secondary-transaction-table";
import Header4 from "jsx/layout/header4";
import React, { useContext, useEffect, useState } from "react";

import UpdateSecondaryPrice from "./admin/update-secondary-price";
import UpdateSecondaryStatus from "./admin/update-secondary-status";

export default function CompanySecondaryManageAdmin({ match }) {
  const db = getFirestore();

  const { secondaryId } = match.params;

  const [secondary, setSecondary] = useState(null);
  const [summaries, setSummaries] = useState(null);
  const { selectedOrganization } = useContext(UserContext);
  const [secondaryOrders, setSecondaryOrders] = useState([]);
  const [transactions, setTransactions] = useState([]);
  const [documentMetadata, setDocumentMetadata] = useState({});
  const [signatureText, setSignatureText] = useState();
  const [authorizedSellers, setAuthorizedSellers] = useState([]);
  const [selectedSeller, setSelectedSeller] = useState();
  const [isSubmittingTransactions, setIsSubmittingTransactions] =
    useState(false);
  const [isApprovingTransactions, setIsApprovingTransactions] = useState(false);
  const [isGeneratingExport, setIsGeneratingExport] = useState(false);
  const [isImportingProForma, setIsImportingProForma] = useState(false);
  const [isExportingCompletedTxns, setIsExportingCompletedTxns] =
    useState(false);
  const [effectiveDate, setEffectiveDate] = useState();

  const [isFetchingTransactions, setFetchingTransactions] = useState(false);

  const getSecondary = async () => {
    const secondaryDoc = doc(
      db,
      "organizations",
      selectedOrganization,
      "organizations_private",
      "data",
      "secondaries",
      secondaryId
    );
    const secondary = await getDoc(secondaryDoc);
    setSecondary({ id: secondary.id, ...secondary.data() });
  };

  const getSecondaryOrders = async () => {
    const secondaryOrderQuery = collection(
      db,
      "organizations",
      selectedOrganization,
      "organizations_admin",
      "data",
      "secondaries",
      secondary.id,
      "secondary_orders"
    );
    const querySnapshot = await getDocs(query(secondaryOrderQuery));
    const orders = querySnapshot.docs.map((doc) => ({
      id: doc.id,
      ...doc.data(),
    }));
    setSecondaryOrders(orders);
  };

  const getAuthorizedSellers = async () => {
    const secondaryOrderQuery = collection(
      db,
      "organizations",
      selectedOrganization,
      "organizations_admin",
      "data",
      "secondaries",
      secondary.id,
      "secondary_sellers"
    );
    const querySnapshot = await getDocs(query(secondaryOrderQuery));
    const sellers = querySnapshot.docs.map((doc) => ({
      id: doc.id,
      ...doc.data(),
    }));
    setAuthorizedSellers(sellers);
  };

  const getSecurities = async () => {
    const result = await get("users/listSecuritiesByEmail", {
      organizationId: selectedOrganization,
      email: selectedSeller,
    });
    setSummaries(result.data);
    return result;
  };

  useEffect(() => {
    if (selectedSeller) getSecurities();
  }, [selectedSeller]);

  const createBuyOrder = async ({ quantity }) => {
    await post(`secondaries/createBuyOrder`, {
      secondaryId: secondary.id,
      organizationId: selectedOrganization,
      quantity,
    });
    getSecondaryOrders();
  };

  const createSellOrder = async ({ quantity, securityId }) => {
    await post(`secondaries/createSellOrder`, {
      organizationId: selectedOrganization,
      secondaryId: secondary.id,
      sellSecuritiesOrder: [{ securityId, quantity }],
      sellerEmail: selectedSeller,
    });
    getSecondaryOrders();
  };

  const createTransaction = async ({ buyOrderId, sellOrderId, quantity }) => {
    await post(`secondaries/createTransaction`, {
      organizationId: selectedOrganization,
      secondaryId: secondary.id,
      buyOrderId,
      sellOrderId,
      quantity,
    });
    getTransactions();
  };

  const getTransactions = async () => {
    setFetchingTransactions(true);
    const transactionsSnapshot = await getDocs(
      collection(
        db,
        "organizations",
        selectedOrganization,
        "organizations_admin",
        "data",
        "secondaries",
        secondary.id,
        "secondary_transactions"
      )
    );
    const transactions = transactionsSnapshot.docs.map((doc) => ({
      id: doc.id,
      ...doc.data(),
    }));
    setTransactions(transactions);
    setFetchingTransactions(false);
  };

  const approveTransactions = async () => {
    const result = await post(`secondaries/approveTransactions`, {
      organizationId: selectedOrganization,
      secondaryId: secondary.id,
    });
    if (result.data?.secondaryTransactions) {
      setTransactions(result.data?.secondaryTransactions);
    }
  };

  const fetchTransferDoc = async (documentId, transactionId) => {
    const document = await getDoc(
      doc(
        db,
        "organizations",
        selectedOrganization,
        "organizations_admin",
        "data",
        "secondaries",
        secondary.id,
        "secondary_transactions",
        transactionId,
        "documents",
        documentId
      )
    );

    const documentMetadata = document.data();

    setDocumentMetadata(documentMetadata);
  };

  const generateTransferDoc = async (transactionId) => {
    const params = {
      organizationId: selectedOrganization,
      secondaryId: secondary.id,
      transactionId: transactionId,
    };
    const result = await post(`secondaries/createTransferDoc`, params);
    const documentId = result.data.documentId;

    await fetchTransferDoc(documentId, transactionId);
  };

  async function signDocAs(signerParty, transactionId) {
    const params = {
      organizationId: selectedOrganization,
      signerParty: signerParty,
      secondaryId: secondary.id,
      transactionId: transactionId,
      signatureText: signatureText,
    };

    const result = await post(`signTransferDoc`, params);
    const signedDocId = result.data.documentId;

    const document = await getDoc(
      doc(
        db,
        "organizations",
        selectedOrganization,
        "organizations_admin",
        "data",
        "secondaries",
        secondary.id,
        "secondary_transactions",
        transactionId,
        "documents",
        signedDocId
      )
    );

    const documentMetadata = document.data();

    setDocumentMetadata(documentMetadata);
  }

  async function finalizeDocument(documentId, transactionId, date) {
    const params = {
      organizationId: selectedOrganization,
      secondaryId: secondary.id,
      effectiveDate: date,
      transactionId,
      documentId,
    };

    const result = await post(
      `secondaries/finalizeTransactionDocument`,
      params
    );
    const signedDocId = result.data.documentId;

    const document = await getDoc(
      doc(
        db,
        "organizations",
        selectedOrganization,
        "organizations_admin",
        "data",
        "secondaries",
        secondary.id,
        "secondary_transactions",
        transactionId,
        "documents",
        signedDocId
      )
    );

    const documentMetadata = document.data();

    setDocumentMetadata(documentMetadata);
  }

  async function generateExport() {
    const result = await post("secondaries/createTransactionExport", {
      organizationId: selectedOrganization,
      secondaryId: secondary.id,
    });
    setSecondary(result.data.secondary);
  }

  useEffect(() => {
    if (selectedOrganization) getSecondary();
  }, [selectedOrganization]);

  useEffect(() => {
    if (secondary?.id) getSecondaryOrders();
    if (secondary?.id) getTransactions();
    if (secondary?.id) getAuthorizedSellers();
  }, [secondary?.id]);

  return (
    <>
      <Header4 />
      <div className="content-body section-padding">
        <div className="card outlined container">
          <div className="card-header">
            <h3>Secondary admin</h3>
            <div className="mt-4">
              <h4 style={{ textAlign: "end" }}>Status: {secondary?.status}</h4>
              {secondary && (
                <UpdateSecondaryStatus
                  secondary={secondary}
                  organizationId={selectedOrganization}
                  handleUpdate={getSecondary}
                />
              )}
            </div>
          </div>
          <div className="row mt-4">
            <h3 className="mb-4">Price</h3>
            {secondary && (
              <UpdateSecondaryPrice
                secondary={secondary}
                organizationId={selectedOrganization}
                handleUpdate={getSecondary}
              />
            )}
          </div>
          <hr className="my-4" />
          <div className="row mt-4">
            <h3 className="mb-4">Orders</h3>
            <div>
              <h5>Create buy order</h5>
              <Formik
                initialValues={{ quantity: "" }}
                onSubmit={(values, { resetForm }) => {
                  createBuyOrder(values);
                  resetForm({ quantity: "" });
                }}
              >
                {(props) => (
                  <Form className="d-flex mb-4">
                    <Field
                      type="number"
                      placeholder="Quantity"
                      name="quantity"
                      className="form-control"
                    />
                    <button
                      type="submit"
                      className="btn btn-secondary ms-2"
                      disabled={!props.dirty}
                    >
                      Submit
                    </button>
                  </Form>
                )}
              </Formik>
            </div>

            <div>
              <h5>Create sell order</h5>
              <Formik
                initialValues={{ quantity: "", securityId: "" }}
                onSubmit={(values, { resetForm }) => {
                  createSellOrder(values);
                  resetForm({ quantity: "", securityId: "" });
                }}
              >
                {(props) => (
                  <Form className="d-flex">
                    <Field
                      as="select"
                      placeholder="Security"
                      name="securityId"
                      value={selectedSeller}
                      className="form-control"
                      onChange={(e) => {
                        setSelectedSeller(e.target.value);
                      }}
                    >
                      <option defaultValue value="">
                        Select a seller
                      </option>
                      {authorizedSellers
                        .filter((e) => e.userId)
                        .map((seller) => (
                          <option value={seller.id} key={seller.id}>
                            {seller.id}
                          </option>
                        ))}
                    </Field>
                    <Field
                      as="select"
                      placeholder="Security"
                      name="securityId"
                      className="form-control ms-2"
                    >
                      <option defaultValue value="">
                        Select a security
                      </option>
                      {summaries
                        ?.flatMap(({ securities }) => securities)
                        ?.filter(
                          ({ meta: { isSellable, sellable } }) =>
                            isSellable && sellable > 0
                        )
                        .map((security) => (
                          <option value={security.id} key={security.id}>
                            {security.certificateNo}
                          </option>
                        ))}
                    </Field>
                    {props.values.securityId && (
                      <Field
                        type="number"
                        className="form-control"
                        placeholder={`Quantity (max ${
                          summaries
                            .flatMap(({ securities }) => securities)
                            .find(({ id }) => id === props.values.securityId)
                            ?.meta.sellable
                        })`}
                        name="quantity"
                      />
                    )}
                    <button
                      type="submit"
                      className="btn btn-secondary  ms-2"
                      disabled={!props.dirty}
                    >
                      Submit
                    </button>
                  </Form>
                )}
              </Formik>
            </div>
          </div>
          <hr className="my-5" />

          <div className="row">
            <h3 className="mb-4">Matching</h3>

            <div className="col-4 mb-4">
              <h5>Create batch transactions</h5>
              {secondaryOrders?.length > 0 && (
                <>
                  <button
                    type="submit"
                    className="btn btn-secondary form-control"
                    disabled={
                      isSubmittingTransactions ||
                      !(
                        secondary.status.startsWith("active.ordering") ||
                        secondary.status === "active.matching"
                      )
                    }
                    onClick={async () => {
                      setIsSubmittingTransactions(true);
                      await post("secondaries/generateTransactions", {
                        secondaryId: secondary.id,
                        organizationId: selectedOrganization,
                      });
                      await getTransactions();
                      setIsSubmittingTransactions(false);
                    }}
                  >
                    Generate transactions
                  </button>
                </>
              )}
            </div>
            <h5>Create single transaction</h5>
            <Formik
              initialValues={{
                quantity: "",
                buyOrderId: "",
                sellOrderId: "",
              }}
              onSubmit={(values, { resetForm }) => {
                createTransaction(values);
                resetForm({
                  quantity: "",
                  buyOrderId: "",
                  sellOrderId: "",
                });
              }}
            >
              {(props) => (
                <Form className="d-flex">
                  <Field
                    as="select"
                    name="sellOrderId"
                    className="form-control"
                  >
                    <option defaultValue value="">
                      Sell security
                    </option>
                    {secondaryOrders
                      .filter(
                        ({ orderType, status }) =>
                          orderType === "sell" && status === "open"
                      )
                      .map(
                        (
                          {
                            id,
                            name,
                            quantity,
                            securityDetails: { certificateNo },
                          },
                          i
                        ) => (
                          <option value={id} key={i}>
                            {name} | {certificateNo} ({quantity})
                          </option>
                        )
                      )}
                  </Field>
                  <Field
                    as="select"
                    name="buyOrderId"
                    className="form-control ms-2"
                  >
                    <option defaultValue value="">
                      Buy security
                    </option>
                    {secondaryOrders
                      .filter(
                        ({ orderType, status }) =>
                          orderType === "buy" && status === "open"
                      )
                      .map(({ id, name, quantity }, i) => (
                        <option value={id} key={i}>
                          {name} ({quantity})
                        </option>
                      ))}
                  </Field>

                  <Field
                    type="number"
                    className="form-control ms-2"
                    placeholder={`Quantity`}
                    name="quantity"
                  />

                  <button
                    type="submit"
                    className="btn btn-secondary ms-2"
                    disabled={!props.dirty}
                  >
                    Submit
                  </button>
                </Form>
              )}
            </Formik>
          </div>
          <hr className="my-5" />
          <h3 className="mb-4">Doc generation</h3>
          <div className="mb-4">
            <div className="row">
              <div className="mb-4">
                <h5>Pro forma transaction</h5>
                <MSButton
                  disabled={isGeneratingExport}
                  onClick={async () => {
                    setIsGeneratingExport(true);
                    await generateExport();
                    setIsGeneratingExport(false);
                  }}
                >
                  {secondary?.spreadsheetId ? "Update" : "Create"} pro forma
                  export for draft transactions
                </MSButton>
                {secondary?.spreadsheetUrl && (
                  <a
                    href={secondary.spreadsheetUrl}
                    target="_blank"
                    style={{ margin: 20 }}
                    rel="noreferrer"
                  >
                    View spreadsheet
                  </a>
                )}
                {secondary?.spreadsheetId && (
                  <MSButton
                    disabled={isImportingProForma}
                    onClick={async () => {
                      setIsImportingProForma(true);
                      await post("secondaries/updateTransactionsFromProForma", {
                        secondaryId: secondary.id,
                        organizationId: selectedOrganization,
                      });
                      setIsImportingProForma(false);
                    }}
                  >
                    Update transactions from pro forma
                  </MSButton>
                )}
              </div>
              <div>
                <h5>Approve transactions</h5>
                <button
                  className="btn btn-primary"
                  disabled={isApprovingTransactions}
                  onClick={async () => {
                    setIsApprovingTransactions(true);
                    await approveTransactions();
                    setIsApprovingTransactions(false);
                  }}
                >
                  Approve transactions and create docs
                </button>
              </div>
            </div>
          </div>
          <div className="row mb-3">
            <h5>Sign transaction</h5>
            <Formik initialValues={{ transactionId: "" }}>
              {(props) => (
                <Form>
                  <Field
                    as="select"
                    placeholder="Transaction"
                    name="transactionId"
                    className="form-control"
                    onChange={(e) => {
                      props.handleChange(e);
                      const transactionId = e.target.value;
                      const transaction = transactions.find(
                        (transaction) => transaction.id === transactionId
                      );
                      if (transaction) {
                        const documentId = transaction.transferDocumentId;
                        if (documentId)
                          fetchTransferDoc(documentId, transactionId);
                      } else {
                        setDocumentMetadata({});
                      }
                    }}
                  >
                    <option defaultValue value="">
                      Select a transaction
                    </option>
                    {transactions.map((transaction) => (
                      <option value={transaction.id} key={transaction.id}>
                        {`${transaction.securityDetails.certificateNo}
                        | ${transaction.sellerName} 
                        | ${transaction.buyerName}
                        | ${transaction.quantity}`}
                      </option>
                    ))}
                  </Field>
                  {props.values.transactionId && (
                    <>
                      {!documentMetadata.unsignedDocxUrl && (
                        <div className="mt-2 mb-2">
                          <button
                            type="button"
                            className="btn btn-secondary"
                            onClick={() =>
                              generateTransferDoc(props.values.transactionId)
                            }
                          >
                            Generate docs
                          </button>
                        </div>
                      )}

                      {documentMetadata.latestDocxUrl && (
                        <div className="mt-2 mb-2">
                          <a href={documentMetadata.latestDocxUrl}>
                            <button type="button" className="btn btn-secondary">
                              {`Download ${documentMetadata.latestDocxName}`}
                            </button>
                          </a>
                        </div>
                      )}

                      <div className="row row-cols-auto mt-2 mb-2 justify-content-start">
                        {documentMetadata.latestDocxUrl &&
                          !documentMetadata.isSignedBySeller && (
                            <div className="col">
                              <button
                                type="button"
                                className="btn btn-secondary"
                                disabled={!signatureText}
                                onClick={() =>
                                  signDocAs(
                                    "seller",
                                    props.values.transactionId
                                  )
                                }
                              >
                                Sign as seller
                              </button>
                            </div>
                          )}
                        {documentMetadata.latestDocxUrl &&
                          !documentMetadata.isSignedByBuyer && (
                            <div className="col">
                              <button
                                type="button"
                                className="btn btn-secondary"
                                disabled={!signatureText}
                                onClick={() =>
                                  signDocAs("buyer", props.values.transactionId)
                                }
                              >
                                Sign as buyer
                              </button>
                            </div>
                          )}
                        {documentMetadata.latestDocxUrl &&
                          !documentMetadata.isSignedByCompany && (
                            <div className="col">
                              <button
                                type="button"
                                className="btn btn-secondary"
                                disabled={!signatureText}
                                onClick={() =>
                                  signDocAs(
                                    "company",
                                    props.values.transactionId
                                  )
                                }
                              >
                                Sign as company
                              </button>
                            </div>
                          )}
                        {!documentMetadata.isCompleted &&
                          documentMetadata.latestDocxUrl && (
                            <div className="col">
                              <button
                                type="button"
                                className="btn btn-secondary"
                                onClick={async () =>
                                  await finalizeDocument(
                                    documentMetadata.documentId,
                                    props.values.transactionId,
                                    effectiveDate
                                  )
                                }
                              >
                                Finalize document
                              </button>

                              <LocalizationProvider
                                dateAdapter={AdapterDateFns}
                              >
                                <DatePicker
                                  label="Effective Date"
                                  value={effectiveDate}
                                  onChange={(newValue) => {
                                    setEffectiveDate(newValue);
                                  }}
                                  renderInput={(params) => (
                                    <MSField {...params} />
                                  )}
                                />
                              </LocalizationProvider>
                            </div>
                          )}
                      </div>
                      {!documentMetadata.isCompleted &&
                        documentMetadata.latestDocxUrl && (
                          <input
                            style={{
                              fontFamily: "Dancing Script",
                              fontSize: 34,
                              textIndent: 16,
                            }}
                            type="text"
                            autoComplete="off"
                            autoCorrect="off"
                            autoCapitalize="off"
                            spellCheck="false"
                            placeholder="First Last"
                            onChange={(e) => {
                              setSignatureText(e.target.value);
                            }}
                          />
                        )}
                    </>
                  )}
                </Form>
              )}
            </Formik>
          </div>
          <div>
            <h5>Export completed transactions</h5>
            {secondary && (
              <MSButton
                disabled={isExportingCompletedTxns}
                onClick={async () => {
                  setIsExportingCompletedTxns(true);
                  await post("secondaries/createCompletedTransactionsExport", {
                    secondaryId: secondary.id,
                    organizationId: selectedOrganization,
                  });
                  await getSecondary();
                  setIsExportingCompletedTxns(false);
                }}
              >
                Create completed transaction export
              </MSButton>
            )}
            {secondary?.completedTransactionsSpreadsheetUrl && (
              <a
                href={secondary.completedTransactionsSpreadsheetUrl}
                target="_blank"
                style={{ margin: 20 }}
                rel="noreferrer"
              >
                View spreadsheet
              </a>
            )}
          </div>
          <hr className="my-5" />

          <h4 className="mt-3">Orders</h4>
          <SecondaryOrderTable
            secondaryOrders={secondaryOrders}
            secondaryId={secondaryId}
            organizationId={selectedOrganization}
            isAdmin={true}
          />
          <h4 className="mt-3">Transactions</h4>
          <SecondaryTransactionTable
            transactions={transactions}
            secondaryId={secondary?.id}
            loading={isFetchingTransactions}
          />

          {summaries && (
            <div>
              {summaries.map(({ category, securities, summary }) => (
                <div key={category}>
                  <h3>{category}</h3>
                  <div> Summary: {JSON.stringify(summary)} </div>
                  <div>
                    Securities:{" "}
                    {securities.map((security) => (
                      <div key={security.id}>
                        {security.certificateNo} | qty{" "}
                        {security.quantityOutstanding} | (
                        {JSON.stringify(security.meta)})
                      </div>
                    ))}
                  </div>
                </div>
              ))}
            </div>
          )}
        </div>
      </div>
    </>
  );
}
