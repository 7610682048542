import { collection, getDocs, getFirestore } from "@firebase/firestore";
import { duotone } from "@fortawesome/fontawesome-svg-core/import.macro";
import { InputAdornment } from "@mui/material";
import Box from "@mui/material/Box";
import { DataGridPro, GridLinkOperator } from "@mui/x-data-grid-pro";
import { post } from "jsx/api";
import { UserContext } from "jsx/contexts/user-context";
import EmptyState from "jsx/element/empty-state";
import { formatPrice, monthDiff, toCapcase } from "jsx/helpers";
import EmployeeActionButton from "jsx/pages/company/tender-participants-action-button";
import React, { useCallback, useEffect, useMemo, useState } from "react";
import { useHistory } from "react-router-dom";

import MSField from "./ms-field";
import StakeholderRoleFilter from "./stakeholder-role-filter";

const columnHeight = "40px !important";

const gridStyle = {
  width: "100%",
  "& .MuiDataGrid-columnHeaderWrapper": {
    background: "rgba(0, 0, 0, 0.05)",
  },
  "& .MuiDataGrid-columnsContainer": {
    minHeight: columnHeight,
    maxHeight: columnHeight,
    lineHeight: columnHeight,
  },
  "& .MuiDataGrid-columnSeparator": {
    minHeight: columnHeight,
  },
  "& .MuiDataGrid-columnHeaderTitle": {
    lineHeight: columnHeight,
  },
  "& .MuiDataGrid-window": {
    top: columnHeight,
  },
  "& .MuiDataGrid-root": {
    borderRadius: "0px",
  },

  ".MuiCheckbox-colorPrimary.Mui-checked": {
    color: "#000",
  },
  ".MuiDataGrid-root .MuiDataGrid-row.Mui-selected": {
    background: "rgba(0, 0, 0, 0.03)",
  },
};

const gridInputStyle = {
  "& .MuiDataGrid-cell:not(:last-child)": {
    borderRight: "rgba(0, 0, 0, 0.20)",
    borderRightStyle: "dashed",
    borderRightWidth: "1px",
  },
  "& .MuiDataGrid-cell": {
    borderBottom: "0px !important",
    position: "relative",
  },
};

const inputStyle = {
  textAlign: "right",
  background: "transparent",
  border: "none",
};

const columnHeaderStyle = {
  "&.MuiDataGrid-root .MuiDataGrid-columnHeader:focus": {
    outline: "none",
  },
};

export default function TenderModelingTable({
  isFullscreen,
  showModeling = true,
  hiddenFields = [],
  refresh,
  disableSelectionOnClick,
}) {
  const { selectedOrganization } = React.useContext(UserContext);
  const [selectionModel, setSelectionModel] = React.useState([]);
  const [searchString, setSearchString] = useState("");
  const [percentSellable, setPercentSellable] = useState(20);
  const [pricePerShare, setPricePerShare] = useState(0);
  const [maxParticipants, setMaxParticipants] = useState(12);
  const [stakeholders, setStakeholders] = useState([]);
  const [loading, setLoading] = useState(true);

  const db = getFirestore();
  const history = useHistory();
  const [roleFilter, setRoleFilter] = useState(["Employee", "Ex-Employee"]);

  useEffect(() => {
    fetchEmployees();
  }, [selectedOrganization]);

  useEffect(() => {
    if (refresh) {
      fetchEmployees();
    }
  }, [refresh]);

  const handleAddEmployees = useCallback(
    async (secondaryId, selectionModel) => {
      const selectedEmployees = selectionModel.flatMap((id) => {
        const stakeholder = stakeholders.find(
          (empl) => empl.primaryEmail === id
        );
        const email = stakeholder.primaryEmail;

        if (email) {
          return [
            {
              email: email,
              firstName: stakeholder.firstName,
              lastName: stakeholder.lastName,
            },
          ];
        } else {
          return [];
        }
      });

      setSelectionModel([]);

      await post("secondaries/addSellers", {
        organizationId: selectedOrganization,
        secondaryId,
        sellers: selectedEmployees,
      });
      history.push(`/org/tenders/manage/${secondaryId}/stakeholders`);
    },
    [stakeholders, history, selectedOrganization]
  );

  function searchOnChange(e) {
    setSearchString(e.target.value);
  }

  const filterModel = useMemo(() => {
    let items = [];

    if (searchString) {
      items = [
        {
          id: 1,
          columnField: "displayName",
          operatorValue: "contains",
          value: `${searchString}`,
        },
      ];
    } else {
      items = [
        {
          id: 2,
          columnField: "roleDisplay",
          operatorValue: "oneOf",
          value: roleFilter,
        },
      ];
    }

    return {
      items,
      linkOperator: GridLinkOperator.And,
    };
  }, [searchString, roleFilter]);

  async function fetchEmployees() {
    if (!selectedOrganization) {
      setLoading(false);
      return;
    }
    const querySnapshot = await getDocs(
      collection(
        db,
        `organizations/${selectedOrganization}/organizations_private/data/stakeholders`
      )
    );

    // default sort by tenure
    const mappedData = querySnapshot.docs.map((doc) => doc.data());
    mappedData.map((e) => {
      if (e.tenureInMonths) {
        return e;
      } else if (e.earliestVestingStartDate) {
        e.tenureInMonths = monthDiff(
          new Date(e.earliestVestingStartDate.seconds * 1000),
          new Date()
        );
      } else {
        e.tenureInMonths = "";
      }
      return e;
    });

    const sortedByTenure = mappedData.sort((a, b) =>
      a.tenureInMonths < b.tenureInMonths ? 1 : -1
    );

    setStakeholders(sortedByTenure);
    setLoading(false);
  }

  const rows = React.useMemo(() => {
    return stakeholders.map((stakeholder, i) => {
      const maxSellable = stakeholder.securitiesSummary
        ?.map((sec) => sec.sellable ?? 0)
        .reduce((acc, num) => (acc += num), 0);

      let lastSaleDate;

      const securitiesWithSaleDates = stakeholder.securitiesSummary?.filter(
        (e) => e.secondarySaleDates?.length > 0
      );

      if (securitiesWithSaleDates.length > 0) {
        const saleDates = securitiesWithSaleDates
          .map((e) => e.secondarySaleDates.map((e) => e.seconds * 1000))
          .reduce(function (i, j) {
            return i.concat(j);
          });

        lastSaleDate = Math.max(...saleDates);
      }

      return {
        id: stakeholder.primaryEmail,
        displayName: stakeholder.displayName,
        monthsEmployed: stakeholder.tenureInMonths,
        email: stakeholder.primaryEmail,
        roleDisplay: toCapcase(stakeholder.role?.replace("_", "-")),
        role: stakeholder.role,
        unvested: stakeholder.securitiesSummary
          ?.map((sec) => sec.quantityUnvested ?? 0)
          .reduce((acc, num) => (acc += num), 0),
        lastSold: lastSaleDate,
        outstanding: stakeholder.securitiesSummary
          ?.map((sec) => sec.quantityOutstanding ?? 0)
          .reduce((acc, num) => (acc += num), 0),
        weightedExercisePrice: stakeholder.securitiesSummary
          ?.map((sec) => {
            return sec.weightedExercisePrice ?? 0;
          })
          .reduce((acc, num) => (acc += num), 0),
        owned: stakeholder.securitiesSummary
          ?.map((sec) => {
            if (
              sec.category === "share" ||
              sec.category === "restrictedStockAward"
            ) {
              return sec.quantityOutstanding;
            }

            return 0;
          })
          .reduce((acc, num) => (acc += num), 0),
        sellable: maxSellable,
        exercised: stakeholder.securitiesSummary
          ?.map((sec) => sec.quantityExercised ?? 0)
          .reduce((acc, num) => (acc += num), 0),
        exercisable: stakeholder.securitiesSummary
          ?.map((sec) => sec.quantityExercisable ?? 0)
          .reduce((acc, num) => (acc += num), 0),
      };
    });
  }, [stakeholders]);

  const columns = useMemo(() => {
    const initialColumns = [
      {
        field: "displayName",
        headerName: "Name",
        minWidth: 140,
        flex: 0.25,
      },
      {
        field: "email",
        headerName: "Email",
        minWidth: 140,
        flex: 0.25,
      },
      {
        field: "roleDisplay",
        headerName: "Role",
        minWidth: 140,
        flex: 0.25,
        filterOperators: [
          {
            label: "OneOf",
            value: "oneOf",
            getApplyFilterFn: (filterItem) => {
              if (
                !filterItem.columnField ||
                !filterItem.value ||
                !filterItem.operatorValue
              ) {
                return null;
              }

              return (params) => {
                return filterItem.value.includes(params.value);
              };
            },
          },
        ],
      },
      {
        field: "monthsEmployed",
        headerName: "Tenure",
        description:
          "Months employed at the company from HR software. If not available, this is calculated from the earliest security vesting start date",
        type: "number",
        minWidth: 120,
        flex: 0.2,
        valueFormatter: (params) => {
          if (params.value === 0) {
            return "-";
          }

          return `${Math.floor(params.value)} mo`;
        },
      },
      {
        field: "outstanding",
        headerName: "Total units",
        type: "number",
        minWidth: 150,
        flex: 0.23,
      },
      {
        field: "unvested",
        headerName: "Unvested units",
        type: "number",
        minWidth: 150,
        flex: 0.23,
        description: "Unvested units",
      },
      {
        field: "sellable",
        headerName: "Vested Units",
        type: "number",
        minWidth: 150,
        flex: 0.25,
      },
      {
        field: "lastSold",
        headerName: "Last Sold",
        type: "number",
        minWidth: 150,
        flex: 0.25,
        description:
          "Last time individual participated in a secondary in months",
        valueGetter: ({ value }) =>
          value ? monthDiff(new Date(value), new Date()) : "",
        valueFormatter: (params) => {
          if (params.value === 0 || params.value === "") {
            return "-";
          }

          return `${params.value} mo`;
        },
      },
      {
        field: "calcSellable",
        headerName: "Max sellable",
        type: "number",
        minWidth: 150,
        flex: 0.25,
        description: "Max sellable * percent sellable",
        valueGetter: (params) => {
          return Math.floor(params.row.sellable * (percentSellable / 100));
        },
      },
      {
        field: "proceeds",
        headerName: "Proceeds",
        type: "number",
        minWidth: 150,
        flex: 0.25,
        description: "Pre-tax total proceeds",
        valueGetter: (params) => {
          return (
            Math.floor(params.row.sellable * (percentSellable / 100)) *
            pricePerShare
          );
        },
        valueFormatter: (params) => {
          if (params.value === 0) {
            return "-";
          }

          return formatPrice(params.value, 1);
        },
      },
    ];
    return initialColumns.filter((e) => !hiddenFields.includes(e.field));
  }, [percentSellable, pricePerShare, hiddenFields]);

  const handleSelectionModelChange = useCallback(
    (newSelectionModel) => {
      const sliced = newSelectionModel.slice(0, maxParticipants);
      setSelectionModel(sliced);
    },
    [maxParticipants]
  );

  const isRowSelectable = useCallback(
    (params) => {
      if (selectionModel.includes(params.row.id)) {
        return true;
      }
      return !(selectionModel.length >= maxParticipants);
    },
    [selectionModel, maxParticipants]
  );

  function changeSellable(e) {
    // positive decimal only
    const numberRegex = /^\d*\.?\d*$/;
    let input = e.target.value;

    if (numberRegex.test(input)) {
      if (input > 100) {
        input = 100;
      }
      setPercentSellable(input);
    } else {
      setPercentSellable(0);
    }
  }

  function changePricePerShare(e) {
    // positive decimal only
    const numberRegex = /^\d*\.?\d*$/;
    const input = e.target.value;

    if (numberRegex.test(input)) {
      setPricePerShare(input);
    } else {
      setPricePerShare(0);
    }
  }

  const headerColumns = useMemo(() => {
    return [
      {
        field: "percentSellable",
        headerName: "Percent sellable",
        minWidth: 200,
        flex: 0.25,
        headerAlign: "right",
        align: "right",

        renderCell: (cellValues) => {
          return (
            <>
              <Box
                sx={{
                  width: 34,
                  height: "100%",
                  background: "rgba(0, 0, 0, 0.03)",
                  position: "absolute",
                  left: 0,
                  display: "flex",
                  alignItems: "center",
                }}
              >
                <p style={{ left: "12px", position: "absolute" }}>%</p>
              </Box>
              <div>
                <input
                  value={percentSellable}
                  onChange={changeSellable}
                  style={inputStyle}
                />
              </div>
            </>
          );
        },
      },
      {
        field: "pricePerShare",
        headerName: "Price per unit",
        minWidth: 200,
        flex: 0.25,
        headerAlign: "right",
        align: "right",

        renderCell: (cellValues) => {
          return (
            <>
              <Box
                sx={{
                  width: 34,
                  height: "100%",
                  background: "rgba(0, 0, 0, 0.03)",
                  position: "absolute",
                  left: 0,
                  display: "flex",
                  alignItems: "center",
                }}
              >
                <p style={{ left: "12px", position: "absolute" }}>$</p>
              </Box>
              <div>
                <input
                  value={pricePerShare}
                  onChange={changePricePerShare}
                  style={inputStyle}
                />
              </div>
            </>
          );
        },
      },
      {
        field: "numberOfParticipants",
        headerName: "Max participants",
        minWidth: 200,
        flex: 0.25,
        headerAlign: "right",
        align: "right",

        renderCell: (cellValues) => {
          return (
            <>
              <Box
                sx={{
                  width: 34,
                  height: "100%",
                  background: "rgba(0, 0, 0, 0.03)",
                  position: "absolute",
                  left: 0,
                  display: "flex",
                  alignItems: "center",
                }}
              >
                <p style={{ left: "12px", position: "absolute" }}>$</p>
              </Box>
              <div>
                <input
                  value={maxParticipants}
                  onChange={(e) => {
                    const sliced = selectionModel.slice(0, e.target.value);
                    setSelectionModel(sliced);

                    setMaxParticipants(e.target.value);
                  }}
                  style={inputStyle}
                />
              </div>
            </>
          );
        },
      },
    ];
  }, [selectionModel, percentSellable, pricePerShare, maxParticipants]);

  const inputRows = [
    {
      id: 1,
      percentSellable: 0,
      pricePerShare: 0,
      numberOfParticipants: 12,
    },
  ];

  const resultHeaderSection = {
    minWidth: 80,
    flexDirection: "column",
    marginLeft: "48px",
    textAlign: "right",
  };

  const calculatedTotalShares = useMemo(() => {
    const selected = rows.filter((e) => selectionModel.includes(e.id));
    return selected.reduce((a, e) => a + e.sellable, 0);
  }, [rows, selectionModel]);

  const calculatedSharesSellable = Math.floor(
    calculatedTotalShares * (percentSellable / 100)
  );

  const calculatedRoundTotal = calculatedSharesSellable * pricePerShare;

  const headerFullScreenStyle = {
    position: "fixed",
    backgroundColor: "white",
    zIndex: 9,
    width: "100%",
    top: 80,
    borderBottom: "1px solid #CCC",
    left: 0,
    paddingLeft: 32,
    paddingRight: 32,
    boxShadow: "0px 0px 8px rgba(0, 0, 0, 0.2)",
  };

  return (
    <>
      <div>
        {showModeling && (
          <div style={isFullscreen ? headerFullScreenStyle : {}}>
            <div className="d-flex mb-3 mt-3">
              <div className="flex-grow-1"></div>

              <div style={resultHeaderSection}>
                <h4>{selectionModel.length}</h4>
                <p className="small">Selected stakeholders</p>
              </div>

              <div style={resultHeaderSection}>
                <h4>{calculatedTotalShares.toLocaleString()}</h4>
                <p className="small">Total units</p>
              </div>

              <div style={resultHeaderSection}>
                <h4>{calculatedSharesSellable.toLocaleString()}</h4>
                <p className="small">Sellable units</p>
              </div>

              <div style={resultHeaderSection}>
                <h4>${calculatedRoundTotal.toLocaleString()}</h4>
                <p className="small">Total</p>
              </div>
            </div>

            <Box
              sx={{
                ...gridStyle,
                ...gridInputStyle,
                height: 94,
                marginBottom: "24px",
              }}
            >
              <DataGridPro
                rows={inputRows}
                columns={headerColumns}
                density="compact"
                autoHeight={true}
                disableColumnMenu={true}
                checkboxSelection={false}
                hideFooterPagination={true}
                hideFooter={true}
                disableSelectionOnClick={true}
                disableRowGrouping={true}
                disableColumnResize={true}
                sx={columnHeaderStyle}
              />
            </Box>
          </div>
        )}

        <Box
          sx={{ ...gridStyle }}
          style={{ marginTop: isFullscreen ? 184 : 0 }}
        >
          <div
            className="d-flex align-items-bottom"
            style={{ marginBottom: 16 }}
          >
            <MSField
              name="Search stakeholders"
              className="ms-2"
              size="small"
              style={{ minWidth: 300 }}
              placeholder="First or last name"
              onChange={searchOnChange}
              InputProps={{
                startAdornment: (
                  <InputAdornment position="start" style={{ outline: "none" }}>
                    <i
                      className="fa fa-search"
                      style={{
                        fontSize: 18,
                      }}
                    ></i>
                  </InputAdornment>
                ),
              }}
            />
            <StakeholderRoleFilter
              className="ms-2"
              size="small"
              filter={roleFilter}
              onChange={(e) => {
                setRoleFilter(e);
              }}
            />
          </div>

          <DataGridPro
            columns={columns}
            checkboxSelection
            loading={loading}
            onSelectionModelChange={handleSelectionModelChange}
            disableSelectionOnClick={disableSelectionOnClick}
            disableColumnMenu
            rows={rows}
            selectionModel={selectionModel}
            filterModel={filterModel}
            isRowSelectable={isRowSelectable}
            key={loading}
            components={{
              NoRowsOverlay: CustomNoRowsOverlay,
            }}
            sx={{
              minHeight: 600,
              borderColor: "var(--purple-light)",

              "& .MuiDataGrid-cell": {
                borderBottomColor: "var(--purple-light)",
                fontFamily: "var(--primary-font)",
                fontSize: 14,
                fontWeight: 400,
              },
              "& .MuiDataGrid-columnHeaderTitle": {
                fontSize: 13,
                fontFamily: "var(--primary-font)",
                color: "#6c757d",
              },
              "& .MuiDataGrid-columnHeaders": {
                borderBottomColor: "var(--purple-light)",
              },
              "& .MuiDataGrid-row:hover": {
                background: "#FFF",
                // background: "var(--background)",
              },

              "& .MuiDataGrid-cell:focus": {
                outline: "none",
              },
              "& .MuiDataGrid-columnHeader:focus": {
                outline: "none",
              },
              "& .MuiDataGrid-columnHeader:focus-within": {
                outline: "none",
              },
              "& .MuiDataGrid-cell:focus-within": {
                outline: "none",
              },
            }}
          />
        </Box>
      </div>
    </>
  );
}

function CustomNoRowsOverlay() {
  return (
    <div
      style={{
        height: "100%",
      }}
    >
      <EmptyState
        icon={duotone("rectangle-history-circle-user")}
        title={"No stakeholders to display"}
        subtitle={
          "Use the 'Import data' button on the top right to upload stakeholder information."
        }
      />
    </div>
  );
}
