// import AreaChart from 'jsx/charts/area';
import FundraisingChart from "jsx/charts/fundraise-chart";
import React, { useEffect } from "react";
import { Link } from "react-router-dom";

function CompanyDetails(props) {
  let orderBlock;
  if (props.orders) {
    orderBlock = (
      <div className="card">
        <div className="card-header border-0 py-0">
          <h4 className="card-title">Your Orders</h4>
        </div>
        <div className="card-body pt-2">
          <div className="transaction-table">
            <div className="table-responsive">
              <table className="table mb-0">
                <tbody>{props.orders}</tbody>
              </table>
            </div>
          </div>
        </div>
      </div>
    );
  }

  const leadership = [];
  if (props.company.management) {
    for (const person of props.company.management) {
      leadership.push(
        <tr>
          <td style={{ color: "#161c2d" }}>{person.name}</td>
          <td style={{ fontWeight: 200 }}>{person.role}</td>
        </tr>
      );
    }
  }

  const board = [];
  if (props.company.board) {
    for (const person of props.company.board) {
      board.push(
        <tr>
          <td style={{ color: "#161c2d" }}>{person.name}</td>
          <td style={{ fontWeight: 200 }}>{person.role}</td>
        </tr>
      );
    }
  }

  const formattedData = [];
  const annotations = [];

  const fundraising = [];
  if (props.company.chart_data) {
    for (const fundraise of props.company.chart_data) {
      const dataPoint = [new Date(fundraise.date).getTime(), fundraise.value];

      if (fundraise.price_per) {
        const pricePer = fundraise.price_per.split(" per share")[0];
        dataPoint.push(pricePer);
      }

      if (fundraise.title) {
        annotations.push([
          new Date(fundraise.date).getTime(),
          fundraise.value,
          fundraise.title,
        ]);
      }

      console.log(fundraise);

      formattedData.push(dataPoint);

      fundraising.push(
        <tr>
          <td style={{ color: "#161c2d" }}>{fundraise.date}</td>
          <td style={{ fontWeight: 200 }}>{fundraise.title}</td>
          <td style={{ fontWeight: 200 }}>{fundraise.number}</td>
          <td style={{ color: "#161c2d" }}>{fundraise.pretty_value}</td>
        </tr>
      );
    }
  }

  fundraising.reverse();

  useEffect(() => {});

  return (
    <>
      <div className="col-xl-8 col-lg-10 col-xxl-10">
        <div className="card profile_chart">
          <div className="card-header py-0">
            <div className="chart_current_data">
              <h2>{props.company.name}</h2>
              <p>
                {props.company.funding}
                <span> raised to date</span>
              </p>
            </div>
            {/* <div className="duration-option">
                            <Linkid="all" className="active">ALL</Link>
                            <Linkid="one_month" className="">1M</Link>
                            <Linkid="six_months">6M</Link>
                            <Linkid="one_year" className="">1Y</Link>
                            <Linkid="ytd" className="">YTD</Link>
                        </div> */}
          </div>
          <div className="card-body">
            <FundraisingChart data={formattedData} annotations={annotations} />
          </div>
        </div>

        <div className="card">
          <div className="card-header border-0 py-0">
            <h4 className="card-title">About</h4>
          </div>
          <div className="card-body">
            <p>{props.company.about}</p>

            <div className="row">
              <div className="col-xl-3 col-sm-6 col-6">
                <div className="chart-stat">
                  <p className="mb-1">Founded</p>
                  <h5>{props.company.founded}</h5>
                </div>
              </div>
            </div>
          </div>
        </div>
        {orderBlock}
        <div className="card">
          <div className="card-header border-0 py-0">
            <h4 className="card-title">Related Tags</h4>
          </div>
          <div className="card-body">
            <Link to={"#"} className="btn btn-lg btn-secondary rounded-pill">
              {/* <img src={require('./../../images/apple.svg')} alt="" /> */}
              {props.company.sector}
            </Link>
          </div>
        </div>
        <div className="card">
          <div className="card-header border-0 py-0">
            <h4 className="card-title">Fundraising</h4>
          </div>
          <div className="card-body">
            <div className="row">
              <div className="transaction-table">
                <div className="table-responsive">
                  <table className="table">
                    <tbody>{fundraising}</tbody>
                  </table>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div className="card">
          <div className="card-header border-0 py-0">
            <h4 className="card-title">Leadership</h4>
          </div>
          <div className="card-body">
            <div className="row">
              <div className="transaction-table">
                <div className="table-responsive">
                  <table className="table">
                    <tbody>{leadership}</tbody>
                  </table>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div className="card">
          <div className="card-header border-0 py-0">
            <h4 className="card-title">Board members</h4>
          </div>
          <div className="card-body">
            <div className="row">
              <div className="transaction-table">
                <div className="table-responsive">
                  <table className="table">
                    <tbody>{board}</tbody>
                  </table>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div className="card">
          <div className="card-header border-0 py-0">
            <h4 className="card-title">News</h4>
            <Link to={"#"}>View More</Link>
          </div>
          <div className="card-body">
            <div className="row news mb-4">
              <div className="col-xl-9 col-sm-8 col-8">
                <ul className="post-nfo">
                  <li className="me-2">
                    <h5>MarketWatch</h5>
                  </li>
                  <li>
                    <h6>Aug 27</h6>
                  </li>
                </ul>

                <div>
                  <h4>
                    Your Mastercard credit card is undergoing one of the biggest
                    changes in years
                  </h4>
                  <p>
                    The act of swiping a credit card has been part of our muscle
                    memory for decades. It’s hard to imagine it could soon go
                    away.
                  </p>
                </div>
              </div>
              <div className="col-xl-3 col-sm-4 col-4">
                <img
                  className="img-fluid rounded shadow-md mb-4"
                  src={require("../../images/about/1.jpg")}
                  alt=""
                />
              </div>
            </div>

            <div className="row news mb-4">
              <div className="col-xl-9 col-sm-8 col-8">
                <ul className="post-nfo">
                  <li className="me-2">
                    <h5>MarketWatch</h5>
                  </li>
                  <li>
                    <h6>Aug 27</h6>
                  </li>
                </ul>

                <div>
                  <h4>
                    Your Mastercard credit card is undergoing one of the biggest
                    changes in years
                  </h4>
                  <p>
                    The act of swiping a credit card has been part of our muscle
                    memory for decades. It’s hard to imagine it could soon go
                    away.
                  </p>
                </div>
              </div>
              <div className="col-xl-3 col-sm-4 col-4">
                <img
                  className="img-fluid rounded shadow-md mb-4"
                  src={require("../../images/about/1.jpg")}
                  alt=""
                />
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
}

export default CompanyDetails;
