import Table from "@mui/material/Table";
import TableBody from "@mui/material/TableBody";
import TableCell from "@mui/material/TableCell";
import TableContainer from "@mui/material/TableContainer";
import TableHead from "@mui/material/TableHead";
import TableRow from "@mui/material/TableRow";
import { camelToTitle } from "jsx/utils";
import { Fragment, useState } from "react";

import SecurityModal from "./seller-security-modal";

export default function SecuritiesTable({ securities, pricePerShare }) {
  const [showCert, setShowCert] = useState();
  const [selectedSecurity, setSelectedSecurity] = useState();

  function showSecurityModal(security) {
    setSelectedSecurity(security);
    setShowCert(true);
  }

  return (
    <>
      <SecurityModal
        open={showCert}
        onClose={() => setShowCert(false)}
        security={selectedSecurity}
      />
      <TableContainer>
        <Table aria-label="simple table">
          <TableHead>
            <TableRow>
              <TableCell>Certificate</TableCell>
              <TableCell align="right">Sellable units</TableCell>
              <TableCell align="right">Total sellable</TableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            {securities.map((security, i) => {
              return (
                <Fragment key={i}>
                  {security.securities.map((s, j) => {
                    return (
                      <TableRow
                        hover={true}
                        key={j}
                        onClick={() => {
                          showSecurityModal(s);
                        }}
                        sx={{ cursor: "pointer" }}
                      >
                        <TableCell sx={{ paddingLeft: 4 }}>
                          {s.formattedSecurityId}
                        </TableCell>
                        <TableCell align="right">
                          {s.meta.sellable.toLocaleString()}
                        </TableCell>
                        <TableCell align="right">
                          {pricePerShare && (
                            <>
                              {(pricePerShare * s.meta.sellable).toLocaleString(
                                "en-US",
                                {
                                  style: "currency",
                                  currency: "USD",
                                  maximumFractionDigits: 0,
                                }
                              )}
                              <br />
                            </>
                          )}
                        </TableCell>
                      </TableRow>
                    );
                  })}
                </Fragment>
              );
            })}
          </TableBody>
        </Table>
      </TableContainer>
    </>
  );
}
