import PageTitle from "jsx/element/page-title";
import Footer2 from "jsx/layout/footer2";
import Header2 from "jsx/layout/header2";
import Sidebar from "jsx/layout/sidebar";
import React from "react";
import { Link } from "react-router-dom";

function Browse() {
  return (
    <>
      <Header2 />
      <Sidebar />
      <PageTitle />

      <div className="content-body">
        <div className="container-fluid">
          <div className="row">
            <div className="col-xl-12">
              <div className="card">
                <div className="card-header border-0">
                  <h4 className="card-title">Top Companies</h4>
                </div>
                <div className="card-body pt-2">
                  <div className="market-table">
                    <div className="table-responsive">
                      <table className="table mb-0 table-responsive-sm">
                        <thead>
                          <tr>
                            <th>Name</th>
                            <th>Price</th>
                            <th>Change</th>
                            <th>Chart</th>
                            <th>Trade</th>
                          </tr>
                        </thead>
                        <tbody>
                          <tr>
                            <td className="coin_icon">
                              <i className="cc BTC"></i>
                              <span>
                                Stripe <b>BTC</b>
                              </span>
                            </td>

                            <td>USD 680,175.06</td>
                            <td>
                              <span className="text-success">+1.13%</span>
                            </td>
                            <td>
                              {" "}
                              <span className="sparkline8"></span>
                            </td>
                            <td>
                              <Link to={"#"} className="btn btn-success">
                                Buy
                              </Link>
                            </td>
                          </tr>
                          <tr>
                            <td className="coin_icon">
                              <i className="cc ETH"></i>
                              <span>
                                Gusto <b>ETH</b>
                              </span>
                            </td>

                            <td>USD 680,175.06</td>
                            <td>
                              <span className="text-success">+1.13%</span>
                            </td>
                            <td>
                              {" "}
                              <span className="sparkline8"></span>
                            </td>
                            <td>
                              <Link to={"#"} className="btn btn-success">
                                Buy
                              </Link>
                            </td>
                          </tr>
                          <tr>
                            <td className="coin_icon">
                              <i className="cc BCH-alt"></i>
                              <span>
                                Stripe Cash <b>BCH</b>
                              </span>
                            </td>

                            <td>USD 680,175.06</td>
                            <td>
                              <span className="text-success">+1.13%</span>
                            </td>
                            <td>
                              {" "}
                              <span className="sparkline8"></span>
                            </td>
                            <td>
                              <Link to={"#"} className="btn btn-success">
                                Buy
                              </Link>
                            </td>
                          </tr>
                          <tr>
                            <td className="coin_icon">
                              <i className="cc LTC"></i>
                              <span>
                                Brex <b>LTC</b>
                              </span>
                            </td>

                            <td>USD 680,175.06</td>
                            <td>
                              <span className="text-danger">-0.47%</span>
                            </td>
                            <td>
                              {" "}
                              <span className="sparkline8"></span>
                            </td>
                            <td>
                              <Link to={"#"} className="btn btn-success">
                                Buy
                              </Link>
                            </td>
                          </tr>
                        </tbody>
                      </table>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>

      <Footer2 />
    </>
  );
}

export default Browse;
