import Rescale from "jsx/pages/company/fundraise/fundraise-view-deal";
import ModernShare from "jsx/pages/company/fundraise/fundraise-view-deal-ms";
import { useParams } from "react-router-dom";

function FundraiseViewDealHack() {
  const { organizationId } = useParams();
  const content =
    organizationId === "u92KtqP2ZQUJxfGuvFfT" ? <Rescale /> : <ModernShare />;
  return content;
}

export default FundraiseViewDealHack;
