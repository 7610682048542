import {
  collection,
  doc,
  getDoc,
  getDocs,
  getFirestore,
} from "@firebase/firestore";
import { post } from "jsx/api";
import { UserContext } from "jsx/contexts/user-context";
import ManageSecondariesNav from "jsx/element/manage-secondaries-nav";
import SecondaryStakeholderTable from "jsx/element/secondary-stakeholder-table";
import { isProduction } from "jsx/helpers";
import Header4 from "jsx/layout/header4";
import React, { useContext, useEffect, useState } from "react";

function CompanySecondaryManageEmployees({ match }) {
  const { selectedOrganization } = useContext(UserContext);
  const [secondary, setSecondary] = useState({});
  const [stakeholders, setStakeholders] = useState([]);
  const [secondaryOrders, setSecondaryOrders] = useState([]);
  const [secondaryEmployees, setSecondaryEmployees] = useState([]);

  const [loading, setIsLoading] = useState(false);

  const { secondaryId } = match.params;

  const db = getFirestore();

  // Get secondary information along with stakeholder, secondary seller, secondary order
  // data, secondary transactions
  async function fetchSecondary(organizationId, secondaryId) {
    setIsLoading(true);

    const fetchSecondary = async () => {
      const secondarySnap = await getDoc(
        doc(
          db,
          `organizations/${organizationId}/organizations_private/data/secondaries/${secondaryId}`
        )
      );
      const secondaryData = secondarySnap.data();
      setSecondary(secondaryData);
      return secondaryData;
    };

    const fetchEmployees = async () => {
      const employeesSnapshot = await getDocs(
        collection(
          db,
          `organizations/${organizationId}/organizations_private/data/stakeholders`
        )
      );
      const stakeholders = employeesSnapshot.docs.map((doc) => ({
        id: doc.id,
        ...doc.data(),
      }));
      setStakeholders(
        stakeholders.filter((stakeholder) =>
          ["employee", "ex_employee"].includes(stakeholder.role)
        )
      );
      return stakeholders;
    };

    const fetchSellers = async () => {
      const sellersSnapshot = await getDocs(
        collection(
          db,
          `organizations/${organizationId}/organizations_admin/data/secondaries/${secondaryId}/secondary_sellers`
        )
      );

      const secondarySellers = sellersSnapshot.docs.map((seller) => ({
        id: seller.id,
        ...seller.data(),
      }));
      return secondarySellers;
    };

    const fetchOrders = async () => {
      const secondaryOrdersSnapshot = await getDocs(
        collection(
          db,
          `organizations/${organizationId}/organizations_admin/data/secondaries/${secondaryId}/secondary_orders`
        )
      );
      const secondaryOrders = secondaryOrdersSnapshot.docs.map((doc) => ({
        id: doc.id,
        ...doc.data(),
      }));
      setSecondaryOrders(secondaryOrders);
      return secondaryOrders;
    };

    const [secondaryData, stakeholders, secondarySellers, secondaryOrders] =
      await Promise.all([
        fetchSecondary(),
        fetchEmployees(),
        fetchSellers(),
        fetchOrders(),
      ]);

    const secondaryEmployees = secondarySellers.flatMap((secondarySeller) => {
      const stakeholder = stakeholders.find(
        (stakeholder) => secondarySeller.email === stakeholder.primaryEmail
      );

      if (secondarySeller) {
        const securitiesSelling = secondaryOrders
          .filter(
            (order) =>
              order.userId === secondarySeller.userId &&
              order.status === "open" &&
              order.orderType === "sell"
          )
          .reduce((acc, { quantity }) => acc + quantity, 0);
        return [{ ...stakeholder, ...secondarySeller, securitiesSelling }];
      } else {
        return [];
      }
    });

    setSecondaryEmployees(secondaryEmployees);
    setIsLoading(false);
  }

  async function addSellersToSecondary(sellers) {
    // Disable Rescale till we're ready to go
    const orgSnap = await getDoc(
      doc(db, "organizations", selectedOrganization)
    );
    const { companyName } = orgSnap.data();
    if (!(isProduction() && companyName.toLowerCase() === "rescale")) {
      await post("secondaries/addSellers", {
        organizationId: selectedOrganization,
        secondaryId,
        sellers,
      });
    }
    await fetchSecondary(selectedOrganization, secondaryId);
  }

  function onSecondaryEmployeesUpdate(stakeholders) {
    setSecondaryEmployees(stakeholders);
  }

  useEffect(() => {
    if (
      selectedOrganization &&
      secondaryId &&
      Object.entries(secondary).length === 0
    ) {
      fetchSecondary(selectedOrganization, secondaryId);
    }
  }, [selectedOrganization, secondaryId]);

  return (
    <>
      <Header4
        hideAllMenuItems
        backButtonLink={"/org/secondaries/manage"}
        pageTitle="Manage Secondary"
      />

      <div className="content-body section-padding">
        <div className="container">
          <div className="d-flex">
            <div className="me-4" style={{ minWidth: "220px" }}>
              <ManageSecondariesNav secondaryId={secondaryId} />
            </div>
            <div className="col-xl-10">
              <div className="flex-column">
                <div className="card outlined">
                  <div className="card-header">
                    <h4 className="card-title">Add participants</h4>
                  </div>

                  <div className="card-body">
                    <SecondaryStakeholderTable
                      secondary={secondary}
                      onAddEmployees={addSellersToSecondary}
                      stakeholders={stakeholders}
                      secondaryEmployees={secondaryEmployees}
                      organizationId={selectedOrganization}
                      secondaryId={secondaryId}
                      loading={loading}
                      onSecondaryEmployeesUpdate={onSecondaryEmployeesUpdate}
                    />
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
}

export default CompanySecondaryManageEmployees;
