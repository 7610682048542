import { useField } from "formik";
import React from "react";
function FileInput({ name, ...props }) {
  const [field, meta, helpers] = useField(name);
  const { setValue } = helpers;
  const handleChange = (event) => {
    if (event.target.files !== undefined) {
      setValue(
        event.target.files[0] !== undefined ? event.target.files[0] : ""
      );
    }
  };
  return (
    <div
      className={
        "form-control d-flex flex-row align-items-center " + props.className
      }
    >
      <input
        {...props}
        type="file"
        onChange={handleChange}
        onBlur={handleChange}
      />
    </div>
  );
}

export default FileInput;
