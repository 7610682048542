import moment from "moment";

export default function SellerExpirationBar({ secondary }) {
  function deadlineString(activeSecondary, condensed = true) {
    if (!activeSecondary) {
      return "";
    }
    const hasExpired = moment(activeSecondary.endDate._seconds * 1000).isBefore(
      new Date()
    );

    const prefix = hasExpired ? "Offer expired –" : "Deadline –";

    if (condensed) {
      return `${prefix} ${moment(
        activeSecondary.endDate._seconds * 1000
      ).format("MMM D YYYY, h:mm A z")}`;
    } else {
      return `${prefix} ${
        activeSecondary &&
        moment(activeSecondary.endDate._seconds * 1000).format("MMM D YYYY")
      }`;
    }
  }

  return (
    <div className="bar position-fixed w-100" style={{ zIndex: 99, top: 80 }}>
      <div className="container">
        <div style={{ paddingTop: "12px" }} className="text-center">
          {deadlineString(secondary)}
        </div>
      </div>
    </div>
  );
}
