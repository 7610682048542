import React from "react";

const UserContext = React.createContext({
  user: {
    displayName: "",
    photoURL: "",
    uid: "",
    email: "",
    organizations: [],
  },
  company: {},
});

export { UserContext };
