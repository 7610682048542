import Skeleton from "@mui/material/Skeleton";
import Tooltip from "@mui/material/Tooltip";
import { ChatWidget } from "@papercups-io/chat-widget";
import amplitude from "amplitude-js";
import {
  addDoc,
  collection,
  doc,
  getDoc,
  getDocs,
  getFirestore,
  query,
  serverTimestamp,
  setDoc,
  where,
} from "firebase/firestore";
import { post } from "jsx/api";
import { UserContext } from "jsx/contexts/user-context";
import MSButton from "jsx/element/button";
import FundraiseOfferSlider from "jsx/element/fundraise-offer-slider";
import PDFViewer from "jsx/element/pdf-viewer";
import Header5 from "jsx/layout/header5";
import React, { useEffect, useRef, useState } from "react";
import { useParams } from "react-router-dom";

function FundraiseViewDeal(props) {
  const [offset, setOffset] = useState(0);
  const { hasProcessedAuth, user } = React.useContext(UserContext);
  const [round, setRound] = useState();
  const [organization, setOrganization] = useState({});
  const [isEdit, setIsEdit] = useState(false);
  const [admin, setAdmin] = useState();

  const [rescalePdf, setRescalePdf] = useState();
  const [rescalePdf2, setRescalePdf2] = useState();

  const { organizationId, roundId } = useParams();
  const [isTooltipOpen, setIsTooltipOpen] = useState(false);
  const copyTextInput = useRef(null);

  const [success, setSuccess] = useState(false);

  const [investmentRequest, setInvestmentRequest] = useState();
  const [investmentRequestId, setInvestmentRequestId] = useState();

  const isRoundClosed =
    round && round.endDate && Date.now() > round.endDate.seconds * 1000;

  useEffect(() => {
    window.onscroll = () => {
      setOffset(window.pageYOffset);
    };
    amplitude.getInstance().init("a2c07ba04b14df0fbc73b31aa3e2e6d6");
  }, []);

  const db = getFirestore();
  let sliderContent = (
    <>
      {!investmentRequest && (
        <FundraiseOfferSlider
          success={success}
          onSubmit={createInvestmentRequest}
        />
      )}

      {isEdit && investmentRequest && (
        <FundraiseOfferSlider
          success={success}
          onSubmit={createInvestmentUpdateRequest}
          investmentAmount={investmentRequest.investmentAmount}
          maxPrice={parseFloat(investmentRequest.maxPrice)}
        />
      )}

      {investmentRequest && !isEdit && (
        <div>
          <div className="card-header">
            <h3>Submitted</h3>
            {!isRoundClosed && (
              <MSButton
                className="me-2"
                title="Edit"
                type="light small"
                onClick={() => {
                  setIsEdit(true);
                }}
              />
            )}
          </div>
          <div className="card-body">
            Rescale will receive your request to invest.{" "}
            {isRoundClosed &&
              "The investment deadline has passed and you are unable to make anymore edits at this time."}
            <h6 className="form-label mb-0">Max Price</h6>
            <p style={{ marginBottom: "-8px" }}>{investmentRequest.maxPrice}</p>
            <h6 className="form-label mb-0">Investment Amount</h6>
            <p>${investmentRequest.investmentAmount.toLocaleString()}</p>
          </div>
        </div>
      )}
      <div className="row">
        <Tooltip
          title="Saved link to clipboard"
          open={isTooltipOpen}
          disableFocusListener
          disableHoverListener
          disableTouchListener
        >
          <a
            href="#"
            onClick={copyLink}
            className="text-center mt-0 mb-2"
            style={{ fontWeight: "500", cursor: "pointer" }}
          >
            Share this deal – Copy link
          </a>
        </Tooltip>
        <input
          hidden
          ref={copyTextInput}
          className="form-control w-100"
          value={`${window.location}`}
        />
      </div>
    </>
  );
  if (!round) {
    sliderContent = (
      <>
        <div>
          <Skeleton height={80} />
          <Skeleton height={250} />
        </div>
      </>
    );
  } else if (!investmentRequest && isRoundClosed) {
    sliderContent = (
      <>
        <div className="card-header">
          <h3>Round closed</h3>
        </div>
        <div className="card-body">
          Rescale is no longer accepting investment requests at this time. The
          last date to invest in this round was{" "}
          {new Date(round.endDate.seconds * 1000).toLocaleString("en-US", {
            timeZone: "America/Los_Angeles",
            month: "long",
            day: "numeric",
            hour: "numeric",
            minute: "numeric",
            second: "numeric",
            hour12: true,
          })}{" "}
          PST.
          <br />
          <br /> If you have already put in an investment request, Rescale will
          review the offers and respond to your request shortly.
        </div>
      </>
    );
  }

  useEffect(() => {
    fetchInvestmentRequest();
    if (user.uid) {
      amplitude.getInstance().setUserId(user.uid);
      amplitude.getInstance().logEvent("Fundraise Round - Viewed", {
        organizationId,
        roundId,
        userId: user.uid,
        displayName: user.displayName,
        email: user.email,
      });
    }
  }, [user.uid]);

  useEffect(() => {
    if (user.uid) {
      post(`followRound`, { organizationId, roundId });
    }
  }, [hasProcessedAuth]);

  async function fetchInvestmentRequest() {
    if (!user.uid) {
      return;
    }
    const q = query(
      collection(
        db,
        `users/${user.uid}/users_private/data/investment_requests`
      ),
      where("organizationId", "==", organizationId)
    );
    const querySnapshot = await getDocs(q);

    if (querySnapshot.docs.length > 0) {
      setInvestmentRequestId(querySnapshot.docs[0].id);
      setInvestmentRequest(querySnapshot.docs[0].data());
    }
  }

  function copyLink() {
    setIsTooltipOpen(true);
    setTimeout(() => {
      setIsTooltipOpen(false);
    }, 2000);
    /* Select the text field */
    copyTextInput.current.select();
    copyTextInput.current.setSelectionRange(0, 99999); /* For mobile devices */

    /* Copy the text inside the text field */
    navigator.clipboard.writeText(copyTextInput.current.value);
    amplitude.getInstance().logEvent("Fundraise Round - Copy Link", {
      organizationId,
      roundId,
      userId: user.uid,
      displayName: user.displayName,
      email: user.email,
    });
  }

  async function fetchRound() {
    const res = await getDoc(
      doc(
        db,
        "organizations",
        organizationId,
        "organizations_private",
        "data",
        "rounds",
        roundId
      )
    );

    if (res) {
      setRound(res.data());
    }
  }

  async function fetchOrganization() {
    const res = await getDoc(doc(db, "organizations", organizationId));

    if (res) {
      setOrganization(res.data());
    }
  }

  async function getPdf() {
    const params = {
      filename: "rescale.pdf",
    };
    const result = await post(`pdf`, params);

    setRescalePdf(result.data);
  }

  async function getPdf2() {
    const params = {
      filename: "rescale_overview.pdf",
    };
    const result = await post(`pdf`, params);

    setRescalePdf2(result.data);
  }

  async function checkAccrediation() {
    if (!user.uid) {
      return;
    }

    const adminData = (
      await getDoc(doc(db, "users", user.uid, "users_admin", "data"))
    ).data();
    setAdmin(adminData);

    fetchRound();
    fetchOrganization();
    getPdf();
    getPdf2();
  }

  async function createInvestmentRequest(investmentAmount, maxPrice) {
    const params = {
      investorId: user.uid,
      investmentAmount: investmentAmount,
      maxPrice: maxPrice,
      investorName: user.displayName,
      investorEmail: user.email,
      roundId: roundId,
      organizationId: organizationId,
      createdDate: serverTimestamp(),
    };

    await addDoc(
      collection(
        db,
        "users",
        user.uid,
        "users_private",
        "data",
        "investment_requests"
      ),
      params
    );
    await fetchInvestmentRequest();
    amplitude
      .getInstance()
      .logEvent("Fundraise Round - Submit Investment Request", {
        organizationId,
        roundId,
        userId: user.uid,
        displayName: user.displayName,
        email: user.email,
        maxPrice,
        investmentAmount,
      });
  }

  async function createInvestmentUpdateRequest(investmentAmount, maxPrice) {
    const params = {
      investmentAmount: investmentAmount,
      maxPrice: maxPrice,
      updatedDate: serverTimestamp(),
    };

    await setDoc(
      doc(
        db,
        "users",
        user.uid,
        "users_private",
        "data",
        "investment_requests",
        investmentRequestId
      ),
      params,
      { merge: true }
    );
    await fetchInvestmentRequest();
    setIsEdit(false);
  }

  useEffect(() => {
    checkAccrediation();
  }, [user.uid]);

  return (
    <>
      <Header5 />
      {admin && (
        <>
          <div className="content-body" style={{ paddingTop: "80px" }}>
            <div className="bar  position-fixed w-100" style={{ zIndex: 99 }}>
              <div className="container">
                <div style={{ paddingTop: "12px" }} className="text-center">
                  Investment Deadline – Dec 3rd, 11:59PM PST
                </div>
              </div>
            </div>
            <div className="container">
              <div className="row">
                <div
                  className="col-xl-8 col-md-6 top-content"
                  style={{ paddingTop: "100px" }}
                >
                  <div className="outlined card">
                    <div className="card-header">
                      <img
                        src="https://rescale.com/wp-content/uploads/rescale_dark_logo.svg"
                        alt="rescale"
                        style={{ height: "40px" }}
                      />
                      <a href="https://rescale.com/">
                        <button className="btn btn-small btn-outline waves-effect mt-0 light">
                          Website
                        </button>
                      </a>
                    </div>
                    <div className="card-body">
                      <div>
                        <h4 className="mt-0">About</h4>
                        <p>
                          Rescale is the developer of a hybrid HPC cloud
                          platform enabling intelligent computing for digital
                          R&D.
                        </p>

                        <h4 className="mt-0">Questions</h4>
                        <p>
                          For any company requests or questions, email Rescale
                          Investor Relations at
                          <a href="mailto:ir@rescale.com"> ir@rescale.com</a>
                        </p>
                      </div>
                    </div>
                  </div>
                </div>

                <div className="col-xl-8 col-md-8">
                  <PDFViewer file={rescalePdf} showWatermark={true} />
                </div>

                <div className="col-xl-8 col-md-8">
                  <PDFViewer file={rescalePdf2} showWatermark={true} />
                </div>

                <div className={"col-12 d-sm-none"}>
                  <div className="card">
                    <div className="card outlined">{sliderContent}</div>
                  </div>
                </div>

                <div className={"col-4 "}>
                  <div
                    className="card position-fixed d-none d-sm-block"
                    style={{ top: "180px" }}
                  >
                    <div className="card outlined fixed-size">
                      {sliderContent}
                    </div>
                  </div>
                </div>

                <div className="col-xl-8 col-md-8 mb-5">
                  <p className="small">
                    This is solely for information purposes and not offering of
                    security. The company will have sole discretion in
                    determining the acceptance of investment requests, and
                    allocation amounts, which will only be made pursuant to
                    definitive agreement.
                  </p>
                </div>
              </div>
            </div>
          </div>
        </>
      )}

      {/* 
            <Bottom />

            <Footer1 /> */}
      <ChatWidget
        // `accountId` is used instead of `token` in older versions
        // of the @papercups-io/chat-widget package (before v1.2.x).
        // You can delete this line if you are on the latest version.
        // accountId="a7012e56-3103-4b84-a651-c6f3e379085c"
        token="a7012e56-3103-4b84-a651-c6f3e379085c"
        inbox="5e418ce4-0b89-429b-ad0e-803a77ad2dfb"
        title="Welcome to Modern Share"
        subtitle="Having any trouble? Write us a message below."
        primaryColor="#1890ff"
        greeting="This is Vu from Modern Share. Let me know if you need any help or if you encounter any issues using the software."
        newMessagePlaceholder="Start typing..."
        showAgentAvailability={false}
        agentAvailableText="We're online right now!"
        agentUnavailableText="We're away at the moment."
        requireEmailUpfront={false}
        iconVariant="outlined"
        baseUrl="https://app.papercups.io"
        // Optionally include data about your customer here to identify them
        // customer={{
        //   name: __CUSTOMER__.name,
        //   email: __CUSTOMER__.email,
        //   external_id: __CUSTOMER__.id,
        //   metadata: {
        //     plan: "premium"
        //   }
        // }}
      />
    </>
  );
}

export default FundraiseViewDeal;
