import { doc, getDoc, getFirestore } from "@firebase/firestore";
import Skeleton from "@mui/material/Skeleton";
import SecondaryDocumentRow from "jsx/element/secondary-document-row";
import Header4 from "jsx/layout/header4";
import React, { useEffect, useState } from "react";

import WaitingForSignatures from "../company/secondaries/docs/secondaries-waiting-for-secondaries";

function InvestorSecondaryTransaction({ match }) {
  const [transaction, setTransaction] = useState();

  const { organizationId, secondaryId, transactionId } = match.params;

  const db = getFirestore();

  async function fetchTransaction(organizationId, secondaryId, transactionId) {
    const transactionSnapshot = await getDoc(
      doc(
        db,
        "organizations",
        organizationId,
        "organizations_admin",
        "data",
        "secondaries",
        secondaryId,
        "secondary_transactions",
        transactionId
      )
    );
    const transactionData = transactionSnapshot.data();
    setTransaction(transactionData);
    return transactionData;
  }

  async function fetchData() {
    await fetchTransaction(organizationId, secondaryId, transactionId);
  }

  useEffect(() => {
    fetchData();
  }, [organizationId, secondaryId, transactionId]);

  const [originalPriceInBaseUnits, originalPricePrecision] =
    transaction?.securityDetails.securityCategory === "optionGrant"
      ? [
          transaction?.securityDetails.strikePriceInBaseUnits,
          transaction?.securityDetails.strikePricePrecision,
        ]
      : [
          transaction?.securityDetails.purchasePriceInBaseUnits,
          transaction?.securityDetails.purchasePricePrecision,
        ];
  const originalPrice = originalPriceInBaseUnits / originalPricePrecision;
  const originalPriceFormat = {
    style: "currency",
    currency: "USD",
    minimumFractionDigits: Math.max(2, Math.log10(originalPricePrecision ?? 2)),
  };

  const transferDocId = transaction?.transferDocumentId;

  let transactionSection;

  if (transaction) {
    transactionSection = (
      <div className="card-body">
        <div className="mt-3 mb-3">
          <h4>Security</h4>
        </div>
        <div className="card outlined-small-thin">
          <div className="row">
            <div className="col">
              <Detail title="Security name">
                {transaction.securityDetails.certificateNo}
              </Detail>
            </div>
            <div className="col">
              <Detail
                title={
                  transaction.securityDetails.securityCategory === "optionGrant"
                    ? "Strike price"
                    : "Purchase price"
                }
              >
                {originalPrice.toLocaleString(undefined, originalPriceFormat)}
              </Detail>
            </div>
            <div className="col">
              <Detail title="Quantity">
                {transaction.quantity.toLocaleString()}
              </Detail>
            </div>
            <div className="col">
              <Detail title="Total">
                {(
                  (transaction.pricePerShareInBaseUnits /
                    transaction.pricePerSharePrecision) *
                  transaction.quantity
                ).toLocaleString(undefined, {
                  style: "currency",
                  currency: "USD",
                })}
              </Detail>
            </div>
          </div>
        </div>
        {transferDocId && (
          <>
            <WaitingForSignatures
              isWaiting={transaction.isSignedByBuyer}
              transaction={transaction}
              documentPreviewSection={
                <SecondaryDocumentRow
                  organizationId={organizationId}
                  secondaryId={secondaryId}
                  transactionId={transactionId}
                  documentId={transferDocId}
                  onDocumentVoid={fetchData}
                />
              }
            />
          </>
        )}
      </div>
    );
  } else {
    transactionSection = (
      <>
        <Skeleton height={50} style={{ marginTop: 50, marginBottom: -20 }} />
        <Skeleton height={120} />
        <Skeleton height={50} style={{ marginTop: 0, marginBottom: -20 }} />
        <Skeleton height={120} />
        <Skeleton height={50} style={{ marginTop: 0, marginBottom: -20 }} />
        <Skeleton height={120} />
      </>
    );
  }

  return (
    <>
      <Header4 hideAllMenuItems />

      <div className="content-body section-padding">
        <div className="container">
          <div className="row justify-content-center align-items-center">
            <div className="col-10">
              <div className="card outlined">
                <div className="card-header">
                  <h4 className="card-title">Transaction</h4>
                </div>
                {transactionSection}
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
}

// helper component for formatting details/info
function Detail({ title, children }) {
  return (
    <div className="p-2">
      <div>
        <h6>{title}</h6>
      </div>
      <div>{children}</div>
    </div>
  );
}

export default InvestorSecondaryTransaction;
