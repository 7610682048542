import {
  collection,
  doc,
  getDoc,
  getDocs,
  getFirestore,
  onSnapshot,
  setDoc,
} from "@firebase/firestore";
import { regular } from "@fortawesome/fontawesome-svg-core/import.macro";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { InputAdornment } from "@mui/material";
import Dialog from "@mui/material/Dialog";
import DialogActions from "@mui/material/DialogActions";
import Table from "@mui/material/Table";
import TableBody from "@mui/material/TableBody";
import TableCell from "@mui/material/TableCell";
import TableContainer from "@mui/material/TableContainer";
import TableHead from "@mui/material/TableHead";
import TableRow from "@mui/material/TableRow";
import Tooltip from "@mui/material/Tooltip";
import { post } from "jsx/api";
import { getBaseUrl } from "jsx/api";
import { UserContext } from "jsx/contexts/user-context";
import MSButton from "jsx/element/button";
import Divider from "jsx/element/divider";
import FilesViewer from "jsx/element/files-viewer";
import ManageTendersNav from "jsx/element/manage-tenders-nav";
import MSField from "jsx/element/ms-field";
import TenderParticipantTable from "jsx/element/tender-participant-table";
import { isProduction } from "jsx/helpers";
import { formatPrice } from "jsx/helpers";
import Header6 from "jsx/layout/header6";
import moment from "moment";
import React, { useContext, useEffect, useRef, useState } from "react";
import { Link } from "react-router-dom";

export default function CompanyTenderManageDataroom({ match }) {
  const { selectedOrganization } = useContext(UserContext);
  const [secondary, setSecondary] = useState({});
  const [sellerDocuments, setSellerDocuments] = useState([]);
  const [stakeholders, setStakeholders] = useState([]);
  const [secondaryOrders, setSecondaryOrders] = useState([]);
  const [secondaryEmployees, setSecondaryEmployees] = useState([]);

  const [loading, setIsLoading] = useState(false);

  const { secondaryId } = match.params;

  const db = getFirestore();

  // Get secondary information along with stakeholder, secondary seller, secondary order
  // data, secondary transactions
  async function fetchSecondary(organizationId, secondaryId) {
    setIsLoading(true);

    const fetchSecondary = async () => {
      const secondarySnap = await getDoc(
        doc(
          db,
          `organizations/${organizationId}/organizations_private/data/secondaries/${secondaryId}`
        )
      );
      const secondaryData = secondarySnap.data();
      setSecondary(secondaryData);
      setSellerDocuments(secondaryData.sellerDocuments ?? []);
      return secondaryData;
    };

    const fetchEmployees = async () => {
      const employeesSnapshot = await getDocs(
        collection(
          db,
          `organizations/${organizationId}/organizations_private/data/stakeholders`
        )
      );
      const stakeholders = employeesSnapshot.docs.map((doc) => ({
        id: doc.id,
        ...doc.data(),
      }));
      setStakeholders(
        stakeholders.filter((stakeholder) =>
          ["employee", "ex_employee"].includes(stakeholder.role)
        )
      );
      return stakeholders;
    };

    const fetchSellers = async () => {
      const sellersSnapshot = await getDocs(
        collection(
          db,
          `organizations/${organizationId}/organizations_admin/data/secondaries/${secondaryId}/secondary_sellers`
        )
      );

      const secondarySellers = sellersSnapshot.docs.map((seller) => ({
        id: seller.id,
        ...seller.data(),
      }));
      return secondarySellers;
    };

    const fetchOrders = async () => {
      const secondaryOrdersSnapshot = await getDocs(
        collection(
          db,
          `organizations/${organizationId}/organizations_admin/data/secondaries/${secondaryId}/secondary_orders`
        )
      );
      const secondaryOrders = secondaryOrdersSnapshot.docs.map((doc) => ({
        id: doc.id,
        ...doc.data(),
      }));
      setSecondaryOrders(secondaryOrders);
      return secondaryOrders;
    };

    const [secondaryData, stakeholders, secondarySellers, secondaryOrders] =
      await Promise.all([
        fetchSecondary(),
        fetchEmployees(),
        fetchSellers(),
        fetchOrders(),
      ]);

    const secondaryEmployees = secondarySellers.flatMap((secondarySeller) => {
      const stakeholder = stakeholders.find(
        (stakeholder) => secondarySeller.email === stakeholder.primaryEmail
      );

      if (secondarySeller) {
        const securitiesSelling = secondaryOrders
          .filter(
            (order) =>
              order.userId === secondarySeller.userId &&
              order.status === "open" &&
              order.orderType === "sell"
          )
          .reduce((acc, { quantity }) => acc + quantity, 0);
        return [{ ...stakeholder, ...secondarySeller, securitiesSelling }];
      } else {
        return [];
      }
    });

    setSecondaryEmployees(secondaryEmployees);
    setIsLoading(false);
  }

  async function addSellersToSecondary(sellers) {
    // Disable Rescale till we're ready to go
    const orgSnap = await getDoc(
      doc(db, "organizations", selectedOrganization)
    );
    const { companyName } = orgSnap.data();
    if (!(isProduction() && companyName.toLowerCase() === "rescale")) {
      await post("secondaries/addSellers", {
        organizationId: selectedOrganization,
        secondaryId,
        sellers,
      });
    }
    await fetchSecondary(selectedOrganization, secondaryId);
  }

  function onSecondaryEmployeesUpdate(stakeholders) {
    setSecondaryEmployees(stakeholders);
  }

  useEffect(() => {
    if (
      selectedOrganization &&
      secondaryId &&
      Object.entries(secondary).length === 0
    ) {
      fetchSecondary(selectedOrganization, secondaryId);
    }
  }, [selectedOrganization, secondaryId]);

  async function uploadSellerDocument(e) {
    var formData = new FormData();
    var file = e.target.files[0];
    formData.append("file", file);
    formData.append("organizationId", selectedOrganization);
    formData.append("secondaryId", secondaryId);
    await post("secondaries/uploadSellerDocument", formData, {
      headers: {
        "Content-Type": "multipart/form-data",
      },
    });
    await fetchSecondary(selectedOrganization, secondaryId);
  }

  async function deleteSellerDocument(sellerDocumentId) {
    const newSellerDocuments = secondary.sellerDocuments.filter((item) => {
      return item.documentId !== sellerDocumentId;
    });

    await setDoc(
      doc(
        db,
        "organizations",
        selectedOrganization,
        "organizations_private",
        "data",
        "secondaries",
        secondaryId
      ),
      { sellerDocuments: newSellerDocuments },
      { merge: true }
    );
    await fetchSecondary(selectedOrganization, secondaryId);
  }

  return (
    <>
      <Header6 />
      <div className="content-body section-padding">
        <div className="container">
          <div className="d-flex">
            <div className="me-4" style={{ minWidth: "220px" }}>
              <ManageTendersNav secondaryId={secondaryId} />
            </div>
            <div className="col-xl-10">
              <div className="flex-column">
                <div className="card outlined">
                  <div className="card-header">
                    <div>
                      <h4 className="card-title">Dataroom</h4>
                      <p className="small mt-3">
                        Make offer documents and disclosure materials accessible
                        to participants by uploading them to our secure dataroom
                      </p>
                    </div>
                  </div>
                  <div className="card-body">
                    <div className="col-12">
                      <SellerDocuments
                        deleteSellerDocument={deleteSellerDocument}
                        sellerDocuments={sellerDocuments}
                        uploadSellerDocument={uploadSellerDocument}
                      />
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
}

function SellerDocuments({
  deleteSellerDocument,
  sellerDocuments,
  uploadSellerDocument,
}) {
  const { user } = useContext(UserContext);
  const [documentDeleteId, setDocumentDeleteId] = useState();
  return (
    <>
      <div className="mb-2">
        <div className="d-flex mt-4 mb-5">
          <div className="flex-grow-1">
            <div className="d-flex justify-content-center align-items-center">
              <MSButton
                onClick={() => {
                  document.getElementById("seller-document-upload").click();
                }}
                size="small"
                title="Upload Offer Documents and Disclosure Materials (pdf only)"
                startIcon={<FontAwesomeIcon icon={regular("upload")} />}
              />
              <input
                id="seller-document-upload"
                type="file"
                accept=".pdf"
                style={{ display: "none" }}
                onChange={(e) => {
                  uploadSellerDocument(e);
                }}
              />
            </div>
          </div>
        </div>

        {sellerDocuments.length > 0 && (
          <FilesViewer
            files={sellerDocuments}
            user={user}
            editMode
            onDelete={setDocumentDeleteId}
          />
        )}
      </div>

      <Dialog
        open={!!documentDeleteId}
        onClose={() => {
          setDocumentDeleteId();
        }}
        aria-labelledby="alert-dialog-title"
        aria-describedby="alert-dialog-description"
      >
        <div className="p-4">
          <h3>Delete this file?</h3>
          <p className="mb-0">
            Deleting this file will also delete the analytics with it.
          </p>
        </div>

        <DialogActions className="me-3 mb-3">
          <MSButton
            title="Cancel"
            onClick={() => {
              setDocumentDeleteId();
            }}
            size="small"
          >
            Cancel
          </MSButton>
          <MSButton
            title="Delete"
            variant="danger"
            onClick={() => {
              setDocumentDeleteId();
              deleteSellerDocument(documentDeleteId);
            }}
            size="small"
            autoFocus
          >
            Delete
          </MSButton>
        </DialogActions>
      </Dialog>
    </>
  );
}
