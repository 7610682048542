import React from "react";

import Reset from "./reset-confirmation";
import VerifyEmailConfirmation from "./verify-email-confirmation";
const queryString = require("query-string");

function AuthActions(props) {
  const parsed = queryString.parse(props.location.search);
  const mode = parsed.mode;
  const oobCode = parsed.oobCode;

  if (mode === "verifyEmail") {
    return <VerifyEmailConfirmation oobCode={oobCode} />;
  } else if (mode === "resetPassword") {
    return <Reset oobCode={oobCode} />;
  } else {
    return <></>;
  }
}

export default AuthActions;
