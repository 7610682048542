import { Formik } from "formik";
import React from "react";
import { useHistory, useLocation } from "react-router-dom";
import * as Yup from "yup";

import CommonSharesFormContext from "./common-shares-form-context";
import routes from "./routes.json";
function useQuery() {
  return new URLSearchParams(useLocation().search);
}
function AddShares({ onAdd }) {
  const query = useQuery();
  const history = useHistory();
  const AddSharesSchema = Yup.object().shape({
    email: Yup.string().email("Invalid email").required("Required"),
    // stackHolderType: Yup.string().required('Required'),
    firstName: Yup.string().when("stackHolderType", {
      is: "individual",
      then: Yup.string()
        .min(2, "Too Short!")
        .max(50, "Too Long!")
        .required("Required"),
    }),
    lastName: Yup.string().when("stackHolderType", {
      is: "individual",
      then: Yup.string()
        .min(2, "Too Short!")
        .max(50, "Too Long!")
        .required("Required"),
    }),
    organizationName: Yup.string().when("stackHolderType", {
      is: "organization",
      then: Yup.string().required("Required"),
    }),
    securityHolderRelationship: Yup.string().required("Required"),
    relationShipStartDate: Yup.string().required("Required"),
    boardApprovalDate: Yup.string().required("Required"),
    issueDate: Yup.string().required("Required"),
    numberOfShares: Yup.string().required("Required"),
    currency: Yup.string().required("Required"),
    legend: Yup.string().required("Required"),
  });
  return (
    <Formik
      initialValues={{
        email: "",
        stackHolderType: "individual",
        firstName: "",
        lastName: "",
        organizationName: "",
        securityHolderRelationship: "",
        relationShipStartDate: "",
        boardApprovalDate: "",
        issueDate: "",
        numberOfShares: "",
        currency: "",
        paymentForShares: {
          values: {
            cashPaid: 0,
            debtCanceled: 0,
            ipTransfered: 0,
            other: 0,
          },
          total: 0,
        },
        vestingSchedule: "",
        vestingStartDate: "",
        accelerationTerms: "",
        legend: "default",
        documents: {},
      }}
      validationSchema={AddSharesSchema}
      onSubmit={(values) => {
        onAdd(query.get("id"), values);
        history.push(routes.view);
      }}
    >
      {({
        values,
        handleChange,
        handleBlur,
        handleSubmit,
        errors,
        touched,
      }) => (
        <>
          <div className="card-header d-flex flex-column align-items-start mb-2">
            <h4 className="card-title">Add Shares</h4>
            <code>{query.get("id")}</code>
          </div>
          <small>
            The information you provide below should reflect how shares exist
            today. Once you sign and activate your account, Carta will add the
            share class to your cap table and issue certificates to your
            shareholders in electronic form.
          </small>
          <CommonSharesFormContext
            config={{
              values,
              handleChange,
              handleBlur,
              handleSubmit,
              errors,
              touched,
            }}
          >
            <button type="submit">Add share</button>
          </CommonSharesFormContext>
        </>
      )}
    </Formik>
  );
}
export default AddShares;
