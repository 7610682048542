import { useField } from "formik";
import React, { useState } from "react";
import MaskedInput from "react-text-mask";
import createNumberMask from "text-mask-addons/dist/createNumberMask";

/*Created by Dumitru Cucu
This currency input is now available to be implemented to anything using isForm={false} */

/*Found this on https://codesandbox.io/s/react-currency-input-with-react-text-mask-4es23?from-embed=&file=/src/CurrencyInput.js */
const defaultMaskOptions = {
  prefix: "",
  suffix: "",
  includeThousandsSeparator: true,
  thousandsSeparatorSymbol: ",",
  allowDecimal: true,
  decimalSymbol: ".",
  decimalLimit: 2, // how many digits allowed after the decimal
  integerLimit: null, // limit length of integer numbers
  allowNegative: false,
  allowLeadingZeroes: false,
};

const CustomInput = ({ maskOptions, ...inputProps }) => {
  const currencyMask = createNumberMask({
    ...defaultMaskOptions,
    ...maskOptions,
  });

  return <MaskedInput mask={currencyMask} {...inputProps} />;
};

CurrencyInput.defaultProps = {
  inputMode: "numeric",
  maskOptions: {},
};

export default function CurrencyInput({ name, form, ...props }) {
  const [field, meta, helpers] = useField(name);
  const { setValue } = helpers;

  const [currency, setCurrency] = useState("$");
  const [amount, setAmount] = useState("");
  const handleChange = (event) => {
    console.log(currency);
    if (amount.length > 0) {
      setValue(amount + event.target.value);
    }
    setCurrency(event.target.value);
  };
  const changeHandler = (e) => {
    setAmount(e.target.value);
    if (e.target.value.length > 0) {
      setValue(e.target.value + currency);
    } else {
      setValue("");
    }
  };
  return (
    <div
      className={
        "form-control d-flex justify-content-start align-items-center " +
        props.className
      }
    >
      <select value={currency} onChange={handleChange} className="currency-box">
        <option value="$">$</option>
        <option value="€">€</option>
        <option value="£">£</option>
        <option value="₨">₨</option>
        <option value="₹">₹</option>
      </select>
      <CustomInput
        name={name}
        {...props}
        value={field.value}
        onChange={changeHandler}
        onBlur={changeHandler}
        className="floating-input"
      />
    </div>
  );
}
