import React from "react";
import { Nav, Navbar } from "react-bootstrap";
import { Link } from "react-router-dom";

function Header3() {
  return (
    <>
      <div className="header">
        <div className="container">
          <div className="row">
            <div className="col-xl-12">
              <div className="navigation">
                <Navbar bg="light" expand="lg">
                  <Link className="navbar-brand" to={"/"}>
                    <img src={require("./../../images/logo.png")} alt="" />
                  </Link>
                  <Navbar.Toggle aria-controls="basic-navbar-nav" />
                  <Navbar.Collapse>
                    <Nav className="me-auto">
                      <Nav.Item>
                        <a href="#intro">Home</a>
                      </Nav.Item>
                      <Nav.Item>
                        <a href="#demo">Demo</a>
                      </Nav.Item>
                      <Nav.Item>
                        <a href="#support">Suppport</a>
                      </Nav.Item>
                    </Nav>
                  </Navbar.Collapse>

                  <div className="signin-btn">
                    <a href="" className="btn btn-primary" target="blank">
                      Buy now
                    </a>
                  </div>
                </Navbar>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
}

export default Header3;
