import { collection, getDocs, getFirestore } from "@firebase/firestore";
import { regular } from "@fortawesome/fontawesome-svg-core/import.macro";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import Table from "@mui/material/Table";
import TableBody from "@mui/material/TableBody";
import TableCell from "@mui/material/TableCell";
import TableContainer from "@mui/material/TableContainer";
import TableHead from "@mui/material/TableHead";
import TableRow from "@mui/material/TableRow";
import { UserContext } from "jsx/contexts/user-context";
import MSButton from "jsx/element/button";
import MSDropdown, { MSDropdownItem } from "jsx/element/dropdown";
import { formatPrice } from "jsx/helpers";
import moment from "moment";
import React, { useState } from "react";
import { Modal } from "react-bootstrap";
import { Link } from "react-router-dom";

const clickableCellStyle = { paddingTop: 16, paddingBottom: 16 };

export default function EmployeeActionButton({
  handleAddEmployees,
  handleRemoveEmployees,
  showRemoveEmployees = false,
  disabled,
}) {
  // Get org's latest imports for stakeholders and securities
  const [modalOpen, setModalOpen] = useState(false);
  const [secondaries, setSecondaries] = useState([]);
  const [loading, setLoading] = useState(true);
  const { selectedOrganization } = React.useContext(UserContext);
  const db = getFirestore();

  const openModal = (importType) => (_event) => {
    setModalOpen(true);
  };

  async function selectSecondary(secondaryId) {
    if (loading) {
      return;
    }
    setLoading(true);
    await handleAddEmployees(secondaryId);
    setModalOpen(false);
    setLoading(false);
  }

  React.useEffect(() => {
    if (!selectedOrganization) {
      return;
    }
    fetchSecondaries();
  }, [selectedOrganization]);

  async function fetchSecondaries() {
    if (!selectedOrganization) {
      return;
    }
    const querySnapshot = await getDocs(
      collection(
        db,
        `organizations/${selectedOrganization}/organizations_private/data/secondaries`
      )
    );
    setSecondaries(querySnapshot.docs);
    setLoading(false);
  }

  const secondariesRows = secondaries.map((doc) => {
    const secondary = doc.data();

    return (
      <TableRow
        onClick={() => {
          selectSecondary(doc.id);
        }}
        key={doc.id}
      >
        <TableCell>
          <span>{secondary.name}</span>
        </TableCell>
        <TableCell>
          {moment(secondary.startDate.toDate()).format("M/D/YYYY")}
        </TableCell>
        <TableCell>
          <span>{moment(secondary.endDate.toDate()).format("M/D/YYYY")}</span>
        </TableCell>
        <TableCell>
          {secondary.pricePerShareInBaseUnits &&
            formatPrice(
              secondary.pricePerShareInBaseUnits,
              secondary.pricePerSharePrecision
            )}
          {!secondary.pricePerShareInBaseUnits &&
            secondary.minPriceInBaseUnits &&
            secondary.maxPriceInBaseUnits &&
            `${formatPrice(
              secondary.minPriceInBaseUnits,
              secondary.minPricePrecision
            )} - ${formatPrice(
              secondary.maxPriceInBaseUnits,
              secondary.maxPricePrecision
            )}`}
        </TableCell>
      </TableRow>
    );
  });

  let dropDownOptions;

  if (!showRemoveEmployees) {
    dropDownOptions = (
      <MSDropdownItem onClick={openModal("securities")}>
        Add to secondary
      </MSDropdownItem>
    );
  } else {
    dropDownOptions = (
      <MSDropdownItem onClick={handleRemoveEmployees}>
        Remove from secondary
      </MSDropdownItem>
    );
  }

  return (
    <>
      <MSDropdown
        disabled={disabled}
        title="Actions"
        className="profile-log d-none d-sm-block"
        style={{ maxWidth: "300px" }}
        buttonProps={{
          variant: "primary",
          sx: { paddingTop: "6px", paddingBottom: "6px" },
        }}
      >
        {dropDownOptions}
      </MSDropdown>

      <Modal
        show={modalOpen}
        onHide={() => setModalOpen(false)}
        contentClassName="larger-modal"
        dialogClassName="larger-modal"
      >
        <Modal.Header
          style={{
            borderBottomColor: "var(--purple-light)",
            marginLeft: -24,
            marginRight: -24,
            paddingLeft: 40,
            paddingRight: 40,
          }}
        >
          <h3>Add stakeholders to a secondary</h3>
          {loading && (
            <div
              className="spinner-border float-end"
              style={{ position: "absolute", right: 32, top: 24 }}
            />
          )}
          <MSButton
            variant="icon"
            style={{ width: 40, height: 40, marginTop: -18 }}
            onClick={() => {
              setModalOpen(false);
            }}
          >
            <FontAwesomeIcon icon={regular("close")} size="xs" />
          </MSButton>
        </Modal.Header>
        {secondaries.length > 0 && (
          <div>
            <div className="card-body">
              <TableContainer>
                <Table aria-label="simple table">
                  <TableHead>
                    <TableRow>
                      <TableCell>Name</TableCell>
                      <TableCell>Start Date</TableCell>
                      <TableCell>End Date</TableCell>
                      <TableCell>Price per share</TableCell>
                    </TableRow>
                  </TableHead>
                  <TableBody
                    sx={{
                      "& .MuiTableRow-root": {
                        transition: "background 0.5",
                        color: "red",
                        bottomBorder: "none",
                        cursor: "pointer",
                      },
                      "& .MuiTableRow-root:hover": {
                        background: "var(--background)",
                        cursor: "pointer",
                      },
                    }}
                  >
                    {secondariesRows}
                  </TableBody>
                </Table>
              </TableContainer>
            </div>
          </div>
        )}
        {secondaries.length === 0 && (
          <div style={{ padding: 16, fontSize: 16 }}>
            No secondaries created yet. You must create one first before you are
            able to add stakeholders to it.
          </div>
        )}
      </Modal>
    </>
  );
}
