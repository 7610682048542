import React from "react";

function StepNumber({ number }) {
  return (
    <div
      style={{
        left: -5,
        top: -5,
        position: "absolute",
        color: "var(--secondary)",
        border: "2px solid #eae9f9",
        background: "var(--purple-light)",
        width: 25,
        height: 25,
        borderRadius: 3,
        textAlign: "center",
      }}
    >
      {number}
    </div>
  );
}

function SecondaryExplainerBuyerContent() {
  return (
    <>
      <div className="mt-4 mb-4">
        <h4 className="mt-4">Overview</h4>

        <p className="mb-3">
          Rescale is partnering with Modern Share to facilitate a secondary
          equity sale.
        </p>
        <p className="mb-3">
          As an existing investor, you are invited to increase your holdings and
          make an offer to acquire Rescale employee shares. A{" "}
          <a
            style={{ textDecoration: "underline" }}
            href="https://www.investopedia.com/terms/d/dutchauction.asp"
          >
            Dutch auction
          </a>{" "}
          will be used to determine the base price and allocation, which will be
          communicated to the employees (sellers) participating in the round.
          Sellers will have 7 days to decide whether to participate in the round
          and how many shares to sell.
        </p>
        <p>
          After sellers select shares, transactions will be drafted and
          documents shared with relevant parties to sign. Upon document signing,
          buyers will send funds via wire transfers and the capitalization table
          updated, completing the transaction.
        </p>
      </div>

      <h4 className="mt-5">Auction Process</h4>
      <ol>
        <li>
          Buyers place bids with a share price and a desired quantity of shares.
          A buyer may submit more than one bid.
        </li>
        <li>Bids are sorted from highest price to lowest.</li>
        <li>
          The total shares available for purchase are then allocated starting
          from the highest priced bid, until there are no more shares available.
        </li>
        <li>
          The winning bids are those which have received an allocation. The
          final clearing price for all winning bids will be the price of the
          first bid not fully allocated.
        </li>
      </ol>

      <h4 className="mb-4 mt-5">Timeline</h4>
      <div style={{ position: "relative" }}>
        <div
          style={{
            width: 2,
            position: "absolute",
            background: "var(--purple-light)",
            marginLeft: 48,
            bottom: 0,
            top: 0,
          }}
        ></div>
        <div className="card outlined-small py-4">
          <StepNumber number={1} />
          <div style={{ flexDirection: "row", display: "flex" }}>
            <div>
              <h5 className="mb-1">Buyer auction</h5>
            </div>
            <div style={{ flexGrow: 1 }}>
              <p className="mb-0 small ms-2">11/29 - 12/13</p>
            </div>
          </div>
          <p className="mt-2">
            Prospective buyers place bids to determine the price of the shares.
          </p>
        </div>
        <div className="card outlined-small py-4">
          <StepNumber number={2} />
          <div style={{ flexDirection: "row", display: "flex" }}>
            <div>
              <h5 className="mb-1">Seller election</h5>
            </div>
            <div style={{ flexGrow: 1 }}>
              <p className="mb-0 small ms-2">12/14 - 12/21</p>
            </div>
          </div>
          <p className="mt-2">
            Sellers elect how many shares they wish to sell at the determined
            price.
          </p>
        </div>
        <div className="card outlined-small py-4">
          <StepNumber number={3} />
          <div style={{ flexDirection: "row", display: "flex" }}>
            <div>
              <h5 className="mb-1">Matching and document signing</h5>
            </div>
            <div style={{ flexGrow: 1 }}>
              <p className="mb-0 small ms-2">12/23 - 12/27</p>
            </div>
          </div>
          <p className="mt-2">
            Orders between buyers and sellers are matched. Transfer agreement
            documents are drafted and sent to all relevant parties for signing.
          </p>
        </div>
        <div className="card outlined-small py-4">
          <StepNumber number={4} />
          <div style={{ flexDirection: "row", display: "flex" }}>
            <div>
              <h5 className="mb-1">Wiring and closing</h5>
            </div>
            <div style={{ flexGrow: 1 }}>
              <p className="mb-0 small ms-2">12/28 - 12/29</p>
            </div>
          </div>
          <p className="mt-2">
            Wires transfers are processed, signatures are released and final cap
            tables updates are made
          </p>
        </div>
      </div>

      <h4 className="mt-5">FAQ</h4>
      <div className="mb-4">
        <b>How many shares are available to buy?</b>
        <p>
          Sellers are provided the opportunity to sell a preset percentage of
          their vested holdings. Once a price is set, sellers elect which shares
          (if any) they would like to sell.
        </p>
      </div>

      <div className="mb-4">
        <b>
          What if the final price is above my bid price? Will I be able to
          change my bid?
        </b>
        <p>
          No. If the final clearing price is above your bid price, then you will
          not be able to participate in the secondary round.
        </p>
      </div>
      <div className="mb-4">
        <b>Is there a way to see outstanding bids and sell orders?</b>
        <p>
          No, this is a sealed auction. Bids will not be revealed to other
          bidders.
        </p>
      </div>
      <div className="mb-4">
        <b>Why am I able to place more than one bid?</b>
        <p>
          This allows you to fully express your preferences. For example, you
          may bid for a large number of shares at a lower price, and also place
          a bid for fewer shares at a higher price.
        </p>
      </div>
      <div>
        <b>Will there be any restrictions or conditions on shares bought?</b>
        <p>
          Shares sold by sellers may have restrictions depending on share
          selection by sellers. You will have an opportunity to review
          conditions, if any, before signing.
        </p>
      </div>
    </>
  );
}

export default SecondaryExplainerBuyerContent;
