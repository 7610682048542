import { Skeleton } from "@mui/material";
import { get } from "jsx/api";
import { useEffect, useState } from "react";
import ReactApexChart from "react-apexcharts";

export default function TenderCohorts({ secondaryId, organizationId }) {
  const [cohortData, setCohortData] = useState();
  const [selectedIndex, setSelectedIndex] = useState(-1);

  let content;
  let userBreakdown;

  if (cohortData) {
    const data = {
      options: {
        plotOptions: {
          bar: {
            dataLabels: {
              position: "top", // top, center, bottom
            },
          },
        },
        dataLabels: {
          enabled: true,
          formatter: function (val) {
            return `${val} / ${cohortData.invitedSellers.length}`;
          },
          offsetY: -20,
          style: {
            fontSize: "13px",
            fontWeight: "600",
            colors: ["#304758"],
          },
        },

        colors: ["#7B6FFF", "#1652F0"],
        chart: {
          id: "basic-bar",
          toolbar: {
            show: false,
          },
          events: {
            dataPointSelection: (event, chartContext, config) => {
              setSelectedIndex(config.dataPointIndex);
            },
          },
        },
        xaxis: {
          categories: [
            "Invited",
            "Signed up",
            "Viewed documents",
            "Drafted order",
            "Completed order",
          ],
        },
      },
      series: [
        {
          name: "Participants",
          data: [
            cohortData.invitedSellers.length,
            cohortData.signedUpSellers.length,
            cohortData.viewedDocsSellers.length,
            cohortData.draftedSellers.length,
            cohortData.submittedSellers.length,
          ],
        },
      ],
    };

    content = (
      <>
        <ReactApexChart
          style={{ marginTop: "-8px", marginBottom: "-8px" }}
          options={data.options}
          series={data.series}
          type="bar"
          height={300}
        />
      </>
    );

    if (selectedIndex > -1) {
      let selectedCohort;

      if (selectedIndex === 0) {
        selectedCohort = cohortData.invitedSellers;
      } else if (selectedIndex === 1) {
        selectedCohort = cohortData.signedUpSellers;
      } else if (selectedIndex === 2) {
        selectedCohort = cohortData.viewedDocsSellers;
      } else if (selectedIndex === 3) {
        selectedCohort = cohortData.draftedSellers;
      } else if (selectedIndex === 4) {
        selectedCohort = cohortData.submittedSellers;
      }

      console.log("SELECTEDCOHORT", selectedCohort);

      userBreakdown = selectedCohort.map((e, i) => {
        console.log(e);
        return UserCell(e.email, e.name, i === selectedCohort.length - 1);
      });
    }
  } else {
    content = (
      <>
        <Skeleton
          height={400}
          variant="rectangular"
          style={{ marginTop: 32 }}
        />
      </>
    );
  }

  async function fetchCohortAnalysis() {
    if (!secondaryId || !secondaryId) {
      return;
    }

    const result = await get("secondaries/getCohortAnalysis", {
      secondaryId,
      organizationId,
    });

    setCohortData(result.data);
  }

  useEffect(() => {
    fetchCohortAnalysis();
  }, [secondaryId, organizationId]);

  return (
    <>
      {content}
      {selectedIndex > -1 && (
        <>
          <h4>Users</h4>
          <div style={{ border: "1px solid #DDD" }}>{userBreakdown}</div>
        </>
      )}
    </>
  );
}

function UserCell(email, name, last) {
  return (
    <div
      className="col-12 d-flex py-3"
      style={{ borderBottom: last ? "" : "1px solid #DDD" }}
    >
      <div className="col-4" style={{ fontSize: 14, marginLeft: 16 }}>
        {name}
      </div>
      <div className="col-8 " style={{ fontSize: 14 }}>
        {email}
      </div>
    </div>
  );
}
