import Header4 from "jsx/layout/header4";
import React from "react";

import SecondaryExplainerBuyerContent from "./secondary-explainer-buyer-content";

function SecondaryExplainerBuyer() {
  return (
    <>
      <Header4 />
      <div className="content-body section-padding">
        <div className="container">
          <div className="row justify-content-center align-items-center">
            <div className="col-10">
              <div className="card outlined">
                <div className="card-header">
                  <h2 className="card-title">The secondary process</h2>
                </div>

                <div className="card-body">
                  <SecondaryExplainerBuyerContent />
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
}

export default SecondaryExplainerBuyer;
