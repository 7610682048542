import { regular } from "@fortawesome/fontawesome-svg-core/import.macro";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import MSButton from "jsx/element/button";
import ManageTendersNav from "jsx/element/manage-tenders-nav";
import TenderModelingTable from "jsx/element/tender-modeling-table";
import Header6 from "jsx/layout/header6";
import React, { useState } from "react";

function CompanyTenderModeling({ match }) {
  const [isFullscreen, setFullscreen] = useState(false);
  const { secondaryId } = match.params;

  return (
    <>
      <Header6 />

      {isFullscreen && (
        <MSButton
          variant="icon"
          onClick={() => {
            setFullscreen(!isFullscreen);
          }}
          style={{
            right: "32px",
            top: "24px",
            minWidth: 36,
            minHeight: 36,
            padding: "0px",
            zIndex: 999,
            position: "fixed",
          }}
        >
          <FontAwesomeIcon icon={regular("arrows-minimize")} size="xs" />
        </MSButton>
      )}

      <div
        className="content-body section-padding"
        style={{ marginTop: `${isFullscreen ? "-60px" : "0"}` }}
      >
        <div className={isFullscreen ? "" : "container"}>
          <div className="d-flex">
            {!isFullscreen && (
              <div className="me-4" style={{ minWidth: "220px" }}>
                <ManageTendersNav secondaryId={secondaryId} />
              </div>
            )}
            <div className={isFullscreen ? "col-xl-12" : "col-xl-11"}>
              <div className="flex-column">
                <div className="card outlined">
                  <div className="card-header">
                    <div>
                      <h4 className="card-title">Tender modeling</h4>
                      <p className="small mt-3">
                        Use the fields below to model scenarios for your tender
                        offer
                      </p>
                    </div>
                    <div>
                      <MSButton
                        variant="icon"
                        onClick={() => {
                          setFullscreen(!isFullscreen);
                        }}
                      >
                        <FontAwesomeIcon
                          icon={regular("arrows-maximize")}
                          size="xs"
                        />
                      </MSButton>
                    </div>
                  </div>

                  <div className="card-body">
                    <TenderModelingTable isFullscreen={isFullscreen} />
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
}

export default CompanyTenderModeling;
