import { Field, Form, Formik } from "formik";
import React from "react";
import * as Yup from "yup";

import SignatoryTitle from "./signatory-title.json";
function Signatories({ onReturn }) {
  /**This data must be generated by the server */
  const data = [
    {
      name: "Vu Tran",
    },
  ];
  const SignatoriesSchema = Yup.object().shape({
    firstSignatory: Yup.string().required("Required"),
    signatoryTitle: Yup.string().required("Required"),
    secondSignatory: Yup.string().required("Required"),
    secondSignatoryTitle: Yup.string().required("Required"),
    convertibleNotes: Yup.string().required("Required"),
  });
  return (
    <Formik
      initialValues={{
        firstSignatory: "",
        signatoryTitle: "",
        secondSignatory: "",
        secondSignatoryTitle: "",
        convertibleNotes: "",
      }}
      validationSchema={SignatoriesSchema}
      onSubmit={(values) => {
        onReturn(4, values);
      }}
    >
      {({ touched, errors, values }) => (
        <Form>
          <div className="card-header">
            <h4 className="card-title">Signatories</h4>
          </div>
          <div className="card-body">
            <div className="mb-3 col-xl-12">
              <h5 className="mb-2 mt-3">First signatory</h5>
              <Field
                component="select"
                name="firstSignatory"
                className={
                  "form-select " +
                  (touched.firstSignatory &&
                    errors.firstSignatory &&
                    "is-invalid")
                }
              >
                <option value={null}></option>
                {data.map((item) => {
                  return (
                    <option key={item.name} value={item.name}>
                      {item.name}
                    </option>
                  );
                })}
              </Field>
              {touched.firstSignatory && (
                <p className="text-danger">{errors.firstSignatory}</p>
              )}
            </div>
            <div className="mb-3 col-xl-12 mt-4">
              <h5 className="mb-2 mt-3">Signatory title</h5>
              <Field
                component="select"
                name="signatoryTitle"
                className={
                  "form-select " +
                  (touched.signatoryTitle &&
                    errors.signatoryTitle &&
                    "is-invalid")
                }
              >
                <option value={null}></option>
                {SignatoryTitle.map((item) => {
                  return (
                    <option key={item.value} value={item.value}>
                      {item.name}
                    </option>
                  );
                })}
              </Field>
              {touched.signatoryTitle && (
                <p className="text-danger">{errors.signatoryTitle}</p>
              )}
            </div>
            <div className="mb-3 col-xl-12 mt-5">
              <h5 className="mb-2 mt-3">Second signatory</h5>
              <Field
                component="select"
                name="secondSignatory"
                className={
                  "form-select " +
                  (touched.secondSignatory &&
                    errors.secondSignatory &&
                    "is-invalid")
                }
              >
                <option value={null}></option>
                {data.map((item) => {
                  return (
                    <option key={item.name} value={item.name}>
                      {item.name}
                    </option>
                  );
                })}
              </Field>
              {touched.secondSignatory && (
                <p className="text-danger">{errors.secondSignatory}</p>
              )}
            </div>
            <div className="mb-3 col-xl-12 mt-4">
              <h5 className="mb-2 mt-3">Signatory title</h5>
              <Field
                component="select"
                name="secondSignatoryTitle"
                className={
                  "form-select " +
                  (touched.secondSignatoryTitle &&
                    errors.secondSignatoryTitle &&
                    "is-invalid")
                }
              >
                <option value=""></option>
                {SignatoryTitle.map((item) => {
                  return (
                    <option key={item.value} value={item.value}>
                      {item.name}
                    </option>
                  );
                })}
              </Field>
              {touched.secondSignatoryTitle && (
                <p className="text-danger">{errors.secondSignatoryTitle}</p>
              )}
              <small>
                One person can act as both signatories. However, the signatory
                title must be different.
              </small>
            </div>
            <div className="mb-3 col-xl-12 mt-5">
              <h5 className="mb-2 mt-3">Convertible notes / SAFE signatory</h5>
              <Field
                component="select"
                name="convertibleNotes"
                className={
                  "form-select " +
                  (touched.convertibleNotes &&
                    errors.convertibleNotes &&
                    "is-invalid")
                }
              >
                <option value=""></option>
                {data.map((item) => {
                  return (
                    <option key={item.name} value={item.name}>
                      {item.name}
                    </option>
                  );
                })}
              </Field>
              {touched.convertibleNotes && (
                <p className="text-danger">{errors.convertibleNotes}</p>
              )}
              <small>
                After you complete this set-up guide, your signatories will be
                notified to sign certificates based off of the securities you
                shared in the previous step.
              </small>
            </div>
          </div>
          <button className="btn" type="submit">
            Submit
          </button>
        </Form>
      )}
    </Formik>
  );
}
export default Signatories;
