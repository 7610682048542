import { duotone } from "@fortawesome/fontawesome-svg-core/import.macro";
import { Skeleton } from "@mui/material";
import Table from "@mui/material/Table";
import TableBody from "@mui/material/TableBody";
import TableCell from "@mui/material/TableCell";
import TableContainer from "@mui/material/TableContainer";
import TableHead from "@mui/material/TableHead";
import TableRow from "@mui/material/TableRow";
import { get } from "jsx/api";
import { UserContext } from "jsx/contexts/user-context";
import EmptyState from "jsx/element/empty-state";
import SellerHeader from "jsx/layout/seller-header";
import React, { useEffect, useState } from "react";
import { useHistory } from "react-router-dom";

function Dashboard() {
  const { user } = React.useContext(UserContext);
  const [securities, setSecurities] = useState();
  const [activeSecondaries, setActiveSecondaries] = useState();

  useEffect(() => {
    fetchSecurities();
    fetchActiveSecondaries();
  }, [user.uid]);

  async function fetchSecurities() {
    if (!user.uid) {
      return;
    }
    const result = await get("users/listSecurities");
    setSecurities(result.data);
  }

  async function fetchActiveSecondaries() {
    const result = await get("users/getActiveSecondaries");
    setActiveSecondaries(result.data);
  }

  return (
    <>
      <SellerHeader />
      <div className="content-body section-padding">
        <div className="container">
          <div className="row">
            <div className="col-12">
              <div className="card outlined">
                <div className="card-header border-0">
                  <h4 className="card-title">Portfolio</h4>
                </div>
                <div className="card-body pt-2">
                  <Companies
                    securities={securities}
                    activeSecondaries={activeSecondaries}
                  />
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
}

function Companies({ securities, activeSecondaries }) {
  const history = useHistory();
  if (!securities) {
    return (
      <>
        <Skeleton height={50} />
        <Skeleton height={50} />
        <Skeleton height={50} />
      </>
    );
  } else if (securities.length) {
    return (
      <>
        <TableContainer>
          <Table aria-label="simple table">
            <TableHead>
              <TableRow>
                <TableCell>Company</TableCell>
                <TableCell align="right">Unit Price</TableCell>
                <TableCell align="right">Sellable</TableCell>
                <TableCell align="right">Total</TableCell>
              </TableRow>
            </TableHead>
            <TableBody
              sx={{
                "& .MuiTableRow-root": {
                  transition: "background 0.5",
                  color: "red",
                  bottomBorder: "none",
                },
                "& .MuiTableRow-root:hover": {
                  background: "var(--background)",
                },
              }}
            >
              {securities.map((security, i) => {
                let activeSecondary = (activeSecondaries || []).find((j) => {
                  return security.organizationId === j.organizationId;
                });
                let pricePerShare;

                if (activeSecondary && activeSecondary.length > 0) {
                  activeSecondary = activeSecondary[0];
                }

                if (
                  activeSecondary &&
                  activeSecondary.pricePerShareInBaseUnits &&
                  activeSecondary.pricePerSharePrecision
                ) {
                  pricePerShare =
                    activeSecondary.pricePerShareInBaseUnits /
                    activeSecondary.pricePerSharePrecision;
                }

                return (
                  <TableRow
                    sx={{ cursor: "pointer" }}
                    onClick={() =>
                      history.push(`/seller/orgs/${security.organizationId}`)
                    }
                    key={i}
                    hover={true}
                  >
                    <TableCell>{security.organizationName}</TableCell>
                    <TableCell align="right">
                      {pricePerShare
                        ? pricePerShare.toLocaleString("en-US", {
                            minimumFractionDigits: 2,
                            style: "currency",
                            currency: "USD",
                          })
                        : "-"}
                    </TableCell>
                    <TableCell align="right">
                      {pricePerShare && (
                        <>
                          {(
                            pricePerShare * security.overview.sellable
                          ).toLocaleString("en-US", {
                            maximumFractionDigits: 0,
                            style: "currency",
                            currency: "USD",
                          })}
                          <br />
                        </>
                      )}
                      {security.overview.sellable.toLocaleString("en-US")} units
                    </TableCell>
                    <TableCell align="right">
                      {pricePerShare && (
                        <>
                          {(
                            pricePerShare * security.overview.total
                          ).toLocaleString("en-US", {
                            style: "currency",
                            currency: "USD",
                            maximumFractionDigits: 0,
                          })}
                          <br />
                        </>
                      )}
                      {security.overview.total.toLocaleString()} units
                    </TableCell>
                  </TableRow>
                );
              })}
            </TableBody>
          </Table>
        </TableContainer>
      </>
    );
  } else {
    return (
      <EmptyState
        icon={duotone("coins")}
        style={{ marginTop: 24 }}
        title={"No companies to display"}
        subtitle={
          "Your portfolio will display here when your company updates your equity information"
        }
      />
    );
  }
}

export default Dashboard;
