import { regular } from "@fortawesome/fontawesome-svg-core/import.macro";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableRow,
} from "@mui/material";
import { format } from "date-fns";
import MSButton from "jsx/element/button";
import { Modal } from "react-bootstrap";

export default function SecurityModal({ security, onClose, open }) {
  if (!security) return <></>;
  // const pluralShareType =
  //   security.meta.securityCategory === "optionGrant" ? "options" : "shares";
  const pluralShareType = "units";
  return (
    <Modal
      show={open}
      onHide={onClose}
      contentClassName="larger-modal"
      dialogClassName="larger-modal"
    >
      <Modal.Header>
        <h3>Certificate details</h3>
        <MSButton
          variant="icon"
          style={{ width: 40, height: 40, marginTop: -18 }}
          onClick={() => {
            onClose();
          }}
        >
          <FontAwesomeIcon icon={regular("close")} size="xs" />
        </MSButton>
      </Modal.Header>
      <TableContainer>
        <Table>
          <TableBody>
            <TableRow>
              <TableCell>Certificate Name</TableCell>
              <TableCell align="right">{security.certificateNo}</TableCell>
            </TableRow>

            <TableRow>
              <TableCell>Security type</TableCell>
              <TableCell align="right">Profit Interest Units</TableCell>
            </TableRow>

            <TableRow>
              <TableCell>Quantity issued</TableCell>
              <TableCell align="right">
                {security.quantityIssued.toLocaleString()} {pluralShareType}
              </TableCell>
            </TableRow>

            <TableRow>
              <TableCell>Quantity outstanding</TableCell>
              <TableCell align="right">
                {security.quantityOutstanding?.toLocaleString() ?? 0}{" "}
                {pluralShareType}
              </TableCell>
            </TableRow>

            {/* <TableRow>
              <TableCell>Award type</TableCell>
              <TableCell align="right">
                {security.awardType ?? "Common"}
              </TableCell>
            </TableRow> */}
            {false && security.meta.securityCategory === "optionGrant" && (
              <>
                <TableRow>
                  <TableCell>Quantity exercisable</TableCell>
                  <TableCell align="right">
                    {(security.quantityExercisable ?? 0).toLocaleString()}
                  </TableCell>
                </TableRow>

                <TableRow>
                  <TableCell>Strike price</TableCell>
                  <TableCell align="right">$100MM</TableCell>
                </TableRow>
                {/* 
                <TableRow>
                  <TableCell>Exercise price</TableCell>
                  <TableCell align="right">
                    {(
                      security.exercisePriceInBaseUnits /
                      security.pricePerSharePrecision
                    ).toLocaleString("en-US", {
                      currency: "USD",
                      style: "currency",
                    })}
                  </TableCell>
                </TableRow> */}
              </>
            )}

            <TableRow>
              <TableCell>Grant date</TableCell>
              <TableCell align="right">
                {security.initiatedDate &&
                  format(
                    new Date(security.initiatedDate?._seconds * 1000),
                    "MM/dd/yyyy"
                  )}
              </TableCell>
            </TableRow>
          </TableBody>
        </Table>
      </TableContainer>
      {security.vestingStartDate && (
        <>
          <h4
            className="mt-4 ps-2 pb-3"
            style={{ borderBottom: "1px solid #e3e3eb" }}
          >
            Vesting
          </h4>
          <TableContainer>
            <Table>
              <TableBody>
                <TableRow>
                  <TableCell>Vesting start date</TableCell>
                  <TableCell align="right">
                    {format(
                      new Date(security.vestingStartDate._seconds * 1000),
                      "MM/dd/yyyy"
                    )}
                  </TableCell>
                </TableRow>
                <TableRow>
                  <TableCell>Schedule</TableCell>
                  <TableCell align="right">
                    {security.vestingSchedule}
                  </TableCell>
                </TableRow>
                <TableRow>
                  <TableCell>Quantity vested</TableCell>
                  <TableCell align="right">
                    {(security.cumulativeVested ?? 0).toLocaleString()}
                  </TableCell>
                </TableRow>
              </TableBody>
            </Table>
          </TableContainer>
        </>
      )}
    </Modal>
  );
}
