import {
  addDoc,
  collection,
  getFirestore,
  serverTimestamp,
} from "firebase/firestore";
import { UserContext } from "jsx/contexts/user-context";
import React, { useEffect, useState } from "react";
import { Tab, Tabs } from "react-bootstrap";

function BuySellElement(props) {
  const db = getFirestore();
  const { user } = React.useContext(UserContext);

  const [shares, setShares] = useState(0);

  useEffect(() => {});

  function onChange(e) {
    const re = /^[0-9\b]+$/;

    // if value is not blank, then test the regex

    if (e.target.value === "" || re.test(e.target.value)) {
      setShares(e.target.value);
    }
  }

  async function createOrder(order) {
    const docRef = await addDoc(
      collection(
        db,
        "users",
        user.uid,
        "users_private",
        "data",
        "users_orders"
      ),
      {
        contractId: props.contractId,
        companyId: props.companyId,
        numberOfShares: shares,
        buySell: order,
        price: props.marketPrice,
        status: "pending",
        createdDate: serverTimestamp(),
      }
    );
    console.log("ref", docRef);
  }

  return (
    <>
      <div className="buy-sell-widget">
        <Tabs defaultActiveKey="buy" id="uncontrolled-tab-example">
          <Tab eventKey="buy" title="Buy">
            <div className="mb-3">
              <label className="me-sm-2">Order method</label>
              <div className="input-group mb-3">
                <select name="currency" className="form-select">
                  <option value="market">Market Order</option>
                  <option value="limit">Limit Order</option>
                </select>
              </div>
            </div>
            <div className="mb-3">
              <label className="me-sm-2">Number of shares</label>
              <div className="input-group mb-3">
                <input
                  type="number"
                  name="usd_amount"
                  className="form-control"
                  placeholder="0"
                  onChange={onChange}
                  autoComplete="off"
                  value={shares ? shares : ""}
                />
              </div>
            </div>

            <div className="mb-3">
              <div className="d-flex justify-content-between mt-3">
                <p className="mb-0">Market Price</p>
                <h6 className="mb-0  mt-1">${props.marketPrice}</h6>
              </div>
              <div className="d-flex justify-content-between mt-3">
                <p className="mb-0">Estimated Cost</p>
                <h6 className="mb-0 mt-1">
                  ${(props.marketPrice * shares).toFixed(2)}
                </h6>
              </div>
            </div>
            <button
              type="submit"
              name="submit"
              onClick={() => {
                createOrder("buy");
              }}
              className="btn btn-success w-100"
            >
              Buy
            </button>
          </Tab>
          <Tab eventKey="sell" title="Sell">
            <div className="mb-3">
              <label className="me-sm-2">Currency</label>
              <div className="input-group mb-3">
                <select name="currency" className="form-control">
                  <option data-display="Stripe" value="Stripe">
                    Stripe
                  </option>
                  <option value="Brex">Brex</option>
                </select>
                <input
                  type="text"
                  name="usd_amount"
                  className="form-control"
                  defaultValue="125.00 USD"
                />
              </div>
            </div>

            <div className="mb-3">
              <label className="me-sm-2">Payment Method</label>
              <div className="input-group mb-3">
                <select name="currency" className="form-control">
                  <option data-display="Stripe" value="Stripe">
                    Stripe
                  </option>
                  <option value="Brex">Brex</option>
                </select>
                <input
                  type="text"
                  name="usd_amount"
                  className="form-control"
                  defaultValue="125.00 USD"
                />
              </div>
            </div>

            <div className="mb-3">
              <label className="me-sm-2">Enter your amount</label>
              <div className="input-group">
                <input
                  type="text"
                  name="currency_amount"
                  className="form-control"
                  placeholder="0.0214 BTC"
                />
                <input
                  type="text"
                  name="usd_amount"
                  className="form-control"
                  placeholder="125.00 USD"
                />
              </div>
              <div className="d-flex justify-content-between mt-3">
                <p className="mb-0">Monthly Limit</p>
                <h6 className="mb-0">$49750 remaining</h6>
              </div>
            </div>
            <button
              type="submit"
              name="submit"
              onClick={() => {
                createOrder("sell");
              }}
              className="btn btn-success w-100"
            >
              Sell
            </button>
          </Tab>
        </Tabs>
      </div>
    </>
  );
}

export default BuySellElement;
