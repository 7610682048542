import "react-dates/initialize";
import "react-dates/lib/css/_datepicker.css";

import SettingsNav from "jsx/element/contract-settings-nav";
import PageTitle from "jsx/element/page-title";
import Sidebar from "jsx/layout/company-sidebar";
import Footer2 from "jsx/layout/footer2";
import Header2 from "jsx/layout/header2";
import React, { useState } from "react";
import { SingleDatePicker } from "react-dates";

function Settings() {
  const [ruleExplanation, setRuleExplanation] = useState("");
  const [ruleTitle, setRuleTitle] = useState("");

  const next = () => {
    alert("next");
  };

  const processFocus = (e) => {
    if (e.target.name === "holding") {
      setRuleTitle("Holding period");
      setRuleExplanation(
        <div>
          <p>
            Holding period refers to the amount of time an investor must hold a
            share before they can sell the share.
          </p>
          <p>
            The standard holding period a company elects is usually 6 months.
          </p>
        </div>
      );
    } else if (e.target.name === "high") {
      setRuleTitle("High price");
      setRuleExplanation(
        <div>
          <p>
            High price is the maximum price an investor of the share can list
            the share price for.
          </p>
        </div>
      );
    }
  };

  return (
    <>
      <Header2 />
      <Sidebar />
      <PageTitle />

      <div className="content-body">
        <div className="container-fluid">
          <div className="row">
            <div className="col-xl-3 col-md-4">
              <SettingsNav />
            </div>
            <div className="col-xl-9 col-md-8">
              <div className="row">
                <div className="col-xl-6">
                  <div className="card">
                    <div className="card-header">
                      <h4 className="card-title">Trading rules</h4>
                    </div>
                    <div className="card-body">
                      <div className="row">
                        <div className="mb-3 col-xl-12">
                          <h5 className="mb-2 mt-3">Holding period</h5>
                          <input
                            type="number"
                            name="holding"
                            className="form-control"
                            placeholder="0 months"
                            autoFocus
                            onFocus={processFocus}
                          />
                        </div>

                        <div className="mb-3 col-xl-12">
                          <h5 className="mb-2 mt-3">Trading window</h5>
                          <SingleDatePicker />

                          <input
                            type="number"
                            className="form-control"
                            placeholder="0"
                            onFocus={processFocus}
                          />
                        </div>

                        <div className="mb-3 col-xl-12">
                          <h5 className="mb-2 mt-3">High price</h5>

                          <input
                            type="number"
                            name="high"
                            className="form-control"
                            placeholder="0"
                            onFocus={processFocus}
                          />
                        </div>

                        <div className="mb-3 col-xl-12">
                          <h5 className="mb-2 mt-3">Low price</h5>

                          <input
                            type="number"
                            className="form-control"
                            placeholder="0"
                            onFocus={processFocus}
                          />
                        </div>

                        <div className="col-12">
                          <button
                            className="btn btn-success waves-effect"
                            onClick={next}
                          >
                            Next
                          </button>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
                <div className="col-xl-4 col-lg-12 col-xxl-12">
                  <div className="card position-fixed" style={{ width: 300 }}>
                    <div className="card-header">
                      <h4 className="card-title">{ruleTitle}</h4>
                    </div>
                    <div className="card-body">
                      <div className="row">
                        <p>{ruleExplanation}</p>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>

      <Footer2 />
    </>
  );
}

export default Settings;
