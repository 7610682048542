import {
  addDoc,
  collection,
  getDocs,
  getFirestore,
  onSnapshot,
  orderBy,
  query,
  serverTimestamp,
} from "firebase/firestore";
import React, { useEffect, useState } from "react";

function Orders(props) {
  const [orders, setOrders] = useState([]);

  const db = getFirestore();

  var contractId = "seriesa";
  var companyId = "modernshare";

  function randomFloat(min, max) {
    return (Math.random() * (max - min) + min).toFixed(2);
  }

  function randomInt(min, max) {
    return Math.floor(Math.random() * (max - min + 1) + min);
  }

  async function createOrder(order, shares, price) {
    await addDoc(
      collection(
        db,
        "users",
        props.userId,
        "users_private",
        "data",
        "users_orders"
      ),
      {
        contractId: contractId,
        companyId: companyId,
        numberOfShares: shares,
        buySell: order,
        price: price,
        status: "pending",
        createdDate: serverTimestamp(),
      }
    );
  }

  async function listenForChanges() {
    const collectionRef = collection(
      db,
      "users",
      props.userId,
      "users_private",
      "data",
      "users_orders"
    );
    const q = query(collectionRef, orderBy("createdDate", "desc"));

    onSnapshot(q, (querySnapshot) => {
      processOrders(querySnapshot);
    });
  }

  function processOrders(querySnapshot) {
    var ret = [];
    querySnapshot.forEach((doc) => {
      const data = doc.data();
      ret.push(
        <tr key={doc.id}>
          <td>
            <span
              className={
                "badge badge-" + (data.buySell === "buy" ? "success" : "danger")
              }
            >
              {data.buySell}
            </span>
          </td>

          <td
            className={
              "text-" + (data.buySell === "buy" ? "success" : "danger")
            }
          >
            {data.numberOfShares} shares
          </td>
          <td>${data.price}</td>
          <td>{data.status}</td>
        </tr>
      );
    });

    setOrders(ret);
  }

  async function fetchUserOrders() {
    const collectionRef = collection(
      db,
      "users",
      props.userId,
      "users_private",
      "data",
      "users_orders"
    );
    const q = query(collectionRef, orderBy("createdDate", "desc"));
    const querySnapshot = await getDocs(q);
    processOrders(querySnapshot);
  }

  function makeRandomOrders() {
    const random = randomInt(1, 6);
    const buyOrSell = randomInt(0, 1) ? "buy" : "sell";

    createOrder(buyOrSell, randomInt(1, 300), randomFloat(9, 22));

    setTimeout(() => {
      makeRandomOrders();
    }, random * 1000);
  }

  useEffect(() => {
    fetchUserOrders();
    listenForChanges();
    makeRandomOrders();
  }, []);

  return (
    <>
      <table className="table table-trading">
        <thead>
          <tr>
            <th>Order</th>
            <th>Shares</th>
            <th>Price</th>
            <th>Status</th>
          </tr>
        </thead>
        <tbody>{orders}</tbody>
      </table>
    </>
  );
}

export default Orders;
