import { post } from "jsx/api";
import { UserContext } from "jsx/contexts/user-context";
import MSButton from "jsx/element/button";
import React, { useEffect, useState } from "react";
import { Link } from "react-router-dom";

function VerifyIdentity({ admin, location, type, isSignin }) {
  const { user } = React.useContext(UserContext);
  const [flow, setFlow] = useState();
  const [isShowingFlow, setIsShowingFlow] = useState(false);
  const [isVerificationError, setIsVerificationError] = useState(
    admin.kycStatus === "failed"
  );

  function openVerificationLink() {
    flow.open();
  }

  useEffect(() => {
    // Move this to secrets.json later, but doesn't matter bc it's publishable
    const isProd = window.location.host.includes("modernshare.com");

    const script = document.createElement("script");
    script.src = "https://cdn.cognitohq.com/flow.js";
    script.async = true;
    script.onload = () => {
      // eslint-disable-next-line
      const ref = new Flow({
        publishableKey: isProd
          ? "live_publishable_key_71c7c0f75600da8cb83a1a6859dfe2a4"
          : "sandbox_publishable_key_aeb398d2a771deee1e95f4ad9ccbe19e",
        templateId: isProd ? "flwtmp_be9nphFK347gMW" : "flwtmp_e4YiYqkpuQe9A1",
        user: {
          customerReference: user.uid,
          email: user.email,
        },
      });

      ref.on("all", (e) => {
        if (e.action === "closed") {
          setIsShowingFlow(false);
        }
        if (e.action === "opened") {
          setIsShowingFlow(true);
        }
        if (e.action === "failed") {
          setIsVerificationError(true);
        }
      });

      setFlow(ref);
    };

    document.body.appendChild(script);
  }, []);

  return (
    <>
      {!isShowingFlow && isVerificationError && (
        <div className="auth-form card">
          <div className="card-header">
            <h3>Error verifying identity</h3>
            <MSButton
              type="outlined light small"
              title="Debug skip"
              hidden={!window.location.href.includes("localhost")}
              onClick={async () => {
                await post("users/debug", { action: "verifyKYC" });
              }}
            />
          </div>
          <div
            className="card-body"
            style={{ paddingTop: 32, paddingBottom: 16 }}
          >
            <p>
              There was an error verifying your identity. Please contact support
              at help@modernshare.com.
            </p>
          </div>
        </div>
      )}
      {!isShowingFlow && !isVerificationError && (
        <div className="outlined card">
          <div className="card-header">
            <h3>Verify your identity</h3>
            <MSButton
              title="Debug skip"
              hidden={!window.location.href.includes("localhost")}
              onClick={async () => {
                await post("users/debug", { action: "verifyKYC" });
              }}
            />
          </div>
          <div
            className="card-body"
            style={{ paddingTop: 32, paddingBottom: 16 }}
          >
            <p>
              Before we can finish setting up your account, we must verify your
              identity. We've partnered with Cognito for a seamless experience.
            </p>
          </div>

          <MSButton
            size="large"
            onClick={openVerificationLink}
            title={"Verify"}
            variant="primary"
          />
          <Link
            style={{ margin: "0 auto", fontSize: 16, marginTop: 16 }}
            to={"/logout"}
          >
            Logout
          </Link>
        </div>
      )}
    </>
  );
}

export default VerifyIdentity;
