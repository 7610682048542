import Header4 from "jsx/layout/header4";
import React, { useEffect } from "react";

function TrustedContact() {
  useEffect(() => {
    window.scrollTo({
      top: 0,
      left: 0,
      behavior: "instant",
    });
  }, []);

  return (
    <>
      <Header4 hideAllMenuItems />

      <div className="terms_condition mt-4">
        <div className="container">
          <div className="row justify-content-center">
            <div className="col-lg-9">
              <div className="terms_condition-content">
                <h2>Trusted Contact Person</h2>
                <p>
                  A Trusted Contact Person is an individual age 18 and over whom
                  we may contact if we are unable to reach you or if there is
                  suspicious activity relating to your account. They will not be
                  included on any standard correspondence relating to your
                  transactions on the Modern Share platform. Regulatory rules
                  require us to ask if you would like to identify a trusted
                  contact person for your account. You are not required to add a
                  Trusted Contact Person to your account, and this will not
                  impact your ability to conduct transactions on the Modern
                  Share platform.
                </p>
                <p>
                  Modern Share and one or more of its associated persons are
                  authorized to contact your trusted contact person, disclose
                  information about your account to address possible financial
                  exploitation, confirm the specifics of your current contact
                  information or health status if we cannot reach you, or
                  confirm the identity of your legal guardian, executor, trustee
                  or holder of a power of attorney, or as otherwise permitted by
                  FINRA Rule 2165.
                </p>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
}

export default TrustedContact;
