import React from "react";

function Bottom() {
  return (
    <>
      <div className="bottom section-padding"></div>
    </>
  );
}

export default Bottom;
