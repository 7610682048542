import "react-dates/initialize";
import "react-dates/lib/css/_datepicker.css";

import {
  addDoc,
  collection,
  getFirestore,
  serverTimestamp,
} from "@firebase/firestore";
import { InputAdornment, MenuItem } from "@mui/material";
import { Field, Form, Formik } from "formik";
import { UserContext } from "jsx/contexts/user-context";
import MSButton from "jsx/element/button";
import FormError from "jsx/element/form-error";
import MSField from "jsx/element/ms-field";
import PageTitle from "jsx/element/page-title";
import Header4 from "jsx/layout/header4";
import React from "react";
import NumberFormat from "react-number-format";
import { useHistory } from "react-router-dom";
import * as Yup from "yup";

import rounds from "./rounds.json";

function CompanyFundraiseManageCreate() {
  const { selectedOrganization, organizations } = React.useContext(UserContext);

  const FundraiseSchema = Yup.object().shape({
    legalEntity: Yup.string().min(2, "Too short").required("Required"),
    roundType: Yup.string().required("Required"),
    instrument: Yup.string().required("Required"),
    mfn: Yup.bool().required("Required"),
    proRata: Yup.bool().required("Required"),
    //Conditional fields
    valuationCapType: Yup.string().required("Required"),
    cap: Yup.number().when("valuationCapType", {
      is: "capped",
      then: Yup.number()
        .positive("Must be a positive number")
        .required("Required"),
    }),
    safeType: Yup.string().when("instrument", {
      is: "safe",
      then: Yup.string().when("valuationCapType", {
        is: "capped",
        then: Yup.string().required("Required"),
      }),
    }),
    discount: Yup.number().required("Required"),
  });

  const db = getFirestore();

  const history = useHistory();

  return (
    <>
      <Header4 hideAllMenuItems title="Create round" />
      <PageTitle />
      <Formik
        initialValues={{
          legalEntity: organizations[selectedOrganization]
            ? organizations[selectedOrganization].legalEntity
            : "",
          roundType: "",
          instrument: "",
          mfn: "false",
          proRata: "false",
          otherTerms: "",
          //conditional
          valuationCapType: "",
          cap: 0,
          //needed in case if the instrument
          //is set to safe and valCap is set capped
          safeType: "",
          discount: 0,
        }}
        enableReinitialize={true}
        validationSchema={FundraiseSchema}
        onSubmit={async (values) => {
          const resetValue = (id) => {
            const type = typeof values[id];
            if (type === "string") {
              values[id] = "";
            }
            if (type === "number") {
              values[id] = 0;
            }
          };
          //Remove unused conditional values
          if (values.valuationCapType === "uncapped" && values.cap > 0) {
            resetValue("cap");
          }

          if (
            values.valuationCapType === "uncapped" &&
            values.safeType !== ""
          ) {
            resetValue("safeType");
          }

          values.createdDate = serverTimestamp();
          values.mfn = values.mfn === "true";
          values.proRata = values.proRata === "true";

          try {
            await addDoc(
              collection(
                db,
                "organizations",
                selectedOrganization,
                "organizations_private",
                "data",
                "rounds"
              ),
              values
            );
            history.push(`/org/fundraise/manage`);
          } catch (e) {
            console.log("error", e);
          }
        }}
      >
        {(formik) => {
          const { values, isSubmitting, touched, errors, setFieldValue } =
            formik;

          return (
            <Form>
              <div className="content-body">
                <div className="container mb-5">
                  <div className="row justify-content-center align-items-center">
                    <div className="col-xl-8 col-md-8">
                      <div className="row">
                        <div className="card outlined">
                          <div
                            className="card-header"
                            style={{ display: "block" }}
                          >
                            <h2>Create round</h2>
                            <p>
                              Setup a new fundraising round to generate
                              documents and invite investors
                            </p>
                          </div>
                          <div className="card-body">
                            <div className="row">
                              <div className="col-xl-12">
                                <MSField
                                  name="legalEntity"
                                  disabled
                                  formik={formik}
                                />
                              </div>
                              <div className="col-xl-12">
                                <MSField
                                  name="roundType"
                                  select
                                  formik={formik}
                                >
                                  {rounds.map((item) => {
                                    return (
                                      <MenuItem
                                        key={item.value}
                                        value={item.value}
                                      >
                                        {item.title}
                                      </MenuItem>
                                    );
                                  })}
                                </MSField>

                                {values.roundType === "secondary" && (
                                  <div className="info-box mt-3">
                                    <i className="fa fa-exclamation-triangle fa-lg warning-icon"></i>
                                    Secondary deals work differently,
                                    specifically: The minimum allocation
                                    requirement is increased to $250,000 We
                                    charge carry for all LPs (not only those you
                                    found on Modern Share) on a sliding scale
                                    based on investment size For more
                                    information, reach out to
                                    funds@saxcapital.co
                                  </div>
                                )}
                              </div>

                              <div className="col-xl-12">
                                <MSField
                                  name="instrument"
                                  select
                                  formik={formik}
                                >
                                  <MenuItem value="safe">SAFE</MenuItem>
                                  <MenuItem value="note">
                                    Convertible Note
                                  </MenuItem>
                                  {/* <option value="equity">Equity</option> */}
                                </MSField>
                              </div>
                              {(values.instrument === "note" ||
                                values.instrument === "safe") && (
                                <>
                                  <div className="col-xl-12">
                                    <MSField
                                      name="valuationCapType"
                                      select
                                      formik={formik}
                                    >
                                      <MenuItem value="capped">Capped</MenuItem>
                                      <MenuItem value="uncapped">
                                        Uncapped
                                      </MenuItem>
                                    </MSField>
                                  </div>
                                  {values.valuationCapType === "capped" && (
                                    <>
                                      <div className="col-xl-12">
                                        <NumberFormat
                                          name="cap"
                                          customInput={MSField}
                                          placeholder="0"
                                          isNumericString={true}
                                          thousandSeparator={true}
                                          allowNegative={false}
                                          onValueChange={(val) =>
                                            setFieldValue("cap", val.floatValue)
                                          }
                                          formik={formik}
                                          inputProps={{
                                            style: { textAlign: "right" },
                                          }}
                                          InputProps={{
                                            startAdornment: (
                                              <InputAdornment
                                                position="start"
                                                style={{ outline: "none" }}
                                              >
                                                $
                                              </InputAdornment>
                                            ),
                                            sx: {
                                              "&::after": {
                                                borderColor: "black",
                                              },
                                            },
                                          }}
                                        />
                                      </div>
                                    </>
                                  )}
                                  {values.instrument === "safe" &&
                                    values.valuationCapType === "capped" && (
                                      <div className="col-xl-12">
                                        <h6 className="mb-2 mt-3">SAFE Type</h6>
                                        <div
                                          role="group"
                                          className={
                                            "d-flex flex-row " +
                                            (errors.safeType &&
                                              touched.safeType &&
                                              "is-invalid")
                                          }
                                        >
                                          <label className="m-2">
                                            <Field
                                              className="form-check-input"
                                              type="radio"
                                              name="safeType"
                                              value="pre-money"
                                            />
                                            Pre-Money
                                          </label>
                                          <label className="m-2">
                                            <Field
                                              className="form-check-input"
                                              type="radio"
                                              name="safeType"
                                              value="post-money"
                                            />
                                            Post-Money
                                          </label>
                                        </div>
                                        <FormError
                                          show={
                                            touched.safeType && errors.safeType
                                          }
                                          message={errors.safeType}
                                        />
                                      </div>
                                    )}
                                  <div className="col-xl-12">
                                    <NumberFormat
                                      name="discount"
                                      customInput={MSField}
                                      placeholder="0"
                                      isNumericString={true}
                                      thousandSeparator={true}
                                      allowNegative={false}
                                      onValueChange={(val) =>
                                        setFieldValue(
                                          "discount",
                                          val.floatValue
                                        )
                                      }
                                      touched={touched}
                                      errors={errors}
                                      inputProps={{
                                        style: { textAlign: "right" },
                                      }}
                                      InputProps={{
                                        endAdornment: (
                                          <InputAdornment
                                            position="end"
                                            style={{ outline: "none" }}
                                          >
                                            %
                                          </InputAdornment>
                                        ),
                                        sx: {
                                          "&::after": {
                                            borderColor: "black",
                                          },
                                        },
                                      }}
                                    />
                                  </div>
                                </>
                              )}
                              {values.instrument === "equity" && (
                                <>
                                  <div className="col-xl-12">
                                    <h6 className="mb-2 mt-3">Valuation</h6>
                                    {/* To avoid errors, we must reset "Convertible Note" and "SAFE"'s fields */}
                                    {delete values["valuationCapCurrency"]}
                                    {delete values["valuationCapType"]}
                                    {delete values["safeType"]}
                                    {delete values["discount"]}
                                  </div>
                                  <div className="col-xl-12">
                                    <div
                                      role="group"
                                      className="d-flex flex-row"
                                    >
                                      <label className="m-2">
                                        <Field
                                          className="form-check-input"
                                          type="radio"
                                          name="equityType"
                                          value="pre-money"
                                        />
                                        Pre-Money
                                      </label>
                                      <label className="m-2">
                                        <Field
                                          className="form-check-input"
                                          type="radio"
                                          name="equityType"
                                          value="post-money"
                                        />
                                        Post-Money
                                      </label>
                                    </div>
                                  </div>
                                  <div className="col-xl-12">
                                    <h6 className="mb-2 mt-3">Share class</h6>
                                    <Field
                                      name="shareClassOption"
                                      component="select"
                                      className="form-select"
                                    >
                                      <option value="" selected disabled hidden>
                                        Choose here
                                      </option>
                                      <option value={null}></option>
                                      <option value="common">Common</option>
                                      <option value="preffered">
                                        Preferred
                                      </option>
                                    </Field>
                                  </div>
                                </>
                              )}

                              <div className="mb-0 col-xl-12">
                                <h6 className="mb-2 mt-3">MFN</h6>
                                <p className="mb-1">
                                  Will the investor receive formal legal Most
                                  Favored Nation rights?
                                </p>
                                <div
                                  role="group"
                                  className={
                                    "d-flex flex-row " +
                                    (errors.mfn && touched.mfn && "is-invalid")
                                  }
                                >
                                  <label className="m-2">
                                    <Field
                                      className="form-check-input"
                                      type="radio"
                                      name="mfn"
                                      value="true"
                                    />
                                    Yes
                                  </label>
                                  <label className="m-2">
                                    <Field
                                      className="form-check-input"
                                      type="radio"
                                      name="mfn"
                                      value="false"
                                    />
                                    No
                                  </label>
                                </div>

                                <h6 className="form-label mt-2">
                                  Pro-rata rights
                                </h6>
                                <p className="mb-1">
                                  Will the investor receive formal legal
                                  pro-rata rights?
                                </p>
                                <div
                                  role="group"
                                  className={
                                    "d-flex flex-row " +
                                    (errors.proRata &&
                                      touched.proRata &&
                                      "is-invalid")
                                  }
                                >
                                  <label className="m-2">
                                    <Field
                                      className="form-check-input"
                                      type="radio"
                                      name="proRata"
                                      value="true"
                                    />
                                    Yes
                                  </label>
                                  <label className="m-2">
                                    <Field
                                      className="form-check-input"
                                      type="radio"
                                      name="proRata"
                                      value="false"
                                    />
                                    No
                                  </label>
                                </div>
                              </div>
                              <div className="mb-0 col-xl-12">
                                <h6 className="form-label mt-2">Other terms</h6>
                                <p className="mb-1">
                                  Not shown to investors. Non-standard
                                  liquidation preferences, interest rates,
                                  redemption rights, etc.
                                </p>
                                <Field
                                  type="text"
                                  name="otherTerms"
                                  className="form-control"
                                  placeholder=""
                                />
                              </div>
                            </div>
                            <div className="col-12 mt-5">
                              <MSButton
                                size="large"
                                variant="primary"
                                type="submit"
                                className="w-100"
                                loading={isSubmitting}
                              >
                                Create Round
                              </MSButton>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </Form>
          );
        }}
      </Formik>
    </>
  );
}

export default CompanyFundraiseManageCreate;
