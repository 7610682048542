import React, { useState } from "react";
import {
  BrowserRouter as Router,
  Route,
  Switch,
  useRouteMatch,
} from "react-router-dom";

import AddNote from "./convertible-add-note";
import ConvertibleUsers from "./convertible-users";
import WelcomeCard from "./convertible-welcome-card";

function ConvertibleNotesMain({ onReturn }) {
  const { path } = useRouteMatch();
  const [data, setData] = useState([]);
  const handleAdd = (values) => {
    const processedData = {
      id: data.length + 1,
      ...values,
    };
    setData((prev) => [...prev, processedData]);
  };
  const handleEdit = (id, values) => {
    console.log(id, values);
    const processedData = {
      id: id,
      ...values,
    };
    setData((prev) =>
      prev.map((item) => {
        if (item.id === id) {
          item = processedData;
        }
        return item;
      })
    );
  };
  const handleRemove = (id) => {
    setData((prev) => prev.filter((item) => item.id !== id));
  };
  const handleFinish = () => {
    onReturn(7, data);
  };
  const isListEmpty = data.length === 0 && true;
  return (
    <Router>
      <div>
        <Switch>
          <Route exact path={path}>
            <WelcomeCard show={isListEmpty} />
          </Route>
          <Route path={`${path}/view`}>
            <ConvertibleUsers
              onEdit={handleEdit}
              onRemove={handleRemove}
              emptyListVal={isListEmpty}
              data={data}
            />
            <button onClick={handleFinish} className="btn">
              Submit
            </button>
          </Route>
          <Route exact path={`${path}/add`}>
            <AddNote onAdd={handleAdd} />
          </Route>
        </Switch>
      </div>
    </Router>
  );
}

export default ConvertibleNotesMain;
