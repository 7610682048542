import Slider from "@mui/material/Slider";
import amplitude from "amplitude-js";
import { Form, Formik } from "formik";
import MSButton from "jsx/element/button";
import React, { useState } from "react";
import NumberFormat from "react-number-format";
import * as Yup from "yup";

const AllocationSchema = Yup.object().shape({
  investmentAmount: Yup.number()
    .required()
    .positive()
    .integer()
    .moreThan(0)
    .required("Required"),
});

function FundraiseOfferSlider(props) {
  const [allocationChance, setAllocationChance] = useState("Lower");
  const [maxPrice, setMaxPrice] = useState(
    props.maxPrice ? props.maxPrice.toString() + "B" : "1.5B"
  );
  const [toolTipText, setToolTipText] = useState(
    "The round will be likely oversubscribed and this extra allocation is likely to be cut."
  );

  const marks = [
    {
      value: 1.5,
      label: "$1.5B",
    },
    {
      value: 1.6,
      label: "$1.6B",
    },
    {
      value: 1.7,
      label: "$1.7B",
    },
    {
      value: 1.8,
      label: "$1.8B",
    },
    {
      value: 1.9,
      label: "$1.9B",
    },
    {
      value: 2.0,
      label: "$2.0B",
    },
    {
      value: 2.1,
      label: "Uncapped",
    },
  ];

  function valueLabelFormat(value) {
    if (value === 2.1) {
      return "Uncapped MFN";
    }
    return value.toString() + "B";
  }

  function handleChange(event, value) {
    if (value <= 1.6) {
      setAllocationChance("Lower");
      setToolTipText("The allocation has a low chance of being fulfilled.");
    } else if (value <= 1.8) {
      setAllocationChance("Medium");
      setToolTipText(
        "The allocation has a reasonable chance of being fulfilled."
      );
    } else if (value <= 2) {
      setAllocationChance("Higher");
      setToolTipText("The allocation has a high chance of being fulfilled.");
    } else if (value === 2.1) {
      setAllocationChance("Guaranteed");
      setToolTipText(
        "Your allocation is guaranteed with an uncapped MFN. It will resolve to the best price."
      );
    }

    setMaxPrice(valueLabelFormat(value));
    if (parseFloat(value) !== parseFloat(maxPrice)) {
      amplitude
        .getInstance()
        .logEvent("Fundraise Round - Change Max Price", { maxPrice: value });
    }
  }

  let investButtonText = `Invest at $${maxPrice}`;

  if (maxPrice.includes("MFN")) {
    investButtonText = `Invest with uncapped MFN`;
  }

  return (
    <>
      <Formik
        initialValues={{
          investmentAmount: props.investmentAmount ?? 0,
        }}
        validationSchema={AllocationSchema}
        onSubmit={async (values) => {
          props.onSubmit(values.investmentAmount, maxPrice);
        }}
      >
        {({ touched, errors, isSubmitting, setFieldValue }) => (
          <Form>
            <>
              <div className="row">
                <h3 className="form-label">Reserve Your Investment</h3>
                <p className="small">
                  Select the maximum valuation cap you are willing to invest at.
                  If the final clearing cap is lower, you will receive your
                  requested allocation at the lower cap.
                </p>
                <h6 className="mb-4">Pre-Money SAFE Valuation Cap with MFN</h6>

                <div className="px-4 pt-2 pb-4">
                  <Slider
                    aria-label="Max Price"
                    defaultValue={props.maxPrice ?? 1.4}
                    step={0.1}
                    marks={marks}
                    valueLabelDisplay="auto"
                    min={1.5}
                    valueLabelFormat={valueLabelFormat}
                    max={2.1}
                    onChange={handleChange}
                    sx={{
                      color: "#111",
                      "& .MuiSlider-thumb": {
                        borderRadius: "1px",
                        width: "24px",
                        height: "24px",
                      },
                      "& .MuiSlider-rail": {
                        height: 4,
                        borderRadius: "0px",
                      },
                      "& .MuiSlider-track": {
                        height: 5,
                      },
                      "& .MuiSlider-markLabel": {
                        fontSize: "12px",
                        marginTop: "4px",
                      },
                    }}
                  />
                </div>
                <hr />
                <div className="mb-4 col-12">
                  <div className="d-flex">
                    <h6 className="flex-grow-1 mt-2">Allocation Chance</h6>
                    <p
                      className="mb-0"
                      style={{ fontWeight: "600", marginTop: "0px" }}
                    >
                      {allocationChance}
                    </p>
                  </div>

                  <p className="small mb-0">{toolTipText}</p>

                  <div className="row">
                    <div className="col-12">
                      <div className="d-flex">
                        <h6 className="form-label">Requested Allocation</h6>
                      </div>

                      <div className="input-with-icon">
                        <i className="fa fa-usd icon"></i>
                        <NumberFormat
                          name="investmentAmount"
                          className={
                            "form-control " +
                            (errors.investmentAmount ? "is-invalid" : "")
                          }
                          placeholder="0"
                          onValueChange={(val) =>
                            setFieldValue("investmentAmount", val.floatValue)
                          }
                          allowNegative={false}
                          isNumericString={true}
                          thousandSeparator={true}
                          defaultValue={props.investmentAmount ?? undefined}
                        />
                      </div>
                    </div>
                  </div>
                </div>
                <div className="mb-3">
                  <MSButton
                    loading={isSubmitting}
                    title={investButtonText}
                    className="btn-big w-100"
                  />
                </div>
              </div>
            </>
          </Form>
        )}
      </Formik>
    </>
  );
}

export default FundraiseOfferSlider;
