import { UserContext } from "jsx/contexts/user-context";
import SignInForm from "jsx/element/signin-form";
import Lottie from "lottie-react";
import React, { useEffect } from "react";
import { Link, useHistory } from "react-router-dom";

function Signin({ location, isSignin }) {
  const { user } = React.useContext(UserContext);
  const queryString = require("query-string");
  const queryParams = queryString.parse(location.search);

  const history = useHistory();
  const { redirectUrl, type } = queryParams;

  useEffect(() => {
    if (!user.email) {
      return;
    }

    if (redirectUrl) {
      history.push(redirectUrl);
    } else if (type === "company") {
      history.push("/org/dashboard");
    } else if (type === "seller") {
      history.push("/seller/dashboard");
    } else {
      // history.push("/investor/dashboard");
      history.push("/org/dashboard");
    }
  }, [user.email]);
  return (
    <>
      <Lottie
        animationData={require("images/liquid.json")}
        loop={true}
        style={{
          width: "100%",
          maxHeight: 340,
          position: "fixed",
          left: 0,
          right: 0,
          bottom: 0,
          opacity: 0.2,
        }}
      />
      <div className="authentication">
        <div className="container">
          <div className="row justify-content-center align-items-center">
            <div className="col-xl-5 col-md-8 col-xs-12">
              <div className="text-center">
                <Link to={"./"}>
                  <img
                    src={require("images/logo-small.png").default}
                    alt=""
                    className="my-4"
                    style={{
                      imageRendering: "crisp-edges",
                    }}
                  />
                </Link>
              </div>
              <div className="auth-form card">
                <div className="card-body">
                  <SignInForm
                    isSignin={isSignin}
                    redirectUrl={redirectUrl}
                    type={type}
                  />
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
}

export default Signin;
