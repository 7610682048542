import { Field, Form, Formik } from "formik";
import React, { useState } from "react";
import { Button, Modal } from "react-bootstrap";
function AccountAdministrators({ onReturn }) {
  const [data, setData] = useState([
    {
      id: 1,
      firstName: "Vu",
      lastName: "Tran",
      jobTitle: "Chief Executive Officer",
      email: "vutran0@gmail.com",
      permission: "Admin",
    },
  ]);
  const [show, setShow] = useState(false);
  const addHandler = ({ values }) => {
    setShow(!show);
    if (values !== undefined) {
      //Here is the successful event.
      const processedData = {
        id: data.length + 1,
        ...values,
      };
      setData((prev) => [...prev, processedData]);
    }
  };
  const handleEdit = (id, { data }) => {
    setData((prev) =>
      prev.map((item) => {
        if (item.id === id) {
          item.email = data.email;
          item.firstName = data.firstName;
          item.lastName = data.lastName;
          item.jobTitle = data.jobTitle;
          item.permission = data.permission;
        }
        return item;
      })
    );
  };
  const handleRemove = (id) => {
    id > 1 && setData((prev) => prev.filter((item) => item.id !== id));
  };
  return (
    <Formik
      initialValues={{}}
      onSubmit={async (values) => {
        await new Promise((resolve) => setTimeout(resolve, 500));
        const processedData = {
          ...values,
          admins: data,
        };
        onReturn(3, processedData);
      }}
    >
      <Form>
        <div className="card-header">
          <h4 className="card-title">
            As an option, add administrators to your account
          </h4>
          <button onClick={addHandler} className="add-floating-button">
            Add more
          </button>
          {show && (
            <AddUserDialog
              show={show}
              setShow={setShow}
              returnFunction={addHandler}
            />
          )}
        </div>
        <div className="card-body">
          <p>
            Administrators will have access to make changes to the Modern Share
            account. Authorized signatories are responsible for signing
            electronic securities issued by your company. You will be asked to
            designate administrators and provide authorized signatories in the
            next step.
          </p>
          <div className="row p-0">
            {data.map((item) => {
              return (
                <Item
                  key={item.id}
                  id={item.id}
                  allData={item}
                  onEdit={handleEdit}
                  onRemove={handleRemove}
                />
              );
            })}
          </div>
          <div className="mb-3 col-xl-12">
            <h5 className="mb-2 mt-3">
              If you have a law firm, you can add them to your account
            </h5>
            <p>
              Companies often add their law firms to Modern Share so they can
              easily manage the cap table. By adding your law firm, you grant
              them permission to view and edit your cap table. You also have the
              option of adding your law firm once your account is activated.
            </p>
            <Field
              type="text"
              name="companyName"
              className="form-control"
              placeholder="Law firm name"
            />
            <small>
              By adding your law firm, you are granting them legal administrator
              access to your account.
            </small>
          </div>
          <div>
            <button type="submit">Next</button>
          </div>
        </div>
      </Form>
    </Formik>
  );
}

function Item({ id, allData, onEdit, onRemove }) {
  const [show, setShow] = useState("");
  const handleFinish = ({ data }) => {
    setShow(!show);
    onEdit(id, { data });
  };
  const fullName = `${allData.firstName} ${allData.lastName}`;
  return (
    <div
      className={
        "col-5 d-flex flex-row align-items-center margin-right-2 position-relative incorporation-box mt-0 mb-4"
      }
    >
      <div className="d-flex flex-row justify-content-center align-items-center profile-thumbnail-custom">
        {fullName[0]}
      </div>
      <div className="d-flex flex-column">
        <small>Name: {fullName}</small>
        <small>Job Title: {allData.jobTitle}</small>
        <small>Email: {allData.email}</small>
        <small>Permission: {allData.permission}</small>
      </div>
      <div className="edit-button-float">
        <small onClick={() => setShow(!show)} className="m-3">
          Edit
        </small>
        {id > 1 && <small onClick={() => onRemove(id)}>Remove</small>}
      </div>
      {show && (
        <EditDialog
          show={show}
          setShow={setShow}
          returnFunction={handleFinish}
          defValues={allData}
        />
      )}
    </div>
  );
}

function AddUserDialog({ show, setShow, returnFunction }) {
  const handleClose = () => setShow(false);

  const [isError, setError] = useState(false);

  const [email, setEmail] = useState("");
  const [firstName, setFirstName] = useState("");
  const [lastName, setLastName] = useState("");
  const [title, setTitle] = useState("");
  const [permission, setPermission] = useState(null);

  const handleFinish = () => {
    if (
      email.length > 5 &&
      firstName.length > 0 &&
      lastName.length > 0 &&
      title.length > 1 &&
      permission !== null
    ) {
      const values = {
        email: email,
        name: `${firstName} ${lastName}`,
        firstName: firstName,
        lastName: lastName,
        jobTitle: title,
        permission: permission,
      };
      returnFunction({ values });
    } else {
      setError(true);
    }
  };

  return (
    <>
      <Modal show={show} onHide={handleClose}>
        <Modal.Header>
          <Modal.Title>Add administrators to your account</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <div className="mb-4 col-xl-12">
            <p>Email address</p>
            <input
              onChange={(e) => setEmail(e.target.value)}
              className={
                "form-control " + (isError && email.length < 5 && "is-invalid")
              }
              type="email"
            />
          </div>
          <div className="mb-4 col-xl-12">
            <p>First Name</p>
            <input
              onChange={(e) => setFirstName(e.target.value)}
              className={
                "form-control " +
                (isError && firstName.length === 0 && "is-invalid")
              }
              type="text"
            />
          </div>
          <div className="mb-4 col-xl-12">
            <p>Last Name</p>
            <input
              onChange={(e) => setLastName(e.target.value)}
              className={
                "form-control " +
                (isError && lastName.length === 0 && "is-invalid")
              }
              type="text"
            />
          </div>
          <div className="mb-4 col-xl-12">
            <p>Title</p>
            <input
              onChange={(e) => setTitle(e.target.value)}
              className={
                "form-control " + (isError && title.length < 1 && "is-invalid")
              }
              type="text"
            />
          </div>
          <div className="mb-4 col-xl-12">
            <p>Permission</p>
            <select
              onChange={(e) => setPermission(e.target.value)}
              className={
                "form-select " +
                (isError && permission === null && "is-invalid")
              }
            >
              <option value={null}></option>
              <option value={"View"}>View</option>
              <option value={"Edit"}>Edit</option>
              <option value={"Admin"}>Admin</option>
            </select>
          </div>
        </Modal.Body>
        <Modal.Footer>
          <Button
            variant="primary"
            className="transparent-button"
            onClick={handleClose}
          >
            Cancel
          </Button>
          <Button
            variant="secondary"
            className="custom-button"
            onClick={handleFinish}
          >
            Add team member
          </Button>
        </Modal.Footer>
      </Modal>
    </>
  );
}

function EditDialog({ show, setShow, returnFunction, defValues }) {
  const handleClose = () => setShow(false);

  const [isError, setError] = useState(false);

  const [email, setEmail] = useState(defValues.email);
  const [firstName, setFirstName] = useState(defValues.firstName);
  const [lastName, setLastName] = useState(defValues.lastName);
  const [title, setTitle] = useState(defValues.jobTitle);
  const [permission, setPermission] = useState(defValues.permission);

  const handleFinish = () => {
    if (
      email.length > 5 &&
      firstName.length > 0 &&
      lastName.length > 0 &&
      title.length > 1 &&
      permission !== null
    ) {
      const data = {
        email: email,
        name: `${firstName} ${lastName}`,
        firstName: firstName,
        lastName: lastName,
        jobTitle: title,
        permission: permission,
      };
      returnFunction({ data });
    } else {
      setError(true);
    }
  };

  return (
    <>
      <Modal show={show} onHide={handleClose}>
        <Modal.Header>
          <Modal.Title>Edit administrators to your account</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <div className="mb-4 col-xl-12">
            <p>Email address</p>
            <input
              defaultValue={email}
              onChange={(e) => setEmail(e.target.value)}
              className={
                "form-control " + (isError && email.length < 5 && "is-invalid")
              }
              type="email"
            />
          </div>
          <div className="mb-4 col-xl-12">
            <p>First Name</p>
            <input
              defaultValue={firstName}
              onChange={(e) => setFirstName(e.target.value)}
              className={
                "form-control " +
                (isError && firstName.length === 0 && "is-invalid")
              }
              type="text"
            />
          </div>
          <div className="mb-4 col-xl-12">
            <p>Last Name</p>
            <input
              defaultValue={lastName}
              onChange={(e) => setLastName(e.target.value)}
              className={
                "form-control " +
                (isError && lastName.length === 0 && "is-invalid")
              }
              type="text"
            />
          </div>
          <div className="mb-4 col-xl-12">
            <p>Title</p>
            <input
              defaultValue={title}
              onChange={(e) => setTitle(e.target.value)}
              className={
                "form-control " + (isError && title.length < 1 && "is-invalid")
              }
              type="text"
            />
          </div>
          <div className="mb-4 col-xl-12">
            <p>Permission</p>
            <select
              defaultValue={permission}
              onChange={(e) => setPermission(e.target.value)}
              className={
                "form-select " +
                (isError && permission === null && "is-invalid")
              }
            >
              <option value={"View"}>View</option>
              <option value={"Edit"}>Edit</option>
              <option value={"Admin"}>Admin</option>
            </select>
          </div>
        </Modal.Body>
        <Modal.Footer>
          <Button
            variant="primary"
            className="transparent-button"
            onClick={handleClose}
          >
            Cancel
          </Button>
          <Button
            variant="secondary"
            className="custom-button"
            onClick={handleFinish}
          >
            Save changes
          </Button>
        </Modal.Footer>
      </Modal>
    </>
  );
}
export default AccountAdministrators;
