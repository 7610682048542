import { useField } from "formik";
import React, { useEffect, useState } from "react";

import NumberFormattedNonForm from "./number-formated-non-form";
function PaymentForShares({ name }) {
  const [field, _meta, helpers] = useField(name);
  const setFieldValue = helpers.setValue;
  const [values, setValues] = useState({
    cashPaid: field.value.values.cashPaid,
    debtCanceled: field.value.values.debtCanceled,
    ipTransfered: field.value.values.ipTransfered,
    other: field.value.values.other,
  });
  const [total, setTotal] = useState(field.value.total);
  const handleReturn = (id, value) => {
    setValues((prev) => ({
      ...prev,
      [id]: value,
    }));
  };
  useEffect(() => {
    setTotal(
      Object.values(values).reduce(
        (previousValue, currentValue) => previousValue + currentValue
      )
    );
    setFieldValue({ values, total });
  }, [values, total]);
  return (
    <div className="d-flex flex-column">
      <div className="row">
        <div className="col mb-2">
          <p className="mb-0">Cash paid</p>
          <small>
            The amount of cash paid to the company for this certificate
          </small>
        </div>
        <div className="col-3">
          <NumberFormattedNonForm
            id={"cashPaid"}
            defaultValue={field.value.values.cashPaid}
            onReturn={handleReturn}
          />
        </div>
      </div>
      <div className="row mb-2">
        <div className="col">
          <p className="mb-0">Debt canceled</p>
          <small>
            For convertible securities, the total value of interest being
            converted into shares
          </small>
        </div>
        <div className="col-3">
          <NumberFormattedNonForm
            id={"debtCanceled"}
            defaultValue={field.value.values.debtCanceled}
            onReturn={handleReturn}
          />
        </div>
      </div>
      <div className="row mb-2">
        <div className="col">
          <p className="mb-0">IP transferred</p>
          <small>
            Cash value of the technology or IP exchanged for this share
          </small>
        </div>
        <div className="col-3">
          <NumberFormattedNonForm
            id={"ipTransfered"}
            defaultValue={field.value.values.ipTransfered}
            onReturn={handleReturn}
          />
        </div>
      </div>
      <div className="row mb-2">
        <div className="col">
          <p className="mb-0">Other</p>
          <small>
            Any other value exchanged for the shares apart from cash, debt
            canceled, or IP transferred.
          </small>
        </div>
        <div className="col-3">
          <NumberFormattedNonForm
            id={"other"}
            defaultValue={field.value.values.other}
            onReturn={handleReturn}
          />
        </div>
      </div>
      <div className="row mb-2">
        <div className="col">
          <p className="mb-0">Total:</p>
        </div>
        <div className="col-3 d-flex flex-row justify-content-end">{total}</div>
      </div>
    </div>
  );
}
export default PaymentForShares;
