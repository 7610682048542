import "react-dates/initialize";
import "react-dates/lib/css/_datepicker.css";

import Skeleton from "@mui/material/Skeleton";
import {
  collection,
  deleteDoc,
  doc,
  getDocs,
  getFirestore,
  query,
} from "firebase/firestore";
import { get, post } from "jsx/api";
import { UserContext } from "jsx/contexts/user-context";
import SettingsNav from "jsx/element/settings-nav";
import Header6 from "jsx/layout/header6";
import React, { useEffect, useState } from "react";
import { usePlaidLink } from "react-plaid-link";
import { Link } from "react-router-dom";

// import stripe forma
// import StripeCheckout from "./stripe-checkout";

function SettingsBankConnect() {
  const { selectedOrganization } = React.useContext(UserContext);
  const [transactions, setTransactions] = useState([]);
  const [bank, setBank] = useState();

  const [linkToken, setLinkToken] = useState("");

  const db = getFirestore();

  useEffect(() => {
    generateToken();
  }, []);

  useEffect(() => {
    // Tested that the integration works, hardcodes it so we don't use up all the free credit
    // setBank({
    //   accessToken: "access-sandbox-48b98cc3-96ea-4444-bad0-6ecf6d77000f",
    //   itemId: "AzlbBB8KEMF1J3M3Wjvls7RwayKxggU1az9Z5",
    //   createdAt: { seconds: 1634832041, nanoseconds: 498000000 },
    //   institution: {
    //     routing_numbers: ["084106768"],
    //     name: "Mercury",
    //     country_codes: ["US"],
    //     oauth: false,
    //     institution_id: "ins_116794",
    //     products: ["assets", "auth", "transactions", "identity"],
    //   },
    //   item: {
    //     update_type: "background",
    //     webhook: "",
    //     item_id: "AzlbBB8KEMF1J3M3Wjvls7RwayKxggU1az9Z5",
    //     billed_products: ["auth", "transactions"],
    //     consent_expiration_time: null,
    //     error: null,
    //     available_products: ["balance"],
    //     institution_id: "ins_116794",
    //   },
    // });
    fetchBankDetails();
  }, [selectedOrganization]);

  useEffect(() => {
    fetchTransactions();
  }, [bank]);

  async function fetchTransactions() {
    if (!bank || !bank.accessToken) {
      return;
    }
    const params = {
      accessToken: bank.accessToken,
    };

    const result = await get(`plaid/transactions`, {
      params,
    });

    setTransactions(result.data);
  }

  async function fetchBankDetails() {
    if (!selectedOrganization) {
      return;
    }

    const companiesRef = collection(
      db,
      "organizations",
      selectedOrganization,
      "organizations_private",
      "data",
      "banks"
    );
    const q = query(companiesRef);
    const querySnapshot = await getDocs(q);

    if (querySnapshot.docs.length > 0) {
      const bankDetails = {
        ...querySnapshot.docs[0].data(),
        id: querySnapshot.docs[0].id,
      };
      setBank(bankDetails);
    } else {
      setBank({});
    }
  }

  const onSuccess = React.useCallback(
    async (public_token, metadata) => {
      // send public_token to server
      const params = {
        public_token,
        account_id: metadata.accounts[0].id,
        organizationId: selectedOrganization,
        metadata: metadata,
      };

      const response = await post(`plaid/set_access_token`, params);

      fetchBankDetails();
    },
    [selectedOrganization]
  );

  const config = {
    token: linkToken,
    onSuccess,
  };

  function disconnectBank() {
    deleteDoc(
      doc(
        db,
        "organizations",
        selectedOrganization,
        "organizations_private",
        "data",
        "banks",
        bank.id
      )
    );
  }

  async function generateToken() {
    const response = await post(`plaid/create_link_token`);
    const data = response.data;
    setLinkToken(data.link_token);
  }

  const { open, ready } = usePlaidLink(config);
  let bankHeader;
  let bankContent;

  if (!bank) {
    bankHeader = (
      <>
        <div style={{ width: "100%" }}>
          <Skeleton height={80} />
        </div>
      </>
    );

    bankContent = (
      <>
        <div className="card outlined">
          <div style={{ lineHeight: "40px", marginBottom: "16px" }}>
            <Skeleton height={60} />
            <Skeleton height={80} />
            <Skeleton height={80} />
            <Skeleton height={80} />
            <Skeleton height={80} />
          </div>
        </div>
      </>
    );
  } else {
    if (bank.metadata?.institution) {
      bankHeader = (
        <>
          <div className="d-flex">
            <span className="me-3">
              <i className="fa fa-usd"></i>
            </span>
            <div className="flex-grow-1">
              <h5 className="mt-0 mb-1">{bank.metadata.institution?.name}</h5>
            </div>
            <div className="edit-option">
              <button
                className="btn btn-small waves-effect"
                onClick={disconnectBank}
              >
                Disconnect
              </button>
            </div>
          </div>
        </>
      );

      bankContent = (
        <div className="card outlined">
          <div className="card-header">
            <h4 className="card-title">Deposits</h4>
          </div>

          <div className="card-body">JFIWjwo</div>
        </div>
      );
    } else {
      bankHeader = (
        <div className="d-flex">
          <span className="me-3">
            <i className="fa fa-usd"></i>
          </span>
          <div className="flex-grow-1">
            <h5 className="mt-0 mb-1">Connect a bank account</h5>
            <p>
              Adding a bank account will enable us to automatically send
              confirmations when you receive funds into your account.
            </p>
          </div>
          <div className="edit-option">
            <button
              className="btn btn-small waves-effect"
              onClick={() => open()}
              disabled={!ready}
            >
              Connect
            </button>
          </div>
        </div>
      );
    }
  }

  const BankBody = () => {
    if (!transactions.length) {
      return;
    }

    return transactions.map((t) => {
      const name = t?.name.split(";")[0];

      if (name.length > 50) {
        return;
      }

      return (
        <tr key={t.transaction_id}>
          <td>
            <span>
              <Link>{name}</Link>
            </span>
          </td>

          <td>
            <span>
              <Link>{t.date}</Link>
            </span>
          </td>
          <td>
            <span>
              <Link>${t.amount.toLocaleString()}</Link>
            </span>
          </td>

          <td>
            <Link>
              <span className="pill">{t.category[1]}</span>
            </Link>
          </td>
          {/* <td><span className="pill">{round.mfn ? "Yes" : "No"}</span></td>
                <td><span className="pill">{round.proRata ? "Yes" : "No"}</span></td> */}
        </tr>
      );
    });
  };

  return (
    <>
      <Header6 />
      <div className="content-body section-padding">
        <div className="container">
          <div className="d-flex">
            <div className="me-4" style={{ minWidth: "200px" }}>
              <SettingsNav />
            </div>

            <div className="col-xl-10">
              <div className="flex-column">
                <div className="card outlined">
                  <div className="card-body">
                    <div className="fundraising-rounds">{bankHeader}</div>
                  </div>
                </div>
                {bankContent}
                {/* Stripe */}
                {/* <StripeCheckout /> */}
                {/* End Stripe */}
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
}

export default SettingsBankConnect;
