import React from "react";
import { Link } from "react-router-dom";

import Divider from "./divider";

function SettingsNav() {
  const activeSetting =
    document.location.href.split("/")[
      document.location.href.split("/").length - 1
    ];

  return (
    <>
      <div className="card settings_menu">
        <h4 className="my-3">Create Round</h4>
        <Divider
          style={{
            position: "relative",
            left: 0,
            marginBottom: 16,
            height: 2,
            marginRight: 4,
          }}
        />

        <div>
          <ul>
            <div className="sidemenu">
              <li
                className={
                  "nav-item " + (activeSetting === "basic" ? "active" : "")
                }
              >
                <Link to={"/org/fundraise/manage/create"} className="nav-link">
                  <i className="la la-usd"></i>
                  <span>Basic Info</span>
                </Link>
              </li>
              <li
                className={
                  "nav-item " + (activeSetting === "kyc" ? "active" : "")
                }
              >
                <Link to={"/org/fundraise/investors"} className="nav-link">
                  <i className="la la-bank"></i>
                  <span>Other Investors</span>
                </Link>
              </li>
              <li
                className={
                  "nav-item " + (activeSetting === "kyc" ? "active" : "")
                }
              >
                <Link to={"/org/fundraise/company-info"} className="nav-link">
                  <i className="la la-bank"></i>
                  <span>Company Info</span>
                </Link>
              </li>
              <li
                className={
                  "nav-item " + (activeSetting === "kyc" ? "active" : "")
                }
              >
                <Link
                  to={"/org/fundraise/risks-and-disclosures"}
                  className="nav-link"
                >
                  <i className="la la-bank"></i>
                  <span>Risks & Disclosures</span>
                </Link>
              </li>
              <li
                className={
                  "nav-item " + (activeSetting === "kyc" ? "active" : "")
                }
              >
                <Link to={"/org/fundraise/team-members"} className="nav-link">
                  <i className="la la-bank"></i>
                  <span>Team Members</span>
                </Link>
              </li>
              <li
                className={
                  "nav-item " + (activeSetting === "kyc" ? "active" : "")
                }
              >
                <Link
                  to={"/org/fundraise/e-sign-and-submit"}
                  className="nav-link"
                >
                  <i className="la la-bank"></i>
                  <span>E-Sign & Submit</span>
                </Link>
              </li>
            </div>
          </ul>
        </div>
      </div>
    </>
  );
}

export default SettingsNav;
