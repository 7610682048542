import {
  collection,
  doc,
  getDoc,
  getDocs,
  getFirestore,
  query,
} from "firebase/firestore";
import { Field, Form, Formik } from "formik";
import { post } from "jsx/api";
import { UserContext } from "jsx/contexts/user-context";
import SecondaryOrderTable from "jsx/element/secondary-order-table";
import SecondaryTransactionTable from "jsx/element/secondary-transaction-table";
import Header4 from "jsx/layout/header4";
import React, { useContext, useEffect, useState } from "react";

export default function CompanySecondaryManageMatch({ match }) {
  const db = getFirestore();

  const { secondaryId } = match.params;

  const [secondary, setSecondary] = useState(null);
  const { selectedOrganization } = useContext(UserContext);
  const [secondaryOrders, setSecondaryOrders] = useState([]);
  const [transactions, setTransactions] = useState([]);
  const [documentMetadata, setDocumentMetadata] = useState({});
  const [isSubmittingTransactions, setIsSubmittingTransactions] =
    useState(false);

  const [isFetchingTransactions, setFetchingTransactions] = useState(false);

  const getSecondary = async () => {
    const secondaryDoc = doc(
      db,
      "organizations",
      selectedOrganization,
      "organizations_private",
      "data",
      "secondaries",
      secondaryId
    );
    const secondary = await getDoc(secondaryDoc);
    setSecondary({ id: secondary.id, ...secondary.data() });
  };

  const getSecondaryOrders = async () => {
    const secondaryOrderQuery = collection(
      db,
      "organizations",
      selectedOrganization,
      "organizations_admin",
      "data",
      "secondaries",
      secondary.id,
      "secondary_orders"
    );
    const querySnapshot = await getDocs(query(secondaryOrderQuery));
    const orders = querySnapshot.docs.map((doc) => ({
      id: doc.id,
      ...doc.data(),
    }));
    setSecondaryOrders(orders);
  };

  const createTransaction = async ({ buyOrderId, sellOrderId, quantity }) => {
    await post(`secondaries/createTransaction`, {
      organizationId: selectedOrganization,
      secondaryId: secondary.id,
      buyOrderId,
      sellOrderId,
      quantity,
    });
    getTransactions();
  };

  const getTransactions = async () => {
    setFetchingTransactions(true);
    const transactionsSnapshot = await getDocs(
      collection(
        db,
        "organizations",
        selectedOrganization,
        "organizations_admin",
        "data",
        "secondaries",
        secondary.id,
        "secondary_transactions"
      )
    );
    const transactions = transactionsSnapshot.docs.map((doc) => ({
      id: doc.id,
      ...doc.data(),
    }));
    setTransactions(transactions);
    setFetchingTransactions(false);
  };

  useEffect(() => {
    if (selectedOrganization) getSecondary();
  }, [selectedOrganization]);

  useEffect(() => {
    if (secondary?.id) getSecondaryOrders();
    if (secondary?.id) getTransactions();
  }, [secondary?.id]);

  return (
    <>
      <Header4 />
      <div className="content-body section-padding">
        <div className="card outlined container mb-4">
          <div className="card-header">
            <h3>Transactions</h3>
            <hr />
          </div>
          <div className="row">
            <h4 className="mt-3">Match transactions</h4>
            <Formik
              initialValues={{
                quantity: "",
                buyOrderId: "",
                sellOrderId: "",
              }}
              onSubmit={(values, { resetForm }) => {
                createTransaction(values);
                resetForm({
                  quantity: "",
                  buyOrderId: "",
                  sellOrderId: "",
                });
              }}
            >
              {(props) => (
                <Form className="d-flex">
                  <Field
                    as="select"
                    name="sellOrderId"
                    className="form-control"
                  >
                    <option defaultValue value="">
                      Sell security
                    </option>
                    {secondaryOrders
                      .filter(
                        ({ orderType, status }) =>
                          orderType === "sell" && status === "open"
                      )
                      .map(
                        (
                          {
                            id,
                            name,
                            quantity,
                            securityDetails: { certificateNo },
                          },
                          i
                        ) => (
                          <option value={id} key={i}>
                            {name} | {certificateNo} ({quantity})
                          </option>
                        )
                      )}
                  </Field>
                  <Field
                    as="select"
                    name="buyOrderId"
                    className="form-control ms-2"
                  >
                    <option defaultValue value="">
                      Buy security
                    </option>
                    {secondaryOrders
                      .filter(
                        ({ orderType, status }) =>
                          orderType === "buy" && status === "open"
                      )
                      .map(({ id, name, quantity }, i) => (
                        <option value={id} key={i}>
                          {name} ({quantity})
                        </option>
                      ))}
                  </Field>

                  <Field
                    type="number"
                    className="form-control ms-2"
                    placeholder={`Quantity`}
                    name="quantity"
                  />

                  <button
                    type="submit"
                    className="btn btn-secondary ms-2"
                    disabled={!props.dirty}
                  >
                    Submit
                  </button>
                </Form>
              )}
            </Formik>
          </div>

          <h4 className="mt-3">Orders</h4>
          <SecondaryOrderTable
            secondaryOrders={secondaryOrders}
            secondaryId={secondaryId}
            organizationId={selectedOrganization}
            isAdmin={true}
          />
          <h4 className="mt-3">Transactions</h4>
          <SecondaryTransactionTable
            transactions={transactions}
            secondaryId={secondary?.id}
            loading={isFetchingTransactions}
          />
        </div>
      </div>
    </>
  );
}
