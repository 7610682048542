import { Tooltip } from "@mui/material";
import MSButton from "jsx/element/button";
import SignatureField from "jsx/element/signature-field";

export default function SigningSection({
  documentPreviewSection,
  isError,
  signatureImage,
  setSignatureImage,
  isSigning,
  isPreviewing,
  submitForm,
  downloadPreview,
}) {
  return (
    <>
      <div
        style={{
          height: 1,
          background: "#EEE",
          marginTop: "32px",
        }}
      />
      <h4 className="form-section-header mt-4">Preview documents</h4>
      <ul className="fundraising-rounds mt-4">{documentPreviewSection}</ul>
      <div
        style={{
          height: 1,
          background: "#EEE",
          marginTop: "32px",
        }}
      />

      {/* Signature section */}
      <h4 className="form-section-header">Create your electronic signature</h4>

      <h6 className="form-label mb-4">
        Enter your full name to sign the document.
      </h6>

      <div className="mb-0 col-xs-12">
        <SignatureField
          className={
            "signature-block w-100 form-control " +
            (isError && !signatureImage && "is-invalid")
          }
          onChange={(img) => {
            setSignatureImage(img);
          }}
        />
      </div>
      <div className="mb-0 col-xl-12">
        <p className="smaller mb-3 mt-4">
          By clicking the button below: I certify that I consent to conduct all
          affairs relating to the documents listed above electronically. I
          further certify that I have electronically accessed the documents
          listed above, and that I have read them. I adopt the above electronic
          signature as my signature, and hereby electronically sign the
          documents listed above. I acknowledge that I have accessed, have read
          and hereby agree to the Modern Share Terms of Service, and that I
          authorize the Modern Share services, in the manner designated therein,
          to process the documents and signatures provided herewith and to
          create, store, and communicate electronic records of the documents
          listed above. I further acknowledge that my signature will be held
          strictly in escrow until the closing date, at which point my
          signature, together with the counterparty's signatures, will be
          released concurrently.
        </p>
        <Tooltip
          placement="right"
          title="Prefill the document with your information and preview it without signing it"
        >
          <span>
            <MSButton
              variant="secondary"
              size="large"
              className="w-100 mt-4 mb-4"
              loading={isPreviewing}
              onClick={downloadPreview}
            >
              Preview
            </MSButton>
          </span>
        </Tooltip>
        <MSButton
          variant="primary"
          size="large"
          className="w-100"
          loading={isSigning}
          title="Sign"
          loadingLabel="Signing"
          onClick={submitForm}
        />
      </div>
    </>
  );
}
