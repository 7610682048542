import { doc, getDoc, getFirestore, setDoc } from "firebase/firestore";
import { Field, Form, Formik } from "formik";
import { UserContext } from "jsx/contexts/user-context";
import MSButton from "jsx/element/button";
import FormError from "jsx/element/form-error";
import React, { useEffect, useState } from "react";
import * as Yup from "yup";

const SignSchema = Yup.object().shape({
  account: Yup.string().required("Required"),
  accountName: Yup.string().required("Required"),
  name: Yup.string().required("Required"),
  routing: Yup.string().required("Required"),
  swiftBic: Yup.string(),
  instructions: Yup.string(),
});

export function SellerBankingInformation({ onSubmit }) {
  const { user } = React.useContext(UserContext);
  const [bankDetails, setBankDetails] = useState({});
  const db = getFirestore();

  useEffect(() => {
    async function getBankDetails(userId) {
      const bankSnap = await getDoc(
        doc(db, "users", user.uid, "users_private", "data", "banks", "0")
      );
      const bankData = bankSnap.data();
      setBankDetails(bankData ?? {});
    }
    if (user.uid) {
      getBankDetails(user.uid);
    }
  }, [db, user.uid]);

  async function updateBankDetails({
    account,
    accountName,
    instructions,
    name,
    routing,
    swift_bic,
  }) {
    await setDoc(
      doc(db, "users", user.uid, "users_private", "data", "banks", "0"),
      {
        type: "manual",
        account,
        accountName,
        instructions,
        name,
        routing,
        swift_bic,
      },
      { merge: true }
    );
  }
  return (
    <Formik
      enableReinitialize
      initialValues={{
        account: bankDetails.account ?? "",
        accountName: bankDetails.accountName ?? user.displayName,
        instructions: bankDetails.instructions ?? "",
        name: bankDetails.name ?? "",
        routing: bankDetails.routing ?? "",
        swiftBic: bankDetails.swift_bic ?? "",
      }}
      validationSchema={SignSchema}
      onSubmit={async (values) => {
        await updateBankDetails({
          account: values.account ?? null,
          accountName: values.accountName ?? null,
          instructions: values.instructions ?? null,
          name: values.name ?? null,
          routing: values.routing ?? null,
          swift_bic: values.swiftBic ?? null,
        });
        onSubmit();
        return;
      }}
    >
      {(formik) => {
        const { initialValues, isSubmitting, errors, touched } = formik;
        return (
          <Form>
            <div className="card-body">
              <div className="row">
                {/* Review section */}
                <div className="mb-0 col-xl-12">
                  <h6 className="form-label">Bank name</h6>
                  <Field
                    type="text"
                    name="name"
                    className={
                      "form-control " +
                      (touched.name && errors.name && "is-invalid")
                    }
                    placeholder="Chase, Bank of America, Wells Fargo, etc."
                    autoComplete="off"
                    autoCorrect="off"
                    autoCapitalize="off"
                    spellCheck="false"
                  />
                </div>
                <FormError
                  show={touched["name"] && errors["name"]}
                  message={errors["name"]}
                />
              </div>

              <div className="mb-0 col-xl-12">
                <h6 className="form-label">Name on Account</h6>
                <Field
                  type="text"
                  name="accountName"
                  className={
                    "form-control " +
                    (touched.accountName && errors.accountName && "is-invalid")
                  }
                  placeholder="Jane Doe"
                  autoComplete="off"
                  autoCorrect="off"
                  autoCapitalize="off"
                  spellCheck="false"
                />
                <FormError
                  show={touched["accountName"] && errors["accountName"]}
                  message={errors["accountName"]}
                />
              </div>

              <div className="mb-0 col-xl-12">
                <h6 className="form-label">ABA Routing Number</h6>
                <Field
                  type="text"
                  name="routing"
                  className={
                    "form-control " +
                    (touched.routing && errors.routing && "is-invalid")
                  }
                  placeholder="00000000000"
                  autoComplete="off"
                  autoCorrect="off"
                  autoCapitalize="off"
                  spellCheck="false"
                />
                <FormError
                  show={touched["routing"] && errors["routing"]}
                  message={errors["routing"]}
                />
              </div>

              <div className="mb-0 col-xl-12">
                <h6 className="form-label">Bank Account Number</h6>
                <Field
                  type="text"
                  name="account"
                  className={
                    "form-control " +
                    (touched.account && errors.account && "is-invalid")
                  }
                  placeholder="00000000000"
                  autoComplete="off"
                  autoCorrect="off"
                  autoCapitalize="off"
                  spellCheck="false"
                />
                <FormError
                  show={touched["account"] && errors["account"]}
                  message={errors["account"]}
                />
              </div>

              <div className="mb-0 col-xl-12">
                <h6 className="form-label">
                  SWIFT/BIC code number (only for foreign bank accounts)
                </h6>
                <Field
                  type="text"
                  name="swiftBic"
                  className={
                    "form-control " +
                    (touched.signerName && errors.signerName && "is-invalid")
                  }
                  placeholder="0000000"
                  autoComplete="off"
                  autoCorrect="off"
                  autoCapitalize="off"
                  spellCheck="false"
                />
                <FormError
                  show={errors["swiftBic"]}
                  message={errors["swiftBic"]}
                />
              </div>

              <div className="mb-0 col-xl-12">
                <h6 className="form-label">
                  Additional instructions (optional)
                </h6>
                <Field
                  type="text"
                  name="instructions"
                  className={
                    "form-control " +
                    (touched.instructions &&
                      errors.instructions &&
                      "is-invalid")
                  }
                  placeholder="Any additional instructions necessary to process a wire transfer."
                  autoComplete="off"
                  autoCorrect="off"
                  autoCapitalize="off"
                  spellCheck="false"
                />
                <FormError
                  show={errors["instructions"]}
                  message={errors["instructions"]}
                />
              </div>
            </div>
            <MSButton
              style={{ float: "right" }}
              disabled={!errors}
              type="submit"
              loading={isSubmitting}
              title={"Next"}
              variant="primary"
            />
          </Form>
        );
      }}
    </Formik>
  );
}
