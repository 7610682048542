import Checkbox from "@mui/material/Checkbox";
import FormControl from "@mui/material/FormControl";
import InputLabel from "@mui/material/InputLabel";
import ListItemText from "@mui/material/ListItemText";
import MenuItem from "@mui/material/MenuItem";
import OutlinedInput from "@mui/material/OutlinedInput";
import Select from "@mui/material/Select";
import * as React from "react";

const ITEM_HEIGHT = 64;
const ITEM_PADDING_TOP = 8;
const MenuProps = {
  PaperProps: {
    style: {
      maxHeight: ITEM_HEIGHT * 4.5 + ITEM_PADDING_TOP,
      width: 250,
    },
  },
};

const variants = [
  {
    id: 1,
    name: "Employee",
  },
  {
    id: 2,
    name: "Ex-Employee",
  },
  {
    id: 3,
    name: "Executive",
  },
  {
    id: 4,
    name: "Investor",
  },
  {
    id: 5,
    name: "Consultant",
  },
  {
    id: 6,
    name: "Board member",
  },
  {
    id: 7,
    name: "Contractors",
  },
  {
    id: 8,
    name: "Interns",
  },
  {
    id: 9,
    name: "Other",
  },
];

export default function StakeholderRoleFilter({
  onChange,
  filter = ["Employee", "Ex-Employee"],
  ...props
}) {
  const [variantName, setVariantName] = React.useState(
    variants.filter((e) => filter.includes(e.name))
  );

  const handleChange = (event) => {
    const {
      target: { value },
    } = event;

    let duplicateRemoved = [];

    value.forEach((item) => {
      if (duplicateRemoved.findIndex((o) => o.id === item.id) >= 0) {
        duplicateRemoved = duplicateRemoved.filter((x) => x.id === item.id);
      } else {
        duplicateRemoved.push(item);
      }
    });

    setVariantName(duplicateRemoved);
    onChange(duplicateRemoved.map((e) => e.name));
  };

  return (
    <div>
      <FormControl sx={{ width: 300 }} {...props}>
        <InputLabel id="demo-multiple-checkbox-label">Roles</InputLabel>
        <Select
          multiple
          value={variantName}
          onChange={handleChange}
          input={<OutlinedInput label="Roles" />}
          renderValue={(selected) => selected.map((x) => x.name).join(", ")}
          MenuProps={MenuProps}
        >
          {variants.map((variant) => (
            <MenuItem
              key={variant.id}
              value={variant}
              style={{ width: "100%" }}
            >
              <Checkbox
                style={{ padding: 8 }}
                checked={
                  variantName.findIndex((item) => item.id === variant.id) >= 0
                }
              />
              <ListItemText primary={variant.name} />
            </MenuItem>
          ))}
        </Select>
      </FormControl>
    </div>
  );
}
