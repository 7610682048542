import { get } from "jsx/api";
import { UserContext } from "jsx/contexts/user-context";
import SellerHeader from "jsx/layout/seller-header";
import React, { useEffect, useState } from "react";
import { Link } from "react-router-dom";

import SellerLoTStepper from "../company/secondaries/docs/seller-lot-stepper";
import SellerExpirationBar from "./seller-expiration-bar";
import OrderForms from "./seller-order-forms";
import SecuritiesTable from "./seller-securities-table";
import SellerTendersNav from "./seller-tenders-nav";

export default function SellerCompany({ match }) {
  const [organizationId, setOrganizationId] = useState();
  const { organizationId: initialOrgId } = match.params;
  const { user } = React.useContext(UserContext);
  const [securities, setSecurities] = useState();
  const [secondary, setSecondary] = useState();
  const [orders, setOrders] = useState([]);
  const [tabValue, setTabValue] = React.useState("1");
  const [orderPreview, setOrderPreview] = useState();

  async function fetchSecondaries() {
    if (!secondary) {
      const result = await get("users/getSecondaries");
      if (result.data && result.data.length > 0) {
        let secondary;
        if (initialOrgId) {
          secondary = result.data.find(
            (sec) => sec.organizationId === initialOrgId
          );
        } else {
          secondary = result.data?.[0];
        }
        setOrganizationId(secondary.organizationId);
        setSecondary(secondary);
      }
    }
  }

  useEffect(() => {
    if (!secondary && user.uid) {
      fetchSecondaries();
    }
    if (organizationId) {
      fetchSecurities();
      fetchOrders();
    }
  }, [organizationId, secondary, user.uid]);

  async function fetchSecurities() {
    if (!user.uid) {
      return;
    }
    const result = await get("users/listSecurities");
    if (result.data && result.data.length > 0) {
      setSecurities(
        result.data.find((sec) => sec.organizationId === organizationId)
      );
    }
  }

  async function fetchOrders() {
    const result = await get("secondaries/listOrders", { organizationId });
    if (result.data?.secondaryOrders?.length > 0) {
      setOrders(result.data.secondaryOrders);
    }
  }

  const total = securities?.overview.total;
  const sellable = securities?.overview.sellable;
  const pricePerShare = secondary
    ? secondary.pricePerShareInBaseUnits / secondary.pricePerSharePrecision
    : null;

  const sellableDollars = pricePerShare
    ? pricePerShare * securities?.overview.sellable
    : null;
  const sellableQuantity = securities?.overview.sellable ?? null;

  const sellableSecurities = securities?.summaries?.flatMap((secGroup) =>
    secGroup.securities
      .filter((sec) => sec.meta?.sellable > 0)
      .map((sec) => ({ ...sec, category: secGroup.category }))
  );

  let content;

  const hasExpired = new Date(secondary?.endDate._seconds * 1000) < new Date();

  if (hasExpired) {
    content = (
      <div className="d-flex">
        <div className="me-4" style={{ minWidth: "220px" }}>
          <SellerTendersNav
            organizationId={organizationId}
            secondaryId={secondary?.id}
          />
        </div>
        <div className="col-xl-10">
          <div className="card outlined">
            <div className="card-header">
              <h4 className="card-title">The offer period has expired</h4>
            </div>

            <div className="card-body mt-4">
              View your orders and signed documents, if any, in "Your orders" on
              the navigation bar to the left.
            </div>
          </div>
        </div>
      </div>
    );
  } else {
    if (orderPreview && organizationId) {
      content = (
        <SellerLoTStepper
          orderPreview={orderPreview}
          pricePerShare={pricePerShare}
          organizationId={organizationId}
          secondaryId={secondary.id}
          cancelOrder={() => {
            setOrderPreview(null);
          }}
        ></SellerLoTStepper>
      );
    } else {
      content = (
        <div className="d-flex">
          <div className="me-4" style={{ minWidth: "220px" }}>
            <SellerTendersNav
              organizationId={organizationId}
              secondaryId={secondary?.id}
            />
          </div>
          <div className="col-xl-10">
            <div className="card outlined">
              <div className="card-header">
                <h4 className="card-title">
                  {securities?.organizationName} Holdings
                </h4>
              </div>

              <div className="card-body pt-5">
                <div className="d-flex flex-wrap">
                  <div className="col-12 col-sm-6 col-md-3 d-flex">
                    <div className="card outlined py-4 me-4 rounded w-100 ps-4 pe-2">
                      <h2>{total?.toLocaleString()}</h2>
                      <h6>total units</h6>
                    </div>
                  </div>
                  <div className="col-12 col-sm-6 col-md-3 d-flex">
                    <div className="card outlined py-4 me-4 rounded w-100 ps-4 pe-2">
                      <h2>{sellable?.toLocaleString()}</h2>
                      <h6>sellable units</h6>
                    </div>
                  </div>
                  <div className="col-12 col-sm-6 col-md-3 d-flex">
                    <div className="card outlined py-4 me-4 rounded w-100 ps-4 pe-2">
                      <h2>
                        {pricePerShare?.toLocaleString("en-US", {
                          minimumFractionDigits: 2,
                          maximumFractionDigits: 2,
                          currency: "USD",
                          style: "currency",
                        })}
                      </h2>
                      <h6>per unit</h6>
                    </div>
                  </div>
                  <div className="col-12 col-sm-6 col-md-3 d-flex">
                    <div className="card outlined py-4 me-4 rounded w-100 ps-4 pe-2">
                      <h2>
                        {sellableDollars?.toLocaleString("en-US", {
                          maximumFractionDigits: 0,
                          currency: "USD",
                          style: "currency",
                        })}
                      </h2>
                      <h6>sellable during tender</h6>
                    </div>
                  </div>
                </div>
                {securities?.summaries && (
                  <SecuritiesTable
                    securities={securities?.summaries}
                    pricePerShare={pricePerShare}
                  />
                )}
              </div>
            </div>

            {securities?.summaries &&
              !orders.some(({ status }) => status === "open") && (
                <div className="card outlined">
                  <OrderForms
                    orgName={securities.organizationName}
                    pricePerShare={pricePerShare}
                    sellableDollars={sellableDollars}
                    sellableQuantity={sellableQuantity}
                    organizationId={organizationId}
                    secondaryId={secondary?.id}
                    securities={sellableSecurities}
                    onOrderSubmitted={fetchOrders}
                    onOrderPreview={(orderPreview) => {
                      setOrderPreview(orderPreview);
                    }}
                  />
                </div>
              )}
            {securities?.summaries &&
              orders.some(({ status }) => status === "open") && (
                <div className="card outlined">
                  <div className="card-header" style={{ display: "block" }}>
                    <h4 className="card-title">
                      Sell {securities.organizationName}
                    </h4>
                  </div>
                  <div className="card-body pt-4">
                    <p>
                      You have already submitted an order to tender units in the
                      offer. To change your elections or information, you must
                      first cancel your existing order before submitting a new
                      order with the updated elections.
                    </p>
                    <Link
                      to={`/seller/tender/${secondary.id}/orders`}
                      className="btn btn-primary mt-4"
                    >
                      View order
                    </Link>
                  </div>
                </div>
              )}
          </div>
        </div>
      );
    }
  }

  return (
    <>
      <SellerHeader />

      <div className="content-body section-padding">
        <SellerExpirationBar secondary={secondary} />
        <div className="container" style={{ paddingTop: "50px" }}>
          {content}
        </div>
      </div>
    </>
  );
}
