import { duotone } from "@fortawesome/fontawesome-svg-core/import.macro";
import { regular } from "@fortawesome/fontawesome-svg-core/import.macro";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { Skeleton } from "@mui/material";
import Table from "@mui/material/Table";
import TableBody from "@mui/material/TableBody";
import TableCell from "@mui/material/TableCell";
import TableContainer from "@mui/material/TableContainer";
import TableHead from "@mui/material/TableHead";
import TableRow from "@mui/material/TableRow";
import { doc, getDoc, getFirestore } from "firebase/firestore";
import { get } from "jsx/api";
import { UserContext } from "jsx/contexts/user-context";
import MSButton from "jsx/element/button";
import EmptyState from "jsx/element/empty-state";
import { detailedStatus } from "jsx/helpers";
import Header4 from "jsx/layout/header4";
import React, { useEffect, useState } from "react";
import { Link } from "react-router-dom";

function Dashboard({ history }) {
  const { user, admin } = React.useContext(UserContext);
  const [orders, setOrders] = useState();
  const [followingCompanies, setFollowingCompanies] = useState();

  const db = getFirestore();

  const showVerification =
    admin.kycStatus !== "success" || !admin.accreditationStatus;

  useEffect(() => {
    fetchActiveSecondaries();
    fetchOrders();
    fetchFollowingSecondaries();
  }, [user.uid]);

  async function fetchActiveSecondaries() {
    const result = await get("users/getActiveSecondaries");
    if (result.data && result.data.length > 0) {
      history.replace("/seller/dashboard");
    }
  }

  async function fetchOrders() {
    if (!user.uid) {
      return;
    }
    const result = await get("secondaries/listOrders");

    const orders = await Promise.all(
      result.data.secondaryOrders.map(async (order) => {
        const result = await get("secondaries/listTransactionsByOrderId", {
          orderId: order.id,
          organizationId: order.organizationId,
        });

        return { ...order, transactions: result.data.secondaryTransactions };
      })
    );

    orders.sort((a, b) =>
      a.createdDate._seconds < b.createdDate._seconds ? 1 : -1
    );

    setOrders(orders);
  }

  async function fetchFollowingSecondaries() {
    if (!user.uid) {
      return;
    }
    const following = await getDoc(
      doc(db, `users/${user.uid}/users_private/data/following/organizations`)
    );

    if (following.data() && "secondaries" in following.data()) {
      const hydrated = await Promise.all(
        following.data().secondaries.map(async (e) => {
          const companyData = await getDoc(doc(db, `organizations/${e.oid}`));
          return {
            secondaryId: e.sid,
            organizationId: e.oid,
            companyData: companyData.data(),
          };
        })
      );

      setFollowingCompanies(hydrated);
    } else {
      setFollowingCompanies([]);
    }
  }

  let ordersSection;
  let followingSection;

  if (!orders) {
    ordersSection = (
      <>
        <Skeleton height={50} />
        <Skeleton height={50} />
        <Skeleton height={50} />
      </>
    );
  } else if (orders.length) {
    ordersSection = (
      <>
        <TableContainer>
          <Table aria-label="simple table">
            <TableHead>
              <TableRow>
                <TableCell>Company</TableCell>
                <TableCell align="right">Date</TableCell>
                <TableCell align="right">Share Price</TableCell>
                <TableCell align="right">Quantity</TableCell>
                <TableCell align="center">Status</TableCell>
              </TableRow>
            </TableHead>
            <TableBody
              sx={{
                "& .MuiTableRow-root": {
                  transition: "background 0.5",
                  color: "red",
                  bottomBorder: "none",
                },
                "& .MuiTableRow-root:hover": {
                  background: "var(--background)",
                },
              }}
            >
              {orders.map((order, i) => {
                const orderLink = `/investor/secondaries/${order.organizationId}/${order.secondaryId}/orders/${order.id}`;

                // `investor/secondaries/HY624uMYo7WB4abBJq57/transactions/cnqB6tJazOeRmAoEBVIM_iTZ072UnaKVQGgazQjM4`
                let orderStatus = order.status;

                if (order.transactions && order.transactions.length > 0) {
                  orderStatus = detailedStatus(order.transactions[0]);
                }

                return (
                  <TableRow component={Link} to={orderLink} key={i}>
                    <TableCell>
                      <span className="text-success">
                        {order.organizationName}
                      </span>
                    </TableCell>

                    <TableCell align="right" style={{ padding: "16px" }}>
                      {new Date(
                        order.createdDate._seconds * 1000
                      ).toLocaleDateString()}
                    </TableCell>
                    <TableCell align="right">
                      <span className="text-success">
                        {(
                          order.pricePerShareInBaseUnits /
                          order.pricePerSharePrecision
                        ).toLocaleString(undefined, {
                          style: "currency",
                          currency: "USD",
                          minimumFractionDigits: Math.max(
                            2,
                            Math.log10(order.pricePerSharePrecision)
                          ),
                        })}
                      </span>
                    </TableCell>
                    <TableCell align="right">
                      <span className="text-success">{order.quantity}</span>
                    </TableCell>
                    <TableCell align="center">
                      <span className="pill px-3 py-2">{orderStatus}</span>
                    </TableCell>
                  </TableRow>
                );
              })}
            </TableBody>
          </Table>
        </TableContainer>
      </>
    );
  } else {
    ordersSection = (
      <EmptyState
        icon={duotone("wallet")}
        style={{ marginTop: 24 }}
        title={"No orders to display"}
        subtitle={
          "Orders will display here when you submit a request to participate in a secondary round"
        }
      />
    );
  }

  if (!followingCompanies) {
    followingSection = (
      <>
        <Skeleton height={30} />
        <Skeleton height={30} />
      </>
    );
  } else if (followingCompanies.length) {
    followingSection = (
      <div style={{ display: "flex", flexDirection: "column" }}>
        {followingCompanies.map((e, i) => {
          return (
            <Link
              key={i}
              style={{
                fontSize: 16,
                fontWeight: 500,
                paddingTop: 8,
              }}
              to={`/org/${e.organizationId}/secondary/${e.secondaryId}`}
            >
              {e.companyData.companyName}
            </Link>
          );
        })}
      </div>
    );
  } else {
    followingSection = (
      <div style={{ marginTop: 24 }}>
        <h6 style={{ fontWeight: 400 }}>Not following any companies yet.</h6>
      </div>
    );
  }

  return (
    <>
      <Header4 />
      <div className="content-body section-padding">
        <div className="container">
          <div className="row">
            {showVerification && (
              <div className="col-xl-12">
                <div className="card outlined-small">
                  <div className="card-body">
                    <div className="fundraising-rounds">
                      <div className="d-flex">
                        <span className="me-3">
                          <i className="fa fa-usd"></i>
                        </span>
                        <div className="flex-grow-1">
                          <h4 className="mt-0 mb-1">
                            Verify your investor account
                          </h4>
                          <p>
                            Before you can participate in transactions, you must
                            first verify your identity and complete our
                            accreditation questionaire.
                          </p>
                        </div>
                        <div className="edit-option">
                          <Link to="/onboarding">
                            <MSButton
                              title="Verify"
                              variant="secondary"
                              startIcon={
                                <FontAwesomeIcon icon={regular("id-card")} />
                              }
                            ></MSButton>
                          </Link>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            )}
            <div className="col-xl-8">
              <div className="card outlined">
                <div className="card-header border-0">
                  <h4 className="card-title">Investment requests</h4>
                </div>
                <div className="card-body pt-2">{ordersSection}</div>
              </div>
            </div>
            <div className="col-xl-4">
              <div className="card outlined-small">
                <div className="card-header border-0">
                  <h5>Watchlist</h5>
                </div>
                <div className="card-body pt-2">{followingSection}</div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
}

export default Dashboard;
