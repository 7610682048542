import {
  collection,
  doc,
  getDoc,
  getDocs,
  getFirestore,
  onSnapshot,
  setDoc,
} from "@firebase/firestore";
import { regular } from "@fortawesome/fontawesome-svg-core/import.macro";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { InputAdornment } from "@mui/material";
import Dialog from "@mui/material/Dialog";
import DialogActions from "@mui/material/DialogActions";
import Skeleton from "@mui/material/Skeleton";
import Table from "@mui/material/Table";
import TableBody from "@mui/material/TableBody";
import TableCell from "@mui/material/TableCell";
import TableContainer from "@mui/material/TableContainer";
import TableHead from "@mui/material/TableHead";
import TableRow from "@mui/material/TableRow";
import Tooltip from "@mui/material/Tooltip";
import { post } from "jsx/api";
import { getBaseUrl } from "jsx/api";
import { UserContext } from "jsx/contexts/user-context";
import MSButton from "jsx/element/button";
import Divider from "jsx/element/divider";
import FilesViewer from "jsx/element/files-viewer";
import ManageSecondariesNav from "jsx/element/manage-secondaries-nav";
import MSField from "jsx/element/ms-field";
import { formatPrice } from "jsx/helpers";
import Header4 from "jsx/layout/header4";
import moment from "moment";
import React, { useContext, useEffect, useRef, useState } from "react";
import { Link } from "react-router-dom";
import { CountUp } from "use-count-up";

function SecondaryHeaderSkeleton() {
  return (
    <div className="d-flex flex-wrap" style={{ marginRight: "-24px" }}>
      {Array(3)
        .fill(1)
        .map((_, i) => (
          <div key={i} className="col-12 col-sm-4 d-flex">
            <div className="card outlined py-4 me-4 rounded w-100">
              <div className="d-flex">
                <Skeleton variant="circular" width={40} height={40} />
                <div className="ms-4">
                  <h2>
                    <Skeleton variant="text" width={60} />
                  </h2>
                  <h6>
                    <Skeleton variant="text" width={120} />
                  </h6>
                </div>
              </div>
            </div>
          </div>
        ))}
    </div>
  );
}

function SecondaryHeader({
  secondary,
  secondaryId,
  secondaryEmployees,
  secondaryOrders,
}) {
  if (!secondary.startDate) {
    return <div></div>;
  }

  const securitiesSellingTotal = secondaryOrders.reduce(
    (acc, { quantity, status, orderType }) =>
      acc +
      parseInt(
        status !== "canceled" && orderType === "sell" ? quantity ?? 0 : 0
      ),
    0
  );

  const securitiesTotalBuying = secondaryOrders.reduce(
    (acc, { quantity, status, orderType }) =>
      acc +
      parseInt(
        status !== "canceled" && orderType === "buy" ? quantity ?? 0 : 0
      ),
    0
  );

  const displayRounded = (value) =>
    value.toLocaleString("en-US", {
      maximumFractionDigits: 0,
      style: "currency",
      currency: "USD",
    });

  const cards = [
    {
      title: "Stakeholders",
      value: secondaryEmployees.length ?? 0,
      icon: "la-id-badge",
      link: `/org/secondaries/manage/${secondaryId}/stakeholders`,
    },
    {
      title: "Total buy request",
      value: secondary.pricePerShareInBaseUnits
        ? (secondary.pricePerShareInBaseUnits * securitiesTotalBuying) /
          secondary.pricePerSharePrecision
        : 0,
      icon: "la-file-invoice-dollar",
      display: displayRounded,
      count: true,
      link: `/org/secondaries/manage/${secondaryId}/orders`,
    },
    {
      title: "Total selling",
      value: secondary.pricePerShareInBaseUnits
        ? (secondary.pricePerShareInBaseUnits * securitiesSellingTotal) /
          secondary.pricePerSharePrecision
        : 0,
      icon: "la-money",
      display: displayRounded,
      count: true,
      link: `/org/secondaries/manage/${secondaryId}/orders`,
    },
  ];

  return (
    <div className="d-flex flex-wrap" style={{ marginRight: "-24px" }}>
      {cards.map(
        ({ title, value, display, icon, count, decimals, link }, i) => (
          <div key={i} className="col-12 col-sm-4 d-flex">
            <Link
              to={link}
              className="secondary-header-card card outlined py-4 me-4 rounded w-100 ps-4 pe-2"
            >
              <div className="d-flex">
                <div className="big-icon">
                  <i className={`la ${icon}`}></i>
                </div>
                <div>
                  <h2>
                    {count ? (
                      <CountUp
                        isCounting
                        end={value}
                        duration={0.5}
                        decimalPlaces={decimals ?? 0}
                        formatter={display ?? ((val) => val)}
                      />
                    ) : (
                      value
                    )}
                  </h2>
                  <h6>{title}</h6>
                </div>
                <div className="d-flex align-items-center ms-auto me-2">
                  <FontAwesomeIcon
                    className="card-icon"
                    icon={regular("chevron-right")}
                    size="lg"
                    style={{ color: "var(--gray-200)" }}
                  />
                </div>
              </div>
            </Link>
          </div>
        )
      )}
    </div>
  );
}

function CompanySecondariesManage({ match }) {
  const { selectedOrganization } = useContext(UserContext);
  const [secondary, setSecondary] = useState({});
  const [stakeholders, setStakeholders] = useState([]);
  const [secondaryEmployees, setSecondaryEmployees] = useState([]);
  const [secondaryOrders, setSecondaryOrders] = useState([]);
  const [transactions, setTransactions] = useState([]);
  const [decks, setDecks] = useState([]);
  const [sellerDocuments, setSellerDocuments] = useState([]);
  const [deckConfirmId, setDeckConfirmId] = useState();

  const [loading, setIsLoading] = useState(false);

  //Tooltip
  const [isTooltipOpen, setIsTooltipOpen] = useState(false);
  const copyTextInput = useRef(null);
  const copyTooltipTarget = useRef(null);

  const { secondaryId } = match.params;

  const db = getFirestore();

  function copyLink() {
    setIsTooltipOpen(true);
    setTimeout(() => {
      setIsTooltipOpen(false);
    }, 3000);

    /* Copy the text inside the text field */
    navigator.clipboard.writeText(
      `${getBaseUrl()}/org/${selectedOrganization}/invite/secondary/${secondaryId}`
    );
  }

  // Get secondary information along with stakeholder, secondary seller, secondary order
  // data, secondary transactions
  async function fetchSecondary(organizationId, secondaryId) {
    setIsLoading(true);

    const fetchSecondary = async () => {
      const secondarySnap = await getDoc(
        doc(
          db,
          `organizations/${organizationId}/organizations_private/data/secondaries/${secondaryId}`
        )
      );
      const secondaryData = secondarySnap.data();
      setSecondary(secondaryData);
      setDecks(secondaryData.decks ?? []);
      setSellerDocuments(secondaryData.sellerDocuments ?? []);
      return secondaryData;
    };

    const fetchEmployees = async () => {
      const employeesSnapshot = await getDocs(
        collection(
          db,
          `organizations/${organizationId}/organizations_private/data/stakeholders`
        )
      );
      const stakeholders = employeesSnapshot.docs.map((doc) => ({
        id: doc.id,
        ...doc.data(),
      }));
      setStakeholders(stakeholders);
      return stakeholders;
    };

    const fetchSellers = async () => {
      const sellersSnapshot = await getDocs(
        collection(
          db,
          `organizations/${organizationId}/organizations_admin/data/secondaries/${secondaryId}/secondary_sellers`
        )
      );

      const secondarySellers = sellersSnapshot.docs.map((seller) => ({
        id: seller.id,
        ...seller.data(),
      }));
      return secondarySellers;
    };

    const fetchOrders = async () => {
      const secondaryOrdersSnapshot = await getDocs(
        collection(
          db,
          `organizations/${organizationId}/organizations_admin/data/secondaries/${secondaryId}/secondary_orders`
        )
      );
      const secondaryOrders = secondaryOrdersSnapshot.docs.map((doc) => ({
        id: doc.id,
        ...doc.data(),
      }));
      setSecondaryOrders(secondaryOrders);
      return secondaryOrders;
    };

    const fetchTransactions = async () => {
      const transactionsSnapshot = await getDocs(
        collection(
          db,
          "organizations",
          organizationId,
          "organizations_admin",
          "data",
          "secondaries",
          secondaryId,
          "secondary_transactions"
        )
      );
      const transactions = transactionsSnapshot.docs.map((doc) => ({
        id: doc.id,
        ...doc.data(),
      }));
      setTransactions(transactions);
      return transactions;
    };

    const [
      secondaryData, // eslint-disable-line no-unused-vars
      stakeholders,
      secondarySellers,
      secondaryOrders,
      transactions, // eslint-disable-line no-unused-vars
    ] = await Promise.all([
      fetchSecondary(),
      fetchEmployees(),
      fetchSellers(),
      fetchOrders(),
      fetchTransactions(),
    ]);

    const secondaryEmployees = secondarySellers.flatMap((secondarySeller) => {
      const stakeholder = stakeholders.find(
        (stakeholder) =>
          secondarySeller.email === stakeholder.workEmail ||
          secondarySeller.email === stakeholder.homeEmail
      );

      if (secondarySeller) {
        const securitiesSelling = secondaryOrders
          .filter(
            (order) =>
              order.userId === secondarySeller.userId &&
              order.status === "open" &&
              order.orderType === "sell"
          )
          .reduce((acc, { quantity }) => acc + quantity, 0);
        return [{ ...stakeholder, ...secondarySeller, securitiesSelling }];
      } else {
        return [];
      }
    });

    setSecondaryEmployees(secondaryEmployees);
    setIsLoading(false);
  }

  useEffect(() => {
    if (
      selectedOrganization &&
      secondaryId &&
      Object.entries(secondary).length === 0
    ) {
      fetchSecondary(selectedOrganization, secondaryId);
    }
  }, [selectedOrganization, secondaryId]);

  async function uploadSecondaryDeck(e) {
    var formData = new FormData();
    var file = e.target.files[0];
    formData.append("file", file);
    formData.append("organizationId", selectedOrganization);
    formData.append("secondaryId", secondaryId);
    await post("secondaries/uploadDeck", formData, {
      headers: {
        "Content-Type": "multipart/form-data",
      },
    });
    await fetchSecondary(selectedOrganization, secondaryId);
  }

  async function deleteDeck(deckId) {
    const newDecks = secondary.decks.filter((item) => {
      return item.deckId !== deckId;
    });

    await setDoc(
      doc(
        db,
        "organizations",
        selectedOrganization,
        "organizations_private",
        "data",
        "secondaries",
        secondaryId
      ),
      { decks: newDecks },
      { merge: true }
    );
    await fetchSecondary(selectedOrganization, secondaryId);
  }

  async function uploadSellerDocument(e) {
    var formData = new FormData();
    var file = e.target.files[0];
    formData.append("file", file);
    formData.append("organizationId", selectedOrganization);
    formData.append("secondaryId", secondaryId);
    await post("secondaries/uploadSellerDocument", formData, {
      headers: {
        "Content-Type": "multipart/form-data",
      },
    });
    await fetchSecondary(selectedOrganization, secondaryId);
  }

  async function deleteSellerDocument(sellerDocumentId) {
    const newSellerDocuments = secondary.sellerDocuments.filter((item) => {
      return item.documentId !== sellerDocumentId;
    });

    await setDoc(
      doc(
        db,
        "organizations",
        selectedOrganization,
        "organizations_private",
        "data",
        "secondaries",
        secondaryId
      ),
      { sellerDocuments: newSellerDocuments },
      { merge: true }
    );
    await fetchSecondary(selectedOrganization, secondaryId);
  }

  return (
    <>
      <Header4
        hideAllMenuItems
        backButtonLink={"/org/secondaries/manage"}
        pageTitle="Manage Secondary"
      />
      <div className="content-body section-padding">
        <div className="container">
          <div className="d-flex">
            <div className="me-4" style={{ minWidth: "220px" }}>
              <ManageSecondariesNav secondaryId={secondaryId} />
            </div>
            <div className="col-xl-10">
              <div className="flex-column">
                {secondary && (
                  <SecondaryHeader
                    secondary={secondary}
                    secondaryId={secondaryId}
                    secondaryEmployees={secondaryEmployees}
                    secondaryOrders={secondaryOrders}
                  />
                )}
                {!secondary ||
                  (Object.keys(secondary).length === 0 && (
                    <SecondaryHeaderSkeleton />
                  ))}
                <div className="card outlined">
                  <div className="card-header">
                    <h4 className="card-title">{secondary.name}</h4>
                    <MSButton
                      component={Link}
                      variant="secondary"
                      to={`/org/secondaries/manage/${secondaryId}/edit`}
                      title="Edit"
                      size="small"
                      startIcon={<FontAwesomeIcon icon={regular("edit")} />}
                    />
                  </div>
                  <SecondaryDetails
                    secondary={secondary}
                    secondaryId={secondaryId}
                  />
                  <div className="card-body">
                    <div className="mt-4">
                      <h4>Dataroom</h4>
                    </div>
                    <div className="col-12 outlined-thin">
                      <div className={"pt-2"} style={{ fontWeight: 600 }}>
                        Buyer link
                      </div>
                      <p className="small mb-0">
                        A shareable link to send to potential buyers
                      </p>
                      <MSField
                        className="mb-4 mt-4"
                        name="buyerLink"
                        value={`${getBaseUrl()}/org/${selectedOrganization}/invite/secondary/${secondaryId}`}
                        InputProps={{
                          style: { fontSize: 14 },
                          endAdornment: (
                            <Tooltip
                              title="Saved link to clipboard"
                              open={isTooltipOpen}
                              disableFocusListener
                              disableHoverListener
                              disableTouchListener
                            >
                              <InputAdornment position="end">
                                <MSButton
                                  style={{ margin: 0 }}
                                  onClick={copyLink}
                                  edge="end"
                                  variant="icon"
                                >
                                  <FontAwesomeIcon
                                    icon={regular("copy")}
                                    size="xs"
                                  />
                                </MSButton>
                              </InputAdornment>
                            </Tooltip>
                          ),
                        }}
                      />
                      <Divider style={{ left: 48, right: 48 }} />

                      <div className="py-4 mb-2">
                        {decks.length === 0 && (
                          <div className="d-flex">
                            <div className="flex-grow-1">
                              <span style={{ fontWeight: 600 }}>Decks</span>
                              <p className="small mb-0">
                                A deck or other financial disclosures to show to
                                potential buyers
                              </p>
                            </div>
                            <div className="d-flex justify-content-center align-items-center">
                              <MSButton
                                onClick={() => {
                                  document
                                    .getElementById("deck-upload")
                                    .click();
                                }}
                                size="small"
                                title="Upload deck"
                                startIcon={
                                  <FontAwesomeIcon icon={regular("upload")} />
                                }
                              />
                              <input
                                id="deck-upload"
                                type="file"
                                style={{ display: "none" }}
                                onChange={(e) => {
                                  uploadSecondaryDeck(e);
                                }}
                              />
                            </div>
                          </div>
                        )}
                        {decks.length > 0 && (
                          <>
                            <div className={"mb-0"} style={{ fontWeight: 600 }}>
                              Decks
                            </div>

                            {decks.map((deck, i) => (
                              <div className="d-flex py-2" key={i}>
                                <div className="flex-grow-1">
                                  <p className="mt-1 mb-0">{deck.name}</p>
                                  <p className="small mb-0">
                                    {"Uploaded " +
                                      new Date(
                                        deck.createdDate.milliseconds
                                      ).toLocaleString("en-us", {
                                        month: "short",
                                        day: "numeric",
                                      })}
                                  </p>
                                </div>
                                <div>
                                  <MSButton
                                    className="me-2"
                                    title="Delete"
                                    size="small"
                                    startIcon={
                                      <FontAwesomeIcon
                                        icon={regular("trash")}
                                      />
                                    }
                                    onClick={(e) => {
                                      setDeckConfirmId(deck.deckId);
                                    }}
                                  />
                                </div>
                              </div>
                            ))}
                          </>
                        )}
                      </div>
                      <SellerDocuments
                        deleteSellerDocument={deleteSellerDocument}
                        sellerDocuments={sellerDocuments}
                        uploadSellerDocument={uploadSellerDocument}
                      />
                      <BuyerTrackingSection
                        organizationId={selectedOrganization}
                        secondaryId={secondaryId}
                        secondaryOrders={secondaryOrders}
                      />
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>

          <Dialog
            open={!!deckConfirmId}
            onClose={() => {
              setDeckConfirmId();
            }}
            aria-labelledby="alert-dialog-title"
            aria-describedby="alert-dialog-description"
          >
            <div className="p-4">
              <h3>Delete this deck?</h3>
              <p className="mb-0">
                Deleting this deck will also delete the analytics with it.
              </p>
            </div>

            <DialogActions className="me-3 mb-3">
              <MSButton
                title="Cancel"
                onClick={() => {
                  setDeckConfirmId();
                }}
                size="small"
              >
                Cancel
              </MSButton>
              <MSButton
                title="Delete"
                variant="danger"
                onClick={() => {
                  setDeckConfirmId();
                  deleteDeck(deckConfirmId);
                }}
                size="small"
                autoFocus
              >
                Delete
              </MSButton>
            </DialogActions>
          </Dialog>
        </div>
      </div>
    </>
  );
}

export default CompanySecondariesManage;

// helper component for formatting details/info
function Detail({ title, icon, children }) {
  return (
    <div className="p-2">
      <div className="d-flex">
        <FontAwesomeIcon
          icon={icon}
          size="sm"
          style={{ marginRight: 12, marginTop: 2, color: "var(--gray-500)" }}
        />

        <h5 className="col-5" style={{ color: "var(--gray-500)" }}>
          {title}
        </h5>
        <h5>{children}</h5>
      </div>
    </div>
  );
}

function SecondaryDetails(props) {
  const { secondary, secondaryId } = props;
  const [isHovering, setIsHovered] = useState(false);

  if (!secondary.startDate) {
    return <div></div>;
  }

  const formatDate = (seconds) => {
    if (!seconds) return;
    return new Date(seconds * 1000).toLocaleString("en-us", {
      month: "short",
      day: "numeric",
    });
  };

  const startDate = formatDate(secondary.startDate?.seconds);
  const buyerEndDate = formatDate(secondary.buyerEndDate?.seconds);
  const sellerStartDate = formatDate(secondary.sellerStartDate?.seconds);
  const endDate = formatDate(secondary.endDate?.seconds);
  const signingStartDate = formatDate(secondary.signingStartDate?.seconds);
  const signingEndDate = formatDate(secondary.signingEndDate?.seconds);
  const closingDate = formatDate(secondary.closingDate?.seconds);

  const sellableLimit = secondary.sellableRatioLimit.toLocaleString(undefined, {
    style: "percent",
  });

  const available =
    secondary.maxSharesAvailableForPurchase?.toLocaleString("en-US");

  return (
    <div>
      <div className="my-4">
        <h4>Secondary Overview</h4>
      </div>
      <div style={{ paddingLeft: 8, marginBottom: 8 }}>
        <div className="row">
          {startDate && buyerEndDate && (
            <Detail
              title="Buyer ordering period"
              icon={regular("calendar-range")}
            >
              {startDate} - {buyerEndDate}
            </Detail>
          )}
          {sellerStartDate && endDate && (
            <Detail
              title="Seller ordering period"
              icon={regular("calendar-range")}
            >
              {sellerStartDate} - {endDate}
            </Detail>
          )}
          {signingStartDate && signingEndDate && (
            <Detail title="Signing period" icon={regular("calendar-pen")}>
              {signingStartDate} - {signingEndDate}
            </Detail>
          )}
          {closingDate && (
            <Detail title="Closing date" icon={regular("calendar-check")}>
              {closingDate}
            </Detail>
          )}
          <Detail title="Price per share" icon={regular("money-bill")}>
            {secondary.pricePerShareInBaseUnits
              ? formatPrice(
                  secondary.pricePerShareInBaseUnits,
                  secondary.pricePerSharePrecision
                )
              : "-"}
          </Detail>
          {secondary.minPriceInBaseUnits && secondary.maxPriceInBaseUnits && (
            <Detail title="Price range" icon={regular("money-bill")}>
              {formatPrice(
                secondary.minPriceInBaseUnits,
                secondary.minPricePrecision
              )}{" "}
              -{" "}
              {formatPrice(
                secondary.maxPriceInBaseUnits,
                secondary.maxPricePrecision
              )}
            </Detail>
          )}
          <Detail title="Max sellers" icon={regular("user-group")}>
            {secondary.sellerCountLimit}
          </Detail>
          <Detail title="Sellable limit" icon={regular("percent")}>
            {sellableLimit}
          </Detail>
          {available && (
            <Detail title="Shares available" icon={regular("sack-dollar")}>
              {available}
            </Detail>
          )}

          <Detail title="Fully diluted shares" icon={regular("memo")}>
            {secondary.fullyDilutedSharesCount?.toLocaleString() ?? "-"}
          </Detail>
        </div>
      </div>
    </div>
  );
}

function BuyerTrackingSection({
  organizationId,
  secondaryId,
  secondaryOrders,
}) {
  let roundVisitsSub;
  const db = getFirestore();
  const [visits, setVisits] = useState([]);
  const [groupedVisits, setGroupedVisits] = useState([]);

  useEffect(() => {
    fetchVisits();
  }, [secondaryId, organizationId]);

  async function fetchVisits() {
    if (!secondaryId || !organizationId) {
      return;
    }
    if (!roundVisitsSub) {
      roundVisitsSub = onSnapshot(
        doc(
          db,
          "organizations",
          organizationId,
          "organizations_private",
          "data",
          "secondaries",
          secondaryId,
          "visits",
          "summary"
        ),
        (doc) => {
          if (doc.exists && doc.data() && "visits" in doc.data()) {
            setVisits(doc.data().visits.reverse());
          }
        }
      );
    }
  }

  useEffect(() => {
    if (visits.length === 0) {
      return;
    }
    let grouped = Object.entries(
      visits.reduce(function (r, a) {
        r[a.e] = r[a.e] || [];
        r[a.e].push(a);
        return r;
      }, Object.create(null))
    );

    grouped = grouped.filter((e) => e[0] !== "");
    setGroupedVisits(grouped);
  }, [visits]);

  return (
    <>
      <Divider style={{ left: 48, right: 48 }} />
      <div className="mt-4">
        <div className="d-flex">
          <div className={"mb-2 mt-3"} style={{ flexGrow: 1 }}>
            <h4>Buyers</h4>
          </div>

          <Link to={`/org/secondaries/manage/${secondaryId}/visits`}>
            <div style={{ flexDirection: "row", display: "flex" }}>
              <div className={`forward-button d-flex`}>
                <div style={{ marginTop: 16 }}>
                  <span style={{ marginRight: 6 }}>Detailed analytics</span>
                  <i className="la la-angle-right" style={{ fontSize: 14 }}></i>
                </div>
              </div>
            </div>
          </Link>
        </div>

        {groupedVisits.length === 0 && (
          <div className="py-4">
            No buyers have been added yet. Share the link above to buyers and
            they will appear here.
          </div>
        )}

        {groupedVisits.length > 0 && (
          <>
            <TableContainer
              style={{
                border: "1px solid var(--purple-light  )",
                borderRadius: 8,
              }}
            >
              <Table aria-label="simple table">
                <TableHead>
                  <TableRow>
                    <TableCell>Email</TableCell>
                    <TableCell align="right">Updated</TableCell>
                    <TableCell align="right">Views</TableCell>
                    <TableCell align="right">Status</TableCell>
                  </TableRow>
                </TableHead>
                <TableBody>
                  {groupedVisits.map((e) => {
                    const existingOrder = secondaryOrders.find((order) => {
                      return order.email === e[0] && order.status === "open";
                    });
                    let link;
                    if (existingOrder) {
                      link = `/org/secondaries/manage/${secondaryId}/orders/${existingOrder.id}`;
                    }

                    return (
                      <TableRow key={e[0]}>
                        <TableCell>
                          <h6>{e[0]}</h6>
                        </TableCell>

                        <TableCell align="right" style={{ padding: "24px" }}>
                          {moment(new Date(e[1][0].d)).format("M/D, h:mm:ss a")}
                        </TableCell>
                        <TableCell align="right">
                          <span>{e[1].length}</span>
                        </TableCell>
                        <TableCell align="right">
                          <span>
                            {existingOrder ? (
                              <MSButton
                                component={Link}
                                to={link}
                                size="small"
                                startIcon={
                                  <FontAwesomeIcon icon={regular("eye")} />
                                }
                              >
                                Submitted
                              </MSButton>
                            ) : (
                              "Viewed"
                            )}
                          </span>
                        </TableCell>
                      </TableRow>
                    );
                  })}
                </TableBody>
              </Table>
            </TableContainer>
          </>
        )}
      </div>
    </>
  );
}

function SellerDocuments({
  deleteSellerDocument,
  sellerDocuments,
  uploadSellerDocument,
}) {
  const { user } = useContext(UserContext);
  const [documentDeleteId, setDocumentDeleteId] = useState();
  return (
    <>
      <div className="py-4 mb-2">
        <div className="d-flex">
          <div className="flex-grow-1">
            <span style={{ fontWeight: 600 }}>Seller Documents</span>
            <p className="small mb-0">
              Financial disclosures to show to potential sellers during the
              secondary
            </p>
          </div>
          <div className="d-flex justify-content-center align-items-center">
            <MSButton
              onClick={() => {
                document.getElementById("seller-document-upload").click();
              }}
              size="small"
              title="Upload document"
              startIcon={<FontAwesomeIcon icon={regular("upload")} />}
            />
            <input
              id="seller-document-upload"
              type="file"
              style={{ display: "none" }}
              onChange={(e) => {
                uploadSellerDocument(e);
              }}
            />
          </div>
        </div>

        {sellerDocuments.length > 0 && (
          <FilesViewer
            files={sellerDocuments}
            user={user}
            editMode
            onDelete={setDocumentDeleteId}
          />
        )}
      </div>

      <Dialog
        open={!!documentDeleteId}
        onClose={() => {
          setDocumentDeleteId();
        }}
        aria-labelledby="alert-dialog-title"
        aria-describedby="alert-dialog-description"
      >
        <div className="p-4">
          <h3>Delete this deck?</h3>
          <p className="mb-0">
            Deleting this deck will also delete the analytics with it.
          </p>
        </div>

        <DialogActions className="me-3 mb-3">
          <MSButton
            title="Cancel"
            onClick={() => {
              setDocumentDeleteId();
            }}
            size="small"
          >
            Cancel
          </MSButton>
          <MSButton
            title="Delete"
            variant="danger"
            onClick={() => {
              setDocumentDeleteId();
              deleteSellerDocument(documentDeleteId);
            }}
            size="small"
            autoFocus
          >
            Delete
          </MSButton>
        </DialogActions>
      </Dialog>
    </>
  );
}
