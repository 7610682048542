import React from "react";
import { Link } from "react-router-dom";

import Divider from "./divider";

function ManageSecondariesNav(props) {
  const { secondaryId } = props;

  return (
    <>
      <div className="settings_menu">
        <h4 className="my-3">Secondary</h4>
        <Divider
          style={{
            position: "relative",
            left: 0,
            marginBottom: 16,
            height: 2,
            marginRight: 4,
          }}
        />

        <ul>
          <div className="sidemenu">
            <li className="nav-item">
              <Link
                to={`/org/secondaries/manage/${secondaryId}/overview`}
                className={
                  "nav-link " +
                  (document.location.href.includes("overview") ? "active" : "")
                }
              >
                <i className="la la-chart-bar"></i>
                <span>Overview</span>
              </Link>
            </li>
            <li className="nav-item">
              <Link
                to={`/org/secondaries/manage/${secondaryId}/stakeholders`}
                className={
                  "nav-link " +
                  (document.location.href.includes("stakeholders")
                    ? "active"
                    : "")
                }
              >
                <i className="la la-user-circle"></i>
                <span>Add Participants</span>
              </Link>
            </li>
            <li className="nav-item">
              <Link
                to={`/org/secondaries/manage/${secondaryId}/orders`}
                className={
                  "nav-link " +
                  (document.location.href.includes("orders") ? "active" : "")
                }
              >
                <i className="la la-list"></i>
                <span>Orders</span>
              </Link>
            </li>
          </div>
        </ul>
      </div>
    </>
  );
}

export default ManageSecondariesNav;
