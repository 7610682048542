import React from "react";
import { Link } from "react-router-dom";

import Divider from "./divider";

function SettingsNav() {
  const activeSetting =
    document.location.href.split("/")[
      document.location.href.split("/").length - 1
    ];

  return (
    <>
      <div className="card settings_menu">
        <h4 className="my-3">Contract Settings</h4>
        <Divider
          style={{
            position: "relative",
            left: 0,
            marginBottom: 16,
            height: 2,
            marginRight: 4,
          }}
        />

        <div className="card-body">
          <ul>
            <li
              className={
                "nav-item " + (activeSetting === "rules" ? "active" : "")
              }
            >
              <Link to={"./rules"} className="nav-link">
                <i className="la la-lock"></i>
                <span>Trading rules</span>
              </Link>
            </li>
            <li
              className={
                "nav-item " + (activeSetting === "kyc" ? "active" : "")
              }
            >
              <Link to={"./verify-identity"} className="nav-link">
                <i className="la la-bank"></i>
                <span>Investor rules</span>
              </Link>
            </li>
            <li
              className={
                "nav-item " + (activeSetting === "issue" ? "active" : "")
              }
            >
              <Link to={"./issue"} className="nav-link">
                <i className="la la-usd"></i>
                <span>Issuance</span>
              </Link>
            </li>
          </ul>
        </div>
      </div>
    </>
  );
}

export default SettingsNav;
