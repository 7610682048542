import { duotone } from "@fortawesome/fontawesome-svg-core/import.macro";
import { Dialog, DialogActions } from "@mui/material";
import { post } from "jsx/api";
import { get } from "jsx/api";
import { UserContext } from "jsx/contexts/user-context";
import MSButton from "jsx/element/button";
import EmptyState from "jsx/element/empty-state";
import SellerHeader from "jsx/layout/seller-header";
import React, { useEffect, useState } from "react";

import OrdersTable from "./seller-orders-table";
import SellerTendersNav from "./seller-tenders-nav";

export default function SellerViewOrders({ match }) {
  const [organizationId, setOrganizationId] = useState();
  const { organizationId: initialOrgId } = match.params;
  const { user } = React.useContext(UserContext);
  const [secondary, setSecondary] = useState();
  const [orders, setOrders] = useState();
  const [isCancelingOrders, setIsCancelingOrders] = useState(false);
  const [isLoading, setIsLoading] = useState(false);

  async function fetchSecondaries() {
    if (!secondary) {
      const result = await get("users/getSecondaries");
      if (result.data && result.data.length > 0) {
        let secondary;
        if (initialOrgId) {
          secondary = result.data.find(
            (sec) => sec.organizationId === initialOrgId
          );
        } else {
          secondary = result.data?.[0];
        }
        setOrganizationId(secondary.organizationId);
        setSecondary(secondary);
      }
    }
  }

  useEffect(() => {
    if (!secondary && user.uid) {
      fetchSecondaries();
    }
    if (organizationId) {
      fetchOrders();
    }
  }, [organizationId, secondary, user.uid]);

  async function fetchOrders() {
    const result = await get("secondaries/listOrders", { organizationId });
    if (result.data?.secondaryOrders?.length > 0) {
      setOrders(result.data.secondaryOrders);
    }
  }

  async function cancelOrders() {
    const cancelable = orders?.filter(({ status }) => status === "open");
    for (const order of cancelable) {
      await post(`secondaries/cancelOrder`, {
        secondaryId: order.secondaryId,
        organizationId: order.organizationId,
        secondaryOrderId: order.id,
      });
    }
  }

  return (
    <>
      <SellerHeader />

      <div className="content-body section-padding">
        <div className="container">
          <div className="d-flex">
            <div className="me-4" style={{ minWidth: "220px" }}>
              <SellerTendersNav
                organizationId={organizationId}
                secondaryId={secondary?.id}
              />
            </div>
            <div className="col-xl-10">
              <div className="card outlined">
                <div className="card-header d-flex justify-content-start">
                  <h4 className="card-title">Orders</h4>
                  {secondary?.status === "active.ordering.seller" &&
                    orders?.length &&
                    orders.some(({ status }) => status === "open") && (
                      <MSButton
                        className="ms-auto"
                        title="Cancel orders"
                        size="small"
                        onClick={(e) => {
                          setIsCancelingOrders(true);
                        }}
                      />
                    )}
                </div>
                {orders?.length ? (
                  <OrdersTable orders={orders} />
                ) : (
                  <EmptyState
                    icon={duotone("rectangle-history-circle-user")}
                    title={"No orders to display"}
                    subtitle={
                      "When you put in your orders, they will appear here"
                    }
                  />
                )}
              </div>
            </div>
          </div>
        </div>
      </div>

      <Dialog
        open={isCancelingOrders}
        onClose={() => {
          setIsCancelingOrders(false);
        }}
        aria-labelledby="alert-dialog-title"
        aria-describedby="alert-dialog-description"
      >
        <div className="p-4">
          <h3> Confirm order cancellation</h3>
          <p className="mb-0">
            By canceling an order, you are withdrawing your election to sell
            units in the offer. As a result, you will not participate in the
            offer unless you place a new order prior to the offer's expiration.
          </p>
        </div>

        <DialogActions className="me-3 mb-3">
          <MSButton
            title="Back"
            onClick={() => {
              setIsCancelingOrders(false);
            }}
            size="small"
          >
            Back
          </MSButton>
          <MSButton
            title="Cancel submitted orders"
            variant="danger"
            onClick={async () => {
              setIsLoading(true);
              await cancelOrders();
              await fetchOrders();
              setIsLoading(false);
              setIsCancelingOrders(false);
            }}
            size="small"
            loading={isLoading}
            disabled={isLoading}
            autoFocus
          >
            Cancel submitted orders
          </MSButton>
        </DialogActions>
      </Dialog>
    </>
  );
}
