import React from "react";
import { NavLink } from "react-router-dom";

function Sidebar() {
  const activeStyle = {
    color: "white",
  };
  return (
    <>
      <div className="captable-sidebar">
        <div className="menu">
          <ul>
            <li>
              <NavLink
                activeStyle={activeStyle}
                to={"/captable/company-details"}
                data-toggle="tooltip"
                data-placement="right"
                title="Company Details"
              >
                Company Details
              </NavLink>
            </li>
            <li>
              <NavLink
                activeStyle={activeStyle}
                to={"/captable/incorporation-documents"}
                data-toggle="tooltip"
                data-placement="right"
                title="Incorporation Documents"
              >
                Incorporation Documents
              </NavLink>
            </li>
            <li>
              <NavLink
                activeStyle={activeStyle}
                to={"/captable/account-administrators"}
                data-toggle="tooltip"
                data-placement="right"
                title="Account Administrators"
              >
                Account Administrators
              </NavLink>
            </li>
            <li>
              <NavLink
                activeStyle={activeStyle}
                to={"/captable/signatories/"}
                data-toggle="tooltip"
                data-placement="right"
                title="Signatories"
              >
                Signatories
              </NavLink>
            </li>
            <li>
              <NavLink
                activeStyle={activeStyle}
                to={"/captable/shareholder-experience/"}
                data-toggle="tooltip"
                data-placement="right"
                title="Shareholder Experience"
              >
                Shareholder Experience
              </NavLink>
            </li>
            <li>
              <NavLink
                activeStyle={activeStyle}
                to={"/captable/common-shares/"}
                data-toggle="tooltip"
                data-placement="right"
                title="Common Shares"
              >
                Common Shares
              </NavLink>
            </li>
            <li>
              <NavLink
                activeStyle={activeStyle}
                to={"/captable/convertible-notes/"}
                data-toggle="tooltip"
                data-placement="right"
                title="Convertible Notes"
              >
                Convertible Notes
              </NavLink>
            </li>
            {/* TODO (DUMITRU) add missing routes */}
          </ul>
        </div>
      </div>
    </>
  );
}

export default Sidebar;
