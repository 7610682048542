import "react-dates/initialize";
import "react-dates/lib/css/_datepicker.css";
import "react-datepicker/dist/react-datepicker.css";

import Header4 from "jsx/layout/header4";
import React from "react";

import SecondaryForm from "./secondary-form";

function CompanySecondariesManageCreate() {
  return (
    <>
      <Header4 hideAllMenuItems title="Create secondary round" />
      <div className="content-body section-padding">
        <div className="container mb-5">
          <div className="row justify-content-center align-items-center">
            <div className="col-12">
              <div className="row">
                <div className="card outlined">
                  <div className="card-header" style={{ display: "block" }}>
                    <h2>Create secondary round</h2>
                    <p>
                      Setup a new secondary round to generate documents and
                      invite investors
                    </p>
                  </div>
                  <div className="card-body">
                    <SecondaryForm />
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
}

export default CompanySecondariesManageCreate;
