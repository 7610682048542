import { regular } from "@fortawesome/fontawesome-svg-core/import.macro";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import Button from "@mui/material/Button";
import ButtonGroup from "@mui/material/ButtonGroup";
import { collection, doc, getFirestore } from "firebase/firestore";
import { postBeacon } from "jsx/api";
import MSButton from "jsx/element/button";
import React, { useEffect, useRef, useState } from "react";
import { Document, Page } from "react-pdf/dist/esm/entry.webpack";
import { useVisibilityChange } from "use-visibility-change";

function degreesToRadians(degrees) {
  return degrees * (Math.PI / 180);
}

let timeSinceLastChange = new Date();
let log = { timePerPage: {} };

function PDFViewer({
  file,
  organizationId,
  deckId,
  roundId,
  secondaryId,
  showDownloadButton,
  email,
  displayName,
  showWatermark,
  useAnalytics,
  userId,
  hideFullScreenButton,
}) {
  const pdfWrapperRef = useRef();
  const [numPages, setNumPages] = useState(null);
  const canvas = useRef();
  const [isRendered, setIsRendered] = useState();
  const [isFullscreen, setFullscreen] = useState(false);
  const [sessionId, setSessionId] = useState();
  const [pageNumber, setPageNumber] = useState(1);
  const db = getFirestore();

  function onRenderSuccess() {
    setIsRendered(true);
  }

  function previousPage() {
    logPageChanged(pageNumber);
    const page = Math.max(1, pageNumber - 1);
    setPageNumber(page);
    setIsRendered(false);
  }

  function nextPage() {
    logPageChanged(pageNumber);
    const page = Math.min(numPages, pageNumber + 1);
    setPageNumber(page);
    setIsRendered(false);
  }

  function onDocumentLoadSuccess({ numPages }) {
    setNumPages(numPages);
  }

  // watermark
  useEffect(() => {
    if (!canvas.current) {
      return;
    }
    if (showWatermark) {
      watermark();
    }
  }, [isRendered]);

  useEffect(() => {
    logStartSession();
  }, []);

  const onHide = () => {
    logEndSession();
  };

  const onShow = () => {
    logStartSession();
  };

  useVisibilityChange({ onShow, onHide });

  function watermark() {
    let wordWrap = `${email} `;
    wordWrap = wordWrap.repeat(20);
    wordWrap += "\n";
    wordWrap = wordWrap.repeat(50);

    var context = canvas.current.getContext("2d");
    var { width, height } = canvas.current;

    context.save();
    const lineheight = 100;

    const angle = -15;
    context.translate(width / 2, lineheight);
    context.translate(0, 0);
    context.rotate(degreesToRadians(angle));
    context.globalCompositeOperation = "multiply";
    context.textAlign = "center";
    context.font = "40px sans-serif";
    context.fillStyle = "rgba(0, 0, 0, .08)";

    var lines = wordWrap.split("\n");

    for (var i = 0; i < lines.length; i++)
      context.fillText(lines[i], 0, -300 + i * lineheight);

    context.restore();
  }

  let pdfStyle = {};
  let pdfHeaderStyle = {};
  let pdfDividerStyle = {};

  if (isFullscreen) {
    pdfStyle = {
      position: "absolute",
      top: 0,
      left: 0,
      right: 0,
      height: "100%",
      zIndex: 9998,
      backgroundColor: "black",
      border: "0px solid",
    };
    pdfHeaderStyle = {
      position: "absolute",
      top: 0,
      left: 0,
      right: 0,
      backgroundColor: "#303133",
      paddingTop: 13,
      paddingBottom: 13,
      zIndex: 9999,
    };
    pdfDividerStyle = {
      height: "4px",
      backgroundColor: "var(--primary)",
      position: "absolute",
      left: 0,
      right: 0,
      top: 64,
      zIndex: 2,
    };
  } else {
    pdfDividerStyle = {
      height: "1px",
      backgroundColor: "#DDD",
      position: "absolute",
      left: 0,
      right: 0,
      top: 70,
      zIndex: 2,
    };
  }

  // Start analytics
  function logPageChanged(oldPage) {
    if (!useAnalytics) {
      return;
    }
    const currentDate = new Date();
    const timeSpent = currentDate - timeSinceLastChange;

    log.timePerPage[oldPage] = log.timePerPage[oldPage]
      ? log.timePerPage[oldPage] + timeSpent
      : timeSpent;
    timeSinceLastChange = new Date();
  }

  async function logEndSession() {
    if (!useAnalytics) {
      return;
    }
    logPageChanged(pageNumber);
    log.endingPage = pageNumber;
    log.endDate = new Date().getTime();
    log.totalPages = numPages;

    await postBeacon("logVisit", {
      payload: JSON.stringify({
        log,
        organizationId: organizationId,
        deckId: deckId,
        ...(roundId && { roundId: roundId }),
        ...(secondaryId && { secondaryId: secondaryId }),
      }),
    });

    log = { timePerPage: {} };
  }

  function logStartSession() {
    if (!useAnalytics) {
      return;
    }
    timeSinceLastChange = new Date();
    const docRef = doc(collection(db, "tmp"));
    setSessionId(docRef.id);

    log.createdDate = timeSinceLastChange.getTime();
    log.email = email;
    log.displayName = displayName;
    log.userId = userId;
  }

  // end analytics

  function handleKeydown(event) {
    const key = event.key;
    if (key === "ArrowRight") {
      nextPage();
    } else if (key === "ArrowLeft") {
      previousPage();
    } else if (key === "f") {
      setFullscreen(!isFullscreen);
    }
  }

  useEffect(() => {
    // resets rendering when the pdf is changed
    setTimeout(() => {
      setIsRendered(false);
    }, 100);

    if (isFullscreen) {
      window.addEventListener("keydown", handleKeydown);
    } else {
      window.removeEventListener("keydown", handleKeydown);
    }
  }, [isFullscreen]);

  return (
    <div className="outlined card" ref={pdfWrapperRef} style={pdfStyle}>
      <div
        className="d-flex justify-content-center mb-4"
        style={pdfHeaderStyle}
      >
        {showDownloadButton && (
          <a
            href={file}
            download
            className="mt-2 btn btn-small light btn-outline d-sm-none"
            style={{
              minWidth: 36,
              minHeight: 36,
              padding: "0px",
              position: "absolute",
              left: 16,
              top: 6,
            }}
          >
            <i
              style={{ left: 0, top: 6, position: "relative" }}
              className={`las la-download`}
            ></i>
          </a>
        )}

        <ButtonGroup
          variant="outlined"
          style={{ zIndex: 10, marginTop: "-8px" }}
        >
          <Button
            style={{
              borderColor: "var(--border-color)",
              color: "#333",
              backgroundColor: "white",
            }}
            onClick={previousPage}
          >
            <FontAwesomeIcon icon={regular("minus")} />
          </Button>
          <Button
            style={{
              borderColor: "var(--border-color)",
              color: "#333",
              backgroundColor: "white",
            }}
            disabled
          >
            <span>
              Page {pageNumber} of {numPages}
            </span>
          </Button>
          <Button
            style={{
              borderColor: "var(--border-color)",
              color: "#333",
              backgroundColor: "white",
            }}
            onClick={nextPage}
          >
            <FontAwesomeIcon icon={regular("plus")} />
          </Button>
        </ButtonGroup>
        {file && showDownloadButton && (
          <a
            href={file}
            download
            className="btn btn-small light btn-outline position-absolute  d-none d-sm-block"
            style={{ right: "76px" }}
          >
            Download
          </a>
        )}
        <div
          style={{
            right: "16px",
            minWidth: 36,
            minHeight: 36,
            padding: "0px",
            position: "absolute",
          }}
          className="d-none d-lg-block"
        >
          {!hideFullScreenButton && (
            <MSButton
              variant="icon"
              onClick={() => {
                setFullscreen(!isFullscreen);
              }}
              style={{ backgroundColor: "white" }}
            >
              <FontAwesomeIcon
                icon={
                  isFullscreen
                    ? regular("arrows-minimize")
                    : regular("arrows-maximize")
                }
                size="xs"
              />
            </MSButton>
          )}
        </div>
      </div>
      <div style={pdfDividerStyle}></div>
      {!file && (
        <div className="d-flex justify-content-center py-4">
          <div className="spinner-border" />
        </div>
      )}

      {file && (
        <div
          className="d-flex justify-content-center py-4"
          style={{ marginTop: isFullscreen ? 20 : 0 }}
        >
          <Document
            file={file}
            onLoadError={(e) => {
              console.log(e);
            }}
            onLoadSuccess={onDocumentLoadSuccess}
            loading={<div style={{ width: "100%", height: "800px" }} />}
          >
            <Page
              width={
                isFullscreen
                  ? undefined
                  : pdfWrapperRef.current?.getBoundingClientRect().width - 60
              }
              height={window.innerHeight - 80}
              pageNumber={pageNumber}
              canvasRef={canvas}
              onRenderSuccess={onRenderSuccess}
              loading={<div style={{ width: "100%", height: "800px" }} />}
            />
          </Document>
        </div>
      )}
    </div>
  );
}

export default PDFViewer;
