import {
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableRow,
} from "@mui/material";
import React, { useState } from "react";

export function OrderPreview({
  pricePerShare,
  previewDetails: {
    grossProceedsInCents,
    exerciseCostInCents,
    netProceedsInCents,
    securities,
  },
}) {
  const [showSecurity, setShowSecurity] = useState({});
  return (
    <>
      <h4>Sell confirmation</h4>
      <TableContainer>
        <Table>
          <TableBody>
            {securities.map((security, i) => {
              return (
                <TableRow key={i}>
                  <TableCell>
                    <div style={{ fontWeight: "500" }}>
                      {security.certificateNo} @
                      {pricePerShare?.toLocaleString("en-US", {
                        minimumFractionDigits: 2,
                        maximumFractionDigits: 2,
                        currency: "USD",
                        style: "currency",
                      })}{" "}
                    </div>
                    <div style={{ color: "#999", marginTop: 4 }}>
                      × {security.quantity} units
                    </div>
                    {showSecurity[security.certificateNo] && (
                      <div style={{ color: "var(--gray-600)" }}>
                        <div>Estimated proceeds</div>
                      </div>
                    )}
                  </TableCell>
                  <TableCell align="right">
                    <div style={{ fontWeight: "500" }}>
                      {(
                        security.estimatedProceeds?.grossProceedsInCents / 100
                      ).toLocaleString("en-US", {
                        minimumFractionDigits: 2,
                        maximumFractionDigits: 2,
                        currency: "USD",
                        style: "currency",
                      })}
                    </div>
                    {security.priority && (
                      <div style={{ color: "#999", marginTop: 4 }}>
                        Priority {security.priority}
                      </div>
                    )}

                    {showSecurity[security.certificateNo] && (
                      <div style={{ color: "var(--gray-600)" }}>
                        <div>
                          {(
                            security.estimatedProceeds.grossProceedsInCents /
                            100
                          ).toLocaleString("en-US", {
                            minimumFractionDigits: 2,
                            maximumFractionDigits: 2,
                            currency: "USD",
                            style: "currency",
                          })}
                        </div>
                      </div>
                    )}
                  </TableCell>
                </TableRow>
              );
            })}
          </TableBody>
        </Table>
      </TableContainer>
      <h4 style={{ marginTop: 48 }}>Order summary</h4>
      <TableContainer>
        <Table>
          <TableBody>
            <TableRow>
              <TableCell>
                <div style={{ fontSize: 16 }}>Estimated proceeds</div>
              </TableCell>
              <TableCell align="right">
                <div style={{ fontSize: 16, fontWeight: "500" }}>
                  {(grossProceedsInCents / 100).toLocaleString("en-US", {
                    minimumFractionDigits: 2,
                    maximumFractionDigits: 2,
                    currency: "USD",
                    style: "currency",
                  })}
                </div>
              </TableCell>
            </TableRow>
          </TableBody>
        </Table>
      </TableContainer>
    </>
  );
}
