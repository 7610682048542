import { Field, Form, Formik } from "formik";
import States from "jsx/data/states.json";
import SettingsNav from "jsx/element/fundraise-create-nav";
import PageTitle from "jsx/element/page-title";
import Header4 from "jsx/layout/header4";
import React from "react";

import CompanyStages from "./companyStages.json";
import CompanyTypes from "./companyTypes.json";
import Countries from "./countries.json";
import Provinces from "./provinces.json";
function CompanyInfo() {
  return (
    <>
      <Header4 />
      <PageTitle />
      <Formik
        initialValues={{
          countryOfIncorporation: "US",
          stateOfIncorporation: "AL",
          incorporationType: "c",
          companyStage: "pre-launch",
        }}
        onSubmit={async (values) => {
          if (values.countryOfIncorporation !== "US") {
            delete values["stateOfIncorporation"];
            delete values["incorporationType"];
          }
          await new Promise((resolve) => setTimeout(resolve, 500));
        }}
      >
        {({ values }) => (
          <Form>
            {console.log(values)}
            <div className="content-body">
              <div className="container-fluid">
                <div className="row">
                  <div className="col-md-2">
                    <SettingsNav />
                  </div>
                  <div className="col-xl-9 col-md-8">
                    <div className="row">
                      <div className="col-xl-6">
                        <div className="card">
                          <div className="card-header">
                            <h4 className="card-title">Company info</h4>
                          </div>
                          <div className="card-body">
                            <div className="row">
                              <div className="mb-4 col-xl-12">
                                <h5 className="mb-2 mt-3">
                                  Company Legal Name
                                </h5>
                                <p>
                                  If you don’t know, leave blank. We’ll need
                                  this before the deal closes.
                                </p>
                                <Field
                                  type="text"
                                  name="companyName"
                                  className="form-control"
                                  placeholder=""
                                  autoFocus
                                />
                              </div>
                              <div className="mb-4 col-xl-12">
                                <h5 className="mb-2 mt-3">
                                  Country of Incorporation
                                </h5>
                                <Field
                                  name="countryOfIncorporation"
                                  defaultValue="US"
                                  component="select"
                                  className="form-select"
                                >
                                  {Countries.map((item) => {
                                    return (
                                      <option key={item.code} value={item.code}>
                                        {item.name}
                                      </option>
                                    );
                                  })}
                                </Field>
                              </div>
                              {values.countryOfIncorporation === "US" && (
                                <div className="mb-4 col-xl-12">
                                  <h5 className="mb-2 mt-3">
                                    State of Incorporation
                                  </h5>
                                  <Field
                                    name="stateOfIncorporation"
                                    defaultValue="US"
                                    component="select"
                                    className="form-select"
                                  >
                                    <option value="" selected disabled hidden>
                                      Choose here
                                    </option>
                                    {States.map((item) => {
                                      return (
                                        <option
                                          key={item.abbreviation}
                                          value={item.abbreviation}
                                        >
                                          {item.name}
                                        </option>
                                      );
                                    })}
                                  </Field>
                                </div>
                              )}
                              {values.countryOfIncorporation === "CA" && (
                                <div className="mb-4 col-xl-12">
                                  <h5 className="mb-2 mt-3">
                                    Province of Incorporation
                                  </h5>
                                  <Field
                                    name="provinceOfIncorporation"
                                    component="select"
                                    className="form-select"
                                  >
                                    <option value="" selected disabled hidden>
                                      Choose here
                                    </option>
                                    {Provinces.map((item) => {
                                      return (
                                        <option
                                          key={item.abbreviation}
                                          value={item.abbreviation}
                                        >
                                          {item.name}
                                        </option>
                                      );
                                    })}
                                  </Field>
                                </div>
                              )}
                              {values.countryOfIncorporation === "US" && (
                                <div className="mb-4 col-xl-12">
                                  <h5 className="mb-2 mt-3">
                                    Incorporation Type
                                  </h5>
                                  <Field
                                    name="incorporationType"
                                    defaultValue="US"
                                    component="select"
                                    className="form-select"
                                  >
                                    <option value="" selected disabled hidden>
                                      Choose here
                                    </option>
                                    {CompanyTypes.map((item) => {
                                      return (
                                        <option
                                          key={item.value}
                                          value={item.value}
                                        >
                                          {item.name}
                                        </option>
                                      );
                                    })}
                                  </Field>
                                </div>
                              )}
                              <div className="mb-4 col-xl-12">
                                <h5 className="mb-2 mt-3">Company Stage</h5>
                                <Field
                                  name="companyStage"
                                  defaultValue="US"
                                  component="select"
                                  className="form-select"
                                >
                                  <option value="" selected disabled hidden>
                                    Choose here
                                  </option>
                                  {CompanyStages.map((item) => {
                                    return (
                                      <option
                                        key={item.value}
                                        value={item.value}
                                      >
                                        {item.name}
                                      </option>
                                    );
                                  })}
                                </Field>
                              </div>
                              <div className="mb-4 col-xl-12">
                                <h5 className="mb-2 mt-3">Post-Round Runway</h5>
                                <p>
                                  How many months of runway will the company
                                  have after this round?
                                </p>
                                <Field
                                  type="number"
                                  name="months"
                                  className="form-control"
                                  placeholder=""
                                  autoFocus
                                />
                              </div>
                              <button className="btn" type="submit">
                                Submit
                              </button>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </Form>
        )}
      </Formik>
    </>
  );
}

export default CompanyInfo;
