import {
  collection,
  getDocs,
  getFirestore,
  onSnapshot,
  orderBy,
  query,
} from "firebase/firestore";
import React, { useEffect, useState } from "react";

function OrderBook(props) {
  const [orders, setOrders] = useState([]);

  const db = getFirestore();

  var contractId = "seriesa";
  var companyId = "modernshare";

  async function listenForChanges() {
    const collectionRef = collection(
      db,
      "companies",
      companyId,
      "contracts",
      contractId,
      "orders"
    );
    const q = query(collectionRef, orderBy("createdDate", "desc"));

    onSnapshot(q, (querySnapshot) => {
      processOrders(querySnapshot);
    });
  }

  function processOrders(querySnapshot) {
    var ret = [];
    querySnapshot.forEach((doc) => {
      const data = doc.data();
      ret.push(
        <tr key={doc.id}>
          <td>
            <span
              className={
                "badge badge-" + (data.buySell === "buy" ? "success" : "danger")
              }
            >
              {data.buySell}
            </span>
          </td>

          <td
            className={
              "text-" + (data.buySell === "buy" ? "success" : "danger")
            }
          >
            {data.numberOfShares} shares
          </td>
          <td>{data.filled}</td>
          <td>${data.price}</td>
          <td>{data.status}</td>
        </tr>
      );
    });

    setOrders(ret);
  }

  async function fetchOpenORders() {
    const collectionRef = collection(
      db,
      "companies",
      companyId,
      "contracts",
      contractId,
      "orders"
    );
    const q = query(collectionRef, orderBy("createdDate", "desc"));
    const querySnapshot = await getDocs(q);
    processOrders(querySnapshot);
  }

  useEffect(() => {
    fetchOpenORders();
    listenForChanges();
  }, []);

  return (
    <>
      <table className="table table-trading">
        <thead>
          <tr>
            <th>Order</th>
            <th>Shares</th>
            <th>Filled</th>
            <th>Price</th>
            <th>Status</th>
          </tr>
        </thead>
        <tbody>{orders}</tbody>
      </table>
    </>
  );
}

export default OrderBook;
