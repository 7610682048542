import { doc, getDoc, getFirestore } from "@firebase/firestore";
import { duotone } from "@fortawesome/fontawesome-svg-core/import.macro";
import { Skeleton } from "@mui/material";
import { get, post } from "jsx/api";
import MSButton from "jsx/element/button";
import EmptyState from "jsx/element/empty-state";
import Header4 from "jsx/layout/header4";
import React, { useEffect, useState } from "react";
import { Link } from "react-router-dom";

import InvestorSecondaryTransactionTable from "./investor-secondary-transaction-table";

function InvestorSecondaryOrder({ match }) {
  const [order, setOrder] = useState();
  const [transactions, setTransactions] = useState();
  const [calculatedTotalShares, setCalculatedTotalShares] = useState(0);
  const [calculatedPriceTotal, setCalculatedPriceTotal] = useState(0);
  const [isCancelling, setCancelling] = useState(false);
  const [isFetchingTransactions, setFetchingTransactions] = useState(false);

  const { organizationId, secondaryId, orderId } = match.params;

  const db = getFirestore();

  async function fetchOrder() {
    setFetchingTransactions(true);
    const orderSnapshot = await getDoc(
      doc(
        db,
        "organizations",
        organizationId,
        "organizations_admin",
        "data",
        "secondaries",
        secondaryId,
        "secondary_orders",
        orderId
      )
    );
    const orderData = orderSnapshot.data();
    if (!orderData) {
      return;
    }

    orderData.id = orderSnapshot.id;
    setOrder(orderData);

    const result = await get("secondaries/listTransactionsByOrderId", {
      orderId: orderSnapshot.id,
      // organizationId: orderData.organizationId,
    });

    setTransactions(result.data.secondaryTransactions);
    setFetchingTransactions(false);

    return orderData;
  }

  useEffect(() => {
    fetchOrder();
  }, []);

  useEffect(() => {
    if (!transactions) {
      return;
    }
    let quantityShares = 0;
    let priceTotal = 0;

    transactions.forEach((e) => {
      quantityShares += e.quantity;
      priceTotal +=
        (e.pricePerShareInBaseUnits / e.pricePerSharePrecision) * e.quantity;
    });

    setCalculatedPriceTotal(priceTotal);
    setCalculatedTotalShares(quantityShares.toLocaleString());
  }, [transactions]);

  async function cancelOrder() {
    setCancelling(true);

    await post(`secondaries/cancelOrder`, {
      secondaryId: secondaryId,
      organizationId: organizationId,
      secondaryOrderId: order.id,
    });
    setCancelling(false);
    await fetchOrder();
  }

  let orderBlock, transactionBlock;

  if (order) {
    orderBlock = (
      <>
        <div className="mt-4 mb-3">
          <h4>Order details</h4>
        </div>
        <div className="card outlined-small-thin py-3">
          <div className="row">
            <div className="col">
              <Detail title="Date">
                {new Date(
                  order.createdDate.seconds * 1000
                ).toLocaleDateString()}
              </Detail>
            </div>
            <div className="col" style={{ maxWidth: 160 }}>
              <Detail title={"Price Per Share"}>
                {(
                  order.pricePerShareInBaseUnits / order.pricePerSharePrecision
                ).toLocaleString(undefined, {
                  style: "currency",
                  currency: "USD",
                  minimumFractionDigits: Math.max(
                    2,
                    Math.log10(order.pricePerSharePrecision)
                  ),
                })}
              </Detail>
            </div>
            <div className="col" style={{ maxWidth: 140 }}>
              <Detail title="Quantity">
                {order.quantity.toLocaleString()}
              </Detail>
            </div>
            <div className="col" style={{ maxWidth: 140 }}>
              <Detail title="Total">
                {(
                  (order.pricePerShareInBaseUnits /
                    order.pricePerSharePrecision) *
                  order.quantity
                ).toLocaleString(undefined, {
                  style: "currency",
                  currency: "USD",
                  minimumFractionDigits: Math.max(
                    2,
                    Math.log10(order.pricePerSharePrecision)
                  ),
                })}
              </Detail>
            </div>
            <div className="col" style={{ maxWidth: 140 }}>
              <Detail title="Status">{order.status}</Detail>
            </div>
          </div>
        </div>
      </>
    );
  } else {
    orderBlock = (
      <>
        <Skeleton height={50} style={{ marginTop: 30 }} />
        <Skeleton height={50} />
        <Skeleton height={50} />
      </>
    );
  }

  const resultHeaderSection = {
    minWidth: 80,
    flexDirection: "column",
    marginLeft: "48px",
    textAlign: "right",
  };

  if (transactions) {
    if (transactions.length) {
      transactionBlock = (
        <>
          <div>
            <h4>Transactions</h4>
          </div>

          <InvestorSecondaryTransactionTable
            transactions={transactions}
            secondaryId={secondaryId}
            organizationId={organizationId}
            loading={isFetchingTransactions}
          />
          <div className="d-flex mt-4 ">
            <div className="flex-grow-1"></div>

            <div style={resultHeaderSection}>
              <h4 className="mb-1">{calculatedTotalShares}</h4>
              <p className="small">Total shares</p>
            </div>

            <div style={resultHeaderSection} className="me-3">
              <h4 className="mb-1">
                {calculatedPriceTotal.toLocaleString(undefined, {
                  style: "currency",
                  currency: "USD",
                })}
              </h4>
              <p className="small">Total filled</p>
            </div>
          </div>
        </>
      );
    } else {
      transactionBlock = (
        <>
          <div>
            <h4>Transactions</h4>
          </div>
          <EmptyState
            style={{ marginTop: 24, marginBottom: 24 }}
            icon={duotone("money-check-dollar")}
            title={"No transactions to display"}
            subtitle={
              "Transasction will appear here once the order becomes filled."
            }
          />
        </>
      );
    }
  } else {
    transactionBlock = (
      <>
        <Skeleton height={500} style={{ marginTop: -80 }} />
      </>
    );
  }

  let viewDetails = "";

  if (transactions && transactions.length) {
    viewDetails = `../transactions/${transactions[0].id}`;
  }

  return (
    <>
      <Header4 hideAllMenuItems />

      <div className="content-body section-padding">
        <div className="container">
          <div className="row justify-content-center align-items-center">
            <div className="col-10">
              <div className="card outlined">
                <div className="card-header">
                  <div className="d-flex" style={{ width: "100%" }}>
                    <div style={{ flexGrow: 1 }}>
                      <h4 className="card-title">Order</h4>
                    </div>

                    <div style={{ marginTop: -12 }}>
                      {!transactions ||
                        (transactions.length === 0 &&
                          order.status !== "canceled" && (
                            <MSButton
                              title="Cancel Order"
                              loading={isCancelling}
                              size="small"
                              variant="danger"
                              onClick={() => cancelOrder()}
                            />
                          ))}
                    </div>
                  </div>
                </div>
                <div>{orderBlock}</div>
                <div className="mb-4">{transactionBlock}</div>
                {viewDetails && (
                  <Link to={viewDetails}>
                    <MSButton
                      title="View details and sign"
                      variant="primary"
                      className="w-100"
                      size="large"
                    />
                  </Link>
                )}
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
}

// helper component for formatting details/info
function Detail({ title, children }) {
  return (
    <div className="p-2">
      <div>
        <h6>{title}</h6>
      </div>
      <div style={{ fontSize: 14 }}>{children}</div>
    </div>
  );
}

export default InvestorSecondaryOrder;
