import { doc, getFirestore, onSnapshot } from "firebase/firestore";
import { UserContext } from "jsx/contexts/user-context";
import Divider from "jsx/element/divider";
import VisitAnalytics from "jsx/element/visit-analytics";
import Header4 from "jsx/layout/header4";
import React, { useEffect, useState } from "react";
import { useParams } from "react-router-dom";
import { CountUp } from "use-count-up";

function timeDateFromMilliseconds(seconds) {
  if (Number.isNaN(seconds)) {
    return 0;
  }

  var date = new Date(0);
  date.setSeconds(seconds / 1000); // specify value for SECONDS here
  return date.toISOString().substr(14, 5);
}

function FundraiseVisits() {
  const { selectedOrganization } = React.useContext(UserContext);
  const [round, setRound] = useState({});
  const [roundVisits, setRoundVisits] = useState([]);

  const { roundId } = useParams();

  const db = getFirestore();

  let totalTime = 0;

  // Javascript reducers are confusion
  if (roundVisits.length > 0) {
    for (const visit of roundVisits) {
      totalTime += visit.t;
    }
  }

  let roundSub;
  let roundVisitsSub;

  useEffect(() => {
    fetchRound();
  }, [roundId, selectedOrganization]);

  async function fetchRound() {
    if (!selectedOrganization || !roundId) {
      return;
    }

    if (!roundSub) {
      roundSub = onSnapshot(
        doc(
          db,
          "organizations",
          selectedOrganization,
          "organizations_private",
          "data",
          "rounds",
          roundId
        ),
        (doc) => {
          // const source = doc.metadata.hasPendingWrites ? "Local" : "Server";
          setRound(doc.data());
        }
      );
    }

    if (!roundVisitsSub) {
      roundVisitsSub = onSnapshot(
        doc(
          db,
          "organizations",
          selectedOrganization,
          "organizations_private",
          "data",
          "rounds",
          roundId,
          "visits",
          "summary"
        ),
        (doc) => {
          if (doc.exists && doc.data() && "visits" in doc.data()) {
            setRoundVisits(doc.data().visits.reverse());
          }
        }
      );
    }
  }

  return (
    <>
      <Header4 hideAllMenuItems />
      <div className="content-body section-padding">
        <div className="container">
          <div className="row justify-content-center align-items-center">
            <div className="col-4 col-xs-12">
              <div className="card outlined py-4">
                <div className="d-flex">
                  <div className="big-icon">
                    <i className="la la-chart-bar"></i>
                  </div>
                  <div>
                    <h2>
                      <CountUp
                        isCounting
                        end={roundVisits.length}
                        duration={0.5}
                        decimalPlaces={0}
                      />
                    </h2>
                    <h6>Total Views</h6>
                  </div>
                </div>
              </div>
            </div>
            <div className="col-4 col-xs-12">
              <div className="card outlined py-4">
                <div className="d-flex">
                  <div className="big-icon">
                    <i className="la la-clock"></i>
                  </div>
                  <div>
                    <h2>
                      {timeDateFromMilliseconds(totalTime / roundVisits.length)}
                    </h2>
                    <h6>Average Time Spent</h6>
                  </div>
                </div>
              </div>
            </div>
            <div className="col-4 col-xs-12">
              <div className="card outlined py-4">
                <div className="d-flex">
                  <div className="big-icon">
                    <i className="la la-stopwatch"></i>
                  </div>
                  <div>
                    <h2>{timeDateFromMilliseconds(totalTime)}</h2>
                    <h6>Total Time Spent</h6>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div className="row justify-content-center align-items-center">
            <div className="col-xl-12">
              <div className="card outlined">
                <div className="card-header border-0">
                  <div>
                    <h4 className="card-title mb-0">Views</h4>
                  </div>
                </div>
                <div className="card-body pt-2">
                  <div className="col-12 mt-3">
                    {round && (
                      <>
                        <div className="outlined py-0">
                          {roundVisits.length === 0 && (
                            <div className="py-4">
                              You have not received any analytics data yet.
                            </div>
                          )}
                          {roundVisits.map((e) => {
                            return (
                              <div key={e.i}>
                                <VisitAnalytics
                                  selectedOrganization={selectedOrganization}
                                  roundId={roundId}
                                  visit={e}
                                />
                                <Divider style={{ marginTop: "0px" }} />
                              </div>
                            );
                          })}
                        </div>
                      </>
                    )}
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
}

export default FundraiseVisits;
