import Header4 from "jsx/layout/header4";
import React, { useEffect } from "react";
import { Link } from "react-router-dom";

function TermsConditions() {
  useEffect(() => {
    window.scrollTo({
      top: 0,
      left: 0,
      behavior: "instant",
    });
  }, []);

  return (
    <>
      <Header4 hideAllMenuItems title="Setup account" />

      <div className="terms_condition mt-4">
        <div className="container">
          <div className="row justify-content-center">
            <div className="col-lg-9">
              <div className="terms_condition-content">
                <h2>Terms of Use</h2>
                <p>
                  PLEASE READ THESE TERMS OF USE (THIS “AGREEMENT” OR THESE
                  “TERMS OF USE”) CAREFULLY. THESE TERMS OF USE ARE A LEGALLY
                  BINDING AGREEMENT BETWEEN YOU AND M-SHARE CAPITAL LLC (“MODERN
                  SHARE”).
                </p>

                <p>
                  THE TERM “YOU” MEANS YOU AND ANY ENTITY OR PERSON YOU MAY
                  LAWFULLY REPRESENT OR ADVISE IN CONNECTION WITH THE USE OF THE
                  SERVICE, AND “YOUR” HAS THE CORRELATED MEANING.
                </p>

                <p>
                  THIS AGREEMENT SETS FORTH THE LEGALLY BINDING TERMS AND
                  CONDITIONS FOR YOUR ACCESS AND USE OF ANY MODERN SHARE SERVICE
                  OR TECHNOLOGY, INCLUDING BUT NOT LIMITED TO ALL DOMAINS AND
                  SUBDOMAINS OF MODERN SHARE’S WEBSITE AT
                  HTTPS://MODERNSHARE.COM (THE “SITE”), MODERN SHARE’S
                  APPLICATION (“APPLICATION”), AND ALL OF THE ASSOCIATED
                  SERVICES, FEATURES, CONTENT, WIDGETS, MATERIALS, AND OTHER
                  TOOLS OFFERED BY MODERN SHARE OR THAT YOU DOWNLOAD FROM THE
                  SITE OR APPLICATION OR FROM ANY THIRD PARTY APPLICATION STORES
                  AUTHORIZED BY MODERN SHARE (BEING REFERRED TO IN THIS
                  AGREEMENT, COLLECTIVELY WITH THE SITE AND APPLICATION, THE
                  “SERVICE”).
                </p>

                <p>
                  SUBJECT TO THESE TERMS OF USE, MODERN SHARE MAY OFFER TO
                  PROVIDE THE SERVICE, AS DESCRIBED MORE FULLY ON THE SITE
                  AND/OR THE APPLICATION, AND WHICH IS SELECTED BY YOU, SOLELY
                  FOR YOUR OWN USE, AND NOT FOR THE USE OR BENEFIT OF ANY THIRD
                  PARTY.
                </p>

                <p>
                  BY REGISTERING FOR AND/OR USING THE SERVICE IN ANY MANNER,
                  INCLUDING BUT NOT LIMITED TO DOWNLOADING, INSTALLING OR USING
                  THE APPLICATION, AND VISITING OR BROWSING THE SITE, YOU AGREE
                  TO BE LEGALLY BOUND BY THIS AGREEMENT. THIS AGREEMENT APPLIES
                  TO ALL USERS OF THE SERVICE, INCLUDING USERS WHO ARE ALSO
                  CONTRIBUTORS OF CONTENT, INFORMATION, AND OTHER MATERIALS OR
                  SERVICES ON THE SITE OR APPLICATION.
                </p>

                <h3>Acceptance of terms; incorporation of related terms</h3>

                <p>
                  The Service is offered subject to acceptance without
                  modification of all of the terms and conditions contained in
                  these Terms of Use, which terms also incorporate Modern
                  Share’s Privacy Policy, located{" "}
                  <Link to="/privacy-policy">here</Link>, and all other
                  operating rules, policies and procedures that may be published
                  from time to time on the Site or the Application by Modern
                  Share, each of which is incorporated by reference and each of
                  which may be updated by Modern Share from time to time without
                  notice to you. In addition, some services offered through the
                  Service may be subject to additional terms and conditions
                  promulgated by Modern Share from time to time; your use of
                  such services is subject to those additional terms and
                  conditions, which are incorporated into these Terms of Use by
                  this reference.
                </p>

                <p>
                  When or if you register on the Site or the Application (as
                  more fully explained below) you will also be prompted to agree
                  to a Non-Disclosure Agreement between you and Modern Share. To
                  the extent that you accept and agree to any Non-Disclosure
                  Agreement with Modern Share, that Non-Disclosure Agreement is
                  also incorporated herein by reference.
                </p>

                <p>
                  If you do not acknowledge or agree with these Terms of Use, do
                  not use or browse the Site, or download, install, or use the
                  Application. If you do not acknowledge or agree with these
                  Terms of Use, you must also immediately uninstall the
                  Application and discontinue the use of the Services. Continued
                  use of the Services signifies your continued acceptance of
                  these terms and any changes to them.
                </p>

                <h3>Eligibility</h3>

                <p>
                  The Service is available only to individuals who are at least
                  18 years old. You represent and warrant that if you are an
                  individual, you are of legal age to form a binding contract,
                  and that all registration information you submit is accurate
                  and truthful. Modern Share may, in its sole discretion, refuse
                  to offer the Service to any person or entity and change its
                  eligibility criteria at any time. This provision is void where
                  prohibited by law and the right to access the Service is
                  revoked in such jurisdictions.
                </p>

                <p>
                  Portions of the Service are available only to “Accredited
                  Investors” as that term is defined by the U.S. Securities and
                  Exchange Commission, which may be found{" "}
                  <a href="https://www.investor.gov/introduction-investing/general-resources/news-alerts/alerts-bulletins/investor-bulletins/updated-3">
                    here
                  </a>
                  .
                </p>

                <h3>Modification of terms of use</h3>

                <p>
                  Modern Share reserves the right, at its sole discretion, to
                  modify or replace any of the Terms of Use, or change, suspend,
                  or discontinue the Site, Application or any part of the
                  Service (including without limitation, the availability of any
                  feature, database, or content) at any time by posting a notice
                  on the Site or Application or by sending you an email. Modern
                  Share may also impose limits on certain features and services
                  or restrict your access to parts or all of the Service without
                  notice or liability. It is your responsibility to check the
                  Terms of Use periodically for changes. Your continued use of
                  the Site, Application or the Service following the posting of
                  any changes to the Terms of Use constitutes acceptance of
                  those changes.
                </p>

                <h3>REGISTRATION</h3>

                <p>
                  You may browse the Site and view Content on the Site without
                  registering, but as a condition to using certain aspects of
                  the Site and the Application, you are required to register
                  with Modern Share and provide Modern Share with accurate,
                  truthful, and complete registration information (including,
                  but not limited to your name (“User Name”), e-mail address and
                  a password you will use to access the Site, Application and
                  the Service). Information gathered through the registration
                  process and information related to your account will be
                  subject to these Terms of Use as well as to our Privacy Policy
                  located <Link to="/privacy-policy">here</Link>.
                </p>
                <p>
                  You represent and warrant that all information provided by you
                  when registering is true, accurate, up-to-date and complete
                  and that you will maintain, at all times, true, accurate,
                  up-to-date, and complete information related to your
                  registration, including any information or affirmation
                  provided with respect to your status as an “Accredited
                  Investor.” If your status as, or the basis on which you
                  qualify as, an “Accredited Investor” changes after
                  registration, you are required to notify Modern Share at{" "}
                  <a href="mailto:support@modernshare.com">
                    support@modernshare.com
                  </a>{" "}
                  as soon as practicable. Failure to comply with any of the
                  obligations in this paragraph shall constitute a breach of the
                  Terms of Use, which may result in immediate termination of
                  your Modern Share account.
                </p>
                <p>You shall not:</p>
                <ul>
                  <li>
                    provide any false personal information to Modern Share
                    (including a false User Name) or create any account for
                    anyone other than yourself without permission;
                  </li>
                  <li>
                    use a User Name that is the name of another person with the
                    intent to impersonate that person;
                  </li>
                  <li>
                    use a User Name that is a name subject to any rights of a
                    person other than you without appropriate authorization; or
                  </li>
                  <li>
                    use a User Name that is a name that is otherwise offensive,
                    vulgar or obscene.
                  </li>
                </ul>

                <p>
                  Modern Share reserves the right to refuse registration of or
                  cancel a User Name in its sole discretion.
                </p>

                <p>
                  You are solely responsible for all transactions and activities
                  that occur on your account, whether undertaken by you or
                  anyone or anything using the Service while using your User
                  Name, whether authorized or unauthorized. You shall be
                  responsible for maintaining security and confidentiality with
                  respect to your Modern Share password and information related
                  to your registration. Modern Share shall not be responsible
                  for any losses arising from the financial loss or theft of
                  your information due to unauthorized or fraudulent
                  transactions using your account. You shall also never use
                  another user’s account without such other user’s express
                  permission. You will immediately notify Modern Share in
                  writing of any actual or suspected unauthorized use of your
                  account, or other account related security breach of which you
                  are aware.
                </p>

                <p>
                  To the extent applicable, when utilizing the Site, Application
                  or the Service, you are required to use the security
                  procedures currently or hereafter maintained by Modern Share
                  to confirm that only authorized users have access to the
                  Service and any account.
                </p>

                <h3>Transaction Fees</h3>
                <p>
                  When you complete a transaction with the assistance of the
                  Modern Share platform, whether through the Site or the
                  Application and whether as a buyer or a seller, you agree to
                  pay Modern Share a transaction fee of up to five percent (5%)
                  of the value of each such completed transaction. The amount of
                  such transaction fee will be determined by Modern Share, in
                  its sole discretion, on a transaction-by-transaction basis but
                  will not exceed the percentage stated in the preceding
                  sentence.
                </p>
                <p>
                  Modern Share also reserve the right to institute reasonable
                  fees to be payable by company-issuer users at a later date.
                </p>

                <h3>Consent to electronic communications</h3>

                <p>
                  You specifically agree to receive and/or obtain
                  “Communications” from Modern Share and its affiliates in
                  “Electronic Form”, rather than in paper form, and to the use
                  of electronic signatures in our relationship with you. The
                  term “Communications” includes, but is not limited to,
                  documents, agreements, terms, statements, data, records and
                  any other communications regarding your relationship to Modern
                  Share and/or its affiliates, including Form CRS or any other
                  notices, summaries, forms, disclosures or documents required
                  by law, rule or regulation. The term “Electronic Form”
                  includes, but is not limited to, electronic delivery of
                  Communications posted or made available on the Site and/or
                  through the Application, and/or sent via the e-mail address
                  associated with the account through which you access the
                  Modern Share platform, or through which investment
                  opportunities are accessed, or transactions are conducted, on
                  your behalf.
                </p>

                <p>
                  You accept Communications provided in Electronic Form as
                  reasonable and proper notice, for the purpose of any and all
                  laws, rules, and regulations, and agree that delivery of such
                  Communications in Electronic Form fully satisfies any
                  requirement that such Communications be provided to you in
                  writing or in a form that you may keep. You represent that you
                  have the appropriate personal computer or other electronic
                  device, software, and internet connection to enable you to
                  retain, if you so choose, any Communications that Modern Share
                  sends in Electronic Form for your records.
                </p>

                <p>
                  If you have the legal right under any federal and/or state
                  laws or regulations to receive a document in paper form, you
                  may withdraw your consent to receiving that document in
                  Electronic Form by contacting Modern Share at{" "}
                  <a href="mailto:support@modernshare.com">
                    support@modernshare.com
                  </a>
                  . A withdrawal of your consent to receive such document in
                  Electronic Form will be effective only after we have had
                  reasonable time to process your request.
                </p>

                <h3>Securities products</h3>

                <p>
                  Any securities mentioned on the Site, Application or as part
                  of the Service are suitable only for prospective investors who
                  are familiar with and willing to accept the high risks
                  associated with private investments, including the risk of
                  complete loss of the investment. Securities sold through
                  private placements are not publicly traded and, therefore, are
                  illiquid unless and until registered with the U.S. Securities
                  and Exchange Commission (the “SEC”), if at all. Securities
                  will be subject to restrictions on resale and transfer,
                  including holding period requirements. Investing in private
                  placements requires high tolerance for risk, low need for
                  liquidity, and willingness to make long-term commitments.
                  Investors must be able to afford to lose their entire
                  investment. Investment opportunities available on the Site,
                  Application or as part of the Service are not FDIC-insured,
                  may lose value, and are not guaranteed by any bank or
                  institution.
                </p>

                <p>
                  Any securities mentioned have not been registered under the
                  Securities Act, in reliance on exemptions thereto. Similar
                  reliance has been placed on apparently available exemptions
                  from securities registration or qualification requirements
                  under applicable state securities laws. Modern Share does not
                  represent that any governmental agency has necessarily
                  reviewed the Site, Application or the Service or has passed
                  upon either the adequacy of the disclosure contained therein
                  or the fairness of the terms of any transaction available
                  thereon.{" "}
                </p>

                <p>
                  The exemptions relied upon for such transactions are
                  significantly dependent upon the accuracy of representations
                  made by users of Modern Share, including you, as well as the
                  issuers of the applicable securities, each as may be reflected
                  in applicable transaction documents. In the event that any
                  such representations prove to be untrue, the registration
                  exemptions might not be available and substantial liability
                  could result. These risks are non-exhaustive and are intended
                  to highlight certain risks associated with investing in
                  securities that are not registered with the SEC. WE STRONGLY
                  ADVISE ALL PERSONS AND ENTITIES WHO ELECT TO PARTICIPATE IN
                  TRANSACTIONS ON THE SITE, APPLICATION OR IN CONNECTION WITH
                  THE SERVICE TO CONSULT LEGAL, TAX, AND FINANCIAL PROFESSIONALS
                  BEFOREHAND, CAREFULLY REVIEW ALL THE SPECIFIC RISK DISCLOSURES
                  PROVIDED AS PART OF ANY TRANSACTION MATERIALS, AND REQUEST ANY
                  ADDITIONAL INFORMATION.
                </p>

                <h3>Rules and conduct</h3>

                <p>
                  As a condition of access and use, you promise not to access or
                  use the Site, Application and the Service for any purpose that
                  is prohibited by the Terms of Use. The Site, Application and
                  the Service (including, without limitation, any associated
                  Content (as defined below)) is provided only for your own
                  personal, non-commercial use, unless commercial use has been
                  explicitly approved by Modern Share. You are responsible for
                  all of your activity in connection with the Site, Application
                  and the Service. For purposes of the Terms of Use, the term
                  “Content” includes, without limitation, any videos, audio
                  clips, written forum comments, barcodes, hyperlinks,
                  information, data, text, photographs, software, scripts,
                  graphics, and interactive features generated, provided, or
                  otherwise made accessible by Modern Share or its partners on
                  or through the Site, Application or the Service.
                </p>

                <p>
                  By way of example, and not as a limitation, you shall not (and
                  shall not permit any third party to) either (a) take any
                  action or (b) upload, download, post, submit or otherwise
                  distribute or facilitate distribution of any Content on or
                  through the Site, Application or the Service that:
                </p>
                <ul>
                  <li>
                    infringes any patent, trademark, trade secret, copyright,
                    right of publicity or other right of any other person or
                    entity or violates any law or contractual duty;
                  </li>
                  <li>
                    you know is false, misleading, untruthful or inaccurate;
                  </li>
                  <li>
                    is unlawful, threatening, abusive, harassing, defamatory,
                    libelous, deceptive, fraudulent, invasive of another’s
                    privacy, tortious, obscene, vulgar, pornographic, offensive,
                    profane, or otherwise inappropriate as determined by Modern
                    Share in its sole discretion;
                  </li>
                  <li>
                    constitutes unauthorized or unsolicited advertising, junk or
                    bulk e-mail (“spamming”);
                  </li>
                  <li>
                    involves commercial activities and/or sales without Modern
                    Share’s prior written consent such as contests, sweepstakes,
                    barter, advertising, or pyramid schemes;
                  </li>
                  <li>
                    contains software viruses or any other computer codes,
                    files, or programs that are designed or intended to disrupt,
                    damage, limit or interfere with the proper function of any
                    software, hardware, network or telecommunications equipment
                    or to damage or obtain unauthorized access to any system,
                    data, password or other information of Modern Share or any
                    third party;
                  </li>
                  <li>
                    impersonates any person or entity, including any employee or
                    representative of Modern Share; or
                  </li>
                  <li>
                    sends altered, deceptive or false source-identifying
                    information, including “spoofing” or “phishing”.
                  </li>
                </ul>
                <p>Additionally, you shall not (directly or indirectly):</p>
                <ul>
                  <li>
                    take any action that imposes or may impose (as determined by
                    Modern Share in its sole discretion) an unreasonable or
                    disproportionately large load on Modern Share’s (or its
                    third party providers’) infrastructure;
                  </li>
                  <li>
                    interfere or attempt to interfere with the proper working of
                    the Site, Application or the Service or any activities
                    conducted on the Site or Application;
                  </li>
                  <li>
                    bypass any measures Modern Share may use to prevent or
                    restrict access to the Site or Application (or other
                    accounts, computer systems or networks connected to the Site
                    or Application);
                  </li>
                  <li>
                    run any form of auto-responder or “spam” on the Service;
                  </li>
                  <li>
                    use manual or automated software, devices, or other
                    processes to “crawl” or “spider” any component of the Site
                    or Application;
                  </li>
                  <li>
                    decipher, decompile, disassemble, reverse engineer or
                    otherwise attempt to derive any source code or underlying
                    ideas or algorithms of any part of the Site, Application or
                    the Service, except to the limited extent applicable laws
                    specifically prohibit such restriction;
                  </li>
                  <li>
                    modify, translate, or otherwise create derivative works of
                    any part of the Site, Application or the Service, or
                  </li>
                  <li>
                    copy, rent, lease, distribute, or otherwise transfer any of
                    the rights that you receive hereunder.
                  </li>
                  <li>
                    hack the Site, Application, the Service, or any part
                    thereof; or
                  </li>
                  <li>
                    intercept, capture, emulate, or redirect the communications
                    protocols used by us for any purpose, including without
                    limitation causing the Site, Application or Service to
                    connect to any computer server or other device not
                    authorized by us.
                  </li>
                </ul>

                <p>
                  You shall abide by all applicable local, state, national and
                  international laws and regulations in your use of the
                  Services.
                </p>

                <p>
                  To the extent that you have agreed to a Non-Disclosure
                  Agreement between you and Modern Share or to otherwise keep
                  any information, data, documents, agreement, files or other
                  materials confidential, you are subject to the terms of any
                  such agreement, and are prohibited from uploading, posting,
                  submitting or otherwise distributing or facilitating
                  distribution of any such confidential material on or through
                  the Site, Application, any third party social media sites, or
                  any websites and applications.
                </p>

                <p>
                  Modern Share does not guarantee that any Content (as defined
                  below) will be made available on the Site, Application or
                  through the Service. Modern Share has no obligation to monitor
                  the Site, Application, Service or Content. However, Modern
                  Share reserves the right to: (a) remove, edit or modify any
                  Content in its sole discretion from the Site, Application or
                  Service at any time, without notice to you and for any reason
                  (including, but not limited to, upon receipt of claims or
                  allegations from third parties or authorities relating to such
                  Content or if Modern Share is concerned that you may have
                  violated the Terms of Use), or for no reason at all; and/or
                  (b) suspend or terminate the right to use the Site,
                  Application and/or the Services pursuant to this Agreement.
                </p>

                <h3>Third party sites and content</h3>

                <p>
                  The Site and the Application may permit you to link to other
                  websites, services or resources on the Internet, and other
                  websites, services or resources may contain links to the Site
                  and the Application. These other websites, services and
                  resources (“Third Party Sites”) are not under Modern Share’s
                  control, and you acknowledge that Modern Share is not
                  responsible or liable for any content, information, data,
                  advertising, products, goods or services available on or
                  through any such Third Party Sites (“Third Party Content”), as
                  well as for any of the functions, accuracy, legality,
                  appropriateness or any other aspect of such Third Party Sites.
                  The inclusion of any such link does not imply endorsement by
                  Modern Share or any association with its operators. You
                  further acknowledge and agree that Modern Share shall not be
                  responsible or liable, directly or indirectly, for any damage
                  or loss caused or alleged to be caused by or in connection
                  with the use of or reliance on any such Third Party Sites or
                  Third Party Content.
                </p>

                <p>
                  You agree that your access and/or use of Third Party Sites,
                  including but not limited Third Party Content and any other
                  materials on or available through Third Party Sites is at your
                  own risk and subject to the terms and conditions of use and
                  privacy policies applicable to such Third Party Sites.
                </p>

                <h3>Content and license</h3>

                <p>
                  You acknowledge and agree that any and all intellectual
                  property rights (the “IP Rights”) on the Site, in the Service
                  and in the Application are and shall remain the exclusive
                  property of Modern Share. Nothing in this Agreement intends to
                  or shall transfer any IP Rights to, or to vest any IP Rights
                  in, you. You are only entitled to the limited use of the
                  rights granted to you in this Agreement. You will not take any
                  action to jeopardize, limit or interfere with the IP Rights.
                  You acknowledge and agree that any unauthorized use of the IP
                  Rights is a violation of this Agreement, as well as a
                  violation of applicable intellectual property laws.
                </p>

                <p>
                  You acknowledge and understand that all title and rights in
                  and to any Third Party Content that is not contained on the
                  Site, in the Service, or in the Application, but may be
                  accessed through the Site, Service, or the Application is the
                  property of the respective content owners and may be protected
                  by applicable patent, copyright, or other intellectual
                  property laws and treaties. You agree not to sell, assign,
                  rent, lease, distribute, export, import, act as an
                  intermediary or provider, or otherwise grant rights to third
                  parties with regard to the Site, Application or Service or any
                  part thereof without our prior written consent.
                </p>

                <p>
                  You grant to us a nonexclusive, perpetual, non-revocable,
                  royalty free license to use, retain, and share any information
                  transmitted through the Site and/or the Application by you,
                  including but not limited to account numbers, name, date,
                  account amount, and endorsements for the purpose of providing
                  the Service. This license shall survive termination of this
                  Agreement for such period as necessary for us to provide the
                  Service, comply with the law, or comply with an internal
                  guidelines or procedures.
                </p>

                <p>
                  Modern Share grants each user of the Site, Application and/or
                  Service a worldwide, non-exclusive, non-sublicensable and
                  non-transferable limited license to use and reproduce the
                  Content, solely for personal, non-commercial use, unless
                  commercial use has been explicitly approved by Modern Share.
                  Use, reproduction, modification, distribution or storage of
                  any Content for other than personal, non-commercial use is
                  expressly prohibited without prior written permission from
                  Modern Share, or from the copyright holder identified in such
                  Content’s copyright notice. You shall not sell, license, rent,
                  or otherwise use or exploit any Content for commercial use or
                  in any way that violates any third-party right.
                </p>

                <h3>Your information</h3>

                <p>
                  The collection, use and disclosure, if any, of information
                  collected from you or about you by Modern Share on the Site
                  and/or the Application is detailed in Modern Share’s Privacy
                  Policy that can be found{" "}
                  <Link to="/privacy-policy">here</Link>. By acknowledging and
                  agreeing to this Agreement, or by using the Site, Application
                  or the Services, you consent to Modern Share, including its
                  subsidiaries, affiliates, agents, third-party partners and
                  service providers, receiving, collecting, storing, processing,
                  transmitting, and using your information for Services
                  functionality and for the purposes disclosed in Modern Share’s
                  Privacy Policy.
                </p>

                <h3>If you download and use the Modern Share Application</h3>

                <p>
                  If you download and use the Modern Share Application, the
                  following additional terms and conditions also apply to you:
                </p>

                <h4>
                  Acceptance of Additional Terms; Incorporation of Additional
                  Related Terms
                </h4>

                <p>
                  Your download and/or use of the Application is subject to
                  acceptance without modification of these additional terms and
                  conditions, which are incorporated into the Terms of Use by
                  this reference.
                </p>

                <p>
                  When or if you download the Application on a mobile device
                  owned or controlled by you (“Device”), the Terms of Use also
                  incorporate and supplement each of the following: 1. if the
                  Application is downloaded, installed, accessed or used on an
                  Apple OS Device, then Apple, Inc. (“Apple”) Terms and
                  Conditions, available{" "}
                  <a href="https://www.apple.com/legal/internet-services/itunes/us/terms.html#service">
                    here
                  </a>
                  , including without limitation the Licensed Application End
                  User License Agreement therein; and 2. if the Application is
                  downloaded, installed, accessed or used on an Android OS
                  Device, then the Terms of Service for Google Play provided by
                  Google LLC (Google), available{" "}
                  <a href="https://play.google.com/intl/ALL_us/about/play-terms/index.html">
                    here
                  </a>{" "}
                  (collectively, “OS Terms”).
                </p>

                <p>
                  If you do not acknowledge or agree with these additional terms
                  and conditions applicable to your use of the Application, do
                  not download, install, or use the Application and, if already
                  downloaded, immediately uninstall the Application and
                  discontinue the use of the Services. Continued use of the
                  Services on the Application signifies your continued
                  acceptance of these additional terms and any changes to them.
                </p>

                <h4>Additional Content and License Provisions</h4>

                <p>
                  The Application, Services and the associated software products
                  made available to you by Modern Share are licensed, not sold,
                  to you. Subject to these Terms, Modern Share grants you a
                  personal, worldwide, perpetual, royalty-free, non-exclusive,
                  non-transferable, non-sublicensable limited and revocable
                  license to use the Application on Device as permitted by the
                  usage rules contained in the relevant OS Terms and in
                  accordance with this Agreement. Any use of the Application or
                  the Service in any other manner, including, without
                  limitation, resale, transfer, modification or distribution of
                  the Content is prohibited. This Agreement also governs any
                  updates to, or supplements or replacements for, the
                  Application unless separate terms accompany such updates,
                  supplements or replacements, in which case the separate terms
                  will apply. The Application may update automatically on your
                  Device once a new version or feature becomes available.
                </p>

                <p>
                  To the extent not mentioned above, and without waiving any of
                  the foregoing, your grant to us a non-exclusive, perpetual,
                  non-revocable, royalty free license to use, retain, and share
                  information transmitted through the Application by you for the
                  purpose of providing the Service including any such
                  information that is transmitted through a Device, including
                  but not limited the Device camera.
                </p>

                <h4>Additional Rules and Conduct</h4>

                <p>
                  You are solely responsible for the confidentiality and
                  security of any information sent from or stored on the Device
                  by the Application. Modern Share shall not be responsible for
                  any losses arising from the financial loss or theft of your
                  information due to unauthorized or fraudulent transactions
                  using your account or Device. Users shall be solely
                  responsible for taking precautionary steps to protect any
                  information stored on the Device, including without limitation
                  password-protecting the Device and employing remote-wipe
                  feature if available.
                </p>

                <h4>Push Notifications</h4>

                <p>
                  When or if you download the Application on a Device, and if
                  you are a registered user of the Application, we may send you
                  push notifications to your Device as a way to deliver
                  information, offers, alerts, updates, reminders, news, and
                  other relevant notifications pertaining to Services, including
                  messages, sounds, and hyperlinks to pages within the
                  Application, or hyperlinks to pages on the Site.
                </p>

                <p>
                  A “push notification” is a message that is different from a
                  text or Short Message Service (“SMS”) message in that it is
                  displayed outside of the Application’s normal user interface.
                  The message will appear onscreen without you having to tap the
                  Modern Share Application icon. Modern Share may deliver push
                  notifications on Devices, whether or not you are currently
                  logged in to and/or using the Application, and whether or not
                  your Device is in locked and/or sleep mode.
                </p>

                <p>
                  Push notifications may be controlled from within the
                  Application’s settings and at the Device’s operating system
                  “OS” level at any time. Some Device OS may allow Modern Share
                  to send push notifications only after you grant permission for
                  Modern Share to do so when you first download the Application.
                  Other Device OS will automatically opt you in to receive the
                  push notifications when you download the Application. In any
                  case, you can manage your push notification preferences, and
                  choose whether Modern Share has permission to send push
                  notifications, by managing your preferences in the Application
                  or at the OS level settings of your Device, and you can set
                  and reset your push notifications permissions as often as you
                  wish. If you choose not to receive push notifications, then
                  you will still receive certain notifications and
                  communications related to Services via the email address
                  associated with the account through which you access the
                  Modern Share platform, or through which investment
                  opportunities are accessed, or transactions are conducted, on
                  your behalf, including Communications, as defined above. For
                  avoidance of doubt, choosing to opt out of push notifications
                  does not serve as an indication of your withdrawal of consent
                  to receiving Communications in Electronic Form.
                </p>

                <h3>Changes to service</h3>

                <p>
                  We reserve the right to add or delete features or functions,
                  or to provide programming fixes, updates and upgrades, to the
                  Site, Application or Service. You acknowledge and agree that
                  we have no obligation to make available to you any subsequent
                  versions of the Site or the Application. You also agree that
                  you may have to enter into a renewed version of this Agreement
                  if you want to download, install or use a new version of the
                  Site, Application, or Service. We have no obligation
                  whatsoever to furnish any maintenance and support services
                  with respect to the Site, Application, or Service, and any
                  such maintenance and support services provided will be
                  provided at our discretion.
                </p>

                <h3>Termination</h3>

                <p>
                  Modern Share may terminate your access to all or any part of
                  the Site, Application or the Service at any time, with or
                  without cause, with or without notice, effective immediately,
                  which may result in the forfeiture and destruction of all
                  information associated with your membership. If you wish to
                  terminate your account, you may do so by following the
                  instructions on the Site and Application. Any fees paid
                  hereunder are non-refundable. All provisions of the Terms of
                  Use which by their nature should survive termination shall
                  survive termination, including, without limitation, ownership
                  provisions, warranty disclaimers, indemnity and limitations of
                  liability.
                </p>

                <h3>Warranty disclaimer</h3>

                <p>
                  By virtue of your accessing the Service, Modern Share has no
                  special relationship or fiduciary duty with you. Any
                  relationship with Modern Share’s affiliates will be governed
                  by your written agreement with that affiliate. You acknowledge
                  that Modern Share has no control over, and no duty to take any
                  action regarding: which users gains access to the Site and
                  Application; what Content you access via the Site and
                  Application; what effects the Content may have on you; how you
                  may interpret or use the Content; or what actions you may take
                  as a result of having been exposed to the Content. You release
                  Modern Share from all liability for you having acquired or not
                  acquired Content through the Site and Application. The Site
                  and Application may contain, or direct you to websites
                  containing, information that some people may find offensive or
                  inappropriate. Modern Share makes no representations
                  concerning any Content contained in or accessed through the
                  Site and Application, and Modern Share will not be responsible
                  or liable for the accuracy, copyright compliance, legality or
                  decency of material contained in or accessed through the Site,
                  Application or the Service.
                </p>

                <p>
                  THE SITE, APPLICATION AND THE SERVICE ARE PROVIDED “AS IS” AND
                  “AS AVAILABLE” AND IS WITHOUT WARRANTY OF ANY KIND, EXPRESS OR
                  IMPLIED, INCLUDING, BUT NOT LIMITED TO, THE IMPLIED WARRANTIES
                  OF TITLE, NON-INFRINGEMENT, MERCHANTABILITY AND FITNESS FOR A
                  PARTICULAR PURPOSE, AND ANY WARRANTIES IMPLIED BY ANY COURSE
                  OF PERFORMANCE OR USAGE OF TRADE, ALL OF WHICH ARE EXPRESSLY
                  DISCLAIMED. MODERN SHARE, AND ITS DIRECTORS, EMPLOYEES,
                  AGENTS, SUPPLIERS, PARTNERS AND CONTENT PROVIDERS DO NOT
                  WARRANT THAT: (A) THE SITE, APPLICATION OR THE SERVICE WILL BE
                  SECURE OR AVAILABLE AT ANY PARTICULAR TIME OR LOCATION; (B)
                  ANY DEFECTS OR ERRORS WILL BE CORRECTED; (C) ANY CONTENT OR
                  SOFTWARE AVAILABLE AT OR THROUGH THE SITE, APPLICATION OR THE
                  SERVICE IS FREE OF VIRUSES OR OTHER HARMFUL COMPONENTS; OR (D)
                  THE RESULTS OF USING THE SITE, APPLICATION OR THE SERVICE WILL
                  MEET YOUR REQUIREMENTS. YOUR USE OF THE SERVICE IS SOLELY AT
                  YOUR OWN RISK.
                </p>

                <p>
                  SOME STATES DO NOT ALLOW LIMITATIONS ON HOW LONG AN IMPLIED
                  WARRANTY LASTS, SO THE ABOVE LIMITATIONS MAY NOT APPLY TO YOU.
                </p>

                <p>
                  Electronic Communications Privacy Act Notice (18USC
                  2701-2711): MODERN SHARE MAKES NO GUARANTY OF CONFIDENTIALITY
                  OR PRIVACY OF ANY COMMUNICATION OR INFORMATION TRANSMITTED ON
                  THE SITE, APPLICATION OR ANY WEBSITE LINKED TO THE SITE OR
                  APPLICATION. Modern Share will not be liable for the privacy
                  of email addresses, registration and identification
                  information, disk space, communications, confidential or
                  trade-secret information, or any other Content stored on
                  Modern Share’s equipment, transmitted over networks accessed
                  by the Site or Application, or otherwise connected with your
                  use of the Service.
                </p>

                <h3>Indemnification</h3>

                <p>
                  You shall defend, indemnify, and hold harmless Modern Share,
                  its affiliates and each of its, and its affiliates employees,
                  contractors, directors, suppliers and representatives from all
                  liabilities, claims, and expenses, including reasonable
                  attorneys’ fees, that arise from or relate to your use or
                  misuse of, or access to, the Site, Application, Service,
                  Content, violation of the Terms of Use, or infringement by
                  you, or any third party using your account, of any
                  intellectual property or other right of any person or entity.
                  Modern Share reserves the right to assume the exclusive
                  defense and control of any matter otherwise subject to
                  indemnification by you, in which event you will assist and
                  cooperate with Modern Share in asserting any available
                  defenses.
                </p>

                <h3>Limitation of liability</h3>

                <p>
                  IN NO EVENT SHALL MODERN SHARE, OR ANY OF ITS DIRECTORS,
                  EMPLOYEES, AGENTS, PARTNERS, SUPPLIERS OR CONTENT PROVIDERS,
                  BE LIABLE UNDER CONTRACT, TORT, STRICT LIABILITY, NEGLIGENCE
                  OR ANY OTHER LEGAL OR EQUITABLE THEORY WITH RESPECT TO THE
                  SITE, APPLICATION OR THE SERVICE (I) FOR ANY LOST PROFITS,
                  DATA LOSS, COST OF PROCUREMENT OF SUBSTITUTE GOODS OR
                  SERVICES, OR SPECIAL, INDIRECT, INCIDENTAL, PUNITIVE,
                  EXEMPLARY OR CONSEQUENTIAL DAMAGES OF ANY KIND WHATSOEVER,
                  SUBSTITUTE GOODS OR SERVICES (HOWEVER ARISING), (II) FOR ANY
                  BUGS, VIRUSES, TROJAN HORSES, OR THE LIKE (REGARDLESS OF THE
                  SOURCE OF ORIGINATION), (III) FOR ANY DIRECT DAMAGES IN EXCESS
                  OF (IN THE AGGREGATE) ONE-HUNDRED U.S. DOLLARS ($100.00). SOME
                  STATES DO NOT ALLOW THE EXCLUSION OR LIMITATION OF INCIDENTAL
                  OR CONSEQUENTIAL DAMAGES, SO THE ABOVE LIMITATIONS AND
                  EXCLUSIONS MAY NOT APPLY TO YOU. IF APPLICABLE LAW DOES NOT
                  ALLOW ALL OR ANY PART OF THE ABOVE LIMITATION OF LIABILITY TO
                  APPLY TO YOU, THE LIMITATIONS WILL APPLY TO YOU ONLY TO THE
                  EXTENT PERMITTED BY APPLICABLE LAW.
                </p>

                <p>
                  MODERN SHARE WILL NOT BE RESPONSIBLE FOR ANY DAMAGES, LOSSES
                  OR LIABILITY TO YOU OR ANYONE ELSE IF ANY EVENT LEADING TO
                  SUCH DAMAGES, LOSSES OR LIABILITY WOULD HAVE BEEN PREVENTED BY
                  THE USE OF THEN AVAILABLE SECURITY FEATURES AND/OR PROCEDURES
                  MAINTAINED BY MODERN SHARE TO CONFIRM THAT ONLY AUTHORIZED
                  USERS HAVE ACCESS TO THE SERVICE AND/OR ANY ACCOUNT.
                  ADDITIONALLY, YOU ARE RESPONSIBLE FOR ALL LOGIN CREDENTIALS,
                  INCLUDING USERNAMES AND PASSWORDS. WE WILL NOT BE RESPONSIBLE
                  FOR ANY DAMAGES, LOSSES OR LIABILITY TO YOU, OR ANYONE ELSE,
                  IF SUCH INFORMATION IS NOT KEPT CONFIDENTIAL BY YOU, OR IF
                  SUCH INFORMATION IS CORRECTLY PROVIDED BY AN UNAUTHORIZED
                  THIRD-PARTY LOGGING INTO AND ACCESSING THE SITE, APPLICATION
                  OR THE SERVICE.
                </p>

                <h3>Dispute resolution</h3>

                <p>
                  Most concerns can be resolved by contacting Modern Share at{" "}
                  <a href="mailto:support@modernshare.com">
                    support@modernshare.com
                  </a>
                  . We both agree to use best efforts to settle any dispute,
                  claim, question, or disagreement directly through consultation
                  with Modern Share, and good faith negotiations shall be a
                  condition to either of us initiating any other process or
                  proceeding.
                </p>
                <p>
                  A printed version of the Terms of Use and of any notice given
                  in electronic form shall be admissible in judicial or
                  administrative proceedings based upon or relating to the Terms
                  of Use to the same extent and subject to the same conditions
                  as other business documents and records originally generated
                  and maintained in printed form. You and Modern Share agree
                  that any cause of action arising out of or related to the
                  Service must commence within one (1) year after the cause of
                  action arose; otherwise, such cause of action is permanently
                  barred.
                </p>
                <p>
                  <strong>Choice of Law</strong>. The Terms of Use shall be
                  governed by and construed in accordance with the laws of the
                  State of California, excluding its conflicts of law rules, and
                  the United States of America.
                </p>
                <p>
                  <strong>Arbitration</strong>. The parties acknowledge that
                  this Agreement evidences a transaction involving interstate
                  commerce. Notwithstanding the provision with respect to choice
                  of law, any arbitration conducted pursuant to the terms of
                  this Agreement shall be governed by the Federal Arbitration
                  Act.
                </p>
                <p>
                  To the fullest extent permissible by law, except as provided
                  under certain limited circumstances below, any dispute,
                  controversy or claim arising from or relating to the subject
                  matter of this Agreement, including whether the claims
                  asserted are arbitrable, shall be finally determined and
                  settled by arbitration in San Francisco, California. The
                  arbitration shall be administered by JAMS, using the English
                  language, in accordance with Streamlined Arbitration Rules and
                  Procedures of JAMS then in effect, by one commercial
                  arbitrator with substantial experience in resolving
                  intellectual property, internet and commercial contract
                  disputes, who shall be selected from the appropriate list of
                  JAMS arbitrators in accordance with such Rules. JAMS may be
                  contacted at http://www.jamsadr.com/.
                </p>
                <p>
                  The prevailing party in any arbitration or other proceeding
                  arising under these Terms of Use shall be entitled to receive
                  reimbursement of its reasonable expenses (including reasonable
                  attorneys’ fees, expert witness fees and all other expenses)
                  incurred in connection therewith.
                </p>
                <p>
                  Judgment upon the award so rendered may be entered in a court
                  having jurisdiction or application may be made to such court
                  for judicial acceptance of any award and an order of
                  enforcement, as the case may be.
                </p>
                <p>
                  <strong>Exceptions</strong>. Notwithstanding the foregoing,
                  each party shall have the right to (a) institute an action in
                  a court of proper jurisdiction for injunctive or other
                  equitable relief; (b) bring an individual action in small
                  claims court; (c) pursue an enforcement action through the
                  applicable federal, state, or local agency if that action is
                  available; and (d) file suit in a court of law to address an
                  intellectual property infringement claim.
                </p>
                <p>
                  <strong>Enforceability and Jurisdiction</strong>. Without
                  waiving any of the foregoing, if any asserted claims are
                  deemed not arbitrable, the parties consent to exclusive
                  jurisdiction and venue in the state and federal courts located
                  in the City and County of San Francisco.
                </p>

                <h3>Integration and severability</h3>
                <p>
                  The Terms of Use are the entire agreement between you and
                  Modern Share with respect to the Site, Application or the
                  Service and use of the Site and Application, and supersede all
                  prior or contemporaneous communications and proposals (whether
                  oral, written or electronic) between you and Modern Share with
                  respect to the Site and Application. If any provision of the
                  Terms of Use is found to be unenforceable or invalid, that
                  provision will be limited or eliminated to the minimum extent
                  necessary so that the Terms of Use will otherwise remain in
                  full force and effect and enforceable. The failure of either
                  party to exercise in any respect any right provided for herein
                  shall not be deemed a waiver of any further rights hereunder.
                </p>

                <h3>Miscellaneous</h3>
                <p>
                  Modern Share shall not be liable for any failure to perform
                  its obligations hereunder where such failure results from any
                  cause beyond Modern Share’s reasonable control, including,
                  without limitation, mechanical, electronic or communications
                  failure or degradation (including “line-noise” interference).
                  The Terms of Use are personal to you, and are not assignable,
                  transferable or sublicensable by you except with Modern
                  Share’s prior written consent. Modern Share may assign,
                  transfer or delegate any of its rights and obligations
                  hereunder without consent. No agency, partnership, joint
                  venture, or employment relationship is created as a result of
                  the Terms of Use and neither party has any authority of any
                  kind to bind the other in any respect. All notices under the
                  Terms of Use will be in writing and will be deemed to have
                  been duly given when received, if personally delivered or sent
                  by certified or registered mail, return receipt requested;
                  when receipt is electronically confirmed by Modern Share, if
                  transmitted to Modern Share by facsimile or e-mail or upon
                  receipt if transmitted to you; or the day after it is sent, if
                  sent for next day delivery by recognized overnight delivery
                  service.
                </p>

                <h3>Electronic fund transfers</h3>
                <h4>Electronic Fund Transfer Disclosure Statement</h4>
                <p>
                  The following disclosures are made in accordance with the
                  federal law regarding electronic payments, deposits, transfers
                  of funds and other electronic transfers to and from your
                  account(s). There may be limitations on account activity that
                  restrict your ability to make electronic fund transfers. Any
                  such limits are disclosed in the appropriate agreements
                  governing your account.
                </p>
                <h4>Definitions</h4>
                <p>
                  Electronic Fund Transfer: Any transfer of funds, other than a
                  transaction originated by check, draft or similar paper
                  instrument, that is initiated through an electronic device or
                  computer to instruct us to debit or credit an account.
                  Electronic Fund Transfers include such electronic transactions
                  as direct deposits or withdrawals of funds, transfers
                  initiated via telephone, website or mobile application.
                </p>
                <h4>Your Liability: Authorized Transfers</h4>
                <p>
                  You are liable for all Electronic Fund Transfers that you
                  authorize, whether directly or indirectly.
                </p>
                <h4>Your Liability: Unauthorized Transfers</h4>
                <p>
                  Tell us at once if you believe your account or access
                  information is lost or stolen or has been or may be subject to
                  unauthorized Electronic Fund Transfers. Contact us immediately
                  to keep your possible losses to a minimum. You could lose all
                  the money in your account(s). If you tell us within two (2)
                  business days after learning of the loss or theft of your
                  account access device, or after learning of any other
                  unauthorized transfers from your account involving your
                  account access device, you can lose no more than $50 if
                  Electronic Fund Transfers are made without your permission.
                  For these transactions, if you DO NOT tell us within two (2)
                  business days after learning of the loss, theft or
                  unauthorized use, and we can establish that we could have
                  prevented the unauthorized transfer(s) if you had told us in
                  time, you could lose as much as $500. Also, if your periodic
                  account statement shows unauthorized transfers and you DO NOT
                  tell us within sixty (60) days after the statement was
                  delivered to you, you may not get back any money you lose
                  after the sixty (60) day period if we can prove that we could
                  have prevented the unauthorized transfer(s) if you had told us
                  in time. If an extenuating circumstance (such as extended
                  travel or hospitalization) prevents you from promptly
                  notifying us of a suspected lost or stolen access device or of
                  any other suspected unauthorized transfer(s), the time periods
                  specified in this section may be extended for a reasonable
                  period.
                </p>

                <h3>Changes</h3>
                <p>
                  Modern Share reserves the right and sole discretion to modify
                  these Terms of Use, the Privacy Policy, and any notices,
                  rules, policies, and procedures that may be published on the
                  Site, from time to time, and any such modifications become
                  effective immediately upon your opportunity to view them after
                  a modified version is posted to the Site. It is your sole
                  responsibility to check the Site to view any such changes. If
                  you do not agree to all of the changes, you must cease use of
                  the Site; any usage by you of the Services after any
                  modifications are made indicates acceptance of the modified
                  Terms or other provisions. Notwithstanding and without
                  limiting the foregoing, Modern Share may provide a notice to
                  you of any such revision, and require you to indicate
                  acceptance of any new version in order to continue to use the
                  Site, Application and/or the Service.
                </p>

                <h3>Contact</h3>
                <p>
                  If you have any questions, please email us at{" "}
                  <a href="mailto:support@modernshare.com">
                    support@modernshare.com
                  </a>
                  .
                </p>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
}

export default TermsConditions;
