import { useField } from "formik";
import React from "react";

import currencies from "./currencies.json";

function CurrencySelect({ name, ...props }) {
  const [field, _meta] = useField(name);
  return (
    <select
      name={name}
      value={field.value}
      className={"form-select " + props.className}
      onChange={field.onChange}
      onBlur={field.onBlur}
    >
      <option value=""></option>
      {currencies.map((item) => {
        return (
          <option key={item.cc} value={item.cc}>
            {item.name}
          </option>
        );
      })}
    </select>
  );
}

export default CurrencySelect;
