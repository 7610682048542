import FundraiseOfferSlider from "jsx/element/fundraise-offer-slider";
import Header4 from "jsx/layout/header4";

function FundraiseOffer() {
  return (
    <>
      <Header4 hideAllMenuItems title="Offer" hideBackButton />
      <div className="content-body section-padding">
        <div className="container">
          <div className="row justify-content-center align-items-center">
            <div className="col-8">
              <div className="card outlined">
                <div className="card-header" style={{ display: "block" }}>
                  <h2>Request additional allocation</h2>
                  <p>
                    Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed
                    do eiusmod tempor incididunt ut labore et dolore magna
                    aliqua.
                  </p>
                </div>

                <FundraiseOfferSlider />
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
}

export default FundraiseOffer;
