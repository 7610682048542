import "react-dates/initialize";
import "react-dates/lib/css/_datepicker.css";

import {
  addDoc,
  collection,
  getDocs,
  getFirestore,
  serverTimestamp,
} from "@firebase/firestore";
import {
  duotone,
  regular,
} from "@fortawesome/fontawesome-svg-core/import.macro";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import Table from "@mui/material/Table";
import TableBody from "@mui/material/TableBody";
import TableCell from "@mui/material/TableCell";
import TableContainer from "@mui/material/TableContainer";
import TableHead from "@mui/material/TableHead";
import TableRow from "@mui/material/TableRow";
import { UserContext } from "jsx/contexts/user-context";
import MSButton from "jsx/element/button";
import EmptyState from "jsx/element/empty-state";
import SettingsNav from "jsx/element/fundraise-nav";
import Header4 from "jsx/layout/header4";
import React, { useEffect, useState } from "react";
import { Link } from "react-router-dom";

function CompanyFundraiseManageCreate() {
  const { selectedOrganization } = React.useContext(UserContext);
  const [rounds, setRounds] = useState([]);

  const db = getFirestore();

  useEffect(() => {
    fetchRounds();
  }, [selectedOrganization]);

  async function debugCreate() {
    const values = {
      roundType: "Seed",
      instrument: "SAFE",
      cap: 250000000,
      discount: 0,
      proRata: true,
      mfn: false,
      size: 5000000,
      safeType: "post-money",
      createdDate: serverTimestamp(),
    };

    try {
      await addDoc(
        collection(
          db,
          "organizations",
          selectedOrganization,
          "organizations_private",
          "data",
          "rounds"
        ),
        values
      );
      await fetchRounds();
    } catch (e) {
      console.log("error", e);
    }
  }

  async function fetchRounds() {
    if (!selectedOrganization) {
      return;
    }
    const querySnapshot = await getDocs(
      collection(
        db,
        `organizations/${selectedOrganization}/organizations_private/data/rounds`
      )
    );

    setRounds(querySnapshot.docs);
  }

  const emptyState = (
    <EmptyState
      icon={duotone("money-check-dollar")}
      title={"No fundraising rounds to display"}
      subtitle={"Fundraising rounds will appear here when you create one"}
    />
  );

  return (
    <>
      <Header4 />

      <div className="content-body section-padding">
        <div className="container">
          <div className="d-flex">
            <div className="me-4" style={{ minWidth: "220px" }}>
              <SettingsNav />
            </div>
            <div className="col-xl-10">
              <div className="flex-column">
                <div className="card outlined">
                  <div className="card-header">
                    <h4 className="card-title">Funding rounds</h4>
                    <div>
                      <MSButton
                        size="small"
                        hidden={!window.location.href.includes("localhost")}
                        onClick={debugCreate}
                      >
                        Debug
                      </MSButton>
                      <Link to="/org/fundraise/manage/create">
                        <MSButton
                          size="small"
                          variant="secondary"
                          startIcon={
                            <FontAwesomeIcon icon={regular("plus")} size="xs" />
                          }
                        >
                          Create Fundraising Round
                        </MSButton>
                      </Link>
                    </div>
                  </div>

                  <div className="card-body">
                    {rounds.length > 0 && (
                      <TableContainer>
                        <Table aria-label="simple table">
                          <TableHead>
                            <TableRow>
                              <TableCell>Round</TableCell>
                              <TableCell align="right">Cap</TableCell>
                              <TableCell align="right">Discount</TableCell>
                              <TableCell align="right">MFN</TableCell>
                              <TableCell align="right">Pro Rata</TableCell>
                            </TableRow>
                          </TableHead>
                          <TableBody
                            sx={{
                              "& .MuiTableRow-root": {
                                transition: "background 0.5",
                                color: "red",
                                bottomBorder: "none",
                              },
                              "& .MuiTableRow-root:hover": {
                                background: "var(--background)",
                              },
                            }}
                          >
                            {rounds.map((e) => {
                              const round = e.data();
                              const roundLink = `/org/fundraise/manage/${e.id}`;

                              console.log("secondary", round);
                              return (
                                <TableRow
                                  component={Link}
                                  to={roundLink}
                                  key={round.name}
                                >
                                  <TableCell>{round.roundType}</TableCell>

                                  <TableCell
                                    align="right"
                                    style={{ padding: "24px" }}
                                  >
                                    {round.valuationCapType === "uncapped"
                                      ? `Uncapped`
                                      : `$${round.cap.toLocaleString()}`}
                                  </TableCell>
                                  <TableCell align="right">
                                    <span>{round.discount}</span>
                                  </TableCell>
                                  <TableCell align="right">
                                    <span>{round.mfn ? "Yes" : "No"}</span>
                                  </TableCell>
                                  <TableCell align="right">
                                    <span>{round.proRata ? "Yes" : "No"}</span>
                                  </TableCell>
                                </TableRow>
                              );
                            })}
                          </TableBody>
                        </Table>
                      </TableContainer>
                    )}
                  </div>
                  {rounds.length === 0 && emptyState}
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
}

export default CompanyFundraiseManageCreate;
