import { post } from "jsx/api";
import { UserContext } from "jsx/contexts/user-context";
import MSButton from "jsx/element/button";
import React, { useContext, useMemo, useState } from "react";
import { useDropzone } from "react-dropzone";

const baseStyle = {
  flex: 1,
  display: "flex",
  flexDirection: "column",
  alignItems: "center",
  padding: "20px",
  borderWidth: 2,
  borderRadius: 2,
  borderColor: "#eeeeee",
  borderStyle: "dashed",
  backgroundColor: "#fafafa",
  color: "#bdbdbd",
  outline: "none",
  transition: "border .24s ease-in-out",
};

const focusedStyle = {
  borderColor: "#000",
  color: "#000",
};

const acceptStyle = {
  borderColor: "#00e676",
};

const rejectStyle = {
  borderColor: "#ff1744",
};

export default function ImportUpload({ importType, onFinish }) {
  const [isProcessing, setIsProcessing] = useState(false);
  const [errors, setErrors] = useState([]);

  const { selectedOrganization } = useContext(UserContext);
  const importFileType = importType === "stakeholders" ? "(.csv)" : "(.xlsx)";
  const submitForm = (file) => async (event) => {
    setIsProcessing(true);

    const formData = new FormData();
    formData.append("file", file);
    formData.append("importType", importType);
    formData.append("organizationId", selectedOrganization);
    let resp;
    try {
      resp = await post("imports", formData, {
        headers: {
          "Content-Type": "multipart/form-data",
        },
      });
    } catch (err) {
      const validationErrors = err?.response?.data?.errors;
      if (validationErrors) {
        setErrors(validationErrors);
      }
    }

    setIsProcessing(false);
    if (resp?.status === 200) {
      onFinish();
    }
  };

  const importTypeMapping = {
    stakeholders: "stakeholder",
  };

  const {
    acceptedFiles,
    getRootProps,
    getInputProps,
    isFocused,
    isDragAccept,
    isDragReject,
  } = useDropzone({
    onDrop: () => setErrors([]),
    accept: ".csv, .xlsx",
    multiple: false,
    maxFiles: 1,
  });

  const style = useMemo(
    () => ({
      ...baseStyle,
      ...(isFocused ? focusedStyle : {}),
      ...(isDragAccept ? acceptStyle : {}),
      ...(isDragReject ? rejectStyle : {}),
    }),
    [isFocused, isDragAccept, isDragReject]
  );

  const file = acceptedFiles && acceptedFiles[0];

  return (
    <div>
      <h4 style={{ textTransform: "capitalize" }}>
        {importTypeMapping[importType] || importType} import
      </h4>
      {file && errors.length === 0 ? (
        <div>
          {file.path} - {file.size} bytes
        </div>
      ) : (
        <div {...getRootProps({ style })}>
          <input {...getInputProps()} />
          <p className="m-0">
            Drag and drop or tap to upload{" "}
            {importTypeMapping[importType] || importType} table {importFileType}
          </p>
        </div>
      )}
      {errors.length > 0 && (
        <div className="d-flex flex-column">
          <h5>Errors</h5>
          {errors.map((err, i) => (
            <div key={i}>
              {err.param && err.msg ? `${err.param}: ${err.msg}` : err}
            </div>
          ))}
        </div>
      )}
      <div className="d-flex justify-content-end">
        <MSButton
          title="Cancel"
          onClick={onFinish}
          className="me-2 mt-2"
          type="light small"
        />
        <MSButton
          loading={isProcessing}
          disabled={acceptedFiles.length === 0 || errors.length > 0}
          title={isProcessing ? "Processing" : "Done"}
          size="small"
          className="me-2 mt-2"
          onClick={submitForm(file)}
        />
      </div>
    </div>
  );
}
