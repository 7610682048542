import Bottom from "jsx/element/bottom";
import Footer1 from "jsx/layout/footer1";
import Header1 from "jsx/layout/header1";
import React from "react";
import { Link } from "react-router-dom";

function Homepage() {
  return (
    <>
      <Header1 />
      <div className="intro">
        <div className="container">
          <div className="row justify-content-between align-items-center">
            <div className="col-xl-6 col-lg-6 col-12">
              <div className="intro-content">
                <h1>
                  Trade with{" "}
                  <strong className="text-primary">Modern Share</strong>. <br />{" "}
                  Buy and sell private shares.
                </h1>
                <p>
                  Get the best access to the best pre-IPO companies instantly.
                  Sign up below.
                </p>
              </div>

              <div className="intro-btn">
                <Link to={"#"} className="btn me-4">
                  Get Started
                </Link>
                <Link to={"#"} className="btn">
                  Browse Now
                </Link>
              </div>
            </div>
            <div className="col-xl-5 col-lg-6 col-12">
              <div className="card outlined">
                <div className="card-body">
                  <form
                    method="post"
                    name="myform"
                    className="currency_validate"
                  >
                    <div className="mb-3">
                      <label className="me-sm-2">Send</label>
                      <div className="input-group mb-3">
                        <select name="currency" className="form-control">
                          <option data-display="Stripe" value="Stripe">
                            Stripe
                          </option>
                          <option value="Brex">Brex</option>
                        </select>
                        <input
                          type="text"
                          name="usd_amount"
                          className="form-control"
                          value="125.00 USD"
                        />
                      </div>
                    </div>

                    <div className="mb-3">
                      <label className="me-sm-2">Get</label>
                      <div className="input-group mb-3">
                        <select name="currency" className="form-control">
                          <option data-display="Stripe" value="Stripe">
                            Stripe
                          </option>
                          <option value="Brex">Brex</option>
                        </select>
                        <input
                          type="text"
                          name="usd_amount"
                          className="form-control"
                          value="125.00 USD"
                        />
                      </div>
                      <div className="d-flex justify-content-between mt-0 align-items-center">
                        <p className="mb-0">Monthly Limit</p>
                        <h6 className="mb-0">$49750 remaining</h6>
                      </div>
                    </div>
                    <Link
                      to={"./signin"}
                      type="submit"
                      name="submit"
                      className="btn btn-big w-100"
                    >
                      Exchange Now
                      <i className="la la-arrow-right"></i>
                    </Link>
                  </form>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>

      <div className="getstart section-padding">
        <div className="container">
          <div className="row justify-content-center">
            <div className="col-xl-8">
              <div className="section-title">
                <h2>Get started in a few minutes</h2>
              </div>
            </div>
          </div>
          <div className="row">
            <div className="col-xl-4 col-lg-4 col-md-4 col-sm-4">
              <div className="getstart-content">
                <span>
                  <i className="la la-user-plus"></i>
                </span>
                <h3>Create an account</h3>
              </div>
            </div>
            <div className="col-xl-4 col-lg-4 col-md-4 col-sm-4">
              <div className="getstart-content">
                <span>
                  <i className="la la-bank"></i>
                </span>
                <h3>Link your bank account</h3>
              </div>
            </div>
            <div className="col-xl-4 col-lg-4 col-md-4 col-sm-4">
              <div className="getstart-content">
                <span>
                  <i className="la la-exchange"></i>
                </span>
                <h3>Start buying & selling</h3>
              </div>
            </div>
          </div>
        </div>
      </div>

      <Bottom />

      <Footer1 />
    </>
  );
}

export default Homepage;
