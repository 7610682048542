import {
  addDoc,
  collection,
  doc,
  getFirestore,
  serverTimestamp,
  setDoc,
} from "@firebase/firestore";
import { regular } from "@fortawesome/fontawesome-svg-core/import.macro";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { LocalizationProvider } from "@mui/lab";
import { InputAdornment, Skeleton } from "@mui/material";
import ToggleButton from "@mui/material/ToggleButton";
import ToggleButtonGroup from "@mui/material/ToggleButtonGroup";
import { AdapterDateFns } from "@mui/x-date-pickers/AdapterDateFns";
import { DatePicker } from "@mui/x-date-pickers/DatePicker";
import { endOfDay, startOfDay } from "date-fns";
import { Form, Formik } from "formik";
import { UserContext } from "jsx/contexts/user-context";
import MSButton from "jsx/element/button";
import Divider from "jsx/element/divider";
import FormError from "jsx/element/form-error";
import MSField from "jsx/element/ms-field";
import React from "react";
import NumberFormat from "react-number-format";
import { useHistory } from "react-router-dom";
import * as Yup from "yup";

export default function SecondaryForm({ secondary, secondaryId, isUpdate }) {
  const db = getFirestore();

  const history = useHistory();

  const { selectedOrganization } = React.useContext(UserContext);
  const SecondariesSchema = Yup.object().shape({
    roundType: Yup.string().required("Required").typeError("Required"),
    // TODO: Readd orderBookVisibility once it's editable/actionable by user
    // orderBookVisibility: Yup.string()
    //   .required("Required")
    //   .typeError("Required"),
    // TODO: Add proper date validation (endDate after startDate)
    startDate: Yup.date().required("Required").typeError("Required"),
    buyerEndDate: Yup.date().required("Required").typeError("Required"),
    sellerStartDate: Yup.date().required("Required").typeError("Required"),
    endDate: Yup.date().required("Required").typeError("Required"),
    sellerCountLimit: Yup.number().required("Required").typeError("Required"),
    sellablePercentLimit: Yup.number()
      .required("Required")
      .typeError("Required"),
    minPrice: Yup.number().required("Required").typeError("Required"),
    maxPrice: Yup.number().required("Required").typeError("Required"),
    fmv: Yup.number().required("Required").typeError("Required"),

    signingStartDate: Yup.date().required("Required").typeError("Required"),
    signingEndDate: Yup.date().required("Required").typeError("Required"),
  });

  let content;

  if (isUpdate && !secondary) {
    content = (
      <>
        <Skeleton height={50} />
        <Skeleton height={50} />
        <Skeleton height={50} />
        <Skeleton height={50} />
      </>
    );
  } else {
    let initialValues = {
      roundType: "nonTender",
      orderBookVisibility: "private",
      startDate: null,
      buyerEndDate: null,
      sellerStartDate: null,
      endDate: null,
      signingStartDate: null,
      signingEndDate: null,
      fullyDilutedSharesCount: "",
      sellerCountLimit: "12",
      sellablePercentLimit: "20",
      minPrice: null,
      maxPrice: null,
      fmv: null,
    };

    if (isUpdate && secondary) {
      initialValues = secondary;
      initialValues.minPrice =
        secondary.minPriceInBaseUnits / secondary.minPricePrecision;

      initialValues.maxPrice =
        secondary.maxPriceInBaseUnits / secondary.maxPricePrecision;

      initialValues.sellablePercentLimit = secondary.sellableRatioLimit * 100;
      initialValues.fmv = secondary.fmvInBaseUnits / secondary.fmvPrecision;
      initialValues.orderBookVisibility =
        secondary.orderBookVisibility ?? "private";

      for (const field of [
        "startDate",
        "buyerEndDate",
        "sellerStartDate",
        "endDate",
        "signingStartDate",
        "signingEndDate",
      ]) {
        initialValues[field] = secondary[field]?.seconds
          ? new Date(secondary[field].seconds * 1000)
          : secondary[field];
      }
    }
    content = (
      <Formik
        initialValues={initialValues}
        enableReinitialize={true}
        validationSchema={SecondariesSchema}
        onSubmit={async (values) => {
          const { sellablePercentLimit, minPrice, maxPrice } = values;

          // Counts decimals after period, raise 10 to the number of decimals
          // e.g. 12.53 => 2 decimal places => 10^2 => 100
          // Defaults to 1 if there are no decimals (e.g. 5 => 0 decimals => 10^0 => 1)
          const calcPrecision = (price) =>
            10 ** (price.toString().split(".")?.[1]?.length || 0);
          const calcPrice = (price) => {
            if (!price) return [null, 0];
            const precision = calcPrecision(price);
            return [Math.round(price * precision), precision];
          };
          const [fmvInBaseUnits, fmvPrecision] = calcPrice(values.fmv);
          const [minPriceInBaseUnits, minPricePrecision] = calcPrice(minPrice);
          const [maxPriceInBaseUnits, maxPricePrecision] = calcPrice(maxPrice);

          // Turn a percentage into 0-1 unit number (e.g. 10.21% => 0.1021)
          const sellablePercentLimitUnit = Number(sellablePercentLimit) / 100;

          const newSecondary = {
            name: "Tender 2023", // Values are strings
            // name: values.roundType === "nonTender" ? "Non-tender" : "Tender", // Values are strings
            startDate: startOfDay(values.startDate),
            buyerEndDate: endOfDay(values.buyerEndDate),
            sellerStartDate: startOfDay(values.sellerStartDate),
            endDate: endOfDay(values.endDate),
            signingStartDate: startOfDay(values.signingStartDate),
            signingEndDate: endOfDay(values.signingEndDate),
            sellerCountLimit: parseInt(values.sellerCountLimit),
            sellableRatioLimit: sellablePercentLimitUnit,
            fullyDilutedSharesCount: values.fullyDilutedSharesCount,
            roundType: values.roundType,
            minPriceInBaseUnits,
            minPricePrecision,
            maxPriceInBaseUnits,
            maxPricePrecision,
            fmvInBaseUnits,
            fmvPrecision,
            orderBookVisibility: values.orderBookVisibility,

            // VU TODO think about how we should actually set closingDate
            closingDate: endOfDay(values.signingEndDate),
            createdDate: serverTimestamp(),
            updatedDate: serverTimestamp(),
          };

          try {
            if (isUpdate) {
              await setDoc(
                doc(
                  db,
                  "organizations",
                  selectedOrganization,
                  "organizations_private",
                  "data",
                  "secondaries",
                  secondaryId
                ),
                newSecondary,
                { merge: true }
              );
              history.push(`/org/secondaries/manage/${secondaryId}/overview`);
            } else {
              const ref = await addDoc(
                collection(
                  db,
                  "organizations",
                  selectedOrganization,
                  "organizations_private",
                  "data",
                  "secondaries"
                ),
                { ...newSecondary, status: "active.setup" }
              );
              history.push(`/org/secondaries/manage/${ref.id}/overview`);
            }
          } catch (e) {
            console.log("error", e);
          }
        }}
      >
        {(formik) => {
          const { values, isSubmitting, touched, errors, setFieldValue } =
            formik;

          if (errors && Object.keys(errors).length > 0) {
            console.log("form errors", errors);
          }

          return (
            <Form>
              {/* <div className="row col-xl-12 py-5">
                <div className="col-4">
                  <h5 className="mb-2">Round Type</h5>
                  <p className="mb-1" style={{ color: "var(--gray-500)" }}>
                    Will this be a tender or a non-tender round?
                  </p>
                </div>
                <div className="col-8">
                  <ToggleButtonGroup
                    exclusive
                    value={values.roundType}
                    onChange={(event, value) => {
                      setFieldValue("roundType", value);
                    }}
                    sx={{
                      "& .Mui-selected, &.Mui-selected:hover": {
                        backgroundColor: "rgba(100,92,255, 0.1) !important",

                        color: "var(--primary) !important",
                        borderColor: "var(--primary) !important",
                        borderWidth: "2px !important",
                      },
                    }}
                  >
                    <ToggleButton
                      disableRipple
                      className="col-6"
                      value="nonTender"
                      style={{
                        textTransform: "none",
                        fontFamily: "var(--primary-font)",
                        flexDirection: "column",
                        padding: 32,
                      }}
                    >
                      <p style={{ fontSize: 18 }}>Non-Tender</p>
                      <p style={{ fontSize: 14 }}>
                        A standard secondary round with under 12 sellers
                      </p>
                    </ToggleButton>
                    <ToggleButton
                      disableRipple
                      value="tender"
                      className="col-6"
                      style={{
                        textTransform: "none",
                        fontFamily: "var(--primary-font)",
                        flexDirection: "column",
                        padding: 32,
                      }}
                    >
                      <p style={{ fontSize: 18 }}>Tender</p>
                      <p style={{ fontSize: 14 }}>
                        A tender secondary round for more than 12 sellers
                      </p>
                    </ToggleButton>
                  </ToggleButtonGroup>
                  <FormError
                    show={touched["roundType"] && errors["roundType"]}
                    message={errors["roundType"]}
                  />
                </div>
              </div>
              <Divider /> */}

              {/* <div className="row col-xl-12 py-5">
                <div className="col-4">
                  <h5 className="mb-2">Order book visibility</h5>
                  <p className="mb-1" style={{ color: "var(--gray-500)" }}>
                    Will the buyers and sellers be able to see anonymized
                    orders?
                  </p>
                </div>
                <div className="col-8">
                  <ToggleButtonGroup
                    exclusive
                    value={values.orderBookVisibility}
                    onChange={(event, value) => {
                      setFieldValue("orderBookVisibility", value);
                    }}
                    sx={{
                      "& .Mui-selected, &.Mui-selected:hover": {
                        backgroundColor: "rgba(100,92,255, 0.1) !important",

                        color: "var(--primary) !important",
                        borderColor: "var(--primary) !important",
                        borderWidth: "2px !important",
                      },
                    }}
                  >
                    <ToggleButton
                      disableRipple
                      className="col-6"
                      value="private"
                      style={{
                        textTransform: "none",
                        fontFamily: "var(--primary-font)",
                        flexDirection: "column",
                        padding: 32,
                      }}
                    >
                      <FontAwesomeIcon
                        icon={regular("eye-slash")}
                        style={{ margin: 8 }}
                        size="2x"
                      />
                      <p style={{ fontSize: 18 }}>Private</p>
                      <p style={{ fontSize: 14 }}>
                        The order book is private and participants can only see
                        their own order
                      </p>
                    </ToggleButton>
                    <ToggleButton
                      disableRipple
                      value="public"
                      className="col-6"
                      style={{
                        textTransform: "none",
                        fontFamily: "var(--primary-font)",
                        flexDirection: "column",
                        padding: 32,
                      }}
                    >
                      <FontAwesomeIcon
                        icon={regular("eye")}
                        style={{ margin: 8 }}
                        size="2x"
                      />
                      <p style={{ fontSize: 18 }}>Public</p>
                      <p style={{ fontSize: 14 }}>
                        All orders can be anonymously viewed by partipants
                      </p>
                    </ToggleButton>
                  </ToggleButtonGroup>
                  <FormError
                    show={
                      touched["orderBookVisibility"] &&
                      errors["orderBookVisibility"]
                    }
                    message={errors["orderBookVisibility"]}
                  />
                </div>
              </div>
              <Divider /> */}
              <div className="row col-xl-12 py-5">
                <div className="col-4">
                  <h5 className="mb-2">Buying Date Range</h5>
                  <p className="mb-1" style={{ color: "var(--gray-500)" }}>
                    The dates in which buyers can submit buy orders
                  </p>
                </div>
                <div className="col-8">
                  <div className="d-flex" style={{ marginTop: -12 }}>
                    <LocalizationProvider dateAdapter={AdapterDateFns}>
                      <div className="col-6">
                        <DatePicker
                          label="Buying Start Date"
                          value={values.startDate}
                          onChange={(newValue) => {
                            setFieldValue("startDate", new Date(newValue));
                          }}
                          renderInput={(params) => (
                            <MSField
                              name="startDate"
                              formik={formik}
                              {...params}
                            />
                          )}
                        />
                      </div>
                      <p
                        style={{
                          marginTop: 30,
                          padding: 8,
                          color: "var(--gray-500)",
                        }}
                      >
                        to
                      </p>
                      <div className="col-6">
                        <DatePicker
                          label="Buying End Date"
                          value={values.buyerEndDate}
                          onChange={(newValue) => {
                            setFieldValue("buyerEndDate", new Date(newValue));
                          }}
                          renderInput={(params) => (
                            <MSField
                              name="buyerEndDate"
                              formik={formik}
                              {...params}
                            />
                          )}
                        />
                      </div>
                    </LocalizationProvider>
                  </div>
                </div>
              </div>
              <Divider />

              <div className="row col-xl-12 py-5">
                <div className="col-4">
                  <h5 className="mb-2">Selling Date Range</h5>
                  <p className="mb-1" style={{ color: "var(--gray-500)" }}>
                    The dates that sellers can submit sell orders
                  </p>
                </div>
                <div className="col-8">
                  <div className="d-flex" style={{ marginTop: -12 }}>
                    <LocalizationProvider dateAdapter={AdapterDateFns}>
                      <div className="col-6">
                        <DatePicker
                          label="Selling Start Date"
                          value={values.sellerStartDate}
                          onChange={(newValue) => {
                            setFieldValue(
                              "sellerStartDate",
                              new Date(newValue)
                            );
                          }}
                          renderInput={(params) => (
                            <MSField
                              name="sellerStartDate"
                              formik={formik}
                              {...params}
                            />
                          )}
                        />
                      </div>
                      <p
                        style={{
                          marginTop: 30,
                          padding: 8,
                          color: "var(--gray-500)",
                        }}
                      >
                        to
                      </p>
                      <div className="col-6">
                        <DatePicker
                          label="Selling End Date"
                          value={values.endDate}
                          onChange={(newValue) => {
                            setFieldValue("endDate", new Date(newValue));
                          }}
                          renderInput={(params) => (
                            <MSField
                              name="endDate"
                              formik={formik}
                              {...params}
                            />
                          )}
                        />
                      </div>
                    </LocalizationProvider>
                  </div>
                </div>
              </div>
              <Divider />

              <div className="row col-xl-12 py-5">
                <div className="col-4">
                  <h5 className="mb-2">Signing Date Range</h5>
                  <p className="mb-1" style={{ color: "var(--gray-500)" }}>
                    The dates in which buyers and sellers will start signing
                  </p>
                </div>
                <div className="col-8">
                  <div className="d-flex" style={{ marginTop: -12 }}>
                    <LocalizationProvider dateAdapter={AdapterDateFns}>
                      <div className="col-6">
                        <DatePicker
                          label="Signing Start Date"
                          value={values.signingStartDate}
                          onChange={(newValue) => {
                            setFieldValue(
                              "signingStartDate",
                              new Date(newValue)
                            );
                          }}
                          renderInput={(params) => (
                            <MSField
                              name="signingStartDate"
                              formik={formik}
                              {...params}
                            />
                          )}
                        />
                      </div>
                      <p
                        style={{
                          marginTop: 30,
                          padding: 8,
                          color: "var(--gray-500)",
                        }}
                      >
                        to
                      </p>
                      <div className="col-6">
                        <DatePicker
                          label="Signing End Date"
                          value={values.signingEndDate}
                          onChange={(newValue) => {
                            setFieldValue("signingEndDate", new Date(newValue));
                          }}
                          renderInput={(params) => (
                            <MSField
                              name="signingEndDate"
                              formik={formik}
                              {...params}
                            />
                          )}
                        />
                      </div>
                    </LocalizationProvider>
                  </div>
                </div>
              </div>
              <Divider />

              <div className="row col-xl-12 py-5">
                <div className="col-4">
                  <h5 className="mb-2">Price Range</h5>
                  <p className="mb-1" style={{ color: "var(--gray-500)" }}>
                    The minimum and maximum sellable value per share
                  </p>
                </div>
                <div className="col-8">
                  <div className="d-flex" style={{ marginTop: -12 }}>
                    <div className="col-6">
                      <NumberFormat
                        name="minPrice"
                        customInput={MSField}
                        placeholder="0"
                        isNumericString={true}
                        thousandSeparator={true}
                        defaultValue={values.minPrice}
                        allowNegative={false}
                        onValueChange={(val) =>
                          setFieldValue("minPrice", val.floatValue)
                        }
                        formik={formik}
                        inputProps={{
                          style: { textAlign: "right" },
                          "data-lpignore": "true",
                        }}
                        InputProps={{
                          startAdornment: (
                            <InputAdornment
                              position="start"
                              style={{ outline: "none" }}
                            >
                              $
                            </InputAdornment>
                          ),
                          sx: {
                            "&::after": {
                              borderColor: "black",
                            },
                          },
                        }}
                      />
                    </div>
                    <p
                      style={{
                        marginTop: 30,
                        padding: 8,
                        color: "var(--gray-500)",
                      }}
                    >
                      to
                    </p>
                    <div className="col-6">
                      <NumberFormat
                        name="maxPrice"
                        customInput={MSField}
                        placeholder="0"
                        isNumericString={true}
                        thousandSeparator={true}
                        defaultValue={values.maxPrice}
                        allowNegative={false}
                        onValueChange={(val) =>
                          setFieldValue("maxPrice", val.floatValue)
                        }
                        formik={formik}
                        inputProps={{
                          style: { textAlign: "right" },
                          "data-lpignore": "true",
                        }}
                        InputProps={{
                          startAdornment: (
                            <InputAdornment
                              position="start"
                              style={{ outline: "none" }}
                            >
                              $
                            </InputAdornment>
                          ),
                          sx: {
                            "&::after": {
                              borderColor: "black",
                            },
                          },
                        }}
                      />
                    </div>
                  </div>
                </div>
              </div>
              <Divider />

              <div className="row col-xl-12 py-5">
                <div className="col-4">
                  <h5 className="mb-2">Seller Settings</h5>
                  <p className="mb-1" style={{ color: "var(--gray-500)" }}>
                    Parameters enforced for Sellers in the secondary round
                  </p>
                </div>
                <div className="col-8">
                  <div className="d-flex" style={{ marginTop: -12 }}>
                    <div className="col-6 me-4">
                      <MSField
                        name="sellerCountLimit"
                        value={values.sellerCountLimit}
                        type="number"
                        description="The maximum number of sellers that can
            participate in this round"
                        formik={formik}
                        inputProps={{
                          "data-lpignore": true,
                          autoComplete: "off",
                        }}
                      />
                    </div>
                    <div className="col-6">
                      <MSField
                        name="sellablePercentLimit"
                        type="number"
                        value={values.sellablePercentLimit}
                        formik={formik}
                        description="How much of a seller's vested holdings can be sold this round?"
                        InputProps={{
                          endAdornment: (
                            <InputAdornment
                              position="end"
                              style={{ outline: "none" }}
                            >
                              %
                            </InputAdornment>
                          ),
                          sx: {
                            "&::after": { borderColor: "black" },
                          },
                        }}
                        inputProps={{
                          step: "any",
                          "data-lpignore": true,
                          autoComplete: "off",
                        }}
                      />
                    </div>
                  </div>
                </div>
              </div>
              <Divider />

              <div className="row col-xl-12 py-5">
                <div className="col-4">
                  <h5 className="mb-2">Shares Details</h5>
                  <p className="mb-1" style={{ color: "var(--gray-500)" }}>
                    Additional information about the securities
                  </p>
                </div>
                <div className="col-8">
                  <div className="d-flex" style={{ marginTop: -12 }}>
                    <div className="col-6 me-4">
                      <NumberFormat
                        label="Current FMV/409A Price Per Share"
                        name="fmv"
                        customInput={MSField}
                        value={values.fmv}
                        placeholder="0"
                        isNumericString={true}
                        thousandSeparator={true}
                        allowNegative={false}
                        onValueChange={(val) =>
                          setFieldValue("fmv", val.floatValue)
                        }
                        formik={formik}
                        inputProps={{
                          style: { textAlign: "right" },
                          "data-lpignore": true,
                          autoComplete: "off",
                        }}
                        InputProps={{
                          startAdornment: (
                            <InputAdornment
                              position="start"
                              style={{ outline: "none" }}
                            >
                              $
                            </InputAdornment>
                          ),
                          sx: {
                            "&::after": {
                              borderColor: "black",
                            },
                          },
                        }}
                      />
                    </div>
                    <div className="col-6">
                      <MSField
                        name="fullyDilutedSharesCount"
                        type="number"
                        label="Fully diluted shares"
                        value={values.fullyDilutedSharesCount}
                        formik={formik}
                        placeholder={"0"}
                        InputProps={{
                          sx: {
                            "&::after": { borderColor: "black" },
                          },
                        }}
                        inputProps={{
                          step: "1",
                          "data-lpignore": true,
                          autoComplete: "off",
                        }}
                        thousandSeparator={true}
                      />
                    </div>
                  </div>
                </div>
              </div>
              <Divider />
              <div className="col-12 my-2 mt-4">
                <MSButton
                  size="large"
                  variant="primary"
                  type="submit"
                  className="w-100"
                  loading={isSubmitting}
                >
                  {`${isUpdate ? "Update" : "Create"} Round`}
                </MSButton>
              </div>
            </Form>
          );
        }}
      </Formik>
    );
  }

  return <div>{content}</div>;
}
