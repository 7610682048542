import { doc, getFirestore, onSnapshot } from "firebase/firestore";
import { get } from "jsx/api";
import { UserContext } from "jsx/contexts/user-context";
import Divider from "jsx/element/divider";
import ManageTendersNav from "jsx/element/manage-tenders-nav";
import TenderCohorts from "jsx/element/tender-cohorts";
import TenderVisitAnalytics from "jsx/element/tender-visit-analytics";
import Header6 from "jsx/layout/header6";
import React, { useEffect, useState } from "react";
import { useParams } from "react-router-dom";

function TenderVisits() {
  const { selectedOrganization } = React.useContext(UserContext);
  const [roundVisits, setRoundVisits] = useState([]);
  const [visitsGroupedByUser, setVisitsGroupedByUser] = useState([]);

  const { secondaryId } = useParams();

  const db = getFirestore();

  useEffect(() => {
    if (!roundVisits) {
      return;
    }
    const userDict = {};

    const uniqueUsers = [...new Set(roundVisits.map((e) => e.e))];

    roundVisits.forEach((e) => {
      if (!(e.e in userDict)) {
        userDict[e.e] = [];
      }
      userDict[e.e].push(e);
    });

    const ret = [];
    uniqueUsers.forEach((e) => {
      ret.push(userDict[e]);
    });

    setVisitsGroupedByUser(ret);
    console.log("visitsGroupedByUser", ret);
  }, [roundVisits]);

  let roundVisitsSub;

  useEffect(() => {
    fetchRound();
  }, [secondaryId, selectedOrganization]);

  async function fetchRound() {
    if (!selectedOrganization || !secondaryId) {
      return;
    }

    if (!roundVisitsSub) {
      roundVisitsSub = onSnapshot(
        doc(
          db,
          "organizations",
          selectedOrganization,
          "organizations_private",
          "data",
          "secondaries",
          secondaryId,
          "visits",
          "summary"
        ),
        (doc) => {
          if (doc.exists && doc.data() && "visits" in doc.data()) {
            setRoundVisits(doc.data().visits.reverse());
          }
        }
      );
    }
  }

  return (
    <>
      <Header6 />
      <div className="content-body section-padding">
        <div className="container">
          <div className="d-flex">
            <div className="me-4" style={{ minWidth: "220px" }}>
              <ManageTendersNav secondaryId={secondaryId} />
            </div>
            <div className="col-xl-10">
              <div className="row justify-content-center align-items-center">
                <div className="col-xl-12">
                  <div className="card outlined-thin">
                    <div className="card-header border-0">
                      <div>
                        <h4 className="card-title">Round progress</h4>
                        <p className="small mt-3">
                          View participant cohorts and progress
                        </p>
                      </div>
                    </div>
                    <TenderCohorts
                      secondaryId={secondaryId}
                      organizationId={selectedOrganization}
                    />
                  </div>
                </div>
              </div>
              <div className="row justify-content-center align-items-center">
                <div className="col-xl-12">
                  <div className="card outlined-thin">
                    <div className="card-header border-0">
                      <div>
                        <h4 className="card-title">Views</h4>
                        <p className="small mt-3">
                          View participant analytics, including document view
                          count and the time spent on each page
                        </p>
                      </div>
                    </div>
                    <div className="card-body pt-2">
                      <div className="col-12 mt-3">
                        {roundVisits.length === 0 && (
                          <div className="py-4">
                            You have not received any analytics data yet.
                          </div>
                        )}
                        {visitsGroupedByUser.map((e) => {
                          return (
                            <div key={e.i}>
                              <TenderVisitAnalytics
                                selectedOrganization={selectedOrganization}
                                secondaryId={secondaryId}
                                roundVisits={e}
                              />
                              <Divider style={{ marginTop: "0px" }} />
                            </div>
                          );
                        })}
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
}

export default TenderVisits;
