import { get } from "jsx/api";
import MSButton from "jsx/element/button";
import SellerHeader from "jsx/layout/seller-header";
import React, { useEffect, useState } from "react";

import SellerTendersNav from "./seller-tenders-nav";

export default function SellerHelp({ match }) {
  const { secondaryId, organizationId } = match.params;

  const [commsData, setCommsData] = useState();

  useEffect(() => {
    fetchComms();
  }, []);

  async function fetchComms() {
    try {
      const req = await get("secondaries/getFAQs", {
        secondaryId,
        organizationId,
      });

      if (req.data && req.data.comms) {
        setCommsData(req.data.comms);
      }
    } catch (e) {}
  }

  return (
    <>
      <SellerHeader />

      <div className="content-body section-padding">
        <div className="container">
          <div className="d-flex">
            <div className="me-4" style={{ minWidth: "220px" }}>
              <SellerTendersNav
                organizationId={organizationId}
                secondaryId={secondaryId}
              />
            </div>
            <div className="col-xl-10">
              <div className="card outlined">
                <div className="card-header">
                  <h4 className="card-title">FAQs</h4>
                  <a href="mailto:support@modernshare.com">
                    <MSButton size="small" variant="secondary">
                      Contact Us
                    </MSButton>
                  </a>
                </div>
                <div className="card-body">
                  {commsData && (
                    <div
                      dangerouslySetInnerHTML={{
                        __html: commsData[0].content.replaceAll("\n", "<br />"),
                      }}
                    />
                  )}
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
}
