import {
  duotone,
  regular,
} from "@fortawesome/fontawesome-svg-core/import.macro";
import { Skeleton } from "@mui/material";
import Table from "@mui/material/Table";
import TableBody from "@mui/material/TableBody";
import TableCell from "@mui/material/TableCell";
import TableContainer from "@mui/material/TableContainer";
import TableHead from "@mui/material/TableHead";
import TableRow from "@mui/material/TableRow";
import { collection, getDocs, getFirestore } from "firebase/firestore";
import { UserContext } from "jsx/contexts/user-context";
import EmptyState from "jsx/element/empty-state";
import Header4 from "jsx/layout/header4";
import React, { useEffect, useState } from "react";
import { useHistory } from "react-router-dom";
import { CountUp } from "use-count-up";

function Dashboard() {
  const { user, selectedOrganization, selectOrganization } =
    React.useContext(UserContext);
  const [investments, setInvestments] = useState();
  const [amountRequested, setAmountRequested] = useState(0);
  const [avgMaxPrice, setAvgMaxPrice] = useState(0);
  // const [totalClosed, setTotalClosed] = useState(0);
  const totalClosed = 0;

  const db = getFirestore();
  const history = useHistory();

  useEffect(() => {
    if (!selectedOrganization) {
      // pick the first organization if exists, if not, redirect to investor account
      if (user.organizations && user.organizations.length > 0) {
        selectOrganization(user.organizations[0]);
      } else {
        history.replace("/investor/dashboard");
      }
      return;
    }

    fetchInvestmentsRequests();
  }, [selectedOrganization]);

  async function fetchInvestmentsRequests() {
    if (!selectedOrganization) {
      return;
    }
    const querySnapshot = await getDocs(
      collection(
        db,
        "organizations",
        selectedOrganization,
        "organizations_admin",
        "data",
        "investment_requests"
      )
    );

    const totalRequested = 0;
    const totalMaxPrice = 0.0;
    const nonUncappedRequests = 0;

    if (nonUncappedRequests) {
      setAvgMaxPrice(totalMaxPrice / nonUncappedRequests);
    }
    setAmountRequested(totalRequested);
    setInvestments(querySnapshot.docs);
  }

  let content;
  if (!investments) {
    content = (
      <>
        <Skeleton height={50} />
        <Skeleton height={50} />
        <Skeleton height={50} />
        <Skeleton height={50} />
      </>
    );
  } else if (investments && investments.length > 0) {
    content = (
      <TableContainer>
        <Table aria-label="simple table">
          <TableHead>
            <TableRow>
              <TableCell>Investor</TableCell>
              <TableCell align="right">Requested Date</TableCell>
              <TableCell align="right">Amount</TableCell>
              <TableCell align="right">Max Cap</TableCell>
            </TableRow>
          </TableHead>
          <TableBody
            sx={{
              "& .MuiTableRow-root": {
                transition: "background 0.5",
              },
              "& .MuiTableRow-root:hover": {
                background: "var(--background)",
              },
            }}
          >
            {investments.map((doc, i) => {
              const investment = doc.data();

              return (
                <TableRow key={i}>
                  <TableCell>
                    <h6>{investment.investorName}</h6>
                  </TableCell>

                  <TableCell align="right" style={{ padding: "24px" }}>
                    {investment.investorEmail}
                  </TableCell>
                  <TableCell align="right">
                    {new Date(
                      investment.createdDate.seconds * 1000
                    ).toLocaleString()}
                  </TableCell>
                  <TableCell align="right">
                    ${investment.investmentAmount.toLocaleString()}{" "}
                  </TableCell>
                  <TableCell align="right">${investment.maxPrice}</TableCell>
                </TableRow>
              );
            })}
          </TableBody>
        </Table>
      </TableContainer>
    );
  } else {
    content = (
      <EmptyState
        icon={duotone("money-check-dollar-pen")}
        title={"No investment requests to display"}
        subtitle={"Investment requests will appear here when you receive one"}
      />
    );
  }

  return (
    <>
      <Header4 />
      <div className="content-body section-padding">
        <div className="container">
          <div className="row">
            <div className="col-4 col-xs-12">
              <div
                className="card outlined"
                style={{ paddingTop: 30, paddingBottom: 30 }}
              >
                <div className="d-flex">
                  <div className="big-icon">
                    <i className="la la-handshake"></i>
                  </div>
                  <div>
                    <h2>
                      <CountUp
                        isCounting
                        end={amountRequested}
                        duration={0.5}
                        decimalPlaces={0}
                        formatter={(value) =>
                          "$" + value.toLocaleString().split(".")[0]
                        }
                      />
                    </h2>
                    <h6>Total Amount Requested</h6>
                  </div>
                </div>
              </div>
            </div>
            <div className="col-4 col-xs-12">
              <div
                className="card outlined"
                style={{ paddingTop: 30, paddingBottom: 30 }}
              >
                <div className="d-flex">
                  <div className="big-icon">
                    <i className="la la-money-bill"></i>
                  </div>
                  <div>
                    <h2>
                      <CountUp
                        isCounting
                        end={avgMaxPrice}
                        duration={0.5}
                        decimalPlaces={0}
                        formatter={(value) =>
                          "$" + value.toLocaleString().split(".")[0] + "B"
                        }
                      />
                    </h2>{" "}
                    <h6>Average Max Price</h6>
                  </div>
                </div>
              </div>
            </div>
            <div className="col-4 col-xs-12">
              <div
                className="card outlined"
                style={{ paddingTop: 30, paddingBottom: 30 }}
              >
                <div className="d-flex">
                  <div className="big-icon">
                    <i className="la la-bank"></i>
                  </div>
                  <div>
                    <h2>
                      <CountUp
                        isCounting
                        end={totalClosed}
                        duration={0.5}
                        decimalPlaces={0}
                        formatter={(value) =>
                          "$" + value.toLocaleString().split(".")[0]
                        }
                      />
                    </h2>
                    <h6>Total Closed</h6>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div className="row">
            <div className="col-xl-12">
              <div className="card outlined">
                <div className="card-header border-0">
                  <h4 className="card-title">Investment requests</h4>
                </div>

                <div className="card-body pt-2">{content}</div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
}

export default Dashboard;
