import React, { useState } from "react";
import {
  BrowserRouter as Router,
  Route,
  Switch,
  useRouteMatch,
} from "react-router-dom";
import uniqid from "uniqid";

import CommonSharesAdd from "./common-shares-add-class";
import AddShares from "./common-shares-add-shares";
import EditShares from "./common-shares-edit-shares";
import View from "./common-shares-view";
import WelcomeCard from "./common-shares-welcome-card";
const templateData = [
  {
    id: "ab23y28kfjms",
    shareClassName: "Secondary",
    shareClassPrefix: "CS",
    authorizedShares: "121,221",
    authorizedDate: "2021-04-27",
    parValue: "1,00",
    shares: [
      {
        id: "40645a1kuz3wmb4",
        email: "dumitruiurie@gmail.com",
        stackHolderType: "individual",
        firstName: "Dumitru",
        lastName: "Cucu",
        organizationName: "",
        securityHolderRelationship: "Ex Advisor",
        relationShipStartDate: "2021-03-20",
        boardApprovalDate: "2021-04-20",
        issueDate: "2021-03-02",
        numberOfShares: "1,212",
        currency: "AFN",
        paymentForShares: {
          values: {
            cashPaid: 1212,
            debtCanceled: 131212,
            ipTransfered: 312,
            other: 2121,
          },
          total: 134857,
        },
        vestingSchedule: "",
        vestingStartDate: "2021-04-20",
        accelerationTerms: "Nothing to say",
        legend: "default",
        documents: {},
      },
    ],
  },
];
function CommonSharesMain({ onReturn }) {
  const [data, setData] = useState(templateData);
  const { path } = useRouteMatch();
  console.log(data);
  const isListEmpty = data.length === 0 && true;
  const handleAddClass = (values) => {
    setData((prev) => [...prev, { id: uniqid(), ...values, shares: [] }]);
  };
  const handleAddShares = (id, values) => {
    const isValid = data.filter((item) => item.id === id)[0] !== undefined;
    const dataHandler = (prev) =>
      prev.map((item) => {
        if (item.id === id) {
          item = {
            ...prev.filter((item) => item.id === id)[0],
            shares: [
              ...prev.filter((item) => item.id === id)[0].shares,
              { id: uniqid(), ...values },
            ],
          };
        }
        return item;
      });
    isValid && setData(dataHandler);
  };
  const handleEditShares = (id, rootId, values) => {};
  return (
    <Router>
      <div>
        <Switch>
          <Route exact path={path}>
            <WelcomeCard show={isListEmpty} />
          </Route>
          <Route path={`${path}/current`}>
            <View show={!isListEmpty} data={data} />
          </Route>
          <Route exact path={`${path}/add-class`}>
            <CommonSharesAdd onAdd={handleAddClass} />
          </Route>
          <Route exact path={`${path}/add-shares`}>
            <AddShares onAdd={handleAddShares} />
          </Route>
          <Route exact path={`${path}/edit-share/:rootId/:id`}>
            <EditShares data={data} onEdit={handleEditShares} />
          </Route>
        </Switch>
      </div>
    </Router>
  );
}

export default CommonSharesMain;
