import { Divider } from "@mui/material";
import MSDropdown, { MSDropdownItem } from "jsx/element/dropdown";
import React, { useState } from "react";
import { Modal } from "react-bootstrap";

import ImportUpload from "./upload";

export default function ImportButton({ onFinish }) {
  // Get org's latest imports for stakeholders and securities
  const [importType, setImportType] = useState(null);
  const [modalOpen, setModalOpen] = useState(false);

  const openUpload = (importType) => (_event) => {
    setImportType(importType);
    setModalOpen(true);
  };

  const handleFinish = () => {
    setModalOpen(false);
    if (onFinish) onFinish(importType);
  };

  return (
    <>
      <MSDropdown
        size="small"
        title="Import data"
        anchorOrigin={{
          vertical: "bottom",
          horizontal: "right",
        }}
        transformOrigin={{
          vertical: "top",
          horizontal: "right",
        }}
      >
        <MSDropdownItem onClick={openUpload("stakeholders")}>
          Import stakeholder data
        </MSDropdownItem>
        <MSDropdownItem onClick={openUpload("securities")}>
          Import securities data
        </MSDropdownItem>
      </MSDropdown>

      <Modal show={modalOpen} onHide={() => setModalOpen(false)}>
        <ImportUpload importType={importType} onFinish={handleFinish} />
      </Modal>
    </>
  );
}
