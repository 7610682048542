const { FontAwesomeIcon } = require("@fortawesome/react-fontawesome");

// helper component for formatting details/info
export default function Detail({ title, icon, children }) {
  return (
    <div className="p-2">
      <div className="d-flex">
        <FontAwesomeIcon
          icon={icon}
          size="sm"
          style={{ marginRight: 12, marginTop: 2, color: "var(--gray-500)" }}
        />

        <h5 className="col-5" style={{ color: "var(--gray-500)" }}>
          {title}
        </h5>
        <h5>{children}</h5>
      </div>
    </div>
  );
}
