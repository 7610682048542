import React from "react";
import ReactApexChart from "react-apexcharts";

function nFormatter(num) {
  if (num >= 1000000000) {
    return (num / 1000000000).toFixed(1).replace(/\.0$/, "") + "B";
  }
  if (num >= 1000000) {
    return (num / 1000000).toFixed(1).replace(/\.0$/, "") + "M";
  }
  if (num >= 1000) {
    return (num / 1000).toFixed(1).replace(/\.0$/, "") + "K";
  }
  return num;
}

function AreaChart(props) {
  const points = [];

  for (const a of props.annotations) {
    points.push({
      x: a[0],
      y: a[1],
      marker: {
        size: 0,
      },
      label: {
        borderColor: "#7B6FFF",
        style: {
          fontSize: "12px",
          color: "#fff",
          background: "#7B6FFF",
        },
        position: "bottom",
        text: a[2],
      },
    });
  }

  const state = {
    series: [
      {
        name: "Price Per Share",
        data: props.data,
      },
    ],
    options: {
      chart: {
        type: "area",
        height: 300,
        foreColor: "#8C87C2",
        fontFamily: "Rubik, sans-serif",
        dropShadow: {
          enabled: true,
          enabledSeries: [0],
          top: -2,
          left: 2,
          blur: 5,
          opacity: 0.06,
        },
        toolbar: {
          show: false,
        },
      },
      colors: ["#7B6FFF", "#1652F0"],
      stroke: {
        curve: "straight",
        width: 3,
      },
      dataLabels: {
        enabled: false,
      },
      annotations: {
        xaxis: points,
        // yaxis: [
        //   {
        //     y: 12.0,
        //     borderColor: "#00E396",
        //     label: {
        //       borderColor: "#00E396",
        //       style: {
        //         color: "#fff",
        //         background: "#00E396",
        //       },
        //       text: "Y Axis Annotation",
        //     },
        //   },
        // ],
      },
      markers: {
        size: 6,
        hover: {
          size: 9,
        },
      },
      xaxis: {
        type: "datetime",
        axisBorder: {
          show: false,
        },
        axisTicks: {
          show: false,
        },
        min: undefined,
        max: undefined,
      },
      yaxis: {
        show: false,
        labels: {
          offsetX: -10,
          offsetY: 0,
          formatter: function (value) {
            return "$" + nFormatter(value);
          },
        },
        axisBorder: {
          show: false,
        },
        axisTicks: {
          show: false,
        },
        tooltip: {
          enabled: false,
        },
      },
      grid: {
        show: false,
        padding: {
          left: -5,
          right: 5,
        },
      },
      tooltip: {
        custom: function ({ series, seriesIndex, dataPointIndex, w }) {
          var data = w.globals.initialSeries[seriesIndex].data[dataPointIndex];

          return `<div style="padding: 16px;  font-size: 13px;">
              <h4 style="margin-bottom: 4px;">${data[1]}</h4>
              <div>
                <b>Price Per Share</b><span style="margin-left: 8px;">$${data[4]}</span
              </div>
              <div>
                <b>Valuation</b><span style="margin-left: 8px;">${data[3]}</span>
              </div>
          </div>`;
        },
      },
      legend: {
        position: "top",
        horizontalAlign: "left",
      },
      fill: {
        type: "gradient",
        gradient: {
          shadeIntensity: 1,
          opacityFrom: 0.5,
          opacityTo: 0,
          stops: [0, 100, 100],
        },
      },
    },
  };

  return (
    <>
      <div id="chart-timeline">
        <ReactApexChart
          options={state.options}
          series={state.series}
          type="area"
          height={350}
        />
      </div>
    </>
  );
}

export default AreaChart;
