import Divider from "jsx/element/divider";
import React from "react";
import { Link } from "react-router-dom";

export default function SellerTendersNav(props) {
  const { secondaryId, organizationId } = props;

  return (
    <>
      <div className="settings_menu">
        <h4 className="my-3">Tender</h4>
        <Divider
          style={{
            position: "relative",
            left: 0,
            marginBottom: 16,
            height: 2,
            marginRight: 4,
          }}
        />

        <ul>
          <div className="sidemenu">
            <li className="nav-item">
              <Link
                to={`/seller/dashboard`}
                className={
                  "nav-link " +
                  (!document.location.href.includes("tender") ? "active" : "")
                }
              >
                <i className="la la-comment-dollar"></i>
                <span>Place Order</span>
              </Link>
            </li>

            <li className="nav-item">
              <Link
                to={`/seller/tender/${secondaryId}/orders`}
                className={
                  "nav-link " +
                  (document.location.href.includes("orders") ? "active" : "")
                }
              >
                <i className="la la-list"></i>
                <span>View Orders</span>
              </Link>
            </li>
            <li className="nav-item">
              <Link
                to={`/seller/orgs/${organizationId}/tender/${secondaryId}/dataroom`}
                className={
                  "nav-link " +
                  (document.location.href.includes("dataroom") ? "active" : "")
                }
              >
                <i className="la la-file-alt"></i>
                <span>Dataroom</span>
              </Link>
            </li>
            <li className="nav-item">
              <Link
                to={`/seller/orgs/${organizationId}/tender/${secondaryId}/help`}
                className={
                  "nav-link " +
                  (document.location.href.includes("help") ? "active" : "")
                }
              >
                <i className="la la-question-circle"></i>
                <span>Help / FAQs</span>
              </Link>
            </li>
            {/* <li className="nav-item">
              <Link
                to={`/seller/orgs/${organizationId}/secondary/${secondaryId}/profile`}
                className={
                  "nav-link " +
                  (document.location.href.includes("profile") ? "active" : "")
                }
              >
                <i className="la la-user-circle"></i>
                <span>Manage Profile</span>
              </Link>
            </li> */}
          </div>
        </ul>
      </div>
    </>
  );
}
