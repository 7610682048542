import PageTitle from "jsx/element/page-title";
import Footer2 from "jsx/layout/footer2";
import Header2 from "jsx/layout/header2";
import Sidebar from "jsx/layout/sidebar";
import React from "react";

function History() {
  return (
    <>
      <Header2 />
      <Sidebar />
      <PageTitle />

      <div className="content-body">
        <div className="container-fluid">
          <div className="row">
            <div className="col-xl-12">
              <div className="card">
                <div className="card-header border-0">
                  <h4 className="card-title">Transaction History</h4>
                </div>
                <div className="card-body pt-0">
                  <div className="transaction-table">
                    <div className="table-responsive">
                      <table className="table mb-0 table-responsive-sm">
                        <tbody>
                          <tr>
                            <td>
                              <span className="sold-thumb">
                                <i className="la la-arrow-down"></i>
                              </span>
                            </td>

                            <td>
                              <span className="badge badge-danger">Sold</span>
                            </td>
                            <td>
                              <i className="cc BTC"></i> BTC
                            </td>
                            <td>Using - Bank *******5264</td>
                            <td className="text-danger">-0.000242 BTC</td>
                            <td>-0.125 USD</td>
                          </tr>
                          <tr>
                            <td>
                              <span className="buy-thumb">
                                <i className="la la-arrow-up"></i>
                              </span>
                            </td>
                            <td>
                              <span className="badge badge-success">Buy</span>
                            </td>
                            <td>
                              <i className="cc LTC"></i> LTC
                            </td>
                            <td>Using - Card *******8475</td>
                            <td className="text-success">-0.000242 BTC</td>
                            <td>-0.125 USD</td>
                          </tr>
                          <tr>
                            <td>
                              <span className="sold-thumb">
                                <i className="la la-arrow-down"></i>
                              </span>
                            </td>
                            <td>
                              <span className="badge badge-danger">Sold</span>
                            </td>
                            <td>
                              <i className="cc XRP"></i> XRP
                            </td>
                            <td>Using - Card *******8475</td>
                            <td className="text-danger">-0.000242 BTC</td>
                            <td>-0.125 USD</td>
                          </tr>
                          <tr>
                            <td>
                              <span className="buy-thumb">
                                <i className="la la-arrow-up"></i>
                              </span>
                            </td>
                            <td>
                              <span className="badge badge-success">Buy</span>
                            </td>
                            <td>
                              <i className="cc Benchling"></i> Benchling
                            </td>
                            <td>Using - Card *******2321</td>
                            <td className="text-success">-0.000242 BTC</td>
                            <td>-0.125 USD</td>
                          </tr>
                          <tr>
                            <td>
                              <span className="sold-thumb">
                                <i className="la la-arrow-down"></i>
                              </span>
                            </td>
                            <td>
                              <span className="badge badge-danger">Sold</span>
                            </td>
                            <td>
                              <i className="cc BTC"></i> BTC
                            </td>
                            <td>Using - Card *******2321</td>
                            <td className="text-danger">-0.000242 BTC</td>
                            <td>-0.125 USD</td>
                          </tr>
                          <tr>
                            <td>
                              <span className="sold-thumb">
                                <i className="la la-arrow-down"></i>
                              </span>
                            </td>

                            <td>
                              <span className="badge badge-danger">Sold</span>
                            </td>
                            <td>
                              <i className="cc BTC"></i> BTC
                            </td>
                            <td>Using - Bank *******5264</td>
                            <td className="text-danger">-0.000242 BTC</td>
                            <td>-0.125 USD</td>
                          </tr>
                          <tr>
                            <td>
                              <span className="buy-thumb">
                                <i className="la la-arrow-up"></i>
                              </span>
                            </td>
                            <td>
                              <span className="badge badge-success">Buy</span>
                            </td>
                            <td>
                              <i className="cc LTC"></i> LTC
                            </td>
                            <td>Using - Card *******8475</td>
                            <td className="text-success">-0.000242 BTC</td>
                            <td>-0.125 USD</td>
                          </tr>
                          <tr>
                            <td>
                              <span className="sold-thumb">
                                <i className="la la-arrow-down"></i>
                              </span>
                            </td>
                            <td>
                              <span className="badge badge-danger">Sold</span>
                            </td>
                            <td>
                              <i className="cc XRP"></i> XRP
                            </td>
                            <td>Using - Card *******8475</td>
                            <td className="text-danger">-0.000242 BTC</td>
                            <td>-0.125 USD</td>
                          </tr>
                          <tr>
                            <td>
                              <span className="buy-thumb">
                                <i className="la la-arrow-up"></i>
                              </span>
                            </td>
                            <td>
                              <span className="badge badge-success">Buy</span>
                            </td>
                            <td>
                              <i className="cc Benchling"></i> Benchling
                            </td>
                            <td>Using - Card *******2321</td>
                            <td className="text-success">-0.000242 BTC</td>
                            <td>-0.125 USD</td>
                          </tr>
                          <tr>
                            <td>
                              <span className="sold-thumb">
                                <i className="la la-arrow-down"></i>
                              </span>
                            </td>
                            <td>
                              <span className="badge badge-danger">Sold</span>
                            </td>
                            <td>
                              <i className="cc BTC"></i> BTC
                            </td>
                            <td>Using - Card *******2321</td>
                            <td className="text-danger">-0.000242 BTC</td>
                            <td>-0.125 USD</td>
                          </tr>
                        </tbody>
                      </table>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>

      <Footer2 />
    </>
  );
}

export default History;
