import { doc, getDoc, getFirestore } from "firebase/firestore";
import Bottom from "jsx/element/bottom";
import CompanyDetails from "jsx/element/company-details";
import Footer1 from "jsx/layout/footer1";
import Header1 from "jsx/layout/header1";
import React, { useEffect, useState } from "react";
import { useParams } from "react-router-dom";

function Company() {
  const [company, setCompany] = useState({});
  const [lastFunding, setLastFunding] = useState({});

  const { companyId } = useParams();

  const db = getFirestore();

  async function fetchCompany() {
    const docRef = doc(db, "companies", companyId);
    const docSnap = await getDoc(docRef);
    if (docSnap.exists()) {
      setCompany(docSnap.data());
      if (docSnap.data().chart_data) {
        const chartLength = docSnap.data().chart_data.length;
        setLastFunding(docSnap.data().chart_data[chartLength - 1]);
      }
    } else {
    }
  }

  useEffect(() => {
    fetchCompany();
  }, []);

  return (
    <>
      <Header1 />
      <div className="content-body section-padding mt-8">
        <div className="container">
          <div className="row">
            <CompanyDetails company={company} />
            <div className="col-xl-4 col-lg-12 col-xxl-12">
              <div className="card position-fixed">
                <div className="card outlined fixed-size">
                  <div className="text-center py-4">
                    <p>Estimated valuation</p>
                    <h2>{company.valuation}</h2>
                    <p>{lastFunding.price_per}</p>

                    <button
                      type="submit"
                      name="submit"
                      onClick={() => {}}
                      className="btn btn-success w-100"
                    >
                      Sign up
                    </button>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>

      <Bottom />

      <Footer1 />
    </>
  );
}

export default Company;
