import { regular } from "@fortawesome/fontawesome-svg-core/import.macro";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { UserContext } from "jsx/contexts/user-context";
import MSDropdown from "jsx/element/dropdown";
import React, { useEffect, useState } from "react";
import { Nav, Navbar, NavDropdown } from "react-bootstrap";
import { useMediaQuery } from "react-responsive";
import { Link } from "react-router-dom";
import { useHistory } from "react-router-dom";

export default function Header6(props) {
  const [offset, setOffset] = useState(0);
  const { user, organizations, selectedOrganization, selectOrganization } =
    React.useContext(UserContext);
  const [menuItems, setMenuItems] = useState([]);
  const isMobile = useMediaQuery({ minWidth: 0, maxWidth: 500 });

  const history = useHistory();
  const isCompanyAccount =
    selectedOrganization && selectedOrganization !== "personal";

  useEffect(() => {
    window.onscroll = () => {
      setOffset(window.pageYOffset);
    };
  }, []);

  const navDropdownOverview = (
    <div className="company-switcher ms-2">
      {(!selectedOrganization || selectedOrganization === "personal") &&
        user.displayName}
      {selectedOrganization &&
        organizations &&
        organizations[selectedOrganization] &&
        organizations[selectedOrganization].companyName}
    </div>
  );

  useEffect(() => {
    if (!organizations || !Object.keys(organizations).length) {
      return;
    }
    setMenuItems(menuItems);
  }, [organizations]);

  const companySwitcherMenu = (
    <>
      <NavDropdown title={navDropdownOverview} className="company-switcher">
        {menuItems.length > 0 && <>{menuItems}</>}

        <NavDropdown.Item style={{ fontSize: 15 }} as={Link} to="/org/new">
          <FontAwesomeIcon
            icon={regular("plus")}
            style={{ marginRight: 8 }}
            size="lg"
          />
          Create new team
        </NavDropdown.Item>
      </NavDropdown>
    </>
  );

  const navDropdownFundraise = (
    <div>
      <i className="la la-usd"></i>
      <span>Fundraise</span>
    </div>
  );

  const navDropdownSecondaries = (
    <div>
      <i className="la la-comments-dollar"></i>
      <span>Secondaries</span>
    </div>
  );

  const navDropdownSettings = (
    <div>
      <i className="la la-gear"></i>
      <span>Settings</span>
    </div>
  );

  const companySettingsDropdownMenu = (
    <>
      <NavDropdown.Item as={Link} to="/org/settings/company">
        <FontAwesomeIcon
          icon={regular("briefcase")}
          style={{ marginRight: 8 }}
        />
        Company Info
      </NavDropdown.Item>
      <NavDropdown.Item as={Link} to="/org/settings/team">
        <FontAwesomeIcon
          icon={regular("user-group")}
          style={{ marginRight: 8 }}
        />
        Manage Team
      </NavDropdown.Item>
      {/* {isCompanyAccount && (
        <>
          <NavDropdown.Divider />
          <NavDropdown.Item as={Link} to="/org/settings/bank">
            <i className="la la-piggy-bank"></i>
            Connect Bank
          </NavDropdown.Item>
        </>
      )} */}
    </>
  );

  let dropDownButtonTitle = user.displayName ?? user.email;

  if (isMobile) {
    dropDownButtonTitle = "Account";
  }

  if (dropDownButtonTitle.length > 12 && user.email.includes("@")) {
    dropDownButtonTitle = dropDownButtonTitle.split("@")[0];
  }

  const personalSettingsDropdownMenu = <></>;
  let selectedMenu;
  if (window.location.href.includes("overview")) {
    selectedMenu = "overview";
  } else if (window.location.href.includes("settings")) {
    selectedMenu = "settings";
  }

  let firstLetter;

  if (isCompanyAccount) {
    if (organizations && selectedOrganization in organizations) {
      const organization = organizations[selectedOrganization];
      firstLetter = organization.companyName.length
        ? organization.companyName[0]
        : "";
    }
  } else {
    if (user.displayName) {
      firstLetter = user.displayName.length ? user.displayName[0] : "";
    }
  }

  let navBarItems;

  if (isCompanyAccount) {
    navBarItems = (
      <Nav className="mr-auto my-lg-0" navbarScroll>
        <div className="d-flex">
          <Nav.Link
            as={Link}
            to={`/org/dashboard`}
            className={selectedMenu === "overview" ? "selected" : ""}
          >
            <i className="la la-home"></i>
            <span>Overview</span>
          </Nav.Link>
          <NavDropdown
            title={navDropdownSettings}
            className={selectedMenu === "settings" ? "selected" : ""}
          >
            {selectedOrganization === "personal"
              ? personalSettingsDropdownMenu
              : companySettingsDropdownMenu}
          </NavDropdown>
        </div>
      </Nav>
    );
  }

  return (
    <>
      <div className={"header " + (offset > 60 ? "fixed" : "")}>
        <div className="container">
          <div className="row">
            <div className="col-xl-12 navigation">
              <Navbar
                expand="lg"
                style={{ minHeight: 80, paddingLeft: 8, paddingRight: 8 }}
              >
                {!props.hideAllMenuItems && (
                  <>
                    <div className="company-logo">
                      {firstLetter ?? (
                        <i
                          className="la la-user"
                          style={{
                            position: "absolute",
                            fontSize: 22,
                            left: 15,
                            marginTop: 7,
                          }}
                        ></i>
                      )}
                    </div>
                    {navDropdownOverview}
                  </>
                )}
                {props.hideAllMenuItems && !props.hideBackButton && (
                  <div style={{ flexDirection: "row", display: "flex" }}>
                    <div
                      className="back-button d-flex d-none d-sm-block"
                      onClick={() => {
                        if (props.backButtonLink) {
                          history.replace(props.backButtonLink);
                        } else {
                          history.goBack();
                        }
                      }}
                    >
                      <div>
                        <i className="la la-angle-left"></i>
                        <span>Back</span>
                      </div>
                    </div>

                    {props.pageTitle && (
                      <div
                        style={{
                          marginLeft: 32,
                          fontSize: 20,
                          fontWeight: 500,
                          marginTop: 11,
                          lineHeight: 0,
                        }}
                      >
                        {props.pageTitle}
                      </div>
                    )}
                  </div>
                )}
                {props.children}

                <Navbar.Collapse
                  id="navbarScroll"
                  className="justify-content-center"
                >
                  {!props.hideAllMenuItems &&
                    !props.hideCenterMenu &&
                    navBarItems}
                </Navbar.Collapse>
                {user.email && (!props.hideAllMenuItems || props.showLogout) && (
                  <MSDropdown
                    title={dropDownButtonTitle}
                    buttonProps={{ variant: "primary", size: "small" }}
                    anchorOrigin={{
                      vertical: "bottom",
                      horizontal: "right",
                    }}
                    transformOrigin={{
                      vertical: "top",
                      horizontal: "right",
                    }}
                  >
                    <Link to={"/org/dashboard"} className="dropdown-item">
                      <i className="la la-user"></i> Dashboard
                    </Link>

                    <Link to={"/logout"} className="dropdown-item logout">
                      <i className="la la-sign-out"></i> Logout
                    </Link>
                  </MSDropdown>
                )}
              </Navbar>
            </div>
          </div>
        </div>
      </div>
    </>
  );
}
