// Don't auto-sort these imports
/* eslint-disable simple-import-sort/imports */
import React, { Component, useEffect } from "react";
import {
  BrowserRouter,
  Route,
  Switch,
  Redirect,
  useHistory,
  useLocation,
} from "react-router-dom";
// import Homepage from "./pages/home";
import Homepage2 from "./pages/home2";
// import Homepage3 from "./pages/home3";

// import ComingSoon from "./pages/coming-soon";

import Secret from "./pages/secret";
import Price from "./pages/price";
import Wallet from "./pages/wallet";
import About from "./pages/about";
import Team from "./pages/team";
import BrowseCompany from "./pages/browse-company";
// import { Sugar } from "react-preloaders";

import Blog from "./pages/blog";
import BlogSingle from "./pages/blog-single";
import Career from "./pages/career";
import Contact from "./pages/contact";
import HelpDesk from "./pages/helpdesk";
import Faq from "./pages/faq";

// Investor pages
import InvestorDashboard from "./pages/investor/investor-dashboard";
import Company from "./pages/investor/company";
import Browse from "./pages/investor/browse";
import Accounts from "./pages/investor/accounts";
import Settings from "./pages/investor/settings";
import Preferences from "./pages/investor/settings-preferences";
import SettingsSecurity from "./pages/investor/settings-security";
import SettingsAccount from "./pages/investor/settings-account";
import AddBankAccount from "./pages/investor/add-bank-acc";
import AddDebitCard from "./pages/investor/add-debit-card";
import Onboarding from "./pages/investor/onboarding";

// Investor add / setup
import CompanySetupInfo from "./pages/company/company-setup-info";
import CompanyEditInfo from "./pages/company/settings/settings-edit-info";

// Company pages
import CompanyDashboard from "./pages/company/personal-investments";
import CompanyContracts from "./pages/company/contracts";
// import CompanyContractsSettings from "./pages/company/contract-settings";
import CompanyContractsCreateRules from "./pages/company/contract-create-rules";
import CompanyContractsCreateKYC from "./pages/company/contract-create-kyc";
import CompanyContractsCreateIssue from "./pages/company/contract-create-issue";

// Seller pages
import SellerDashboard from "./pages/seller/seller-dashboard";
import SellerCompany from "./pages/seller/seller-company-view";
import SellerViewOrders from "./pages/seller/seller-view-orders";
import SellerDataroom from "./pages/seller/seller-dataroom";

// Fundraise
import CompanyFundraiseManage from "./pages/company/fundraise/fundraise-manage";
import CompanyFundraiseManageCreate from "./pages/company/fundraise/fundraise-manage-create";

// Investments
import FundraiseInvestors from "./pages/company/fundraise/fundraise-round";
import FundraiseInvestorsVisits from "./pages/company/fundraise/fundraise-visits";

import CompanyInfo from "./pages/company/fundraise/fundraise-company-info";
import RisksAndDisclosures from "./pages/company/fundraise/fundraise-risks-disclosures";
import TeamMembers from "./pages/company/fundraise/fundraise-team-members";
import FinalStep from "./pages/company/fundraise/fundraise-final-step";

import TeamInvite from "./pages/company/fundraise/team-invite";
import FundraiseInvite from "./pages/company/fundraise/fundraise-invite";
import CompanyFundraiseSign from "./pages/company/fundraise/fundraise-sign";
import CompanyFundraiseInvestment from "./pages/company/fundraise/fundraise-investment";

// Secondaries
import CompanySecondaryManageOverview from "./pages/company/secondaries/manage/secondary-manage-overview";
import CompanySecondariesManageEdit from "./pages/company/secondaries/secondaries-manage-edit";

import CompanySecondaryManageEmployees from "./pages/company/secondaries/manage/secondary-manage-stakeholders";
import CompanySecondaryManageOrders from "./pages/company/secondaries/manage/secondary-manage-orders";
import CompanySecondaryManageVisits from "./pages/company/secondaries/manage/secondary-visits";
import CompanySecondaryManageAdmin from "./pages/company/secondaries/manage/secondary-admin";
import CompanySecondaryManageMatch from "./pages/company/secondaries/manage/secondary-match";

import CompanySecondariesManageList from "./pages/company/secondaries/secondaries-manage-list";
import CompanySecondariesManageCreate from "./pages/company/secondaries/secondaries-manage-create";
import CompanySecondariesStakeholdersManageTable from "./pages/company/secondaries/secondaries-stakeholders-manage";
import CompanySecondariesModeling from "./pages/company/secondaries/secondaries-modeling";
import CompanySecondaryDataroom from "./pages/company/secondaries/secondary-dataroom";
import CompanySecondariesTransaction from "./pages/company/secondaries/secondaries-transaction";
import CompanySecondariesDocumentView from "./pages/company/secondaries/docs/secondaries-doc-view";
import CompanySecondariesSignCompany from "./pages/company/secondaries/docs/secondaries-sign-company";
import CompanySecondariesSignBuyer from "./pages/company/secondaries/docs/secondaries-sign-buyer";
import CompanySecondariesSignSpouse from "./pages/company/secondaries/docs/secondaries-sign-spouse";

import SecondaryInvite from "./pages/company/secondaries/secondary-invite";
import InvestorSecondaryTransaction from "./pages/investor/investor-secondary-transaction";
import InvestorSecondaryOrder from "./pages/investor/investor-secondary-order";

// Tenders
import CompanyTenderManageOverview from "./pages/company/tenders/manage/tender-manage-overview";
import CompanyTenderManageEmployees from "./pages/company/tenders/manage/tender-manage-participants";
import CompanyTenderManageOrders from "./pages/company/tenders/manage/tender-manage-orders";
import CompanyTenderManageDataroom from "./pages/company/tenders/manage/tender-manage-dataroom";
import CompanyTenderManageAnalytics from "./pages/company/tenders/manage/tender-manage-analytics";
import CompanyTenderModeling from "./pages/company/tenders/manage/tender-manage-modeling";
import CompanyTenderManageCommunications from "./pages/company/tenders/manage/tender-manage-communications";

import Test from "./pages/debug/test";
import SignTest from "./pages/debug/sign";

// Settings
import SettingsBankConnect from "./pages/company/settings/settings-bank-connect";
import SettingsManageTeam from "./pages/company/settings/settings-manage-team";

// Captable
// Onboarding
import OnboardCompanyDetails from "./pages/captable/onboard/captable-main";
import Loader from "./element/loader";

import Locked from "./pages/lock";
import Otp1 from "./pages/otp-1";
import Otp2 from "./pages/otp-2";
import PrivacyPolicy from "./pages/privacy-policy";
import TrustedContact from "./pages/trusted-contact";
import NonDisclosure from "./pages/non-disclosure";
import DisclaimersDisclosures from "./pages/disclaimers-disclosures";
import Reset from "./pages/reset";
// import ResetConfirmation from "./pages/reset-confirmation";
import Signin from "./pages/signin";
import Logout from "./pages/logout";
import Signup from "./pages/signup";
import TermsConditions from "./pages/terms-conditions";
import VerifyStep1 from "./pages/verify-step-1";
import VerifyStep2 from "./pages/verify-step-2";
import VerifyStep3 from "./pages/verify-step-3";
import VerifyStep4 from "./pages/verify-step-4";
import VerifyStep5 from "./pages/verify-step-5";
import VerifyStep6 from "./pages/verify-step-6";
// import VerifyEmail from "jsx/pages/verify-email";
import History from "./pages/history";
import Demo from "./pages/demo";
import FundraiseVieDealHack from "./pages/company/fundraise/fundraise-view-deal-hack";
// import FundraiseViewDeal from "./pages/company/fundraise/fundraise-view-deal";
import FundraiseOffer from "./pages/company/fundraise/fundraise-offer";

// import PlaidConnect from "./pages/company/plaid";
import { UserContext } from "jsx/contexts/user-context";
import AuthActions from "jsx/pages/auth-actions";
import SecondaryExplainerBuyer from "./pages/company/secondaries/secondary-explainer-buyer";
import SecondaryExplainerSeller from "./pages/company/secondaries/secondary-explainer-seller";
import SellerHelp from "./pages/seller/seller-help";

const queryString = require("query-string");

function RouteRequiresAccreditedInvestor(props) {
  const { user, hasProcessedAuth, admin, selectedOrganization } =
    React.useContext(UserContext);
  const location = useLocation();
  const history = useHistory();
  const queryParams = queryString.parse(props.location.search);

  useEffect(() => {
    if (!user || user.userLoggedOut) {
      if (queryParams.countersign) {
        history.replace({
          pathname: `/org/signin`,
          search:
            `?redirectUrl=${location.pathname}&type=company` + location.search,
        });
      } else {
        history.replace({
          pathname: `/org/signup`,
          search:
            `?redirectUrl=${location.pathname}&type=company` + location.search,
        });
      }
    }
  }, [user, admin]);

  let content = <></>;

  // VU: HACK, it doesn't force KYC if it's a non personal account
  if (selectedOrganization && selectedOrganization !== "personal") {
    content = props.children;
  } else if (!user.emailVerified && !user.debugEmailVerified) {
    content = <Onboarding shouldShowKyc={true} />;
  } else if (!admin?.accreditationStatus) {
    content = <Onboarding shouldShowKyc={true} />;
  } else {
    content = props.children;
  }

  return (
    <>
      {hasProcessedAuth && <Route {...props}>{content}</Route>}
      {!hasProcessedAuth && <Loader />}
    </>
  );
}

function RouteRequiresLogin(props) {
  const { user, hasProcessedAuth, admin } = React.useContext(UserContext);
  const location = useLocation();
  const history = useHistory();
  const queryParams = queryString.parse(props.location.search);

  useEffect(() => {
    if (!user || user.userLoggedOut) {
      if (queryParams.countersign) {
        history.replace({
          pathname: `/org/signin`,
          search:
            `?redirectUrl=${location.pathname}&type=company` + location.search,
        });
      } else {
        history.replace({
          pathname: `/org/signup`,
          search:
            `?redirectUrl=${location.pathname}&type=company` + location.search,
        });
      }
    }
  }, [user, admin]);

  let content = <></>;

  if (!user.emailVerified && !user.debugEmailVerified) {
    content = <Onboarding hideProgressBar={true} />;
  } else {
    content = props.children;
  }

  return (
    <>
      {hasProcessedAuth && <Route {...props}>{content}</Route>}
      {!hasProcessedAuth && <Loader />}
    </>
  );
}

// function RouteRequiresOrganization(props) {
//   const { user } = React.useContext(UserContext);
//   const location = useLocation();

//   let content;

//   if (!user || user.userLoggedOut) {
//     content = (
//       <Redirect
//         to={{
//           pathname: "/org/signin",
//           search: `?redirectUrl=${location.pathname}`,
//         }}
//       />
//     );
//   } else {
//     content = props.children;
//   }

//   return (
//     <>
//       <Route {...props}>content</Route>
//     </>
//   );
// }

class Index extends Component {
  render() {
    return (
      <>
        <BrowserRouter basename={""}>
          {/* <BrowserRouter> */}
          <div id="main-wrapper">
            <Switch>
              <Route path="/" exact>
                <Redirect to="/org/dashboard" />
              </Route>

              <Route path="/secret" exact component={Secret} />
              <Route path="/companies" component={Homepage2} />
              <Route path="/price" component={Price} />
              <Route path="/wallet" component={Wallet} />
              <Route path="/about" component={About} />
              <Route path="/team" component={Team} />
              <Route path="/company/:companyId" component={BrowseCompany} />
              <Route path="/blog" component={Blog} />
              <Route path="/blog-single" component={BlogSingle} />
              <Route path="/career" component={Career} />
              <Route path="/contact" component={Contact} />
              <Route path="/helpdesk" component={HelpDesk} />
              <Route path="/faq" component={Faq} />
              <Route path="/logout" component={Logout} />

              {/* Test */}
              <Route path="/test" component={Test} />

              <Route path="/signTest" component={SignTest} />

              {/* Captable */}
              <RouteRequiresLogin
                path="/captable"
                component={OnboardCompanyDetails}
              />

              {/* Investor */}
              <RouteRequiresLogin
                path="/investor/dashboard"
                component={InvestorDashboard}
              />
              <RouteRequiresAccreditedInvestor
                path="/investor/company/:companyKey/contract/:contractId"
                component={Company}
              />
              <RouteRequiresAccreditedInvestor
                path="/investor/secondaries/:organizationId/:secondaryId/orders/:orderId"
                component={InvestorSecondaryOrder}
              />
              <RouteRequiresLogin
                path="/investor/secondaries/:organizationId/:secondaryId/transactions/:transactionId/documents/:documentId/sign"
                component={CompanySecondariesSignBuyer}
              />
              <RouteRequiresAccreditedInvestor
                path="/investor/secondaries/:organizationId/:secondaryId/transactions/:transactionId"
                component={InvestorSecondaryTransaction}
              />
              <RouteRequiresAccreditedInvestor
                path="/investor/browse"
                component={Browse}
              />
              <RouteRequiresAccreditedInvestor
                path="/investor/accounts"
                component={Accounts}
              />
              <RouteRequiresAccreditedInvestor
                path="/investor/settings"
                component={Settings}
              />
              <RouteRequiresAccreditedInvestor
                path="/investor/settings-preferences"
                component={Preferences}
              />
              <RouteRequiresAccreditedInvestor
                path="/investor/settings-security"
                component={SettingsSecurity}
              />
              <RouteRequiresAccreditedInvestor
                path="/investor/settings-account"
                component={SettingsAccount}
              />
              <RouteRequiresAccreditedInvestor
                path="/investor/add-bank-acc"
                component={AddBankAccount}
              />
              <RouteRequiresAccreditedInvestor
                path="/investor/add-debit-card"
                component={AddDebitCard}
              />
              <RouteRequiresLogin
                path="/org/new"
                component={CompanySetupInfo}
              />
              <RouteRequiresLogin
                path="/org/settings/company"
                component={CompanyEditInfo}
              />
              <RouteRequiresLogin
                path="/org/settings/bank"
                component={SettingsBankConnect}
              />
              <RouteRequiresLogin
                path="/org/settings/team"
                component={SettingsManageTeam}
              />

              {/* Company */}
              <RouteRequiresLogin
                path="/org_old/dashboard"
                component={CompanyDashboard}
              />
              <RouteRequiresLogin
                path="/org/contracts/create/rules"
                component={CompanyContractsCreateRules}
              />
              <RouteRequiresLogin
                path="/org/contracts/create/kyc"
                component={CompanyContractsCreateKYC}
              />
              <RouteRequiresLogin
                path="/org/contracts/create/issue"
                component={CompanyContractsCreateIssue}
              />

              <RouteRequiresLogin
                path="/org/contracts"
                component={CompanyContracts}
              />

              <RouteRequiresLogin
                path="/org/fundraise/manage/create"
                component={CompanyFundraiseManageCreate}
              />

              <RouteRequiresLogin
                path="/org/fundraise/investors"
                component={FundraiseInvestors}
              />
              <RouteRequiresLogin
                path="/org/fundraise/company-info"
                component={CompanyInfo}
              />
              <RouteRequiresLogin
                path="/org/fundraise/risks-and-disclosures"
                component={RisksAndDisclosures}
              />
              <RouteRequiresLogin
                path="/org/fundraise/team-members"
                component={TeamMembers}
              />
              <RouteRequiresLogin
                path="/org/fundraise/e-sign-and-submit"
                component={FinalStep}
              />

              <RouteRequiresLogin
                path="/org/:organizationId/investment/:investmentId/sign"
                component={CompanyFundraiseSign}
              />

              <Route
                path="/org/:organizationId/invite/code/:inviteCode"
                component={TeamInvite}
              />
              <Route
                path="/org/:organizationId/invite/secondary/:secondaryId"
                component={SecondaryInvite}
              />
              <Route
                path="/org/:organizationId/invite/:roundId/:investmentId"
                component={FundraiseInvite}
              />
              <Route
                path="/org/:organizationId/invite/:roundId"
                component={FundraiseInvite}
              />
              <RouteRequiresLogin
                path="/org/fundraise/manage/:roundId/investment/:investmentId"
                component={CompanyFundraiseInvestment}
              />
              <RouteRequiresLogin
                path="/org/fundraise/manage/:roundId/visits/:deckId"
                component={FundraiseInvestorsVisits}
              />
              <RouteRequiresLogin
                path="/org/fundraise/manage/:roundId"
                component={FundraiseInvestors}
              />
              <RouteRequiresLogin
                path="/org/fundraise/manage"
                component={CompanyFundraiseManage}
              />

              <RouteRequiresAccreditedInvestor
                path="/org/:organizationId/fundraise/round/:roundId/offer"
                component={FundraiseOffer}
              />

              <RouteRequiresAccreditedInvestor
                path="/org/:organizationId/secondary/:secondaryId"
                component={CompanySecondaryDataroom}
              />
              <Route
                path="/org/:organizationId/secondaries/:secondaryId/transactions/:transactionId/documents/:documentId/view"
                component={CompanySecondariesDocumentView}
              />
              <RouteRequiresLogin
                path="/org/:organizationId/secondaries/:secondaryId/transactions/:transactionId/documents/:documentId/company_sign"
                component={CompanySecondariesSignCompany}
              />
              <Route
                path="/org/:organizationId/secondaries/:secondaryId/transactions/:transactionId/documents/:documentId/buyer_sign"
                component={CompanySecondariesSignBuyer}
              />
              <Route
                path="/org/:organizationId/secondaries/:secondaryId/transactions/:transactionId/documents/:documentId/spouse_sign"
                component={CompanySecondariesSignSpouse}
              />
              <RouteRequiresLogin
                path="/org/:organizationId/fundraise/round/:roundId"
                component={FundraiseVieDealHack}
              />
              <RouteRequiresLogin
                path="/org/secondaries/manage/create"
                component={CompanySecondariesManageCreate}
              />
              <RouteRequiresLogin
                path="/org/secondaries/manage"
                exact
                component={CompanySecondariesManageList}
              />
              <RouteRequiresLogin
                path="/org/secondaries/manage/:secondaryId/transactions/:transactionId"
                component={CompanySecondariesTransaction}
              />
              <RouteRequiresLogin
                path="/org/secondaries/manage/:secondaryId/overview"
                component={CompanySecondaryManageOverview}
              />
              <RouteRequiresLogin
                path="/org/secondaries/manage/:secondaryId/edit"
                component={CompanySecondariesManageEdit}
              />
              <RouteRequiresLogin
                path="/org/secondaries/manage/:secondaryId/stakeholders"
                component={CompanySecondaryManageEmployees}
              />
              <RouteRequiresLogin
                path="/org/secondaries/manage/:secondaryId/admin"
                component={CompanySecondaryManageAdmin}
              />
              <RouteRequiresLogin
                path="/org/secondaries/manage/:secondaryId/match"
                component={CompanySecondaryManageMatch}
              />
              <RouteRequiresLogin
                path="/org/secondaries/manage/:secondaryId/orders/:orderId"
                component={CompanySecondaryManageOrders}
              />
              <RouteRequiresLogin
                path="/org/secondaries/manage/:secondaryId/orders"
                component={CompanySecondaryManageOrders}
              />
              <RouteRequiresLogin
                path="/org/secondaries/manage/:secondaryId/visits"
                component={CompanySecondaryManageVisits}
              />
              <RouteRequiresLogin
                path="/org/secondaries/stakeholders"
                component={CompanySecondariesStakeholdersManageTable}
              />
              <RouteRequiresLogin
                path="/org/secondaries/modeling"
                component={CompanySecondariesModeling}
              />
              <RouteRequiresLogin
                path="/org/secondaries/how-it-works"
                component={SecondaryExplainerBuyer}
              />
              <RouteRequiresLogin
                path="/org/secondaries/how-to-sell"
                component={SecondaryExplainerSeller}
              />
              {/* Seller */}
              <RouteRequiresLogin
                path="/seller/tender/:secondaryId/orders"
                component={SellerViewOrders}
              />
              <RouteRequiresLogin
                path="/seller/orgs/:organizationId/tender/:secondaryId/dataroom"
                component={SellerDataroom}
              />

              <RouteRequiresLogin
                path="/seller/orgs/:organizationId/tender/:secondaryId/help"
                component={SellerHelp}
              />
              <RouteRequiresLogin
                path="/seller/dashboard"
                component={SellerCompany}
              />

              {/* Tenders */}
              <RouteRequiresLogin
                path="/org/dashboard"
                component={CompanyTenderManageOverview}
              />
              <RouteRequiresLogin
                path="/org/tenders/manage/:secondaryId/participants"
                component={CompanyTenderManageEmployees}
              />
              <RouteRequiresLogin
                path="/org/tenders/manage/:secondaryId/orders"
                component={CompanyTenderManageOrders}
              />
              <RouteRequiresLogin
                path="/org/tenders/manage/:secondaryId/dataroom"
                component={CompanyTenderManageDataroom}
              />
              <RouteRequiresLogin
                path="/org/tenders/manage/:secondaryId/analytics"
                component={CompanyTenderManageAnalytics}
              />
              <RouteRequiresLogin
                path="/org/tenders/manage/:secondaryId/modeling"
                component={CompanyTenderModeling}
              />
              <RouteRequiresLogin
                path="/org/tenders/manage/:secondaryId/communications"
                component={CompanyTenderManageCommunications}
              />

              {/* Password reset */}
              <Route path="/lock" component={Locked} />
              <Route path="/otp-1" component={Otp1} />
              <Route path="/otp-2" component={Otp2} />
              <Route path="/privacy-policy" component={PrivacyPolicy} />
              <Route path="/trusted-contact" component={TrustedContact} />
              <Route path="/non-disclosure" component={NonDisclosure} />
              <Route
                path="/disclaimers-disclosures"
                component={DisclaimersDisclosures}
              />
              <Route path="/reset" component={Reset} />
              <Route path="/auth/action" component={AuthActions} />

              <Route
                path="/org/signin"
                render={(props) => (
                  <Signin {...props} isSignin="true" type="company" />
                )}
              />
              <Route
                path="/org/signup/"
                render={(props) => <Signin {...props} type="company" />}
              />

              <Route
                path="/seller/signin"
                render={(props) => (
                  <Signin {...props} isSignin="true" type="seller" />
                )}
              />
              <Route
                path="/seller/signup/"
                render={(props) => <Signin {...props} type="seller" />}
              />

              <Route
                path="/investor/signin"
                render={(props) => (
                  <Signin {...props} isSignin="true" type="investor" />
                )}
              />

              <Route
                path="/investor/signup"
                render={(props) => (
                  <Signin {...props} isSignin="true" type="investor" />
                )}
              />
              <Route
                path="/onboarding"
                render={(props) => (
                  <Onboarding
                    {...props}
                    showBackButton={true}
                    shouldShowKyc={true}
                  />
                )}
              />
              <Route path="/signup" component={Signup} />
              <Route path="/terms-conditions" component={TermsConditions} />
              <Route path="/terms-condition" component={TermsConditions} />
              <Route path="/verify-step-1" component={VerifyStep1} />
              <Route path="/verify-step-2" component={VerifyStep2} />
              <Route path="/verify-step-3" component={VerifyStep3} />
              <Route path="/verify-step-4" component={VerifyStep4} />
              <Route path="/verify-step-5" component={VerifyStep5} />
              <Route path="/verify-step-6" component={VerifyStep6} />
              <Route path="/history" component={History} />
              <Route path="/demo" component={Demo} />
            </Switch>
          </div>
        </BrowserRouter>
      </>
    );
  }
}

export default Index;
