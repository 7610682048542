import { Skeleton } from "@mui/material";
import { post } from "jsx/api";
import { UserContext } from "jsx/contexts/user-context";
import PDFViewer from "jsx/element/pdf-viewer";
import Header4 from "jsx/layout/header4";
import React, { useEffect, useState } from "react";

export default function SecondariesDocumentView({ match, location }) {
  const { user, hasProcessedAuth } = React.useContext(UserContext);
  const { organizationId, secondaryId, transactionId, documentId } =
    match.params;
  const queryParams = new URLSearchParams(location.search.replace("?", ""));
  const token = queryParams.get("t");

  const [file, setFile] = useState();

  async function fetchDocumentPdf() {
    const params = {
      organizationId: organizationId,
      secondaryId: secondaryId,
      transactionId: transactionId,
      documentId: documentId,
      fileFormat: "pdf",
      token: token,
    };
    const result = await post("secondaries/getTransferDocUrl", params);
    if (result) setFile(result.data);
  }

  useEffect(() => {
    if (hasProcessedAuth && !file) fetchDocumentPdf();
  }, [hasProcessedAuth, file]);

  const isLoaded = file;
  const documentPreview = file ? (
    <PDFViewer showDownloadButton={true} file={file} />
  ) : (
    "loading"
  );

  const loading = (
    <div className="card outlined">
      <Skeleton height={50} />
      <Skeleton height={80} />
      <Skeleton height={80} />
      <Skeleton height={80} />
      <Skeleton height={80} />
      <Skeleton height={80} />
    </div>
  );

  return (
    <>
      {user?.uid && <Header4 hideAllMenuItems />}

      <div
        className={"content-body" + (user?.uid ? " section-padding" : "")}
        style={{ minHeight: "100vh" }}
      >
        <div className="container">
          <div className="row justify-content-center align-items-center">
            <div className="col-12">{isLoaded ? documentPreview : loading}</div>
          </div>
        </div>
      </div>
    </>
  );
}
