import { Field, Form, Formik } from "formik";
import SettingsNav from "jsx/element/fundraise-create-nav";
import PageTitle from "jsx/element/page-title";
import Header4 from "jsx/layout/header4";
import React from "react";

function FinalStep() {
  const companyName = "Modern Share";
  return (
    <>
      <Header4 />
      <PageTitle />
      <Formik
        initialValues={{}}
        onSubmit={async (values) => {
          await new Promise((resolve) => setTimeout(resolve, 500));
        }}
      >
        <Form>
          <div className="content-body">
            <div className="container-fluid">
              <div className="row">
                <div className="col-md-2">
                  <SettingsNav />
                </div>
                <div className="col-xl-9 col-md-8">
                  <div className="row">
                    <div className="col-xl-6">
                      <div className="card">
                        <div className="card-header">
                          <h4 className="card-title">E-Sign & Submit</h4>
                        </div>
                        <div className="card-body">
                          <div className="row">
                            <div className="mb-4 col-xl-12">
                              <h5 className="mb-0 mt-3">
                                How can investors in the RUV be helpful?
                              </h5>
                              <small>(Optional)</small>
                              <div
                                role="group"
                                className="d-flex flex-column mt-2"
                                aria-labelledby="checkbox-group"
                              >
                                <label>
                                  <Field
                                    className="form-check-input"
                                    type="checkbox"
                                    name="RUV"
                                    value="referTechnical"
                                  />
                                  Refer technical talent to {companyName}.
                                </label>
                                <label>
                                  <Field
                                    className="form-check-input"
                                    type="checkbox"
                                    name="RUV"
                                    value="referPotentialCostumers"
                                  />
                                  Refer potential customers to {companyName}.
                                </label>
                                <label>
                                  <Field
                                    className="form-check-input"
                                    type="checkbox"
                                    name="RUV"
                                    value="connectionToExperiencedOperators"
                                  />
                                  Connections to experienced operators in the
                                  same industry as {companyName}.
                                </label>
                                <label>
                                  <Field
                                    className="form-check-input"
                                    type="checkbox"
                                    name="RUV"
                                    value="expertiseWithRegulatory"
                                  />
                                  Expertise with regulatory or legal
                                  constraints.
                                </label>
                                <label>
                                  <Field
                                    className="form-check-input"
                                    type="checkbox"
                                    name="RUV"
                                    value="adviceFromMarketing"
                                  />
                                  Advice from marketing, go to market, and
                                  growth experts.
                                </label>
                                <label>
                                  <Field
                                    className="form-check-input"
                                    type="checkbox"
                                    name="RUV"
                                    value="connectionsToPressAndInfluencers"
                                  />
                                  Connections to press and influencers.
                                </label>
                              </div>
                            </div>
                            <div className="mb-4 col-xl-12">
                              <h5 className="mb-0 mt-3">Closing Terms</h5>
                              <div
                                role="group"
                                className="d-flex flex-column mt-2"
                                aria-labelledby="checkbox-group"
                              >
                                <label>
                                  <Field
                                    className="form-check-input"
                                    type="checkbox"
                                    name="closing-terms"
                                    value="acknowledgeIamAFounder"
                                  />
                                  I acknowledge that I am a founder (or
                                  authorized representative) of {companyName}.
                                </label>
                                <label>
                                  <Field
                                    className="form-check-input"
                                    type="checkbox"
                                    name="closing-terms"
                                    value="option2"
                                  />
                                  A fund will pool investors’ capital to invest
                                  in {companyName} as a single entity.
                                </label>
                                <label>
                                  <Field
                                    className="form-check-input"
                                    type="checkbox"
                                    name="closing-terms"
                                    value="option3"
                                  />
                                  For No-Fee RUVs, Modern Share will send the
                                  company an invoice for the underlying state
                                  filing costs.
                                </label>
                                <label>
                                  <Field
                                    className="form-check-input"
                                    type="checkbox"
                                    name="closing-terms"
                                    value="IamNotAnBadActor"
                                  />
                                  I am not (and none of the executives of the
                                  company are) considered a bad actor according
                                  to the SEC (i.e., I have never breached
                                  securities law)
                                </label>
                              </div>
                            </div>
                            <button type="submit">Submit</button>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </Form>
      </Formik>
    </>
  );
}

export default FinalStep;
