import { duotone } from "@fortawesome/fontawesome-svg-core/import.macro";
import { DataGridPro } from "@mui/x-data-grid-pro";
import { post } from "jsx/api";
import EmptyState from "jsx/element/empty-state";
import { titleCase } from "jsx/helpers";
import moment from "moment";
import React from "react";

import MSButton from "./button";

export default function SecondaryOrderTable({
  secondaryOrders,
  highlightedOrderId,
  secondaryId,
  organizationId,
  isAdmin,
  orderBook,
  loading,
}) {
  let columns = [];

  if (!orderBook) {
    columns.push({ field: "name", headerName: "Name", flex: 140 });
    columns.push({
      field: "certificateNo",
      headerName: "Security",
      flex: 110,
      valueFormatter: ({ value }) => value ?? "-",
    });
  }

  columns = columns.concat([
    {
      field: "orderType",
      headerName: "Order",
      flex: 60,
      valueFormatter: ({ value }) => value && titleCase(value),
    },
    {
      field: "quantity",
      headerName: "Quantity",
      type: "number",
      flex: 80,
      valueFormatter: ({ value }) => value && value.toLocaleString(),
    },
    {
      field: "pricePerShare",
      headerName: "Price",
      type: "number",
      align: "right",
      headerAlign: "right",
      flex: 80,
      valueFormatter: ({ value }) =>
        value &&
        value.toLocaleString("en-US", {
          style: "currency",
          currency: "USD",
        }),
    },
    {
      field: "total",
      headerName: "Total",
      type: "number",
      flex: 100,
      valueFormatter: ({ value }) =>
        value &&
        value.toLocaleString("en-US", {
          style: "currency",
          currency: "USD",
        }),
    },
    {
      field: "updatedDate",
      headerName: "Updated",
      type: "datetime",
      flex: 120,
      align: "right",
      headerAlign: "right",
      valueFormatter: ({ value }) =>
        value && moment(value.toDate()).format("M/D/YYYY"),
    },
    {
      field: "status",
      headerName: "Status",
      flex: 80,
      valueFormatter: ({ value }) => value && titleCase(value),
      align: "center",
      headerAlign: "center",
      renderCell: (cellValues) => {
        return <div className="pill py-1">{cellValues.formattedValue}</div>;
      },
    },
  ]);

  if (isAdmin) {
    columns.push({ field: "userId", headerName: "ID", flex: 60 });
    columns.push({
      field: "action",
      headerName: " ",
      flex: 120,
      disableClickEventBubbling: true,
      renderCell: (params) => {
        // const order = secondaryOrders.find(
        //   (empl) => empl.orderId === params.row.id
        // );
        return (
          <CancelButton
            secondaryId={secondaryId}
            organizationId={organizationId}
            orderId={params.row.id}
          />
        );
      },
    });
  }

  const rows = secondaryOrders.map((order, id) => {
    const {
      name,
      quantity,
      status,
      orderType,
      updatedDate,
      pricePerShareInBaseUnits,
      pricePerSharePrecision,
      securityDetails,
      userId,
    } = order;
    const total =
      (pricePerShareInBaseUnits * quantity) / pricePerSharePrecision;
    return {
      id: order.id,
      userId,
      name,
      quantity,
      total,
      pricePerShare: pricePerShareInBaseUnits / pricePerSharePrecision,
      orderType,
      status,
      certificateNo: securityDetails?.certificateNo,
      updatedDate,
    };
  });

  let selectionModel = [];

  if (highlightedOrderId) {
    const orderIndex = secondaryOrders.findIndex((e) => {
      return e.id === highlightedOrderId;
    });

    if (orderIndex > -1) {
      selectionModel = [orderIndex];
    }
  }

  return (
    <>
      <div>
        <div style={{ width: "100%" }}>
          <DataGridPro
            density={orderBook ? "compact" : "standard"}
            rows={rows}
            columns={columns}
            pageSize={100}
            disableColumnMenu
            selectionModel={selectionModel}
            hideFooter={true}
            autoHeight={true}
            key={loading}
            initialState={{
              sorting: {
                sortModel: [{ field: "pricePerShare", sort: "desc" }],
              },
            }}
            components={{
              NoRowsOverlay: CustomNoRowsOverlay,
            }}
            sx={{
              borderColor: "var(--purple-light)",
              minHeight: 400,

              "& .MuiDataGrid-cell": {
                borderBottomColor: "var(--purple-light)",
                fontFamily: "var(--primary-font)",
                fontSize: 14,
                fontWeight: 400,
              },
              "& .MuiDataGrid-columnHeaderTitle": {
                fontSize: 13,
                fontFamily: "var(--primary-font)",
                color: "#6c757d",
              },
              "& .MuiDataGrid-columnHeaders": {
                borderBottomColor: "var(--purple-light)",
              },
              "& .MuiDataGrid-row:hover": {
                background: "#FFF",
                // background: "var(--background)",
              },
              "& .MuiDataGrid-columnSeparator": {
                visibility: "hidden",
              },
              "& .MuiDataGrid-cell:focus": {
                outline: "none",
              },
              "& .MuiDataGrid-columnHeader:focus": {
                outline: "none",
              },
              "& .MuiDataGrid-columnHeader:focus-within": {
                outline: "none",
              },
              "& .MuiDataGrid-cell:focus-within": {
                outline: "none",
              },
            }}
          />
        </div>
      </div>
    </>
  );
}

function CancelButton({ orderId, secondaryId, organizationId }) {
  return (
    <>
      <MSButton
        size="small"
        onClick={() => {
          post("secondaries/cancelOrder", {
            secondaryOrderId: orderId,
            secondaryId,
            organizationId,
          });
        }}
      >
        Cancel
      </MSButton>
    </>
  );
}

function CustomNoRowsOverlay() {
  return (
    <div
      style={{
        height: "100%",
      }}
    >
      <EmptyState
        icon={duotone("rectangle-history-circle-user")}
        title={"No orders to display"}
        subtitle={
          "When buyers and sellers put in their orders, it will appear here."
        }
      />
    </div>
  );
}
