import React from "react";
import { Link, useHistory } from "react-router-dom";

import routes from "./routes.json";

function CommonSharesView({ data, show }) {
  const history = useHistory();
  if (!show) {
    history.push(routes.root);
  }
  return (
    <>
      {show && (
        <>
          <div className="card-body d-flex flex-column">
            {data.map((item) => {
              return <Card item={item} />;
            })}
          </div>
        </>
      )}
    </>
  );
}
function Card({ item }) {
  const rootId = item.id;
  return (
    <div className="card-main mb-4">
      <div className="row p-4">
        <div className="col">
          <h4 className="mb-0">{item.shareClassName}</h4>
          <code>{item.id}</code>
        </div>
        <div className="col d-flex flex-row justify-content-end align-items-center">
          <span className="text-primary">Edit</span>
        </div>
      </div>
      <div className="row p-4">
        <div className="col d-flex flex-column">
          <h5>Authorized shares:</h5>
          {item.authorizedShares}
        </div>
        <div className="col d-flex flex-column">
          <h5>Authorized date:</h5>
          {item.authorizedDate}
        </div>
        <div className="col d-flex flex-column">
          <h5>Par value:</h5>
          {item.parValue}
        </div>
      </div>
      {item.shares.map((item) => {
        return (
          <div className="row mb-3 card-outlined">
            <div className="col d-flex flex-column justify-content-center">
              <h6>Shareholder name:</h6>
              <span>
                {item.stackHolderType === "individual"
                  ? `${item.firstName} ${item.lastName}`
                  : item.organizationName}
              </span>
            </div>
            <div className="col d-flex flex-column justify-content-center">
              <h6>Number of shares:</h6>
              <span>{item.numberOfShares}</span>
            </div>
            <div className="col d-flex flex-column justify-content-center">
              <h6>Issue date:</h6>
              <span>Issue date: {item.issueDate}</span>
            </div>
            <div className="col-1 d-flex align-items-center flex-row justify-content-end">
              <Link
                to={`/captable/common-shares/edit-share/${rootId}/${item.id}`}
                className="text-primary"
              >
                Edit
              </Link>
            </div>
          </div>
        );
      })}
      <div className="p-4">
        <Link to={`add-shares?id=${item.id}`}>Add shares</Link>
      </div>
    </div>
  );
}
export default CommonSharesView;
