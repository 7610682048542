import SignatureField from "jsx/element/signature-field";
import React, { useState } from "react";

function SignTest() {
  const [signatureImage, setSignatureImage] = useState();

  console.log("test", signatureImage);
  return (
    <>
      <div style={{ margin: 16 }}>
        <SignatureField
          onChange={(e) => {
            setSignatureImage(e);
          }}
        />

        <img alt="" src={signatureImage} />
      </div>
    </>
  );
}

export default SignTest;
