import { Field, Form, Formik } from "formik";
import { post } from "jsx/api";
import MSButton from "jsx/element/button";
import React, { useState } from "react";
import { Link } from "react-router-dom";
import * as Yup from "yup";

const SigningSchema = Yup.object().shape({
  signatureName: Yup.string().required(
    "Your signature is required for confirmation"
  ),
});

export default function SellerTenderSign({
  organizationId,
  secondaryId,
  orderPreview,
  onSubmit,
}) {
  const [signed, setSigned] = useState(false);
  const [lotUrl, setLotUrl] = useState();
  const [isLoadingView, setIsLoadingView] = useState(false);
  const securityPriorities = orderPreview?.securities.map(
    ({ quantity, securityId, priority }) => ({
      securityId,
      quantity,
      priority: priority ?? null,
    })
  );

  async function createLoTVersion({
    organizationId,
    secondaryId,
    securityPriorities,
    sellerSignatureInput,
  }) {
    await post("secondaries/createLetterOfTransmittalVersion", {
      organizationId,
      secondaryId,
      ...(securityPriorities && { securityPriorities }),
      ...(sellerSignatureInput && { sellerSignatureInput }),
    });
    let stale = true;
    while (stale) {
      const result = await getLoTVersion({ secondaryId });
      if (result.freshVersionPdf) {
        stale = false;
        return result.pdfUrl;
      }
      await new Promise((resolve) => setTimeout(resolve, 1500));
    }
  }

  async function getLoTVersion({ secondaryId }) {
    const result = await post("secondaries/getLetterOfTransmittal", {
      secondaryId,
    });
    return result.data;
  }

  async function fetchFile(url) {
    const result = await fetch(url);
    const file = await result.blob();
    const tempUrl = window.URL.createObjectURL(file);
    const tag = document.createElement("a");
    tag.href = tempUrl;
    tag.download = "Letter-Of-Transmittal-filled-formatted.pdf";
    document.body.appendChild(tag);
    tag.click();
    window.URL.revokeObjectURL(tempUrl);
    tag.remove();
  }

  return (
    <>
      <Formik
        enableReinitialize
        initialValues={{ signatureName: "" }}
        onSubmit={async (values) => {
          const url = await createLoTVersion({
            organizationId,
            secondaryId,
            securityPriorities,
            sellerSignatureInput: values.signatureName,
          });
          setLotUrl(url);
          setSigned(true);
          onSubmit();
        }}
        validationSchema={SigningSchema}
      >
        {({
          values,
          isSubmitting,
          setFieldValue,
          setFieldTouched,
          errors,
          touched,
        }) => (
          <Form>
            <div className="content-body">
              <div className="container mb-5">
                <div className="row justify-content-center align-items-center">
                  <div className="row">
                    <h4 className="form-section-header mt-4">Documents</h4>

                    <ul className="fundraising-rounds mt-4">
                      <div className="outlined-small" style={{ padding: 16 }}>
                        <div className="d-flex">
                          <div className="flex-grow-1">
                            <h5 className="mb-1">Letter of Transmittal</h5>
                            Letter-Of-Transmittal-filled-formatted.pdf
                          </div>
                          <div className="edit-option">
                            <MSButton
                              size="small"
                              title="View"
                              loadingLabel="Processing"
                              loading={isLoadingView}
                              disabled={isLoadingView}
                              onClick={async () => {
                                setIsLoadingView(true);
                                let url;
                                if (lotUrl) {
                                  url = lotUrl;
                                } else {
                                  const lotUrl = await createLoTVersion({
                                    organizationId,
                                    secondaryId,
                                    securityPriorities,
                                  });
                                  setLotUrl(lotUrl);
                                  url = lotUrl;
                                }
                                setIsLoadingView(false);
                                await fetchFile(url);
                              }}
                              className="me-2"
                            />
                          </div>
                        </div>
                      </div>
                    </ul>
                    {signed && (
                      <div className="mb-0 col-xl-12">
                        <p className="mb-3 mt-4 text-primary">
                          Your order has been signed and submitted. You can find
                          your order details on the main page. If you wish to
                          make any modifications to the order, you may cancel
                          and resubmit.
                        </p>
                        <Link
                          to={`/seller/tender/${secondaryId}/orders`}
                          className="btn btn-primary"
                        >
                          View order
                        </Link>
                      </div>
                    )}
                    {!signed && (
                      <>
                        <h4 className="form-section-header">
                          Create your electronic signature
                        </h4>

                        <h6 className="form-label mb-4">
                          Enter your full name to sign the document.
                        </h6>

                        <div className="mb-0 col-xs-12">
                          <Field
                            type="text"
                            autoComplete="off"
                            autoCorrect="off"
                            autoCapitalize="off"
                            spellCheck="false"
                            name="signatureName"
                            className={
                              "signature-block w-100 form-control " +
                              (touched.signatureName &&
                                errors.signatureName &&
                                "is-invalid")
                            }
                            placeholder="First Last"
                            onChange={(e) =>
                              setFieldValue("signatureName", e.target.value)
                            }
                          />
                        </div>

                        <div className="mb-0 col-xl-12">
                          <p className="smaller mb-3 mt-4">
                            By clicking the button below: I certify that I
                            consent to conduct all affairs relating to the
                            documents listed above electronically. I further
                            certify that I have electronically accessed the
                            documents listed above, and that I have read them. I
                            adopt the above electronic signature as my
                            signature, and hereby electronically sign the
                            documents listed above. I acknowledge that I have
                            accessed, have read and hereby agree to the Modern
                            Share Terms of Service, and that I authorize the
                            Modern Share services, in the manner designated
                            therein, to process the documents and signatures
                            provided herewith and to create, store, and
                            communicate electronic records of the documents
                            listed above.
                          </p>

                          <MSButton
                            className="w-100"
                            type="submit"
                            loading={isSubmitting}
                            disabled={Object.keys(errors).length > 0}
                            title="Sign"
                            loadingLabel="Signing"
                            variant="primary"
                          />
                        </div>
                      </>
                    )}
                  </div>
                </div>
              </div>
            </div>
          </Form>
        )}
      </Formik>
    </>
  );
}
