import { regular } from "@fortawesome/fontawesome-svg-core/import.macro";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { InputAdornment } from "@mui/material";
import Skeleton from "@mui/material/Skeleton";
import Table from "@mui/material/Table";
import TableBody from "@mui/material/TableBody";
import TableCell from "@mui/material/TableCell";
import TableContainer from "@mui/material/TableContainer";
import TableHead from "@mui/material/TableHead";
import TableRow from "@mui/material/TableRow";
// import rescalePdf2 from "documents/rescale.pdf";
import rescaleNda from "documents/rescale_nda.pdf";
import rescalePdf from "documents/rescale_overview.pdf";
import rescaleCapTable from "documents/rescale_summary_cap_table_12.6.22.pdf";
// import Slider from "@mui/material/Slider";
import {
  collection,
  doc,
  getDoc,
  getFirestore,
  onSnapshot,
  serverTimestamp,
} from "firebase/firestore";
import { Form, Formik } from "formik";
import { get, post } from "jsx/api";
import FundraisingChart from "jsx/charts/fundraise-chart";
import { UserContext } from "jsx/contexts/user-context";
import MSButton from "jsx/element/button";
import Divider from "jsx/element/divider";
import FundraisingTable from "jsx/element/fundraising-table";
import MSField from "jsx/element/ms-field";
import NumberFormatted from "jsx/element/number-formatted";
import PDFViewer from "jsx/element/pdf-viewer";
import SecondaryOrderTable from "jsx/element/secondary-order-table";
import { formatPrice } from "jsx/helpers";
import Header5 from "jsx/layout/header5";
import moment from "moment";
import React, { useEffect, useMemo, useRef, useState } from "react";
import { Modal } from "react-bootstrap";
import { useMediaQuery } from "react-responsive";
import { useParams } from "react-router-dom";
import { Link } from "react-router-dom";
import * as Yup from "yup";

import SecondaryExplainerBuyerContent from "./secondary-explainer-buyer-content";

function CompanySecondaryDataroom() {
  const { user } = React.useContext(UserContext);
  const [secondary, setSecondary] = useState({});
  const [order, setOrder] = useState();
  const [orders, setOrders] = useState();
  const [secondaryOrders, setSecondaryOrders] = useState([]);
  const [fundraisingData, setFundraisingData] = useState();
  const [companyDetails, setCompanyDetails] = useState();
  const [isFetchingOrders, setFetchingOrders] = useState(false);
  const [needsNDA, setNeedsNDA] = useState(true);
  const [isAccepting, setIsAccepting] = useState(false);

  const [loading, setIsLoading] = useState(true);
  const [pricePerShare, setPricePerShare] = useState();
  const [isInReview, setIsInReview] = useState(false);
  const [sharesToBuy, setSharesToBuy] = useState();
  const [isPlacingOrder, setIsPlacingOrder] = useState(false);
  const [isShowingOrderConfirmation, setShowingOrderConfirmation] =
    useState(false);
  const [hasAgreedToTerms, setHasAgreedToTerms] = useState(false);

  const [decks, setDecks] = useState([]);

  const { organizationId, secondaryId } = useParams();
  const [modalOpen, setModalOpen] = useState(false);

  const fundraisingSectionElement = useRef();

  const graphData = useMemo(() => {
    if (!fundraisingData) {
      return [[], []];
    }
    const formattedData = [];
    const annotations = [];

    fundraisingData.forEach((e) => {
      const dataPoint = [
        moment(e.funding_date, "MMM YYYY").toDate().getTime(),
        e.share_type,
        e.humanized_money_raised_dollars,
        e.humanized_implied_valuation_dollars,
        parseFloat(e.humanized_share_price_dollars.split("$")[1]),
      ];

      const annotation = [
        moment(e.funding_date, "MMM YYYY").toDate().getTime(),
        parseFloat(e.humanized_share_price_dollars.split("$")[1]),
        e.share_type,
      ];

      formattedData.push(dataPoint);
      annotations.push(annotation);
    });
    return [formattedData, annotations];
  }, [fundraisingData]);

  // TODO (@paulcretu) make sure data is sorted
  const latestRound = fundraisingData?.[0];

  const endDate = secondary?.buyerEndDate ?? secondary?.endDate;
  const expired = endDate
    ? new Date() >= new Date(endDate.seconds * 1000)
    : false;

  const isMobile = useMediaQuery({ minWidth: 0, maxWidth: 500 });

  const isAutomatedMatching =
    secondary.fillType === "autofill" ||
    secondary.orderBookVisibility === "public";

  const db = getFirestore();

  useEffect(() => {
    if (user.uid) {
      post(`followSecondary`, { organizationId, secondaryId });
    }
    fetchFundraisingData();

    checkIfNDANeeded();
  }, []);

  async function checkIfNDANeeded() {
    const res = await getDoc(
      doc(
        db,
        "users",
        user.uid,
        "users_private",
        "data",
        "agreements",
        "organizations",
        organizationId,
        "secondary",
        secondaryId,
        "nda"
      )
    );
    if (res.exists && res.data() && res.data().agreed) {
      setNeedsNDA(false);
      setIsAccepting(false);
    } else {
      setNeedsNDA(true);
    }
  }

  async function fetchFundraisingData() {
    const result = await get(`data/fetch`, {
      company: "rescale",
    });

    const funding = [
      {
        share_type: "SAFE 2",
        funding_date: "Dec 2021",
        humanized_implied_valuation_dollars: "$1.7B*",
        humanized_share_price_dollars: "$48.57*",
      },
      {
        share_type: "SAFE 1",
        funding_date: "Sep 2021",
        humanized_implied_valuation_dollars: "$1.4B*",
        humanized_share_price_dollars: "$40.00*",
      },
      {
        share_type: "Series C-1",
        funding_date: "Jun 2021",
        humanized_implied_valuation_dollars: "$685MM",
        humanized_share_price_dollars: "$19.08",
      },
      {
        share_type: "Series C",
        funding_date: "Dec 2020",
        humanized_implied_valuation_dollars: "$420MM",
        humanized_share_price_dollars: "$12.01",
      },
      {
        share_type: "Series B",
        funding_date: "Apr 2018",
        humanized_implied_valuation_dollars: "$205MM",
        humanized_share_price_dollars: "$6.15",
      },
      {
        share_type: "Series A + Seed",
        funding_date: "Jul 2016",
        humanized_implied_valuation_dollars: "$71MM",
        humanized_share_price_dollars: "$2.39",
      },
    ];

    // funding_date | fmt: "Jul 2022"
    // share_type | ""
    // humanized_money_raised_dollars
    // humanized_implied_valuation_dollars
    // humanized_share_price_dollars
    setFundraisingData(funding);
    setCompanyDetails(result.data.details);
  }

  async function fetchOrders() {
    setFetchingOrders(true);
    onSnapshot(
      collection(
        db,
        `organizations/${organizationId}/organizations_admin/data/secondaries/${secondaryId}/secondary_orders`
      ),
      (snapshot) => {
        const secondaryOrders = snapshot.docs.map((doc) => ({
          id: doc.id,
          ...doc.data(),
        }));
        setSecondaryOrders(secondaryOrders);
        setFetchingOrders(false);
      }
    );
  }

  async function fetchOpenOrders() {
    if (!user.uid) {
      return;
    }

    const params = {
      secondaryId: secondaryId,
      organizationId,
    };

    const fetchOrderRequest = await get(`secondaries/listOrders`, params);
    const orders = fetchOrderRequest.data.secondaryOrders;
    orders.sort((a, b) =>
      a.createdDate._seconds < b.createdDate._seconds ? 1 : -1
    );

    if (orders.length > 0) {
      setOrder(orders[0]);
    }

    setOrders(orders);
  }

  const transactionFee = 0.02;

  let dealContent;

  if (isInReview) {
    dealContent = (
      <>
        <h3 className="mb-4">Review buy order</h3>

        <Detail title="Shares requested">
          {parseInt(sharesToBuy).toLocaleString()}
        </Detail>
        <Detail title="Price per share">{formatPrice(pricePerShare, 1)}</Detail>
        <Detail title="Sale total">
          {formatPrice(sharesToBuy * pricePerShare, 1)}
        </Detail>
        <Detail title="Fees (2%)">
          {formatPrice(sharesToBuy * pricePerShare * transactionFee, 1)}
        </Detail>
        <div className="pb-2">
          <Divider />
        </div>
        <Detail title="Total" titleSize={16} detailSize={16}>
          {formatPrice(sharesToBuy * pricePerShare * (1 + transactionFee), 1)}
        </Detail>

        <div className="mb-0 col-xl-12">
          <h6 className="form-label mt-3 mb-3">
            Please read and agree to the following below.
          </h6>
          <ol className="rules ms-4" style={{ fontSize: 12 }}>
            <li>
              I am an{" "}
              <a
                style={{ textDecoration: "underline" }}
                target="_blank"
                rel="noopener noreferrer"
                href="https://www.investor.gov/introduction-investing/general-resources/news-alerts/alerts-bulletins/investor-bulletins/updated-3"
              >
                accredited investor
              </a>
              .
            </li>
            <li>
              I understand that most investments in startups result in a
              complete loss.
            </li>
            <li>
              I understand that Modern Share does not guarantee the accuracy of
              information on the site, and I am responsible for my own
              diligence.
            </li>
            <li>
              I promise to hold Modern Share harmless against any damage that
              may happen to myself as a result of my use of Modern Share.
            </li>
            <li>
              If I invest, I will comply with securities laws and consult my own
              attorney and professional advisors where I need advice.
            </li>
            <li>
              I understand that I will be permanently banned from Modern Share
              and that I may face legal consequences if I falsely represent my
              accreditation status.
            </li>
            <li>
              I acknowledge and agree that, following the completion of this
              transaction, I will pay Modern Share a transaction fee of up to
              five percent (5%) of the gross value of each such completed
              transaction.
            </li>
            <li>
              I have read Modern Share's{" "}
              <Link
                style={{ textDecoration: "underline" }}
                to="/disclaimers-disclosures"
              >
                disclosures and disclaimers
              </Link>
              .
            </li>
          </ol>
          <div className="form-check">
            <label className={"agree-text"} style={{ fontSize: 14 }}>
              <input
                className="form-check-input"
                type="checkbox"
                value=""
                style={{ fontSize: 16 }}
                onChange={() => {
                  setHasAgreedToTerms(!hasAgreedToTerms);
                }}
              />
              I agree to the terms above and to{" "}
              <Link
                style={{ textDecoration: "underline" }}
                to="/terms-condition"
              >
                Modern Share's terms and conditions
              </Link>
              .
            </label>
          </div>
        </div>

        <>
          <MSButton
            title={`Confirm Buy Limit Order`}
            className="w-100 mt-4"
            variant="primary"
            size="large"
            loading={isPlacingOrder}
            onClick={async () => {
              await createBuyOrder();
            }}
            disabled={!hasAgreedToTerms}
          />
          <MSButton
            title={`Edit`}
            className="w-100 mt-3"
            variant="secondary"
            size="large"
            onClick={async () => {
              setIsInReview(false);
            }}
          />
        </>
      </>
    );
  } else if (isShowingOrderConfirmation) {
    dealContent = (
      <>
        <h3 className="mb-4">Order confirmed</h3>
        <Detail title="Order status">{order.status}</Detail>
        <Detail title="Date submitted">
          {new Date(order.createdDate._seconds * 1000).toLocaleString()}
        </Detail>

        <Detail title="Price per share">
          {formatPrice(
            order.pricePerShareInBaseUnits,
            order.pricePerSharePrecision
          )}
        </Detail>

        <Detail title="Shares requested">{order.quantity}</Detail>

        <Detail title="Sale total">
          {formatPrice(
            (order.pricePerShareInBaseUnits / order.pricePerSharePrecision) *
              order.quantity,
            1
          )}
        </Detail>
        <Detail title="Fees (2%)">
          {formatPrice(
            (order.pricePerShareInBaseUnits / order.pricePerSharePrecision) *
              order.quantity *
              transactionFee,
            1
          )}
        </Detail>
        <Detail title="Total">
          {formatPrice(
            (order.pricePerShareInBaseUnits / order.pricePerSharePrecision) *
              order.quantity *
              (1 + transactionFee),
            1
          )}
        </Detail>
        <div className="w-100 py-4">
          <Divider />
        </div>

        <MSButton
          title="Done"
          className="w-100"
          size="large"
          variant="secondary"
          onClick={() => {
            setShowingOrderConfirmation(false);
            setIsPlacingOrder(false);
            setSharesToBuy();
            setIsInReview(false);
            setPricePerShare();
          }}
        />
      </>
    );
  } else if (!expired) {
    dealContent = (
      <>
        <Formik
          initialValues={{
            quantity: sharesToBuy ?? "",
            pricePerShare: pricePerShare ?? "",
          }}
          onSubmit={async (values) => {
            setIsInReview(true);
          }}
          validationSchema={Yup.object().shape({
            pricePerShare: Yup.number()
              .label("Price")
              .min(secondary.minPriceInBaseUnits / secondary.minPricePrecision)
              .max(secondary.maxPriceInBaseUnits / secondary.maxPricePrecision)
              .required("Required"),
            quantity: Yup.number()
              .label("Quantity")
              .min(1)
              .max(secondary.maxSharesAvailableForPurchase)
              .required("Required"),
          })}
        >
          {(formik) => {
            const { setFieldValue, isSubmitting } = formik;
            const noSharesAvailable = !secondary.maxSharesAvailableForPurchase;

            let orderSectionType;

            // Buy order, no limit
            if (secondary.pricePerShareInBaseUnits) {
              orderSectionType = (
                <>
                  <h3>Enter a buy order</h3>
                  <h5 className="pt-3">Quantity of shares to buy</h5>

                  {!isAutomatedMatching && (
                    <p className="small mb-2">
                      There are{" "}
                      {(
                        secondary.maxSharesAvailableForPurchase ?? 0
                      ).toLocaleString()}{" "}
                      total shares for sale at {roundPricePerShare} per share
                    </p>
                  )}
                  <div>
                    <NumberFormatted
                      name="quantity"
                      label="Shares To Buy"
                      autoComplete="off"
                      customInput={MSField}
                      placeholder="0"
                      className="mt-2"
                      allowNegative={false}
                      isNumericString={true}
                      thousandSeparator={true}
                      defaultValue={sharesToBuy}
                      onValueChange={(val) => {
                        setSharesToBuy(val.value);
                        setFieldValue("quantity", Number(val.value));
                      }}
                      formik={formik}
                      inputProps={{
                        style: { textAlign: "right" },
                      }}
                      InputProps={{
                        endAdornment: (
                          <InputAdornment position="end">shares</InputAdornment>
                        ),
                      }}
                    />
                  </div>
                </>
              );
            } else if (
              secondary.minPriceInBaseUnits &&
              secondary.maxPriceInBaseUnits
            ) {
              orderSectionType = (
                <>
                  <h3>Enter a buy limit order</h3>

                  <h5 className="pt-3">Quantity of shares to buy</h5>

                  {!isAutomatedMatching && (
                    <p className="small mb-2">
                      There are{" "}
                      {(
                        secondary.maxSharesAvailableForPurchase ?? 0
                      ).toLocaleString()}{" "}
                      total shares for sale
                    </p>
                  )}
                  <div>
                    <NumberFormatted
                      name="quantity"
                      label="Shares To Buy"
                      autoComplete="off"
                      customInput={MSField}
                      placeholder="0"
                      className="mt-2"
                      allowNegative={false}
                      isNumericString={true}
                      thousandSeparator={true}
                      defaultValue={sharesToBuy}
                      onValueChange={(val) => {
                        setSharesToBuy(val.value);
                        setFieldValue("quantity", val.value);
                      }}
                      formik={formik}
                      inputProps={{
                        style: { textAlign: "right" },
                      }}
                      InputProps={{
                        endAdornment: (
                          <InputAdornment position="end">shares</InputAdornment>
                        ),
                      }}
                    />
                  </div>

                  <h5 className="pt-4">Limit Price</h5>
                  <div>
                    <NumberFormatted
                      customInput={MSField}
                      name="pricePerShare"
                      autoComplete="off"
                      placeholder="0"
                      allowNegative={false}
                      isNumericString={true}
                      thousandSeparator={true}
                      defaultValue={pricePerShare}
                      onValueChange={(val) => {
                        setPricePerShare(val.floatValue);
                        setFieldValue("pricePerShare", val.floatValue);
                      }}
                      formik={formik}
                      inputProps={{
                        style: { textAlign: "right" },
                      }}
                      InputProps={{
                        startAdornment: (
                          <InputAdornment position="start">$</InputAdornment>
                        ),
                      }}
                    />
                  </div>
                </>
              );
            }

            return (
              <Form>
                {orderSectionType}
                <div className="mt-4">
                  <Detail title="Total">
                    {pricePerShare && sharesToBuy
                      ? formatPrice(pricePerShare * sharesToBuy, 1)
                      : "$0"}
                  </Detail>
                </div>

                <>
                  <MSButton
                    loading={isSubmitting}
                    disabled={noSharesAvailable}
                    title={
                      noSharesAvailable ? "No shares available" : "Review Order"
                    }
                    className="w-100"
                    type="submit"
                    variant="primary"
                    size="large"
                  />
                </>

                <Link
                  to={"#"}
                  onClick={() => {
                    setModalOpen(true);
                  }}
                >
                  <p className="small text-decoration-underline text-center mt-3">
                    How does this work?
                  </p>
                </Link>
              </Form>
            );
          }}
        </Formik>
      </>
    );
  } else if (expired) {
    dealContent = <p>The response deadline has passed.</p>;
  }

  let roundPricePerShare;

  if (
    (secondary.minPriceInBaseUnits && secondary.maxPriceInBaseUnits) ||
    secondary.pricePerShareInBaseUnits
  ) {
    const formattedMinPricePerShare = formatPrice(
      secondary.minPriceInBaseUnits,
      secondary.minPricePrecision
    );
    // const formattedMaxPricePerShare = formatPrice(
    //   secondary.maxPriceInBaseUnits,
    //   secondary.maxPricePrecision
    // );

    if (secondary.pricePerShareInBaseUnits) {
      roundPricePerShare = formatPrice(
        secondary.pricePerShareInBaseUnits,
        secondary.pricePerSharePrecision
      );
    } else if (secondary.minPriceInBaseUnits && secondary.maxPriceInBaseUnits) {
      // Comment out for now until we decide to show the full range
      // roundPricePerShare = `${formattedMinPricePerShare} - ${formattedMaxPricePerShare}`;
      roundPricePerShare = formattedMinPricePerShare;
    }
  }

  const dealOverview = (
    <>
      <h3 className="mb-3">Overview</h3>
      <Detail title="Shares potentially available">
        {(secondary.maxSharesAvailableForPurchase ?? 0).toLocaleString()}
      </Detail>
      <Detail title="Share class">Common</Detail>
      <Detail title="Minimum price">{roundPricePerShare}</Detail>
      <Detail title="Latest round price">
        <Link
          to={"#"}
          onClick={() => {
            fundraisingSectionElement.current.scrollIntoView();
          }}
          style={{ textDecoration: "underline" }}
        >
          {latestRound?.humanized_share_price_dollars ?? "-"}
        </Link>
      </Detail>
    </>
  );

  function dealOverviewLoading(fixed) {
    return (
      <div style={{ width: fixed ? 300 : undefined }}>
        <Skeleton height={50} />
        <Skeleton height={50} />
        <Skeleton height={50} />
        <Skeleton height={50} />
      </div>
    );
  }

  function dealContentLoading(fixed) {
    return (
      <div style={{ width: fixed ? 300 : undefined }}>
        <Skeleton height={50} />
        <Skeleton height={50} />
        <Skeleton height={50} />
        <Skeleton height={50} />
        <Skeleton height={50} />
        <Skeleton height={50} />
      </div>
    );
  }

  const pdfLoading = (
    <>
      <div className="card">
        <div className="outlined-small">
          <Skeleton height={50} />
          <Skeleton height={80} />
          <Skeleton height={80} />
          <Skeleton height={80} />
          <Skeleton height={80} />
          <Skeleton height={80} />
        </div>
      </div>
    </>
  );

  useEffect(() => {
    if (secondary.pricePerShareInBaseUnits) {
      setPricePerShare(
        secondary.pricePerShareInBaseUnits / secondary.pricePerSharePrecision
      );
    }

    if (isAutomatedMatching) {
      fetchOrders();
    }
  }, [secondary]);

  async function fetchSecondary() {
    const res = await getDoc(
      doc(
        db,
        "organizations",
        organizationId,
        "organizations_private",
        "data",
        "secondaries",
        secondaryId
      )
    );

    if (res) {
      const secondaryData = res.data();
      setSecondary(secondaryData);

      if (!secondaryData.decks) {
        return;
      }

      const tmp = [];
      let deckId = 0;
      for (const deck of secondaryData.decks) {
        const result = await post("getBucketDownloadPath", {
          filename: secondaryData.decks[0].fileUrl,
        });
        tmp.push(
          <PDFViewer
            key={deckId}
            deckId={deck.deckId}
            file={result.data}
            email={user.email}
            displayName={user.displayName}
            userId={user.uid}
            organizationId={organizationId}
            secondaryId={secondaryId}
            showWatermark={true}
            useAnalytics={true}
          />
        );

        deckId += 1;
      }

      setDecks(tmp);
    }
  }

  async function load() {
    if (!user.uid) {
      return;
    }

    await Promise.all([fetchSecondary(), fetchOpenOrders()]);
    setIsLoading(false);
  }

  async function createBuyOrder() {
    setIsPlacingOrder(true);
    const params = {
      secondaryId: secondaryId,
      organizationId: organizationId,
      quantity: sharesToBuy,
      createdDate: serverTimestamp(),
      pricePerShareInBaseUnits: pricePerShare * 100,
      pricePerSharePrecision: 100,
    };

    setOrder();

    await post(`secondaries/createBuyOrder`, params);
    await load();
    setIsPlacingOrder(false);
    setShowingOrderConfirmation(true);
    setIsInReview(false);
    setHasAgreedToTerms(false);
  }

  useEffect(() => {
    load();
  }, [user.uid]);

  let ordersSection;
  if (isAutomatedMatching) {
    ordersSection = (
      <div>
        <div className="card outlined-small">
          <h3 className="mt-4">Order book</h3>
          <div className="py-4">
            <SecondaryOrderTable
              loading={isFetchingOrders}
              secondaryOrders={secondaryOrders}
            />
          </div>
        </div>
      </div>
    );
  } else if (orders && orders.length) {
    ordersSection = (
      <div>
        <div className="card outlined-small">
          <h3 className="mt-4 mb-4">Your orders</h3>
          <div>
            <TableContainer
              style={{
                border: "1px solid var(--purple-light)",
                borderRadius: 8,
              }}
            >
              <Table aria-label="simple table">
                <TableHead>
                  <TableRow>
                    <TableCell>Company</TableCell>
                    <TableCell align="right">Date Submitted</TableCell>
                    <TableCell align="right">Price Per Share</TableCell>
                    <TableCell align="right">Quantity</TableCell>
                    <TableCell align="right">Status</TableCell>
                  </TableRow>
                </TableHead>
                <TableBody
                  sx={{
                    "& .MuiTableRow-root": {
                      transition: "background 0.5",
                      color: "red",
                      bottomBorder: "none",
                    },
                    "& .MuiTableRow-root:hover": {
                      background: "var(--background)",
                    },
                  }}
                >
                  {orders.map((order, i) => {
                    const orderLink = `/investor/secondaries/${order.organizationId}/${order.secondaryId}/orders/${order.id}`;

                    // `investor/secondaries/HY624uMYo7WB4abBJq57/transactions/cnqB6tJazOeRmAoEBVIM_iTZ072UnaKVQGgazQjM4`
                    const orderStatus = order.status;

                    return (
                      <TableRow component={Link} to={orderLink} key={i}>
                        <TableCell>
                          <span className="text-success">
                            {order.organizationName}
                          </span>
                        </TableCell>

                        <TableCell align="right" style={{ padding: "16px" }}>
                          {new Date(
                            order.createdDate._seconds * 1000
                          ).toLocaleString()}
                        </TableCell>
                        <TableCell align="right">
                          <span className="text-success">
                            {formatPrice(
                              order.pricePerShareInBaseUnits,
                              order.pricePerSharePrecision
                            )}
                          </span>
                        </TableCell>
                        <TableCell align="right">
                          <span className="text-success">
                            {Number(order.quantity).toLocaleString("en-US")}
                          </span>
                        </TableCell>
                        <TableCell align="right">
                          <span
                            className="text-success"
                            style={{ textTransform: "capitalize" }}
                          >
                            {orderStatus}
                          </span>
                        </TableCell>
                      </TableRow>
                    );
                  })}
                </TableBody>
              </Table>
            </TableContainer>
          </div>
        </div>
      </div>
    );
  }

  const nda = (
    <>
      <div className="container">
        <div
          style={{
            paddingTop: "12px",
            paddingBottom: "12px",
          }}
        >
          <div>
            <div className="card outlined-small py-4">
              <div
                style={{
                  display: "flex",
                  flexDirection: "row",
                }}
              >
                <div
                  style={{
                    marginTop: 8,
                    marginRight: 16,
                    marginLeft: 8,
                    flexGrow: 1,
                  }}
                >
                  To continue, you must agree to the terms outlined in the
                  Non-Disclosure Agreement below.
                </div>
                <div
                  style={{
                    display: "flex",
                    alignItems: "center",
                    justifyContent: "center",
                  }}
                >
                  <MSButton
                    title="Accept"
                    variant="primary"
                    loading={isAccepting}
                    onClick={async () => {
                      setIsAccepting(true);

                      await post(`secondaries/acceptAgreement`, {
                        organizationId,
                        secondaryId,
                        agreement: "nda",
                      });
                      await checkIfNDANeeded();
                    }}
                  ></MSButton>
                </div>
              </div>
            </div>
          </div>
          <PDFViewer
            key={"rescaleNda"}
            file={rescaleNda}
            email={user.email}
            showWatermark={false}
            useAnalytics={false}
            hideFullScreenButton={true}
          />
        </div>
      </div>
    </>
  );

  const dataroomContent = (
    <>
      <Modal
        show={modalOpen}
        onHide={() => setModalOpen(false)}
        dialogClassName="larger-modal"
        contentClassName="larger-modal"
      >
        <Modal.Header style={{ borderBottomColor: "var(--purple-light)" }}>
          <h3>The secondary process</h3>
          <MSButton
            variant="icon"
            style={{ width: 40, height: 40, marginTop: -18 }}
            onClick={() => {
              setModalOpen(false);
            }}
          >
            <FontAwesomeIcon icon={regular("close")} size="xs" />
          </MSButton>
        </Modal.Header>
        <Modal.Body>
          <SecondaryExplainerBuyerContent />
        </Modal.Body>
      </Modal>
      <Header5 />

      <div
        className="content-body"
        style={{
          paddingTop: isMobile
            ? endDate
              ? "64px"
              : "104px"
            : endDate
            ? "80px"
            : "120px",
          minHeight: "100vh",
          paddingBottom: "60px",
        }}
      >
        {endDate && (
          <div
            className={isMobile ? "" : "position-fixed w-100 mb-5"}
            style={{
              zIndex: 99,
              background: "black",
              color: "white",
              marginBottom: isMobile ? 16 : 0,
            }}
          >
            <div className="container">
              <div
                style={{
                  paddingTop: "12px",
                  paddingBottom: "12px",
                }}
                className="text-center"
              >
                {`Response Deadline – ${new Date(
                  endDate.seconds * 1000
                ).toLocaleDateString("en-US", {
                  month: "short",
                  day: "numeric",
                  year: "numeric",
                  hour: "2-digit",
                  minute: "2-digit",
                  timeZoneName: "short",
                })}`}
              </div>
            </div>
          </div>
        )}

        <div
          className="container pt-5 mt-5"
          style={{
            maxWidth: 1300,
          }}
        >
          <div className="row">
            <div className="col-lg-8 col-12">
              <div className="card outlined-small py-4">
                {companyDetails && (
                  <div>
                    <h3 className="mb-3">{companyDetails.companyName}</h3>
                    {/* <p>{companyDetails.description}</p> */}
                    <p>
                      Rescale is enabling intelligent computing for digital R&D.
                    </p>
                  </div>
                )}
                {!companyDetails && (
                  <>
                    <Skeleton height={40} />
                    <Skeleton height={80} />
                  </>
                )}
              </div>

              <div className={"col-12 d-lg-none"}>
                {!isAutomatedMatching && (
                  <div className="card outlined-small py-4">
                    {loading ? dealOverviewLoading() : dealOverview}
                  </div>
                )}
                <div className="card outlined-small py-4">
                  {loading ? dealContentLoading() : dealContent}
                </div>
              </div>

              {ordersSection}

              {loading ? (
                pdfLoading
              ) : (
                <PDFViewer
                  file={rescalePdf}
                  showWatermark={true}
                  email={user.email}
                  displayName={user.displayName}
                  userId={user.uid}
                />
              )}

              {loading ? (
                pdfLoading
              ) : (
                <PDFViewer
                  file={rescaleCapTable}
                  showWatermark={true}
                  email={user.email}
                  displayName={user.displayName}
                  userId={user.uid}
                />
              )}

              {loading ? pdfLoading : decks}

              {/* <div className="col-12">
            <PDFViewer
              file={rescalePdf2}
              showWatermark={true}
              email={user.email}
              displayName={user.displayName}
              userId={user.uid}
            />
          </div> */}

              <div
                className="card outlined-small"
                ref={fundraisingSectionElement}
              >
                <h3 className="mt-4">Fundraising history</h3>

                {fundraisingData && (
                  <>
                    <div className="my-3">
                      <FundraisingChart
                        data={graphData[0]}
                        annotations={graphData[1]}
                      />
                    </div>

                    <FundraisingTable fundraises={fundraisingData} />
                  </>
                )}

                {!fundraisingData && (
                  <>
                    <Skeleton
                      variant="rectangular"
                      height={300}
                      className="my-3"
                    />
                    <Skeleton
                      variant="rectangular"
                      height={300}
                      className="my-3"
                    />
                  </>
                )}
              </div>

              <div
                className="card outlined-small"
                style={{ color: "var(--gray-700)" }}
              >
                <p style={{ marginBottom: 20, marginTop: 15, fontSize: 13 }}>
                  The information provided by Rescale, Inc. ("Rescale") contains
                  "forward-looking information", including "future-oriented
                  financial information" and "financial outlook", under
                  applicable securities laws (collectively referred to herein as
                  forward-looking statements). All statements other than
                  statements of historical fact are forward-looking statements.
                </p>
                <p style={{ marginBottom: 20, fontSize: 13 }}>
                  Forward-looking statements are based on management's
                  expectations, assumptions, and projections based on
                  information available at the time the statements were made.
                  Rescale's future financial condition and results of operations
                  are subject to change due to inherent risks and uncertainties,
                  many of which are beyond Rescale's control. Important factors
                  that could cause our actual results, performance and
                  achievements, or industry results to differ materially from
                  estimates or projections contained in or implied by Rescale's
                  forward-looking statements include but are not limited to:
                  Rescale's revenue growth rate in the future; Rescale's ability
                  to achieve and sustain profitability, our business, financial
                  condition, and operating results; competition and loss of
                  market share to Rescale competitors; the market for Rescale
                  services may not grow; security and privacy breaches;
                  Rescale's ability to manage our growth effectively; or
                  Rescale's ability to respond to rapid technological changes.
                  We do not undertake any obligation to update or release any
                  revisions to any forward-looking statement or to report any
                  events or circumstances after the date hereof or to reflect
                  the occurrence of unanticipated events, except as required by
                  law. Rescale offers no assurance that forward-looking
                  statements will prove to be accurate, as actual results and
                  future events could differ materially from those anticipated
                  in such statements.
                </p>
              </div>
            </div>

            <div className="col-lg-4 col-12">
              <div
                className="position-fixed"
                style={{
                  width: 360,
                  overflowY: "auto",
                  maxHeight: "calc(100vh - 190px)",
                }}
              >
                <div className="d-none d-sm-block">
                  {!isAutomatedMatching && (
                    <div className="card outlined-small py-4">
                      {loading ? dealOverviewLoading("fixed") : dealOverview}
                    </div>
                  )}
                  <div className="card outlined-small py-4">
                    {loading ? dealContentLoading("fixed") : dealContent}
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );

  let content;

  if (needsNDA) {
    content = nda;
  } else {
    content = dataroomContent;
  }

  return <>{content}</>;
}

export default CompanySecondaryDataroom;

// helper component for formatting details/info
function Detail({ title, icon, children, titleSize, detailSize }) {
  return (
    <div className="py-1">
      <div className="d-flex">
        <h5
          style={{
            color: "var(--gray-500)",
            flexGrow: 1,
            fontWeight: "400",
            fontSize: titleSize ?? 14,
          }}
        >
          {title}
        </h5>
        <h5 style={{ textAlign: "right", fontSize: detailSize ?? 14 }}>
          {children}
        </h5>
      </div>
    </div>
  );
}
